import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, inputMaxWidth, spacingUnits, transition } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			maxWidth: inputMaxWidth,

			'& $label': {
				width: '100%',
			},
			'& $indicator': {
				width: 13,
				display: 'block',
				opacity: 0,
				position: 'absolute',
				right: '100%',
				top: spacingUnits(3.5),
				transform: 'translate(-3px, -50%)',
				transition: transition('opacity'),

				'& > *': {
					width: '100%',
					fill: getThemeStyle('palettePrimaryA400'),
				},
				'&$indicatorWithLabel': {
					top: `calc(${spacingUnits(4)}px + ${getThemeStyle('typographySubtitle2LineHeight')} + 2px)`,
				},
			},
			'& $helperText': {
				position: 'absolute',
				bottom: 0,
				left: 0,
				fontSize: '1.3rem',
				lineHeight: '1.6rem',
				fontWeight: 500,
				letterSpacing: '-0.019rem',
				padding: [[0, 0, spacingUnits(1), spacingUnits(1.5)]],
			},
			'& $errorMessage': {
				color: getThemeStyle('paletteErrorMain'),
			},
			'&.MuiFormControl-root': {
				marginBottom: spacingUnits(2),

				'& .MuiInputBase-root': {
					'&.Mui-error .MuiInputBase-input': {
						paddingBottom: spacingUnits(1),
					},

					'& > .MuiSelect-root': {
						display: 'flex',
						alignItems: 'center',
						whiteSpace: 'normal',
						fontSize: '1.6rem',
						height: 56,
					},

					'& > .MuiSelect-icon': {
						bottom: 0,
						top: 0,

						fill: getThemeStyle('paletteGrey500'),
						fontSize: '3.2rem',
						margin: 'auto 0',

						'&.MuiSelect-iconOpen': {
							fill: getThemeStyle('palettePrimary500'),
						},
					},
				},
			},
		},
		label: {},
		indicator: {},
		indicatorWithLabel: {},
		helperText: {},
		menu: {
			'& > .MuiPaper-root': {
				border: `1px solid ${getThemeStyle('palettePrimaryA200')}`,

				'& > ul > li.MuiListItem-root': {
					backgroundColor: 'white',
					fontSize: '1.6rem',
					fontWeight: 500,
					whiteSpace: 'normal',
				},
			},
		},
		input: {},
		errorMessage: {},
	},
	{ name: 'pantheraSelect' },
);
