import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { spacingUnits, brandMexicanRed, subtleBackgroundColor } from '../../../../theme/theme';

export const headerHeight = 84;
export const mobileHeaderHeight = 72;

const useStyles = makeStyles(
	(theme) => ({
		root: {
			backgroundColor: subtleBackgroundColor,
			paddingLeft: spacingUnits(2),
			paddingRight: spacingUnits(2),
			paddingTop: spacingUnits(4),
			paddingBottom: spacingUnits(4) + headerHeight,

			'& .MuiTypography-body1': {
				fontSize: '2rem',
				lineHeight: '2.8rem',
			},

			[theme.breakpoints.down('md')]: {
				paddingTop: spacingUnits(5),
				paddingBottom: spacingUnits(5),

				'& .MuiTypography-h1': {
					fontSize: '4rem',
					lineHeight: '4.2rem',
				},

				'& .MuiTypography-body1': {
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
				},
			},
		},
		underline: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',

			'&:after': {
				content: '""',
				borderBottom: `3px solid ${brandMexicanRed}`,
				paddingTop: 14,
				width: 80,
			},
		},
	}),
	{ name: 'heroTitle' },
);

type HeroTitleProps = {
	className?: string;
	title?: string;
	subtitle?: string;
	maxWidth?: number;
};

export const HeroTitle: React.FC<HeroTitleProps> = ({ className, title, subtitle, maxWidth = 620 }) => {
	const styles = useStyles();
	return (
		<Box className={clsx(styles.root, className)} display="flex" justifyContent="center" alignContent="center">
			<Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" maxWidth={maxWidth}>
				{title && (
					<Typography className={styles.underline} variant="h1" gutterBottom>
						{title}
					</Typography>
				)}
				{subtitle && (
					<Typography variant="body1" align="center" display="block">
						{subtitle}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
