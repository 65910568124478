import React from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PaymentRoutes } from '../../../../enums/routerPath';
import { RootState } from '../../../../store/rootReducer';

type PaymentPlanButtonProps = {
	buttonText?: string;
};

export const PaymentPlanButton: React.FC<PaymentPlanButtonProps> = ({ buttonText = 'Set up a payment plan' }) => {
	const ccPIFOnly = useSelector((state: RootState) => state.paymentMethod.ccPIFOnly);
	return !ccPIFOnly ? (
		<Button color="secondary" fullWidth size="large" variant="contained" component={Link} to={PaymentRoutes.PAYMENT_PLAN.path}>
			{buttonText}
		</Button>
	) : null;
};
