import { Button } from '@material-ui/core';
import React from 'react';

import { AssistantCTAHollow } from '../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../elements/ButtonStack/buttonStack';
import { LockedModal } from '../../../../elements/LockedModal/lockedModal';
import { WrapWithLink } from '../../../../elements/WrapWithLink/wrapWithLink';
import getContent from './content';
import useStyles from './otherOptionsLockedModal.styles';

type Props = {
	onClose: () => void;
	onContinue: () => void;
};

export const OtherOptionsLockedModal: React.FC<Props> = ({ onClose, onContinue }) => {
	const styles = useStyles();
	const content = getContent();

	return (
		<LockedModal className={styles.root} onClose={onClose} showCloseButton open={true}>
			<AssistantCTAHollow avatarClassName={styles.assistantAvatar} title={content.title} />
			<ButtonStack>
				{content.buttons.map((button, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<WrapWithLink key={i} LinkProps={button.LinkProps}>
						<Button color="secondary" fullWidth size="large" variant="contained">
							{button.title}
						</Button>
					</WrapWithLink>
				))}
				<Button color="primary" fullWidth onClick={onContinue} size="large" variant="outlined">
					Go to account overview
				</Button>
			</ButtonStack>
		</LockedModal>
	);
};
