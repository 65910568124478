import { Button, Card, Hidden } from '@material-ui/core';
import React from 'react';
import { AssistantCTAHollow } from '../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ContactButtons } from '../../elements/ContactButtons/contactButtons';
import { history } from '../../../../store/history';
import useStyles from './contactGeneral.styles';

export const ContactGeneral: React.FC = () => {
	const styles = useStyles();

	const handleBackButton = () => {
		history.goBack();
	};

	const Content = () => (
		<>
			<AssistantCTAHollow subtitle="We’re always here to help so please contact us if you need assistance." title="Get in touch" />
			<ContactButtons />
			<Button className={styles.backButton} color="primary" fullWidth onClick={handleBackButton} size="large" variant="outlined">
				Back
			</Button>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					<Content />
				</Card>
			</Hidden>
			<Hidden mdUp>
				<Content />
			</Hidden>
		</>
	);
};
