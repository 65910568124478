import { Checkbox, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { CheckThick } from '../PantheraIcon';
import useStyles from './pantheraCheckbox.styles';

export type PantheraCheckboxProps = {
	className: string;
	checked: boolean;
	label?: string;
	onChange: () => void;
	value: string;
};

export const PantheraCheckbox: React.FC<PantheraCheckboxProps> = ({ className, checked, label, onChange, value }) => {
	const styles = useStyles();
	return (
		<FormControlLabel
			className={clsx(styles.root, checked && styles.rootSelected, className)}
			control={
				<Checkbox
					checked={checked}
					checkedIcon={
						<span className={styles.checkbox}>
							<CheckThick />
						</span>
					}
					disableRipple
					icon={<span className={styles.checkbox} />}
					onChange={onChange}
				/>
			}
			label={label}
			value={value}
		/>
	);
};
