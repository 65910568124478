import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/rootReducer';
import { DebtStatusLogicProps } from '../../types';
import { Blocked } from './Blocked/blocked';
import { PIF } from './PIF/pif';

export const BlockedWorkflow: React.FC<DebtStatusLogicProps> = () => {
	const isPIF = useSelector((state: RootState) => (state.customer.customerDetails?.outstandingAmt ?? 0) <= 0);

	return <>{isPIF ? <PIF /> : <Blocked />}</>;
};
