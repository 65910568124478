import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';
import { AvailablePaymentMethod, PaymentMode } from '../../enums/paymentForm';
import { WebRoutes } from '../../enums/routerPath';
import { RootState } from '../../store/rootReducer';
import { IPageProps, Page } from '../page';

export interface IPaymentAgreementRouteProps extends RouteProps {
	redirectPath?: string;
}
export const PaymentAgreementRoute: React.FC<IPaymentAgreementRouteProps & IPageProps> = ({ title, redirectPath, component, ...rest }) => {
	const { paymentCard, paymentMethod, paymentMode, paymentAmount, paymentDirectDebit } = useSelector(
		(state: RootState) => ({
			paymentCard: state.paymentForm.paymentCard,
			paymentDirectDebit: state.paymentForm.paymentDirectDebit,
			paymentMethod: state.paymentForm.paymentMethod,
			paymentMode: state.paymentForm.paymentMode,
			paymentAmount: state.paymentForm.paymentAmount,
		}),
		shallowEqual,
	);

	let inAgreementFlow = false;

	if (
		paymentMode === PaymentMode.SCHEDULED &&
		((paymentMethod?.Name === AvailablePaymentMethod.CARD && paymentCard) ||
			(paymentMethod?.Name === AvailablePaymentMethod.DIRECT && paymentDirectDebit) ||
			(paymentMethod?.Name === AvailablePaymentMethod.BPAY && Number(paymentAmount) > 0))
	) {
		inAgreementFlow = true;
	}

	return inAgreementFlow ? <Page title={title} {...rest} component={component} /> : <Redirect to={redirectPath ?? WebRoutes.HOME.path} />;
};
