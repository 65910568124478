import { Box, Button, Card, List, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import { ChevronRight } from '../../../../../../elements/PantheraIcon/ChevronRight';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { WrapWithLink } from '../../../../../../elements/WrapWithLink/wrapWithLink';
import useStyles from './supportNavigationCard.styles';

type SupportNavigationCardItem = {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	icon?: React.ReactNode;
	LinkProps?: LinkProps;
};

type SupportNavigationCardProps = {
	className?: string;
	isInline: boolean;
	items: SupportNavigationCardItem[];
};

export const SupportNavigationCard: React.FC<SupportNavigationCardProps> = ({ items, isInline, ...props }) => {
	const styles = useStyles();
	return (
		<Box>
			{({ className }: { className: string }) => (
				<Card raised {...props} className={clsx(className, props.className)}>
					<List className={clsx(styles.list, isInline && styles.listInline)}>
						{items.map(
							// eslint-disable-next-line @typescript-eslint/no-shadow
							({ icon, LinkProps, subtitle, title, ...itemProps }, index) => (
								<ListItem
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									disableGutters
									divider={index !== items.length - 1 && !isInline}
									{...itemProps}
								>
									<WrapWithLink className={styles.listItem} LinkProps={LinkProps}>
										<div className={styles.icon}>{icon}</div>
										<Spacing className={styles.listItemContent} units={isInline ? 2 : 1}>
											<Typography variant="subtitle2">{title}</Typography>
											<Typography className={styles.subtitle} variant="body2">
												{subtitle}
											</Typography>
										</Spacing>
										{isInline ? (
											<Button className={styles.button} color="secondary" variant="contained">
												See more
											</Button>
										) : (
											<ChevronRight className={styles.chevron} />
										)}
									</WrapWithLink>
								</ListItem>
							),
						)}
					</List>
				</Card>
			)}
		</Box>
	);
};
