/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import { combineReducers, AnyAction } from '@reduxjs/toolkit';
import auth, { initialState } from './features/auth/authSlice';
import customer from './features/customer/details/customerSlice';
import customerBpay from './features/customer/bpay/customerBpaySlice';
import customerPhone from './features/customer/phone/customerPhoneSlice';
import customerEmail from './features/customer/email/customerEmailSlice';
import customerAddress from './features/customer/address/customerAddressSlice';
import accountLayout from './features/accountLayout/accountLayoutSlice';
import errorModal from './features/errorModal/errorModalSlice';
import paymentForm from './features/paymentForm/paymentFormSlice';
import paymentFrequency from './features/paymentFrequency/paymentFrequencySlice';
import paymentMethod from './features/paymentMethod/paymentMethodSlice';
import arrangementActive from './features/arrangement/active/arrangementActiveSlice';
import paymentSchedule from './features/arrangement/paymentSchedule/paymentScheduleSlice';
import transaction from './features/transaction/transactionSlice';
import arrangementSummary from './features/arrangement/summary/summarySlice';

const combinedReducer = combineReducers({
	auth,
	customer,
	customerBpay,
	customerPhone,
	customerEmail,
	customerAddress,
	accountLayout,
	errorModal,
	paymentForm,
	paymentFrequency,
	paymentMethod,
	arrangementActive,
	paymentSchedule,
	transaction,
	arrangementSummary,
});

const rootReducer = (state: ReturnType<typeof combinedReducer> | undefined | Partial<ReturnType<typeof combinedReducer>>, action: AnyAction) => {
	if (action.type === 'auth/logout') {
		return combinedReducer(undefined, action);
	}
	if (action.type === 'auth/timedOut') {
		const auth = { ...initialState, timedOut: true, user: undefined };
		state = { auth };
		return combinedReducer(state as ReturnType<typeof combinedReducer>, action);
	}
	return combinedReducer(state as ReturnType<typeof combinedReducer>, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
