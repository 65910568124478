/* eslint-disable react/no-array-index-key */
import { Grid, GridSize, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './settingsList.styles';

type SettingsListItem = {
	addedDate: React.ReactNode;
	value: React.ReactNode;
};

type SettingsListLoaderProps = {
	className?: string;
	columnWidths?: GridSize[];
	items: SettingsListItem[];
};

export const SettingsListLoader: React.FC<SettingsListLoaderProps> = ({ className, columnWidths = [6, 6], items }) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, className)}>
			{items.map((item, index) => (
				<Grid key={index} alignItems="center" className={styles.row} container>
					<Grid item xs={columnWidths[0]}>
						<Typography className={styles.value} variant="subtitle2">
							{item.value}
						</Typography>
					</Grid>
					<Grid className={styles.dateTrash} item xs={columnWidths[1]}>
						<div className={styles.date}>
							<Typography variant="subtitle2">{item.addedDate}</Typography>
						</div>
					</Grid>
				</Grid>
			))}
		</div>
	);
};
