/* eslint-disable @typescript-eslint/no-explicit-any */
import Color from 'color';
import { get, isArray } from 'lodash';

const transparentize = (color: string, amount: number) =>
	Color(color)
		.rgb()
		.alpha(1 - amount)
		.string();

export const debtDetailsPanelWidth = 395;
export const bodyContentMaxWidth = 750;
export const bodyContentMarketingMaxWidth = 944;
export const footerMaxWidth = 1140;
export const toolbarLoggedInHeight = 56;
export const toolbarMarketingHeight = 80;
export const datePickerWidth = 330;
export const inputMaxWidth = 552;

// Brand colors
export const brandMineShaft = '#262626';
export const brandMexicanRed = '#A62424';
export const brandTundora = '#404040';

export const success = '#1f9158';
export const errorDark = '#94193d';
export const errorBaseColor = '#d91236';
export const errorLight = '#fb565d';
export const backgroundColor = '#F5F5F6';
export const warning = errorBaseColor;
export const shadows = '#191919';

export const subtleBackgroundColor = '#F9FAFB';

export const textGrey = '#191919';
export const textDarkGrey = '#0D0D0D';

export const fontFamilyDisplay = "'Red Hat Display', 'Helvetica', 'Arial', 'sans-serif'";
export const fontFamilyText = "'Red Hat Text', 'Helvetica', 'Arial', 'sans-serif'";

const theme = {
	paletteCommonBlack: '#000',
	paletteCommonWhite: '#fff',
	paletteCommonTransparent: 'rgba(0, 0, 0, 0)',
	paletteCommonDarkBlack: 'rgba(0, 0, 0, 0.87)',
	paletteCommonLightBlack: 'rgba(0, 0, 0, 0.54)',
	paletteCommonMinBlack: 'rgba(0, 0, 0, 0.26)',
	paletteCommonFaintBlack: 'rgba(0, 0, 0, 0.12)',
	paletteCommonLightWhite: '#f3f6f8',
	paletteCommonDarkWhite: 'rgba(255, 255, 255, 0.54)',
	paletteGrey50: '#e5e9eb',
	paletteGrey100: '#eff3f6',
	paletteGrey200: '#d8d8d8',
	paletteGrey400: '#9fb9cb',
	paletteGrey500: '#5b6f7b',
	paletteGrey900: '#242431',
	palettePrimary50: '#E5E5E5',
	palettePrimary100: '#BEBEBE',
	palettePrimary200: '#939393',
	palettePrimary300: '#676767',
	palettePrimary400: '#474747',
	palettePrimary500: brandMineShaft,
	palettePrimary600: '#222222',
	palettePrimary700: '#1C1C1C',
	palettePrimary800: '#171717',
	palettePrimary900: '#0D0D0D',
	palettePrimaryA100: '#C08B8B',
	palettePrimaryA200: '#955050',
	palettePrimaryA400: '#493636',
	palettePrimaryA700: '#353030',
	paletteSecondary50: '#F4E5E5',
	paletteSecondary100: '#E4BDBD',
	paletteSecondary200: '#D39292',
	paletteSecondary300: '#C16666',
	paletteSecondary400: '#B34545',
	paletteSecondary500: brandMexicanRed,
	paletteSecondary600: '#9E2020',
	paletteSecondary700: '#951B1B',
	paletteSecondary800: '#8B1616',
	paletteSecondary900: '#7B0D0D',
	paletteSecondaryA100: '#FFADAD',
	paletteSecondaryA200: '#FF7A7A',
	paletteSecondaryA400: '#FF4747',
	paletteSecondaryA700: '#FF2D2D',
	paletteTertiary50: '#E8E8E8',
	paletteTertiary100: '#C6C6C6',
	paletteTertiary200: '#A0A0A0',
	paletteTertiary300: '#797979',
	paletteTertiary400: '#5D5D5D',
	paletteTertiary450: '#244656',
	paletteTertiary500: brandTundora,
	paletteTertiary600: '#3A3A3A',
	paletteTertiary700: '#323232',
	paletteTertiary800: '#2A2A2A',
	paletteTertiary900: '#1C1C1C',
	paletteTertiaryA100: '#D3ACAC',
	paletteTertiaryA200: '#AF6A6A',
	paletteTertiaryA400: '#674C4C',
	paletteTertiaryA700: '#514949',
	paletteErrorLight: errorLight,
	paletteErrorMain: errorBaseColor,
	paletteErrorDark: errorDark,
	paletteErrorText: '#C02138',
	paletteSuccessMain: success,
	paletteTextPrimary: textGrey,
	paletteTextSecondary: Color(textGrey).rgb().darken(0.33).toString(),
	paletteTextHint: '#5b6f7b',
	palettePopperText: '#282C2F',
	palettePopperBackground: '#DBC285',
	paletteBackdropBackground: transparentize('#262626', 0.2),
	paletteInputUnfocusedBackground: transparentize('#E1E2E1', 0.8),
	typographyDisplayFontFamily: fontFamilyDisplay,
	typographyFontFamily: fontFamilyText,
	typographyFontSize: 16,
	// normal text 16
	typographyBody1FontSize: '1.8rem',
	typographyBody1FontWeight: 400,
	typographyBody1FontFamily: fontFamilyText,
	typographyBody1LineHeight: '2.6rem',
	typographyBody1DesktopFontSize: '1.6rem',
	typographyBody1DesktopLineHeight: '2.4rem',
	// normal text 14
	typographyBody2FontSize: '1.4rem',
	typographyBody2FontWeight: 400,
	typographyBody2FontFamily: fontFamilyText,
	typographyBody2LineHeight: '2.2rem',
	// disclaimer 12
	typographyCaptionFontSize: '1.2rem',
	typographyCaptionFontWeight: 400,
	typographyCaptionFontFamily: fontFamilyText,
	typographyCaptionLineHeight: '2rem',
	// button
	typographyButtonFontSize: '1.5rem',
	typographyButtonFontWeight: 'bold',
	typographyButtonFontFamily: fontFamilyDisplay,
	typographyButtonLineHeight: '1.6rem',
	typographyButtonLetterSpacing: '0.02rem',
	// heading 3
	typographyH1FontSize: '4.8rem',
	typographyH1FontWeight: 'bold',
	typographyH1FontFamily: fontFamilyDisplay,
	typographyH1LineHeight: '5rem',
	typographyH1LetterSpacing: '-0.15rem',
	typographyH1Color: textDarkGrey,
	// heading 4
	typographyH2FontSize: '4rem',
	typographyH2FontWeight: 'bold',
	typographyH2FontFamily: fontFamilyDisplay,
	typographyH2LineHeight: '4.2rem',
	typographyH2LetterSpacing: '-0.1rem',
	typographyH2Color: textDarkGrey,
	// heading 5
	typographyH3FontSize: '3.6rem',
	typographyH3FontWeight: 'bold',
	typographyH3FontFamily: fontFamilyDisplay,
	typographyH3LineHeight: '3.8rem',
	typographyH3LetterSpacing: '-0.085rem',
	typographyH3Color: textDarkGrey,
	// heading 6
	typographyH4FontSize: '3rem',
	typographyH4FontWeight: 'bold',
	typographyH4FontFamily: fontFamilyDisplay,
	typographyH4LineHeight: '3.2rem',
	typographyH4LetterSpacing: '-0.07rem',
	typographyH4Color: textDarkGrey,
	// heading 7
	typographyH5FontSize: '2.4rem',
	typographyH5FontWeight: 'bold',
	typographyH5FontFamily: fontFamilyDisplay,
	typographyH5LineHeight: '3.1rem',
	typographyH5LetterSpacing: '-0.075rem',
	typographyH5Color: textDarkGrey,
	// heading 8
	typographyH6FontSize: '2rem',
	typographyH6FontWeight: 'bold',
	typographyH6FontFamily: fontFamilyDisplay,
	typographyH6LineHeight: '2.2rem',
	typographyH6LetterSpacing: '-0.045rem',
	typographyH6Color: textDarkGrey,
	// heading 9
	typographySubtitle1FontSize: '1.8rem',
	typographySubtitle1FontWeight: 'bold',
	typographySubtitle1FontFamily: fontFamilyDisplay,
	typographySubtitle1LineHeight: '2rem',
	typographySubtitle1LetterSpacing: '-0.04rem',
	typographySubtitle1Color: textDarkGrey,
	// heading 10
	typographySubtitle2FontSize: '1.6rem',
	typographySubtitle2FontWeight: 'bold',
	typographySubtitle2FontFamily: fontFamilyDisplay,
	typographySubtitle2LineHeight: '1.8rem',
	typographySubtitle2LetterSpacing: '-0.03rem',
	typographySubtitle2Color: textDarkGrey,
	// heading 11
	typographyOverlineFontSize: '1.4rem',
	typographyOverlineFontWeight: 'bold',
	typographyOverlineFontFamily: fontFamilyDisplay,
	typographyOverlineLineHeight: '1.6rem',
	typographyOverlineLetterSpacing: '-0.02rem',
	typographyOverlineColor: textDarkGrey,
	spacingUnitClose: 5,
	spacingUnit: 8,
	shapeBorderRadius: 10,
	shadowsTourGlow: `0 0 10px 0 #61C0FF, 0 2px 9px 0 ${transparentize(shadows, 0.94)}, 0 4px 12px 0 ${transparentize(shadows, 0.9)}`,
	shadowsMinus8: `0 -2px 9px 0 ${transparentize(shadows, 0.94)}, 0 -4px 12px 0 ${transparentize(shadows, 0.9)}`,
	shadows0: 'none',
	shadows1: `0 3px 7px 0 ${transparentize(shadows, 0.88)}`,
	shadows2: `0 1px 2px 0 ${transparentize(shadows, 0.85)}, 0 2px 4px 0 ${transparentize(shadows, 0.93)}`,
	// default MUI
	shadows3: `0 4px 7px 0 ${transparentize(shadows, 0.85)}`,
	// default MUI
	shadows4: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows5: '0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows6: '0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows7: '0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12)',
	shadows8: `0 2px 9px 0 ${transparentize(shadows, 0.94)}, 0 4px 12px 0 ${transparentize(shadows, 0.9)}`,
	shadows9: `0 1px 7px 0 ${transparentize(shadows, 0.9)}, 0 7px 12px 0 ${transparentize(shadows, 0.93)}, 0 4px 12px 0 ${transparentize(
		shadows,
		0.9,
	)}`,
	// default MUI
	shadows10: '0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows11: '0 6px 7px -4px rgba(0, 0, 0, 0.2), 0 11px 15px 1px rgba(0, 0, 0, 0.14), 0 4px 20px 3px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows12: '0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows13: '0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows14: '0 7px 9px -4px rgba(0, 0, 0, 0.2), 0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows15: '0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows16: '0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows17: '0 8px 11px -5px rgba(0, 0, 0, 0.2), 0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows18: '0 9px 11px -5px rgba(0, 0, 0, 0.2), 0 18px 28px 2px rgba(0, 0, 0, 0.14), 0 7px 34px 6px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows19: '0 9px 12px -6px rgba(0, 0, 0, 0.2), 0 19px 29px 2px rgba(0, 0, 0, 0.14), 0 7px 36px 6px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows20: '0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows21: '0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 21px 33px 3px rgba(0, 0, 0, 0.14), 0 8px 40px 7px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows22: '0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows23: '0 11px 14px -7px rgba(0, 0, 0, 0.2), 0 23px 36px 3px rgba(0, 0, 0, 0.14), 0 9px 44px 8px rgba(0, 0, 0, 0.12)',
	// default MUI
	shadows24: '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12)',
	transitionsEasingEaseInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
	transitionsEasingEaseOut: 'cubic-bezier(0, 0, 0.2, 1)',
	transitionsEasingEaseIn: 'cubic-bezier(0.4, 0, 1, 1)',
	transitionsEasingSharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
	transitionsDurationShortest: 150,
	transitionsDurationShorter: 200,
	transitionsDurationShort: 250,
	transitionsDurationStandard: 300,
	transitionsDurationComplex: 375,
	transitionsDurationEnteringScreen: 225,
	transitionsDurationLeavingScreen: 195,
	breakpointsValuesXS: 0,
	breakpointsValuesSM: 600,
	breakpointsValuesMD: 960,
	breakpointsValuesLG: 1280,
	breakpointsValuesXL: 1920,
	zIndexAppBar: 1100,
	zIndexDrawer: 1200,
	zIndexLoadingOverlay: 1600,
	zIndexMobileStepper: 1000,
	zIndexModal: 1300,
	zIndexSnackbar: 1400,
	zIndexSpeedDial: 1050,
	zIndexTooltip: 1500,
};

export const getStrictDimensionsForDiameter = (diameter: number) => ({
	height: diameter,
	minHeight: diameter,
	minWidth: diameter,
	width: diameter,
});

export const getThemeStyle = (key: string) => get(theme, key);

export const spacingUnits = (multiplier: number) => theme.spacingUnit * multiplier;

export const transition = (
	prop: string | string[],
	time = `${getThemeStyle('transitionsDurationStandard')}ms`,
	easing = getThemeStyle('transitionsEasingEaseInOut'),
	delay = '0s',
) => {
	const transitionOutput: string[] = [];
	let propArray = prop;
	if (!isArray(propArray)) {
		propArray = [prop as string];
	}

	const returnIndexIfArray = (arg: string, index: number) => {
		if (isArray(arg)) {
			if (arg[index] !== undefined) {
				return arg[index];
			}
			throw new Error('[Custom transition] attempted to get index for transition arg that doesnt exist');
		}
		return arg;
	};

	propArray.forEach((aProp: string, index: number) => {
		transitionOutput.push(`${aProp} ${returnIndexIfArray(time, index)} ${returnIndexIfArray(easing, index)} ${returnIndexIfArray(delay, index)}`);
	});
	return transitionOutput.join(',');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	...theme,
	backgroundColor,
	subtleBackgroundColor,
	brandMineShaft,
	brandMexicanRed,
	brandTundora,
	success,
	warning,
	toolbarLoggedInHeight,
	toolbarMarketingHeight,
	datePickerWidth,
};
