import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../../../../../theme';

import { getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		section: {
			marginBottom: spacingUnits(4),
			'& $sectionHeading': {
				fontSize: '1.4rem',
				marginBottom: spacingUnits(0.5),
				fontWeight: 500,
			},
			'& $listWrapper': {
				borderRadius: '10px',
				[MuiTheme.breakpoints.down('sm')]: {
					margin: [[0, -spacingUnits(3)]],
					borderRadius: '0',
				},
			},
		},
		customerName: {
			fontFamily: getThemeStyle('typographyFontFamily'),
			fontWeight: 500,
			marginBottom: spacingUnits(3),
		},
		customerInfoWrapper: {
			marginBottom: spacingUnits(1.5),

			'& $customerInfo': {
				fontFamily: getThemeStyle('typographyFontFamily'),
				fontWeight: 400,
				textAlign: 'right',
			},
			'& $customerInfoHeading': {
				fontFamily: getThemeStyle('typographyFontFamily'),
				fontWeight: 500,
			},
		},
		sectionHeading: {},
		listWrapper: {},
		customerInfo: {},
		customerInfoHeading: {},
	},
	{ name: 'contactsTab' },
);
