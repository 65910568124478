import React from 'react';

type WarningRejectedProps = {
	startColor?: string;
	endColor?: string;
};

export const WarningRejected: React.FC<WarningRejectedProps> = ({ startColor = '#FF9497', endColor = '#FF5260', ...rest }) => {
	return (
		<svg viewBox="0 0 80 80" {...rest}>
			<defs>
				<linearGradient id="WarningRejected-gradient" x1="18.726%" x2="77.792%" y1="19.955%" y2="84.919%">
					<stop offset="0%" stopColor={startColor} />
					<stop offset="100%" stopColor={endColor} />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path
					d="M39.999 11c.862 0 1.657.461 2.087 1.209l30.592 53.164c.43.748.43 1.67 0 2.418A2.41 2.41 0 0170.59 69H9.41a2.407 2.407 0 01-2.088-1.209 2.428 2.428 0 010-2.418l30.59-53.164A2.409 2.409 0 0140 11zm0 7.253L13.582 64.164h52.835l-26.418-45.91zM42 52v6h-5v-6h5zm0-20v16h-5V32h5z"
					fill="url(#WarningRejected-gradient)"
				/>
			</g>
		</svg>
	);
};
