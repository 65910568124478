import { Button, Card, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AssistantCTAHollow } from '../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { AccountRoutes, PaymentRoutes } from '../../../../../enums/routerPath';
import { resetPaymentFormFailure } from '../../../../../store/features/paymentForm/paymentFormSlice';
import { RootState } from '../../../../../store/rootReducer';
import useStyles from './arrangementFailed.styles';

export const ArrangementFailed: React.FC = () => {
	const dispatch = useDispatch();
	const styles = useStyles();
	const [errorSnackbar, setErrorSnackbar] = useState(false);

	const arrangementError = useSelector((state: RootState) => state.paymentForm.paymentFailure?.errors[0]);

	const handleTryAnotherMethod = () => {
		dispatch(resetPaymentFormFailure());
	};

	useEffect(() => {
		setErrorSnackbar(!!arrangementError);
	}, [arrangementError]);

	return (
		<>
			<Snackbar open={errorSnackbar} autoHideDuration={6000} onClose={() => setErrorSnackbar(false)}>
				<Alert variant="filled" severity="error">
					{arrangementError}
				</Alert>
			</Snackbar>
			<Card className={styles.card} elevation={8}>
				<AssistantCTAHollow
					subtitle="Your plan set up was unsuccessful. Please try again with another payment method."
					title="Plan set up unsuccessful"
				/>
				<ButtonStack>
					<Button
						color="secondary"
						fullWidth
						size="large"
						variant="contained"
						component={Link}
						to={PaymentRoutes.PAYMENT_PLAN.path}
						onClick={handleTryAnotherMethod}
					>
						Try another method
					</Button>
					<Button color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
						Go to account overview
					</Button>
				</ButtonStack>
			</Card>
		</>
	);
};
