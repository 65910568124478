import { ARLResponse } from '../../../interceptor/base.api.response';

export interface ITransactionOutput {
	date: string;
	amount: number;
	status: string;
	method: string;
	notes?: string;
}

export type IGetTransaction = ARLResponse<{ payments: ITransactionOutput[] }>;

export enum TransactionAPI {
	TRANSACTION = '/api/transaction',
}
