import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
	{
		root: {
			display: 'flex',
			whiteSpace: 'nowrap',

			'& $adornment': {
				fontSize: '61%',
				alignSelf: 'flex-start',
				lineHeight: '1em',
			},
			'& $decimals': {
				fontSize: '61%',
				alignSelf: 'flex-end',
				lineHeight: '1em',
			},
		},
		adornment: {},
		decimals: {},
	},
	{ name: 'currencyFancyDisplay' },
);
