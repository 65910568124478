import { Button, Dialog, Grid, IconButton, Link, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { Logo } from '../../../../../../elements/Branding/Logo';
import { Close } from '../../../../../../elements/PantheraIcon/Close';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { WrapWithLink } from '../../../../../../elements/WrapWithLink/wrapWithLink';
import { BusinessRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { spacingUnits } from '../../../../../../theme/theme';
import useStyles from './mobileHeader.styles';

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
	return <Slide direction="down" ref={ref} {...props} />;
});

type BusinessMobileHeaderProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const BusinessMobileHeader: React.FC<BusinessMobileHeaderProps> = ({ isOpen, onClose }) => {
	const styles = useStyles();
	return (
		<Dialog className={styles.root} fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
			<Grid className={styles.logoWrapper} container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<WrapWithLink
						className={styles.logo}
						LinkProps={{
							to: BusinessRoutes.HOME.path,
						}}
					>
						<Logo />
					</WrapWithLink>
				</Grid>
				<Grid item>
					<IconButton edge="end" color="primary" aria-label="menu" onClick={onClose}>
						<Close style={{ width: 24 }} />
					</IconButton>
				</Grid>
			</Grid>
			<Grid className={styles.menuItemsWrapper} container direction="column" justifyContent="flex-start" alignItems="center">
				<Spacing units={5}>
					<Grid item>
						<Link component={NavLink} to={BusinessRoutes.INDUSTRIES.path} underline="none" exact>
							Industries
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={BusinessRoutes.OUR_SERVICES.path} underline="none" exact>
							Our Services
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={BusinessRoutes.COMPLIANCE.path} underline="none" exact>
							Compliance
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={BusinessRoutes.CONTACT.path} underline="none" exact>
							Contact
						</Link>
					</Grid>
				</Spacing>
				<Spacing units={3} style={{ marginTop: spacingUnits(5) }}>
					<Grid item>
						<Button color="primary" size="medium" fullWidth variant="outlined" component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
							Resolve overdue account
						</Button>
					</Grid>
					<Grid item>
						<Button color="secondary" size="medium" fullWidth variant="contained" href={window.config.REACT_APP_CLIENT_URL}>
							Client Login
						</Button>
					</Grid>
				</Spacing>
			</Grid>
		</Dialog>
	);
};
