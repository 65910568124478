const loadEncryptionScript = (isDebug: boolean, callback: () => void) => {
	const existingScript = document.getElementById('ewayRapid');

	if (!existingScript) {
		const script: HTMLScriptElement = document.createElement('script') as HTMLScriptElement;
		script.type = 'text/javascript';
		script.id = 'ewayRapid';
		script.src = isDebug ? 'https://secure.ewaypayments.com/scripts/eCrypt.js' : 'https://secure.ewaypayments.com/scripts/eCrypt.min.js';
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	}

	if (existingScript && callback) callback();
};

export default loadEncryptionScript;
