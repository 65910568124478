import React from 'react';

export const CaretUpSm = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M8.604 15h6.792c.534 0 .804-.685.431-1.093l-3.396-3.713a.576.576 0 0 0-.863 0l-3.395 3.713C7.8 14.315 8.07 15 8.603 15"
			fillRule="evenodd"
		/>
	</svg>
);
