import React from 'react';

export const ProcessServing = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 129.28 129.28" {...props}>
		<rect
			x={83.4}
			y={76.81}
			width={27.56}
			height={17.67}
			rx={2.87}
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path d="M89.4 88.48v-5.67H105v5.67l-.69-.25a20.67 20.67 0 00-14.17 0z" fill="#fcc" />
		<path
			d="M91.69 76.81v-3.92a2.68 2.68 0 012.68-2.68H100a2.67 2.67 0 012.67 2.68v3.92"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<circle
			cx={31.52}
			cy={13.14}
			r={11.64}
			transform="rotate(-45 31.524 13.142)"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
			d="M39.85 76.96v40.44M23.19 116.71V76.96M31.52 42.68v4.86a8.6 8.6 0 008.6 8.6h18.93M47.09 127.78h-23.9v-11.07H36a11.06 11.06 0 0111.06 11.07z"
		/>
		<path
			d="M45 49.82v-5.6a13.49 13.49 0 00-13.48-13.49h0A13.49 13.49 0 0018 44.22v12.09A43.87 43.87 0 0023.19 77h16.66a43.57 43.57 0 004.65-14"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<circle
			cx={97.76}
			cy={13.14}
			r={11.64}
			transform="rotate(-13.28 97.771 13.123)"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
			d="M89.43 100.48v16.92M106.09 116.71v-16.23M82.18 127.78h23.91v-11.07H93.25a11.07 11.07 0 00-11.07 11.07zM86.11 68.9a43.84 43.84 0 01-1.84-12.59V44.22a13.49 13.49 0 0113.49-13.49h0a13.49 13.49 0 0113.49 13.49v12.09a44.19 44.19 0 01-1.84 12.59M97.76 42.68v27.53M84.27 56.31H68.92"
		/>
	</svg>
);
