/* eslint-disable react/no-array-index-key */
import { ButtonProps, CardActions, CardContent, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Severity } from '../../enums/severity';
import { BrandAvatar } from '../Branding/BrandAvatar';
import { LogoBrandmark } from '../Branding/LogoBrandmark';
import { DismissableCard } from '../DismissableCard/dismissableCard';
import { DismissableCardEvents } from '../DismissableCard/types';
import useStyles from './assistantCTACard.styles';

type AssistantCTACardProps = {
	assistant?: string;
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	severity?: Severity;
	actions?: React.ReactNode;
};

export const AssistantCTACard: React.FC<AssistantCTACardProps & DismissableCardEvents> = ({
	assistant,
	children,
	title,
	subtitle,
	severity,
	actions,
	onDismiss,
	showCard = true,
}) => {
	const styles = useStyles();
	return (
		<DismissableCard className={styles.root} severity={severity} onDismiss={onDismiss} showCard={showCard}>
			<LogoBrandmark className={styles.brandmark} />
			<BrandAvatar assistant={assistant} className={styles.avatar} />
			<div>
				<CardContent>
					<Typography className={styles.cardTitle} variant="h6">
						{title}
					</Typography>
					{subtitle && <Typography variant="body1">{subtitle}</Typography>}
				</CardContent>
				{children}
				{React.Children.count(actions) > 0 && (
					<CardActions>
						{(actions as React.ReactElement)?.type !== React.Fragment &&
							React.Children.map(actions as React.ReactElement<ButtonProps>, (child, index) =>
								React.isValidElement(child) ? (
									<React.Fragment key={index}>
										{React.cloneElement(child, {
											className: clsx(child?.props.className),
										})}
									</React.Fragment>
								) : null,
							)}
						{(actions as React.ReactElement)?.type === React.Fragment &&
							React.Children.map(
								(actions as React.ReactElement)?.props.children as React.ReactElement<ButtonProps>,
								(fragChild, fragIndex) =>
									React.isValidElement(fragChild) ? (
										<React.Fragment key={fragIndex}>
											{React.cloneElement(fragChild, {
												className: clsx(fragChild?.props.className, styles.buttonWrapper),
											})}
										</React.Fragment>
									) : null,
							)}
					</CardActions>
				)}
			</div>
		</DismissableCard>
	);
};
