import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { WebRoutes } from '../../enums/routerPath';
import { IPageProps, Page } from '../page';

export interface ILoginRouteProps extends RouteProps {
	isAuthenticated: boolean;
	redirectPath?: string;
}

export const LoginRoute: React.FC<ILoginRouteProps & IPageProps> = ({ isAuthenticated, title, redirectPath, component, ...props }) => {
	return isAuthenticated ? <Redirect to={redirectPath ?? WebRoutes.HOME.path} /> : <Page title={title} {...props} component={component} />;
};
