import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $arrows': {
				height: 24,
				fill: getThemeStyle('paletteGrey500'),
			},
		},
		arrows: {},
	},
	{ name: 'datePicker' },
);
