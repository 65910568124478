import { makeStyles } from '@material-ui/core/styles';
import { fontFamilyText, getThemeStyle, spacingUnits } from '../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			padding: spacingUnits(2),
			marginBottom: spacingUnits(2),
			'&$cardBg-default': {
				backgroundColor: getThemeStyle('paletteCommonWhite'),
				color: 'inherit',
			},
			'&$cardBg-primary': {
				backgroundColor: getThemeStyle('paletteTertiary500'),
				color: getThemeStyle('paletteCommonWhite'),

				'@global .MuiDivider-root': {
					opacity: 0.2,
					backgroundColor: getThemeStyle('paletteTertiary100'),
				},
			},
			'& $firstRow': {
				display: 'flex',
				alignItems: 'center',
			},
			'& $debtBranding': {
				width: spacingUnits(7),
				marginRight: spacingUnits(1.5),
				height: spacingUnits(7),
				position: 'relative',
				minWidth: spacingUnits(7),
				minHeight: spacingUnits(7),

				'& $squareSpacing': {
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,

					'& > *': {
						width: '100%',
						height: '100%',
					},
				},
			},
			'& $debtTitleWrapper': {
				flexGrow: 1,

				'& h6': {
					color: 'inherit',
					marginBottom: 2,
				},
			},
			'& $currencyDisplay': {
				fontWeight: 500,
				fontFamily: fontFamilyText,
				fontSize: '2.6rem',
				lineHeight: '2.2rem',
				margin: [[spacingUnits(0.5), 0]],
			},
			'& $sectionBody': {
				color: 'inherit',
				fontSize: '1.4rem',
				fontWeight: 'normal',
				fontFamily: fontFamilyText,
			},

			'& $buttonWrapper': {
				textAlign: 'right',
			},

			'& $arrangementCompletionDetails': {
				display: 'flex',
				justifyContent: 'flex-end',

				'& > hr': {
					height: 48,
					margin: 0,
				},

				'& > div': {
					marginLeft: spacingUnits(2),
					minWidth: 100,
					paddingTop: spacingUnits(0.5),

					'& > p:first-of-type': {
						fontSize: '1.6rem',
						fontWeight: 500,
					},
				},
			},
		},

		details: {
			margin: [[0, -spacingUnits(2)]],
			padding: [[spacingUnits(1), spacingUnits(2)]],
		},

		detailsTitle: {
			color: 'inherit',
			fontSize: '1.8rem',
			fontWeight: 500,
			fontFamily: fontFamilyText,
		},

		detailsSubtitle: {
			color: 'inherit',
			fontSize: '1.4rem',
			marginTop: '1rem',
			fontWeight: 'normal',
			fontFamily: fontFamilyText,
		},

		detailsBody: {
			color: 'inherit',
			fontSize: '1.6rem',
			lineHeight: '1.6rem',
			fontWeight: 500,
			fontFamily: fontFamilyText,
		},

		footer: {
			backgroundColor: getThemeStyle('paletteCommonLightWhite'),
			borderTop: `1px solid ${getThemeStyle('palettePrimary50')}`,
			margin: -spacingUnits(2),
			marginTop: spacingUnits(2),
			padding: [[spacingUnits(1), spacingUnits(2)]],
		},

		'cardBg-default': {},
		'cardBg-primary': {},
		firstRow: {},
		debtBranding: {},
		squareSpacing: {},
		debtTitleWrapper: {},
		currencyDisplay: {},
		sectionBody: {},
		buttonWrapper: {},
		arrangementCompletionDetails: {},
		progressBar: {},
		progress: {
			'& .MuiLinearProgress-colorPrimary': {
				backgroundColor: getThemeStyle('paletteTertiary800'),
			},
			'& .MuiLinearProgress-barColorPrimary': {
				backgroundColor: getThemeStyle('paletteSecondary400'),
				borderColor: getThemeStyle('paletteSecondary500'),
			},
		},
	},
	{ name: 'debtDetailsCard' },
);
