import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			justifyContent: 'flex-end',
			'&$rootSolo': {
				'& $listItem': {
					padding: 0,
				},
			},
			'& $listItem': {
				padding: [[0, 0, spacingUnits(2)]],

				'& $buttonWrapper': {
					width: '100%',
				},
			},
		},
		'@global': {
			'.MuiList-root': {
				'&$root': {
					marginTop: spacingUnits(6),
				},
			},
		},
		rootSolo: {},
		listItem: {},
		buttonWrapper: {},
	},
	{ name: 'buttonStack' },
);
