import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			textAlign: 'center',
			marginBottom: spacingUnits(3),

			'& $avatar': {
				height: 104,
				width: 104,
				boxShadow: getThemeStyle('shadows9'),
				border: 'none',
				margin: [[0, 'auto', spacingUnits(3)]],
			},
			'& $title': {
				marginBottom: spacingUnits(1.5),
			},
		},
		avatar: {},
		title: {},
	},
	{ name: 'assistantCTAHollow' },
);
