import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { Variant } from '@material-ui/core/styles/createTypography';
import useStyles from './assistantCTAHollow.styles';
import { BrandAvatar } from '../Branding/BrandAvatar';

type AssistantCTAHollowProps = {
	assistant?: string;
	avatarClassName?: string;
	className?: string;
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	subtitleVariant?: Variant;
	reverseTitles?: boolean;
	stepper?: React.ReactNode;
};

export const AssistantCTAHollow: React.FC<AssistantCTAHollowProps> = ({
	assistant,
	avatarClassName,
	className,
	title,
	subtitle,
	children = null,
	subtitleVariant = 'body1',
	reverseTitles = false,
	stepper = null,
	...props
}) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, className)} {...props}>
			<BrandAvatar assistant={assistant} className={clsx(styles.avatar, avatarClassName)} />
			{stepper}
			{reverseTitles && subtitle && (
				<Typography className={styles.title} variant={subtitleVariant}>
					{subtitle}
				</Typography>
			)}
			<Typography className={styles.title} variant="h5">
				{title}
			</Typography>
			{children}
			{!reverseTitles && subtitle && <Typography variant={subtitleVariant}>{subtitle}</Typography>}
		</div>
	);
};
