import { ARLResponse } from '../../../interceptor/base.api.response';

export enum ArrangementAPI {
	ARRANGEMENT_ACTIVE = '/api/arrangement/active',
	ARRANGEMENT_SUMMARY = '/api/arrangement/summary',
}
export interface IArrangementActive {
	paID: number;
	Type: 'Direct Debit' | 'Credit Card' | 'VISA' | 'MASTERCARD' | 'BPAY';
	commencementDate: Date;
	frequency: string;
	amount: number;
	account?: string;
}

export type IGetArrangementActive = ARLResponse<{ arrangement: IArrangementActive }>;

export interface IArrangementSummaryOutput {
	sms: boolean;
	email: boolean;
}

export type IArrangementSummary = ARLResponse<{ summary: IArrangementSummaryOutput }>;
