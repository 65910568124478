import { Avatar } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

// assistant image imports
import ava from '../../static/images/assistants/avatar.jpg';
import useStyles from './brandAvatar.styles';

const assistants: { [key: string]: string } = {
	ava,
};

type BrandAvatarProps = {
	className?: string;
	assistant?: string;
};

export const BrandAvatar: React.FC<BrandAvatarProps> = ({ className, assistant = 'ava', ...props }) => {
	const styles = useStyles();
	return <Avatar className={clsx(styles.root, className)} src={assistants[assistant]} {...props} />;
};
