import { AccountRoutes } from '../../../../enums/routerPath';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => ({
	title: 'We have the following options available for you',
	buttons: [
		{
			LinkProps: {
				to: AccountRoutes.HARDSHIP.path,
			},
			title: 'Apply for hardship',
		},
		{
			LinkProps: {
				to: AccountRoutes.CONTACT_GENERAL.path,
			},
			title: 'Request information about my account',
		},
	],
});
