import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $ctaSubheading': {
				fontSize: '1.8rem',
				lineHeight: '2.6rem',
				marginBottom: spacingUnits(0.5),
			},
			'& $ctaTitle': {
				marginBottom: spacingUnits(2),
			},
		},
		ctaSubheading: {},
		ctaTitle: {},
	},
	{ name: 'cTAHeadings' },
);
