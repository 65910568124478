import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../theme';
import { getThemeStyle, spacingUnits, warning } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			display: 'flex',
			padding: spacingUnits(3),
			position: 'relative',
			marginBottom: spacingUnits(3),
			flexDirection: 'column',

			'&$rootWithSeverityPadding': {
				paddingLeft: spacingUnits(3) + 5,
			},
			'& $severityStripe': {
				position: 'absolute',
				left: 0,
				bottom: 0,
				top: 0,
				width: 8,

				'&$severity-info': {
					backgroundColor: getThemeStyle('palettePrimary300'),
				},
				'&$severity-success': {
					backgroundColor: getThemeStyle('paletteSecondary200'),
				},
				'&$severity-warning': {
					backgroundColor: warning,
				},
				'&$severity-error': {
					backgroundColor: getThemeStyle('paletteErrorMain'),
				},
			},
			'& $closeIcon': {
				position: 'absolute',
				top: spacingUnits(1),
				right: spacingUnits(1),
				width: spacingUnits(3),
				zIndex: 2,
				cursor: 'pointer',
				height: 'auto',
				fill: getThemeStyle('paletteTertiary500'),
			},
			'&$hidden': {
				display: 'none',
			},
		},
		rootWithSeverityPadding: {},
		severityStripe: {},
		'severity-info': {},
		'severity-success': {},
		'severity-warning': {},
		'severity-error': {},
		closeIcon: {},
		hidden: {},
		[MuiTheme.breakpoints.up('sm')]: {
			root: {
				flexDirection: 'row',
			},
		},
	},
	{ name: 'dismissableCard' },
);
