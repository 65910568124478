import { makeStyles } from '@material-ui/core/styles';
import { spacingUnits } from '../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $card': {
				padding: spacingUnits(3),
			},
		},
		card: {},
	},
	{ name: 'directDebit' },
);
