import React from 'react';

export const CirclePlusSolid = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M19.071 4.929c-3.903-3.903-10.239-3.903-14.142 0-3.903 3.903-3.903 10.239 0 14.142 3.903 3.903 10.239 3.903 14.142 0 3.903-3.903 3.903-10.239 0-14.142zm-5.66 12.732H10.59v-4.25H6.34V10.59h4.25V6.34h2.82v4.25h4.25v2.82h-4.25v4.25z"
			fillRule="evenodd"
		/>
	</svg>
);
