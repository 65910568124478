import React from 'react';

export const MinusLarge = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<g fillRule="evenodd" stroke="none" strokeWidth="1">
				<path d="M21 13H3a1 1 0 010-2h18a1 1 0 010 2z" />
			</g>
		</svg>
	);
};
