import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../../../theme';
import { getThemeStyle, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			height: spacingUnits(7),
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'center',
			padding: spacingUnits(1.5),
			width: 'auto',
			'& > span': {
				fontSize: '1.3rem',
				color: getThemeStyle('paletteTertiary400'),
			},
		},
		[MuiTheme.breakpoints.up('md')]: {
			root: {
				height: spacingUnits(13),
			},
		},
		[MuiTheme.breakpoints.down('md')]: {
			root: {
				paddingBottom: 0,
			},
			copyright: {
				paddingBottom: 0,
			},
		},
	},
	{ name: 'footer' },
);
