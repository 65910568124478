import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PantheraBreadcrumbs } from '../../../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { HdClipboardMagnify } from '../../../../../../elements/PantheraIcon/HdClipboardMagnify';
import { HdCreditCards } from '../../../../../../elements/PantheraIcon/HDCreditCards';
import { HdDocument } from '../../../../../../elements/PantheraIcon/HdDocument';
import { HdMedicalFolder } from '../../../../../../elements/PantheraIcon/HdMedicalFolder';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { SupportRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { HelpfulLinkCard } from '../../elements/HelpfulLinkCard/helpfulLinkCard';
import useStyles from './otherAssistance.styles';

const breadcrumbs = [
	{
		title: 'Help',
		LinkProps: {
			to: WebRoutes.SUPPORT.path,
		},
	},
	{
		title: 'Other assistance',
		LinkProps: {
			to: SupportRoutes.OTHER_ASSISTANCE.path,
		},
	},
];

export const OtherAssistance: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<main className={styles.container}>
			<Spacing units={3}>
				<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
				<Spacing units={screenAtDesktopWidth ? 5 : 3}>
					<Typography variant="h3">Other assistance</Typography>
					<Typography variant="h4">Helpful links</Typography>
				</Spacing>
				<HelpfulLinkCard
					icon={<HdDocument />}
					text="and how to lodge a hardship request"
					title="Our Hardship Policy"
					link={WebRoutes.HARDSHIP.path}
				/>
				<HelpfulLinkCard
					icon={<HdCreditCards />}
					text="Independent guidance and advice for you"
					title="National Debt Hotline"
					link="https://ndh.org.au/debt-solutions/what-is-financial-hardship-and-what-are-your-rights/"
				/>
				<HelpfulLinkCard
					icon={<HdMedicalFolder />}
					text="Connect with a free financial counsellor"
					title="Free Financial Counselling Support"
					link="https://www.financialcounsellingaustralia.org.au/contact/"
				/>
				<HelpfulLinkCard
					icon={<HdClipboardMagnify />}
					text="Designed to protect consumers who are experiencing long-term and severe financial hardship from unnecessary debt collection activity"
					title="The National Hardship Register"
					link="https://www.nhr.org.au/"
				/>
			</Spacing>
		</main>
	);
};
