import { Avatar, ButtonBase, Hidden, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import useStyles from './userMenu.styles';
import { CaretDownSm, CaretUpSm, Gear, Home, InformationSolid } from '../../../../../../elements/PantheraIcon';
import { Person } from '../../../../../../elements/PantheraIcon/Person';
import { logout } from '../../../../../../store/features/auth/authSlice';
import { AccountRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { RootState } from '../../../../../../store/rootReducer';
import theme from '../../../../../../theme';
import { LogoutIcon } from '../../../../../../elements/PantheraIcon/Logout';
import { UserMenuFlyout } from '../UserMenuFlyout/userMenuFlyout';
import { WebsiteWorkflow } from '../../../../../../enums/websiteWorkflow';

export const UserMenu = ({ fullName }: { fullName: string }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));

	const { websiteWorkflow, customerLoaded, outstanding } = useSelector(
		(state: RootState) => ({
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			customerLoaded: state.customer.isLoaded,
			outstanding: state.customer.customerDetails?.outstandingAmt ?? 0,
		}),
		shallowEqual,
	);

	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		if (customerLoaded && (websiteWorkflow === WebsiteWorkflow.BLOCKED || outstanding <= 0)) {
			setShow(false);
		}
	}, [customerLoaded, websiteWorkflow, outstanding]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		return screenAtDesktopWidth ? setAnchorEl(event.currentTarget) : setMobileOpen(!mobileOpen);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMobileOpen(false);
	};

	return (
		<div>
			<ButtonBase onClick={handleClick}>
				<div className={clsx(styles.root, mobileOpen && styles.rootIsOpen)}>
					<Avatar className={styles.avatar} component="span">
						<Person />
					</Avatar>
					<Hidden xsDown>
						<Typography className={styles.userName} component="span" noWrap variant="subtitle2">
							{fullName}
						</Typography>
					</Hidden>
					{anchorEl || mobileOpen ? (
						<CaretUpSm className={styles.caret} height={24} width={24} />
					) : (
						<CaretDownSm className={styles.caret} height={24} width={24} />
					)}
				</div>
			</ButtonBase>
			<Menu
				id="simple-menu"
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className={styles.dropDown}
			>
				{customerLoaded && show && (
					<div>
						<MenuItem onClick={handleClose} component={Link} to={AccountRoutes.OVERVIEW.path}>
							<ListItemIcon>
								<Home />
							</ListItemIcon>
							<ListItemText primary="Account overview" />
						</MenuItem>
						<MenuItem onClick={handleClose} component={Link} to={WebRoutes.SUPPORT.path}>
							<ListItemIcon>
								<InformationSolid />
							</ListItemIcon>
							<ListItemText primary="Support Centre" />
						</MenuItem>
						<MenuItem onClick={handleClose} component={Link} to={AccountRoutes.ACCOUNT_SETTINGS.path}>
							<ListItemIcon>
								<Gear />
							</ListItemIcon>
							<ListItemText primary="Account settings" />
						</MenuItem>
					</div>
				)}
				<MenuItem onClick={() => dispatch(logout())} data-cy="btnLogout">
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</MenuItem>
			</Menu>
			<UserMenuFlyout show={customerLoaded && show} isOpen={mobileOpen} handleClose={handleClose} />
		</div>
	);
};
