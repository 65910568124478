import React from 'react';

export const Lock = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 236 140" fill="none" {...props}>
			<path
				d="M40.611 46.08l.58-1.29.77 1.18a6 6 0 002.53 2.16l1.29.57-1.18.78a5.898 5.898 0 00-2.16 2.53l-.57 1.28-.78-1.18a5.88 5.88 0 00-2.53-2.15l-1.28-.58 1.18-.77a5.938 5.938 0 002.15-2.53zM1.94 36.4l.33-.75.45.69c.36.549.87.983 1.47 1.25l.75.33-.69.45A3.433 3.433 0 003 39.84l-.33.75-.45-.69a3.446 3.446 0 00-1.47-1.25L0 38.32l.69-.45c.548-.36.982-.87 1.25-1.47zM170.53 117.92l.34-.75.44.68c.36.551.87.985 1.47 1.25l.75.34-.68.45c-.55.36-.99.87-1.26 1.47l-.33.75-.45-.69a3.421 3.421 0 00-1.47-1.25l-.75-.34.69-.45c.55-.357.98-.864 1.25-1.46zM230.15 84.08l.58-1.29.77 1.18a5.92 5.92 0 002.53 2.16l1.29.57-1.18.78a6.05 6.05 0 00-2.16 2.53l-.57 1.29-.78-1.18a5.81 5.81 0 00-2.52-2.16l-1.29-.58 1.18-.77a5.945 5.945 0 002.15-2.53zM222.39 36.78l1-2.16 1.3 2a9.984 9.984 0 004.25 3.62l2.17 1-2 1.3a9.85 9.85 0 00-3.62 4.25l-1 2.16-1.3-2a9.929 9.929 0 00-4.25-3.62l-2.17-1 2-1.3a9.975 9.975 0 003.62-4.25zM28.59 6.58l1-2.17 1.3 2a10 10 0 004.25 3.63l2.17 1-2 1.3a10.008 10.008 0 00-3.62 4.25l-1 2.17-1.3-2a9.93 9.93 0 00-4.25-3.63l-2.16-1 2-1.3a10.003 10.003 0 003.61-4.25z"
				fill="red"
			/>
			<path
				d="M159.62 89.2c24.63 0 44.6-19.968 44.6-44.6S184.25 0 159.62 0c-24.63 0-44.6 19.968-44.6 44.6s19.97 44.6 44.6 44.6z"
				fill="#ffcccc"
			/>
			<path
				opacity={0.2}
				d="M41.07 139.4c16.828 0 30.47-13.642 30.47-30.47S57.898 78.46 41.07 78.46 10.6 92.102 10.6 108.93s13.642 30.47 30.47 30.47zM135.54 36.28v32h-9v-32c0-12.16-9.24-22.05-20.6-22.05-11.36 0-20.62 9.85-20.62 22.05v32h-9v-32c0-17.42 13.3-31.59 29.65-31.59 16.35 0 29.57 14.17 29.57 31.59z"
				fill="#000"
			/>
			<path
				d="M132.421 36.28v32h-9v-32c0-12.16-9.24-22.05-20.6-22.05-11.36 0-20.61 9.89-20.61 22.05v32h-9v-32c0-17.42 13.3-31.59 29.65-31.59 16.35 0 29.56 14.17 29.56 31.59z"
				fill="#B2B6BB"
			/>
			<path d="M144.001 50.06h-82.46v74.81h82.46V50.06z" fill="red" />
			<path opacity={0.2} d="M144.011 50.06h-9.46v74.81h9.46V50.06z" fill="#000" />
			<path
				d="M101.19 102.001l2.65 12.53H92.62l2.64-12.53a5.614 5.614 0 01-2.28-2.75 5.623 5.623 0 018.63-6.51 5.623 5.623 0 011.86 6.51 5.614 5.614 0 01-2.28 2.75z"
				fill="#263238"
			/>
		</svg>
	);
};
