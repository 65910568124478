import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import IMask from 'imask';
import { Box, Button, Card, Container, debounce, Hidden, MenuItem, Snackbar, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { parseFullName } from 'parse-full-name';
import { LoginHeader } from './elements/Header/loginHeader';
import useStyles from './login.styles';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { RootState } from '../../../../store/rootReducer';
import { RegExEnum } from '../../../../enums/regex.enum';
import { LoginOptions } from '../../../../enums/loginOptions';
import { clearRef, loginAuth, loginCheck, loginConfirm, loginMfa, logout, resetAuth } from '../../../../store/features/auth/authSlice';
import loadScript from '../../../../elements/GoogleRecaptcha/loadRecaptcha';
import { ReCaptchaAction } from '../../../../enums/google';
import { PantheraInput } from '../../../../elements/PantheraInput/PantheraInput';
import { SpinnerButton } from '../../../../elements/SpinnerButton/spinnerButton';
import { PantheraSelect } from '../../../../elements/PantheraSelect/PantheraSelect';
import { convertLoginOptionToString } from '../../../../services/helpers/text.helpers';
import { MaskedPantheraInput } from '../../../../elements/PantheraInput/MaskedPantheraInput';
import { LockedModal } from '../../../../elements/LockedModal/lockedModal';
import { isTestMode } from '../../../..';
import { AssistantCTAHollow } from '../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { SessionTimeoutSnackbar } from '../../../../elements/SessionTimeoutSnackbar/sessionTimeoutSnackbar';

interface IReferenceForm {
	reference: string;
}

interface IVerifyForm {
	authType: string;
	DOB: string;
	passwordOnly: string;
	Mobile: string;
	Phone: string;
	Email: string;
	Postcode: string;
}

interface IMFAForm {
	code: string;
}

const HeaderText: React.FC = () => (
	<Typography color="primary" variant="h5">
		Hi,{' '}
		<Hidden smDown>
			<br />
		</Hidden>
		Welcome to the{' '}
		<Typography color="secondary" component="span" variant="h5">
			{window.config.REACT_APP_COMPANY}{' '}
		</Typography>
		customer portal, an SMS, Email or letter from us would have directed you here to manage your account.
	</Typography>
);

const BodyText: React.FC = () => (
	<div>
		<Typography variant="body1" paragraph>
			We are one of Australia’s leading debt collection agencies with over 30 years of experience helping companies from all industries to
			resolve overdue customer accounts.
		</Typography>
		<Typography variant="body1" paragraph>
			One of these companies will have referred your account to us for collections, the best thing you can do first is to login to your account
			to understand what options are available to you.
		</Typography>
		<Typography variant="body1" paragraph>
			If you would like to discuss your account with someone via SMS, email or over the phone you can see our contact options.
		</Typography>
	</div>
);

const curYear = new Date().getFullYear();
const curYearEnd = Number(curYear.toString().substr(-2));
const curYearStart = Number(curYear.toString().substr(0, 2));

export const Login: React.FC = () => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const { search } = window.location;
	const params = new URLSearchParams(search);
	let queryStringRef = '';
	// Match only if there is one query param and only one alpha char
	if (Array.from(params).length === 1) {
		params.forEach((value, key) => {
			if (RegExEnum.A_TO_Z.test(key)) {
				queryStringRef = value;
			}
		});
	}

	const { user, mfa, loading, error } = useSelector(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(state: RootState) => ({
			user: state.auth.user,
			mfa: state.auth.mfa,
			loading: state.auth.loading,
			error: state.auth.error,
		}),
		shallowEqual,
	);

	// These could be stored in redux but are only really needed locally
	const [authType, setAuthType] = useState<string>('');
	const [authData, setAuthData] = useState('');
	const [errorSnackbarOpen, setErrorSnackbar] = useState(false);
	const [showLegacyModal, setShowLegacyModal] = useState(false);
	const [customerName, setCustomerName] = useState('');

	useEffect(() => {
		if (user?.authTypes?.includes(LoginOptions.REDIRECT2LEGACY)) {
			setShowLegacyModal(true);
			setAuthData('');
			setAuthType('');
			dispatch(resetAuth());
		} else {
			setAuthType(user?.authTypes?.find((aType) => aType === LoginOptions.DOB) ?? (user?.authTypes?.length ? user?.authTypes[0] : ''));
		}
	}, [user, dispatch]);

	useEffect(() => {
		if (user?.customer?.fullName) {
			const parsedName = parseFullName(user?.customer?.fullName);
			setCustomerName(`${parsedName.first}${parsedName.middle ? ` ${parsedName.middle}` : ''}${parsedName.last ? ` ${parsedName.last}` : ''}`);
		} else {
			setCustomerName('');
		}
	}, [user, dispatch]);

	useEffect(() => {
		setErrorSnackbar(!!error);
	}, [dispatch, error]);

	const handleConfirm = () => {
		dispatch(loginConfirm(true));
	};

	const handleCancel = () => {
		setAuthData('');
		setAuthType('');
		dispatch(logout());
	};

	useEffect(() => {
		if (!isTestMode) {
			loadScript(`https://www.google.com/recaptcha/api.js?render=${window.config.REACT_APP_RECAPTCHA}`, () => {});
		}
	}, []);

	const refSchema = Yup.object().shape({
		reference: Yup.number()
			.required('Enter a valid reference number')
			.min(1, 'Invalid Reference Number Length')
			.max(2147483647, 'Invalid Reference Number Length'),
	});

	const authTypeSchema = Yup.object().shape({
		authType: Yup.string(),
		DOB: Yup.string().when('authType', {
			is: LoginOptions.DOB,
			then: Yup.string()
				.required('Enter a valid DOB')
				.matches(/^[0-9/]+$/, 'DOB must be in dd/mm/yyyy')
				.min(10, 'DOB must be in dd/mm/yyyy')
				.max(10, 'DOB must be in dd/mm/yyyy'),
		}),
		Email: Yup.string().when('authType', {
			is: LoginOptions.EMAIL,
			then: Yup.string().required('Enter a valid email address').email('Invalid email'),
		}),
		Mobile: Yup.string().when('authType', {
			is: LoginOptions.MOBILE,
			then: Yup.string()
				.required('Enter a valid mobile number')
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(10, 'Invalid mobile')
				.max(11, 'Invalid mobile'),
		}),
		Phone: Yup.string().when('authType', {
			is: LoginOptions.PHONE,
			then: Yup.string()
				.required('Enter a valid phone number')
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(10, 'Invalid phone number')
				.max(11, 'Invalid phone number'),
		}),
		passwordOnly: Yup.string().when('authType', {
			is: LoginOptions.PASSWORD,
			then: Yup.string().required('Enter a password').max(20, 'Invalid password Length'),
		}),
		Postcode: Yup.string().when('authType', {
			is: LoginOptions.POSTCODE,
			then: Yup.string()
				.required('Enter a postcode')
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(4, 'Invalid postcode Length')
				.max(4, 'Invalid postcode Length'),
		}),
	});

	const mfaSchema = Yup.object().shape({
		code: Yup.string()
			.trim()
			.required('Enter a valid 2FA code')
			.matches(/^[0-9]{4}/, 'Must be 4 digits'),
	});

	const updateRef = (reference: string, handleSubmit: () => void) => {
		// Check the user is past the check ref stage
		if (user) {
			dispatch(clearRef());
			handleSubmit();
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceRefUpdate = useCallback(
		debounce((reference: string, handleSubmit: () => void) => updateRef(reference, handleSubmit), 500),
		[user],
	);

	const handleRefSubmit = async (values: IReferenceForm) => {
		// Need to wrap it in a promise to wait for script load and ready
		const captchaToken = isTestMode
			? 'cypress'
			: await new Promise<string>((resolve) => {
					window.grecaptcha.ready(() => {
						window.grecaptcha
							.execute(window.config.REACT_APP_RECAPTCHA, { action: ReCaptchaAction.LOGIN })
							.then((token) => resolve(token));
					});
			  });
		if (captchaToken && values.reference) {
			dispatch(loginCheck(values.reference, captchaToken));
		}
	};

	const handleAuthSubmit = () => {
		let data = authData.trim();
		if (authType === LoginOptions.DOB) {
			const splitDate = data.split('/');
			data = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
		}
		dispatch(loginAuth(authType, data));
	};

	const handleTwoDigitDOB = async (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
		setFieldValue: FormikHelpers<IVerifyForm>['setFieldValue'],
	) => {
		const { value } = event.currentTarget;
		if (value) {
			// Handle if only 2 digits entered assume year end
			const yearMonthDay = value.split('/');
			if (yearMonthDay[2].substring(2) === 'yy' && yearMonthDay[2].substring(0, 2) !== 'yy') {
				const yearEnd = yearMonthDay[2].substring(0, 2);
				const year = Number(yearEnd) <= curYearEnd ? `${curYearStart}${yearEnd}` : `${curYearStart - 1}${yearEnd}`;
				event.currentTarget.value = `${yearMonthDay[0]}/${yearMonthDay[1]}/${year}`;
				setAuthData(`${yearMonthDay[0]}/${yearMonthDay[1]}/${year}`);
				await setFieldValue(LoginOptions.DOB, `${yearMonthDay[0]}/${yearMonthDay[1]}/${year}`, true);
			}
		}
	};

	const handleMfaLogin = (values: IMFAForm) => {
		dispatch(loginMfa(values.code));
	};

	const authenticateCard = (
		<Card className={styles.login} elevation={3}>
			<Hidden mdUp>
				<Spacing className={clsx(styles.padding, styles.text)} units={3}>
					<AssistantCTAHollow title={undefined} />
					<HeaderText />
				</Spacing>
			</Hidden>
			<Spacing className={clsx(styles.padding, styles.background)} units={3}>
				<Typography className={styles.loginText} variant="body1">
					To get started please enter the Reference Number that was communicated to you. This will be prefilled for you if you have followed
					a link from an SMS or Email.
				</Typography>
				{!user?.authenticated && (
					<>
						<div>
							<Formik
								initialValues={{
									reference: user?.referenceNumber ? user?.referenceNumber.toString() : queryStringRef,
								}}
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={handleRefSubmit}
								validationSchema={refSchema}
							>
								{(props: FormikProps<IReferenceForm>) => {
									const { values, touched, errors, setFieldValue, setFieldTouched, handleSubmit } = props;
									return (
										<Form>
											<PantheraInput
												fullWidth
												errorMessage={errors.reference}
												InputProps={{
													id: 'reference',
													name: 'reference',
													placeholder: 'Enter reference number',
													value: values.reference,
													maxLength: 10,
													type: 'tel',
													inputMode: 'numeric',
													'data-cy': 'reference',
													onChange: (e) => {
														setFieldValue(e.currentTarget.name, e.currentTarget.value.replace(/[^0-9.]+/g, ''));
														debounceRefUpdate(e.currentTarget.value.replace(/[^0-9.]+/g, ''), handleSubmit);
													},
													onBlur: (e) => setFieldTouched(e.currentTarget.name, true),
												}}
												label="Reference number"
												error={!!errors.reference && !!touched.reference}
											/>

											{!user && (
												<SpinnerButton
													id="btnLoginRef"
													color="secondary"
													type="submit"
													fullWidth
													variant="contained"
													loading={loading}
													data-cy="btnLoginRef"
												>
													Login
												</SpinnerButton>
											)}
										</Form>
									);
								}}
							</Formik>
							{user && (
								<>
									<Formik
										initialValues={{
											authType,
											DOB: '',
											passwordOnly: '',
											Mobile: '',
											Phone: '',
											Email: '',
											Postcode: '',
										}}
										validateOnChange={false}
										validateOnBlur={true}
										onSubmit={handleAuthSubmit}
										validationSchema={authTypeSchema}
										enableReinitialize={true}
									>
										{(props: FormikProps<IVerifyForm>) => {
											const { values, touched, errors, setFieldValue, setFieldTouched } = props;
											return (
												<Form>
													<PantheraSelect
														fullWidth
														label="Form of identification"
														SelectProps={{
															id: 'identification-menu',
															displayEmpty: true,
															disabled: user && user.authTypes && user?.authTypes?.length <= 1,
															value: user?.authTypes?.length ? authType : '',
															inputProps: {
																'data-cy': 'identification-input',
															},
															onChange: (e) => {
																setAuthType(e.target.value as string);
																setFieldValue('authType', e.target.value);
															},
														}}
													>
														{user?.authTypes?.map((userAuthType) => (
															<MenuItem key={userAuthType} value={userAuthType}>
																{convertLoginOptionToString(userAuthType)}
															</MenuItem>
														))}
													</PantheraSelect>
													{authType === LoginOptions.DOB && (
														<MaskedPantheraInput
															fullWidth
															errorMessage={errors.DOB}
															error={!!errors?.DOB && !!touched.DOB}
															mask={Date}
															pattern="d{/}`m{/}`Y"
															lazy={false}
															overwrite={true}
															blocks={{
																d: {
																	mask: IMask.MaskedRange,
																	placeholderChar: 'd',
																	from: 1,
																	to: 31,
																	maxLength: 2,
																},
																m: {
																	mask: IMask.MaskedRange,
																	placeholderChar: 'm',
																	from: 1,
																	to: 12,
																	maxLength: 2,
																},
																Y: {
																	mask: IMask.MaskedRange,
																	placeholderChar: 'y',
																	from: 0,
																	to: 9999,
																	maxLength: 4,
																},
															}}
															format={(date: Date) => {
																const day = date.getDate();
																const month = date.getMonth() + 1;
																const year = date.getFullYear();
																const dayStr = day < 10 ? `0${day}` : day;
																const monthStr = month < 10 ? `0${month}` : month;
																return [dayStr, monthStr, year].join('/');
															}}
															parse={(str: string) => {
																const yearMonthDay = str.split('/');
																return new Date(
																	Number(yearMonthDay[2]),
																	Number(yearMonthDay[1]) - 1,
																	Number(yearMonthDay[0]),
																);
															}}
															onAccept={(value: string) => {
																setAuthData(value);
																setFieldValue(LoginOptions.DOB, value);
															}}
															InputProps={{
																id: LoginOptions.DOB,
																'data-cy': LoginOptions.DOB,
																type: 'tel',
																autoFocus: true,
																onBlur: async (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
																	await handleTwoDigitDOB(event, setFieldValue);
																	setFieldTouched(LoginOptions.DOB, true);
																},
																onKeyPress: async (
																	event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
																) => {
																	return event.key === 'Enter' ? handleTwoDigitDOB(event, setFieldValue) : null;
																},
															}}
														/>
													)}
													{authType === LoginOptions.MOBILE && (
														<PantheraInput
															fullWidth
															errorMessage={errors.Mobile}
															error={!!errors.Mobile && !!touched.Mobile}
															InputProps={{
																placeholder: 'Enter mobile number',
																value: values.Mobile,
																type: 'tel',
																autoFocus: true,
																'data-cy': LoginOptions.MOBILE,
																onChange: (e) => {
																	setAuthData(e.currentTarget.value);
																	setFieldValue(
																		LoginOptions.MOBILE,
																		e.currentTarget.value.replace(/[^0-9.]+/g, ''),
																	);
																},
																onBlur: () => setFieldTouched(LoginOptions.MOBILE, true),
															}}
														/>
													)}
													{authType === LoginOptions.PHONE && (
														<PantheraInput
															fullWidth
															errorMessage={errors.Phone}
															error={!!errors.Phone && !!touched.Phone}
															InputProps={{
																placeholder: 'Enter phone number',
																value: values.Phone,
																type: 'tel',
																autoFocus: true,
																'data-cy': LoginOptions.PHONE,
																onChange: (e) => {
																	setAuthData(e.currentTarget.value);
																	setFieldValue(LoginOptions.PHONE, e.currentTarget.value.replace(/[^0-9.]+/g, ''));
																},
																onBlur: () => setFieldTouched(LoginOptions.PHONE, true),
															}}
														/>
													)}
													{authType === LoginOptions.EMAIL && (
														<PantheraInput
															fullWidth
															errorMessage={errors.Email}
															error={!!errors.Email && !!touched.Email}
															InputProps={{
																placeholder: 'Enter email address',
																value: values.Email,
																type: 'email',
																autoFocus: true,
																'data-cy': LoginOptions.EMAIL,
																onChange: (e) => {
																	setAuthData(e.currentTarget.value);
																	setFieldValue(LoginOptions.EMAIL, e.currentTarget.value);
																},
																onBlur: () => setFieldTouched(LoginOptions.EMAIL, true),
															}}
														/>
													)}
													{authType === LoginOptions.POSTCODE && (
														<PantheraInput
															fullWidth
															errorMessage={errors.Postcode}
															error={!!errors.Postcode && !!touched.Postcode}
															InputProps={{
																placeholder: 'Enter postcode',
																value: values.Postcode,
																type: 'tel',
																autoFocus: true,
																maxLength: 4,
																'data-cy': LoginOptions.POSTCODE,
																onChange: (e) => {
																	setAuthData(e.currentTarget.value);
																	setFieldValue(
																		LoginOptions.POSTCODE,
																		e.currentTarget.value.replace(/[^0-9.]+/g, ''),
																	);
																},
																onBlur: () => setFieldTouched(LoginOptions.POSTCODE, true),
															}}
														/>
													)}
													{authType === LoginOptions.PASSWORD && (
														<PantheraInput
															fullWidth
															errorMessage={errors.passwordOnly}
															error={!!errors.passwordOnly && !!touched.passwordOnly}
															InputProps={{
																placeholder: 'Enter password',
																value: values.passwordOnly,
																type: 'password',
																autoFocus: true,
																maxLength: 20,
																'data-cy': LoginOptions.PASSWORD,
																onChange: (e) => {
																	setAuthData(e.currentTarget.value);
																	setFieldValue(LoginOptions.PASSWORD, e.currentTarget.value);
																},
																onBlur: () => setFieldTouched(LoginOptions.PASSWORD, true),
															}}
														/>
													)}

													<SpinnerButton
														id="btnLogin"
														data-cy="btnLoginRef"
														color="secondary"
														fullWidth
														type="submit"
														variant="contained"
														loading={loading}
													>
														Log in
													</SpinnerButton>
												</Form>
											);
										}}
									</Formik>
								</>
							)}
						</div>
					</>
				)}
			</Spacing>
			<Hidden mdUp>
				<Spacing className={clsx(styles.padding, styles.text)} units={3}>
					<BodyText />
				</Spacing>
			</Hidden>
		</Card>
	);
	return (
		<>
			<Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbar(false)}>
				<Alert variant="filled" severity="error">
					{error}
				</Alert>
			</Snackbar>
			<SessionTimeoutSnackbar />
			<LockedModal onClose={() => setShowLegacyModal(false)} open={showLegacyModal}>
				<Spacing alignItems="center" display="flex" flexDirection="column" units={3}>
					<Typography align="center" variant="h5">
						Please log in to our alternative portal
					</Typography>
					<Spacing units={2} width="100%">
						<Button
							color="secondary"
							fullWidth
							variant="contained"
							href={window.config.REACT_APP_LEGACY_URL}
							disableFocusRipple
							autoFocus
						>
							Go to portal
						</Button>
					</Spacing>
				</Spacing>
			</LockedModal>

			<LockedModal open={!!(user?.verified || mfa)} onClose={handleCancel} showCloseButton>
				{mfa && (
					<Spacing alignItems="center" display="flex" flexDirection="column" units={3}>
						<LockIcon fontSize="large" />
						<Typography align="center" variant="h5">
							2-Step Verification
						</Typography>
						<Typography align="center" variant="body1" data-cy="mfaText">
							A message with a 4-digit verification code was just sent to <strong>{authData}</strong>
						</Typography>
						<Typography align="center" variant="caption">
							Please allow a moment for the code to arrive. If you do not receive the code, please restart the login process or give us
							a call on {window.config.REACT_APP_CONTACT_NUMBER} to speak with a customer service agent.
						</Typography>
						<Formik
							initialValues={{
								code: '',
							}}
							validateOnChange={false}
							validateOnBlur={true}
							onSubmit={handleMfaLogin}
							validationSchema={mfaSchema}
						>
							{(props: FormikProps<IMFAForm>) => {
								const { values, touched, errors, isValid, dirty, setFieldValue, setFieldTouched } = props;
								return (
									<Form>
										<Spacing units={1} width="100%">
											<PantheraInput
												fullWidth
												errorMessage={errors.code}
												error={!!errors.code && !!touched.code}
												InputProps={{
													placeholder: 'Enter the code',
													value: values.code,
													type: 'tel',
													inputMode: 'numeric',
													maxLength: 4,
													'data-cy': 'mfaCode',
													onChange: (e) => setFieldValue('code', e.currentTarget.value, true),
													onBlur: (e) => setFieldTouched('code', true),
												}}
											/>
											<SpinnerButton
												type="submit"
												id="btnMfaConfirm"
												data-cy="btnMfaConfirm"
												color="secondary"
												fullWidth
												variant="contained"
												loading={loading}
												disabled={!(isValid && dirty)}
											>
												Continue
											</SpinnerButton>
										</Spacing>
									</Form>
								);
							}}
						</Formik>
					</Spacing>
				)}
				{user?.verified ? (
					<Spacing alignItems="center" display="flex" flexDirection="column" units={3}>
						<Typography align="center" variant="h5">
							Please confirm your identity
						</Typography>
						<Typography align="center" variant="body1">
							In order to manage this account, you must confirm that you are{' '}
							<Box component="span" fontWeight="fontWeightBold">
								{customerName}
							</Box>
						</Typography>
						<Spacing units={2} width="100%">
							<Button
								id="btnConfirm"
								data-cy="singleCxBtn"
								color="secondary"
								fullWidth
								variant="contained"
								onClick={handleConfirm}
								disableFocusRipple
								autoFocus
							>
								Confirm and Proceed
							</Button>
							<Button color="primary" fullWidth size="medium" variant="outlined" onClick={handleCancel}>
								Cancel
							</Button>
						</Spacing>
					</Spacing>
				) : (
					<div />
				)}
			</LockedModal>

			<LoginHeader />
			<Hidden smDown>
				<Box display="flex" flexDirection="column" justifyContent="center" className={styles.sidebar}>
					<Spacing units={3}>
						<HeaderText />
						<BodyText />
					</Spacing>
				</Box>
			</Hidden>
			<Box
				className={styles.loginWrapper}
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexGrow="1"
				flexShrink="1"
				pt={14}
				pb={3}
			>
				<Container className={styles.loginContainer}>{authenticateCard}</Container>
			</Box>
		</>
	);
};
