import { makeStyles } from '@material-ui/core/styles';
import MuiTheme from '../../../../theme';

import { spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			padding: spacingUnits(6),
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'visible',
		},
		container: {
			marginTop: spacingUnits(4),
		},
		comparisonCard: {
			marginTop: spacingUnits(2),
		},
		offer: {},
		backButton: {
			order: 3,
		},
		continueButton: {
			order: 2,
		},
		continueOfferButton: {
			order: 1,
		},
		[MuiTheme.breakpoints.down('sm')]: {
			root: {
				padding: [[0, 0, 0, 0]],
				boxShadow: 'none',
				borderRadius: 0,
				backgroundColor: 'transparent',
			},
		},
		[MuiTheme.breakpoints.up('md')]: {
			offer: {
				maxWidth: 616,
				minWidth: 'auto',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
			comparisonCard: {
				width: '90%',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
	},
	{ name: 'singlePayment' },
);
