import { Button, ButtonGroup, Collapse, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { startChat } from '../../../../../../actions/startChat';
import { ContactPhone, ContactPhoneHref } from '../../../../../../elements/ContactPhone/contactPhoneNumber';
import { InformationSolid } from '../../../../../../elements/PantheraIcon';
import { BubbleQuestionMark } from '../../../../../../elements/PantheraIcon/BubbleQuestionMark';
import { BubbleSms } from '../../../../../../elements/PantheraIcon/BubbleSms';
import { Gear } from '../../../../../../elements/PantheraIcon/Gear';
import { Home } from '../../../../../../elements/PantheraIcon/Home';
import { LogoutIcon } from '../../../../../../elements/PantheraIcon/Logout';
import { PhoneCall } from '../../../../../../elements/PantheraIcon/PhoneCall';
import { AccountRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { logout } from '../../../../../../store/features/auth/authSlice';

import useStyles from './userMenuFlyout.styles';

type UserMenuFlyoutProps = {
	isOpen?: boolean;
	show: boolean;
	handleClose: () => void;
};

export const UserMenuFlyout: React.FC<UserMenuFlyoutProps> = ({ isOpen = false, show = false, handleClose }) => {
	const [isCollapsed, setIsCollapsed] = React.useState(true);
	const styles = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (isOpen) {
			setIsCollapsed(false);
		}
	}, [isOpen]);

	const handleOnExited = () => setIsCollapsed(true);

	return (
		<div className={clsx(styles.root, isOpen && !isCollapsed && styles.rootOpen)}>
			<Collapse in={isOpen} onExited={handleOnExited}>
				<div className={styles.flyout}>
					{show && (
						<ButtonGroup className={styles.buttons} color="inherit" orientation="vertical">
							<Button
								startIcon={<Home className={styles.buttonIcon} />}
								onClick={handleClose}
								component={Link}
								to={AccountRoutes.OVERVIEW.path}
							>
								Go to account overview
							</Button>
							<Button
								startIcon={<InformationSolid className={styles.buttonIcon} />}
								onClick={handleClose}
								component={Link}
								to={WebRoutes.SUPPORT.path}
							>
								Support Centre
							</Button>
							<Button
								startIcon={<Gear className={styles.buttonIcon} />}
								onClick={handleClose}
								component={Link}
								to={AccountRoutes.ACCOUNT_SETTINGS.path}
							>
								Account settings
							</Button>
							<Button startIcon={<LogoutIcon className={styles.buttonIcon} />} onClick={() => dispatch(logout())}>
								Logout
							</Button>
						</ButtonGroup>
					)}
					{!show && (
						<ButtonGroup className={styles.buttons} color="inherit" orientation="vertical">
							<Button startIcon={<LogoutIcon className={styles.buttonIcon} />} onClick={() => dispatch(logout())}>
								Logout
							</Button>
						</ButtonGroup>
					)}
					<div className={styles.footer}>
						<ul>
							<li>
								<PhoneCall className={styles.icon} />
								<div>
									<Typography className={styles.title} color="inherit" variant="subtitle2">
										Within Australia
									</Typography>
									<Typography color="inherit" variant="subtitle1">
										<a href={ContactPhoneHref()}>{ContactPhone()}</a>
									</Typography>
								</div>
							</li>
							<li>
								<BubbleQuestionMark className={styles.icon} />
								<div onClick={startChat} onKeyDown={startChat} role="button" tabIndex={0}>
									<Typography color="inherit" variant="subtitle1">
										Start a live chat
									</Typography>
								</div>
							</li>
							<li>
								<BubbleSms className={styles.icon} />
								<div>
									<Typography color="inherit" variant="subtitle1">
										<a href={window.config.REACT_APP_CONTACT_MOBILE_HREF}>Start an SMS chat</a>
									</Typography>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</Collapse>
		</div>
	);
};
