import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TabbedCard } from '../../../../elements/TabbedCard/tabbedCard';
import { ContactsTab } from './elements/ContactsTab/contactsTab';
import { PaymentsTab } from './elements/PaymentsTab/paymentsTab';
import { SchedulesTab } from './elements/SchedulesTab/schedulesTab';
import { SummaryTab } from './elements/SummaryTab/summaryTab';

const accountSettingsTabs = {
	summary: {
		title: 'Summary',
		component: SummaryTab,
	},
	contacts: {
		title: 'Contacts',
		component: ContactsTab,
	},
	payments: {
		title: 'Payments',
		component: PaymentsTab,
	},
	schedules: {
		title: 'Schedules',
		component: SchedulesTab,
	},
};

export const AccountSettings: React.FC = () => {
	const location = useLocation();
	const tabToNavigate = location.hash.slice(1);
	const [selectedTab, setSelectedTab] = useState(tabToNavigate || 'summary');

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
		setSelectedTab(newValue);
	};

	// This prevents re-render when passing classes to parent
	useEffect(() => {
		document.getElementById('bodyContent')?.classList.add('whiteBackground-sm', 'brandmarkHidden-sm', 'footerHidden-sm', 'containerNoPadding-sm');
		return () =>
			document
				.getElementById('bodyContent')
				?.classList.remove('whiteBackground-sm', 'brandmarkHidden-sm', 'footerHidden-sm', 'containerNoPadding-sm');
	});

	return <TabbedCard onTabChange={handleTabChange} selectedTab={selectedTab} tabs={accountSettingsTabs} />;
};
