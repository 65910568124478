import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setErrorModalInfo } from '../../store/features/errorModal/errorModalSlice';
import { RootState } from '../../store/rootReducer';
import { AssistantCTAHollow } from '../AssistantCTAHollow/assistantCTAHollow';
import { LockedModal } from '../LockedModal/lockedModal';
import { Spacing } from '../Spacing/spacing';

export const ErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const { title, subtitle, alert } = useSelector(
		(state: RootState) => ({
			title: state.errorModal.errorModalInfo?.title,
			subtitle: state.errorModal.errorModalInfo?.subtitle,
			alert: state.errorModal.errorModalInfo?.alert,
		}),
		shallowEqual,
	);

	const onClose = () => dispatch(setErrorModalInfo(undefined));

	return (
		<LockedModal open={!!title} onClose={onClose} showCloseButton={true}>
			<Spacing units={3}>
				<AssistantCTAHollow title={title} subtitle={subtitle} />
				{alert && (
					<Alert severity="error" style={{ fontWeight: '500' }}>
						{alert}
					</Alert>
				)}
				<Button color="primary" fullWidth size="large" variant="outlined" onClick={onClose}>
					Close
				</Button>
			</Spacing>
		</LockedModal>
	);
};
