import { makeStyles } from '@material-ui/core';
import { brandMexicanRed, getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';

export default makeStyles(
	(theme) => ({
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',

			'& .MuiChip-root': {
				marginBottom: spacingUnits(3),
				height: 'auto',
				borderRadius: 24,

				'& .MuiChip-label': {
					fontSize: '2.4rem',
					lineHeight: '2.6rem',
					padding: [[spacingUnits(1), spacingUnits(2)]],
					fontWeight: 400,
				},
			},

			'& .MuiListItemText-root': {
				'& span': {
					fontWeight: 500,
					fontSize: '2.2rem',
					lineHeight: 1.8,
				},
			},

			'& .MuiListItemAvatar-root': {
				minWidth: 72,
			},

			'& a': {
				fontWeight: 500,
				whiteSpace: 'nowrap',
			},
		},
		avatar: {
			width: 48,
			height: 48,
			color: getThemeStyle('paletteCommonWhite'),
			backgroundColor: brandMexicanRed,

			'& svg': {
				width: 24,
				height: 24,
				stroke: getThemeStyle('paletteCommonWhite'),
			},
		},
		stepWrapper: {
			position: 'relative',
			marginBottom: spacingUnits(3),
		},
		stepText: {
			left: -100,
			top: -100,
			fontWeight: 900,
			fontSize: '12rem',
			lineHeight: '12.2rem',
			position: 'absolute',
			color: brandMexicanRed,
			opacity: 0.15,
			zIndex: 1,
			[theme.breakpoints.down('sm')]: {
				top: 0,
				left: 0,
				position: 'relative',
				fontSize: '8rem',
				lineHeight: '8.2rem',
				marginBottom: spacingUnits(1),
			},
		},
		greyHeader: {
			backgroundColor: subtleBackgroundColor,
		},
		row: {
			paddingTop: spacingUnits(12),
			paddingBottom: spacingUnits(12),

			[theme.breakpoints.down('sm')]: {
				paddingTop: spacingUnits(6),
				paddingBottom: spacingUnits(6),
			},

			'& p': {
				fontSize: '2rem',
				lineHeight: 1.8,
				marginBottom: spacingUnits(3),
			},

			'& h1': {
				position: 'relative',
				lineHeight: 1.1,
				zIndex: 2,
				[theme.breakpoints.down('xs')]: {
					fontSize: '4rem',
				},
			},
		},
		rowTop: {
			[theme.breakpoints.up('md')]: {
				paddingTop: spacingUnits(24),
			},
		},
		rowBottom: {
			[theme.breakpoints.up('md')]: {
				paddingBottom: spacingUnits(24),
			},
		},
		imgWrapper: {
			justifyContent: 'center',
		},
		svgWrapper: {
			display: 'flex',
			width: '100%',
			height: 'auto',
			maxHeight: 470,

			[theme.breakpoints.down('sm')]: {
				maxHeight: 300,
			},

			'& svg': {
				height: 'auto',
				width: '100%',
			},
		},
		svgPhoneWrapper: {
			display: 'flex',
			width: '100%',
			height: 'auto',
			maxWidth: 325,

			[theme.breakpoints.down('md')]: {
				maxWidth: 300,
			},
			[theme.breakpoints.down('sm')]: {
				maxHeight: 300,
			},

			'& svg': {
				height: 'auto',
				width: '100%',
			},
		},
		button: {
			paddingLeft: spacingUnits(8),
			paddingRight: spacingUnits(8),
		},
	}),
	{ name: 'home' },
);
