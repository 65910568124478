import React from 'react';

export const CartColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 80 80" {...props}>
			<defs>
				<path d="M0 0.0001L56.8814792 0.0001 56.8814792 51.8089 0 51.8089z" id="path-1" />
			</defs>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path d="M21 47L62 45 67 25 20 25z" fill="#ffcccc" />
				<g transform="translate(12 14)">
					<mask fill="#fff" id="mask-2">
						<use xlinkHref="#path-1" />
					</mask>
					<path
						d="M11.097 31.379l37.935-2.23 4.027-16.106H9.452l1.645 18.336zm5.343 10.739a3.173 3.173 0 00-3.17 3.17 3.173 3.173 0 003.17 3.169 3.173 3.173 0 003.169-3.17 3.173 3.173 0 00-3.17-3.17zm26.65 0a3.173 3.173 0 00-3.168 3.17 3.173 3.173 0 003.169 3.169 3.173 3.173 0 003.169-3.17 3.173 3.173 0 00-3.17-3.17zm0 9.69c-2.151 0-4.097-1.014-5.337-2.784-1.238-1.766-1.528-3.936-.795-5.956l.345-.95H22.228l.345.95a6.429 6.429 0 01-.793 5.955c-1.241 1.77-3.188 2.786-5.34 2.786a6.467 6.467 0 01-5.85-3.67c-1.102-2.263-.83-4.893.715-6.863l.435-.555-.554-.438c-1.794-1.42-2.98-3.56-3.17-5.723L5.216 3.352H0V0h6.748c.874 0 1.59.656 1.669 1.526l.735 8.166h46.053c.52 0 1 .234 1.32.644.32.41.432.934.306 1.439l-4.845 19.382a1.668 1.668 0 01-1.528 1.267l-39.122 2.299.397.927a5.129 5.129 0 004.723 3.116H43.09a6.53 6.53 0 016.523 6.522 6.53 6.53 0 01-6.523 6.52z"
						fill="#cc0000"
						mask="url(#mask-2)"
					/>
				</g>
			</g>
		</svg>
	);
};
