import { makeStyles } from '@material-ui/core/styles';

import { fontFamilyText, getThemeStyle, inputMaxWidth, spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			color: 'inherit',
			width: '100%',
			maxWidth: inputMaxWidth,

			'& $button': {
				width: '100%',
				fontFamily: fontFamilyText,
				fontWeight: 500,
				fontSize: '1.4rem',
			},

			'& $paper': {
				height: spacingUnits(8),
				display: 'flex',
				width: '100%',
				alignItems: 'center',
				padding: [[spacingUnits(1.5), spacingUnits(2)]],
				border: `1px solid ${getThemeStyle('palettePrimaryA200')}`,
				'& $content': {
					flexGrow: 1,
					display: 'flex',
					alignItems: 'center',
				},
				'& $times': {
					width: 24,
					marginLeft: spacingUnits(1),
					justifySelf: 'flex-end',
					fill: getThemeStyle('paletteGrey500'),
				},
				'& $check': {
					width: 24,
					marginLeft: spacingUnits(1),
					justifySelf: 'flex-end',
					fill: getThemeStyle('palettePrimary400'),
				},
			},
		},
		button: {},
		paper: {},
		content: {},
		times: {},
		check: {},
	},
	{ name: 'buttonOversize' },
);
