import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			border: `1px solid ${getThemeStyle('paletteTertiary50')}`,
			backgroundColor: getThemeStyle('paletteGrey200'),
		},
	},
	{ name: 'brandAvatar' },
);
