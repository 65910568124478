import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { ArrangementAPI, IArrangementSummaryOutput, IArrangementSummary } from '../types';

export interface ArrangementSummaryState {
	summary: IArrangementSummaryOutput | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: ArrangementSummaryState = {
	summary: undefined,
	loading: false,
	error: undefined,
};

export const ArrangementSummarySlice = createSlice({
	name: 'arrangementSummary',
	initialState,
	reducers: {
		getArrangementSummary(state: ArrangementSummaryState) {
			state.loading = true;
			state.summary = undefined;
			state.error = undefined;
		},
		getArrangementSummarySuccess(state: ArrangementSummaryState, action: PayloadAction<IArrangementSummaryOutput | undefined>) {
			state.loading = false;
			state.summary = action.payload;
			state.error = undefined;
		},
		getArrangementSummaryFailure(state: ArrangementSummaryState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		resetArrangementSummary() {
			return initialState;
		},
	},
});

export const { getArrangementSummary, getArrangementSummarySuccess, getArrangementSummaryFailure, resetArrangementSummary } =
	ArrangementSummarySlice.actions;

export default ArrangementSummarySlice.reducer;

export const sendArrangementSummary =
	(phoneID: number, emailAddressID: number, arrangementID: number): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getArrangementSummary());
			const { data } = await arlApi.post<IArrangementSummary>(ArrangementAPI.ARRANGEMENT_SUMMARY, {
				phoneID,
				emailAddressID,
				arrangementID,
			});
			if (data.success) {
				dispatch(getArrangementSummarySuccess(data.data.summary));
			} else {
				dispatch(getArrangementSummarySuccess(undefined));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getArrangementSummaryFailure(e.response?.data.message));
			} else {
				dispatch(getArrangementSummaryFailure('An unknown error occured.'));
			}
		}
	};
