import { Button, Portal, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { AssistantCTAHollow } from '../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { ContactSelect } from '../../../../../elements/ContactSelect/contactSelect';
import { LockedModal } from '../../../../../elements/LockedModal/lockedModal';
import { SpinnerButton } from '../../../../../elements/SpinnerButton/spinnerButton';
import { ContactType } from '../../../../../enums/contactTypes';
import { sendArrangementSummary } from '../../../../../store/features/arrangement/summary/summarySlice';
import { fetchEmailContacts } from '../../../../../store/features/customer/email/customerEmailSlice';
import { fetchPhoneContacts } from '../../../../../store/features/customer/phone/customerPhoneSlice';
import { RootState } from '../../../../../store/rootReducer';
import { RecurringPaymentSummarySent } from '../SummarySent/summarySent';
import useStyles from './summaryLockedModal.styles';

type Props = {
	onClose: () => void;
	onContinue: () => void;
	title: string;
	subtitle: string;
	buttonText: string;
};

interface ISummary {
	arrangementID: number;
	PhoneID: string;
	EmailAddressID: string;
}

export const SummaryLockedModal: React.FC<Props> = ({ onClose, onContinue, title, subtitle, buttonText }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const [summarySentModalIsOpen, setSummarySentModalIsOpen] = useState(false);
	const [errorSnackbarOpen, setErrorSnackbar] = useState(false);
	const [sentToMobile, setSentToMobile] = useState('');
	const [sentToEmail, setSentToEmail] = useState('');

	const { arrangementID, mobileContacts, emailContacts, mobileLoading, emailLoading, summary, loading, error } = useSelector(
		(state: RootState) => ({
			arrangementID: state.paymentForm.paymentSuccess?.arrangement?.paID || 0,
			mobileContacts: state.customerPhone.phoneContacts,
			emailContacts: state.customerEmail.emailContacts,
			mobileLoading: state.customerPhone.loading,
			emailLoading: state.customerEmail.loading,
			summary: state.arrangementSummary.summary,
			loading: state.arrangementSummary.loading,
			error:
				state.arrangementSummary.error ||
				(state.arrangementSummary.summary && !state.arrangementSummary.summary?.email && !state.arrangementSummary.summary?.sms),
		}),
		shallowEqual,
	);

	const contactSchema = Yup.object().shape(
		{
			PhoneID: Yup.string().when('EmailAddressID', {
				is: (value) => !value,
				then: Yup.string().required('Either phone or email is required.'),
			}),
			EmailAddressID: Yup.string().when('PhoneID', {
				is: (value) => !value,
				then: Yup.string().required('Either phone or email is required.'),
			}),
		},
		[['PhoneID', 'EmailAddressID']],
	);

	useEffect(() => {
		dispatch(fetchPhoneContacts([ContactType.Phone_Mobile]));
		dispatch(fetchEmailContacts());
	}, [dispatch]);

	useEffect(() => {
		setErrorSnackbar(!!error);
	}, [error]);

	useEffect(() => {
		if (summary?.email || summary?.sms) {
			setSummarySentModalIsOpen(true);
		}
	}, [summary]);

	const sendSummary = (values: ISummary) => {
		if (emailContacts) {
			const displayEmail = emailContacts.find((x) => Number(x.ContactDetailID) === Number(values.EmailAddressID));
			setSentToEmail(displayEmail?.ContactDetailNormalized || '');
		}
		if (mobileContacts) {
			const displayNumber = mobileContacts.find((x) => Number(x.ContactDetailID) === Number(values.PhoneID));
			setSentToMobile(displayNumber?.ContactDetail || '');
		}
		dispatch(sendArrangementSummary(Number(values.PhoneID), Number(values.EmailAddressID), values.arrangementID));
	};

	return (
		<>
			<Portal>
				<Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbar(false)}>
					<Alert variant="filled" severity="error">
						We were unable to send your arrangement summary
					</Alert>
				</Snackbar>
			</Portal>
			{!summary?.sms && !summary?.email && (
				<LockedModal onClose={onClose} showCloseButton open={true}>
					<AssistantCTAHollow className={styles.assistantHollow} {...{ title, subtitle }} />
					<Formik
						initialValues={{
							arrangementID,
							PhoneID: '',
							EmailAddressID: '',
						}}
						validationSchema={contactSchema}
						validateOnChange={false}
						validateOnBlur={true}
						onSubmit={sendSummary}
					>
						{(props: FormikProps<ISummary>) => {
							const { values, touched, errors, setFieldValue, setFieldTouched } = props;
							return (
								<Form id="sendSummaryForm">
									<ContactSelect
										label="Mobile Number"
										errorMessage={errors?.PhoneID}
										error={!!errors?.PhoneID && !!touched.PhoneID}
										items={
											mobileContacts?.map((mobile, index) => ({
												value: mobile.ContactDetailID,
												display: mobile.ContactDetail,
											})) ?? []
										}
										SelectProps={{
											displayEmpty: true,
											id: 'PhoneID',
											name: 'PhoneID',
											onChange: (e) => setFieldValue('PhoneID', e.target.value, true),
											onBlur: (e) => setFieldTouched('PhoneID', true),
											value: mobileLoading ? '' : values.PhoneID,
											disabled: mobileLoading,
										}}
										loading={mobileLoading}
										placeHolderText="Please select one (optional)"
										showPlaceholder={true}
									/>
									<ContactSelect
										label="Email"
										errorMessage={errors?.EmailAddressID}
										error={!!errors?.EmailAddressID && !!touched.EmailAddressID}
										items={
											emailContacts?.map((email, index) => ({
												value: email.ContactDetailID,
												display: email.ContactDetailNormalized,
											})) ?? []
										}
										SelectProps={{
											displayEmpty: true,
											id: 'EmailAddressID',
											name: 'EmailAddressID',
											onChange: (e) => setFieldValue('EmailAddressID', e.target.value, true),
											onBlur: (e) => setFieldTouched('EmailAddressID', true),
											value: emailLoading ? '' : values.EmailAddressID,
											disabled: emailLoading,
										}}
										loading={emailLoading}
										placeHolderText="Please select one (optional)"
										showPlaceholder={true}
									/>
									<ButtonStack>
										<SpinnerButton
											className={styles.submitButton}
											color="secondary"
											fullWidth
											variant="contained"
											type="submit"
											loading={loading}
											disabled={!(values.PhoneID || values.EmailAddressID)}
										>
											{buttonText}
										</SpinnerButton>
										<Button color="primary" fullWidth size="large" variant="outlined" onClick={onContinue}>
											Back
										</Button>
									</ButtonStack>
								</Form>
							);
						}}
					</Formik>
				</LockedModal>
			)}
			{summarySentModalIsOpen && (
				<RecurringPaymentSummarySent
					phone={sentToMobile}
					email={sentToEmail}
					onClose={() => {
						setSummarySentModalIsOpen(false);
						onContinue();
					}}
				/>
			)}
		</>
	);
};
