import { makeStyles } from '@material-ui/core';
import { getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';
import MuiTheme from '../../../../theme';

export default makeStyles(
	{
		sidebar: {
			background: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			maxWidth: 500,
			position: 'relative',
			padding: spacingUnits(6),
		},
		login: {
			[MuiTheme.breakpoints.up('md')]: {
				padding: spacingUnits(5),
			},
			[MuiTheme.breakpoints.down('sm')]: {
				boxShadow: 'none',
			},
		},
		referenceNumber: {
			width: '100%',

			[MuiTheme.breakpoints.up('sm')]: {
				marginLeft: -spacingUnits(6),
				marginRight: -spacingUnits(6),
				width: `calc(100% + ${spacingUnits(12)}px)`,
			},
		},
		loginWrapper: {
			[MuiTheme.breakpoints.down('sm')]: {
				background: getThemeStyle('paletteCommonWhite'),
			},
		},
		loginText: {
			fontSize: '1.4rem',
			lineHeight: '2rem',
		},
		loginContainer: {
			[MuiTheme.breakpoints.up('md')]: {
				maxWidth: 600,
			},
			[MuiTheme.breakpoints.down('sm')]: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		padding: {
			[MuiTheme.breakpoints.down('sm')]: {
				paddingLeft: spacingUnits(2),
				paddingRight: spacingUnits(2),
			},
			[MuiTheme.breakpoints.up('sm')]: {
				paddingLeft: spacingUnits(5),
				paddingRight: spacingUnits(5),
			},
			[MuiTheme.breakpoints.up('md')]: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		background: {
			[MuiTheme.breakpoints.down('sm')]: {
				borderTop: `1px solid ${getThemeStyle('paletteTertiary100')}`,
				borderBottom: `1px solid ${getThemeStyle('paletteTertiary100')}`,
				backgroundColor: subtleBackgroundColor,
				marginTop: spacingUnits(3),
				paddingTop: spacingUnits(3),
				paddingBottom: spacingUnits(3),
				marginBottom: spacingUnits(3),
			},
		},
		text: {
			[MuiTheme.breakpoints.down('md')]: {
				'& .MuiTypography-h5': {
					fontSize: '2rem',
					lineHeight: '2.7rem',
					textAlign: 'center',
				},
				'& .MuiTypography-body1': {
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
				},
			},
		},
	},
	{ name: 'login' },
);
