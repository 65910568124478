import React from 'react';

export const CloseCircle = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 28 28" {...props}>
		<g fillRule="evenodd" stroke="none" strokeWidth="1">
			<g transform="translate(-330 -108)">
				<g transform="translate(328 106)">
					<path d="M16 2.667C8.64 2.667 2.667 8.64 2.667 16S8.64 29.333 16 29.333 29.333 23.36 29.333 16 23.36 2.667 16 2.667zm6.667 17.34l-2.66 2.66L16 18.66l-4.007 4.007-2.66-2.66L13.34 16l-4.007-4.007 2.66-2.66L16 13.34l4.007-4.007 2.66 2.66L18.66 16l4.007 4.007z" />
				</g>
			</g>
		</g>
	</svg>
);
