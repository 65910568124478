/* eslint-disable max-len */
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { LearnAnywhere } from '../../../../elements/Illustrations/LearnAnywhere';
import { MissionControl } from '../../../../elements/Illustrations/MissionControl';
import { PeopleCollaborating } from '../../../../elements/Illustrations/PeopleCollaborating';
import { PeopleThinking } from '../../../../elements/Illustrations/PeopleThinking';
import { PiggyBank } from '../../../../elements/Illustrations/PiggyBank';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { SupportRoutes, WebRoutes } from '../../../../enums/routerPath';
import { FrancomBanner } from '../../elements/Banner/banner';
import { CustomerFooter } from '../../elements/Footer/footer';
import { CustomerHeader } from '../../elements/Header/header';
import useStyles from './home.styles';

export const CustomerHome: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<FrancomBanner />
			<CustomerHeader />
			<Container className={styles.row} maxWidth="xl">
				<Grid container spacing={8} alignItems="center">
					<Grid className={clsx(styles.imgWrapper, styles.sectionDesktop)} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgWrapper}>
							<LearnAnywhere />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<Spacing units={5}>
							<Typography className={styles.heroTitle} variant="h1" gutterBottom>
								<span style={{ display: 'block' }}>Self manage</span>
								<span style={{ display: 'block' }}>
									<Typography component="span" variant="inherit" color="secondary">
										your account
									</Typography>{' '}
									at
								</span>
								<Typography component="span" variant="inherit" color="secondary">
									your time
								</Typography>{' '}
								and in{' '}
								<Typography component="span" variant="inherit" color="secondary" display="block">
									your way
								</Typography>
							</Typography>
							<Typography variant="body1">
								Login now to start taking control of your finances though our self managed portal.
							</Typography>
							<Button
								className={styles.button}
								color="secondary"
								variant="contained"
								component={RouterLink}
								to={WebRoutes.CUSTOMERS.path}
							>
								Log in
							</Button>
						</Spacing>
					</Grid>
				</Grid>
			</Container>
			<Container className={styles.row} maxWidth={false} disableGutters>
				<Container maxWidth="xl">
					<Grid container spacing={8} alignItems="center">
						<Box clone order={{ xs: 2, md: 1 }}>
							<Grid item xs={12} md={6} lg={5}>
								<Spacing units={5}>
									<Typography variant="h1" color="secondary" gutterBottom>
										Tailored options to suit you
									</Typography>
									<Typography variant="body1">
										We want to understand your situation to give you the best option for resolving your account.
									</Typography>
									<Button
										className={styles.button}
										color="secondary"
										variant="contained"
										component={Link}
										to={WebRoutes.HOW_IT_WORKS.path}
									>
										How it works
									</Button>
								</Spacing>
							</Grid>
						</Box>
						<Box clone order={{ xs: 1, md: 2 }}>
							<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-end">
								<div className={styles.svgWrapper}>
									<PeopleCollaborating />
								</div>
							</Grid>
						</Box>
					</Grid>
				</Container>
			</Container>
			<Container className={styles.row} maxWidth="xl">
				<Grid container spacing={8} alignItems="center">
					<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgWrapper}>
							<PeopleThinking />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<Spacing units={5}>
							<Typography variant="h1" color="secondary" gutterBottom>
								Debt can be confusing
							</Typography>
							<Typography variant="body1">
								We're here to help answer your questions to understand the collections process and your account.
							</Typography>
							<Button
								className={styles.button}
								color="secondary"
								variant="contained"
								component={RouterLink}
								to={SupportRoutes.FAQ.path}
							>
								FAQ's
							</Button>
						</Spacing>
					</Grid>
				</Grid>
			</Container>
			<Container className={styles.row} maxWidth={false} disableGutters>
				<Container maxWidth="xl">
					<Grid container spacing={8} alignItems="center">
						<Box clone order={{ xs: 2, md: 1 }}>
							<Grid item xs={12} md={6} lg={5}>
								<Spacing units={5}>
									<Typography variant="h1" color="secondary" gutterBottom>
										You control when and how you want to contact us
									</Typography>
									<Typography variant="body1">
										Choose how you want to engage with us from self managing in the digital portal, web chat, SMS chat or
										traditional call.
									</Typography>
									<Button
										className={styles.button}
										color="secondary"
										variant="contained"
										component={RouterLink}
										to={WebRoutes.CUSTOMERS.path}
									>
										Log in
									</Button>
								</Spacing>
							</Grid>
						</Box>
						<Box clone order={{ xs: 1, md: 2 }}>
							<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-end">
								<div className={styles.svgWrapper}>
									<MissionControl />
								</div>
							</Grid>
						</Box>
					</Grid>
				</Container>
			</Container>
			<Container className={styles.row} maxWidth="xl">
				<Grid container spacing={8} alignItems="center">
					<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgWrapper}>
							<PiggyBank />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<Spacing units={5}>
							<Typography variant="h1" color="secondary" gutterBottom>
								Traditional collections rebuilt for the digital world of today
							</Typography>
							<Typography variant="body1">
								Combining years of experience with cutting edge technology to deliver friendly solutions that help you take back
								control.
							</Typography>
							<Button className={styles.button} color="secondary" variant="contained" component={RouterLink} to={WebRoutes.ABOUT.path}>
								About Us
							</Button>
						</Spacing>
					</Grid>
				</Grid>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
