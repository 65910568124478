/* eslint-disable @typescript-eslint/no-shadow */
import { Dialog, Slide, PaperProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { CloseCircle } from '../PantheraIcon';
import useStyles from './lockedModal.styles';
import { LogoBrandmark } from '../Branding/LogoBrandmark';

type LockedModalProps = {
	className?: string;
	onClose?: () => void;
	open?: boolean;
	PaperProps?: PaperProps;
	showCloseButton?: boolean;
};

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
	return <Slide ref={ref} direction="up" {...props} />;
});

export const LockedModal: React.FC<LockedModalProps> = ({
	className,
	onClose,
	open = false,
	PaperProps = {},
	showCloseButton = false,
	children,
	...props
}) => {
	const styles = useStyles();
	return (
		<Dialog
			className={styles.root}
			fullWidth
			maxWidth="lg"
			onClose={onClose}
			open={open}
			PaperProps={{
				elevation: 8,
				...PaperProps,
				className: clsx(styles.dialog, className),
			}}
			TransitionComponent={Transition}
			TransitionProps={{
				onExited: onClose,
			}}
			{...props}
		>
			<LogoBrandmark className={styles.brandmark} />
			{showCloseButton && (
				<button type="button" className={styles.closeButton} onClick={onClose}>
					<CloseCircle />
				</button>
			)}
			<div className={styles.container}>{children}</div>
		</Dialog>
	);
};
