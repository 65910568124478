import { Avatar } from '@material-ui/core';
import React from 'react';
import useStyles from './debtBrandLogo.styles';

type DebtBrandLogoProps = {
	clientDisplayName: string;
	color?: 'default' | 'primary';
	logoUrl?: string;
};

export const DebtBrandLogo: React.FC<DebtBrandLogoProps> = ({ clientDisplayName, color = 'default', logoUrl }) => {
	const styles = useStyles({ color });
	return (
		<>
			{!logoUrl && (
				<Avatar variant="rounded" className={styles.avatar}>
					{clientDisplayName.charAt(0).toUpperCase()}
				</Avatar>
			)}
			{logoUrl && (
				<div className={styles.logoImage}>
					<img src={logoUrl} title="client-logo" alt="client-logo" />
				</div>
			)}
		</>
	);
};
