import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $card': {
				padding: spacingUnits(3),

				'& $bpay': {
					height: spacingUnits(6),
					marginBottom: spacingUnits(2.5),
				},
				'& $sectionTitle': {
					marginBottom: spacingUnits(1),
				},
				'& $sectionBody': {
					fontWeight: 500,
					marginBottom: spacingUnits(1),
				},
			},
			'& $buttonStack': {
				marginTop: spacingUnits(2),

				'& $button': {
					justifyContent: 'flex-start',
					marginBottom: spacingUnits(1), // TODO: remove this once other buttons are back
				},
			},
			'& $body': {
				fontSize: '1.6rem',
			},
			'& $text': {
				paddingBottom: spacingUnits(1),
				textAlign: 'center',

				'& $strong strong': {
					fontWeight: 500,
				},
			},
		},
		card: {},
		bpay: {},
		sectionTitle: {},
		sectionBody: {},
		buttonStack: {},
		button: {},
		body: {},
		text: {},
		strong: {},
		desktopButtons: {
			justifyContent: 'flex-start',
		},
	},
	{ name: 'arrangementSetup' },
);
