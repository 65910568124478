import { Button, Card, Hidden } from '@material-ui/core';
import React from 'react';
import { AssistantCTAHollow } from '../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ContactButtons } from '../../elements/ContactButtons/contactButtons';
import { history } from '../../../../store/history';
import useStyles from './hardship.styles';
import { NotifyEmail } from '../../../../enums/notifyEmails';

export const Hardship: React.FC = () => {
	const styles = useStyles();

	const handleBackButton = () => {
		history.goBack();
	};

	const Content = () => (
		<>
			<AssistantCTAHollow
				subtitle="If you would like to apply for hardship, please contact us via email or phone and ask for the Hardship team, to speak with one of our Hardship specialists who will be able to assist."
				title="Get in touch"
			/>
			<ContactButtons email={NotifyEmail.HARDSHIP} />
			<Button className={styles.backButton} color="primary" fullWidth onClick={handleBackButton} size="large" variant="outlined">
				Back
			</Button>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					<Content />
				</Card>
			</Hidden>
			<Hidden mdUp>
				<Content />
			</Hidden>
		</>
	);
};
