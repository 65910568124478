import { Button, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateFormats } from '../../../../../../services/constants/formats';
import { formatAddressString } from '../../../../../../services/helpers/address.helpers';
import { fetchAddressContacts } from '../../../../../../store/features/customer/address/customerAddressSlice';
import { fetchEmailContacts } from '../../../../../../store/features/customer/email/customerEmailSlice';
import { fetchPhoneContacts } from '../../../../../../store/features/customer/phone/customerPhoneSlice';
import { RootState } from '../../../../../../store/rootReducer';
import useStyles from './contactsTab.styles';
import { SettingsList } from './elements/SettingsList/settingsList';
import { SettingsListLoader } from './elements/SettingsList/settingsListLoader';

export const ContactsTab: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const styles = useStyles();

	const { user, phoneContacts, emailContacts, addressContacts, loading } = useSelector(
		(state: RootState) => ({
			user: state.auth.user,
			customerDetails: state.customer.customerDetails,
			phoneContacts: state.customerPhone.phoneContacts,
			emailContacts: state.customerEmail.emailContacts,
			addressContacts: state.customerAddress.addressContacts,
			loading: state.customerPhone.loading || state.customerEmail.loading || state.customerAddress.loading,
		}),
		shallowEqual,
	);

	// Always fetch in case new details
	useEffect(() => {
		dispatch(fetchPhoneContacts());
		dispatch(fetchEmailContacts());
		dispatch(fetchAddressContacts());
	}, [dispatch]);

	return (
		<>
			<div className={styles.section}>
				<Typography className={styles.customerName} variant="h5">
					{user?.displayName}
				</Typography>
			</div>
			<div className={styles.section}>
				<Typography className={styles.sectionHeading} variant="subtitle1">
					Phone numbers
				</Typography>
				{loading && (
					<SettingsListLoader
						className={styles.listWrapper}
						items={Array.from(new Array(2)).map(() => ({
							addedDate: <Skeleton variant="rect" width={75} style={{ marginLeft: 'auto' }} />,
							value: <Skeleton variant="rect" width={100} />,
						}))}
					/>
				)}
				{!loading && (
					<SettingsList
						className={styles.listWrapper}
						emptyText="No numbers recorded"
						items={
							phoneContacts?.map((phone, index) => ({
								id: phone.ContactDetailID,
								value: phone.ContactDetail,
								// secondaryValue: convertContactTypeToString(phone.ContactDetailTypeID),
								// isPrimary: index === 0,
								addedDate: format(parseISO(phone.CreateTS), dateFormats.prettyShort),
							})) ?? []
						}
					/>
				)}
			</div>
			<div className={styles.section}>
				<Typography className={styles.sectionHeading} variant="subtitle1">
					Email addresses
				</Typography>
				{loading && (
					<SettingsListLoader
						className={styles.listWrapper}
						items={Array.from(new Array(2)).map(() => ({
							addedDate: <Skeleton variant="rect" width={75} style={{ marginLeft: 'auto' }} />,
							value: <Skeleton variant="rect" width={250} />,
						}))}
					/>
				)}
				{!loading && (
					<SettingsList
						className={styles.listWrapper}
						columnWidths={[8, 4]}
						emptyText="No email addresses recorded"
						items={
							emailContacts?.map((email, index) => ({
								id: email.ContactDetailID,
								value: email.ContactDetailNormalized,
								// secondaryValue: convertContactTypeToString(email.ContactDetailTypeID),
								// isPrimary: index === 0,
								addedDate: format(parseISO(email.CreateTS), dateFormats.prettyShort),
							})) ?? []
						}
					/>
				)}
			</div>
			<div className={styles.section}>
				<Typography className={styles.sectionHeading} variant="subtitle1">
					Addresses
				</Typography>
				{loading && (
					<SettingsListLoader
						className={styles.listWrapper}
						items={Array.from(new Array(2)).map(() => ({
							addedDate: <Skeleton variant="rect" width={75} style={{ marginLeft: 'auto' }} />,
							value: <Skeleton variant="rect" width={100} />,
						}))}
					/>
				)}
				{!loading && (
					<SettingsList
						className={styles.listWrapper}
						columnWidths={[8, 4]}
						emptyText="No addresses recorded"
						items={
							addressContacts?.map((address, index) => ({
								id: address.AddressID,
								value: formatAddressString(address),
								// secondaryValue: convertAddressTypeToString(address.AddressTypeID),
								// isPrimary: index === 0,
								addedDate: format(parseISO(address.CreateTS), dateFormats.prettyShort),
							})) ?? []
						}
					/>
				)}
			</div>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => history.goBack()}>
				Back
			</Button>
		</>
	);
};
