import { Button } from '@material-ui/core';
import React from 'react';
import { AssistantCTAHollow } from '../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { LockedModal } from '../../../../../../elements/LockedModal/lockedModal';
import { Spacing } from '../../../../../../elements/Spacing/spacing';

type AcceptOfferModalProps = {
	open: boolean;
	routeToPayment: () => void;
};

export const AcceptOfferModal: React.FC<AcceptOfferModalProps> = ({ open, routeToPayment }) => {
	return (
		<LockedModal open={open} onClose={routeToPayment}>
			<AssistantCTAHollow subtitle="Let's complete your payment details and get you on your way." title="We accept your offer" />
			<Spacing units={1.5}>
				<Button
					disableFocusRipple
					autoFocus
					color="secondary"
					fullWidth
					size="large"
					variant="contained"
					data-cy="btnAcceptOffer"
					onClick={routeToPayment}
				>
					Continue
				</Button>
			</Spacing>
		</LockedModal>
	);
};
