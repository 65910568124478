import { makeStyles } from '@material-ui/core';
import { brandMexicanRed, spacingUnits, subtleBackgroundColor } from '../../../../../../theme/theme';

export default makeStyles(
	(theme) => ({
		root: {
			'& .MuiDialog-container': {
				backgroundColor: subtleBackgroundColor,
			},
			'& .MuiPaper-root': {
				backgroundColor: 'transparent',
				border: 'none',
			},
			'& .MuiLink-root': {
				fontSize: '2.4rem',
			},
		},
		logo: {
			display: 'flex',
			'& > svg': {
				height: 40,
			},
		},
		logoWrapper: {
			padding: [[spacingUnits(3), spacingUnits(2.5)]],
		},
		menuItemsWrapper: {
			paddingLeft: spacingUnits(3),
			paddingRight: spacingUnits(3),
			paddingTop: spacingUnits(3),
			paddingBottom: spacingUnits(3),

			'& a.active': {
				color: brandMexicanRed,
			},

			'& .MuiBox-root': {
				width: '100%',
			},

			'& .MuiGrid-item': {
				textAlign: 'center',
				width: '100%',
				maxWidth: 375,
				marginLeft: 'auto',
				marginRight: 'auto',
			},

			[theme.breakpoints.up('sm')]: {
				paddingTop: spacingUnits(6),
				paddingBottom: spacingUnits(6),
			},
		},
	}),
	{ name: 'customerMobileHeader' },
);
