import React from 'react';

export const CarColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 80 80" {...props}>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path d="M12 38L17 33 64 33 68 38 68 43 58 42 58 50 69 50 69 57 12 58 12 50 23 50 23 42 12 42z" fill="#ffcccc" />
				<path
					d="M23.842 12l-5.215 5.215-2.974 12.921-3.442-3.442H7v3.1h3.928l3.416 3.416-4.123 4.123v31.183h12.572v-9.472h34.98v9.472h12.571V37.333l-4.122-4.123 3.416-3.416h3.927v-3.1h-5.211l-3.441 3.441-2.975-12.92L56.724 12H23.842zm-2.394 6.778l3.677-3.678H55.44l3.677 3.678 2.966 12.883H18.482l2.966-12.883zM13.32 38.617l3.857-3.857h46.211l3.857 3.857v1.942H55.763v10.907h11.481v4.478H13.32v-4.478h11.482V40.559H13.32v-1.942zm45.543 9.749h8.382v-4.708h-8.382v4.708zm-45.543 0h8.383v-4.708H13.32v4.708zm47.553 17.051h6.373v-6.373h-6.373v6.373zm-47.553 0h6.373v-6.373H13.32v6.373z"
					fill="#cc0000"
				/>
			</g>
		</svg>
	);
};
