import { Box, Container, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { CustomerHeader } from '../../elements/Header/header';
import { HeroTitle } from '../../elements/HeroTitle/heroTitle';
import useStyles from './terms.styles';
import { CustomerFooter } from '../../elements/Footer/footer';
import { WebRoutes } from '../../../../enums/routerPath';
import { PantheraBreadcrumbs } from '../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { Spacing } from '../../../../elements/Spacing/spacing';

const breadcrumbs = [
	{
		title: `${window.config.REACT_APP_COMPANY} Home`,
		LinkProps: {
			to: WebRoutes.HOME.path,
		},
	},
	{
		title: 'Terms and Conditions',
		LinkProps: {
			to: WebRoutes.TERMS.path,
		},
	},
];

export const Terms: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Box className={styles.root}>
			<CustomerHeader className={styles.greyHeader} />
			<HeroTitle title="Terms and Conditions" subtitle="Last updated 21/04/2016" />
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
					<Typography variant="h4">Terms and Conditions ("Terms")</Typography>
					<Typography variant="body1">
						Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the www.arlcollect.com.au
						website (the "Site") operated by {window.config.REACT_APP_COMPANY} ("us", "we", or "our").
					</Typography>
					<Typography variant="body1">
						Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms. These Terms apply to
						all visitors, users and others who access or use the Site.
					</Typography>
					<Typography variant="body1">
						By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the terms then you may
						not access the Site.
					</Typography>
					<Typography variant="h4">Disclaimer</Typography>
					<Typography variant="body1">
						The materials on the Site are provided “as is”. We make no expressed or implied warranties, and hereby disclaim and negate all
						other warranties including without limitation but not limited to, warranties of title, non-infringement or fitness for a
						particular purpose. We will not be responsible for any loss or damage that could result from interception by third parties of
						any information or services made available to you via the Site. The material on the Site is based on data from multiple
						sources and although we believe those to be reliable, we cannot and do not guarantee the accuracy, validity, timeliness or
						completeness of any information made available to you for any particular purpose. We will not be liable for any loss or damage
						that you incur in the event of any failure or interruption of the Site, or resulting from the act or omission of any other
						party involved in making the Site or providing information contained in or accessed through the Site, or from any other cause
						relating to your access to or your inability to access or use the Site whether or not the circumstances were within our
						control. We do not guarantee continuous, uninterrupted or secure access to the Site.
					</Typography>
					<Typography variant="h4">Links To Other Websites</Typography>
					<Typography variant="body1">
						Our Site may contain links to third-party web sites or services that are not owned or controlled by us. The inclusion of any
						link is not and does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring
						by us of any information contained in any third-party website.
					</Typography>
					<Typography variant="body1">
						We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party web
						sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for
						any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
						services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and
						privacy policies of any third-party web sites or services that you visit.
					</Typography>
					<Typography variant="body1">
						We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you
						visit.
					</Typography>
					<Typography variant="h4">Privacy</Typography>
					<Typography variant="body1">
						By accessing or using the Site you consent to us collecting, storing, processing and using personal information as detailed in
						our{' '}
						<Link component={RouterLink} color="primary" to={WebRoutes.PRIVACY.path}>
							Privacy Policy
						</Link>
						.
					</Typography>
					<Typography variant="h4">Copyright</Typography>
					<Typography variant="body1">
						All right, title and interest in the Site and its content is our exclusive property except as otherwise stated. The Site is
						for your personal and non-commercial use only and you may print, copy and download information from the Site for your personal
						use only. Without our prior written approval you may not modify, copy, distribute, transmit, display, perform, reproduce,
						publish, license, frame, create derivative works from, transfer, or otherwise use in any other way for commercial or public
						purposes in whole or in part any information or software obtained from the Site.
					</Typography>
					<Typography variant="h4">Termination</Typography>
					<Typography variant="body1">
						We may terminate or suspend access to our Site immediately, without prior notice or liability, for any reason whatsoever,
						including without limitation if you breach the Terms.
					</Typography>
					<Typography variant="body1">
						All provisions of the Terms which by their nature should survive termination shall survive termination, including, without
						limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
					</Typography>
					<Typography variant="h4">Governing Law</Typography>
					<Typography variant="body1">
						These Terms shall be governed and construed in accordance with the laws of Australia, without regard to its conflict of law
						provisions.
					</Typography>
					<Typography variant="body1">
						Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision
						of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in
						effect. These Terms constitute the entire agreement between us regarding our Site, and supersede and replace any prior
						agreements we might have between us regarding the Site.
					</Typography>
					<Typography variant="h4">Changes</Typography>
					<Typography variant="body1">
						We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will
						try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be
						determined at our sole discretion.
					</Typography>
					<Typography variant="body1">
						By continuing to access or use our Site after those revisions become effective, you agree to be bound by the revised terms. If
						you do not agree to the new terms, please stop using the Site.
					</Typography>
					<Typography variant="h4">Contact Us</Typography>
					<Typography variant="body1">
						If you have any questions about these Terms, please{' '}
						<Link component={RouterLink} color="secondary" to={WebRoutes.CONTACT.path}>
							contact us
						</Link>
						.
					</Typography>
				</Spacing>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
