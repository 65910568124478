import { makeStyles } from '@material-ui/core/styles';
import { inputMaxWidth, spacingUnits } from '../../../../../theme/theme';
import MuiTheme from '../../../../../theme';

export default makeStyles(
	{
		card: {
			padding: [[spacingUnits(6), spacingUnits(8), spacingUnits(7)]],

			[MuiTheme.breakpoints.down('sm')]: {
				padding: [[spacingUnits(3), spacingUnits(4), spacingUnits(3.5)]],
			},
		},
		form: {},
		merchantFee: {
			marginBottom: spacingUnits(2),
			maxWidth: inputMaxWidth,
		},
	},
	{ name: 'creditCard' },
);
