import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { CustomerContactsAPI, IAddressContacts, IContactAddressOutput } from '../types';

export interface CustomerAddressState {
	addressContacts: IContactAddressOutput[] | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: CustomerAddressState = {
	addressContacts: undefined,
	loading: false,
	error: undefined,
};

export const customerAddressSlice = createSlice({
	name: 'customerAddressSlice',
	initialState,
	reducers: {
		getAddressContacts(state: CustomerAddressState) {
			state.loading = true;
			state.addressContacts = undefined;
			state.error = undefined;
		},
		getAddressContactsSuccess(state: CustomerAddressState, action: PayloadAction<IContactAddressOutput[]>) {
			state.loading = false;
			state.addressContacts = action.payload;
			state.error = undefined;
		},
		getAddressContactsFailure(state: CustomerAddressState, action: PayloadAction<string>) {
			state.loading = false;
			state.addressContacts = undefined;
			state.error = action.payload;
		},
	},
});

export const { getAddressContacts, getAddressContactsSuccess, getAddressContactsFailure } = customerAddressSlice.actions;

export default customerAddressSlice.reducer;

export const fetchAddressContacts = (): AppThunk => async (dispatch) => {
	try {
		dispatch(getAddressContacts());
		const { data } = await arlApi.get<IAddressContacts>(CustomerContactsAPI.ADDRESS_CONTACTS);
		if (data.success) {
			dispatch(getAddressContactsSuccess(data.data.contacts));
		} else {
			dispatch(getAddressContactsFailure(data.message));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(getAddressContactsFailure(e.response?.data.message));
		} else {
			dispatch(getAddressContactsFailure('An unknown error occured.'));
		}
	}
};
