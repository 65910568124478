import { IContactAddressOutput } from '../../store/features/customer/types';

export const formatAddressString = (address: IContactAddressOutput) => {
	let line1 = '';
	let line2 = '';
	const unitStreetNumber = address.ApartmentNo ? `${address.ApartmentNo}${address.StreetNo ? `/${address.StreetNo}` : ''}` : address.StreetNo ?? '';
	if (address.Street || address.Address) {
		line1 = [address.Address ?? '', unitStreetNumber ?? '', address.Street ?? '', address.StreetType ?? ''].join(' ').trim().concat(',\n');
		line2 = [address.Suburb ?? '', address.State ?? '', address.Postcode ?? ''].join(' ').trim();
	}
	return line1.concat(line2);
};
