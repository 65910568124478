import { makeStyles } from '@material-ui/core/styles';
import { getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			flexGrow: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',

			button: {
				marginRight: spacingUnits(1),
				fontSize: '1.6rem',
				fontWeight: 500,
			},
			'& $homeIcon': {
				fill: getThemeStyle('paletteSecondary600'),
				height: 21,
				width: 21,
			},
			'& $phoneIcon': {
				fill: getThemeStyle('paletteSecondary600'),
				height: 24,
				width: 24,
			},
			'& $infoIcon': {
				fill: getThemeStyle('paletteSecondary600'),
				height: 21,
				width: 21,
			},
			'& a': {
				'text-decoration': 'none',
			},
		},
		homeIcon: {},
		phoneIcon: {},
		infoIcon: {},
	},
	{ name: 'headerLinks' },
);
