import { Box, Card, Hidden, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { AllPaymentMethods, PaymentFrequency } from '../../enums/paymentForm';
import { dateFormats } from '../../services/constants/formats';
import { RootState } from '../../store/rootReducer';
import { spacingUnits } from '../../theme/theme';
import { AmountPresenter } from '../AmountPresenter/amountPresenter';
import { BpayHorizontal } from '../PantheraIcon/BpayHorizontal';
import { MasterCard } from '../PantheraIcon/MasterCard';
import { Visa } from '../PantheraIcon/Visa';
import { Spacing } from '../Spacing/spacing';
import useStyles from './nextPaymentCard.styles';

export type NextPaymentCardProps = {
	arrangementID: number;
	amount: number;
	frequency: string;
	onAdjust?: () => void;
	paymentMethod: AllPaymentMethods;
};

const creditCardMethods = ['Credit Card', 'VISA', 'MASTERCARD'];

export const NextPaymentCard: React.FC<NextPaymentCardProps> = ({ arrangementID, amount, frequency, onAdjust, paymentMethod, ...props }) => {
	const styles = useStyles();

	const { outstanding, nextPaymentDate, arrangement } = useSelector((state: RootState) => ({
		outstanding: state.customer.customerDetails?.outstandingAmt,
		nextPaymentDate: state.customer.customerDetails?.nextPayDate,
		arrangement: state.arrangementActive.arrangement,
	}));

	const paymentMethodRender = () => {
		if (creditCardMethods.includes(arrangement?.Type ?? '')) {
			return (
				<>
					<span className={styles.details}>
						{arrangement?.Type === 'MASTERCARD' ? <MasterCard className={styles.cardIcon} /> : <Visa className={styles.cardIcon} />}
						{arrangement?.account && (
							<span className={styles.info}>
								<Hidden smDown>&#9679;&#9679;&#9679;&#9679;</Hidden> {arrangement.account.substr(arrangement.account.length - 4)}
							</span>
						)}
					</span>
				</>
			);
		}
		if (arrangement?.Type === 'Direct Debit') {
			return (
				<>
					<span className={styles.paymentType}>Direct debit</span>
					{arrangement?.account && (
						<span className={styles.info} style={{ marginLeft: spacingUnits(2), textTransform: 'uppercase' }}>
							{arrangement.account}
						</span>
					)}
				</>
			);
		}
		if (arrangement?.Type === 'BPAY') {
			return (
				<span className={styles.details}>
					<BpayHorizontal className={clsx(styles.paymentIcon, styles.bpayIcon)} />
					<span className={styles.info}>BPAY Arrangement</span>
				</span>
			);
		}
		return (
			<span className={styles.details}>
				<span className={styles.info}>{arrangement?.Type} Arrangement</span>
			</span>
		);
	};

	return (
		<Box {...props}>
			<Card className={clsx(styles.root)} raised>
				<div>
					<div>
						<Spacing alignItems="flex-end" component="span" orientation="horizontal" units={0.5}>
							<AmountPresenter amount={outstanding && outstanding < amount ? outstanding : amount} shouldDisplayDecimals size="small" />
							<Box color="black" component="p" fontSize="1.6rem" fontWeight={500} m={0} ml={spacingUnits(0.5)}>
								{frequency.toLowerCase()}
							</Box>
						</Spacing>
						{nextPaymentDate && (
							<>
								<Typography variant="body2">
									Next payment {format(parseISO(nextPaymentDate), dateFormats.prettyShort)}{' '}
									{paymentMethod === AllPaymentMethods.DIRECT && arrangement?.frequency === PaymentFrequency.ONE_OFF && (
										<Tooltip
											title={
												<Typography variant="caption">
													Direct debit payments may take up to 3 business days to process.
												</Typography>
											}
											arrow
										>
											<InfoIcon />
										</Tooltip>
									)}
								</Typography>
							</>
						)}
						<Typography variant="body2">{paymentMethodRender()}</Typography>
					</div>
				</div>
			</Card>
		</Box>
	);
};
