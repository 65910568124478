import { Alert } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addWeeks, format, isToday, isWeekend, parseISO, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AvailablePaymentMethod, PaymentType } from '../../../../enums/paymentForm';
import { dateFormats } from '../../../../services/constants/formats';
import { nextBusinessDate } from '../../../../services/helpers/date.helpers';
import { setHolidayAlertOpen, setPaymentDate } from '../../../../store/features/paymentForm/paymentFormSlice';
import { RootState } from '../../../../store/rootReducer';
import { ButtonOversize } from '../../../ButtonOversize/buttonOversize';
import { DatePickerModal } from '../../../Modals/elements/DatePicker/datePicker';
import { Calendar } from '../../../PantheraIcon';
import useStyles from './schedulePaymentForm.styles';

const today = startOfDay(new Date());
const nextBusinessDay = nextBusinessDate(new Date());

type SchedulePaymentFormProps = {
	maxDate?: Date;
};

export const SchedulePaymentForm: React.FC<SchedulePaymentFormProps> = ({ maxDate = addWeeks(today, 3) }) => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const { selectedPaymentDate, holidayAlertOpen, paymentMethod, paymentType } = useSelector(
		(state: RootState) => ({
			selectedPaymentDate: state.paymentForm.paymentDate,
			holidayAlertOpen: state.paymentForm.holidayAlertOpen,
			paymentMethod: state.paymentForm.paymentMethod,
			paymentType: state.paymentForm.paymentType,
		}),
		shallowEqual,
	);

	const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);
	const [minDate, setMinDate] = useState<Date | string>(nextBusinessDay);

	useEffect(() => {
		if (paymentMethod && paymentMethod.Name === AvailablePaymentMethod.CARD && paymentType === PaymentType.SINGLE) {
			setMinDate(today.toISOString());
			dispatch(setPaymentDate(today.toISOString()));
		} else {
			setMinDate(nextBusinessDay.toISOString());
			dispatch(setPaymentDate(nextBusinessDay.toISOString()));
		}
	}, [dispatch, paymentMethod, paymentType]);

	const disableWeekends = (date: MaterialUiPickersDate) => {
		// Allow weekend if payment date is today and CC
		if (isToday(date ?? new Date()) && paymentMethod?.Name === AvailablePaymentMethod.CARD) {
			return false;
		}
		return isWeekend(date ?? new Date());
	};

	const handlePaymentDateOnChange = (newDateString: MaterialUiPickersDate) => {
		dispatch(setHolidayAlertOpen(false));
		const newDate = newDateString as Date;
		if (!isWeekend(newDate)) {
			dispatch(setPaymentDate(newDate.toISOString()));
		} else if (isToday(newDate) && paymentMethod?.Name === AvailablePaymentMethod.CARD) {
			dispatch(setPaymentDate(newDate.toISOString()));
		} else {
			dispatch(setHolidayAlertOpen(true));
			dispatch(setPaymentDate(nextBusinessDate(newDate).toISOString()));
		}
		setDatePickerModalOpen(false);
	};

	return (
		<div className={styles.root}>
			{holidayAlertOpen && (
				<Alert className={styles.alert} severity="warning" onClose={() => dispatch(setHolidayAlertOpen(false))}>
					Date selected falls on a weekend/holiday. Payment date has been updated to the next business date
				</Alert>
			)}
			<ButtonOversize
				className={styles.paymentDateWrapper}
				onClick={() => setDatePickerModalOpen(true)}
				iconLeft={<Calendar className={styles.iconLeft} />}
			>
				<span className={styles.paymentDateLabel}>Payment date</span>
				{format(parseISO(selectedPaymentDate), dateFormats.prettyShort)}
			</ButtonOversize>
			{datePickerModalOpen && (
				<DatePickerModal
					DatePickerProps={{
						disablePast: true,
						maxDate,
						minDate,
						shouldDisableDate: disableWeekends,
					}}
					onChange={handlePaymentDateOnChange}
					today={today}
					value={parseISO(selectedPaymentDate)}
				/>
			)}
		</div>
	);
};
