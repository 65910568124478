import { Box, Container, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { CustomerHeader } from '../../elements/Header/header';
import { HeroTitle } from '../../elements/HeroTitle/heroTitle';
import useStyles from './privacy.styles';
import { CustomerFooter } from '../../elements/Footer/footer';
import { WebRoutes } from '../../../../enums/routerPath';
import { PantheraBreadcrumbs } from '../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { NotifyEmail } from '../../../../enums/notifyEmails';

const breadcrumbs = [
	{
		title: `${window.config.REACT_APP_COMPANY} Home`,
		LinkProps: {
			to: WebRoutes.HOME.path,
		},
	},
	{
		title: 'Privacy policy',
		LinkProps: {
			to: WebRoutes.PRIVACY.path,
		},
	},
];

export const Privacy: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Box className={styles.root}>
			<CustomerHeader className={styles.greyHeader} />
			<HeroTitle title="Privacy Policy" subtitle="Last updated 25/05/2021" />
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
					<Typography variant="h4">Privacy Policy</Typography>
					<Typography variant="body1">
						We are committed to protecting your privacy. This Privacy Policy outlines how we ({window.config.REACT_APP_COMPANY_FULL})
						manage your personal information. In accordance with the Privacy Act 1988 (Cth) and the Australian Privacy Principles.
					</Typography>
					<Typography variant="h4">What kind of personal information do we collect and hold?</Typography>
					<Typography variant="body1">The kinds of personal information that we collect and hold include:</Typography>
					<ul>
						<li>your identity and contact information such as name, date of birth, address, phone number and email address;</li>
						<li>
							information about your accounts and products with us such as the balance, the type of product, creditor/s, repayment
							schedules and history, and communication history;
						</li>
						<li>
							your financial information such as your occupation or business activities, employment status, income, expenses, repayment
							capacity, assets, liabilities and other personal information related to this;
						</li>
						<li>your credit information and credit eligibility information;</li>
						<li>your payment information such as BSB and account number, credit card number or alternative payment method details;</li>
						<li>information about your activities on our websites, portals, social media, live chat or other digital platforms; and</li>
						<li>for employment purposes, details about your experience, skills, qualifications, background and other related matters.</li>
					</ul>
					<Typography variant="body1">
						Inbound and outbound phone calls to and from our call centres are recorded and may be monitored or reviewed for compliance,
						record-keeping and training purposes. We periodically delete call-recordings.
					</Typography>
					<Typography variant="body1">
						As our interactions with you are most likely to relate to your account or product, it is not possible for you to interact with
						us anonymously or using a pseudonym unless the interaction is a general inquiry or does not relate to your account.
					</Typography>
					<Typography variant="h4">Why we collect your personal information?</Typography>
					<Typography variant="body1">
						We collect, hold, use and disclose our personal information for the purpose of efficiently and effectively carrying out our
						functions and activities including:
					</Typography>
					<ul>
						<li>
							acting as an agent for our clients such as banks, insurers, and utility providers to recover defaulted, cancelled and/or
							terminated credit facilities;
						</li>
						<li>providing accounts receivable management services for our clients;</li>
						<li>assisting you with financial solutions such as credit products and services;</li>
						<li>recruiting staff;</li>
						<li>monitoring, developing, improving, and marketing our products and services; and</li>
						<li>other related functions and activities.</li>
					</ul>
					<Typography variant="h4">How we collect your personal information</Typography>
					<Typography variant="body1">We may collect and hold personal information in ways that include:</Typography>
					<ul>
						<li>
							from you, during our interactions with you or when you access or use our online services such as our website, portal or
							social media and through the use of cookies;
						</li>
						<li>
							from your creditors and financial institutions and related service providers when we purchase an account from them, act on
							their behalf as their agent or service provider, are engaged by them to manage or recover an account or provide a service
							relating to this, and are assisting you with a financial solution such as assisting with a loan application or other
							credit product or solution;
						</li>
						<li>from credit reporting bodies or other information providers;</li>
						<li>from third parties that you have authorised to act on your behalf in your interactions with us;</li>
						<li>from other third parties if we are having difficulty locating or contacting you;</li>
						<li>from your employer about your employment and income and;</li>
						<li>from third party information service providers.</li>
					</ul>
					<Typography variant="h4">Using government related identifiers</Typography>
					<Typography variant="body1">
						We do not usually use government related identifiers such as driver’s license unless it is reasonably necessary to identify
						you for the purposes of our general activities such as recovering debt. In the unlikely event that we do use government
						related identifiers, we will not use or disclose this information other than required by law. An example of when we would use
						a government identifier, is when we need to identify you for the purpose of investigating a identify theft dispute.
					</Typography>
					<Typography variant="h4">How we hold safe and secure your personal information</Typography>
					<Typography variant="body1">
						We will take reasonable steps to protect your personal information by storing it in a secure environment. We will also take
						reasonable steps to protect any personal information from misuse, loss and unauthorised access, modification, or disclosure.
					</Typography>
					<Typography variant="h4">Do we disclose your personal information?</Typography>
					<Typography variant="body1">
						Unless you consent or we are otherwise permitted to do so by law, personal information will only be used for the purpose for
						which it was collected or for a related purpose, and in the case of sensitive information, only for a purpose directly related
						to the primary purpose. We may disclose your personal information in accordance with the Privacy Act, including to:
					</Typography>
					<ul>
						<li>our related companies;</li>
						<li>
							our authorised agents, legal advisers, and service providers (such as technology service providers, identity verification
							service providers, bulk mail handling providers, document storage providers, information or data providers and process
							servers
						</li>
						<li>the original credit provider and its authorised agents;</li>
						<li>your authorised representatives;</li>
						<li>courts and tribunals;</li>
						<li>an entity that may acquire an interest in your credit facility;</li>
						<li>
							where we are required to do so by law, such as under the Anti-Money or Laundering and Counter Terrorism Financing Act 2006
							(Cth);
						</li>
						<li>to investors, agents or advisers, or any entity that has interest in our business;</li>
						<li>to your employer, referees, or identity verification services and;</li>
						<li>other persons with your consent.</li>
					</ul>
					<Typography variant="body1">
						We may also disclose certain related personal information to credit reporting bodies. For example, if you have an account with
						our subsidiary Panthera Finance, and you fail to meet your repayment obligations on that account, we may be entitled to
						disclose that information to such a body. In the event that such information is disclosed to a credit reporting body, that
						body may include such information in reports that are subsequently provided to credit providers to assist them in assessing
						your credit worthiness. We disclose information to the following credit reporting bodies:
					</Typography>
					<Typography variant="body1">
						<b>illion</b>
						<br />
						Phone: 13 23 33
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.illion.com.au/" target="_blank">
							www.illion.com.au
						</Link>
					</Typography>
					<Typography variant="body1">
						<b>Experian</b>
						<br />
						Phone: 1300 783 684
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.experian.com.au/" target="_blank">
							www.experian.com.au
						</Link>
					</Typography>
					<Typography variant="body1">
						<b>Equifax</b>
						<br />
						Phone: 13 83 32
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.equifax.com.au/" target="_blank">
							www.equifax.com.au
						</Link>
					</Typography>
					<Typography variant="h4">Overseas disclosure</Typography>
					<Typography variant="body1">
						We may need to disclose certain information to overseas recipients from time to time to perform a range of technology,
						operational and customer service functions and activities for us. We take the necessary steps to protect personal information
						when it is disclosed overseas. We may disclose your personal information to our clients, contractors or service providers in
						overseas countries including (but is not limited to) New Zealand and The Philippines. If we have reason to believe that you
						are located overseas, we may disclose your personal information to our overseas agent in that country to assist us in
						performing our functions and activities.
					</Typography>
					<Typography variant="h4">Direct marketing</Typography>
					<Typography variant="body1">
						If you have an account with our subsidiary Panthera Finance, occasionally we may use your personal information to provide you
						with current information about offers you may find of interest, changes to our organisation, or new products or services being
						offered by our subsidiaries . If you do not wish to receive marketing information, you may at any time contact us to request
						that you no longer receive direct marketing. We will give effect to your request at the earliest possible opportunity free of
						charge.
					</Typography>
					<Typography variant="h4">Updating your personal information</Typography>
					<Typography variant="body1">
						It is important to us that the personal information we hold about you is accurate and up to date. During the course of our
						relationship with you, we may ask you to inform us if any of your personal information has changed.
					</Typography>
					<Typography variant="body1">
						If you wish to make any changes to your personal information, you may contact us or alternatively log in to our customer
						portal to access and update the relevant information.
					</Typography>
					<Typography variant="h4">Accessing your personal information</Typography>
					<Typography variant="body1">
						You can make an access request to us for the personal information we hold about you. We will not charge you a fee for making
						an access request, but we may charge a reasonable fee for supplying access to the information we hold about you. When this
						applies, we will advise you upfront that a fee may apply and the basis for the calculation of the fee.
					</Typography>
					<Typography variant="body1">
						Depending on the type of request that you make, we may respond to your request immediately, otherwise we will respond to you
						as soon as we reasonably can, but within 28 days. If we are unable to meet this timeframe, we will advise you. There may be
						situations where we are unable to provide you with access to your information. If this applies, we will advise you of this and
						the reasons (if we are able to).
					</Typography>
					<Typography variant="h4">Correcting personal information</Typography>
					<Typography variant="body1">
						If any of the personal information we hold about you is incorrect, inaccurate, or out of date, you may request that we correct
						the information. We may need to consult with other organisations as part of our investigation. If we refuse to correct
						information, we will advise you of this and provide you with the reasons for not correcting the information (if we are able
						to).
					</Typography>
					<Typography variant="body1">
						Depending on the type of correction request that you make we may respond to your request immediately, otherwise we will
						respond to you as soon as we reasonably can, but within 28 days. If we are unable to meet this timeframe, we will advise you.
					</Typography>
					<Typography variant="h4">Complaints</Typography>
					<Typography variant="body1">
						If you are dissatisfied with how we have dealt with your personal information, about a breach of the Australian Privacy
						Principles, a registered AAP code that binds us, or about how we deal with complaints, please contact us. We will acknowledge
						your complaint as soon as we reasonably can. We will provide you with a decision on your complaint within 30 days. If we are
						unable to meet this timeframe, we will advise you.
					</Typography>
					<Typography variant="body1">You may contact us by contacting:</Typography>
					<Typography variant="body1">
						<b>Complaints Department</b>
						<br />
						Phone:{' '}
						<Link color="secondary" href={window.config.REACT_APP_CONTACT_COMPLAINT_HREF}>
							{window.config.REACT_APP_CONTACT_COMPLAINT}
						</Link>
						<br />
						Email:{' '}
						<Link color="secondary" href={`mailto:${NotifyEmail.COMPLAINT}`}>
							{NotifyEmail.COMPLAINT}
						</Link>
						<br />
					</Typography>
					<Typography variant="h4">Further Information</Typography>
					<Typography variant="body1">
						You may request further information about the way we manage your personal information by contacting us.
					</Typography>
					<Typography variant="h4">Changes to our privacy policy</Typography>
					<Typography variant="body1">
						We are constantly reviewing all of our policies to ensure that they are up to date with regulatory requirements. As a
						consequence, we may change this privacy policy from time to time or as the need arises.
					</Typography>
					<Typography variant="body1">You may request this privacy policy in an alternative form.</Typography>
				</Spacing>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
