import { AppBar, Button, Container, IconButton, Link, Toolbar, useScrollTrigger } from '@material-ui/core';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';
import { Logo } from '../../../../elements/Branding/Logo';
import { WrapWithLink } from '../../../../elements/WrapWithLink/wrapWithLink';
import { BusinessRoutes, WebRoutes } from '../../../../enums/routerPath';
import { spacingUnits } from '../../../../theme/theme';
import useStyles from './header.styles';
import { LinesStacked } from '../../../../elements/PantheraIcon/LinesStacked';
import { BusinessMobileHeader } from './elements/Mobile/mobileHeader';

type BusinessHeaderProps = {
	className?: string;
};

export const BusinessHeader: React.FC<BusinessHeaderProps> = ({ className }) => {
	const styles = useStyles();
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});
	const [mobileOpen, setMobileOpen] = React.useState(false);

	return (
		<>
			<BusinessMobileHeader isOpen={mobileOpen} onClose={() => setMobileOpen(false)} />
			<AppBar className={clsx(styles.root, className, scrollTrigger ? styles.scrolled : undefined)} position="relative" elevation={0}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<WrapWithLink
							className={styles.logo}
							LinkProps={{
								to: BusinessRoutes.HOME.path,
							}}
						>
							<Logo />
						</WrapWithLink>
						<nav className={clsx(styles.nav, styles.sectionDesktop)}>
							<div className={styles.navLink}>
								<Link component={NavLink} to={BusinessRoutes.INDUSTRIES.path} underline="none" exact>
									Industries
								</Link>
							</div>
							<div className={styles.navLink}>
								<Link component={NavLink} to={BusinessRoutes.OUR_SERVICES.path} underline="none" exact>
									Our Services
								</Link>
							</div>
							<div className={styles.navLink}>
								<Link component={NavLink} to={BusinessRoutes.COMPLIANCE.path} underline="none" exact>
									Compliance
								</Link>
							</div>
							<div className={styles.navLink}>
								<Link component={NavLink} to={BusinessRoutes.CONTACT.path} underline="none" exact>
									Contact
								</Link>
							</div>
						</nav>
						<div className={styles.grow} />
						<div className={styles.sectionDesktop}>
							<Button
								color="primary"
								size="medium"
								variant="outlined"
								component={RouterLink}
								to={WebRoutes.CUSTOMERS.path}
								style={{ marginRight: spacingUnits(2) }}
							>
								Resolve overdue account
							</Button>
							<Button color="secondary" size="medium" variant="contained" href={window.config.REACT_APP_CLIENT_URL}>
								Client Login
							</Button>
						</div>
						<div className={styles.sectionMobile}>
							<Button
								color="secondary"
								size="small"
								variant="contained"
								style={{ marginRight: spacingUnits(0.5) }}
								href={window.config.REACT_APP_CLIENT_URL}
							>
								Client Login
							</Button>
							<IconButton edge="end" color="primary" aria-label="menu" onClick={() => setMobileOpen(true)}>
								<LinesStacked style={{ width: 24 }} />
							</IconButton>
						</div>
					</Toolbar>
				</Container>
			</AppBar>
		</>
	);
};
