import { Box, Button, Card, Divider, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ContactPhone } from '../../../../../elements/ContactPhone/contactPhoneNumber';
import { CurrencyFancyDisplay } from '../../../../../elements/CurrencyFancyDisplay/currencyFancyDisplay';
import { DebtBrandLogo } from '../../../../../elements/DebtBrandLogo/debtBrandLogo';
import { setDebtPanelOpen } from '../../../../../store/features/accountLayout/accountLayoutSlice';
import { RootState } from '../../../../../store/rootReducer';
import { spacingUnits } from '../../../../../theme/theme';
import useStyles from './debtDetailsCard.styles';

type DebtDetailsCardProps = {
	color?: 'default' | 'primary';
	size?: 'small' | 'medium';
	footer?: React.ReactNode;
	details?: React.ReactNode;
	showBalanceSmall?: boolean;
};

export const DebtDetailsCard: React.FC<DebtDetailsCardProps> = ({
	color = 'default',
	size = 'medium',
	footer = undefined,
	details = undefined,
	showBalanceSmall = true,
}) => {
	const dispatch = useDispatch();
	const styles: Record<string, string> = useStyles();

	const { customerDetails, loading } = useSelector(
		(state: RootState) => ({
			customerDetails: state.customer.customerDetails,
			loading: state.customer.loading,
		}),
		shallowEqual,
	);

	const [progress, setProgress] = useState<number>(0);
	useEffect(() => {
		if (customerDetails) {
			const total = customerDetails.paidAmt + customerDetails.outstandingAmt;
			setProgress(Math.floor((customerDetails.paidAmt / total) * 100 + 0.5));
		}
	}, [customerDetails]);

	return (
		<Card className={clsx(styles.root, styles[`cardBg-${color}`])} raised>
			<div className={styles.firstRow}>
				<Paper className={styles.debtBranding} variant="outlined">
					<div className={styles.squareSpacing}>
						{loading ? (
							<Skeleton variant="rect" />
						) : (
							<DebtBrandLogo
								color={color === 'primary' ? 'default' : 'primary'}
								clientDisplayName={customerDetails?.clientName ?? ''}
							/>
						)}
					</div>
				</Paper>
				<div className={styles.debtTitleWrapper}>
					<Typography variant={size === 'small' ? 'subtitle2' : 'h6'}>{loading ? <Skeleton /> : customerDetails?.clientName}</Typography>
					<Typography component="p" variant={size === 'small' ? 'body2' : 'body1'}>
						{loading ? <Skeleton /> : customerDetails?.clientReference}
					</Typography>
				</div>
				{size === 'small' && showBalanceSmall && (
					<>
						<div>
							{loading ? (
								<Skeleton />
							) : (
								<CurrencyFancyDisplay className={styles.currencyDisplay} value={customerDetails?.outstandingAmt ?? 0} />
							)}
							<Typography variant="caption">Outstanding balance</Typography>
						</div>
					</>
				)}
			</div>
			{size === 'medium' && (
				<>
					<Divider />
					<Grid alignItems="flex-start" container justifyContent="flex-start">
						<Grid item xs={6}>
							{loading ? (
								<Skeleton />
							) : (
								<CurrencyFancyDisplay className={styles.currencyDisplay} value={customerDetails?.outstandingAmt ?? 0} />
							)}
							<Typography className={styles.sectionBody} component="p" variant="subtitle2">
								Outstanding balance
							</Typography>
						</Grid>
					</Grid>

					{(customerDetails?.outstandingAmt ?? 0) > 0 && (
						<Box alignItems="center" display="flex" justifyContent="space-between" className={styles.progress}>
							<LinearProgress style={{ marginRight: spacingUnits(1) }} value={progress} variant="determinate" />

							<Box component="span" fontSize="1.2rem" fontWeight="bold" lineHeight={1} mb="1px">
								{progress}%
							</Box>
						</Box>
					)}

					<Grid container justifyContent="flex-end" style={{ marginTop: spacingUnits(1) }}>
						<Button color="inherit" onClick={() => dispatch(setDebtPanelOpen(true))} size="small" variant="outlined">
							View details
						</Button>
					</Grid>
				</>
			)}
			{details && (
				<div className={styles.details}>
					<Divider />
					<div>
						<Typography className={styles.detailsSubtitle}>Email</Typography>
						<Typography className={styles.detailsBody}>
							<a href={`mailto:${window.config.REACT_APP_CONTACT_EMAIL}?Subject=Website%20Contact`}>
								{window.config.REACT_APP_CONTACT_EMAIL}
							</a>
						</Typography>
						<Typography className={styles.detailsSubtitle}>Phone</Typography>
						<Typography className={styles.detailsBody}>{ContactPhone()}</Typography>
						<Typography className={styles.detailsSubtitle}>Reference number</Typography>
						<Typography className={styles.detailsBody}>{customerDetails?.Reference}</Typography>
					</div>
				</div>
			)}
			{footer && <div className={styles.footer}>{footer}</div>}
		</Card>
	);
};
