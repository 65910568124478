import { Box, Button, Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { CurrencyFancyDisplay } from '../../../../../../../elements/CurrencyFancyDisplay/currencyFancyDisplay';
import { SendTransactionReciept } from '../../../../../../../elements/SendTransactonReceipt/sendTransactionReciept';
import { AccountRoutes, PaymentRoutes } from '../../../../../../../enums/routerPath';
import circleCheckHollow from '../../../../../../../static/images/circle-check-hollow.png';
import useStyles from './paymentSuccessCard.styles';

export type PaymentSuccessCardProps = {
	title: React.ReactNode;
	subTitle: React.ReactNode;
	className: string;
	amountPaid: number;
	remaining?: number;
	canSetupArrangement?: boolean;
	showButtons?: boolean;
};

export const PaymentSuccessCard: React.FC<PaymentSuccessCardProps> = ({
	title,
	subTitle,
	className,
	amountPaid,
	remaining,
	canSetupArrangement = true,
	showButtons = true,
}) => {
	const styles = useStyles();
	return (
		<>
			<AssistantCTAHollow subtitle={subTitle} title={title}>
				<Card className={clsx(styles.root, className)} elevation={8}>
					<div className={styles.checkIcon} style={{ backgroundImage: `url(${circleCheckHollow})` }} />
					<div>
						<Typography className={styles.sectionBody} component="p" variant="subtitle2">
							Amount paid
						</Typography>
						<CurrencyFancyDisplay className={styles.currencyDisplay} value={amountPaid} />
						{remaining && (
							<Typography className={styles.sectionBody} component="p" variant="subtitle2">
								with remaining balance ${remaining}
							</Typography>
						)}
					</div>
				</Card>
			</AssistantCTAHollow>
			<SendTransactionReciept />
			{canSetupArrangement && (
				<Typography>
					<Box component="span" fontWeight={500}>
						Would you like to set up a personalised payment plan to pay off the outstanding balance of ${remaining} at your own pace?
					</Box>
				</Typography>
			)}
			{showButtons && (
				<ButtonStack className={styles.buttonStack}>
					{canSetupArrangement && (
						<Button color="secondary" fullWidth size="large" variant="contained" component={Link} to={PaymentRoutes.PAYMENT_PLAN.path}>
							Set up a recurring payment
						</Button>
					)}
					<Button color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
						Go to account overview
					</Button>
				</ButtonStack>
			)}
		</>
	);
};
