import { useTheme, useMediaQuery, Card, Typography, Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { SupportRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { SupportNavigationCard } from '../../elements/SupportNavigationCard/supportNavigationCard';
import useStyles from './supportMain.styles';
import availableAssistance from '../../../../../../static/images/help-centre/icon-available-assistance.png';
import helpfulDebtResources from '../../../../../../static/images/help-centre/icon-helpful-debt-resources.png';
import faq from '../../../../../../static/images/help-centre/icon-faq.png';

const navigationItems = [
	{
		title: 'Helpful debt resources',
		icon: <img alt="" src={availableAssistance} />,
		LinkProps: { to: SupportRoutes.DEBT_RESOURCES.path },
	},
	{
		title: 'Other assistance available',
		icon: <img alt="" src={helpfulDebtResources} />,
		LinkProps: { to: SupportRoutes.OTHER_ASSISTANCE.path },
	},
	{
		title: 'FAQs',
		icon: <img alt="" src={faq} />,
		LinkProps: { to: SupportRoutes.FAQ.path },
	},
];

export const SupportMain: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<main className={styles.container}>
			<Spacing units={screenAtDesktopWidth ? 9 : 4}>
				<Card className={styles.covidCard} elevation={0}>
					{screenAtDesktopWidth && <div className={styles.image} />}
					<div className={styles.content}>
						<Typography variant={screenAtDesktopWidth ? 'h4' : 'h6'}>COVID-19 financial support</Typography>
						<Typography variant="body1">
							If you’re experiencing hardship during COVID-19 please get in touch with our customer support team as we have a range of
							assistance packages to help you.
						</Typography>
					</div>
					<Button className={styles.button} color="secondary" fullWidth variant="contained" component={Link} to={WebRoutes.CONTACT.path}>
						Contact us
					</Button>
				</Card>

				<SupportNavigationCard isInline={screenAtDesktopWidth} items={navigationItems} />

				<Card className={styles.imageCard} raised>
					<figure />
					<Spacing units={2}>
						<Typography variant="h6">Our commitment to you and your responsibility to us</Typography>
						<Typography variant="body1">
							Learn more about how we operate and how we strive to act ethically and with integrity towards our customers.
						</Typography>
						<Button
							className={styles.button}
							color="secondary"
							fullWidth
							variant="contained"
							component={Link}
							to={SupportRoutes.OUR_COMMITMENT.path}
						>
							Learn more
						</Button>
					</Spacing>
				</Card>
			</Spacing>
		</main>
	);
};
