import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

export const YabbrFields: React.FC = () => {
	const { displayName, reference, dob, phoneContacts, emailContacts } = useSelector(
		(state: RootState) => ({
			displayName: state.auth.user?.displayName,
			reference: state.customer.customerDetails?.Reference,
			dob: state.customer.customerDetails?.dob,
			phoneContacts: state.customerPhone.phoneContacts,
			emailContacts: state.customerEmail.emailContacts,
		}),
		shallowEqual,
	);

	useEffect(() => {
		if (displayName) {
			window.yabbr('field:Name', displayName);
		}
	}, [displayName]);

	useEffect(() => {
		if (dob) {
			window.yabbr('field:DOB', dob);
		}
	}, [dob]);

	useEffect(() => {
		if (emailContacts && emailContacts[0]?.ContactDetailNormalized) {
			window.yabbr('field:Email', emailContacts[0].ContactDetailNormalized);
		}
	}, [emailContacts]);

	useEffect(() => {
		if (reference) {
			window.yabbr('field:Reference', reference.toString());
		}
	}, [reference]);

	useEffect(() => {
		if (phoneContacts && phoneContacts[0]?.ContactDetail) {
			window.yabbr('field:Phone', phoneContacts[0].ContactDetail);
		}
	}, [phoneContacts]);

	return null;
};
