import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { CustomerAPI, IBpayDetails, ICustomerBpayGet } from '../types';

// Seperated this slice so it can be persisted

export interface CustomerBpayState {
	bpayDetails: IBpayDetails | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: CustomerBpayState = {
	bpayDetails: undefined,
	loading: false,
	error: undefined,
};

export const customerBpaySlice = createSlice({
	name: 'customerBpay',
	initialState,
	reducers: {
		getBpayDetails(state: CustomerBpayState) {
			state.loading = true;
			state.bpayDetails = undefined;
			state.error = undefined;
		},
		getBpayDetailsSuccess(state: CustomerBpayState, action: PayloadAction<IBpayDetails>) {
			state.loading = false;
			state.bpayDetails = action.payload;
			state.error = undefined;
		},
		getBpayDetailsFailure(state: CustomerBpayState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const { getBpayDetails, getBpayDetailsSuccess, getBpayDetailsFailure } = customerBpaySlice.actions;

export default customerBpaySlice.reducer;

export const fetchBpayDetails = (): AppThunk => async (dispatch, getState) => {
	try {
		if (getState().customerBpay.bpayDetails) return;
		dispatch(getBpayDetails());
		const { data } = await arlApi.get<ICustomerBpayGet>(CustomerAPI.BPAY_DETAILS);
		if (data.success) {
			dispatch(getBpayDetailsSuccess(data.data.bpay));
		} else {
			dispatch(getBpayDetailsFailure(data.message));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(getBpayDetailsFailure(e.response?.data.message));
		} else {
			dispatch(getBpayDetailsFailure('An unknown error occured.'));
		}
	}
};
