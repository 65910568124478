import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { ArrangementAPI, IArrangementActive, IGetArrangementActive } from '../types';

export interface ArrangementActiveState {
	arrangement: IArrangementActive | undefined;
	loading: boolean;
	error: string | undefined;
	isLoaded: boolean;
}

const initialState: ArrangementActiveState = {
	arrangement: undefined,
	loading: false,
	error: undefined,
	isLoaded: false,
};

export const arrangementActiveSlice = createSlice({
	name: 'arrangementActive',
	initialState,
	reducers: {
		getArrangementActive(state: ArrangementActiveState) {
			state.loading = true;
			state.arrangement = undefined;
			state.error = undefined;
			state.isLoaded = false;
		},
		getArrangementActiveSuccess(state: ArrangementActiveState, action: PayloadAction<IArrangementActive | undefined>) {
			state.loading = false;
			state.arrangement = action.payload;
			state.isLoaded = true;
		},
		getArrangementActiveFailure(state: ArrangementActiveState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
			state.isLoaded = true;
		},
		resetArrangement() {
			return initialState;
		},
	},
});

export const { getArrangementActive, getArrangementActiveSuccess, getArrangementActiveFailure, resetArrangement } = arrangementActiveSlice.actions;

export default arrangementActiveSlice.reducer;

export const fetchArrangementActive = (): AppThunk => async (dispatch) => {
	try {
		dispatch(getArrangementActive());
		const { data } = await arlApi.get<IGetArrangementActive>(ArrangementAPI.ARRANGEMENT_ACTIVE);
		if (data.success) {
			dispatch(getArrangementActiveSuccess(data.data.arrangement));
		} else {
			dispatch(getArrangementActiveSuccess(undefined));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(getArrangementActiveFailure(e.response?.data.message));
		} else {
			dispatch(getArrangementActiveFailure('An unknown error occured.'));
		}
	}
};
