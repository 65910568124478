import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PantheraBreadcrumbs } from '../../../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { ExternalLink } from '../../../../../../elements/PantheraIcon/ExternalLink';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { SupportRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import useStyles from './ourCommitment.styles';

const breadcrumbs = [
	{
		title: 'Help',
		LinkProps: {
			to: WebRoutes.SUPPORT.path,
		},
	},
	{
		title: 'Our commitment to you and your responsibility to us',
		LinkProps: {
			to: SupportRoutes.OUR_COMMITMENT.path,
		},
	},
];

export const OurCommitment: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<main className={styles.container}>
			<Spacing className={styles.root} units={3}>
				<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
				<Spacing units={screenAtDesktopWidth ? 5 : 3}>
					<Typography variant="h3">Our Commitment to you and your responsibility to us</Typography>
					<Typography variant="h4">Our commitment to You</Typography>
				</Spacing>
				<ul>
					<li>Be flexible, fair and realistic in our interactions with you</li>
					<li>Be respectful and professional</li>
					<li>Not mislead or deceive you in any way</li>
					<li>Protect your privacy</li>
					<li>Contact you for reasonable a purpose only</li>
				</ul>
				<Typography className={styles.responsibility} variant="h4">
					Your responsibility to Us
				</Typography>
				<Typography variant="body1">As referenced in the ASIC &#38; ACCC Debt Collection Guidelines:</Typography>
				<ul>
					<li>You are legally responsible for paying a debt you legitimately owe</li>
					<li>You should not attempt to avoid the obligation to satisfy any debts incurred</li>
					<li>
						You should promptly contact us when you are experiencing financial difficulties and attempt to negotiate a variation in
						payments or other arrangement
					</li>
					<li>You should be candid about your financial position, including other debts</li>
					<li>Contact you for reasonable a purpose only</li>
				</ul>
				<Typography>
					The ACCC has publicised a guide for dealing with debt collectors and can be accessed here:{' '}
					<a
						className={styles.externalLink}
						href="https://www.accc.gov.au/publications/dealing-with-debt-collectors-your-rights-responsibilities"
						target="_blank"
						rel="noopener noreferrer"
					>
						Australian Competition &#38; Consumer Commission
						<ExternalLink />
					</a>
				</Typography>
			</Spacing>
		</main>
	);
};
