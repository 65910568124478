import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../theme';
import { brandMexicanRed, getThemeStyle, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',

			'& ul': {
				fontSize: getThemeStyle('typographyBody1FontSize'),
				lineHeight: getThemeStyle('typographyBody1LineHeight'),
				[MuiTheme.breakpoints.up('md')]: {
					fontSize: getThemeStyle('typographyBody1DesktopFontSize'),
					lineHeight: getThemeStyle('typographyBody1DesktopLineHeight'),
				},
			},
		},
		greyHeader: {
			backgroundColor: getThemeStyle('palettePrimary500'),
		},
		greyHero: {
			backgroundColor: getThemeStyle('palettePrimary500'),
			'& .MuiTypography-h1': {
				color: getThemeStyle('paletteCommonWhite'),
			},

			'& .MuiTypography-body1': {
				color: getThemeStyle('paletteCommonWhite'),
			},
		},
		row: {
			padding: [[spacingUnits(3), spacingUnits(2.75), spacingUnits(7)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},
		},
		underline: {
			'&:not(:first-of-type)': {
				marginTop: `${spacingUnits(8)}px !important`,
			},
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'flex-start',
			alignItems: 'flex-start',
			textAlign: 'left',

			'&:after': {
				content: '""',
				borderBottom: `3px solid ${brandMexicanRed}`,
				paddingTop: 14,
				width: 80,
			},
		},
	},
	{
		name: 'compliance',
	},
);
