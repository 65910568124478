import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import useStyles from './cTAHeadings.styles';

type CTAHeadingsProps = {
	className?: string;
	title?: string;
	subtitle?: string;
};

export const CTAHeadings: React.FC<CTAHeadingsProps> = ({ className, subtitle, title }) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, className)}>
			{subtitle && (
				<Typography className={styles.ctaSubheading} variant="body1">
					{subtitle}
				</Typography>
			)}
			{title && (
				<Typography className={styles.ctaTitle} variant="h5">
					{title}
				</Typography>
			)}
		</div>
	);
};
