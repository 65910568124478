import React from 'react';

export const HdDocument = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg
		id="Document_1"
		x="0px"
		y="0px"
		viewBox="0 0 485 485"
		style={{
			enableBackground: 'new 0 0 485 485',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<g>
			<g>
				<g>
					<path d="M340.732,0H55v485h375V94.518L340.732,0z M345,26.364L405.1,90H345V26.364z M415,470H70V15h260v90h85V470z" />
					<rect x={102.5} y={191} width={280} height={15} />
					<rect x={102.5} y={141} width={120} height={15} />
					<rect x={102.5} y={241} width={280} height={15} />
					<rect x={102.5} y={291} width={280} height={15} />
					<rect x={102.5} y={341} width={280} height={15} />
					<rect x={102.5} y={391} width={280} height={15} />
				</g>
			</g>
		</g>
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
	</svg>
);
