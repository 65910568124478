import React from 'react';

import useStyles from './tabPanel.styles';

type TabPanelProps = {
	isActive: boolean;
};

export const TabPanel: React.FC<TabPanelProps> = ({ children, isActive }) => {
	const styles = useStyles();

	if (!isActive) return null;

	return <div className={styles.root}>{children}</div>;
};
