import { Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import IMask from 'imask';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { CardType, PaymentMode, PaymentType } from '../../../../enums/paymentForm';
import { PaymentRoutes, WebRoutes } from '../../../../enums/routerPath';
import { PaymentFailedCard } from '../../../../scenes/AccountLayout/scenes/PaymentModules/PaymentFailed/elements/PaymentFailedCard/paymentFailedCard';
import { PaymentSuccessCard } from '../../../../scenes/AccountLayout/scenes/PaymentModules/PaymentSuccess/elements/PaymentSuccessCard/paymentSuccessCard';
import { calculateMerchantFeeStrings, getCardType } from '../../../../services/helpers/paymentForm.helpers';
import { formatMoney } from '../../../../services/helpers/text.helpers';
import secureBadge from '../../../../static/images/branding/secure-badge.png';
import {
	resetPaymentForm,
	resetPaymentFormQuickPay,
	setPaymentCard,
	submitPayNow,
	submitQuickPay,
} from '../../../../store/features/paymentForm/paymentFormSlice';
import { ICard } from '../../../../store/features/paymentForm/types';
import { history } from '../../../../store/history';
import { RootState } from '../../../../store/rootReducer';
import { AssistantCTAHollow } from '../../../AssistantCTAHollow/assistantCTAHollow';
import loadEncryptionScript from '../../../Eway/loadClientEncryption';
import { LockedModal } from '../../../LockedModal/lockedModal';
import { CardPayment } from '../../../PantheraIcon';
import { MaskedPantheraInput } from '../../../PantheraInput/MaskedPantheraInput';
import { PantheraInput } from '../../../PantheraInput/PantheraInput';
import { SpinnerButton } from '../../../SpinnerButton/spinnerButton';
import useStyles from './cardPaymentForm.styles';

type CardPaymentFormProps = {
	className: string;
	autoFocus: boolean;
	quickPay?: boolean;
};

function validateCardNumber(cardNumber: string | null | undefined) {
	let digit;
	if (!cardNumber && cardNumber?.length !== 16) {
		return false;
	}
	// luhn validation
	let checksum = 0;
	for (let i = 2 - (cardNumber.length % 2); i <= cardNumber.length; i += 2) {
		checksum += Number(cardNumber.charAt(i - 1));
	}
	for (let i = (cardNumber.length % 2) + 1; i < cardNumber.length; i += 2) {
		digit = Number(cardNumber.charAt(i - 1)) * 2;
		if (digit < 10) {
			checksum += digit;
		} else {
			checksum += digit - 9;
		}
	}
	if (checksum % 10 !== 0) {
		return false;
	}
	return true;
}

function validateExpiryDate(expiryDate: string | null | undefined) {
	if (!expiryDate) return false;

	const ccExpires = expiryDate.split('/');
	const exMonth = Number(ccExpires[0]);
	const exYear = Number(`20${ccExpires[1]}`);
	const today = new Date();
	const dayAfterExpriy = new Date();

	dayAfterExpriy.setFullYear(exYear, exMonth, 1);
	if (dayAfterExpriy < today || exMonth > 12 || exYear > today.getFullYear() + 10) {
		return false;
	}
	return true;
}

function validateCardType(cardNumber: string | null | undefined) {
	if (!cardNumber) return false;

	const cardRegEx = {
		VISAELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
		VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
		MASTERCARD: /^5[1-5][0-9]{14}$/,
	};

	return Object.values(cardRegEx).filter((x) => x.test(cardNumber)).length > 0;
}

export const cardSchema = (paymentMode?: PaymentMode) =>
	Yup.object().shape({
		nameOnCard: Yup.string().required('Enter Name on card').max(64),
		cardNumber: Yup.string()
			.required('Enter Card number')
			.matches(/^\d{16}$/, 'Card number should be exactly 16 digits')
			.test('validateCardNumber', 'Invalid card number', (value) => validateCardNumber(value))
			.test('validateCardType', 'Only Visa and Master card allowed', (value) => validateCardType(value)),
		expiryDate: Yup.string()
			.required('Enter expiry date')
			.matches(/^\d{2}\/\d{2}$/, 'Expiry date should be exactly MM/YY format')
			.test('validateExpiryDate', 'Invalid expiry date', (value) => validateExpiryDate(value)),
		...(paymentMode === PaymentMode.NOW && {
			cvv: Yup.string()
				.required('Enter CVV number')
				.matches(/^\d{3}$/, 'CVV number should be exactly 3 digits'),
		}),
	});

export const CardPaymentForm: React.FC<CardPaymentFormProps> = ({ autoFocus, className, quickPay = false }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const { user, paymentAmount, merchantFeePercent, paymentType, paymentMode, card, paymentSuccess, paymentFailure, loading } = useSelector(
		(state: RootState) => ({
			user: state.auth.user,
			paymentAmount: state.paymentForm.paymentAmount,
			merchantFeePercent: state.customer.customerDetails?.merchantFeePercent,
			paymentType: state.paymentForm.paymentType,
			paymentMode: state.paymentForm.paymentMode,
			card: state.paymentForm.paymentCard,
			paymentSuccess: state.paymentForm.paymentSuccess,
			paymentFailure: state.paymentForm.paymentFailure,
			loading: state.paymentForm.loading,
		}),
	);

	const [quickPayModalOpen, setQuickPayModalOpen] = useState(false);
	const [total, SetTotal] = React.useState('0');

	useEffect(() => {
		loadEncryptionScript(false, () => {});
	}, []);

	useEffect(() => {
		SetTotal(calculateMerchantFeeStrings(paymentAmount, merchantFeePercent).total);
	}, [paymentAmount, merchantFeePercent]);

	const handleCardSubmit = (paymentCard: ICard) => {
		const payCard = { ...paymentCard, cardTypeID: getCardType(paymentCard.cardNumber) };
		if (payCard.cardNumber) {
			payCard.encryptedCardNumber = window.eCrypt.encryptValue(payCard.cardNumber, window.config.REACT_APP_EWAY_ENCRYPTIONKEY);
		}
		if (payCard.cvv && paymentMode === PaymentMode.NOW) {
			payCard.cvv = window.eCrypt.encryptValue(payCard.cvv, window.config.REACT_APP_EWAY_ENCRYPTIONKEY);
		}

		if (quickPay) {
			dispatch(setPaymentCard(payCard));
			dispatch(
				submitQuickPay({
					amount: Number(paymentAmount),
					cardNumber: paymentCard.cardNumber,
					expiryDate: paymentCard.expiryDate,
					nameOnCard: paymentCard.nameOnCard,
					cvv: paymentCard.cvv,
				}),
			);
		} else if (paymentType === PaymentType.SINGLE && paymentMode === PaymentMode.NOW) {
			dispatch(setPaymentCard(payCard));
			dispatch(
				submitPayNow({
					amount: Number(paymentAmount),
					encryptedCard: payCard.encryptedCardNumber,
					cardExpiry: payCard.expiryDate,
					cardHolderName: payCard.nameOnCard,
					encryptedCvn: payCard.cvv,
				}),
			);
		} else {
			dispatch(setPaymentCard(payCard));
			history.push(PaymentRoutes.PAYMENT_AGREEMENT.path);
		}
	};

	return (
		<>
			<div className={clsx(styles.root, className)}>
				<div className={styles.secureBadge} style={{ backgroundImage: `url(${secureBadge})` }} />
				<Formik
					initialValues={{
						nameOnCard: '',
						cardNumber: '',
						encryptedCardNumber: '',
						expiryDate: '',
						cvv: '',
						cardTypeID: CardType.VISA,
					}}
					validateOnChange={true}
					validateOnBlur={true}
					onSubmit={handleCardSubmit}
					validationSchema={cardSchema(paymentMode)}
				>
					{(props: FormikProps<ICard>) => {
						const { errors, touched, values, setFieldValue, setFieldTouched, isValid, dirty, resetForm } = props;
						return (
							<Form id="cardPaymentForm">
								<PantheraInput
									fullWidth
									errorMessage={errors.nameOnCard}
									InputProps={{
										autoFocus,
										id: 'frmNameCC',
										name: 'ccname',
										'data-cy': 'ccname',
										placeholder: 'Enter Name',
										autoComplete: 'cc-name',
										maxLength: 64,
										value: values.nameOnCard,
										onChange: (e) => {
											setFieldValue('nameOnCard', e.currentTarget.value);
										},
										onBlur: (e) => (e.currentTarget.value.length ? setFieldTouched('nameOnCard', true) : undefined),
									}}
									label="Name on card"
									error={!!errors.nameOnCard && !!touched.nameOnCard}
								/>

								<PantheraInput
									fullWidth
									errorMessage={errors.cardNumber}
									InputProps={{
										id: 'frmCCNum',
										name: 'cardnumber',
										'data-cy': 'cardnumber',
										type: 'tel',
										inputMode: 'numeric',
										placeholder: 'xxxxxxxxxxxxxxxx',
										autoComplete: 'cc-number',
										maxLength: 16,
										value: values.cardNumber,
										onChange: (e) => {
											setFieldValue('cardNumber', e.currentTarget.value);
										},
										onBlur: (e) => setFieldTouched('cardNumber', true),
									}}
									label={
										<>
											Card number <CardPayment className={styles.cardPaymentIcon} />
										</>
									}
									error={!!errors.cardNumber && !!touched.cardNumber}
								/>
								<Grid alignItems="flex-start" className={styles.grid} container direction="row" justifyContent="center" spacing={3}>
									<Grid item xs={6}>
										<MaskedPantheraInput
											fullWidth
											errorMessage={errors.expiryDate}
											error={!!errors.expiryDate && !!touched.expiryDate}
											label="Expiry"
											mask="MM{/}`YY"
											lazy={false}
											overwrite={true}
											blocks={{
												MM: {
													mask: IMask.MaskedRange,
													placeholderChar: 'M',
													from: 1,
													to: 12,
													maxLength: 2,
												},
												YY: {
													mask: IMask.MaskedRange,
													placeholderChar: 'Y',
													from: 0,
													to: 99,
													maxLength: 2,
												},
											}}
											onAccept={(value: string) => {
												setFieldValue('expiryDate', value);
											}}
											InputProps={{
												type: 'tel',
												id: 'frmCCExp',
												name: 'cc-exp',
												'data-cy': 'cc-exp',
												placeholder: 'MM/YY',
												autoComplete: 'cc-exp',
												value: values.expiryDate,
												onBlur: () => setFieldTouched('expiryDate', true),
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										{paymentMode === PaymentMode.NOW && (
											<PantheraInput
												fullWidth
												errorMessage={errors.cvv}
												InputProps={{
													id: 'frmCCCVC',
													name: 'cvc',
													'data-cy': 'cvc',
													type: 'tel',
													inputMode: 'numeric',
													autoComplete: 'cc-csc',
													maxLength: 3,
													placeholder: 'Enter CVV',
													value: values.cvv,
													onChange: (e) => {
														setFieldValue('cvv', e.currentTarget.value);
													},
													onBlur: (e) => setFieldTouched('cvv', true),
												}}
												label="CVV"
												error={!!errors.cvv && !!touched.cvv}
											/>
										)}
									</Grid>
								</Grid>
								{quickPay && (
									<Button
										data-cy="payNow"
										className={styles.confirmButton}
										color="secondary"
										fullWidth
										variant="contained"
										disabled={!(user && user.referenceNumber) || Number(paymentAmount) <= 0 || !(isValid && dirty)}
										onClick={() => {
											if (paymentSuccess || paymentFailure) {
												dispatch(resetPaymentFormQuickPay());
											}
											if (isValid) {
												setQuickPayModalOpen(true);
											}
										}}
									>
										Pay now
									</Button>
								)}
								{quickPayModalOpen && (
									<LockedModal
										className={styles.root}
										showCloseButton
										open={true}
										onClose={() => {
											if (paymentSuccess) {
												resetForm();
												dispatch(resetPaymentForm());
											}
											setQuickPayModalOpen(false);
										}}
									>
										{!paymentSuccess && !paymentFailure && (
											<>
												<AssistantCTAHollow
													title="Confirmation"
													subtitle={`You are about to process a payment of ${formatMoney(
														Number(total),
													)} for reference number ${user?.referenceNumber}, click continue to proceed.`}
												/>
												<SpinnerButton
													data-cy="confirmPayment"
													color="secondary"
													fullWidth
													variant="contained"
													form="cardPaymentForm"
													type="submit"
													loading={loading}
												>
													Continue
												</SpinnerButton>
											</>
										)}
										{paymentSuccess && !loading && (
											<>
												<PaymentSuccessCard
													title="Thanks for your payment"
													subTitle="Logging in is the easiest way to manage your account. When you log in, you’ll be able to set up a personalised payment plan so that you can get on top of your finances at your own pace."
													className="paymentSuccessCard"
													amountPaid={Number(paymentSuccess.paymentAmount)}
													showButtons={false}
												/>
												<Button
													data-cy="btnLogin"
													color="secondary"
													fullWidth
													size="large"
													variant="contained"
													component={Link}
													to={WebRoutes.HOME.path}
												>
													Log in
												</Button>
											</>
										)}
										{paymentFailure && !loading && (
											<>
												<PaymentFailedCard
													title="Payment Unsuccessful"
													subTitle={paymentFailure.errors.join('. ') || ''}
													paymentAmount={paymentFailure.paymentAmount}
													last4Digits={card?.cardNumber.substring(12) || ''}
													cardTypeID={card?.cardTypeID}
													actions={
														<Button
															data-cy="btnTryAgain"
															color="secondary"
															fullWidth
															size="large"
															variant="contained"
															onClick={() => setQuickPayModalOpen(false)}
														>
															Try again
														</Button>
													}
												/>
											</>
										)}
									</LockedModal>
								)}
							</Form>
						);
					}}
				</Formik>
			</div>
		</>
	);
};
