import { Card, Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebsiteWorkflow } from '../../../../../enums/websiteWorkflow';
import { resetPaymentFormSuccess } from '../../../../../store/features/paymentForm/paymentFormSlice';
import { RootState } from '../../../../../store/rootReducer';
import { DashboardLoader } from '../../../elements/DashboardLoader/dashboardLoader';
import { PaymentSuccessCard } from './elements/PaymentSuccessCard/paymentSuccessCard';
import useStyles from './paymentSuccess.styles';

export const PaymentSuccess: React.FC = () => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const { customerDetailsLoaded, isPIF, websiteWorkflow, amountPaid, inArrrangementPIF, outstandingAmount, ccPIFOnly } = useSelector(
		(state: RootState) => ({
			customerDetailsLoaded: state.customer.isLoaded,
			isPIF: (state.customer.customerDetails?.outstandingAmt ?? 0) <= 0,
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			amountPaid: state.paymentForm.paymentSuccess?.paymentAmount,
			inArrrangementPIF: state.paymentForm.paymentSuccess?.inArrangement,
			outstandingAmount: state.customer.customerDetails?.outstandingAmt,
			ccPIFOnly: state.paymentMethod.ccPIFOnly,
		}),
	);

	// Reset payment form
	useEffect(() => {
		dispatch(resetPaymentFormSuccess());
	}, [dispatch]);

	useEffect(() => {
		document.getElementById('bodyContent')?.classList.add('whiteBackground-sm');
		return () => document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm');
	});

	return (
		<>
			{!customerDetailsLoaded && <DashboardLoader />}
			{customerDetailsLoaded && isPIF && (
				<>
					<Hidden smDown>
						<Card className={styles.card} elevation={8}>
							<PaymentSuccessCard
								title="Congratulations, your account has been paid in full!"
								subTitle={
									<>
										{inArrrangementPIF && (
											<span style={{ fontWeight: 500 }}>Your recurring payment plan has now been cancelled. </span>
										)}
									</>
								}
								className="paymentSuccessCard"
								amountPaid={Number(amountPaid)}
								canSetupArrangement={false}
							/>
						</Card>
					</Hidden>
					<Hidden mdUp>
						<PaymentSuccessCard
							title="Congratulations, your account has been paid in full!"
							subTitle={
								<>
									{inArrrangementPIF && (
										<span style={{ fontWeight: 500 }}>Your recurring payment plan has now been cancelled. </span>
									)}
								</>
							}
							className="paymentSuccessCard"
							amountPaid={Number(amountPaid)}
							canSetupArrangement={false}
						/>
					</Hidden>
				</>
			)}
			{customerDetailsLoaded && !isPIF && (
				<>
					<Hidden smDown>
						<Card className={styles.card} elevation={8}>
							<PaymentSuccessCard
								title="Thanks for your payment"
								subTitle=""
								className="paymentSuccessCard"
								amountPaid={Number(amountPaid)}
								remaining={(outstandingAmount ?? 0) > 0 ? Number(outstandingAmount) : undefined}
								canSetupArrangement={websiteWorkflow !== WebsiteWorkflow.PAYING && !ccPIFOnly && (outstandingAmount ?? 0) > 0}
							/>
						</Card>
					</Hidden>
					<Hidden mdUp>
						<PaymentSuccessCard
							title="Thanks for your payment"
							subTitle=""
							className="paymentSuccessCard"
							amountPaid={Number(amountPaid)}
							remaining={(outstandingAmount ?? 0) > 0 ? Number(outstandingAmount) : undefined}
							canSetupArrangement={websiteWorkflow !== WebsiteWorkflow.PAYING && !ccPIFOnly && (outstandingAmount ?? 0) > 0}
						/>
					</Hidden>
				</>
			)}
		</>
	);
};
