import { Card, Hidden, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import useStyles from './helpfulLinkCard.styles';

type HelpfulLinkCardProps = {
	className?: string;
	icon?: React.ReactNode;
	link?: string;
	onVisit?: () => void;
	text: string;
	title: string;
};

const externalPattern = /^((http|https|mailto|tel|sms):)/;

export const HelpfulLinkCard: React.FC<HelpfulLinkCardProps> = ({ children, className, icon, link, text, title, onVisit, ...props }) => {
	const styles = useStyles();
	const isExternalLink = link && externalPattern.test(link);
	return (
		<Card className={clsx(styles.root, className)} elevation={0} {...props}>
			<Spacing units={2}>
				<div>
					{icon}
					<Hidden mdUp>
						<Typography variant="h6">{title}</Typography>
					</Hidden>
				</div>
				<Spacing className={styles.content} units={2}>
					<Hidden smDown>
						<Typography variant="h6">{title}</Typography>
					</Hidden>
					<Typography variant="body1">{text}</Typography>
					{children}
				</Spacing>
				{isExternalLink && (
					<Button color="secondary" fullWidth size="medium" variant="contained" component="a" href={link} target="_blank">
						Visit
					</Button>
				)}
				{link && !isExternalLink && (
					<Button color="secondary" fullWidth size="medium" variant="contained" component={Link} to={link}>
						Visit
					</Button>
				)}
				{onVisit && (
					<Button color="secondary" fullWidth size="medium" variant="contained" onClick={onVisit}>
						Visit
					</Button>
				)}
			</Spacing>
		</Card>
	);
};
