import React from 'react';

export const CreditCardsColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<defs>
			<path d="M0 0.0002L63.303 0.0002 63.303 58.0752 0 58.0752z" id="path-1" />
		</defs>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<path d="M12 41l5-4 1 7-5 2-1-5z" fill="#ffcccc" />
			<g fill="#ffcccc" transform="translate(50 53)">
				<circle cx="3" cy="3" r="3" />
				<circle cx="8" cy="3" r="3" />
			</g>
			<path d="M32 29L55 15 58 20 39 31 32 31z" fill="#ffcccc" />
			<g transform="translate(8 11)">
				<mask fill="#fff" id="mask-2">
					<use xlinkHref="#path-1" />
				</mask>
				<path
					d="M15.684 50.233h21.479v-3.182H15.684v3.182zm0-7.84h16.251V39.21H15.684v3.182zm44.437 10.17a2.332 2.332 0 01-2.33 2.33H13.354a2.333 2.333 0 01-2.331-2.33V23.81a2.333 2.333 0 012.331-2.33h44.437a2.332 2.332 0 012.33 2.33v28.753zM42.449 3.491c.35-.203.743-.31 1.139-.31a2.271 2.271 0 011.831.929L21.047 18.297h-4.032L42.449 3.491zm5.574 5.131l-16.62 9.676h-4.034L47.015 6.861l1.008 1.761zm1.581 2.761l3.959 6.915H37.725l11.879-6.915zM7.842 23.81v2.177l-4.48 2.608c-.441-1.065-.04-2.325.972-2.914l3.514-2.046a2.536 2.536 0 00-.006.175zm0 8.206l-1.909 1.112-1.009-1.762 2.918-1.699v2.35zm0 4.447l-.329-.575.329-.19v.765zm49.949-18.165h-.561L48.333 2.76A5.449 5.449 0 0044.997.186 5.501 5.501 0 0043.586 0l-.182.003a5.453 5.453 0 00-2.556.74L2.733 22.932A5.536 5.536 0 000 27.732c.004.947.257 1.886.731 2.713l7.111 12.421v9.696a5.518 5.518 0 005.512 5.512h44.437a5.519 5.519 0 005.512-5.512V23.81a5.52 5.52 0 00-5.512-5.512zm-8.005 28.754a2.575 2.575 0 01-1.517-.499 1.581 1.581 0 00-.934-.303c-.338 0-.661.105-.933.303a2.58 2.58 0 01-1.518.5c-1.375 0-2.493-1.046-2.493-2.332 0-1.285 1.118-2.33 2.493-2.33.542 0 1.067.173 1.517.498.273.2.596.304.934.304.338 0 .661-.105.933-.304a2.586 2.586 0 011.518-.498c1.375 0 2.493 1.045 2.493 2.33 0 1.286-1.118 2.331-2.493 2.331zm0-7.842c-.858 0-1.681.185-2.451.55a5.673 5.673 0 00-2.451-.55c-3.129 0-5.674 2.472-5.674 5.511 0 3.04 2.545 5.512 5.674 5.512.858 0 1.681-.185 2.451-.55.77.365 1.593.55 2.451.55 3.129 0 5.674-2.472 5.674-5.512 0-3.039-2.545-5.51-5.674-5.51z"
					fill="#cc0000"
					mask="url(#mask-2)"
				/>
			</g>
		</g>
	</svg>
);
