/* eslint-disable no-nested-ternary */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { LandingLoader } from '../../elements/LandingLoader/landingLoader';
import { WebRoutes } from '../../enums/routerPath';
import { WebsiteWorkflow } from '../../enums/websiteWorkflow';
import { RootState } from '../../store/rootReducer';
import { IPageProps, Page } from '../page';

export interface IPaymentAmountRouteProps extends RouteProps {
	redirectPath?: string;
}

export const PaymentPlanRoute: React.FC<IPaymentAmountRouteProps & IPageProps> = ({ title, component, redirectPath, ...rest }) => {
	const { websiteWorkflow, websiteWorkflowLoaded } = useSelector(
		(state: RootState) => ({
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			websiteWorkflowLoaded: state.customer.isLoaded,
		}),
		shallowEqual,
	);

	return (
		<Route
			{...rest}
			render={(props) =>
				!websiteWorkflowLoaded || !websiteWorkflow ? (
					<LandingLoader />
				) : websiteWorkflowLoaded && websiteWorkflow !== WebsiteWorkflow.PAYING ? (
					<Page title={title} {...rest} component={component} />
				) : (
					<Redirect to={redirectPath ?? WebRoutes.ACCOUNT.path} />
				)
			}
		/>
	);
};
