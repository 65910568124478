import { Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { LockedModal } from '../../../../../../elements/LockedModal/lockedModal';
import { PantheraBreadcrumbs } from '../../../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { HdMedicalFolder, HdClipboardMagnify, ExternalLink } from '../../../../../../elements/PantheraIcon';
import { HdCreditCards } from '../../../../../../elements/PantheraIcon/HDCreditCards';
import { HdMoneyTransactions } from '../../../../../../elements/PantheraIcon/HdMoneyTransactions';
import { HdPiggyBank } from '../../../../../../elements/PantheraIcon/HdPiggyBank';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { WebRoutes, SupportRoutes } from '../../../../../../enums/routerPath';
import { Page } from '../../../../../../routes/page';
import { HelpfulLinkCard } from '../../elements/HelpfulLinkCard/helpfulLinkCard';
import useStyles from './debtResources.styles';

const breadcrumbs = [
	{
		title: 'Help',
		LinkProps: {
			to: WebRoutes.SUPPORT.path,
		},
	},
	{
		title: 'Helpful debt resources',
		LinkProps: {
			to: SupportRoutes.DEBT_RESOURCES.path,
		},
	},
];

const company = window.config.REACT_APP_COMPANY;

export const DebtResources: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	const [showCreditRecordModal, setShowCreditRecordModal] = useState(false);
	const [showOmbudsmanModal, setShowOmbudsmanModal] = useState(false);
	return (
		<main className={styles.container}>
			<Spacing className={styles.root} units={3}>
				<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
				<Spacing units={screenAtDesktopWidth ? 5 : 3}>
					<Typography variant="h3">Helpful debt resources</Typography>
					<Typography variant="h4">Debt help</Typography>
				</Spacing>
				<Spacing units={screenAtDesktopWidth ? 5 : 3}>
					<Typography variant="body1">
						{company} will work with you to arrange payment in full or a suitable payment arrangement for your debts.
						<br />
						<br />
						Contacting us now can help us work with you to reduce the burdens of your debt by organising settlements and payment
						arrangements.
						<br />
						<br />
						Once you contact us with all of your relevant details, our highly trained collectors will look at your individual situation
						and tailor a suitable solution that will assist you to begin dealing with your overdue accounts.
					</Typography>

					<Typography variant="h4">Helpful links</Typography>
				</Spacing>
				<HelpfulLinkCard
					icon={<HdCreditCards />}
					text="If your debts are getting out of control or you are struggling to make ends meet, it's important to act quickly. Help is available."
					title="Dealing with debt"
					link="https://moneysmart.gov.au/managing-debt"
				/>
				<HelpfulLinkCard
					icon={<HdPiggyBank />}
					text="Managing your money can pay off in more ways than one. Learn how to stay on top of your bills, start saving money and aim towards financial success."
					title="Managing your money"
					link="https://moneysmart.gov.au/budgeting"
				/>
				<HelpfulLinkCard
					icon={<HdMoneyTransactions />}
					text="Use a budget planner to work out where your money is going, calculate your expenses and start setting your financial goals."
					title="Budget planner"
					link="https://moneysmart.gov.au/budgeting/budget-planner"
				/>
				<HelpfulLinkCard
					icon={<HdMedicalFolder />}
					text="Financial counselling is a free service offered by community organisations and some government agencies. Financial counsellors can help you solve your money problems."
					title="Financial counselling"
					link="https://moneysmart.gov.au/managing-debt/financial-counselling"
				/>
				<HelpfulLinkCard
					icon={<HdClipboardMagnify />}
					text="Utilising the services of Equifax (previously known as Veda), your file is a tool credit providers use to help them ascertain your financial reliability."
					title="Your credit record"
					onVisit={() => setShowCreditRecordModal(true)}
				/>
				<HelpfulLinkCard
					icon={<Page />}
					text="Generally all queries or complaint should be directed to the appropriate company to consider first.  If you are unhappy with the process or outcome, the following ombudsman schemes and industry bodies may be of use."
					title="Relevant ombudsman schemes"
					onVisit={() => setShowOmbudsmanModal(true)}
				/>
				<Typography className={styles.thingsToAvoid} variant="h4">
					Things to avoid
				</Typography>
				<Typography variant="body1">
					There are an increasing number of advertisements for companies that say they can help you solve your debt problems. You should be
					wary when using these services as they may not prioritise your problems first. They often provide greater financial benefit to the
					company offering them. Read more about this at the{' '}
					<a
						className={styles.externalLink}
						href="https://www.financialcounsellingaustralia.org.au/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Financial Counselling Australia
						<ExternalLink />
					</a>{' '}
					website.
				</Typography>
			</Spacing>
			<LockedModal onClose={() => setShowCreditRecordModal(false)} open={showCreditRecordModal} showCloseButton>
				<Spacing units={3}>
					<Typography variant="h5">Your credit record</Typography>
					<Typography variant="body1">
						<b>illion</b>
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.illion.com.au/">
							www.illion.com.au
						</Link>
					</Typography>
					<Typography variant="body1">
						<b>Experian</b>
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.experian.com.au/">
							www.experian.com.au
						</Link>
					</Typography>
					<Typography variant="body1">
						<b>Equifax</b>
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.equifax.com.au/">
							www.equifax.com.au
						</Link>
					</Typography>
				</Spacing>
			</LockedModal>
			<LockedModal onClose={() => setShowOmbudsmanModal(false)} open={showOmbudsmanModal} showCloseButton>
				<Spacing units={3} pb={5}>
					<Typography variant="h5">Relevant ombudsman schemes</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Australia Financial Complaints Authority (AFCA)</b>
						<br />
						Dispute resolution for credit, finance and loans, insurance, banking deposits and payments, investments and financial advice
						and superannuation
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.afca.org.au/">
							www.afca.org.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Office of the Australian Information Commissioner (OAIC)</b>
						<br />
						The independent national regulator for privacy and freedom of information
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.oaic.gov.au/">
							www.oaic.gov.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Telecommunications Industry Ombudsman (TIO)</b>
						<br />
						Provide a fair, independent and accessible external dispute resolution service for residential consumers and small businesses
						who have an unresolved complaint about their phone or internet service
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.tio.com.au/">
							www.tio.com.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Energy and Water Ombudsman Victoria (EWOV)</b>
						<br />
						Independent and impartial dispute resolution service for complaints about energy and water issues in Victoria
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.ewov.com.au/">
							www.ewov.com.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Energy and Water Ombudsman Queensland (EWOQ)</b>
						<br />
						Independent and impartial dispute resolution service for complaints about energy and water issues in Queensland
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.ewoq.com.au/">
							www.ewoq.com.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Energy and Water Ombudsman Western Australia (EWOWA)</b>
						<br />
						Independent and impartial dispute resolution service for complaints about energy and water issues in Western Australia
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.ombudsman.wa.gov.au/ewowa/">
							www.ombudsman.wa.gov.au/ewowa
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Energy and Water Ombudsman South Australia (EWOSA)</b>
						<br />
						Independent and impartial dispute resolution service for complaints about energy and water issues in South Australia
						<br />
						Website:{' '}
						<Link color="primary" href="https://ewosa.com.au/">
							www.ewosa.com.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Energy and Water Ombudsman New South Wales (EWONSW)</b>
						<br />
						Independent and impartial dispute resolution service for complaints about energy and water issues in New South Wales
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.ewon.com.au/">
							www.ewon.com.au
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>Australian Collectors & Debt Buyers Association (ACDBA)</b>
						<br />
						Promotes the Code of Practice for good behaviours, Standards and Compliance for all member businesses
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.acdba.com/index.php/code-of-practice/">
							www.acdba.com/index.php/code-of-practice
						</Link>
					</Typography>
					<Typography variant="body1" className={styles.schemes}>
						<b>The Australian Banking Association (ABA)</b>
						<br />
						Advocates for a strong, competitive and innovative banking industry that delivers excellent and equitable outcomes for
						customers
						<br />
						Website:{' '}
						<Link color="primary" href="https://www.ausbanking.org.au/">
							www.ausbanking.org.au/
						</Link>
					</Typography>
				</Spacing>
			</LockedModal>
		</main>
	);
};
