/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { PaymentFrequency } from '../../../enums/paymentForm';
import { PeriodicFrequencyID } from '../../../enums/periodicFrequency';
import { IGetPaymentFrequency } from './types';

export const oneOffFrequency: IGetPaymentFrequency = {
	PeriodicFrequencyID: PeriodicFrequencyID.ONE_OFF,
	Name: PaymentFrequency.ONE_OFF,
	IntervalDays: 0,
	MonthlyWeighting: 1,
	PeriodsPerAnnum: 1,
};

const frequencies: IGetPaymentFrequency[] = [
	oneOffFrequency,
	{
		PeriodicFrequencyID: PeriodicFrequencyID.WEEKLY,
		Name: PaymentFrequency.WEEKLY,
		IntervalDays: 7,
		MonthlyWeighting: 4.3333,
		PeriodsPerAnnum: 52,
	},
	{
		PeriodicFrequencyID: PeriodicFrequencyID.FORTNIGHTLY,
		Name: PaymentFrequency.FORTNIGHTLY,
		IntervalDays: 14,
		MonthlyWeighting: 2.1667,
		PeriodsPerAnnum: 26,
	},
	{
		PeriodicFrequencyID: PeriodicFrequencyID.MONTHLY,
		Name: PaymentFrequency.MONTHLY,
		IntervalDays: 0,
		MonthlyWeighting: 1,
		PeriodsPerAnnum: 12,
	},
];

export interface PaymentFrequencyState {
	frequencies: IGetPaymentFrequency[];
}

const initialState: PaymentFrequencyState = {
	frequencies,
};

export const paymentFrequencySlice = createSlice({
	name: 'paymentFrequency',
	initialState,
	reducers: {},
});

export default paymentFrequencySlice.reducer;
