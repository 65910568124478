import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
	{
		root: {
			display: 'block',
			textDecoration: 'none',
		},
	},
	{ name: 'wrapWithLink' },
);
