import React from 'react';

export const HdCreditCards = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<defs>
			<path d="M0 0.974L75.7163832 0.974 75.7163832 55.9999 0 55.9999z" id="path-1" />
		</defs>
		<g fillRule="evenodd" stroke="none" strokeWidth="1">
			<g transform="translate(2 12)">
				<path d="M7.333 26.7081L19.54 26.7081 19.54 24.2641 7.333 24.2641z" />
				<path d="M7.333 34.0411L25.651 34.0411 25.651 31.5971 7.333 31.5971z" />
				<path d="M7.333 41.3741L51.303 41.3741 51.303 38.9301 7.333 38.9301z" />
				<path d="M7.333 48.6931L14.666 48.6931 14.666 46.2491 7.333 46.2491z" />
				<path d="M19.54 48.6931L26.873 48.6931 26.873 46.2491 19.54 46.2491z" />
				<path d="M31.762 48.6931L39.096 48.6931 39.096 46.2491 31.762 46.2491z" />
				<g transform="translate(0 .026)">
					<path d="M50.08 30.349a2.455 2.455 0 01-2.444-2.445 2.456 2.456 0 012.444-2.445 2.455 2.455 0 012.445 2.445 2.455 2.455 0 01-2.444 2.445m-4.264-.09c-.209.06-.416.09-.626.09a2.455 2.455 0 01-2.444-2.445 2.456 2.456 0 012.444-2.445c.21 0 .433.046.627.09a4.83 4.83 0 00-.627 2.355c0 .864.24 1.654.627 2.355m4.263-7.244c-.896 0-1.715.253-2.43.67a4.872 4.872 0 00-2.46-.67 4.89 4.89 0 00-4.888 4.89 4.89 4.89 0 004.889 4.887c.88 0 1.714-.238 2.459-.67a4.785 4.785 0 002.43.67 4.89 4.89 0 004.888-4.888 4.892 4.892 0 00-4.889-4.889" />
					<g>
						<mask fill="#fff" id="mask-2">
							<use xlinkHref="#path-1" />
						</mask>
						<path
							d="M73.228 21.227l-.73 2.325-11.492-3.622c-.313-1.714-1.803-3.011-3.592-3.011h-5.947L18.915 6.635l.73-2.325a1.228 1.228 0 011.535-.791l51.243 16.173c1.029.268.895 1.222.805 1.535zm-2.951 9.315l-9.211-2.906v-5.127l10.686 3.368-1.475 4.665zM63.66 51.514c-.195.626-.911.999-1.536.79l-1.058-.328V30.199l8.48 2.684-5.886 18.63zm-5.024.82h-.014c0 .67-.552 1.222-1.223 1.222H3.666c-.67 0-1.221-.552-1.221-1.222V20.57c0-.671.551-1.222 1.221-1.222h53.748c.67 0 1.222.55 1.222 1.222v31.763zM15.978 15.95l3.056.968H15.68l.298-.968zm2.206-6.991l25.175 7.944H27.127L16.708 13.61l1.476-4.65zm54.984 8.407L21.925 1.18c-.357-.12-3.219-1.058-4.591 2.384l-4.217 13.34H3.666C1.639 16.919 0 18.559 0 20.57v31.763A3.664 3.664 0 003.666 56h53.748c1.282 0 2.4-.655 3.055-1.654 0 0 1.655.462 2.028.462a3.632 3.632 0 003.487-2.564l9.569-30.287c.298-.924.507-3.815-2.385-4.59z"
							fill="#cc0000"
							mask="url(#mask-2)"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
