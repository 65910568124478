import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../../../../theme/theme';

export default makeStyles(
	{
		assistantHollow: {
			marginBottom: spacingUnits(5),
		},
		addNewButton: {
			backgroundColor: `${getThemeStyle('paletteCommonLightWhite')} !important`,

			'& > svg': {
				fill: getThemeStyle('palettePrimary500'),
				marginLeft: 'auto',
				transform: 'rotate(45deg)',
				width: 22,
			},
		},
		submitButton: {
			marginTop: spacingUnits(2),
		},
	},
	{ name: 'summaryLockedModal' },
);
