import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, inputMaxWidth, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $secureBadge': {
				height: 26,
				width: 92,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				marginBottom: spacingUnits(2.5),
			},
			'& $cardPaymentIcon': {
				height: 18,
				marginLeft: 'auto',
			},
			'& $cvvIcon': {
				height: 18,
				marginLeft: 'auto',
				fill: getThemeStyle('palettePrimary400'),
			},
			'& $grid': {
				maxWidth: inputMaxWidth + spacingUnits(3),
			},
			'& $confirmButton': {
				marginTop: spacingUnits(1),
			},
			'& $brandmark': {
				width: 360,
				maxWidth: '100%',
				top: -80,
				left: '50%',
				right: 'unset',
				transform: 'translate(-50%, -50%)',
			},
		},
		secureBadge: {},
		cardPaymentIcon: {},
		cvvIcon: {},
		grid: {},
		confirmButton: {},
		brandmark: {},
	},
	{ name: 'cardPaymentForm' },
);
