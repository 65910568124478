/* eslint-disable react/no-array-index-key */
import { Breadcrumbs, Typography } from '@material-ui/core';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import { WrapWithLink } from '../WrapWithLink/wrapWithLink';
import useStyles from './pantheraBreadcrumbs.styles';

type PantheraBreadcrumbsItemProps = {
	title?: string;
	LinkProps: LinkProps;
};

type PantheraBreadcrumbsProps = {
	isCollapsed?: boolean;
	items: PantheraBreadcrumbsItemProps[];
	lastIsWrapped?: boolean;
};

export const PantheraBreadcrumbs: React.FC<PantheraBreadcrumbsProps> = ({ isCollapsed, items, lastIsWrapped, ...props }) => {
	const styles = useStyles();
	if (isCollapsed) {
		return (
			<Breadcrumbs {...props}>
				{items.length > 1 && (
					<WrapWithLink className={styles.breadcrumbLink} LinkProps={items[items.length - 2].LinkProps}>
						<Typography component="span" variant="body1">
							&lt; Back to {items[items.length - 2].title}
						</Typography>
					</WrapWithLink>
				)}
			</Breadcrumbs>
		);
	}
	return (
		<Breadcrumbs separator=">" {...props}>
			{items.map((item, index) => {
				if (index === items.length - 1 && !lastIsWrapped) {
					return (
						<Typography key={index} component="span" variant="body1">
							{item.title}
						</Typography>
					);
				}
				return (
					<WrapWithLink key={index} className={styles.breadcrumbLink} LinkProps={item.LinkProps}>
						<Typography component="span" variant="body1">
							{item.title}
						</Typography>
					</WrapWithLink>
				);
			})}
		</Breadcrumbs>
	);
};
