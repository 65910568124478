import { makeStyles } from '@material-ui/core/styles';
import { fontFamilyDisplay, getThemeStyle, inputMaxWidth, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			maxWidth: inputMaxWidth,
			minWidth: 0,
			'& $alert': {
				marginBottom: spacingUnits(2),
			},
			'& $paymentDateWrapper': {
				maxWidth: inputMaxWidth,
				'& button': {
					fontSize: '1.6rem',
					fontWeight: 'normal',
				},
				'& $paymentDateLabel': {
					flexGrow: 1,
					color: getThemeStyle('paletteTertiary200'),
					fontFamily: fontFamilyDisplay,
					fontWeight: 500,
					textAlign: 'left',
				},
			},
		},
		paymentDateWrapper: {},
		paymentDateLabel: {},
		alert: {},
		iconLeft: {
			width: 24,
			fill: getThemeStyle('paletteGrey500'),
			marginRight: spacingUnits(1),
		},
	},
	{ name: 'schedulePaymentForm' },
);
