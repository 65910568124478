/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import { IMaskMixin } from 'react-imask';
import { PantheraInput, PantheraInputProps } from './PantheraInput';

type MaskedPantheraInputProps = {
	inputRef?: React.Ref<any>;
	fullWidth?: boolean | undefined;
	style?: React.CSSProperties;
} & PantheraInputProps;

// TODO type this up properly or wait for types to be available

export const MaskedPantheraInput = IMaskMixin(({ inputRef, ...props }: MaskedPantheraInputProps) => {
	return <PantheraInput {...props} InputRef={inputRef} />;
});
