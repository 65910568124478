import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { fetchBpayDetails } from '../../customer/bpay/customerBpaySlice';
import { IArrangementSchedule, IGetArrangementSchedule, PaymentScheduleAPI } from './types';

export interface PaymentScheduleState {
	schedule: IArrangementSchedule[] | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: PaymentScheduleState = {
	schedule: undefined,
	loading: false,
	error: undefined,
};

export const PaymentScheduleSlice = createSlice({
	name: 'paymentSchedule',
	initialState,
	reducers: {
		getPaymentSchedules(state: PaymentScheduleState) {
			state.loading = true;
			state.schedule = undefined;
			state.error = undefined;
		},
		getPaymentSchedulesSuccess(state: PaymentScheduleState, action: PayloadAction<IArrangementSchedule[] | undefined>) {
			state.loading = false;
			state.schedule = action.payload;
		},
		getPaymentSchedulesFailure(state: PaymentScheduleState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		resetPaymentSchedules() {
			return initialState;
		},
	},
});

export const { getPaymentSchedules, getPaymentSchedulesSuccess, getPaymentSchedulesFailure, resetPaymentSchedules } = PaymentScheduleSlice.actions;

export default PaymentScheduleSlice.reducer;

export const fetchPaymentSchedules = (): AppThunk => async (dispatch) => {
	try {
		dispatch(getPaymentSchedules());
		const { data } = await arlApi.get<IGetArrangementSchedule>(PaymentScheduleAPI.SCHEDULES);
		if (data.success) {
			if (data.data.schedule.some((schedule) => schedule.method === 'BPAY')) {
				dispatch(fetchBpayDetails());
			}
			dispatch(getPaymentSchedulesSuccess(data.data.schedule));
		} else {
			dispatch(getPaymentSchedulesFailure('An unknown error occured.'));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(getPaymentSchedulesFailure(e.response?.data.message));
		} else {
			dispatch(getPaymentSchedulesFailure('An unknown error occured.'));
		}
	}
};
