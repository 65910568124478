/* eslint-disable import/no-cycle */
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { isTestMode } from '../..';
import { BodyContent } from '../../elements/BodyContent/bodyContent';
import { AccountRoutes, PaymentRoutes, WebRoutes } from '../../enums/routerPath';
import { WebsiteWorkflow } from '../../enums/websiteWorkflow';
import { ArrangementSuccessRoute } from '../../routes/guards/arrangementSuccess.guard';
import { PaymentAgreementRoute } from '../../routes/guards/paymentAgreement.guard';
import { PaymentAmountRoute } from '../../routes/guards/paymentAmount.guard';
import { PaymentFailedRoute } from '../../routes/guards/paymentFailed.guard';
import { PaymentPlanRoute } from '../../routes/guards/paymentPlan.guard';
import { PaymentSuccessRoute } from '../../routes/guards/paymentSuccess.guard';
import { Page } from '../../routes/page';
import { inPaymentFlow } from '../../routes/routes';
import { setSnackbarOpen } from '../../store/features/accountLayout/accountLayoutSlice';
import { fetchCustomerDetails } from '../../store/features/customer/details/customerSlice';
import { RootState } from '../../store/rootReducer';
import { DebtDetailsPanelComponent } from './elements/DebtDetailsPanel/debtDetailsPanel';
import { HeaderLoggedIn } from './elements/HeaderLoggedIn/headerLoggedIn';
import { SessionTimeout } from './elements/SessionTimeout/sessionTimeout';
import { YabbrFields } from './elements/YabbrFields/yabbrFields';
import { AccountSettings } from './scenes/AccountSettings/accountSettings';
import { ContactGeneral } from './scenes/ContactGeneral/contactGeneral';
import { Hardship } from './scenes/Hardship/hardship';
import { Landing } from './scenes/Landing/landing';
import { Overview } from './scenes/Overview/overview';
import { PaymentArrangement } from './scenes/PaymentArrangement/paymentArrangement';
import { ArrangementFailed } from './scenes/PaymentModules/ArrangementFailed/arrangementFailed';
import { ArrangementSetup } from './scenes/PaymentModules/ArrangementSetup/arrangementSetup';
import { CreditCard } from './scenes/PaymentModules/CreditCard/creditCard';
import { DirectDebit } from './scenes/PaymentModules/DirectDebit/directDebit';
import { PaymentAgreement } from './scenes/PaymentModules/PaymentAgreement/paymentAgreement';
import { PaymentFailed } from './scenes/PaymentModules/PaymentFailed/paymentFailed';
import { PaymentSuccess } from './scenes/PaymentModules/PaymentSuccess/paymentSuccess';
import { SinglePayment } from './scenes/SinglePayment/singlePayment';

const BlockedPIFRoutes = [PaymentRoutes.SINGLE_PAYMENT.path, PaymentRoutes.PAYMENT_PLAN.path];

export const AccountLayout = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const { showDebtPanel, debtPanelOpen, websiteWorkflow, outstandingAmt, snackbarInfo } = useSelector(
		(state: RootState) => ({
			debtPanelOpen: state.accountLayout.debtPanelOpen,
			showDebtPanel: state.accountLayout.showDebtPanel,
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			outstandingAmt: state.customer.customerDetails?.outstandingAmt,
			snackbarInfo: state.accountLayout.snackbarInfo,
		}),
		shallowEqual,
	);

	useEffect(() => {
		dispatch(fetchCustomerDetails());
	}, [dispatch]);

	// Prevent blocked statuses from viewing any page other than the landing
	React.useEffect(() => {
		if (
			location.pathname !== WebRoutes.ACCOUNT.path &&
			(websiteWorkflow === WebsiteWorkflow.BLOCKED || (websiteWorkflow === WebsiteWorkflow.PAYING && (outstandingAmt ?? 0) <= 0))
		) {
			// PIF can go to payment success failed pages, route guards will still catch if they can view those pages
			if (
				(outstandingAmt ?? 0) <= 0 &&
				inPaymentFlow(location.pathname) &&
				!BlockedPIFRoutes.some((blockedRoutes) => location.pathname === blockedRoutes)
			) {
				return;
			}
			history.replace(WebRoutes.ACCOUNT.path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, websiteWorkflow, outstandingAmt]);

	return (
		<>
			{!isTestMode && <YabbrFields />}
			<SessionTimeout />
			<Snackbar open={!!snackbarInfo} autoHideDuration={6000} onClose={() => dispatch(setSnackbarOpen(undefined))}>
				<Alert variant="filled" severity={snackbarInfo?.severity}>
					{snackbarInfo?.message}
				</Alert>
			</Snackbar>
			<HeaderLoggedIn />
			{showDebtPanel && <DebtDetailsPanelComponent openForMobile={debtPanelOpen} />}

			<BodyContent>
				<Route
					render={() => (
						<>
							<Switch>
								<Page path={WebRoutes.ACCOUNT.path} title={WebRoutes.ACCOUNT.title} exact component={Landing} />
								<Page path={AccountRoutes.OVERVIEW.path} title={AccountRoutes.OVERVIEW.title} exact component={Overview} />
								<Page
									path={AccountRoutes.ACCOUNT_SETTINGS.path}
									title={AccountRoutes.ACCOUNT_SETTINGS.title}
									exact
									component={AccountSettings}
								/>{' '}
								<PaymentAmountRoute
									path={PaymentRoutes.PAYMENT_CC.path}
									title={PaymentRoutes.PAYMENT_CC.title}
									exact
									component={CreditCard}
								/>
								<PaymentAmountRoute
									path={PaymentRoutes.PAYMENT_DD.path}
									title={PaymentRoutes.PAYMENT_DD.title}
									exact
									component={DirectDebit}
								/>
								<Page
									path={PaymentRoutes.SINGLE_PAYMENT.path}
									title={PaymentRoutes.SINGLE_PAYMENT.title}
									exact
									component={SinglePayment}
								/>
								<PaymentPlanRoute
									path={PaymentRoutes.PAYMENT_PLAN.path}
									title={PaymentRoutes.PAYMENT_PLAN.title}
									exact
									component={PaymentArrangement}
								/>
								<PaymentSuccessRoute
									path={PaymentRoutes.PAYMENT_SUCCESS.path}
									title={PaymentRoutes.PAYMENT_SUCCESS.title}
									exact
									component={PaymentSuccess}
								/>
								<PaymentFailedRoute
									path={PaymentRoutes.PAYMENT_FAILED.path}
									title={PaymentRoutes.PAYMENT_FAILED.title}
									exact
									component={PaymentFailed}
								/>
								<PaymentAgreementRoute
									path={PaymentRoutes.PAYMENT_AGREEMENT.path}
									title={PaymentRoutes.PAYMENT_AGREEMENT.title}
									exact
									component={PaymentAgreement}
								/>
								<ArrangementSuccessRoute
									path={PaymentRoutes.ARRANGEMENT_SUCCESS.path}
									title={PaymentRoutes.ARRANGEMENT_SUCCESS.title}
									exact
									component={ArrangementSetup}
								/>
								<Page
									path={PaymentRoutes.ARRANGEMENT_FAILED.path}
									title={PaymentRoutes.ARRANGEMENT_FAILED.title}
									exact
									component={ArrangementFailed}
								/>
								<Page
									path={AccountRoutes.CONTACT_GENERAL.path}
									title={AccountRoutes.CONTACT_GENERAL.title}
									exact
									component={ContactGeneral}
								/>
								<Page path={AccountRoutes.HARDSHIP.path} title={AccountRoutes.HARDSHIP.title} exact component={Hardship} />
								<Redirect to={WebRoutes.FOUR_OH_FOUR.path} />
							</Switch>
						</>
					)}
				/>
			</BodyContent>
		</>
	);
};
