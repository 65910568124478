import { darken, makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../../../theme';
import { getStrictDimensionsForDiameter, getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			backgroundColor: darken(subtleBackgroundColor, 0.025),
			padding: [[spacingUnits(3), spacingUnits(2)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(4), spacingUnits(3)]],
			},

			'& > div': {
				[MuiTheme.breakpoints.up('md')]: {
					display: 'flex',
					alignItems: 'center',
				},
				'& $content': {
					marginTop: spacingUnits(1),
					[MuiTheme.breakpoints.up('md')]: {
						margin: [[0, spacingUnits(5), 0, spacingUnits(1.5)]],
					},
					'& p': {
						fontSize: '1.6rem',
					},
				},
				'& > div:first-of-type': {
					display: 'flex',
					alignItems: 'center',

					'& > svg': {
						...getStrictDimensionsForDiameter(48),
						fill: getThemeStyle('paletteSecondary700'),
						marginRight: spacingUnits(1.5),
					},

					'& > h6': {
						fontWeight: 500,
					},
				},

				'& > .MuiButton-root': {
					padding: [[spacingUnits(1.5), spacingUnits(2)]],
					[MuiTheme.breakpoints.up('md')]: {
						maxWidth: 100,
						marginLeft: 'auto',
						marginTop: 0,
						marginRight: spacingUnits(1),
					},
				},
			},
		},
		content: {},
	},
	{
		name: 'helpfulLinkCard',
	},
);
