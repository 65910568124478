import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			justifyContent: 'flex-end',
			marginBottom: spacingUnits(5),
			textAlign: 'center',

			'& $button': {
				justifyContent: 'flex-start',
				marginBottom: spacingUnits(2),
			},

			'& $text': {
				paddingTop: spacingUnits(1),

				'& $strong strong': {
					fontWeight: 500,
				},
			},
		},
		button: {},
		text: {},
		strong: {},
	},
	{ name: 'sendTransactionReciept' },
);
