import { Box, Button, Card, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { AssistantCTAHollow } from '../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../elements/ButtonStack/buttonStack';
import { ComparisonCard } from '../../../../../../elements/ComparisonCard/comparisonCard';
import { Timer, TimerFast } from '../../../../../../elements/PantheraIcon';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { PeriodicFrequencyID } from '../../../../../../enums/periodicFrequency';
import { calcSavings } from '../../../../../../services/helpers/calculation.helpers';
import { convertPaymentFreqToString, pluralize } from '../../../../../../services/helpers/text.helpers';
import useStyles from './savingsOffer.styles';

type LowValueOfferProps = {
	outstanding: number;
	paymentFrequencyID: PeriodicFrequencyID;
	paymentAmount: string;
	onContinue: (acceptedAmount: number) => void;
	onBack: () => void;
};

export const SavingsOffer: React.FC<LowValueOfferProps> = ({ outstanding, paymentFrequencyID, paymentAmount, onContinue, onBack }) => {
	const styles = useStyles();

	const offer = calcSavings(outstanding, parseFloat(paymentAmount), paymentFrequencyID, 0, undefined, true);

	const savings = `SAVE ${pluralize(offer?.monthsSavings ?? 0, 'MTH', 'S')}*`;
	const orgSpecs = [{ content: 'Takes longer' }];
	const newSpecs = [{ content: 'Finish sooner', hasCheckmark: true }];

	const hollow = (
		<AssistantCTAHollow
			subtitle={`You'll save upto ${pluralize(offer?.monthsSavings ?? 0, 'month', 's')} if you increase your payments slightly.`}
			title="Want to finish sooner?"
		/>
	);

	const comparisonCard = (
		<ComparisonCard
			className={styles.comparisonCard}
			items={[
				{
					amount: {
						value: offer?.reofferAmt ?? 0,
						description: convertPaymentFreqToString(paymentFrequencyID),
					},
					icon: <TimerFast />,
					identifier: {
						color: 'secondary',
						content: savings,
					},
					note: `Over ${pluralize(offer?.reofferMonths ?? 0, 'month', 's')}*`,
					specifications: newSpecs,
				},
				{
					amount: {
						value: parseFloat(paymentAmount),
						description: convertPaymentFreqToString(paymentFrequencyID),
					},
					icon: <Timer />,
					identifier: {
						color: 'primary',
						content: 'Original plan',
					},
					note: `Over ${pluralize(offer?.baseMonths ?? 0, 'month', 's')}*`,
					specifications: orgSpecs,
				},
			]}
		/>
	);

	const Buttons = (
		<>
			<Button color="secondary" fullWidth size="large" variant="contained" onClick={() => onContinue(offer?.reofferAmt ?? 0)}>
				Increase amount and finish sooner
			</Button>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => onContinue(parseFloat(paymentAmount))}>
				Continue with original offer
			</Button>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={onBack}>
				Back
			</Button>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					<div className={styles.content}>
						{hollow}
						{comparisonCard}
						<Typography align="center" className={styles.offerLength} variant="body2">
							*Offer lengths may vary over time.
						</Typography>
						<Box pt={5}>
							<Spacing units={2}>{Buttons}</Spacing>
						</Box>
					</div>
				</Card>
			</Hidden>
			<Hidden mdUp>
				{hollow}
				{comparisonCard}
				<Typography align="center" className={styles.offerLength} variant="body2">
					*Offer lengths may vary over time.
				</Typography>
				<ButtonStack>{Buttons}</ButtonStack>
			</Hidden>
		</>
	);
};
