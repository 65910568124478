const loadScript = (url: string, callback: () => void) => {
	const existingScript = document.getElementById('grecaptcha-script');

	if (!existingScript) {
		const script: HTMLScriptElement = document.createElement('script') as HTMLScriptElement;
		script.type = 'text/javascript';
		script.id = 'grecaptcha-script';
		script.src = url;
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	}

	if (existingScript && callback) callback();
};

export default loadScript;
