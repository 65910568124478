import { makeStyles } from '@material-ui/core';
import { getThemeStyle, spacingUnits } from '../../../../../../../../theme/theme';

const borderColor = getThemeStyle('paletteTertiary100');

export default makeStyles(({ breakpoints }) => ({
	root: {
		'& .MuiAccordion-root': {
			borderRadius: '0',
			borderTop: `1px solid ${borderColor}`,
			boxShadow: 'none',

			'&::before': {
				display: 'none',
				zIndex: -1,
			},

			'&:last-of-type': {
				borderBottom: `1px solid ${borderColor}`,
			},

			'&.Mui-expanded': {
				margin: 0,
			},

			'& > .MuiAccordionSummary-root': {
				border: 'none',
				padding: spacingUnits(3),

				[breakpoints.down('sm')]: {
					paddingLeft: 0,
					paddingRight: 0,
				},

				'&.Mui-expanded': {
					minHeight: 0,
				},

				'& > .MuiAccordionSummary-content': {
					margin: 0,

					'& > h6': {
						fontWeight: 500,
					},
				},

				'& .MuiAccordionSummary-expandIcon': {
					border: 'none',
					marginRight: 0,
					padding: 0,

					'& svg': {
						fill: getThemeStyle('palettePrimary400'),
						width: spacingUnits(3),
					},

					'& svg.iconClose': {
						display: 'none',
					},

					'& svg.iconExpand': {
						display: 'block',
					},

					'&.Mui-expanded svg.iconClose': {
						display: 'block',
					},

					'&.Mui-expanded svg.iconExpand': {
						display: 'none',
					},
				},
			},

			'& .MuiAccordionDetails-root': {
				padding: [[0, spacingUnits(7), spacingUnits(5), spacingUnits(3)]],
				flexDirection: 'row',
				flexWrap: 'wrap',

				[breakpoints.down('sm')]: {
					paddingLeft: 0,
					paddingRight: 0,
				},

				'& a': {
					color: getThemeStyle('palettePrimary500'),
					fontWeight: 500,
					display: 'inline-flex',
				},

				'& .MuiLink-root': {
					textDecoration: 'underline',
					cursor: 'pointer',
				},

				'& .MuiTypography-body1': {
					minWidth: '100%',
					display: 'block',
					width: '100%',
				},
			},
		},
	},
}));
