import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LandingLoader } from '../../../../../../elements/LandingLoader/landingLoader';
import { AvailablePaymentMethod } from '../../../../../../enums/paymentForm';
import { fetchArrangementActive } from '../../../../../../store/features/arrangement/active/arrangementActiveSlice';
import { RootState } from '../../../../../../store/rootReducer';
import { DashboardLoader } from '../../../DashboardLoader/dashboardLoader';
import { DebtStatusLogicProps } from '../../types';
import { PayingGeneralDashboard } from './General/payingGeneralDashboard';
import { PayingGeneralLanding } from './General/payingGeneralLanding';
import { PendingAccountClosure } from './PendingClosure/pendingClosure';

export const PayingWorkflow: React.FC<DebtStatusLogicProps> = ({ isDashboard }) => {
	const dispatch = useDispatch();

	const { activeArrangement, activeArrangementLoaded, outstandingAmount, ccAllowed, bpayAllowed } = useSelector(
		(state: RootState) => ({
			activeArrangement: state.arrangementActive.arrangement,
			activeArrangementLoaded: state.arrangementActive.isLoaded,
			outstandingAmount: state.customer.customerDetails?.outstandingAmt ?? 0,
			ccAllowed: state.customer.customerDetails?.IsExtranet_DebtorAllowCreditCardPayment,
			bpayAllowed: state.paymentMethod.methods.some((method) => method.Name === AvailablePaymentMethod.BPAY),
		}),
		shallowEqual,
	);
	// Fetch on load
	useEffect(() => {
		dispatch(fetchArrangementActive());
	}, [dispatch]);

	let content: JSX.Element | null = null;

	if (!activeArrangementLoaded) {
		content = isDashboard ? <DashboardLoader /> : <LandingLoader />;
	} else if (activeArrangementLoaded && outstandingAmount <= 0) {
		content = <PendingAccountClosure />;
	} else if (activeArrangement) {
		content = isDashboard ? (
			<PayingGeneralDashboard arrangement={activeArrangement} />
		) : (
			<PayingGeneralLanding extraPaymentsAllowed={ccAllowed || bpayAllowed} ccAllowed={!!ccAllowed} />
		);
	}

	return content;
};
