// api/pay/methods
export enum AvailablePaymentMethod {
	CARD = 'Debit/Credit Card',
	BPAY = 'BPay',
	DIRECT = 'Direct Debit',
}

export enum AllPaymentMethods {
	CARD = 'Debit/Credit Card',
	BPAY = 'BPay',
	DIRECT = 'Direct Debit',
	DEPOSIT = 'Deposit',
	EXTERNAL = 'Client Payment',
	OTHER = 'Other',
}

export enum PaymentMode {
	NOW = 'NOW',
	SCHEDULED = 'SCHEDULED',
}

export enum PaymentType {
	SINGLE = 'SINGLE',
	ARRANGEMENT = 'ARRANGEMENT',
}

export enum PaymentStep {
	PAYMENT_METHOD = 1,
	PAYMENT_DATE = 2,
	PAYMENT_AMOUNT = 3,
	PAYMENT_OFFER = 4,
}

export enum PaymentOfferStep {
	PAYMENT_AMOUNT = 1,
	PAYMENT_OFFER = 2,
	PAYMENT_CONTACT = 3,
}

// api/pay/frequency
export enum PaymentFrequency {
	ONE_OFF = 'One Off PIF',
	MONTHLY = 'Monthly',
	WEEKLY = 'Weekly',
	FORTNIGHTLY = 'Fortnightly',
}

export enum NegotiationModal {
	CANT_AFFORD = 1,
	WE_ACCEPT = 3,
}

export enum NegotiationOffer {
	LOW = 1,
	ALMOST = 2,
	SAVINGS = 3,
}

export enum CardType {
	VISA = 1,
	MASTER = 2,
}
