import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {},
		avatar: {
			margin: [[0, 'auto', spacingUnits(3)]],
		},
		title: {
			width: '40%',
			marginBottom: spacingUnits(1.5),
		},
	},
	{ name: 'paLoader' },
);
