import { Typography, Box, Button, Grid, Hidden, InputLabel, InputAdornment, Snackbar } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import Alert from '@material-ui/lab/Alert/Alert';
import { ButtonGroup } from '../../../ButtonGroup/buttonGroup';

import useStyles from './paymentAmountForm.styles';
import { PantheraInput } from '../../../PantheraInput/PantheraInput';
import {
	calculateMerchantFeeStrings,
	calculatePercent,
	PaymentAmountButton,
	PaymentFrequencyButton,
} from '../../../../services/helpers/paymentForm.helpers';
import { spacingUnits } from '../../../../theme/theme';
import { IGetPaymentFrequency } from '../../../../store/features/paymentFrequency/types';
import { formatMoney } from '../../../../services/helpers/text.helpers';

const numberInputButtons = [
	{
		title: '1',
		value: 1,
	},
	{
		title: '2',
		value: 2,
	},
	{
		title: '3',
		value: 3,
	},
	{
		title: '4',
		value: 4,
	},
	{
		title: '5',
		value: 5,
	},
	{
		title: '6',
		value: 6,
	},
	{
		title: '7',
		value: 7,
	},
	{
		title: '8',
		value: 8,
	},
	{
		title: '9',
		value: 9,
	},
	{
		title: '.',
		value: 'DECIMAL',
	},
	{
		title: '0',
		value: 0,
	},
	{
		title: 'Del',
		value: 'DELETE',
	},
];

// Have to use paymentAmount as string due to keypad input
type PaymentAmountFormProps = {
	className?: string;
	paymentAmountButtons?: PaymentAmountButton[];
	paymentFrequencyButtons?: PaymentFrequencyButton[];
	paymentAmount: string;
	paymentFrequency?: IGetPaymentFrequency;
	outstandingAmount: number;
	showPercent?: boolean;
	merchantFeePercent?: number | undefined;
	updatePaymentAmount: (paymentAmount: string) => void;
	updatePaymentFrequency?: (paymentFrequencyID: number) => void;
	onEnterKeyPress: () => void;
	paymentAmountError?: string;
	isDisabled?: boolean;
	showKeypadOnSm?: boolean;
};

export const PaymentAmountForm: React.FC<PaymentAmountFormProps> = ({
	className,
	paymentAmountButtons,
	paymentFrequencyButtons,
	paymentAmount = '',
	paymentFrequency,
	outstandingAmount = 0,
	showPercent = true,
	merchantFeePercent,
	updatePaymentAmount,
	updatePaymentFrequency,
	onEnterKeyPress,
	paymentAmountError,
	isDisabled = false,
	showKeypadOnSm = true,
}) => {
	const styles = useStyles();
	const [paymentPercent, SetPaymentPercent] = React.useState('0');
	const [merchantFeeTotal, SetMerchantFeeTotal] = React.useState('0');
	const [snackbarOpen, setSnackbar] = React.useState(false);
	const showNormalInput = showKeypadOnSm ? 'none' : 'block';

	useEffect(() => {
		if (isNaN(outstandingAmount) || isNaN(parseFloat(paymentAmount))) {
			SetPaymentPercent('0');
		} else {
			SetPaymentPercent(
				calculatePercent(paymentAmount, outstandingAmount)
					.toFixed(2)
					.replace(/[.]00$/, ''),
			);
		}
	}, [paymentAmount, outstandingAmount]);

	useEffect(() => {
		SetMerchantFeeTotal(calculateMerchantFeeStrings(paymentAmount, merchantFeePercent).totalFees);
	}, [paymentAmount, merchantFeePercent]);

	useEffect(() => {
		setSnackbar(!!paymentAmountError);
	}, [paymentAmountError]);

	const handleNumberInputOnClick = (value: string | number) => () => {
		if (value === 'DECIMAL') {
			// there can be only one! "."
			if (paymentAmount.indexOf('.') >= 0) {
				return;
			}
			updatePaymentAmount(`${paymentAmount}.`);
			return;
		}
		if (value === 'DELETE') {
			// remove the last digit from the number
			updatePaymentAmount(paymentAmount.slice(0, -1));
			return;
		}
		if (paymentAmount.toString() === '0') {
			updatePaymentAmount(typeof value === 'number' ? value.toString() : (value as string));
			return;
		}
		if (paymentAmount.length >= 3 && paymentAmount.charAt(paymentAmount.length - 3) === '.') {
			// max two decimal places
			return;
		}
		updatePaymentAmount(`${paymentAmount}${value}`);
	};
	return (
		<div className={clsx(styles.root, className)}>
			<>
				<Hidden mdUp>
					<Snackbar open={snackbarOpen}>
						<Alert
							variant="filled"
							severity="error"
							onClose={() => {
								setSnackbar(false);
								updatePaymentAmount(paymentAmount);
							}}
						>
							{paymentAmountError}
						</Alert>
					</Snackbar>
					{showKeypadOnSm && (
						<div className={styles.numberDisplay}>
							<Typography variant="h2">
								<span>$</span>
								{paymentAmount}
							</Typography>
						</div>
					)}
					<Typography className={styles.frequencyDisplay} component="div" variant="subtitle2">
						{paymentFrequency && `per ${paymentFrequency.Name.slice(0, -2).toLowerCase()}`}
						{!paymentFrequency && showPercent && `${paymentPercent}% of amount ${formatMoney(outstandingAmount)}`}
					</Typography>
				</Hidden>
				{(paymentAmountButtons || paymentFrequency) && !isDisabled && (
					<Hidden smDown>
						<InputLabel>{paymentAmountButtons ? 'Quick select amount' : 'Select payment frequency'}</InputLabel>
					</Hidden>
				)}
				{!isDisabled && (
					<Box mx="auto">
						{paymentAmountButtons && (
							<ButtonGroup className={clsx(styles.frequencyButtonsWrapper, className)} mx="auto" size="small">
								{paymentAmountButtons.map((button) => (
									<Button
										key={button.title}
										className={clsx(
											styles.frequencyButton,
											parseFloat(paymentAmount) === parseFloat(button.value.toString()) && styles.frequencyButtonSelected,
										)}
										fullWidth
										variant="contained"
										onClick={() => updatePaymentAmount(button.value.toString())}
									>
										{button.title}
									</Button>
								))}
							</ButtonGroup>
						)}
						{paymentFrequencyButtons && (
							<ButtonGroup className={clsx(styles.frequencyButtonsWrapper, className)} mx="auto" size="small">
								{paymentFrequencyButtons.map((button) => (
									<Button
										key={button.title}
										data-cy={button.title}
										className={clsx(
											styles.frequencyButton,
											paymentFrequency?.PeriodicFrequencyID === button.value && styles.frequencyButtonSelected,
										)}
										fullWidth
										variant="contained"
										onClick={() => (updatePaymentFrequency ? updatePaymentFrequency(button.value) : undefined)}
									>
										{button.title.toString()}
									</Button>
								))}
							</ButtonGroup>
						)}
					</Box>
				)}
				{!isDisabled && showKeypadOnSm && (
					<Hidden mdUp>
						<Grid alignItems="center" container justifyContent="center" spacing={1}>
							{numberInputButtons.map((numberButton) => (
								<Grid key={numberButton.value} item xs={4}>
									<Button
										className={styles.numberButton}
										color="default"
										fullWidth
										onClick={handleNumberInputOnClick(numberButton.value)}
										size="large"
										variant="contained"
										disabled={isDisabled}
									>
										{numberButton.title}
									</Button>
								</Grid>
							))}
						</Grid>
					</Hidden>
				)}
				<Box display={{ xs: showNormalInput, sm: showNormalInput, md: 'block' }}>
					<PantheraInput
						className={styles.paymentInput}
						fullWidth
						errorMessage={paymentAmountError}
						disabled={isDisabled}
						InputProps={{
							onChange: (event) => updatePaymentAmount(event.currentTarget.value),
							onKeyPress: (event) => {
								return event.key === 'Enter' ? onEnterKeyPress() : null;
							},
							onBlur: (event) =>
								updatePaymentAmount(
									parseFloat(event.currentTarget.value)
										.toFixed(2)
										.replace(/[.]00$/, ''),
								),
							required: true,
							type: 'number',
							'data-cy': 'paymentAmount',
							max: outstandingAmount,
							min: 0,
							step: 0.01,
							value: paymentAmount,
						}}
						error={!!paymentAmountError}
						startAdornment={<InputAdornment position="start">$</InputAdornment>}
						label={
							<>
								<span style={{ marginRight: spacingUnits(1) }}>Payment Amount</span>
								<Typography className={styles.frequencyDisplayDesktop} variant="subtitle2">
									{paymentFrequency && `per ${paymentFrequency.Name.slice(0, -2).toLowerCase()}`}
									{!paymentFrequency && showPercent && `${paymentPercent}% of amount ${formatMoney(outstandingAmount)}`}
								</Typography>
							</>
						}
					/>
				</Box>
				{!!merchantFeePercent && (
					<Box style={{ marginTop: spacingUnits(2) }}>
						<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
							<Grid item xs={12} md={5}>
								<PantheraInput
									className={styles.totalInput}
									fullWidth
									InputProps={{
										value: formatMoney(parseFloat(merchantFeeTotal), true, 2),
									}}
									shouldDisplayIndicator={false}
									readOnly={true}
									startAdornment={<InputAdornment position="start">$</InputAdornment>}
									label={
										<>
											<span style={{ marginRight: spacingUnits(1) }}>Credit Card Surcharge</span>
											<Typography className={styles.frequencyDisplayDesktop} variant="subtitle2">
												({merchantFeePercent}%)
											</Typography>
										</>
									}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<PantheraInput
									className={styles.totalInput}
									fullWidth
									InputProps={{
										value: formatMoney(parseFloat(calculateMerchantFeeStrings(paymentAmount, merchantFeePercent).total), true, 2),
									}}
									shouldDisplayIndicator={false}
									readOnly={true}
									startAdornment={<InputAdornment position="start">$</InputAdornment>}
									label="Total Payment Amount"
								/>
							</Grid>
						</Grid>
					</Box>
				)}
			</>
		</div>
	);
};
