/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
import { Box, Button, Card, CardProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { AmountPresenter } from '../AmountPresenter/amountPresenter';
import { CheckThick } from '../PantheraIcon';
import useStyles from './comparisonCard.styles';

type ComparisonCardItemProps = {
	itemHeader?: React.ReactNode;
	amount?: {
		description?: string;
		shouldDisplayDecimals?: boolean;
		value?: number;
	};
	children?: React.ReactNode;
	icon?: React.ReactNode;
	identifier?: { color: 'primary' | 'secondary'; content: React.ReactNode };
	note?: string;
	specifications?: { content: React.ReactNode; hasCheckmark?: boolean }[];
};

type ComparisonCardProps = Omit<CardProps, 'variant'> & {
	className?: string;
	amountDescriptionClassName?: string;
	dividerTop?: string | number;
	center?: boolean;
	amountSize?: 'medium' | 'small';
	footer?: React.ReactNode;
	variant?: 'default' | 'transparent';
	items: ComparisonCardItemProps[];
	isDashboard?: boolean;
};

export const ComparisonCard: React.FC<ComparisonCardProps> = ({
	isDashboard = false,
	amountDescriptionClassName,
	amountSize = 'medium',
	dividerTop,
	footer,
	center = false,
	items,
	variant = 'default',
	...props
}) => {
	const styles = useStyles({ dividerTop, variant, center, isSingle: items.length === 1 });

	const [maxLength, SetMaxLength] = React.useState(0);

	// Checks the number of digits and reduces size an extra step if necessary
	useEffect(() => {
		SetMaxLength(Math.max(...items.map((item) => (item.amount && item.amount.value ? item.amount.value.toFixed(2).length : 1))));
	}, [items, amountSize]);

	return (
		<Box {...props} maxWidth={isDashboard ? 290 : '100%'} mb={isDashboard ? 3 : 0}>
			{({ className }: { className: string }) => (
				<Card {...props} className={clsx(styles.root, className, props.className)} elevation={8}>
					<div className={styles.itemWrapper}>
						{items.map(({ itemHeader, amount, children, icon, identifier, note, specifications }, i) => (
							<React.Fragment key={i}>
								{i > 0 && (
									<div className={styles.divider}>
										<span />
										<div>vs</div>
									</div>
								)}
								<div className={styles.itemOuter}>
									<div key={i} className={clsx(styles.item, identifier && styles.hasIdentifier)}>
										{identifier && (
											<Button
												className={styles.identifier}
												color={identifier.color}
												disableElevation={true}
												disableRipple={true}
												size="small"
												variant={identifier.color === 'secondary' ? 'contained' : 'outlined'}
											>
												{identifier.content}
											</Button>
										)}

										{icon && <div className={styles.iconContainer}>{icon}</div>}

										{itemHeader && <Typography className={clsx(styles.itemHeader)}>{itemHeader}</Typography>}

										{specifications && (
											<Box mb={2}>
												{specifications.map(({ content, hasCheckmark }, i) => (
													<Typography key={i} align="center" className={styles.specification} variant="body2">
														{hasCheckmark && <CheckThick className={styles.checkmark} />}
														{content}
													</Typography>
												))}
											</Box>
										)}

										{amount && amount.value && (
											<AmountPresenter
												amount={amount.value}
												shouldDisplayDecimals={amount.shouldDisplayDecimals}
												size={amountSize}
												dynamicFontSize={true}
												charsLength={maxLength}
											/>
										)}

										{amount && amount.description && (
											<Typography className={clsx(styles.amountDescription, amountDescriptionClassName)}>
												{amount.description}
											</Typography>
										)}

										{note && (
											<Box fontWeight={600} mt={1}>
												{({ className }: { className: string }) => (
													<Typography className={className} variant="body2">
														{note}
													</Typography>
												)}
											</Box>
										)}
										{children}
									</div>
								</div>
							</React.Fragment>
						))}
					</div>
					{footer && <div className={styles.footer}>{footer}</div>}
				</Card>
			)}
		</Box>
	);
};
