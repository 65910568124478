import React from 'react';

export const Logo = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.5 397.49" {...props}>
		<path fillRule="evenodd" clipRule="evenodd" fill="#b11916" d="M0 0h397.49v94.57h-26.08v-68.5H26.07v345.34h345.34v-68.49h26.08v94.57H0V0z" />
		<path
			d="M50.09 273.47H76.8l15.26-35.83h70.17l15.05 35.83h27.56l-65.3-149.46h-24.17L50.09 273.47zm51.51-58.93l25.44-59.36 25.65 59.36H101.6zm122.61 58.93h26.08v-51.73h32.65l36.46 51.73h30.74l-40.07-56.18c20.78-5.93 35.4-20.56 35.4-44.94v-.42c0-12.93-4.45-23.96-12.3-32.01-9.54-9.33-24.17-14.84-42.82-14.84h-66.14v148.39zm26.08-74.83v-49.82h37.95c19.29 0 30.74 8.69 30.74 24.59v.43c0 15.05-11.87 24.8-30.53 24.8h-38.16zm121.12 74.83H475.5v-23.74h-78.02V125.07H371.4v148.4h.01z"
			fill="#333"
		/>
	</svg>
);
