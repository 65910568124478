import { Box, Container, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { CustomerHeader } from '../../elements/Header/header';
import { HeroTitle } from '../../elements/HeroTitle/heroTitle';
import useStyles from './complaints.styles';
import { CustomerFooter } from '../../elements/Footer/footer';
import { WebRoutes } from '../../../../enums/routerPath';
import { PantheraBreadcrumbs } from '../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { Spacing } from '../../../../elements/Spacing/spacing';

const breadcrumbs = [
	{
		title: `${window.config.REACT_APP_COMPANY} Home`,
		LinkProps: {
			to: WebRoutes.HOME.path,
		},
	},
	{
		title: 'Complaints',
		LinkProps: {
			to: WebRoutes.COMPLAINTS.path,
		},
	},
];

export const Complaints: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Box className={styles.root}>
			<CustomerHeader className={styles.greyHeader} />
			<HeroTitle
				title="Complaints"
				subtitle="We hope you will not have cause to do so, but if you have a complaint please follow the guide below."
			/>
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
					<Typography variant="h4">Complaints</Typography>
					<Typography variant="body1">
						{window.config.REACT_APP_COMPANY} has a complaints handling and monitoring process that is designed to comply with the
						Australian Standard to ensure that all complaints received are handled in a fair, efficient and appropriate manner. The
						complaint handling process is regularly reviewed as part of the Quality Management System audit process to ensure it is
						efficient and delivering effective and fair outcomes in a timely manner. To lodge a complaint, please fill out the relevant
						criteria in the form at the bottom of this page.
					</Typography>
					<Typography variant="h6">To be able to resolve your complaint, we will need:</Typography>
					<Typography variant="body1">
						<ul>
							<li>
								All your contact and identification details. This includes your full name, business and/or company name if applicable,
								phone and mobile number/s, email and postal addresses and your {window.config.REACT_APP_COMPANY} reference number.
							</li>
							<li>
								The details of your complaint. This includes the nature of your complaint and the outcome you are seeking. This will
								enable us to fairly and properly undertake an investigation of your complaint on your behalf to reach a resolution
								that’s fair and reasonable.
							</li>
						</ul>
					</Typography>
					<Typography variant="h4">Your Rights and Responsibilities</Typography>
					<Typography variant="body1">
						The complaint handling process is regularly reviewed as part of the Quality Management System audit process to ensure it is
						efficient and delivering effective and fair outcomes in a timely manner.
					</Typography>
					<Typography variant="body1">
						<p>
							<strong>Your rights and responsibilities</strong>
						</p>
						<ul>
							<li>You have the right to make a complaint and to have that complaint handled fairly.</li>
							<li>
								You have a right to have your complaint handled in accordance with the principles of the Australian Standard:
								Complaints handling, ISO 10002-2006, which determines the manner in which {window.config.REACT_APP_COMPANY} will deal
								with your complaint.
							</li>
							<li>
								We will endeavour to do our best to resolve your complaint but if you are still dissatisfied, you can take your
								complaint to ASIC at{' '}
								<Link color="secondary" href="https://asic.gov.au" target="_blank" rel="noreferrer">
									www.asic.gov.au
								</Link>{' '}
								or by calling 1300 302 502 or the Office of the Australian Information Commissioner (OAIC) at{' '}
								<Link color="secondary" href="https://oaic.gov.au" target="_blank" rel="noreferrer">
									www.oaic.gov.au
								</Link>{' '}
								or by calling 1300 363 992 if your complaint relates to Privacy.
							</li>
						</ul>
					</Typography>
					<Typography variant="body1">
						<p>
							<strong>{window.config.REACT_APP_COMPANY}’s obligations and rights</strong>
						</p>
						<ul>
							<li>To use the complaints handling principles to resolve your complaint.</li>
							<li>To resolve all complaints in a fair and equitable matter for both parties.</li>
							<li>
								To take sufficient time to complete an investigation into your complaint and to keep you informed of the expected
								completion date or progress made.
							</li>
							<li>To continuously improve our business and processes.</li>
						</ul>
					</Typography>
					<Typography variant="h4">Commitment &amp; Resources</Typography>
					<Typography variant="body1">We are committed to resolving complaints effectively and efficiently.</Typography>
					<Typography variant="body1">
						<p>
							<strong>Fairness</strong>
							<br />
							We guarantee the complaints handling process will always be undertaken fairly to all parties.
						</p>
						<p>
							<strong>Visibility</strong>
							<br />
							Our commitment to resolving complaints and the process to do so is highly visible within our teams.
						</p>
						<p>
							<strong>Assistance</strong>
							<br />
							If you require assistance to lodge a complaint, {window.config.REACT_APP_COMPANY} will provide the necessary support in
							the formulation and lodgement of your complaint. To lodge a complaint, please fill out the relevant criteria in the
							Complaint form on the{' '}
							<Link component={RouterLink} color="secondary" to={WebRoutes.CONTACT.path}>
								contact page
							</Link>
							.
						</p>
						<p>
							<strong>Responsiveness &amp; Accountability</strong>
							<br />
							All complaints will be dealt with promptly and treated courteously. {window.config.REACT_APP_COMPANY} will advise you of
							the estimated response and resolution time for a complaint. Complaints resolution times are monitored as part of{' '}
							{window.config.REACT_APP_COMPANY}’s management reporting.
						</p>
						<p>
							<strong>Charges</strong>
							<br />
							We do not charge you to lodge a complaint. The {window.config.REACT_APP_COMPANY} complaints handling process is completely
							free.
						</p>
					</Typography>
				</Spacing>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
