import clsx from 'clsx';
import React from 'react';

import { thousands } from '../../services/helpers/text.helpers';
import useStyles from './currencyFancyDisplay.styles';

type CurrencyFancyDisplayProps = {
	className?: string;
	currencySymbol?: string;
	value: number;
};

export const CurrencyFancyDisplay: React.FC<CurrencyFancyDisplayProps> = ({ className, currencySymbol = '$', value }) => {
	const styles = useStyles();
	const wholeValue = thousands(Math.floor(value));
	const decimals = `${Math.round(value * 100)}`.slice(-2);
	return (
		<div className={clsx(styles.root, className)}>
			<span className={styles.adornment}>{currencySymbol}</span>
			<span>{wholeValue}</span>
			<span className={styles.decimals}>.{decimals.length === 1 ? `${decimals}0` : decimals}</span>
		</div>
	);
};
