import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import useStyles from './sendTransactionReciept.styles';
// import { PaperPlane } from '../PantheraIcon/PaperPlane';

type SendTransactionRecieptProps = {
	className?: string;
};

export const SendTransactionReciept: React.FC<SendTransactionRecieptProps> = ({ className, children }) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, className)}>
			{/* <Button
				className={styles.button}
				color="secondary"
				endIcon={<PaperPlane />}
				fullWidth
				size="large"
				variant="contained"
			>
				Send transaction receipt
			</Button> */}
			{children}
			<Typography className={styles.text} variant="body1">
				This transaction will appear on your statement as{' '}
				<Typography className={styles.strong} component="span" variant="body1">
					<strong>{window.config.REACT_APP_COMPANY}</strong>
				</Typography>
			</Typography>
		</div>
	);
};
