import React from 'react';

import { Hidden } from '@material-ui/core';
import { DashboardPayoffOptions } from '../../../../DashboardPayoffOptions/dashboardPayOffOptions';
import { DebtDetailsCard } from '../../../../DebtDetailsPanel/DebtDetailsCard/debtDetailsCard';
import { PAThanksCard } from '../../../../PaymentArrangement/elements/Thanks/card';
import { IArrangementActive } from '../../../../../../../store/features/arrangement/types';
import { NextPaymentCard } from '../../../../../../../elements/NextPaymentCard/nextPaymentCard';
import { AllPaymentMethods } from '../../../../../../../enums/paymentForm';

export type PayingGeneralDashboardProps = {
	arrangement: IArrangementActive;
};

export const PayingGeneralDashboard: React.FC<PayingGeneralDashboardProps> = ({ arrangement }) => {
	return (
		<>
			<Hidden smDown>
				<PAThanksCard />
				<NextPaymentCard
					arrangementID={arrangement.paID}
					amount={arrangement.amount}
					frequency={arrangement.frequency}
					paymentMethod={AllPaymentMethods.CARD}
				/>
				<DashboardPayoffOptions variant="arrangement" />
			</Hidden>
			<Hidden mdUp>
				<DebtDetailsCard color="primary" />
				<PAThanksCard />
				<NextPaymentCard
					arrangementID={arrangement.paID}
					amount={arrangement.amount}
					frequency={arrangement.frequency}
					paymentMethod={AllPaymentMethods.CARD}
				/>
				<DashboardPayoffOptions variant="arrangement" />
			</Hidden>
		</>
	);
};
