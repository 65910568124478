import { makeStyles } from '@material-ui/core/styles';
import { getThemeStyle, spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		icon: {
			flexShrink: 0,
			height: 24,
			width: 24,
			'& svg': {
				fill: getThemeStyle('palettePrimary500'),
			},
		},
		addWrapper: {
			justifyContent: 'space-between',
			backgroundColor: `${getThemeStyle('palettePrimary50')} !important`,
		},
		spinner: {
			marginLeft: spacingUnits(1),
		},
	},
	{ name: 'contactSelect' },
);
