import {
	Avatar,
	Box,
	Container,
	Grid,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { CustomerHeader } from '../../elements/Header/header';
import { HeroTitle } from '../../elements/HeroTitle/heroTitle';
import useStyles from './howItWorks.styles';
import { CustomerFooter } from '../../elements/Footer/footer';
import { CreditCardFeather } from '../../../../elements/PantheraIcon/CreditCardFeather';
import { CalendarFeather } from '../../../../elements/PantheraIcon/CalendarFeather';
import { FileTextFeather } from '../../../../elements/PantheraIcon/FileTextFeather';
import { MessageCircleFeather } from '../../../../elements/PantheraIcon/MessageCircleFeather';
import { PhoneFeather } from '../../../../elements/PantheraIcon/PhoneFeather';
import { VirtualOffice } from '../../../../elements/Illustrations/VirtualOffice';
import { PhoneOptions } from '../../../../elements/Illustrations/PhoneOptions';
import { PhoneLogin } from '../../../../elements/Illustrations/PhoneLogin';
import { TakeASurvey } from '../../../../elements/Illustrations/TakeASurvey';

export const HowItWorks: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Box className={styles.root}>
			<CustomerHeader className={styles.greyHeader} />
			<HeroTitle title="How it works" subtitle="Self manage your account at your time and in your way" />
			<Container className={clsx(styles.row, styles.rowTop)} maxWidth="xl">
				<Grid container spacing={isSmall ? 6 : 8} alignItems="center">
					<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgWrapper}>
							<TakeASurvey />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<div className={styles.stepWrapper}>
							<div className={styles.stepText}>Step 1</div>
							<Typography variant="h1" color="primary" gutterBottom>
								First Contact
							</Typography>
						</div>
						<Typography variant="body1" paragraph>
							You will have received a letter, email or SMS from us because we have been asked to resolve an unpaid account in your name
							by one of our clients.
						</Typography>
						<Typography variant="body1" paragraph>
							The communication will contain details about the client, how much is overdue and other account details as well our your
							ARL account number that you can use to login to manage your account.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Container className={styles.row} maxWidth="xl">
				<Grid container spacing={isSmall ? 4 : 8} alignItems="center">
					<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgPhoneWrapper}>
							<PhoneLogin />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<div className={styles.stepWrapper}>
							<div className={styles.stepText}>Step 2</div>
							<Typography variant="h1" color="primary" gutterBottom>
								Login to your account
							</Typography>
						</div>
						<Typography variant="body1" paragraph>
							Using your ARL account number you can verify your identity and login to self manage your account through our digital
							portal.
						</Typography>
						<Typography variant="body1" paragraph>
							The digital portal will help walk you through the process to understand your account and what the next steps are based on
							your situation.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Container className={styles.row} maxWidth="xl">
				<Grid container spacing={isSmall ? 4 : 8} alignItems="center">
					<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgPhoneWrapper}>
							<PhoneOptions />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<div className={styles.stepWrapper}>
							<div className={styles.stepText}>Step 3</div>
							<Typography variant="h1" color="primary" gutterBottom>
								It's Your Choice
							</Typography>
						</div>
						<Typography variant="body1" paragraph>
							The choice is yours with a variety of flexible payment options available at your fingertips.
						</Typography>
						<List>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<Avatar className={styles.avatar}>
										<CreditCardFeather />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Pay it all"
									secondary={
										<Typography variant="body1" paragraph>
											If you have the funds you can finalise your account and receive confirmation immediately or schedule for
											the future.
										</Typography>
									}
								/>
							</ListItem>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<Avatar className={styles.avatar}>
										<CalendarFeather />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Plan for it"
									secondary={
										<Typography variant="body1" paragraph>
											Work out a payment plan to resolve your account over multiple smaller payments starting either now or in
											the future.
										</Typography>
									}
								/>
							</ListItem>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<Avatar className={styles.avatar}>
										<FileTextFeather />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Other options"
									secondary={
										<Typography variant="body1" paragraph>
											You can take up other options such as putting a hold on your account, negotiating a discount on your
											account or setting up a hardship arrangement based on automated analysis of your financial situation.
										</Typography>
									}
								/>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Container>
			<Container className={clsx(styles.row, styles.rowBottom)} maxWidth="xl">
				<Grid container spacing={isSmall ? 4 : 8} alignItems="center">
					<Grid className={styles.imgWrapper} container item xs={12} md={6} lg={7} justifyContent="flex-start">
						<div className={styles.svgWrapper}>
							<VirtualOffice />
						</div>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<div className={styles.stepWrapper}>
							<div className={styles.stepText}>Step 4</div>
							<Typography variant="h1" color="primary" gutterBottom>
								We are here to help
							</Typography>
						</div>
						<Typography variant="body1" paragraph>
							Plans didn’t go the way you thought they would? <br />
							Struggling to know what to do next?
						</Typography>
						<List>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<Avatar className={styles.avatar}>
										<MessageCircleFeather />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="On your computer"
									secondary={
										<Typography variant="body1" paragraph>
											We are here to help. Email us at{' '}
											<Link color="secondary" underline="none" href={`mailto:${window.config.REACT_APP_CONTACT_EMAIL}`}>
												{window.config.REACT_APP_CONTACT_EMAIL}
											</Link>
											.
										</Typography>
									}
								/>
							</ListItem>
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									<Avatar className={styles.avatar}>
										<PhoneFeather />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Using your phone"
									secondary={
										<Typography variant="body1" paragraph>
											Send us a text or pick up the phone and to hear a friendly voice from the ARL team.{' '}
											<Link color="secondary" underline="none" href={window.config.REACT_APP_CONTACT_MOBILE_HREF}>
												{window.config.REACT_APP_CONTACT_MOBILE}
											</Link>{' '}
											or{' '}
											<Link color="secondary" underline="none" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
												{window.config.REACT_APP_CONTACT_NUMBER}
											</Link>
											.
										</Typography>
									}
								/>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
