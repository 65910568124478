import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../../../theme';
import { getThemeStyle, spacingUnits, textGrey } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& .MuiSkeleton-root': {
				textAlign: 'center',
				minWidth: 'auto',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
			'& .MuiSkeleton-circle': {
				marginBottom: 24,
			},
			'& $title': {
				marginBottom: spacingUnits(1.5),
			},
			'& $list': {
				padding: 0,

				'& $listItemWrapper': {
					position: 'relative',
					width: '100%',
					padding: [[spacingUnits(4), spacingUnits(2), spacingUnits(4), spacingUnits(11.5)]],
					color: textGrey,
				},
				'& $listItemIcon': {
					position: 'absolute',
					height: spacingUnits(6),
					width: spacingUnits(6),
					top: '50%',
					left: spacingUnits(2.75),
					transform: 'translateY(-50%)',

					'& > *': {
						height: '100%',
						width: '100%',
						fill: getThemeStyle('paletteTertiary700'),
					},
				},
				'& $listItemTitle': {
					marginBottom: spacingUnits(0.75),
				},
				'& $listItemLinkItem': {
					height: 24,
					width: 24,
					position: 'absolute',
					top: '50%',
					right: spacingUnits(3),
					transform: 'translateY(-50%)',
					fill: getThemeStyle('paletteGrey500'),
				},
			},
		},
		title: {},
		list: {},
		listItemWrapper: {},
		listItemIcon: {},
		listItemTitle: {},
		listItemLinkItem: {},
		[MuiTheme.breakpoints.up('sm')]: {
			root: {
				'& $list': {
					'& $listItemWrapper': {
						padding: [[spacingUnits(4), spacingUnits(9), spacingUnits(4), spacingUnits(11.5)]],
					},
					'& $listItemTitle': {
						marginBottom: 0,
					},
				},
			},
		},
	},
	{ name: 'dashboardLoader' },
);
