import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../theme';
import { bodyContentMaxWidth, getThemeStyle, spacingUnits, toolbarLoggedInHeight } from '../../theme/theme';

export default makeStyles(
	{
		brandmark: {
			position: 'absolute',
			zIndex: 0,
			fill: getThemeStyle('palettePrimary500'),
			opacity: 0.03,
			mixBlendMode: 'multiply',
			width: 725,
			maxWidth: '100%',
			top: -30, // half height of the avatar on the dashboard, top padding of content
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		root: {
			position: 'relative',
			flexGrow: 1,
			marginTop: toolbarLoggedInHeight,
			maxWidth: '100%',
			'& $container': {
				width: '100%',
				flexGrow: 1,
				maxWidth: bodyContentMaxWidth + spacingUnits(14),
				display: 'flex',
				flexDirection: 'column',
				padding: [[spacingUnits(4), spacingUnits(3) - 2, spacingUnits(4)]],
				margin: [[0, 'auto']],
				position: 'relative',
			},
			'&.whiteBackground': {
				backgroundColor: getThemeStyle('paletteCommonWhite'),
			},
			'&.brandmarkHidden': {
				'& $brandmark': {
					display: 'none',
				},
			},
			'&.footerHidden': {
				'& > footer': {
					display: 'none',
				},
			},
			'&.containerNoPadding': {
				'& $container': {
					padding: 0,
				},
			},
		},
		container: {
			'& > *': {
				minWidth: '100%',
				flexShrink: 0,
			},
		},
		[MuiTheme.breakpoints.up('md')]: {
			brandmark: {
				top: 0,
				left: 0,
			},
			root: {
				display: 'flex',
				flexDirection: 'column',
				'& $container': {
					padding: [[spacingUnits(7), spacingUnits(7), spacingUnits(4)]],
				},
			},
		},
		[MuiTheme.breakpoints.down('sm')]: {
			root: {
				'&.whiteBackground-sm': {
					backgroundColor: getThemeStyle('paletteCommonWhite'),
					'& $container': {
						backgroundColor: getThemeStyle('paletteCommonWhite'),
					},
				},
				'&.brandmarkHidden-sm': {
					'& $brandmark': {
						display: 'none',
					},
				},
				'&.footerHidden-sm': {
					'& > footer': {
						display: 'none',
					},
				},
				'&.containerNoPadding-sm': {
					'& $container': {
						padding: 0,
					},
				},
			},
		},
	},
	{ name: 'bodyContent' },
);
