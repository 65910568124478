import { PeriodicFrequencyID } from '../../enums/periodicFrequency';

const PERIODS_BREAK = 100000;

interface IInterestPeriods {
	months: number;
	runningInterest: number;
	length: number;
}

export interface IReoffer {
	originalAmt: number;
	baseMonths: number;
	reofferAmt: number;
	reofferMonths: number;
	totalSavings: number;
	monthsSavings: number;
}

// TODO: Work out a better way to check payment amount is enough to pay off loan with interest
const calcInterestWhile = (principle: number, rate: number, pmt: number, freq: number): IInterestPeriods => {
	let pv = principle;
	let nv = null;
	let i = 0;
	let runningInterest = 0;
	while (true) {
		let payment = 0;
		if (i % freq === 0) {
			payment = pv < pmt ? pv : pmt;
		}
		nv = (pv * rate) / 365;
		pv = pv + nv - payment;
		runningInterest += nv;
		i += 1;
		if (pv < 1) {
			break;
		}
		// Prevent infinite loop
		if (i === PERIODS_BREAK) {
			break;
		}
	}
	return { months: Math.ceil(i / 30), runningInterest, length: i };
};

export const calcSavings = (
	outstanding: number,
	paymentAmount: number,
	frequencyID: PeriodicFrequencyID,
	interestRate: number,
	altAmount?: number,
	roundAmount?: boolean,
	interestRateOffer?: number,
): IReoffer | undefined => {
	const reofferAmt = roundAmount ? Math.round(altAmount ?? paymentAmount * (1 + 0.2)) : altAmount ?? paymentAmount * (1 + 0.2);
	let interval = 0;
	switch (frequencyID) {
		case PeriodicFrequencyID.WEEKLY:
			interval = 7;
			break;
		case PeriodicFrequencyID.FORTNIGHTLY:
			interval = 14;
			break;
		case PeriodicFrequencyID.MONTHLY:
			interval = 30;
			break;
		default:
			break;
	}
	const baseoffer = calcInterestWhile(outstanding, interestRate, paymentAmount, interval);
	if (baseoffer.length !== PERIODS_BREAK) {
		const newoffer = calcInterestWhile(outstanding, interestRateOffer !== undefined ? interestRateOffer : interestRate, reofferAmt, interval);
		const totalSavings = (outstanding + baseoffer.runningInterest - (outstanding + newoffer.runningInterest)).toFixed(2);
		return {
			originalAmt: paymentAmount,
			baseMonths: baseoffer.months,
			reofferAmt: Number(reofferAmt.toFixed(2)),
			reofferMonths: newoffer.months,
			totalSavings: Number(totalSavings),
			monthsSavings: baseoffer.months - newoffer.months,
		};
	}
	return undefined;
};

export const calcDishonourReoffer = (
	outstanding: number,
	dishonourAmount: number,
	minAmount: number,
	frequencyID: PeriodicFrequencyID,
	interestRate: number,
): IReoffer | undefined => {
	// If Dishonoured Payment is >= (2 X Minimum amount) then;
	// Suggested PA Amount is 20% less than the Dishonoured PA amount
	let reofferAmt: number = dishonourAmount >= 2 * minAmount ? Number((dishonourAmount - dishonourAmount * 0.2).toFixed(2)) : minAmount;
	let interval = 0;

	if (reofferAmt > dishonourAmount) {
		reofferAmt = dishonourAmount;
	}

	switch (frequencyID) {
		case PeriodicFrequencyID.WEEKLY:
			interval = 7;
			break;
		case PeriodicFrequencyID.FORTNIGHTLY:
			interval = 14;
			break;
		case PeriodicFrequencyID.MONTHLY:
			interval = 30;
			break;
		default:
			break;
	}

	const baseoffer = calcInterestWhile(outstanding, interestRate, dishonourAmount, interval);
	if (baseoffer.length !== PERIODS_BREAK) {
		const newoffer = calcInterestWhile(outstanding, interestRate, reofferAmt, interval);
		const totalSavings = (outstanding + baseoffer.runningInterest - (outstanding + newoffer.runningInterest)).toFixed(2);
		return {
			originalAmt: dishonourAmount,
			baseMonths: baseoffer.months,
			reofferAmt: Number(reofferAmt.toFixed(2)),
			reofferMonths: newoffer.months,
			totalSavings: Number(totalSavings),
			monthsSavings: baseoffer.months - newoffer.months,
		};
	}
	return undefined;
};
