import { Grid, GridSize, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './settingsList.styles';

type SettingsListItem = {
	addedDate: string;
	isPrimary?: boolean;
	value: string;
	secondaryValue?: string;
	id: number;
};

type SettingsListProps = {
	className?: string;
	emptyText: React.ReactNode;
	columnWidths?: GridSize[];
	items: SettingsListItem[];
};

export const SettingsList: React.FC<SettingsListProps> = ({ className, columnWidths = [6, 6], emptyText, items }) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, className)}>
			{items.map((item) => (
				<Grid key={item.id} alignItems="center" className={clsx(styles.row, item.isPrimary && styles.rowPrimary)} container>
					<Grid item xs={columnWidths[0]}>
						<Typography className={styles.value} variant="subtitle2">
							{item.value}
						</Typography>
						{item.secondaryValue && (
							<div>
								<Typography className={styles.secondaryValue} variant="subtitle2">
									{item.secondaryValue}
								</Typography>
							</div>
						)}
					</Grid>
					<Grid className={styles.dateTrash} item xs={columnWidths[1]}>
						<div className={styles.date}>
							<Typography component="span" variant="subtitle2">
								Added{' '}
							</Typography>
							<Typography component="span" variant="subtitle2">
								{item.addedDate}
							</Typography>
						</div>
					</Grid>
				</Grid>
			))}
			{items.length === 0 && (
				<div className={styles.row}>
					<Typography className={styles.empty} variant="subtitle2">
						{emptyText}
					</Typography>
				</div>
			)}
		</div>
	);
};
