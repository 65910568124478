import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../theme';
import { getThemeStyle, spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			textAlign: 'center',

			'& $brandmark': {
				position: 'absolute',
				fill: getThemeStyle('palettePrimary500'),
				opacity: 0.05,
				mixBlendMode: 'multiply',
				width: 260,
				maxWidth: '100%',
				top: -30,
				left: '50%',
				transform: 'translate(-50%, -50%)',
			},
			'& $avatar': {
				width: spacingUnits(7),
				height: spacingUnits(7),
				margin: [[0, 'auto', spacingUnits(1)]],
			},
			'& $cardTitle': {
				marginBottom: spacingUnits(0.5),
			},
		},
		brandmark: {},
		avatar: {},
		cardTitle: {},
		[MuiTheme.breakpoints.up('sm')]: {
			root: {
				textAlign: 'left',

				'& > $avatar': {
					width: spacingUnits(10),
					height: spacingUnits(10),
					margin: [[0, spacingUnits(2), 0, 0]],
				},
			},
		},
		[MuiTheme.breakpoints.up('md')]: {
			root: {
				'& > $brandmark': {
					display: 'none',
				},
			},
		},
	},
	{ name: 'assistantCTACard' },
);
