/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormHelperText, InputLabel, Input, FormControlProps, InputBaseComponentProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { TriangleRight } from '../PantheraIcon';
import useStyles from './pantheraInput.styles';
import { QuestionMarkFilled } from '../PantheraIcon/QuestionMarkFilled';
import { FileUpload, FileUploadProps } from './elements/FileUpload/fileUpload';

// @TODO Add tooltip prop

export type PantheraInputProps = {
	className?: string;
	helperText?: string;
	label?: React.ReactNode;
	InputComponent?: typeof Input;
	FileUploadComponent?: typeof FileUpload;
	InputProps?: InputBaseComponentProps;
	FileUploadProps?: FileUploadProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	InputRef?: React.Ref<any>;
	shouldDisplayIndicator?: boolean;
	errorMessage?: string;
	error?: boolean;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	tooltip?: PantheraToolTipProps;
	multiline?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
};

export type PantheraToolTipProps = {
	children: React.ReactNode;
	onClick: () => void;
};

export const PantheraInput = ({
	className,
	label,
	helperText,
	InputComponent = Input,
	FileUploadComponent,
	InputProps,
	FileUploadProps,
	InputRef,
	shouldDisplayIndicator = true,
	errorMessage,
	error = false,
	startAdornment = null,
	endAdornment = null,
	tooltip,
	multiline = false,
	disabled = false,
	readOnly = false,
	...props
}: PantheraInputProps & FormControlProps) => {
	const styles = useStyles();
	return (
		<FormControl className={clsx(styles.root, className)} error={error} {...props}>
			{tooltip && (
				<button type="button" className={styles.tooltip} onClick={tooltip.onClick}>
					<Typography variant="body2">{tooltip.children}</Typography>
					<QuestionMarkFilled />
				</button>
			)}
			{label && (
				<InputLabel className={styles.label} disableAnimation shrink>
					{label}
				</InputLabel>
			)}
			{FileUploadComponent && <FileUploadComponent {...FileUploadProps} />}
			{!FileUploadComponent && (
				<InputComponent
					className={styles.input}
					disableUnderline
					fullWidth
					multiline={multiline}
					inputProps={InputProps}
					startAdornment={startAdornment}
					endAdornment={endAdornment}
					inputRef={InputRef}
					disabled={disabled}
					readOnly={readOnly}
				/>
			)}
			{shouldDisplayIndicator && (
				<span className={clsx('input-indicator', styles.indicator, label && styles.indicatorWithLabel)}>
					<TriangleRight />
				</span>
			)}
			{!error && helperText && <FormHelperText className={styles.helperText}>{helperText}</FormHelperText>}
			{error && <span className={styles.errorMessage}>{errorMessage}</span>}
		</FormControl>
	);
};
