/* eslint-disable no-nested-ternary */
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	LabelDisplayedRowsArgs,
	Portal,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { format, getDate, getMonth, getYear, parseISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { createEvents, EventAttributes } from 'ics';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SpinnerButton } from '../../../../../../elements/SpinnerButton/spinnerButton';
import { BankTransactionMethod } from '../../../../../../enums/bankTransactionMethod';
import { AvailablePaymentMethod } from '../../../../../../enums/paymentForm';
import arlApi from '../../../../../../interceptor/api';
import { dateFormats } from '../../../../../../services/constants/formats';
import { currency, formatMoney } from '../../../../../../services/helpers/text.helpers';
import { fetchPaymentSchedules, resetPaymentSchedules } from '../../../../../../store/features/arrangement/paymentSchedule/paymentScheduleSlice';
import { IGetArrangementSchedule, PaymentScheduleAPI } from '../../../../../../store/features/arrangement/paymentSchedule/types';
import { RootState } from '../../../../../../store/rootReducer';
import { spacingUnits } from '../../../../../../theme/theme';
import useStyles from './schedulesTab.styles';

const creditCardMethods = [BankTransactionMethod.CREDIT_CARD, BankTransactionMethod.CREDIT_CARD_DIRECT_DEBIT];

export const SchedulesTab: React.FC = () => {
	const styles = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const [rowCount, setRowCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const [icsLoading, setIcsLoading] = useState(false);
	const [csvLoading, setCsvLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string | undefined>();

	const { loading, schedules, billerCode, paymentRef, merchantFeePercent, isCC } = useSelector(
		(state: RootState) => ({
			loading: state.paymentSchedule.loading || state.customerBpay.loading,
			schedules: state.paymentSchedule.schedule,
			billerCode: state.customerBpay.bpayDetails?.billerCode,
			paymentRef: state.customerBpay.bpayDetails?.ref,
			merchantFeePercent: state.customer.customerDetails?.merchantFeePercent ?? 0,
			isCC: !!(
				state.paymentSchedule.schedule?.[0].method &&
				creditCardMethods.includes(state.paymentSchedule.schedule[0].method as BankTransactionMethod)
			),
		}),
		shallowEqual,
	);

	useEffect(() => {
		dispatch(fetchPaymentSchedules());
		return () => {
			dispatch(resetPaymentSchedules());
		};
	}, [dispatch]);

	useEffect(() => {
		if (schedules) {
			setRowCount(schedules.length);
		}
	}, [dispatch, schedules]);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const pageSize = parseInt(event.target.value, 10);
		setRowsPerPage(pageSize);
		setPage(0);
	};

	const handleLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
		return `${from}-${to > 0 ? to : count} of ${count}`;
	};

	async function getData() {
		return arlApi.get<IGetArrangementSchedule>(PaymentScheduleAPI.SCHEDULES);
	}

	const handleClickDownloadICS = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setIcsLoading(true);
		try {
			const result = await getData();
			if (result.data && result.data.success) {
				const events = result.data.data.schedule.map((schedule) => {
					let desc = `You are scheduled to pay ${formatMoney(schedule.amount)} today.`;
					if (creditCardMethods.includes(schedule.method as BankTransactionMethod)) {
						desc = `You are scheduled to pay ${formatMoney(schedule.amount)}${
							merchantFeePercent > 0 ? ` (includes ${merchantFeePercent}% surcharge)` : ''
						} today, this payment will automatically be taken by ${
							AvailablePaymentMethod.CARD
						}. If you cannot make this payment please contact us immediately.`;
					} else if (schedule.method === BankTransactionMethod.DIRECT_DEBIT) {
						desc = `You are scheduled to pay ${formatMoney(schedule.amount)} today, this payment will automatically be taken by ${
							AvailablePaymentMethod.DIRECT
						}. If you cannot make this payment please contact us immediately.`;
					} else if (schedule.method === BankTransactionMethod.BPAY) {
						desc = `You are scheduled to pay ${formatMoney(schedule.amount)} today, please ensure you transfer funds via ${
							AvailablePaymentMethod.BPAY
						} using Biller Code: ${billerCode} BPAY Ref: ${paymentRef}. If you cannot make this payment please contact us immediately.`;
					}
					const paDate = parseISO(schedule.date);
					const year = getYear(paDate);
					const month = getMonth(paDate);
					const day = getDate(paDate);
					const evt: EventAttributes = {
						title: `${window.config.REACT_APP_COMPANY} Payment Due`,
						description: desc,
						start: [year, month + 1, day, 9, 0],
						duration: { minutes: 5 },
						url: 'https://www.arlcollect.com.au/',
						organizer: { name: window.config.REACT_APP_COMPANY, email: window.config.REACT_APP_CONTACT_EMAIL },
						alarms: [{ action: 'display', trigger: { hours: 1, before: true } }],
					};
					return evt;
				});
				const { value } = createEvents(events);
				if (value) {
					const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
					saveAs(blob, 'payment-schedule.ics');
				}
			} else {
				setErrorMsg(result.data.message);
			}
		} catch (err) {
			setErrorMsg('Your ICS file could not be downloaded at this time.');
		} finally {
			setIcsLoading(false);
		}
	};

	const handleClickDownloadCSV = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setCsvLoading(true);
		try {
			const result = await getData();
			if (result.data && result.data.success) {
				let csv = '"Date","Amount"\n';
				const array = result.data.data.schedule;
				for (let i = 0; i < array.length; i += 1) {
					const schedule = array[i];
					csv += `"${format(parseISO(schedule.date), dateFormats.prettyShort)}","${schedule.amount}"`;
					csv += '\n';
				}
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
				saveAs(blob, 'payment-schedule.csv');
			} else {
				setErrorMsg(result.data.message);
			}
		} catch (err) {
			setErrorMsg('Your CSV file could not be downloaded at this time.');
		} finally {
			setCsvLoading(false);
		}
	};

	return (
		<>
			<Portal>
				<Snackbar open={!!errorMsg} autoHideDuration={6000}>
					<Alert variant="filled" severity="error" onClose={() => setErrorMsg(undefined)}>
						{errorMsg}
					</Alert>
				</Snackbar>
			</Portal>
			<div className={styles.section}>
				{schedules ? (
					<>
						<Typography variant="subtitle1">Payments due</Typography>
						<Typography variant="body2" style={{ marginTop: spacingUnits(1) }}>
							Note: this breakdown will only show up to the first 20 scheduled payments.
							{merchantFeePercent > 0 && isCC && (
								<span>
									<br />
									Payment amounts below include a surcharge of {merchantFeePercent}% for all Visa or MasterCard.
								</span>
							)}
						</Typography>
						<TableContainer>
							<Table aria-label="Schedules table">
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(rowsPerPage > 0 ? schedules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : schedules).map(
										(sch) => (
											<TableRow key={sch.date}>
												<TableCell component="th" scope="row">
													{format(parseISO(sch.date), dateFormats.prettyShort)}
												</TableCell>
												<TableCell>{currency(sch.amount)}</TableCell>
											</TableRow>
										),
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component="div"
							// [dbo].[sp_credibot_pWebCustomerPaymentSchedule] currently only does TOP 20
							rowsPerPageOptions={[10, { label: 'All', value: -1 }]}
							count={rowCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelDisplayedRows={handleLabelDisplayedRows}
						/>
						<Grid container direction="row" justifyContent="center" alignItems="flex-start" className={styles.buttons}>
							<SpinnerButton color="primary" size="small" variant="outlined" onClick={handleClickDownloadICS} loading={icsLoading}>
								Save to calendar
							</SpinnerButton>
							<SpinnerButton color="primary" size="small" variant="outlined" onClick={handleClickDownloadCSV} loading={csvLoading}>
								Download csv
							</SpinnerButton>
						</Grid>
					</>
				) : loading ? (
					<Box display="flex" alignItems="center" justifyContent="center" padding={4}>
						<CircularProgress color="secondary" size={80} />
					</Box>
				) : (
					<Typography variant="subtitle1">No payments due</Typography>
				)}
			</div>

			<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => history.goBack()}>
				Back
			</Button>
		</>
	);
};
