import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../../../theme';
import { bodyContentMarketingMaxWidth, getStrictDimensionsForDiameter, getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

const container = {
	width: '100%',
	margin: [[0, 'auto']],
	maxWidth: bodyContentMarketingMaxWidth + spacingUnits(5.5),
};

export default makeStyles(
	{
		root: {
			'& $thingsToAvoid': {
				marginTop: spacingUnits(7),
				[MuiTheme.breakpoints.up('md')]: {
					marginTop: spacingUnits(5),
				},
			},
		},
		container: {
			...container,
			padding: [[spacingUnits(7), spacingUnits(2.75), spacingUnits(12)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},
		},
		externalLink: {
			display: 'inline-flex',
			alignItems: 'center',

			color: getThemeStyle('palettePrimary500'),
			fill: getThemeStyle('palettePrimary500'),
			fontWeight: 500,

			'& > svg': getStrictDimensionsForDiameter(18),
		},
		thingsToAvoid: {},
		schemes: {
			'& br': {
				display: 'block',
				content: '""',
				marginTop: '0.5em',
				lineHeight: '190%',
				verticalAlign: 'top',
			},
		},
	},
	{
		name: 'debtResources',
	},
);
