import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			padding: spacingUnits(3),
		},
	},
	{ name: 'tabPanel' },
);
