import { makeStyles } from '@material-ui/core';
import { brandMexicanRed, fontFamilyText, getThemeStyle, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	(theme) => ({
		grow: {
			flexGrow: 1,
		},
		root: {
			backgroundColor: 'transparent',
			paddingTop: spacingUnits(1.5),
			paddingBottom: spacingUnits(1.5),
			transition: 'padding .3s cubic-bezier(.4,0,.2,1), height .3s cubic-bezier(.4,0,.2,1)',

			'& .MuiToolbar-root': {
				minHeight: 'auto',
			},

			'&$scrolled': {
				position: 'fixed',
				top: 0,
				left: 'auto',
				right: 0,
				backgroundColor: getThemeStyle('paletteCommonWhite'),
				borderBottom: `1px solid ${getThemeStyle('paletteGrey50')}`,
				paddingTop: spacingUnits(1),
				paddingBottom: spacingUnits(1),

				'& $logo': {
					'& > svg': {
						height: 40,
					},
				},

				'& $dropdown': {
					paddingTop: spacingUnits(1.75),
					paddingBottom: spacingUnits(1.75),
				},
			},
		},
		logo: {
			display: 'flex',
			'& > svg': {
				height: 40,
				[theme.breakpoints.up('md')]: {
					height: 60,
				},
			},
		},
		nav: {
			alignItems: 'center',
			marginLeft: spacingUnits(6),
		},
		navLink: {
			padding: spacingUnits(1),
			fontSize: '1.6rem',
			lineHeight: '1.8rem',
			fontWeight: 500,

			'& a': {
				color: getThemeStyle('palettePrimary500'),
			},

			'& .active': {
				color: brandMexicanRed,
			},

			'&:not(:first-child)': {
				marginLeft: spacingUnits(5),
			},

			'& .MuiButton-root': {
				fontFamily: fontFamilyText,
				fontSize: '1.6rem',
				lineHeight: '1.8rem',
				fontWeight: 500,
				letterSpacing: 0,
				'&:hover': {
					backgroundColor: 'transparent',
				},
			},

			'&$navLinkDropdown': {
				padding: 0,
			},
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		dropdown: {
			paddingTop: spacingUnits(2.5),
			paddingBottom: spacingUnits(2.5),
		},
		popover: {
			paddingTop: spacingUnits(1),
			boxShadow: 'none',
			backgroundColor: 'transparent',
			overflow: 'visible',
		},
		menuWrapper: {
			width: 280,
			padding: spacingUnits(3),
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			boxShadow: getThemeStyle('shadows2'),
			borderRadius: spacingUnits(1),

			'& a': {
				color: getThemeStyle('palettePrimary500'),
				fontSize: '1.6rem',
				lineHeight: '3rem',
				fontWeight: 500,

				'&.active': {
					color: brandMexicanRed,
				},
			},
		},
		navLinkDropdown: {},
		scrolled: {},
	}),
	{ name: 'customerHeader' },
);
