import { lighten, makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits, transition } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			border: `1px solid ${getThemeStyle('paletteTertiary100')}`,
			borderRadius: spacingUnits(1),
			padding: [[spacingUnits(2), spacingUnits(1.5)]],
			transition: transition(['background-color', 'border-color']),

			'&$rootSelected': {
				backgroundColor: lighten(getThemeStyle('palettePrimary100'), 0.75),
				borderColor: lighten(getThemeStyle('palettePrimary200'), 0.6),

				'& $checkbox': {
					borderColor: getThemeStyle('paletteGrey400'),

					'& > *': {
						width: '100%',
						fill: getThemeStyle('paletteSuccessMain'),
					},
				},
			},
			'& $checkbox': {
				backgroundColor: getThemeStyle('paletteCommonWhite'),
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: 2,
				height: spacingUnits(2),
				width: spacingUnits(2),
				borderRadius: 2,
				border: `1px solid ${getThemeStyle('paletteTertiary300')}`,
			},
			'&.MuiFormControlLabel-root': {
				marginLeft: 0,
				marginRight: 0,
			},
			'& .MuiFormControlLabel-label': {
				fontSize: '1.6rem',
				fontWeight: 500,
				lineHeight: '2.1rem',
			},
			'& .MuiButtonBase-root': {
				border: 'none',
				padding: spacingUnits(1),
				paddingLeft: 0,

				'&.Mui-checked:hover, &:hover': {
					backgroundColor: 'transparent',
				},
			},
		},
		rootSelected: {},
		checkbox: {},
	},
	{ name: 'pantheraCheckbox' },
);
