import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ButtonOversize } from '../../../ButtonOversize/buttonOversize';
import useStyles from './paymentMethods.styles';

export const PaymentMethodsLoader: React.FC = () => {
	const styles = useStyles();
	return (
		<div className={styles.root}>
			<ButtonOversize className={styles.button}>
				<Grid container direction="row" justifyContent="flex-start" alignItems="center">
					<Grid item xs={1}>
						<Skeleton variant="circle" width={30} height={30} />
					</Grid>
					<Grid item xs={4}>
						<Skeleton variant="text" />
					</Grid>
				</Grid>
			</ButtonOversize>
			<ButtonOversize className={styles.button}>
				<Grid container direction="row" justifyContent="flex-start" alignItems="center">
					<Grid item xs={1}>
						<Skeleton variant="circle" width={30} height={30} />
					</Grid>
					<Grid item xs={4}>
						<Skeleton variant="text" />
					</Grid>
				</Grid>
			</ButtonOversize>
		</div>
	);
};
