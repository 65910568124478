import React from 'react';

export const BillsColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 80 80" {...props}>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path d="M7 5l23 1 1 2v32l-2 2H8l-3-2L6 7l1-2zm20 6H12l-2 2v8l2 1 12 1 4-2-1-10z" fill="#ffcccc" />
				<path
					d="M12 21h14v-9H12v9zm-.271 3C10.225 24 9 22.79 9 21.3V11.7C9 10.211 10.225 9 11.729 9H26.27C27.776 9 29 10.212 29 11.701V21.3c0 1.49-1.224 2.7-2.729 2.7H11.73zM9 28h5v-3H9v3zm7 0h5v-3h-5v3zm7 0h5v-3h-5v3zm13 3h18v-3H36v3zM9 33h5v-3H9v3zm7 0h5v-3h-5v3zm7 0h5v-3h-5v3zm13 4h18v-3H36v3zM9 38h5v-3H9v3zm7 0h5v-3h-5v3zm7 0h5v-3h-5v3zm13 5h18v-3H36v3zm-13 6v-3h19.968v1.347A.301.301 0 0143 47.5l-.026.162L42.967 49H23zm0 6h17v-3H23v3zm0 6h16v-3H23v3zm35-10c-1.218.573-1.995 1.727-2 3 .005 1.272.782 2.426 2 3v-6zm3 16c1.218-.574 1.994-1.727 2-3-.006-1.272-.782-2.426-2-3v6zm-1.5 4c-.811 0-1.478-.594-1.543-1.345-2.872-.671-4.947-3.165-4.952-5.972 0-.809.695-1.465 1.548-1.465.854 0 1.548.656 1.548 1.465.005 1.209.723 2.305 1.852 2.85V60.29C55.075 59.623 53 57.13 53 54.317c0-2.814 2.077-5.307 4.957-5.972.065-.752.732-1.345 1.543-1.345.81 0 1.477.593 1.542 1.345 2.872.671 4.947 3.165 4.951 5.972 0 .809-.694 1.465-1.547 1.465-.854 0-1.547-.656-1.547-1.465-.006-1.209-.725-2.305-1.853-2.849v6.242c2.878.667 4.954 3.16 4.954 5.973 0 2.814-2.079 5.307-4.958 5.972C60.977 70.407 60.31 71 59.5 71zM51 20.196c0 .443.361.804.803.804H55l-4-4v3.196zM7.884 7A.892.892 0 007 7.899v31.203c0 .495.397.898.884.898h21.232a.891.891 0 00.884-.898V7.9A.892.892 0 0029.116 7H7.884zM20 65.106c0 .493.395.894.88.894H43.17a17.257 17.257 0 01.827-15.459c2.739-4.78 7.591-7.9 13.002-8.368v-18.61h-5.58c-2.105 0-3.818-1.743-3.818-3.886V14h-14.68v24.805c0 2.143-1.714 3.886-3.82 3.886H20v22.415zM59 45c-7.72 0-14 6.28-14 14s6.28 14 14 14c7.71-.008 13.992-6.29 14-14 0-7.72-6.28-14-14-14zm.053 31a16.967 16.967 0 01-13.824-7.14h-24.27a3.873 3.873 0 01-3.87-3.868V42.679H7.87A3.873 3.873 0 014 38.811V7.87A3.874 3.874 0 017.87 4h21.417a3.874 3.874 0 013.87 3.87v3.27h15.372a3.86 3.86 0 011.552.327c.487.231.865.487 1.193.81l8.113 8.118c.319.324.578.698.766 1.111.253.556.37 1.073.371 1.63v19.029a16.89 16.89 0 0115.267 14.26c1.23 7.847-3.06 15.395-10.43 18.353A16.846 16.846 0 0159.054 76z"
					fill="#cc0000"
				/>
			</g>
		</svg>
	);
};
