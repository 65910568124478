/* eslint-disable react/no-array-index-key */
import { Button, Card, Divider, Grid, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Skeleton } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateFormats } from '../../../../../../services/constants/formats';
import { formatMoney } from '../../../../../../services/helpers/text.helpers';
import { RootState } from '../../../../../../store/rootReducer';
import useStyles from './summaryTab.styles';

export const SummaryTab: React.FC = () => {
	const styles = useStyles();
	const history = useHistory();

	const { customerDetails, customerDetailsLoaded } = useSelector(
		(state: RootState) => ({
			customerDetails: state.customer.customerDetails,
			customerDetailsLoaded: state.customer.isLoaded,
		}),
		shallowEqual,
	);

	return (
		<>
			<Card variant="outlined" className={styles.cardDisplay}>
				<Grid container direction="row" justifyContent="flex-start" alignItems="center">
					<AccountCircleIcon className={styles.icon} color="primary" />{' '}
					<Typography variant="h6" style={{ marginLeft: '1rem' }}>
						Personal Details
					</Typography>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					{!customerDetailsLoaded &&
						Array.from(new Array(3)).map((item, index) => (
							<Grid item xs={12} key={index}>
								<Typography variant="subtitle2" gutterBottom>
									<Skeleton variant="rect" width={75} />
								</Typography>
								<Typography variant="body1">
									<Skeleton variant="rect" width={100} />
								</Typography>
							</Grid>
						))}
					{customerDetailsLoaded && customerDetails?.fullName && (
						<Grid item xs={12}>
							<Typography variant="subtitle2" gutterBottom>
								Name
							</Typography>
							<Typography variant="body1">{customerDetails?.fullName}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && customerDetails?.dob && (
						<Grid item xs={12}>
							<Typography variant="subtitle2" gutterBottom>
								Date of Birth
							</Typography>
							<Typography variant="body1">{format(parseISO(customerDetails?.dob), dateFormats.prettyShort)}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && customerDetails?.Reference && (
						<Grid item xs={12}>
							<Typography variant="subtitle2" gutterBottom>
								Reference Number
							</Typography>
							<Typography variant="body1">{customerDetails?.Reference}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && customerDetails?.gender && (
						<Grid item xs={12}>
							<Typography variant="subtitle2" gutterBottom>
								Gender
							</Typography>
							<Typography variant="body1">{customerDetails?.gender}</Typography>
						</Grid>
					)}
				</Grid>
			</Card>
			<Card variant="outlined" className={styles.cardDisplay}>
				<Grid container direction="row" justifyContent="flex-start" alignItems="center">
					<AssessmentIcon className={styles.icon} color="primary" />{' '}
					<Typography variant="h6" style={{ marginLeft: '1rem' }}>
						Account Details
					</Typography>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					{!customerDetailsLoaded &&
						Array.from(new Array(6)).map((item, index) => (
							<Grid item xs={6} key={index}>
								<Typography variant="subtitle2" gutterBottom>
									<Skeleton variant="rect" width={75} />
								</Typography>
								<Typography variant="body1">
									<Skeleton variant="rect" width={100} />
								</Typography>
							</Grid>
						))}
					{customerDetailsLoaded && customerDetails?.clientName && (
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Original Provider
							</Typography>
							<Typography variant="body1">{customerDetails.clientName}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && customerDetails?.accountName && (
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Account Name
							</Typography>
							<Typography variant="body1">{customerDetails.accountName}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && customerDetails?.clientReference && (
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Client Reference Number
							</Typography>
							<Typography variant="body1">{customerDetails.clientReference}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && !Number.isNaN(customerDetails?.outstandingAmt) && (
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Outstanding Amount
							</Typography>
							<Typography variant="body1">{formatMoney(customerDetails?.outstandingAmt ?? 0)}</Typography>
						</Grid>
					)}
					{customerDetailsLoaded && !Number.isNaN(customerDetails?.paidAmt) && (
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Paid Amount
							</Typography>
							<Typography variant="body1">{formatMoney(customerDetails?.paidAmt ?? 0)}</Typography>
						</Grid>
					)}
				</Grid>
			</Card>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => history.goBack()}>
				Back
			</Button>
		</>
	);
};
