import { Typography } from '@material-ui/core';
import React from 'react';
import { formatMoney } from '../../services/helpers/text.helpers';

import useStyles from './amountPresenter.styles';

type AmountPresenterProps = {
	amount: number;
	shouldDisplayDecimals?: boolean;
	size: 'small' | 'medium' | 'tiny';
	dynamicFontSize?: boolean;
	charsLength?: number;
};

export const AmountPresenter: React.FC<AmountPresenterProps> = ({
	amount,
	size = 'medium',
	shouldDisplayDecimals,
	dynamicFontSize = false,
	charsLength = 0,
	...props
}) => {
	const styles = useStyles({ size, dynamicFontSize, charsLength });
	const [dollars, cents] = amount.toString().split('.');
	return (
		<Typography className={styles.root} variant="h2" {...props}>
			<span>$</span>
			{formatMoney(Number(dollars), true)}
			{(cents || shouldDisplayDecimals) && <sub>.{`${cents || 0}0`.substr(0, 2)}</sub>}
		</Typography>
	);
};
