import React from 'react';

export const FieldServing = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 128 128" {...props}>
		<circle
			cx={23.56}
			cy={67.5}
			r={10.57}
			transform="rotate(-76.72 23.56 67.493)"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M23.56 94.32v4.4a7.81 7.81 0 007.81 7.81h22.05M31.13 125.43h0a39.89 39.89 0 004.22-12.72"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M35.81 100.79v-5.08a12.25 12.25 0 00-12.25-12.25h0a12.25 12.25 0 00-12.24 12.25v11A39.81 39.81 0 0016 125.43h0M53.66 84.11a19.19 19.19 0 000-27.18M48.62 79.07a12.07 12.07 0 000-17.1M42.73 74.8a6 6 0 000-8.56"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<circle
			cx={104.44}
			cy={67.5}
			r={10.57}
			transform="rotate(-67.5 104.44 67.501)"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M104.44 94.32v4.4a7.81 7.81 0 01-7.81 7.81h-22M96.87 125.43h0a39.89 39.89 0 01-4.22-12.72"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M92.19 100.79v-5.08a12.25 12.25 0 0112.25-12.25h0a12.25 12.25 0 0112.24 12.25v11a39.81 39.81 0 01-4.68 18.72h0M95.58 42l9.23 9V12.13a9.56 9.56 0 00-9.57-9.56H62.59A9.57 9.57 0 0053 12.13v20.29A9.57 9.57 0 0062.59 42z"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M73.88 26.55a5 5 0 1010.07 0c0-2.78-2.06-4.06-5-5-2.65-.86-5-2.26-5-5a5 5 0 0110.07 0M78.92 8.64v2.84M78.92 31.59v2.84"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path d="M62.59 8.57A3.56 3.56 0 0059 12.13v20.29A3.56 3.56 0 0062.59 36h5.21a41.43 41.43 0 010-27.41h-5.21z" fill="#fcc" />
	</svg>
);
