/* eslint-disable import/no-cycle */
import { Action, configureStore } from '@reduxjs/toolkit';
import { getUnixTime } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { ThunkAction } from 'redux-thunk';
import { refreshAuth } from './features/auth/authSlice';
import { JWTUser } from './features/auth/types';
import { listenerMiddleware, startAppListening } from './listenerMiddleware';
import rootReducer, { RootState } from './rootReducer';

export const persistRootKey = 'root';

const persistConfig = {
	key: persistRootKey,
	whitelist: ['customerBpay', 'paymentForm', 'paymentFrequency', 'paymentMethod'],
	version: 1,
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

startAppListening({
	predicate: (action, currentState) => {
		if (currentState && currentState.auth && currentState.auth.user && currentState.auth.user.authenticated) {
			const decoded = jwt_decode(localStorage.getItem('token') as string) as JWTUser;
			// Run if current token only has less than 5mins of lifespan
			if (decoded && decoded.exp && decoded.exp - getUnixTime(new Date()) < 300 && decoded.refreshCount > 0) {
				const isStillRefreshing = currentState.auth.loading;
				if (!isStillRefreshing) {
					return true;
				}
			}
		}
		return false;
	},
	effect: async (action, listenerApi) => {
		listenerApi.cancelActiveListeners();
		listenerApi.dispatch(refreshAuth());
	},
});

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).prepend(listenerMiddleware.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = ReturnType<typeof store.dispatch>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
