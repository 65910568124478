import { makeStyles } from '@material-ui/core/styles';
import { spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		card: {
			margin: [[0, 'auto']],
			maxWidth: 616,
			minWidth: 'auto',
			padding: [[spacingUnits(6), spacingUnits(8), spacingUnits(7)]],
			width: '100%',
		},
		backButton: {
			marginTop: spacingUnits(4),
		},
	},
	{ name: 'hardship' },
);
