import { Card, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { TabPanel } from './elements/TabPanel/tabPanel';
import theme from '../../theme';

import useStyles from './tabbedCard.styles';

type TabsProps = {
	title: string;
	component: React.FC<{}>;
};

type TabbedCardProps = {
	className?: string;
	onTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
	selectedTab?: string;
	tabs: Record<string, TabsProps>;
};

export const TabbedCard: React.FC<TabbedCardProps> = ({ className, onTabChange, selectedTab, tabs }) => {
	const styles = useStyles();
	const tabArr = Object.keys(tabs);
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Card className={clsx(styles.root, className)} elevation={0} square={!screenAtDesktopWidth}>
			<Tabs onChange={onTabChange} value={selectedTab} variant="scrollable" scrollButtons="auto">
				{tabArr.map((tabKey) => (
					<Tab key={tabKey} label={tabs[tabKey].title} value={tabKey} />
				))}
			</Tabs>
			{tabArr.map((tabKey) => {
				const Component = tabs[tabKey].component;
				return (
					<TabPanel key={tabKey} isActive={tabKey === selectedTab}>
						<Component />
					</TabPanel>
				);
			})}
		</Card>
	);
};
