import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ContactType } from '../../../../enums/contactTypes';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { CustomerContactsAPI, IContactOutput, IPhoneContacts } from '../types';

export interface CustomerPhoneState {
	phoneContacts: IContactOutput[] | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: CustomerPhoneState = {
	phoneContacts: undefined,
	loading: false,
	error: undefined,
};

export const customerPhoneSlice = createSlice({
	name: 'customerPhoneSlice',
	initialState,
	reducers: {
		getPhoneContacts(state: CustomerPhoneState) {
			state.loading = true;
			state.phoneContacts = undefined;
			state.error = undefined;
		},
		getPhoneContactsSuccess(state: CustomerPhoneState, action: PayloadAction<IContactOutput[]>) {
			state.loading = false;
			state.phoneContacts = action.payload;
			state.error = undefined;
		},
		getPhoneContactsFailure(state: CustomerPhoneState, action: PayloadAction<string>) {
			state.loading = false;
			state.phoneContacts = undefined;
			state.error = action.payload;
		},
	},
});

export const { getPhoneContacts, getPhoneContactsSuccess, getPhoneContactsFailure } = customerPhoneSlice.actions;

export default customerPhoneSlice.reducer;

type FetchPhoneContactsInput = ContactType.Phone_Home | ContactType.Phone_Work | ContactType.Phone_Mobile;

export const fetchPhoneContacts =
	(contactTypes?: FetchPhoneContactsInput[]): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getPhoneContacts());
			const { data } = await arlApi.get<IPhoneContacts>(CustomerContactsAPI.PHONE_CONTACTS, {
				params: {
					contactTypeID: JSON.stringify(contactTypes ?? [ContactType.Phone_Home, ContactType.Phone_Work, ContactType.Phone_Mobile]),
				},
			});
			if (data.success) {
				dispatch(getPhoneContactsSuccess(data.data.contacts));
			} else {
				dispatch(getPhoneContactsFailure(data.message));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getPhoneContactsFailure(e.response?.data.message));
			} else {
				dispatch(getPhoneContactsFailure('An unknown error occured.'));
			}
		}
	};
