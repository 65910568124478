/* eslint-disable @typescript-eslint/no-shadow */
import { ButtonBase, Paper, ButtonBaseProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { LinkProps } from 'react-router-dom';

import { CircleCheckSolid, Times } from '../PantheraIcon';
import { WrapWithLink } from '../WrapWithLink/wrapWithLink';
import useStyles from './buttonOversize.styles';

export type ButtonOversizeProps = {
	className?: string;
	elevation?: number;
	LinkProps?: LinkProps;
	selected?: boolean;
	solo?: boolean;
	iconLeft?: React.ReactNode;
	onRemove?: (event: React.MouseEvent<SVGSVGElement>) => void;
};

export const ButtonOversize: React.FC<ButtonOversizeProps & React.PropsWithChildren<ButtonBaseProps>> = ({
	className,
	children,
	elevation = 8,
	iconLeft = null,
	LinkProps,
	selected,
	solo,
	onRemove,
	...props
}) => {
	const styles = useStyles();
	return (
		<WrapWithLink className={clsx(styles.root, className)} LinkProps={LinkProps}>
			<ButtonBase className={styles.button} {...props}>
				<Paper className={styles.paper} elevation={elevation}>
					{iconLeft}
					<span className={styles.content}>{children}</span>
					{selected && solo && <Times className={styles.times} onClick={(e) => (onRemove ? onRemove(e) : e.preventDefault())} />}
					{selected && !solo && <CircleCheckSolid className={styles.check} />}
				</Paper>
			</ButtonBase>
		</WrapWithLink>
	);
};
