import React from 'react';

export const LightBulbColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<path d="M33 53h15v9.5a7.5 7.5 0 01-15 0V53z" fill="#ffcccc" />
			<g fill="#cc0000" transform="translate(21 10)">
				<path d="M22.875 27.937c-.4 0-.776-.156-1.06-.438l-2.503-2.505-2.503 2.505c-.284.282-.66.438-1.06.438s-.776-.156-1.06-.438l-3.563-3.563a1.503 1.503 0 010-2.12c.285-.282.66-.438 1.06-.438s.777.156 1.06.438l2.503 2.504 2.504-2.504a1.491 1.491 0 011.06-.438c.4 0 .777.156 1.06.438l2.502 2.504 2.505-2.504a1.491 1.491 0 011.059-.438c.4 0 .776.156 1.06.438a1.503 1.503 0 010 2.12l-3.563 3.563c-.285.282-.66.438-1.06.438zm-3.563-24.94c-9.149 0-16.316 7.167-16.316 16.316A16.346 16.346 0 009.94 32.671c2.38 1.673 3.745 4.174 3.745 6.861v3.461H24.94v-3.237c0-2.806 1.397-5.411 3.833-7.148a16.36 16.36 0 006.857-13.295c0-8.997-7.32-16.316-16.318-16.316zm-5.627 47.122H24.94v-4.13H13.685v4.13zm.319 3.372a5.637 5.637 0 005.309 3.754c2.35 0 4.349-1.436 5.215-3.748l.143-.383h-10.8l.133.377zm5.309 6.75c-4.09 0-7.643-2.902-8.45-6.899l-.047-.228h-3.69v-2.995h3.564v-4.131H7.126v-2.995h3.564v-3.461c0-1.698-.901-3.305-2.473-4.409a19.349 19.349 0 01-8.216-15.81C0 8.664 8.665 0 19.312 0a19.19 19.19 0 0113.66 5.653 19.188 19.188 0 015.654 13.66 19.364 19.364 0 01-8.113 15.735c-1.638 1.168-2.577 2.884-2.577 4.708v3.237H31.5v2.995h-3.564v4.131H31.5v2.995h-3.755l-.05.22c-.956 4.131-4.325 6.907-8.383 6.907z" />
			</g>
		</g>
	</svg>
);
