import { makeStyles } from '@material-ui/core';

import { getStrictDimensionsForDiameter, getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		list: {
			'& > li': {
				borderBottomColor: getThemeStyle('palettePrimary50'),
				padding: 0,
			},
			'&$listInline': {
				display: 'flex',
				'& > li': {
					borderLeftColor: 'transparent',
					borderRight: `1px solid ${getThemeStyle('palettePrimary50')}`,
					'&:last-child': {
						borderRightColor: 'transparent',
					},
				},
				'& $listItem': {
					display: 'block',
					padding: [[spacingUnits(4), spacingUnits(3)]],
					textAlign: 'center',
				},
				'& $listItemContent': {
					margin: [[spacingUnits(3), 0]],
				},
				'& $icon': {
					...getStrictDimensionsForDiameter(151),
					margin: [[0, 'auto']],
				},
			},
		},

		listItem: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			padding: [[spacingUnits(3), spacingUnits(2)]],
		},

		listItemContent: {
			flexGrow: 1,
		},

		icon: {
			...getStrictDimensionsForDiameter(64),
			marginRight: spacingUnits(2),

			'& > *': {
				width: '100%',
			},
		},

		chevron: {
			...getStrictDimensionsForDiameter(24),
			fill: getThemeStyle('palettePrimary700'),
			margin: 'auto',
			marginLeft: spacingUnits(1),
		},

		button: {
			minWidth: 135,
			padding: [[spacingUnits(1.5), spacingUnits(2)]],
		},

		subtitle: {
			color: getThemeStyle('paletteTextPrimary'),
			lineHeight: '1.8rem',
		},

		listInline: {},
	},
	{ name: 'support-navigation-card' },
);
