import React from 'react';
import { Button } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaymentRoutes } from '../../../../enums/routerPath';
import { RootState } from '../../../../store/rootReducer';
import { payNowWithAmount } from '../../../../store/features/paymentForm/paymentFormSlice';

type SinglePaymentButtonProps = {
	buttonText?: string;
};

export const SinglePaymentButton: React.FC<SinglePaymentButtonProps> = ({ buttonText = 'I‘m ready to make a payment' }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { ccPIFOnly, outstandingAmount } = useSelector(
		(state: RootState) => ({
			ccPIFOnly: state.paymentMethod.ccPIFOnly,
			outstandingAmount: state.customer.customerDetails?.outstandingAmt,
		}),
		shallowEqual,
	);

	const handleClick = () =>
		ccPIFOnly ? dispatch(payNowWithAmount(outstandingAmount?.toString() ?? '0')) : history.push(PaymentRoutes.SINGLE_PAYMENT.path);

	return (
		<Button color="secondary" fullWidth size="large" variant="contained" onClick={handleClick}>
			{buttonText}
		</Button>
	);
};
