import { makeStyles } from '@material-ui/core';
import { getThemeStyle, spacingUnits } from '../../../../theme/theme';
import MuiTheme from '../../../../theme';

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			minHeight: '100vh',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			overflowX: 'hidden',
		},
		container: {
			width: '100%',
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			marginTop: spacingUnits(10),
			padding: [[spacingUnits(10), spacingUnits(2)]],
			justifyContent: 'center',
			textAlign: 'center',

			'& p': {
				marginTop: spacingUnits(3),
			},

			[MuiTheme.breakpoints.up('md')]: {
				'& h1': {
					fontSize: '6rem',
					lineHeight: '6.2rem',
				},

				'& p': {
					fontSize: '2.5rem',
					lineHeight: '3rem',
				},
			},
		},
		icon: {
			height: 'auto',
			maxWidth: '800px',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: spacingUnits(6),
			marginBottom: spacingUnits(6),

			[MuiTheme.breakpoints.up('md')]: {
				marginTop: spacingUnits(12),
				marginBottom: spacingUnits(12),
			},
		},
		button: {
			marginLeft: 'auto',
			marginRight: 'auto',
			maxWidth: '800px',
		},
	},
	{ name: 'fourOhFour' },
);
