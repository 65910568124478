import React from 'react';

export const IRAP = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 251.1 251.1" {...props}>
		<path fill="none" stroke="#c00000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={5} d="M143.77 100.17l7.66 6.83 11.04-13.51" />
		<path
			d="M191.73 66.42c-24.47 2.89-39.67-11.18-39.67-11.18s-15.2 14.07-39.68 11.18c0 0-7.64 64.84 39.68 78.83 47.31-13.99 39.67-78.83 39.67-78.83z"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeMiterlimit={10}
			strokeWidth={5}
		/>
		<path
			d="M152.06 133.23l-1.21-.47c-23.68-9.16-27.56-36.69-27.77-52.28v-3.17l3.16-.2a54.48 54.48 0 0024.16-7.5l1.7-1 1.7 1a54.55 54.55 0 0024.12 7.5l3.17.21-.09 3.17c-.25 15.6-4.19 43.14-27.74 52.27zm-22.22-49.76c.62 13.88 4.62 34.93 22.21 42.58 17.49-7.63 21.52-28.69 22.18-42.59a61.1 61.1 0 01-22.17-7.14 61.45 61.45 0 01-22.22 7.15z"
			fill="#fccccc"
		/>
		<path
			d="M72.23 108.24l-13.9 4.21a26.59 26.59 0 00-17.12 15.9C36.34 141 29.86 161 26.62 175.3c-4 17.68-6.8 29.13-6.8 54.78M72.83 161.26c0 5.3 5.49 8.53 9.76 5.37 13-9.53 20.25-19.53 25.67-26.64a9.57 9.57 0 0113.74-1.65 2.65 2.65 0 00.3.25c5 4.35 4.44 8.56 0 15.34-4 6.07-9.23 12.77-12.49 17.9a99.06 99.06 0 00-8.22 16.53c-7.33 18.23-19.07 26-27.27 29.36A15.16 15.16 0 0065 231.79v0"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<path
			d="M90.2 231.81h126.09a15 15 0 0015-15V36.1a16.82 16.82 0 00-16.81-16.81H89.64A16.82 16.82 0 0072.83 36.1v125.16"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeMiterlimit={10}
			strokeWidth={5}
		/>
		<path
			d="M122.73 19.29v8.22a9.09 9.09 0 009.09 9.09h40.48a9.09 9.09 0 009.09-9.09v-8.22"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeMiterlimit={10}
			strokeWidth={5}
		/>
		<path d="M221.29 216.81v-53.1l-58.1 58.1h53.1a5 5 0 005-5z" fill="#fccccc" />
	</svg>
);
