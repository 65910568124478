import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),

			'& $buttonStack': {
				flexGrow: 'unset',
			},
		},
		card: {
			padding: [[spacingUnits(6), spacingUnits(8), spacingUnits(7)]],
		},
		buttonStack: {},
	},
	{ name: 'paymentFailed' },
);
