import React from 'react';

export const BubbleQuestionMark = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<path
			d="M39.718 24.42c-4.962 0-8.999 4.036-8.999 8.998h3.6a5.404 5.404 0 0 1 5.4-5.399 5.404 5.404 0 0 1 5.398 5.4c0 1.33-.488 2.607-1.375 3.6l-5.824 6.511v4.287h3.6v-2.912l4.907-5.487a8.983 8.983 0 0 0 2.293-6c0-4.962-4.038-8.999-9-8.999zm-1.8 30.598h3.6v-3.6h-3.6v3.6zm1.8 11.82a27.06 27.06 0 0 1-14.155-3.985l-.659-.405-10.814 2.898 2.9-10.814-.406-.659A27.05 27.05 0 0 1 12.6 39.718C12.6 24.765 24.765 12.6 39.718 12.6c14.954 0 27.118 12.165 27.118 27.118s-12.164 27.12-27.118 27.12zm21.722-48.84C55.637 12.194 47.923 9 39.718 9c-8.205 0-15.919 3.195-21.722 8.997C12.196 23.8 9 31.513 9 39.718c0 5.412 1.42 10.707 4.114 15.364L9 70.437l15.354-4.115a30.657 30.657 0 0 0 15.364 4.115c8.205 0 15.92-3.196 21.722-8.997 5.801-5.803 8.997-13.517 8.997-21.722 0-8.205-3.196-15.919-8.997-21.72z"
			fillRule="evenodd"
		/>
	</svg>
);
