import { makeStyles } from '@material-ui/core/styles';

import { spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $secureBadge': {
				height: 26,
				width: 92,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				marginBottom: spacingUnits(2.5),
			},
		},
		secureBadge: {},
	},
	{ name: 'directDebitPaymentForm' },
);
