import React from 'react';
import { Link, LinkProps, LinkTypeMap, makeStyles } from '@material-ui/core';
import { getThemeStyle } from '../../theme/theme';

const Arrow = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg width="32" height="32" viewBox="0 0 32 32" {...props}>
		<g fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
			<circle cx="16" cy="16" r="15.12" />
			<path d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98" />
		</g>
	</svg>
);

const useStyles = makeStyles(
	() => ({
		root: {
			fontWeight: 500,
			display: 'inline-block',
			whiteSpace: 'nowrap',
			transition: 'all 0.3s ease',

			'& $arrowIcon': {
				position: 'relative',
				top: '-1px',
				transition: 'transform 0.3s ease',
				verticalAlign: 'middle',
			},

			'& circle': {
				transition: 'stroke-dashoffset .3s ease',
				strokeDasharray: 95,
				strokeDashoffset: 95,
			},

			'&:hover': {
				color: getThemeStyle('paletteSecondary400'),
				'& $arrowIcon': {
					transform: 'translate3d(5px, 0, 0)',
				},

				'& circle': {
					strokeDashoffset: 0,
				},
			},
		},
		arrowIcon: {},
		arrowIconCircle: {},
	}),
	{ name: 'linkArrow' },
);

export const LinkArrow = <D extends React.ElementType = LinkTypeMap['defaultComponent'], P = {}>(props: LinkProps<D, P>) => {
	const styles = useStyles();
	return (
		<Link className={styles.root} color="secondary" underline="none" {...props}>
			{props.children} <Arrow className={styles.arrowIcon} />
		</Link>
	);
};
