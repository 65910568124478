export enum LoginOptions {
	DOB = 'DOB',
	PASSWORD = 'passwordOnly',
	MOBILE = 'Mobile',
	PHONE = 'Phone',
	EMAIL = 'Email',
	POSTCODE = 'Postcode',
	REDIRECT2LEGACY = 'redirect2Legacy',
}

export enum LoginOptionsString {
	DOB = 'Date of Birth',
	PASSWORD = 'Password',
	MOBILE = 'Mobile number',
	PHONE = 'Phone number',
	EMAIL = 'Email address',
	POSTCODE = 'Postcode',
}
