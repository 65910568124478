import { persistRootKey } from '../store/store';
import { getAppInsights } from '../elements/TelemetryProvider/telemetryService';

export const clearLocalStorage = () => {
	localStorage.removeItem('token');
	sessionStorage.removeItem(`persist:${persistRootKey}`);
	const appInsights = getAppInsights();
	if (appInsights) {
		appInsights.clearAuthenticatedUserContext();
	}
};
