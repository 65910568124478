import { Button } from '@material-ui/core';
import React from 'react';

import { AssistantCTAHollow } from '../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { IconCard } from '../../../../../elements/IconCard/iconCard';
import { LockedModal } from '../../../../../elements/LockedModal/lockedModal';
import { Spacing } from '../../../../../elements/Spacing/spacing';

type Props = {
	onClose: () => void;
	email?: string;
	phone?: string;
};

export const RecurringPaymentSummarySent: React.FC<Props> = ({ onClose, phone, email }) => {
	return (
		<>
			<LockedModal onClose={onClose} showCloseButton open={true}>
				<Spacing units={2}>
					<AssistantCTAHollow
						subtitle="Please allow up to 15 minutes for the message to be received. Make sure you check your junk inbox in case it gets caught by filters."
						title="We've sent that to you"
					>
						<IconCard
							lines={[
								{
									subtitle: 'Successfully sent to',
									body: phone || email,
								},
								{
									body: !phone && email ? null : email,
								},
							]}
							status="success"
						/>
					</AssistantCTAHollow>
					<Button color="primary" fullWidth variant="outlined" onClick={onClose}>
						Close
					</Button>
				</Spacing>
			</LockedModal>
		</>
	);
};
