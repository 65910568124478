import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits, toolbarLoggedInHeight } from '../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			position: 'fixed',
			top: toolbarLoggedInHeight,
			left: 0,
			right: 0,
			zIndex: getThemeStyle('zIndexAppBar') - 1,
			height: 0,
			'&$rootOpen': {
				height: `calc(100% - ${toolbarLoggedInHeight}px)`,
			},
			'& $flyout': {
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: getThemeStyle('paletteCommonWhite'),
			},
			'& .MuiCollapse-entered': {
				height: '100% !important',
				'& .MuiCollapse-wrapper': {
					height: '100%',
					overflow: 'auto',
				},
			},
			'& $buttons': {
				margin: [[spacingUnits(2), spacingUnits(3), spacingUnits(3)]],
				borderRadius: spacingUnits(0.75),
				'& .MuiButtonBase-root': {
					padding: [[spacingUnits(3), spacingUnits(2)]],
					color: getThemeStyle('paletteSecondary100'),
					backgroundColor: getThemeStyle('paletteSecondary50'),
					'& .MuiButton-label': {
						color: getThemeStyle('paletteTertiary900'),
						alignItems: 'center',
						justifyContent: 'flex-start',
						fontSize: '2rem',
						fontWeight: 500,
					},
				},
				'& $buttonIcon': {
					height: spacingUnits(3),
					width: spacingUnits(3),
					fill: getThemeStyle('paletteSecondary500'),
				},
			},
			'& $footer': {
				width: '100%',
				marginTop: 'auto',
				padding: [[spacingUnits(2.5), spacingUnits(2.5), spacingUnits(3)]],
				backgroundColor: getThemeStyle('palettePrimary400'),
				color: getThemeStyle('paletteCommonWhite'),
				'& ul': {
					padding: 0,
					margin: 0,
					listStyle: 'none',
					'& li': {
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginBottom: spacingUnits(2),
						'& h6': {
							fontWeight: 500,

							'& > a': {
								color: getThemeStyle('paletteCommonWhite'),
								textDecoration: 'none',
							},
						},
						'&:last-of-type': {
							marginBottom: 0,
						},
						'& $icon': {
							width: spacingUnits(4),
							height: spacingUnits(4),
							fill: getThemeStyle('paletteCommonWhite'),
							marginRight: spacingUnits(0.75),
						},
						'& $title': {
							fontFamily: getThemeStyle('typographyFontFamily'),
							fontWeight: 400,
							marginBottom: spacingUnits(0.25),
						},
					},
				},
			},
		},
		rootOpen: {},
		flyout: {},
		buttons: {},
		buttonIcon: {},
		footer: {},
		icon: {},
		title: {},
	},
	{ name: 'userMenuFlyout' },
);
