import { AddressType, AddressTypeString, ContactType, ContactTypeString } from '../../enums/contactTypes';
import { LoginOptions, LoginOptionsString } from '../../enums/loginOptions';
import { AllPaymentMethods } from '../../enums/paymentForm';
import { PeriodicFrequencyID, PeriodicFrequencyString } from '../../enums/periodicFrequency';

export const thousands = (input: number | string = 0, separator = ',') => String(input).replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const round = (value = 0, places = 2) => Math.round(value * Math.pow(10, places)) / Math.pow(10, places);

export const currency = (input = 0, prefix = '$', thousandsSeparator = ',', decimal = '.') => {
	const money = thousands(round(input).toFixed(2), thousandsSeparator);
	if (money.startsWith('-')) {
		return `-${prefix}${money.substr(1)}`;
	}
	return `${prefix}${money}`;
};

export const pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const formatMoney = (amount: number, hideSymbol = false, minimumFractionDigits?: number) =>
	amount.toLocaleString('en-US', {
		style: hideSymbol ? 'decimal' : 'currency',
		currency: 'USD',
		...(minimumFractionDigits && { minimumFractionDigits }),
	});

export const convertPaymentFreqToString = (frequencyID: number): string => {
	const id: number = frequencyID;
	let str = '';
	switch (id) {
		case PeriodicFrequencyID.ONE_OFF:
			str = PeriodicFrequencyString.ONE_OFF;
			break;
		case PeriodicFrequencyID.WEEKLY:
			str = PeriodicFrequencyString.WEEKLY;
			break;
		case PeriodicFrequencyID.FORTNIGHTLY:
			str = PeriodicFrequencyString.FORTNIGHTLY;
			break;
		case PeriodicFrequencyID.MONTHLY:
			str = PeriodicFrequencyString.MONTHLY;
			break;
		default:
			break;
	}
	return str;
};

export const convertPaymentMethodFromString = (paymentMethod: string): AllPaymentMethods => {
	switch (paymentMethod) {
		case AllPaymentMethods.CARD.toString():
			return AllPaymentMethods.CARD;
		case AllPaymentMethods.BPAY.toString():
			return AllPaymentMethods.BPAY;
		case AllPaymentMethods.DIRECT.toString():
			return AllPaymentMethods.DIRECT;
		case AllPaymentMethods.DEPOSIT.toString():
			return AllPaymentMethods.DEPOSIT;
		case AllPaymentMethods.EXTERNAL.toString():
			return AllPaymentMethods.EXTERNAL;
		default:
			return AllPaymentMethods.OTHER;
	}
};

export const convertAddressTypeToString = (addressTypeID: number): string => {
	const id: number = addressTypeID;
	let str = '';
	switch (id) {
		case AddressType.PRIMARY:
			str = AddressTypeString.PRIMARY;
			break;
		case AddressType.MAILING:
			str = AddressTypeString.MAILING;
			break;
		case AddressType.RESIDENTIAL:
			str = AddressTypeString.RESIDENTIAL;
			break;
		default:
			str = AddressTypeString.OTHER;
			break;
	}
	return str;
};

export const convertContactTypeToString = (contactTypeID: number): string => {
	const id: number = contactTypeID;
	let str = '';
	switch (id) {
		case ContactType.Phone_Home:
			str = ContactTypeString.Phone_Home;
			break;
		case ContactType.Phone_Work:
			str = ContactTypeString.Phone_Work;
			break;
		case ContactType.Phone_Mobile:
			str = ContactTypeString.Phone_Mobile;
			break;
		case ContactType.Email_Work:
			str = ContactTypeString.Email_Work;
			break;
		case ContactType.Email_Personal:
			str = ContactTypeString.Email_Personal;
			break;
		default:
			break;
	}
	return str;
};

export const convertLoginOptionToString = (loginOption: string | LoginOptions): string => {
	let str = '';
	switch (loginOption) {
		case LoginOptions.DOB:
			str = LoginOptionsString.DOB;
			break;
		case LoginOptions.PASSWORD:
			str = LoginOptionsString.PASSWORD;
			break;
		case LoginOptions.MOBILE:
			str = LoginOptionsString.MOBILE;
			break;
		case LoginOptions.PHONE:
			str = LoginOptionsString.PHONE;
			break;
		case LoginOptions.EMAIL:
			str = LoginOptionsString.EMAIL;
			break;
		case LoginOptions.POSTCODE:
			str = LoginOptionsString.POSTCODE;
			break;
		default:
			break;
	}
	return str;
};
