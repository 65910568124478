import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { AccountRoutes } from '../../../../../../../enums/routerPath';
import { DebtDetailsCard } from '../../../../DebtDetailsPanel/DebtDetailsCard/debtDetailsCard';
import { PaymentPlanButton } from '../../../../PaymentPlanButton/paymentPlanButton';
import { SinglePaymentButton } from '../../../../SinglePaymentButton/singlePaymentButton';

export const ActiveGeneralLanding: React.FC = () => {
	return (
		<>
			<AssistantCTAHollow
				subtitle="It‘s important you let us know what your intentions are for this account."
				title="We haven't had any updates from you lately"
			/>
			<DebtDetailsCard size="small" />
			<ButtonStack style={{ marginTop: 0 }}>
				<SinglePaymentButton />
				<PaymentPlanButton />
				<Button color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
					Show me my account
				</Button>
			</ButtonStack>
		</>
	);
};
