/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import { parseFullName } from 'parse-full-name';
import { AppThunk } from '../../store';
import arlApi from '../../../interceptor/api';
import { WebRoutes } from '../../../enums/routerPath';
import { ILogin, JWTUser, AuthAPI } from './types';
import { history } from '../../history';
import { clearLocalStorage } from '../../../actions/clearStorage';
import { getAppInsights } from '../../../elements/TelemetryProvider/telemetryService';

let existingUser: JWTUser | undefined;

// Check local storage for unexpired token
if (localStorage.getItem('token')) {
	const decodedToken = jwt_decode(localStorage.getItem('token') as string) as JWTUser;
	if (Date.now() <= decodedToken.exp * 1000) {
		existingUser = decodedToken;
		if (decodedToken.customer?.fullName) {
			const parsedName = parseFullName(decodedToken.customer.fullName);
			existingUser.displayName = `${parsedName.first}${parsedName.middle ? ` ${parsedName.middle}` : ''}${
				parsedName.last ? ` ${parsedName.last}` : ''
			}`;
			existingUser.displayNameShort = parsedName.first;
		}
	}
}

export interface AuthState {
	user: JWTUser | undefined;
	mfa: boolean;
	timedOut: boolean;
	loading: boolean;
	error: string | undefined;
}

export const initialState: AuthState = {
	user: existingUser,
	mfa: false,
	timedOut: false,
	loading: false,
	error: undefined,
};

function startLoading(state: AuthState) {
	state.loading = true;
	state.error = undefined;
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		getRef: startLoading,
		getRefSuccess(state: AuthState, action: PayloadAction<JWTUser>) {
			state.loading = false;
			state.user = action.payload;
			state.error = undefined;
			if (state.user.authTypes?.length === 0) {
				state.error = `Sorry, we are unable to log you into your account at the moment. Please contact our office on ${window.config.REACT_APP_CONTACT_NUMBER} to speak with one of our customer service representatives`;
			}
		},
		getRefFailure(state: AuthState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		clearRef(state: AuthState) {
			state.user = undefined;
			clearLocalStorage();
		},
		getAuth: startLoading,
		getAuthSuccess(state: AuthState, action: PayloadAction<JWTUser>) {
			state.loading = false;
			state.user = action.payload;
			state.error = undefined;
			state.mfa = false;
		},
		getAuthMfaSuccess(state: AuthState, action: PayloadAction<JWTUser>) {
			state.loading = false;
			state.error = undefined;
			state.mfa = true;
		},
		getAuthFailure(state: AuthState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		getConfirm: startLoading,
		getConfirmSuccess(state: AuthState, action: PayloadAction<JWTUser>) {
			state.loading = false;
			const parsedName = parseFullName(action.payload.customer?.fullName ?? '');
			const user: JWTUser = {
				...action.payload,
				displayName: `${parsedName.first}${parsedName.middle ? ` ${parsedName.middle}` : ''}${parsedName.last ? ` ${parsedName.last}` : ''}`,
				displayNameShort: parsedName.first,
			};
			state.user = user;
			state.error = undefined;
			const appInsights = getAppInsights();
			if (appInsights) {
				appInsights.setAuthenticatedUserContext(action.payload.referenceNumber.toString(), action.payload.customer?.debtID.toString(), true);
			}
		},
		getConfirmFailure(state: AuthState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		logout(state: AuthState) {
			clearLocalStorage();
			state.user = undefined;
			history.push(WebRoutes.HOME.path);
		},
		timedOut(state: AuthState) {
			clearLocalStorage();
			state.user = undefined;
			state.timedOut = true;
			history.push(WebRoutes.CUSTOMERS.path);
		},
		clearTimedOut(state: AuthState) {
			state.timedOut = false;
		},
		resetAuth() {
			clearLocalStorage();
			return initialState;
		},
		refreshAuthToken: startLoading,
		refreshAuthTokenSuccess(state: AuthState, action: PayloadAction<JWTUser>) {
			state.loading = false;
			state.error = undefined;
		},
		refreshAuthTokenFailure(state: AuthState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const {
	getRef,
	getRefSuccess,
	getRefFailure,
	clearRef,
	getAuth,
	getAuthSuccess,
	getAuthMfaSuccess,
	getAuthFailure,
	getConfirm,
	getConfirmSuccess,
	getConfirmFailure,
	logout,
	timedOut,
	clearTimedOut,
	resetAuth,
	refreshAuthToken,
	refreshAuthTokenSuccess,
	refreshAuthTokenFailure,
} = authSlice.actions;

export default authSlice.reducer;

export const loginCheck =
	(reference: string, captchaToken: string): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getRef());
			const { data } = await arlApi.get<ILogin>(AuthAPI.REFERENCE_CHECK, {
				params: {
					reference,
					captchaToken,
				},
			});
			if (data.success && data.data.jwt) {
				const decodedToken = jwt_decode(data.data.jwt) as JWTUser;
				dispatch(getRefSuccess(decodedToken));
				localStorage.setItem('token', data.data.jwt);
			} else {
				dispatch(getRefFailure(data.message));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getRefFailure(e.response?.data.message));
			} else {
				dispatch(getRefFailure('An unknown error occured.'));
			}
		}
	};

export const loginAuth =
	(authType: string, authData: string): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getAuth());
			const { data } = await arlApi.get<ILogin>(AuthAPI.VERIFY_ID, {
				params: {
					authType,
					authData,
				},
			});
			if (data.success && data.data.jwt) {
				const decodedToken = jwt_decode(data.data.jwt) as JWTUser;
				if (decodedToken.verified) {
					dispatch(getAuthSuccess(decodedToken));
					localStorage.setItem('token', data.data.jwt);
				} else if (decodedToken.mfa) {
					dispatch(getAuthMfaSuccess(decodedToken));
					localStorage.setItem('token', data.data.jwt);
				} else {
					dispatch(getAuthFailure(data.message));
				}
			} else {
				dispatch(getAuthFailure(data.message));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getAuthFailure(e.response?.data.message));
			} else {
				dispatch(getAuthFailure('The details are not valid, please try again'));
			}
		}
	};

export const loginMfa =
	(code: string): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getAuth());
			const { data } = await arlApi.post<ILogin>(AuthAPI.MFA, {
				code,
			});
			if (data.success && data.data.jwt) {
				const decodedToken = jwt_decode(data.data.jwt) as JWTUser;
				if (decodedToken.verified) {
					dispatch(getAuthSuccess(decodedToken));
					localStorage.setItem('token', data.data.jwt);
				} else {
					dispatch(getAuthFailure(data.message));
				}
			} else {
				dispatch(getAuthFailure(data.message));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getAuthFailure(e.response?.data.message));
			} else {
				dispatch(getAuthFailure('The details are not valid, please try again'));
			}
		}
	};

export const loginConfirm =
	(confirm: boolean): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getConfirm());
			const { data } = await arlApi.get<ILogin>(AuthAPI.CONFIRM, {
				params: {
					confirm,
				},
			});
			if (data.success && data.data.jwt) {
				const decodedToken = jwt_decode(data.data.jwt) as JWTUser;
				if (decodedToken.authenticated) {
					dispatch(getConfirmSuccess(decodedToken));
					localStorage.setItem('token', data.data.jwt);
				} else {
					dispatch(getConfirmFailure(data.message));
				}
			} else {
				dispatch(getConfirmFailure(data.message));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getConfirmFailure(e.response?.data.message));
			} else {
				dispatch(getConfirmFailure('An unknown error occured.'));
			}
		}
	};

export const refreshAuth = (): AppThunk => async (dispatch) => {
	try {
		dispatch(refreshAuthToken());
		const { data } = await arlApi.get<ILogin>(AuthAPI.REFRESH_TOKEN);
		if (data.success && data.data.jwt) {
			const decodedToken = jwt_decode(data.data.jwt) as JWTUser;
			if (decodedToken && decodedToken.authenticated) {
				localStorage.setItem('token', data.data.jwt);
				dispatch(refreshAuthTokenSuccess(decodedToken));
			} else {
				dispatch(refreshAuthTokenFailure(data.message));
			}
		} else {
			dispatch(refreshAuthTokenFailure(data.message));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(refreshAuthTokenFailure(e.response?.data.message));
		} else {
			dispatch(refreshAuthTokenFailure('An unknown error occurred.'));
		}
	}
};
