import { makeStyles } from '@material-ui/core/styles';
import { getThemeStyle, spacingUnits } from '../../../../../../theme/theme';
import MuiTheme from '../../../../../../theme';

export default makeStyles(
	{
		root: {
			'& p': {
				marginBottom: spacingUnits(3),

				'& + ul': {
					marginTop: -spacingUnits(2.5),
				},
			},
			'& h2, & h3, & h4, & h5, & h6': {
				marginBottom: spacingUnits(2),
			},
			'& $rootWithHeader': {
				'& $body': {
					paddingTop: 0,
				},
			},
			'& $header': {
				flexShrink: 0,
				padding: [[spacingUnits(3), spacingUnits(7), spacingUnits(3), spacingUnits(3) - 2]],
				backgroundColor: getThemeStyle('paletteCommonWhite'),
				zIndex: 2,
			},
			'& $body': {
				flexShrink: 1,
				flexGrow: 1,
				overflowY: 'auto',
				padding: spacingUnits(3),
				position: 'relative',
				zIndex: 1,
			},
			'& $footer': {
				flexShrink: 0,
				padding: spacingUnits(3),
				backgroundColor: getThemeStyle('paletteCommonWhite'),
				// boxShadow: getThemeStyle('shadowsMinus8'),
				position: 'relative',
				zIndex: 2,
			},

			'& ul': {
				fontSize: getThemeStyle('typographyBody1FontSize'),
				lineHeight: getThemeStyle('typographyBody1LineHeight'),
				marginBottom: spacingUnits(3),
				[MuiTheme.breakpoints.up('md')]: {
					fontSize: getThemeStyle('typographyBody1DesktopFontSize'),
					lineHeight: getThemeStyle('typographyBody1DesktopLineHeight'),
				},
			},
		},
		rootWithHeader: {},
		header: {},
		body: {},
		footer: {},
	},
	{ name: 'acceptTermsModal' },
);
