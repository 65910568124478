import { makeStyles } from '@material-ui/core/styles';

import MuiTheme from '../../../../../../../../theme';

import { getThemeStyle, spacingUnits } from '../../../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('palettePrimary50'),
			'& $row': {
				padding: [[spacingUnits(1.5), spacingUnits(3)]],
				borderTop: `1px solid ${getThemeStyle('palettePrimary100')}`,

				[MuiTheme.breakpoints.up('md')]: {
					borderLeft: `1px solid ${getThemeStyle('palettePrimary100')}`,
					borderRight: `1px solid ${getThemeStyle('palettePrimary100')}`,
					'&:first-child': {
						borderTopLeftRadius: '10px',
						borderTopRightRadius: '10px',
					},
					'&:last-child': {
						borderBottomLeftRadius: '10px',
						borderBottomRightRadius: '10px',
					},
				},

				'&$rowPrimary': {
					'& $value': {
						fontFamily: getThemeStyle('typographyFontFamily'),
						fontWeight: 500,
					},
				},
				'& $value': {
					fontFamily: getThemeStyle('typographyFontFamily'),
					fontWeight: 400,
					wordBreak: 'break-all',
					whiteSpace: 'break-spaces',
				},
				'& $secondaryValue': {
					fontWeight: 400,
					fontSize: '1.3rem',
					fontFamily: getThemeStyle('typographyFontFamily'),
				},
				'& $dateTrash': {
					display: 'flex',
					alignItems: 'center',

					'& $date': {
						textAlign: 'right',
						flexGrow: 1,
						fontWeight: 400,
						fontSize: '1.3rem',
						fontFamily: getThemeStyle('typographyFontFamily'),

						'& span': {
							fontWeight: 'inherit',
							fontSize: 'inherit',
							fontFamily: 'inherit',
						},
						'& span + span': {
							whiteSpace: 'nowrap',
						},
					},
					'& $trash': {
						height: 24,
						width: 24,
						flexShrink: 0,
						cursor: 'pointer',
						marginLeft: spacingUnits(0.5),
						'& svg': {
							fill: getThemeStyle('paletteTextHint'),
						},
					},
				},
				'& $empty': {
					textAlign: 'center',
					fontWeight: 400,
					fontSize: '1.3rem',
					fontFamily: getThemeStyle('typographyFontFamily'),
				},
			},
			'& $addButtonWrapper': {
				width: '100%',
				display: 'flex',
				borderTop: `1px solid ${getThemeStyle('palettePrimary100')}`,
				borderBottom: `1px solid ${getThemeStyle('palettePrimary100')}`,
				textAlign: 'left',

				'& $addButton': {
					fontFamily: getThemeStyle('typographyFontFamily'),
					fontWeight: 500,
					flexGrow: 1,
				},
				'& $icon': {
					flexShrink: 0,
					height: 24,
					width: 24,
					'& svg': {
						fill: getThemeStyle('palettePrimary500'),
					},
				},
			},
		},
		row: {},
		rowPrimary: {},
		value: {},
		secondaryValue: {},
		dateTrash: {},
		date: {},
		trash: {},
		empty: {},
		addButtonWrapper: {},
		addButton: {},
		icon: {},
	},
	{ name: 'settingsList' },
);
