import React from 'react';

export const ThirdPartyCollections = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 128.94 128.94" {...props}>
		<path
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
			d="M22.72 117.01v10.43M12.02 117.01v10.43M23.39 97.94A5.94 5.94 0 1117.46 92a5.93 5.93 0 015.93 5.94zM5.12 127.44v-15A3.86 3.86 0 019 108.6h17a3.85 3.85 0 013.86 3.86v15M116.75 117.01v10.43M106.05 117.01v10.43"
		/>
		<circle cx={111.49} cy={97.94} r={5.93} fill="none" stroke="#c00" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
		<path
			d="M99.15 127.44v-15a3.86 3.86 0 013.85-3.84h17a3.86 3.86 0 013.86 3.86v15M72.92 52.66a13 13 0 0012.45-8.8 13.76 13.76 0 00.73-4.25 22.21 22.21 0 00-4.51-13.28l-9.67-15.65H55.69l-9.88 15.65a22.28 22.28 0 00-4.51 13.28v.14a13.16 13.16 0 0013.21 12.91z"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M71.92 10.68l6-5.82-2.16-2.15a4.13 4.13 0 00-5.84 0l-1.74 1.74-.77-.77a5.11 5.11 0 00-7.22 0l-.76.77-1.74-1.74a4.13 4.13 0 00-5.84 0l-2.16 2.15 6 5.82M54.88 86.26v41.18M63.7 127.44v-25.29M72.53 102.15v25.29M72.53 86.26v15.89M70.39 69.81a6.69 6.69 0 11-6.69-6.68 6.69 6.69 0 016.69 6.68zM54.88 102.15h17.65M63.7 80.92v7.74"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
			d="M63.7 80.92h7.83l9.67-9.67V60.79M63.7 80.92h-7.83l-9.66-9.67V60.79M92.08 38.93s19.41 9.84 19.41 40.1M105.98 81.33l5.51 5.51 5.51-5.51M36.22 38.93S16.81 48.77 16.81 79M22.32 81.33l-5.51 5.51-5.51-5.51"
		/>
		<path
			d="M76.78 29.91l-.16-.21-8.05-13H59a1.48 1.48 0 00.37 2 24.21 24.21 0 014.75 4.46c4.79 6 6.35 14.62 3.14 21.56a18.61 18.61 0 01-1.07 2h6.73A7 7 0 0079.67 42a8.14 8.14 0 00.43-2.41 16.38 16.38 0 00-3.32-9.68z"
			fill="#fcc"
		/>
	</svg>
);
