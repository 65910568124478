import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { AccountRoutes } from '../../../../../../../enums/routerPath';
import { BPAYDetailsLockedModal } from '../../../../../scenes/PaymentModules/BpayDetailsLockedModal/bpayDetailsLockedModal';
import { DebtDetailsCard } from '../../../../DebtDetailsPanel/DebtDetailsCard/debtDetailsCard';
import { PAThanksHollow } from '../../../../PaymentArrangement/elements/Thanks/hollow';
import { SinglePaymentButton } from '../../../../SinglePaymentButton/singlePaymentButton';

type PayingProps = {
	extraPaymentsAllowed: boolean;
	ccAllowed: boolean;
};

export const PayingGeneralLanding: React.FC<PayingProps> = ({ extraPaymentsAllowed = false, ccAllowed = false }) => {
	const [bpayModalOpen, setBpayModalOpen] = useState(false);

	const handleClick = () => {
		setBpayModalOpen(true);
	};

	const btnText = 'Make extra payments';

	return (
		<>
			<PAThanksHollow />
			<DebtDetailsCard color="default" size="small" />
			<ButtonStack>
				{extraPaymentsAllowed && (
					<>
						{ccAllowed ? (
							<SinglePaymentButton buttonText={btnText} />
						) : (
							<Button color="secondary" fullWidth size="large" variant="contained" onClick={handleClick}>
								{btnText}
							</Button>
						)}
					</>
				)}
				<Button color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
					Show me my account
				</Button>
			</ButtonStack>
			{bpayModalOpen && (
				<BPAYDetailsLockedModal
					onClose={() => {
						setBpayModalOpen(false);
					}}
				/>
			)}
		</>
	);
};
