/* eslint-disable react/no-array-index-key */
import { Card, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import circleCheckHollow from '../../static/images/circle-check-hollow.png';
import useStyles from './iconCard.styles';

type Props = {
	status: 'success';
	lines: Array<React.ReactNode | { subtitle?: string; body?: string }>;
	className?: string;
};

export const IconCard: React.FC<Props> = ({ className, status, lines, ...props }) => {
	const styles = useStyles();
	return (
		<Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
			<Grid item xs={12} md={12} lg={9}>
				<Card className={clsx(styles.root, className)} elevation={8} {...props}>
					<Grid container>
						<Grid item xs={3}>
							{status === 'success' && <div className={styles.checkIcon} style={{ backgroundImage: `url(${circleCheckHollow})` }} />}
						</Grid>
						<Grid item xs={9}>
							<div>
								{lines.map((line, i) => (
									<React.Fragment key={i}>
										{(line && typeof line === 'object' && 'subtitle' in line) ||
										(line && typeof line === 'object' && 'body' in line) ? (
											<>
												{'subtitle' in line && (
													<Typography className={styles.sectionSubtitle} component="p" variant="subtitle2">
														{line.subtitle}
													</Typography>
												)}
												{'body' in line && (
													<Typography className={styles.sectionBody} component="p">
														{line.body}
													</Typography>
												)}
											</>
										) : (
											{ line }
										)}
									</React.Fragment>
								))}
							</div>
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</Grid>
	);
};
