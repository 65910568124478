import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { LockedModal } from '../../../../../../elements/LockedModal/lockedModal';
import { AvailablePaymentMethod, PaymentType } from '../../../../../../enums/paymentForm';
import { spacingUnits } from '../../../../../../theme/theme';
import useStyles from './acceptTermsModal.styles';
import BPayTerms from './bpayTerms';
import CardTerms from './cardTerms';
import DirectDebt from './directDebit';

const termsContent = [
	{
		method: AvailablePaymentMethod.CARD,
		type: PaymentType.SINGLE,
		body: CardTerms,
		header: <Typography variant="h5">{window.config.REACT_APP_COMPANY} Payment Agreement</Typography>,
	},
	{
		method: AvailablePaymentMethod.CARD,
		type: PaymentType.ARRANGEMENT,
		body: CardTerms,
		header: <Typography variant="h5">{window.config.REACT_APP_COMPANY} Payment Agreement</Typography>,
	},
	{
		method: AvailablePaymentMethod.BPAY,
		type: PaymentType.SINGLE,
		body: BPayTerms,
		header: <Typography variant="h5">{window.config.REACT_APP_COMPANY} Payment Agreement</Typography>,
	},
	{
		method: AvailablePaymentMethod.BPAY,
		type: PaymentType.ARRANGEMENT,
		body: BPayTerms,
		header: <Typography variant="h5">{window.config.REACT_APP_COMPANY} Payment Agreement</Typography>,
	},
	{
		method: AvailablePaymentMethod.DIRECT,
		type: PaymentType.SINGLE,
		body: DirectDebt,
		header: <Typography variant="h5">{window.config.REACT_APP_COMPANY} Payment Agreement</Typography>,
	},
	{
		method: AvailablePaymentMethod.DIRECT,
		type: PaymentType.ARRANGEMENT,
		body: DirectDebt,
		header: <Typography variant="h5">{window.config.REACT_APP_COMPANY} Payment Agreement</Typography>,
	},
];

type AcceptTermsModalProps = {
	onAccept: () => void;
	onClose: () => void;
	paymentMethod: string;
	paymentType?: PaymentType;
};

export const AcceptTermsModal: React.FC<AcceptTermsModalProps> = ({ onAccept, onClose, paymentMethod, paymentType }) => {
	const styles = useStyles();
	const term = termsContent.find((terms) => terms.method === paymentMethod && terms.type === paymentType);
	return (
		<LockedModal className={clsx(styles.root, styles.header && styles.rootWithHeader)} showCloseButton={true} onClose={onClose} open>
			<div>{term?.header}</div>
			<div className={styles.body}>{term?.body()}</div>
			<div className={styles.footer}>
				<Button color="secondary" fullWidth onClick={() => onAccept()} size="large" variant="contained">
					Accept terms
				</Button>
				<Button color="primary" fullWidth onClick={() => onClose()} size="large" variant="outlined" style={{ marginTop: spacingUnits(2) }}>
					Back
				</Button>
			</div>
		</LockedModal>
	);
};
