import { makeStyles } from '@material-ui/core/styles';

import { inputMaxWidth, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $button': {
				marginBottom: spacingUnits(1.5),

				'&$selectedButton': {
					marginBottom: spacingUnits(4),
				},
				'& $iconLeft': {
					width: 70,
					marginRight: spacingUnits(1),
				},

				'@global .MuiPaper-root': {
					paddingLeft: spacingUnits(1.5),
				},
			},
		},
		button: {},
		selectedButton: {},
		iconLeft: {},
		merchantFee: {
			marginBottom: spacingUnits(1.5),
			maxWidth: inputMaxWidth,
		},
	},
	{ name: 'paymentMethods' },
);
