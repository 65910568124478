import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { MinusLarge } from '../../../../../../../../elements/PantheraIcon/MinusLarge';
import { PlusLarge } from '../../../../../../../../elements/PantheraIcon/PlusLarge';
import { faqs } from './data';

import useStyles from './faq.styles';

type FAQProps = BoxProps & {
	className?: string;
};

export const FAQ: React.FC<FAQProps> = ({ ...props }) => {
	const styles = useStyles();

	return (
		<Box {...props} className={clsx(styles.root, props.className)}>
			{faqs.map(({ content, title }, i) => (
				<Accordion key={title}>
					<AccordionSummary
						expandIcon={
							<>
								<PlusLarge className="iconExpand" />
								<MinusLarge className="iconClose" />
							</>
						}
					>
						<Typography variant="h6">{title}</Typography>
					</AccordionSummary>
					<AccordionDetails>{content}</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
};
