import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import { ContactDetails } from './elements/contactDetails';
import { ContactForm } from './elements/contactForm';
import { CustomerHeader } from '../../elements/Header/header';
import { HeroTitle } from '../../elements/HeroTitle/heroTitle';
import useStyles from './contact.styles';
import { CustomerFooter } from '../../elements/Footer/footer';

export const Contact: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<CustomerHeader className={styles.greyHeader} />
			<HeroTitle
				title="Contact customer support"
				subtitle="Need to call us about your account? Have a complaint or feedback? Get in touch and we’ll assist you with your enquiry."
			/>
			<Container className={styles.row} maxWidth="lg">
				<Grid container spacing={10}>
					<ContactForm />
					<Grid item xs={12} md={4}>
						<div>
							<ContactDetails />
						</div>
					</Grid>
				</Grid>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
