import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SupportRoutes, WebRoutes } from '../../../../enums/routerPath';
import { Page } from '../../../../routes/page';
import { SupportMain } from './scenes/SupportMain/supportMain';
import useStyles from './supportCentreLayout.styles';
import { SupportFAQ } from './scenes/SupportFAQ/supportFAQ';
import { OtherAssistance } from './scenes/OtherAssistance/otherAssistance';
import { DebtResources } from './scenes/DebtResources/debtResources';
import { OurCommitment } from './scenes/OurCommitment/ourCommitment';
import { CustomerHeader } from '../../elements/Header/header';
import { CustomerFooter } from '../../elements/Footer/footer';
import debtHelplineBanner from '../../../../static/images/marketing/debt-helpline.png';
import { HeroTitle } from '../../elements/HeroTitle/heroTitle';

export const SupportCentreLayout: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root} width="100%">
			<CustomerHeader className={styles.greyHeader} />
			<HeroTitle title="How can we help?" subtitle="Got questions? We have the answers." />
			<Route
				render={() => (
					<>
						<Switch>
							<Page
								path={WebRoutes.SUPPORT.path}
								title={WebRoutes.SUPPORT.title}
								description={WebRoutes.SUPPORT.description}
								exact
								component={SupportMain}
							/>
							<Page
								path={SupportRoutes.FAQ.path}
								title={SupportRoutes.FAQ.title}
								description={SupportRoutes.FAQ.description}
								exact
								component={SupportFAQ}
							/>
							<Page
								path={SupportRoutes.OTHER_ASSISTANCE.path}
								title={SupportRoutes.OTHER_ASSISTANCE.title}
								description={SupportRoutes.OTHER_ASSISTANCE.description}
								exact
								component={OtherAssistance}
							/>
							<Page
								path={SupportRoutes.DEBT_RESOURCES.path}
								title={SupportRoutes.DEBT_RESOURCES.title}
								description={SupportRoutes.DEBT_RESOURCES.description}
								exact
								component={DebtResources}
							/>
							<Page
								path={SupportRoutes.OUR_COMMITMENT.path}
								title={SupportRoutes.OUR_COMMITMENT.title}
								description={SupportRoutes.OUR_COMMITMENT.description}
								exact
								component={OurCommitment}
							/>
							<Redirect to={WebRoutes.FOUR_OH_FOUR.path} />
						</Switch>
					</>
				)}
			/>
			<Box className={styles.debtHelplineBanner}>
				<div>
					<img alt="Financial Counselling Australia" src={debtHelplineBanner} />
					<Typography variant="body1">
						If you're in crisis and struggling to make ends meet, contact the National Debt Helpline <b>1800 007 007</b> — open from
						9.30am to 4.30pm, Monday to Friday.
					</Typography>
				</div>
			</Box>
			<CustomerFooter />
		</Box>
	);
};
