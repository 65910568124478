import React from 'react';

export const PhoneLogin = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 200 371" fill="none" {...props}>
			<path
				d="M168.929 0H31.071C13.911 0 0 13.16 0 29.392v311.312c0 16.233 13.911 29.392 31.071 29.392H168.93c17.16 0 31.071-13.159 31.071-29.392V29.392C200 13.159 186.089 0 168.929 0z"
				fill="#323232"
			/>
			<path
				d="M171.271 6.046H29.331c-12.592 0-22.8 9.657-22.8 21.569v314.62c0 11.912 10.208 21.569 22.8 21.569h141.94c12.593 0 22.802-9.657 22.802-21.569V27.615c0-11.912-10.209-21.57-22.802-21.57z"
				fill="#FBFBFF"
			/>
			<path
				d="M131.726 125.991H68.221v12.27h63.505v-12.27zM169.959 152.373H29.988v12.271h139.971v-12.271zM169.959 178.755H29.988v12.271h139.971v-12.271z"
				fill="#E5E5E5"
			/>
			<path
				d="M99.592 290.913c18.034 0 32.653-14.62 32.653-32.653 0-18.034-14.619-32.654-32.653-32.654s-32.653 14.62-32.653 32.654c0 18.033 14.619 32.653 32.653 32.653z"
				fill="#C62221"
			/>
			<path d="M92.803 273.628L80.816 259.91l6.971-4.825 5.676 6.495 19.174-18.015 6.412 5.407-26.246 24.656z" fill="#fff" />
			<path
				d="M120.618 99.304a39.533 39.533 0 01-21.102 5.69c-7.41-.133-14.686-2.25-20.97-6.22-7.343-4.697-12.966-11.579-16.008-19.783-3.043-8.138-3.374-17.07-.86-25.407 2.513-8.337 7.607-15.615 14.619-20.775 7.012-5.161 15.48-7.874 24.211-7.808 8.732.133 17.133 3.044 24.012 8.403 6.88 5.36 11.841 12.77 14.09 21.173 2.25 8.402 1.786 17.335-1.521 25.407-3.241 8.072-9.063 14.887-16.471 19.32z"
				fill="#B2B6BB"
			/>
			<path
				d="M112.337 60.673c0 2.316-.806 4.633-2.15 6.564-1.411 1.93-3.36 3.41-5.577 4.31-2.285.902-4.77 1.095-7.123.58-2.418-.515-4.569-1.609-6.316-3.282-1.747-1.673-2.889-3.796-3.292-6.113-.47-2.316-.202-4.697.806-6.82.94-2.124 2.553-3.99 4.636-5.277s4.435-1.93 6.921-1.93c1.613 0 3.226.32 4.704.964 1.478.644 2.822 1.48 3.964 2.639 1.143 1.094 2.016 2.445 2.621 3.86.537 1.352.873 2.896.806 4.505zM120.245 94.418l-.065 4.961a38.84 38.84 0 01-20.772 5.615c-7.293-.065-14.456-2.22-20.643-6.137l.066-4.961c.065-5.484 2.279-10.772 6.25-14.624 3.908-3.852 9.248-5.94 14.718-5.876 5.469.066 10.744 2.285 14.586 6.268 3.842 3.917 5.925 9.205 5.86 14.754z"
				fill="#fff"
			/>
		</svg>
	);
};
