import { makeStyles, Button, Chip, Grid, InputLabel, MenuItem, Snackbar, Typography } from '@material-ui/core';
import { AddBox, AttachFile } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { PantheraInput } from '../../../../../elements/PantheraInput/PantheraInput';
import { PantheraSelect } from '../../../../../elements/PantheraSelect/PantheraSelect';
import { Spacing } from '../../../../../elements/Spacing/spacing';
import { SpinnerButton } from '../../../../../elements/SpinnerButton/spinnerButton';
import arlApi from '../../../../../interceptor/api';
import { ARLResponse } from '../../../../../interceptor/base.api.response';
import { getThemeStyle, spacingUnits } from '../../../../../theme/theme';

interface Files {
	input: string;
}

interface IContactForm {
	c_feedbackType: string;
	c_name: string;
	c_debtID: string;
	c_email: string;
	c_message: string;
	numberOfFiles: number;
	files: Files[];
	spamcheck: string;
}

export type FeedBackType = 'Account enquiry' | 'Hardship' | 'Complaints' | undefined;

type ContactFormProps = {
	feedbackType?: FeedBackType;
};

const useStyles = makeStyles(
	(theme) => ({
		fileUploadContainer: {
			'& > label': {
				color: getThemeStyle('paletteCommonWhite'),
			},

			'& > $fileUploadWell': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				border: `1px dashed ${getThemeStyle('palettePrimary100')}`,
				borderRadius: 8,
				padding: spacingUnits(2),
				marginTop: spacingUnits(1),

				'& > .MuiButton-root': {
					border: 'none',
					marginLeft: spacingUnits(2),
					minWidth: 150,
				},
			},

			'& $uploadedFile': {
				textOverflow: 'ellipsis',

				[theme.breakpoints.down('md')]: {
					maxWidth: 200,
				},
			},
		},
		uploadedFile: {},
		fileUploadWell: {},
	}),
	{ name: 'contactForm' },
);

export const ContactForm: React.FC<ContactFormProps> = ({ feedbackType }) => {
	const styles = useStyles();

	const acceptType =
		'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg';
	const SUPPORTED_FORMATS = [
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'image/png',
		'image/jpeg',
	];
	const FILE_SIZE = 5 * 1024 * 1024; // 5MB
	const contactSchema = Yup.object().shape({
		c_feedbackType: Yup.string().required('Feedback type is required'),
		c_name: Yup.string().required('Name is required'),
		c_debtID: Yup.string().required('Account reference is required').max(20, 'Reference number can only be a maximum of 20 chars'),
		c_email: Yup.string().email('Email must be a valid email').required('Email is required'),
		c_message: Yup.string().required('Message is required'),
		files: Yup.array().of(
			Yup.object().shape({
				input: Yup.mixed()
					.test('fileSize', 'File size is too large', (value) => (value ? value.size <= FILE_SIZE : true))
					.test('fileType', 'Unsupported file type', (value) => (value ? SUPPORTED_FORMATS.includes(value.type) : true)),
			}),
		),
	});

	const [loading, setLoading] = React.useState<boolean>(false);
	const [errorSnackbarOpen, setErrorSnackbar] = React.useState(false);
	const [errorMsg, setErrorMsg] = React.useState('');
	const [successSnackbarOpen, setSuccessSnackbar] = React.useState(false);
	const [successMsg, setSuccessMsg] = React.useState('');

	const postForm = async (values: IContactForm, actions: FormikHelpers<IContactForm>) => {
		setLoading(true);
		let result = null;
		const formData = new FormData();
		formData.append('feedbackType', values.c_feedbackType);
		formData.append('name', values.c_name);
		formData.append('debtID', values.c_debtID);
		formData.append('email', values.c_email);
		formData.append('message', values.c_message);
		formData.append('spamcheck', values.spamcheck);
		for (let i = 0; i < values.files.length; i++) {
			const f = values.files[i];
			formData.append(`file${i}`, f.input);
		}
		try {
			result = await arlApi.post<ARLResponse>('/api/contact', formData, { headers: { 'content-type': 'multipart/form-data' } });
			if (result.data && result.data.success) {
				actions.resetForm();
				setSuccessSnackbar(true);
				setSuccessMsg(result.data.message);
			} else {
				setErrorSnackbar(true);
				setErrorMsg(result.data.message);
			}
		} catch (err) {
			setErrorSnackbar(true);
			setErrorMsg(err.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Grid item md={8}>
				<Spacing units={2}>
					<Formik
						initialValues={{
							c_feedbackType: feedbackType ?? '',
							c_name: '',
							c_debtID: '',
							c_email: '',
							c_message: '',
							numberOfFiles: 1,
							files: [{ input: '' }],
							spamcheck: '',
						}}
						validationSchema={contactSchema}
						enableReinitialize={true}
						validateOnBlur={true}
						onSubmit={postForm}
					>
						{(props: FormikProps<IContactForm>) => {
							const { values, touched, errors, isValid, dirty, setFieldValue, handleChange, setValues, setFieldTouched } = props;
							return (
								<Form>
									<Grid container spacing={3}>
										<Grid item sm={6} xs={12}>
											<PantheraSelect
												fullWidth
												errorMessage={errors.c_feedbackType}
												error={!!errors?.c_feedbackType && !!touched.c_feedbackType}
												label="Feedback type"
												SelectProps={{
													displayEmpty: true,
													id: 'c_feedbackType',
													name: 'c_feedbackType',
													value: values.c_feedbackType,
													onChange: handleChange('c_feedbackType'),
													onBlur: (e) => setFieldTouched('c_feedbackType', true),
												}}
												shouldDisplayIndicator={false}
											>
												<MenuItem disabled style={{ display: 'none' }} value="">
													Please select a feedback type
												</MenuItem>
												<MenuItem value="Account enquiry">Account enquiry</MenuItem>
												<MenuItem value="Hardship">Hardship</MenuItem>
												<MenuItem value="Complaints">Complaints</MenuItem>
											</PantheraSelect>
										</Grid>
										<Grid item sm={6} xs={12}>
											<PantheraInput
												fullWidth
												errorMessage={errors.c_name}
												error={!!errors?.c_name && !!touched.c_name}
												InputProps={{
													placeholder: 'Enter your name',
													maxLength: 128,
													id: 'c_name',
													name: 'c_name',
													value: values.c_name,
													onBlur: (e) => setFieldTouched('c_name', true),
												}}
												label="Name"
												shouldDisplayIndicator={false}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.name, e.target.value)}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<PantheraInput
												fullWidth
												errorMessage={errors.c_debtID}
												error={!!errors?.c_debtID && !!touched.c_debtID}
												InputProps={{
													placeholder: '00000000',
													maxLength: 20,
													id: 'c_debtID',
													name: 'c_debtID',
													value: values.c_debtID,
													onBlur: (e) => setFieldTouched('c_debtID', true),
												}}
												label="Account reference"
												shouldDisplayIndicator={false}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.name, e.target.value)}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<PantheraInput
												fullWidth
												errorMessage={errors.c_email}
												error={!!errors?.c_email && !!touched.c_email}
												InputProps={{
													placeholder: 'Enter your email',
													type: 'email',
													maxLength: 128,
													id: 'c_email',
													name: 'c_email',
													value: values.c_email,
													onBlur: (e) => setFieldTouched('c_email', true),
												}}
												label="Email"
												shouldDisplayIndicator={false}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.name, e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<PantheraInput
												fullWidth
												style={{ maxWidth: '100%' }}
												errorMessage={errors.c_message}
												error={!!errors?.c_message && !!touched.c_message}
												InputProps={{
													placeholder: 'Enter your message...',
													rows: 5,
													maxLength: 2000,
													id: 'c_message',
													name: 'c_message',
													value: values.c_message,
													onBlur: (e) => setFieldTouched('c_message', true),
												}}
												label="Message"
												shouldDisplayIndicator={false}
												multiline={true}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.name, e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.fileUploadContainer}>
												<InputLabel disableAnimation shrink>
													File attachment
												</InputLabel>
												{values.files.map((file, i) => (
													// eslint-disable-next-line react/no-array-index-key
													<React.Fragment key={i}>
														{file.input ? (
															<>
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="center"
																	style={{ marginTop: '0.5rem' }}
																>
																	<Chip
																		label={(values.files[i].input as unknown as File).name}
																		onDelete={() => setFieldValue(`files[${i}].input`, '')}
																		icon={<AttachFile />}
																		className={styles.uploadedFile}
																	/>
																	{values.numberOfFiles === i + 1 && values.numberOfFiles < 3 && (
																		<Button
																			style={{ color: getThemeStyle('palettePrimaryA200') }}
																			onClick={(e) => {
																				const files = [...values.files];
																				const numberOfFiles = values.numberOfFiles + 1;
																				files.push({ input: '' });
																				setValues({ ...values, files, numberOfFiles });
																			}}
																		>
																			<AddBox fontSize="large" />
																		</Button>
																	)}
																</Grid>
															</>
														) : (
															<div className={styles.fileUploadWell}>
																<input
																	hidden
																	type="file"
																	accept={acceptType}
																	name={`file-${i}`}
																	id={`file-${i}`}
																	onChange={(e) => {
																		setFieldValue(
																			`files[${i}].input`,
																			e.currentTarget.files ? e.currentTarget.files[0] : '',
																		);
																	}}
																/>
																<Typography variant="body2">
																	Only .pdf, .doc, .docx, .png and .jpg files. Maximum upload of 5MB.
																</Typography>
																<Button
																	onClick={() => {
																		const el = document.getElementById(`file-${i}`);
																		if (el) {
																			el.click();
																		}
																	}}
																	variant="contained"
																>
																	Select file
																</Button>
															</div>
														)}
														{errors.files && errors.files[i] && (
															<span style={{ color: '#d91236' }}>{(errors.files[i] as Files).input}</span>
														)}
													</React.Fragment>
												))}
											</div>
										</Grid>
										<input
											style={{ display: 'none' }}
											id="spamcheck"
											name="spamcheck"
											type="text"
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.name, e.target.value)}
										/>
										<Grid item xs={12}>
											<SpinnerButton
												color="secondary"
												type="submit"
												className="MuiButton-setWidth"
												size="large"
												variant="contained"
												loading={loading}
												disabled={!(isValid && dirty)}
											>
												Submit
											</SpinnerButton>
										</Grid>
									</Grid>
								</Form>
							);
						}}
					</Formik>
				</Spacing>
			</Grid>
			<Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbar(false)}>
				<Alert variant="filled" severity="error">
					{errorMsg}
				</Alert>
			</Snackbar>

			<Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={() => setSuccessSnackbar(false)}>
				<Alert variant="filled" severity="success">
					{successMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
