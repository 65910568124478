import clsx from 'clsx';
import React, { useEffect } from 'react';
import useStyles from './bodyContent.styles';
import { LogoBrandmark } from '../Branding/LogoBrandmark';
import { Footer } from './elements/Footer/footer';

type BodyContentProps = {
	brandmarkClassName?: string;
	className?: string;
	showBrandmark?: boolean;
	footerAdornment?: React.ReactNode;
};

export const BodyContent: React.FC<BodyContentProps> = ({
	brandmarkClassName,
	children,
	className,
	showBrandmark = true,
	footerAdornment = null,
	...props
}) => {
	const styles = useStyles();
	useEffect(() => {
		document.getElementsByClassName('yabbr-frame')[0]?.classList.add('yabbr-frame-account');
		return () => document.getElementsByClassName('yabbr-frame')[0]?.classList.remove('yabbr-frame-account');
	}, []);
	return (
		<main id="bodyContent" className={clsx(styles.root, className)} {...props}>
			{showBrandmark && <LogoBrandmark className={clsx(styles.brandmark, brandmarkClassName)} />}
			<div className={styles.container}>{children}</div>
			<Footer />
		</main>
	);
};
