import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import chrome from '../../static/images/browser-logos/chrome_64x64.png';
import safari from '../../static/images/browser-logos/safari_64x64.png';
import edge from '../../static/images/browser-logos/edge_64x64.png';
import firefox from '../../static/images/browser-logos/firefox_64x64.png';
import { LockedModal } from '../LockedModal/lockedModal';
import { Spacing } from '../Spacing/spacing';

export const UnsupportedBrowser: React.FC = () => {
	const isIE = window.navigator.userAgent.match(/(MSIE|Trident)/);
	return (
		<LockedModal open={!!isIE}>
			<Spacing units={4}>
				<Typography variant="h4" align="center">
					Unsupported browser detected
				</Typography>
				<Typography variant="subtitle1" align="center">
					{window.config.REACT_APP_COMPANY} requires a modern browser for security, reliability and performance.
				</Typography>
				<Typography variant="body1" align="center">
					Please update to Chrome, Safari, Firefox or Microsoft Edge.
				</Typography>
			</Spacing>
			<Spacing units={1} mt={4}>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
					<Grid item xs={3}>
						<a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
							<img src={chrome} title="chrome-logo" alt="chrome-logo" />
						</a>
					</Grid>
					<Grid item xs={3}>
						<a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
							<Typography variant="subtitle2">Chrome</Typography>
						</a>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
					<Grid item xs={3}>
						<a href="https://www.apple.com/au/safari/" target="_blank" rel="noreferrer">
							<img src={safari} title="safari-logo" alt="safari-logo" />
						</a>
					</Grid>
					<Grid item xs={3}>
						<a href="https://www.apple.com/au/safari/" target="_blank" rel="noreferrer">
							<Typography variant="subtitle2">Safari</Typography>
						</a>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
					<Grid item xs={3}>
						<a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">
							<img src={firefox} title="firefox-logo" alt="firefox-logo" />
						</a>
					</Grid>
					<Grid item xs={3}>
						<a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">
							<Typography variant="subtitle2">Firefox</Typography>
						</a>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
					<Grid item xs={3}>
						<a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
							<img src={edge} title="edge-logo" alt="edge-logo" />
						</a>
					</Grid>
					<Grid item xs={3}>
						<a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
							<Typography variant="subtitle2">Edge</Typography>
						</a>
					</Grid>
				</Grid>
			</Spacing>
		</LockedModal>
	);
};
