import React from 'react';

export const BpayVertical = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg id="prefix__Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 98.84 144.2" xmlSpace="preserve" {...props}>
		<style />
		<linearGradient id="prefix__SVGID_1_" gradientUnits="userSpaceOnUse" x1={-166.608} y1={254.349} x2={-165.901} y2={253.642}>
			<stop offset={0} stopColor="#20ac4b" />
			<stop offset={0.983} stopColor="#19361a" />
		</linearGradient>
		<path
			d="M97.7 138.38c0 2.59-2.1 4.69-4.69 4.69H5.87c-2.58 0-4.68-2.1-4.68-4.69V5.81c0-2.58 2.1-4.68 4.68-4.68h87.15c2.59 0 4.69 2.1 4.69 4.68v132.57z"
			fill="#002d6a"
		/>
		<path
			d="M75.98 103.97c-1.22 0-1.77-.02-2.29-.02-.55 0-1.18.1-1.48.96l-1.09 1.96-4.38 7.74-4.38-7.74-1.09-1.96c-.3-.86-.93-.96-1.48-.96-.52 0-1.07.02-2.29.02-1.2 0-.73.93-.73.93s4.44 7.88 8.02 14.24c0 0-.06 7.56-.06 8.09 0 .53.28.86.85.86h2.09c.38 0 1.04-.03 1.04-.84l-.02-8.12c3.57-6.36 8.02-14.24 8.02-14.24s.47-.92-.73-.92zm-44.87.02h-8.77c-.76 0-.87.63-.87 1.02v22.2c0 .91.84.88.93.88h2.24c.37 0 .77-.3.77-.72v-7.96h5.69c4.26 0 7.71-3.45 7.71-7.71.01-4.26-3.45-7.71-7.7-7.71zm-.07 11.58h-5.63v-7.62h5.63c2.11 0 3.81 1.71 3.81 3.81s-1.7 3.81-3.81 3.81zM51.73 105c-.3-.61-.52-1.01-1.6-1.01h-2.84c-1.08 0-1.3.4-1.6 1.01-.27.6-8.23 22.16-8.23 22.16s-.47.93.73.93c1.22 0 1.49.02 2.01.02.55 0 1.18-.1 1.48-.96.26-.75 1.45-4.05 1.45-4.05H54.3s1.19 3.3 1.45 4.05c.3.86.93.96 1.48.96.52 0 .79-.02 2.01-.02 1.2 0 .73-.93.73-.93S52 105.6 51.73 105zm-6.98 14.14l3.96-11.21 4.2 11.21h-8.16zM94.15 0H4.74C2.16 0 .06 2.1.06 4.68v134.83c0 2.59 2.1 4.69 4.68 4.69h89.42c2.59 0 4.68-2.1 4.68-4.69V4.68c0-2.58-2.1-4.68-4.69-4.68zM35.92 35.67h15.95c4.2 0 7.58 3.59 7.58 7.75 0 4.18-3.38 7.57-7.58 7.57l-2.76.14c-1.48.06-2.25.93-2.3 2.29v9.31c.11 1.48 1.01 2.25 2.43 2.25h6.85c4.55 0 8.23 3.69 8.23 8.22 0 4.3-3.32 7.8-7.51 8.16l-18.56.02c-1.38-.04-2.28-.83-2.34-2.34V35.67zM6.76 8.9c0-1.18.91-2.12 2.04-2.21h12.66v14.68H6.76V8.9zm85.39 126.22c0 1.2-.61 2.34-2.37 2.38H8.99c-.05 0-.1 0-.16-.01a2.2 2.2 0 01-2.07-2.19s.01-62.09.01-99.24h24.62l-9.91 3.62v54.36c0 1.52.84 2.32 2.13 2.44h33.43c11.43-.37 20.57-9.72 20.57-21.23 0-7.55-3.93-14.16-9.85-17.93 3.7-3.28 6.49-8.64 6.49-13.92 0-14.06-9.98-22.04-24.24-22.04H36.14V6.69c23.51 0 53.64.01 53.64.01 1.83 0 2.37 1.2 2.37 2.38v126.04z"
			fill="#fff"
		/>
	</svg>
);
