import { makeStyles, Theme } from '@material-ui/core/styles';
import { spacingUnits } from '../../theme/theme';

export interface StyleProps {
	orientation?: 'horizontal' | 'vertical';
	units: number;
}

export default makeStyles<Theme, StyleProps>(
	{
		root: ({ orientation, units }) => ({
			...(orientation === 'horizontal'
				? {
						display: 'flex',

						'& > *:not(:first-child)': {
							marginLeft: spacingUnits(units),
						},
				  }
				: {
						'& > *:not(:first-child)': {
							marginTop: spacingUnits(units),
						},
				  }),
		}),
	},
	{ name: 'spacing' },
);
