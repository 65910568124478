import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModalDetails = {
	title: string;
	subtitle?: string;
	alert?: string;
};

export interface ErrorModalState {
	errorModalInfo: ModalDetails | undefined;
}

const initialState: ErrorModalState = {
	errorModalInfo: undefined,
};

export const errorModalSlice = createSlice({
	name: 'errorModal',
	initialState,
	reducers: {
		setErrorModalInfo(state, action: PayloadAction<ModalDetails | undefined>) {
			state.errorModalInfo = action.payload;
		},
	},
});

export const { setErrorModalInfo } = errorModalSlice.actions;

export default errorModalSlice.reducer;
