import { Container, Divider, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LogoStackedNegative } from '../../../../elements/Branding/LogoStackedNegative';
import { BusinessRoutes, WebRoutes } from '../../../../enums/routerPath';
import useStyles from './footer.styles';

export const BusinessFooter: React.FC = () => {
	const styles = useStyles();
	return (
		<Container className={styles.root} maxWidth={false} disableGutters>
			<Container maxWidth="lg">
				<Grid container alignItems="center" justifyContent="space-evenly" spacing={2}>
					<Grid item className={styles.logo}>
						<LogoStackedNegative />
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">SMS us:</Typography>
						<Link color="inherit" underline="none" href={window.config.REACT_APP_CONTACT_MOBILE_HREF}>
							{window.config.REACT_APP_CONTACT_MOBILE}
						</Link>
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">Call us:</Typography>
						<Link color="inherit" underline="none" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
							{window.config.REACT_APP_CONTACT_NUMBER}
						</Link>
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">Email us:</Typography>
						<Link color="inherit" underline="none" href={`mailto:${window.config.REACT_APP_CONTACT_EMAIL}`}>
							{window.config.REACT_APP_CONTACT_EMAIL}
						</Link>
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">Address:</Typography>
						<Typography component="span">Level 3/380 Docklands Dr, Docklands VIC 3008</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid className={styles.footerLinks} container alignItems="center">
					<Grid item>
						<Link className={styles.navLink} color="inherit" component={RouterLink} to={BusinessRoutes.INDUSTRIES.path} underline="none">
							Industries
						</Link>
					</Grid>
					<Grid item>
						<Link
							className={styles.navLink}
							color="inherit"
							component={RouterLink}
							to={BusinessRoutes.OUR_SERVICES.path}
							underline="none"
						>
							Our Services
						</Link>
					</Grid>
					<Grid item>
						<Link className={styles.navLink} color="inherit" component={RouterLink} to={BusinessRoutes.COMPLIANCE.path} underline="none">
							Compliance
						</Link>
					</Grid>
					<Grid item>
						<Link className={styles.navLink} color="inherit" component={RouterLink} to={BusinessRoutes.CONTACT.path} underline="none">
							Contact
						</Link>
					</Grid>
					<Grid item>
						<Link className={styles.navLink} color="inherit" href={window.config.REACT_APP_CLIENT_URL} underline="none">
							Client Login
						</Link>
					</Grid>
					<Grid className={styles.grow} />
					<Grid item>
						<Link className={styles.navLink} color="inherit" component={RouterLink} to={WebRoutes.LOGIN.path} underline="none">
							Resolve overdue account
						</Link>
					</Grid>
				</Grid>
				<Divider />
				<Grid container alignItems="center" spacing={3}>
					<Grid item xs={12}>
						<Typography className={styles.copyright} component="p" variant="caption">
							{`© ${new Date().getFullYear()} ${window.config.REACT_APP_COMPANY_FULL}`}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
};
