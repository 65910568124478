import React from 'react';

export const CalendarFeather = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" {...props}>
		<rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
		<path d="M16 2L16 6" />
		<path d="M8 2L8 6" />
		<path d="M3 10L21 10" />
	</svg>
);
