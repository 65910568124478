import { ButtonProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getThemeStyle, spacingUnits } from '../../theme/theme';

export interface StyleProps {
	variant: ButtonProps['variant'];
	color: ButtonProps['color'];
}

export default makeStyles<Theme, StyleProps>(
	{
		buttonProgress: ({ variant, color }) => ({
			marginLeft: spacingUnits(2),

			...(variant === 'contained' && {
				color: getThemeStyle('paletteCommonWhite'),
			}),

			...(variant === 'outlined' &&
				color === 'primary' && {
					color: getThemeStyle('palettePrimary500'),
				}),

			...(variant === 'outlined' &&
				color === 'secondary' && {
					color: getThemeStyle('paletteSecondary500'),
				}),
		}),
	},
	{ name: 'spinnerButton' },
);
