import React from 'react';

export const TakeASurvey = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 1250 955" fill="none" {...props}>
			<path
				d="M136.851 951.11L.691 385.16a60.681 60.681 0 0111.31-40.49l230.36-318.83A60.682 60.682 0 01296.901.92l788.649 69.53a60.693 60.693 0 0153.45 45.21l108.36 416.999a60.717 60.717 0 01-.2 31.241l-106 388.58-1004.309-1.37z"
				fill="#F4F4F4"
			/>
			<path
				d="M305.62 577H96.38a9.38 9.38 0 00-9.38 9.38v38.24a9.38 9.38 0 009.38 9.38h6.31l.31 21 14.82-21h187.8a9.38 9.38 0 009.38-9.38v-38.29a9.385 9.385 0 00-9.38-9.33z"
				fill="#DADDDF"
			/>
			<path d="M294.291 596.352h-186.58v4.55h186.58v-4.55zM140.851 610h-33.14v4.55h33.14V610zM229.241 610h-81.12v4.55h81.12V610z" fill="#fff" />
			<path
				d="M19.38 481h209.24a9.38 9.38 0 019.38 9.38v38.24a9.381 9.381 0 01-9.38 9.38h-6.32l-.3 21-14.85-21H19.38a9.38 9.38 0 01-9.38-9.38v-38.29a9.376 9.376 0 019.38-9.33z"
				fill="#DADDDF"
			/>
			<path
				d="M30.71 504.898h186.581v-4.55H30.711v4.55zM184.15 518.551h33.14v-4.55h-33.14v4.55zM95.762 518.551h81.12v-4.55h-81.12v4.55z"
				fill="#fff"
			/>
			<path
				d="M19.38 673h209.24a9.38 9.38 0 019.38 9.38v38.24a9.381 9.381 0 01-9.38 9.38h-6.32l-.3 21-14.85-21H19.38a9.38 9.38 0 01-9.38-9.38v-38.29a9.376 9.376 0 019.38-9.33z"
				fill="#DADDDF"
			/>
			<path
				d="M30.71 696.898h186.581v-4.55H30.711v4.55zM184.15 710.551h33.14v-4.55h-33.14v4.55zM95.762 710.551h81.12v-4.55h-81.12v4.55z"
				fill="#fff"
			/>
			<path
				d="M1169.9 954.38H116.27c-.218.04-.44.02-.653-.04a1.501 1.501 0 01-.973-.82 1.537 1.537 0 010-1.28c.093-.2.228-.38.396-.52s.365-.25.577-.31c.213-.05.435-.07.653-.03H1169.9c.36.06.68.24.91.52.24.27.36.62.36.98s-.12.71-.36.98c-.23.28-.55.46-.91.52zM988.43 171.102H461.111c-5.744 0-10.4 4.656-10.4 10.4V939.14c0 5.75 4.656 10.4 10.4 10.4H988.43c5.74 0 10.4-4.65 10.4-10.4V181.502c0-5.744-4.66-10.4-10.4-10.4z"
				fill="#B2B6BB"
			/>
			<path
				d="M975.78 170.512H448.461c-5.744 0-10.4 4.656-10.4 10.4V938.55c0 5.75 4.656 10.4 10.4 10.4H975.78c5.74 0 10.4-4.65 10.4-10.4V180.912c0-5.744-4.66-10.4-10.4-10.4z"
				fill="#DADDDF"
			/>
			<path
				d="M460.08 170.121h527.71c1.37 0 2.72.269 3.98.792 1.26.522 2.41 1.288 3.37 2.254.97.966 1.74 2.112 2.26 3.374.52 1.262.79 2.614.79 3.98v52.43H449.68v-52.43a10.399 10.399 0 0110.4-10.4z"
				fill="#C62221"
			/>
			<path
				opacity={0.2}
				d="M460.08 170.121h527.71c1.37 0 2.72.269 3.98.792 1.26.522 2.41 1.288 3.37 2.254.97.966 1.74 2.112 2.26 3.374.52 1.262.79 2.614.79 3.98v52.43H449.68v-52.43a10.399 10.399 0 0110.4-10.4z"
				fill="#000"
			/>
			<path d="M448.08 170.121h527.71a10.407 10.407 0 0110.4 10.4v52.43H437.68v-52.43a10.403 10.403 0 0110.4-10.4z" fill="#C62221" />
			<path
				opacity={0.05}
				d="M476.529 272.488H949.53c1.59 0 3.12.632 4.24 1.758a5.981 5.981 0 011.76 4.242v290.51c0 1.592-.63 3.118-1.76 4.243a5.988 5.988 0 01-4.24 1.757H476.529v-302.51z"
				fill="#000"
			/>
			<path
				d="M947.23 275H479.77c-3.186 0-5.77 4.587-5.77 10.242v516.516c0 5.657 2.584 10.242 5.77 10.242h467.46c3.19 0 5.77-4.585 5.77-10.242V285.242c0-5.655-2.58-10.242-5.77-10.242z"
				fill="#fff"
			/>
			<path
				d="M564 640h-58v7h58v-7zM703 590H506v7h197v-7zM917 548H506v7h411v-7zM917 576H506v7h411v-7zM917 562H506v7h411v-7zM917 515H506v7h411v-7zM917 501H506v7h411v-7zM655 445H506v7h149v-7zM628 354H506v7h122v-7z"
				fill="#B2B6BB"
			/>
			<g opacity={0.3} fill="#000">
				<path
					opacity={0.3}
					d="M463.65 208.42a6.88 6.88 0 100-13.76 6.88 6.88 0 000 13.76zM488.099 208.42a6.88 6.88 0 100-13.76 6.88 6.88 0 000 13.76zM512.54 208.42a6.88 6.88 0 100-13.76 6.88 6.88 0 100 13.76z"
				/>
			</g>
			<path d="M899.09 198.102H553.029v6.88H899.09v-6.88z" fill="#fff" />
			<g opacity={0.3} stroke="#000" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round">
				<path opacity={0.3} d="M930.789 194.66h25.98M930.789 201.539h25.98M930.789 208.41h25.98" />
			</g>
			<path opacity={0.3} d="M360.501 237.398h-88.8v88.8h88.8v-88.8z" fill="#000" />
			<path d="M350.8 229.2H262V318h88.8v-88.8z" fill="#fff" />
			<path
				opacity={0.15}
				d="M310.3 241.198c0 .8-.2 1.6-.6 2.2-.4.7-1 1.2-1.8 1.5-.7.3-1.5.4-2.3.2-.8-.2-1.5-.6-2-1.1-.7-.7-1.1-1.7-1.1-2.8 0-1 .4-2 1.1-2.7.7-.7 1.7-1.1 2.7-1.1 1 0 2 .4 2.8 1.1.7.7 1.2 1.7 1.2 2.7z"
				fill="#000"
			/>
			<path opacity={0.1} d="M66.9 261.496h85.9v101.2l-83.8-1.8-2.1-99.4z" fill="#000" />
			<path d="M62.9 257.496h85.9v101.2l-83.8-1.8-2.1-99.4z" fill="#fff" />
			<path
				d="M136.201 283.297h-62.6c-.3 0-.6.1-.9.4-.2.2-.4.5-.4.9 0 .2 0 .3.1.5.1.1.1.3.3.4.1.1.2.2.4.3.1.1.3.1.5.1h62.6c.3 0 .6-.1.9-.4.2-.2.4-.5.4-.9 0-.2 0-.3-.1-.5-.1-.1-.2-.3-.3-.4-.1-.1-.2-.2-.4-.3-.2-.1-.4-.1-.5-.1zM136.201 287.098h-62.6c-.3 0-.6.1-.9.4-.2.2-.4.5-.4.9 0 .3.1.6.4.9.2.2.5.4.9.4h62.6c.2 0 .3 0 .5-.1.1-.1.3-.2.4-.3.1-.1.2-.2.3-.4.1-.1.1-.3.1-.5 0-.3-.1-.6-.4-.9-.3-.3-.6-.4-.9-.4zM136.201 308.598h-62.6c-.2 0-.3 0-.5.1-.1.1-.3.2-.4.3-.1.1-.2.2-.3.4-.1.1-.1.3-.1.5 0 .3.1.6.4.9.2.2.5.4.9.4h62.6c.3 0 .6-.1.9-.4.2-.2.4-.5.4-.9 0-.2 0-.3-.1-.5-.1-.1-.2-.3-.3-.4-.1-.1-.2-.2-.4-.3-.2-.1-.4-.1-.5-.1zM128 321.2H80.2c-.2 0-.3 0-.5.1-.1.1-.3.2-.4.3-.1.1-.2.2-.3.4-.1.1-.1.3-.1.5 0 .3.1.6.4.9.2.2.5.4.9.4H128c.3 0 .6-.1.9-.4.2-.2.4-.5.4-.9 0-.3-.1-.6-.4-.9-.3-.2-.6-.4-.9-.4zM129 338.898H81.2c-.2 0-.3 0-.5.1-.1.1-.3.2-.4.3-.1.1-.2.2-.3.4-.1.1-.1.3-.1.5s0 .3.1.5c.1.1.2.3.3.4.1.1.2.2.4.3.1.1.3.1.5.1H129c.2 0 .3 0 .5-.1.1-.1.3-.2.4-.3.1-.1.2-.2.3-.4.1-.1.1-.3.1-.5s0-.3-.1-.5c-.1-.1-.1-.3-.3-.4-.1-.1-.2-.2-.4-.3-.2-.1-.4-.1-.5-.1z"
				fill="#ECECEC"
			/>
			<path d="M112.2 150.598H36v71.3h76.2v-71.3z" fill="#DADDDF" />
			<path
				d="M101.1 173.297H45.5c-.1 0-.3 0-.4.1-.1.1-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4v.1c0 .3.1.6.3.8.2.2.5.3.7.3h55.6c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8v-.1c0-.3-.1-.6-.3-.8-.3-.1-.5-.2-.8-.2zM101.1 184.598H45.5c-.1 0-.3 0-.4.1-.1.1-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4v.1c0 .1 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.3.2.1 0 .3.1.4.1h55.6c.1 0 .3 0 .4-.1.1-.1.2-.1.3-.2.1-.1.2-.2.2-.3.1-.1.1-.3.1-.4v-.1c0-.3-.1-.6-.3-.8-.2-.2-.4-.3-.7-.2zM101.1 195.7H45.5c-.1 0-.3 0-.4.1-.1.1-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4v.1c0 .1 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.3.2.1.1.3.1.4.1h55.6c.1 0 .3 0 .4-.1.1-.1.2-.1.3-.2.1-.1.2-.2.2-.3.1-.1.1-.3.1-.4v-.1c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.7-.2zM93.601 206.996h-42.4c-.1 0-.3 0-.4.1-.1.1-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4v.1c0 .1 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.3.2.1.1.3.1.4.1h42.4c.1 0 .3 0 .4-.1.1-.1.2-.1.3-.2.1-.1.2-.2.2-.3 0-.1.1-.3.1-.4v-.1c0-.1 0-.3-.1-.4-.1-.1-.1-.2-.2-.3-.1-.1-.2-.2-.3-.2-.1 0-.2-.1-.4-.1z"
				fill="#B1B5BA"
			/>
			<path opacity={0.7} d="M213.701 103.998s61.1-11 76.5 4.4c15.4 15.4 9.5 82.4 9.5 82.4l-86.4-.4.4-86.4z" fill="#B2B6BB" />
			<path d="M203.9 91.599s51.5-7.001 66.7 8.299c15.1 15.3 19.1 78.5 19.1 78.5l-86.2-.5.4-86.3z" fill="#DADDDF" />
			<path
				opacity={0.15}
				d="M250 99.699c-.1.8-.4 1.5-1 2s-1.3.8-2.1.8-1.5-.3-2.1-.9c-.5-.6-.8-1.3-.8-2 0-.4 0-.8.2-1.2.1-.4.4-.7.6-1 .4-.4 1-.7 1.6-.8.6-.1 1.2-.1 1.8.2.6.2 1 .6 1.4 1.1.2.6.4 1.2.4 1.8z"
				fill="#000"
			/>
			<path
				d="M272.5 114.5l-50.1-.3c-.9 0-1.8.4-2.5 1-.7.7-1 1.5-1 2.5 0 .9.4 1.8 1 2.5.7.7 1.5 1 2.5 1l50.1.3c.9 0 1.8-.4 2.5-1 .7-.7 1-1.5 1-2.5 0-.9-.4-1.8-1-2.5-.7-.6-1.6-1-2.5-1zM274.801 131.898l-55-.3c-.1 0-.3 0-.4.1-.1 0-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4 0 .1 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.3.2.1 0 .3.1.4.1l55 .3c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3zM274.701 144.296l-55-.3c-.1 0-.3 0-.4.1-.1 0-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4 0 .1 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.3.2.1 0 .3.1.4.1l55 .3c.1 0 .3 0 .4-.1.1 0 .3-.1.4-.2.1-.1.2-.2.2-.3.1-.1.1-.3.1-.4 0-.1 0-.3-.1-.4-.1-.1-.1-.2-.2-.3-.1-.1-.2-.2-.3-.2-.2 0-.3-.1-.5-.1zM274.701 156.796l-55-.3c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3l55 .3c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.5-.3-.7-.3zM329.9 260.098h-55c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3h55c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3zM329.9 272.598h-55c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3h55c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3zM322.3 285.098h-47.4c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3h47.4c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3z"
				fill="#B1B5BA"
			/>
			<path
				d="M628 326H506v7h122v-7zM628 340H506v7h122v-7zM917 473H506v7h411v-7zM917 487H506v7h411v-7zM430 758.52a4.676 4.676 0 004.59-5.593 4.675 4.675 0 00-3.677-3.677 4.682 4.682 0 00-4.222 7.899A4.68 4.68 0 00430 758.52z"
				fill="#B2B6BB"
			/>
			<path
				opacity={0.15}
				d="M428.249 753.84a4.684 4.684 0 013.15-4.41 4.201 4.201 0 00-1.53-.27 4.683 4.683 0 00-4.68 4.68 4.682 4.682 0 004.68 4.68 4.556 4.556 0 001.53-.27 4.768 4.768 0 01-3.15-4.41z"
				fill="#000"
			/>
			<path d="M430 830.911a4.68 4.68 0 10-4.68-4.68 4.692 4.692 0 004.68 4.68z" fill="#B2B6BB" />
			<path
				opacity={0.15}
				d="M428.249 826.231a4.684 4.684 0 013.15-4.41 4.201 4.201 0 00-1.53-.27 4.68 4.68 0 000 9.36 4.556 4.556 0 001.53-.27 4.598 4.598 0 01-3.15-4.41z"
				fill="#000"
			/>
			<path
				d="M409.409 932.47l58.43 1.44-23.32-16.66a15.57 15.57 0 01-4.628-5.25 15.555 15.555 0 01-1.852-6.75l-.81-18.28-31.78.09 3.96 45.41z"
				fill="#FC9D9D"
			/>
			<path
				opacity={0.15}
				d="M415.889 911.13v-.36a3.79 3.79 0 01.789-2.72 3.81 3.81 0 012.451-1.42l18.64-2.7-.18-3.69h-31.15l2.79 31 25.48-2.79-8.38-3.34a16.656 16.656 0 01-7.2-5.51 16.634 16.634 0 01-3.24-8.47z"
				fill="#000"
			/>
			<path d="M304.16 931.39h26.29l4.86-44.48-31.06.09-.09 44.39z" fill="#FC9D9D" />
			<path
				opacity={0.15}
				d="M310.73 923.91l.63-13.68c.048-.87.398-1.7.989-2.34a3.763 3.763 0 012.251-1.17l18.91-2.88.45-3.78-29.62.09-.09 31.51 6.48-7.75z"
				fill="#000"
			/>
			<path
				d="M302.449 536.59v363.65h34.12l29.71-296.48a3.754 3.754 0 013.735-3.415 3.752 3.752 0 013.735 3.415l31.61 296.48h34.12l-12.34-363.65h-124.69z"
				fill="#C62221"
			/>
			<path
				d="M306.14 915.72l.81-.81a15.995 15.995 0 0122.6.27l.45.54a8.408 8.408 0 012.34 5.76v3.42c-.018 1.99.286 3.97.9 5.86L336 940c.716 2.24 1.08 4.58 1.08 6.93v1.17a3.434 3.434 0 01-1.093 2.34c-.657.6-1.525.93-2.417.9h-32.29a3.413 3.413 0 01-2.434-.88c-.327-.31-.59-.67-.775-1.07a3.407 3.407 0 01-.301-1.29v-.45c.003-2.72.459-5.43 1.35-8l3.24-9.09a19.741 19.741 0 001.17-6.49l.09-2.61a9.054 9.054 0 012.52-5.74z"
				fill="#3F4249"
			/>
			<path d="M301.551 900.24h36.37l.72-7.48h-37l-.09 7.48z" fill="#C62221" />
			<path opacity={0.15} d="M301.551 900.24h15.21l.36-7.48h-15.48l-.09 7.48z" fill="#000" />
			<path
				opacity={0.5}
				d="M297.77 947.68v.45c.044.89.436 1.73 1.092 2.34.657.6 1.525.93 2.418.9h32.32c.444.02.888-.05 1.305-.2.418-.15.802-.38 1.128-.69.327-.3.591-.66.775-1.07.185-.4.287-.84.302-1.28V947c.014-.45-.016-.91-.09-1.35h-39.25v2.03z"
				fill="#000"
			/>
			<path
				d="M405.359 922.11v22.78c-.002.87.168 1.74.502 2.55.334.81.824 1.55 1.443 2.16a6.737 6.737 0 002.164 1.45c.809.33 1.676.5 2.551.5h77.43a2.798 2.798 0 002.79-2.79 12.676 12.676 0 00-2.393-7.39 12.62 12.62 0 00-6.257-4.61l-17.46-5.76-22.13-17.17a6.057 6.057 0 00-3.902-1.29 6.088 6.088 0 00-3.838 1.47l-9.82 8.37a5.478 5.478 0 01-2.47 1.21c-.913.19-1.859.15-2.75-.13l-12.42-3.87a2.657 2.657 0 00-1.221-.08c-.408.07-.793.24-1.127.48-.333.25-.605.56-.795.93-.189.37-.291.78-.297 1.19z"
				fill="#3F4249"
			/>
			<path d="M411.999 951.55h77.43a2.801 2.801 0 002.79-2.79 9.522 9.522 0 00-.36-2.79h-86.41a6.694 6.694 0 006.55 5.58z" fill="#000" />
			<path
				d="M443.531 922.92a.738.738 0 01-.45-.18.7.7 0 01-.206-.5.7.7 0 01.206-.5l4.68-5a.721.721 0 01.5-.21c.187 0 .367.08.5.21s.207.31.207.5-.074.37-.207.5l-4.68 5c-.167.1-.355.16-.55.18zM447.22 925.8a.687.687 0 01-.45-.18.7.7 0 01-.206-.5.7.7 0 01.206-.5l4.68-5a.723.723 0 01.5-.21.72.72 0 01.5.21c.133.13.207.31.207.5s-.074.37-.207.5l-4.68 5c-.167.1-.355.16-.55.18zM450.91 928.69a.683.683 0 01-.45-.18.7.7 0 01-.206-.5.7.7 0 01.206-.5l4.68-5a.69.69 0 01.229-.15.64.64 0 01.541 0c.086.03.164.09.23.15.065.07.118.15.153.23.036.09.054.18.054.27 0 .09-.018.19-.054.27a.619.619 0 01-.153.23l-4.68 5a.74.74 0 01-.55.18zM324.149 923.37a.514.514 0 01-.36-.09 13.887 13.887 0 00-5.945-1.34c-2.056 0-4.087.46-5.945 1.34a.819.819 0 01-.614.05.829.829 0 01-.466-.41.794.794 0 01-.043-.61.802.802 0 01.403-.47 15.293 15.293 0 0113.42 0c.18.11.315.28.382.47.066.2.058.42-.022.61a.976.976 0 01-.349.33 1.05 1.05 0 01-.461.12zM324.149 927.52a.57.57 0 01-.36-.09 13.746 13.746 0 00-5.945-1.35c-2.058 0-4.089.46-5.945 1.35a.805.805 0 01-.303.09.677.677 0 01-.315-.04.752.752 0 01-.274-.16.73.73 0 01-.188-.25.815.815 0 01-.091-.31c-.01-.1.003-.21.036-.31.034-.11.088-.2.16-.28a.717.717 0 01.255-.19 15.166 15.166 0 016.71-1.56c2.326 0 4.622.53 6.71 1.56.182.11.319.28.385.48.067.2.058.41-.025.61-.089.13-.209.24-.35.32a1.06 1.06 0 01-.46.13zM324.149 931.57a.514.514 0 01-.36-.09 13.887 13.887 0 00-5.945-1.34c-2.056 0-4.087.46-5.945 1.34a.646.646 0 01-.303.08.815.815 0 01-.311-.03.934.934 0 01-.273-.16.81.81 0 01-.276-.55.837.837 0 01.195-.59c.07-.08.154-.14.248-.19a15.162 15.162 0 016.71-1.56c2.327 0 4.623.53 6.71 1.56.18.11.315.28.382.47.066.2.058.42-.022.61a.976.976 0 01-.349.33 1.05 1.05 0 01-.461.12z"
				fill="#fff"
			/>
			<path
				opacity={0.15}
				d="M425.699 896.09l-33.7-279.91a17.885 17.885 0 00-17.73-15.75h-4.24a3.718 3.718 0 013.69 3.42l31.43 294.85 20.55-2.61z"
				fill="#000"
			/>
			<path d="M404.46 900.24h36.28l-.54-7.48h-36.46l.72 7.48z" fill="#C62221" />
			<path d="M336.301 368.141h51.7v190.14h-49.2l-2.5-190.14z" fill="#fff" />
			<path opacity={0.15} d="M404.46 900.24h21.6l-.81-7.48h-21.51l.72 7.48z" fill="#000" />
			<path
				d="M346.309 373.15a28.582 28.582 0 0033.26-1 3.853 3.853 0 001.34-3.42l-2.17-20.27a25.61 25.61 0 00.67-6c.33-10.26 1.25-19.51 1.25-19.51s-34-21.35-35.36 4.75l-.5 6.75-2.33 34.19a3.937 3.937 0 00.91 2.59l2.93 1.92z"
				fill="#FC9D9D"
			/>
			<path
				d="M383.66 324.489a11.514 11.514 0 0010.636-7.11 11.514 11.514 0 00-2.504-12.546 11.511 11.511 0 00-19.642 8.166 11.51 11.51 0 0011.51 11.49z"
				fill="#21252B"
			/>
			<path d="M386.84 306.221s7.47 51.58-18.76 52.64c-22.69 1-22-34.23-22-34.23l3.13-22.58 37.63 4.17z" fill="#FC9D9D" />
			<path
				d="M383.001 307.648l-23 1.66a5.343 5.343 0 00-4.84 5.59 5.23 5.23 0 01-1.26 3.8 5.22 5.22 0 01-3.58 1.79 1.524 1.524 0 00-1.32 1.51l.33 5.09a1.085 1.085 0 01-.254.873 1.076 1.076 0 01-.826.377 1.18 1.18 0 01-1.25-.92l-1-4.84-1-1.58-5.42 3.33s-8.76-13.51-4.59-25.85c2.42-7 9.34-9.5 12.68-9.92a3.647 3.647 0 002.58-1.67c1.33-2.25 4.59-6.17 11.84-5.92a12.225 12.225 0 0110.26 5.67 3.52 3.52 0 004.42 1.25 9.99 9.99 0 014.978-.9 9.996 9.996 0 014.782 1.65c7.47 4.75 4.47 17.75-3.53 19.01z"
				fill="#21252B"
			/>
			<path opacity={0.15} d="M344.801 336.078a5.704 5.704 0 003.34-.75l-3.67 5.67.33-4.92z" fill="#000" />
			<path
				d="M346.051 322.569a4.676 4.676 0 00-2.734-2.844 4.661 4.661 0 00-3.936.264c-5.67 2.5-4.5 15.34 5.09 16.09 5 .42 4-2.5 3.17-6.42l-1.59-7.09z"
				fill="#FC9D9D"
			/>
			<path
				opacity={0.15}
				d="M339.129 323.318a3.741 3.741 0 012.75-.75 3.331 3.331 0 012.17 1.75 6.884 6.884 0 01.92 3.51l.08 1.08a.59.59 0 01-1.17 0l-.08-1.08a7.067 7.067 0 00-.75-2.92 2.79 2.79 0 00-1.42-1.25 2.723 2.723 0 00-1.83.5.658.658 0 01-.8-.16c-.2-.09-.12-.43.13-.68zM380.41 362.678l-.84-8.169c-2.16 2.25-8.42 5.509-15.51 3.839a17.415 17.415 0 01-9.09-5.67.529.529 0 00-.565-.185.542.542 0 00-.386.453.511.511 0 00.121.392 18.771 18.771 0 0012 6.421 18.636 18.636 0 0014.27 2.919zM372.49 348.26c0 .08-.09.08 0 0-2.17.16-3.17.25-3.17.25a.733.733 0 01-.721-.947.673.673 0 01.631-.473l3.09-.25c.42 0 .75.25.75.58 0 .5-.25.84-.58.84zM373.649 345.252c-5.67.84-8.25-2.25-9.17-3.75a.69.69 0 01-.082-.249.672.672 0 011.252-.411c.83 1.5 3.67 4.75 10.51 2.5a.716.716 0 01.83.41.743.743 0 01-.42.84c-.95.313-1.927.534-2.92.66z"
				fill="#000"
			/>
			<path
				d="M365.811 331.91a2.086 2.086 0 001.939-1.285 2.088 2.088 0 00-.452-2.282 2.085 2.085 0 00-2.282-.452 2.095 2.095 0 00-.675 3.41c.39.39.918.609 1.47.609z"
				fill="#21252B"
			/>
			<path
				d="M366.73 330.408a.417.417 0 00.42-.42.408.408 0 00-.42-.41.411.411 0 00-.381.57.413.413 0 00.222.228.418.418 0 00.159.032z"
				fill="#fff"
			/>
			<path
				d="M382.33 332a2.083 2.083 0 001.931-1.29 2.087 2.087 0 00-.453-2.278 2.095 2.095 0 00-2.278-.453 2.096 2.096 0 00-1.29 1.931 2.089 2.089 0 002.09 2.09z"
				fill="#21252B"
			/>
			<path
				d="M383.24 330.49a.415.415 0 00.297-.116.412.412 0 00.123-.294.42.42 0 00-.579-.388.427.427 0 00-.223.228.415.415 0 00-.028.16.41.41 0 00.41.41z"
				fill="#fff"
			/>
			<path
				opacity={0.15}
				d="M375.15 340.169l-2.75.16a.698.698 0 01-.66-.58.718.718 0 01.58-.67l2.75-.16a1.285 1.285 0 001.17-1.34 1.277 1.277 0 00-1.34-1.16.695.695 0 01-.66-.59c-.09-.33.25-.58.58-.67a2.562 2.562 0 012.67 2.26 2.606 2.606 0 01-2.34 2.75z"
				fill="#000"
			/>
			<path
				d="M388.439 323.249l-9.09-.27h-1.25l-7.91-.23h-.09l-10.25-.31a2.99 2.99 0 00-2.827 1.768c-.162.36-.251.748-.263 1.142l-.07 2.34a8.171 8.171 0 007.93 8.41 8.171 8.171 0 008.41-7.93l.07-2.33a2.998 2.998 0 00-.61-1.86l3.16.1a2.854 2.854 0 00-.64 1.81l-.06 2.34a8.183 8.183 0 002.163 5.943 8.191 8.191 0 002.615 1.889 8.162 8.162 0 0010.882-4.197 8.154 8.154 0 00.67-3.155l.07-2.33a2.994 2.994 0 00-.8-2.174 3.007 3.007 0 00-2.11-.956zm-16.51 2.51l-.07 2.34a7.003 7.003 0 01-7.109 6.104 7.002 7.002 0 01-6.811-6.434l.07-2.34a1.84 1.84 0 011.89-1.77l10.25.3h.08a1.76 1.76 0 011.7 1.8zm18.19 2.88a7.003 7.003 0 01-7.115 6.154 6.998 6.998 0 01-6.815-6.484l.07-2.33a1.85 1.85 0 011.89-1.78h1.25l9.09.27a1.851 1.851 0 011.78 1.89l-.15 2.28z"
				fill="#21252B"
			/>
			<path
				opacity={0.15}
				d="M377.161 397.328l-1.61 178.86a6.209 6.209 0 001.801 4.427 6.205 6.205 0 004.409 1.843h47.11v-26.76l-51.71-158.37zM354.639 397.75l-24.18 148.85v18.33l-28.09-.34v328l14.67.09 13.33-285.85.34-8.15a9.83 9.83 0 018.2-9.28l11.63-1.93a6.521 6.521 0 004.646-1.941 6.527 6.527 0 001.884-4.669l-2.43-183.11z"
				fill="#000"
			/>
			<path
				d="M397.999 372.309l36.09 8.29a22.992 22.992 0 0117.49 18.3l19 98.5-37.11-3.75-1.61 83.74a3.003 3.003 0 01-3 3h-44.98a3.004 3.004 0 01-3-3l-4.1-179.65 21.22-25.43z"
				fill="#21252B"
			/>
			<path
				d="M397.999 372.309l36.09 8.29a22.992 22.992 0 0117.49 18.3l19 98.5-37.11-3.75-1.61 83.74a3.003 3.003 0 01-3 3h-44.98a3.004 3.004 0 01-3-3l-4.1-179.65 21.22-25.43zM332.539 372.309l-37.18 8.27a23.003 23.003 0 00-17.74 18.95l-15.62 102.36c-.113.73-.157 1.47-.13 2.21.3 7.77 8 13.12 15.53 11.13l5.94-1.57 14.6-5 .79 68.71a3 3 0 003 3h46.55a3 3 0 003-3l3.28-179.64-22.02-25.42z"
				fill="#21252B"
			/>
			<path opacity={0.4} d="M348.18 393.949v50" stroke="#fff" strokeMiterlimit={10} strokeLinecap="round" />
			<path opacity={0.4} d="M383.68 389.949v53.5" stroke="#fff" strokeWidth={1.03} strokeMiterlimit={10} strokeLinecap="round" />
			<path
				d="M379.621 357.48s21.5 5.27 18 18a11.91 11.91 0 01-2.53 4.57 134.487 134.487 0 01-18.2 17.62l4.58-26.64-1.85-13.55zM342.731 357.48s-24.09 6.11-15.89 21.1c0 0 2.37 6.66 27.8 19.95l-12.09-28.3.18-12.75z"
				fill="#21252B"
			/>
			<path
				opacity={0.4}
				d="M342.731 357.48s-24.09 6.11-15.89 21.1c0 0 2.37 6.66 27.8 19.95l-12.09-28.3.18-12.75zM379.621 357.48s21.5 5.27 18 18a11.91 11.91 0 01-2.53 4.57 134.487 134.487 0 01-18.2 17.62l4.58-26.64-1.85-13.55z"
				fill="#fff"
			/>
			<path
				opacity={0.3}
				d="M289.76 422.352a13.922 13.922 0 013.43 3.2 25.447 25.447 0 012.54 3.94 27.977 27.977 0 011.8 4.31 21.95 21.95 0 011 4.56c-.82-1.35-1.5-2.71-2.19-4.07-.37-.66-.67-1.36-1-2-.33-.64-.68-1.35-1-2-.68-1.34-1.4-2.66-2.14-4-.74-1.34-1.59-2.63-2.44-3.94zM433.86 460.712v-38.36l7.84 40a25.891 25.891 0 0010.33 16l17.24 12.31 1.41 7.72-31-9.39-6-28.61"
				fill="#000"
			/>
			<path d="M445.119 467.801s2.5-3.34 3.34 0l-.84 15-4.17-10 1.67-5z" fill="#FC9D9D" />
			<path opacity={0.15} d="M445.119 467.801s2.5-3.34 3.34 0l-.84 15-4.17-10 1.67-5z" fill="#000" />
			<path
				opacity={0.3}
				d="M439.7 396.5a61.054 61.054 0 00-1.76 6c-.51 2-.9 4-1.31 6.06-.77 4.06-1.44 8.16-2 12.24l-1.51-.24a37.943 37.943 0 012.21-6.45 19.411 19.411 0 011.62-3 7.865 7.865 0 012.33-2.47 9.157 9.157 0 00-1.8 2.77 29.992 29.992 0 00-1.19 3.08c-.33 1.06-.66 2.11-.92 3.19s-.55 2.15-.76 3.21l-1.51-.24c.18-2.1.48-4.17.81-6.24.33-2.07.75-4.12 1.27-6.15.24-1 .52-2 .83-3l.46-1.51.53-1.48a30.202 30.202 0 012.7-5.77zM298.08 520.632l.65 56.739a3 3 0 003 3h9.91l-.5-78.419-6-22-5.28-55.661a.653.653 0 00-.672-.544.65.65 0 00-.618.604l-.49 96.281z"
				fill="#000"
			/>
			<path
				d="M469.681 495.281a7.16 7.16 0 014 6.29c.53 7.38-3.47 24.38-23.09 20-1.22-.27-4.38-1.61-3.72-3.66v-.06l22.81-22.57z"
				fill="#21252B"
			/>
			<path
				opacity={0.4}
				d="M469.681 495.281a7.16 7.16 0 014 6.29c.53 7.38-3.47 24.38-23.09 20-1.22-.27-4.38-1.61-3.72-3.66v-.06l22.81-22.57z"
				fill="#fff"
			/>
			<path
				d="M447.619 482.809l20.44 10.94c1.85 1 2.59 3 2.87 5.07.85 6.27-3.31 25.67-22 20.52a6.627 6.627 0 01-3.43-2.34l-12.89-16.68 15.01-17.51z"
				fill="#FC9D9D"
			/>
			<path
				opacity={0.15}
				d="M440 498.441c3.43-2.75 6.94-7.76 8-15.22l-1.67-.83-15.84 15.84 2.08 2.09 12.93 16.68a6.608 6.608 0 003.43 2.34c13.57 3.74 19.47-5.46 21.37-13.18-9.07 16.7-25 1.94-30.57-4a2.533 2.533 0 01.27-3.72z"
				fill="#000"
			/>
			<path
				d="M452.89 453.199h-18.83a2.005 2.005 0 00-1.68.92l-22 34.61a.794.794 0 00-.029.793.807.807 0 00.669.427l20.47.78 22-36.32a.793.793 0 00-.6-1.21z"
				fill="#C62221"
			/>
			<path
				opacity={0.15}
				d="M452.891 453.199h-19.49a.774.774 0 00-.661.37l-22.32 35.16a.803.803 0 00.25 1.095.8.8 0 00.391.125l20.47.78 22-36.32a.794.794 0 00-.249-1.089.79.79 0 00-.391-.121z"
				fill="#000"
			/>
			<path d="M453.46 454.449h-18.77a1.526 1.526 0 00-1.27.7l-22.07 34.75 22.93 1.25 19.18-36.7z" fill="#C62221" />
			<path
				d="M439.42 480.741a.944.944 0 00.36-1.19l-1.33-5.92-5.11-9.78a2.253 2.253 0 011.08-2.8 1.994 1.994 0 011.36-.12c.453.12.85.396 1.12.78l7.39 10.29 3.33 10.84c-3.33 10-15 17.51-15 17.51h-3a9.19 9.19 0 01-5.2-1.62l-6.42-4.5a9.115 9.115 0 01-3.09-3.68l-3.39-7.35a1.944 1.944 0 01.38-2.18 1.937 1.937 0 012.9.18l4.49 5.78 5.36 4.59a1.005 1.005 0 001 .13l.1-.05a.941.941 0 00.34-1.41l-6-8.27-3.54-9.2a1.941 1.941 0 01.94-2.42l.21-.11a1.94 1.94 0 012.5.7l4.9 7.7 6.19 5.41a.942.942 0 001.05.14l.21-.1a1.004 1.004 0 00.4-1.32l-3.68-6.63-5.55-7.64a1.922 1.922 0 01.4-2.68 2.75 2.75 0 013.49.16l6.66 6.82 3.78 7.57a1.001 1.001 0 001.36.37h.01zM298.28 471.189l63-16.32 27.31-10.87a5.995 5.995 0 013.89-.2l12.81 3.61a5.783 5.783 0 012.55 1.49l8 8a2.188 2.188 0 01.37 2.597 2.203 2.203 0 01-.61.703 2.21 2.21 0 01-2.63 0l-8.69-6.52-7.45-2.24a1.103 1.103 0 00-.96 1.95l9.45 6.75a2.426 2.426 0 01.89 2.75c-.18.522-.533.966-1 1.26l-.74.49a2.406 2.406 0 01-2.25.24l-3-1.19 1.17.78a2.095 2.095 0 01.317 3.217 2.068 2.068 0 01-.817.503l-.9.3c-.401.125-.83.125-1.23 0l-5.17-1.49 4.53 1.95a1.321 1.321 0 01.729.765 1.325 1.325 0 01-.059 1.055c-.13.26-.341.47-.6.6l-1.31.65a6.111 6.111 0 01-3.44.62l-5.69-.63s-7.51 1.66-19.18.83l-65.88 33.36-12.51-14.2 9.1-20.81z"
				fill="#FC9D9D"
			/>
			<path
				d="M282.511 472.801s9.17-3.34 15.84-3.34c0 0-1.66 34.19 3.34 36.7 0 0-9.2 7.82-18.36 7.66 0 0-3.33-7.66-.82-41.02z"
				fill="#21252B"
			/>
			<path
				opacity={0.4}
				d="M285.16 471.931a53.142 53.142 0 0112.39-2.45.747.747 0 01.77.79c-.22 5.16-1.18 33.61 3.37 35.89 0 0-9.2 7.82-18.36 7.66 0 0-6.63-11.48-1.38-38.33a4.665 4.665 0 013.21-3.56z"
				fill="#fff"
			/>
			<path
				opacity={0.15}
				d="M397.169 469.879c0 3.34-10.84 0-10.84 0-5 0-20-2.5-20-2.5l-67.8 27.2 1 9.09 2.19 2.49 65.88-33.36c11.67.83 19.18-.83 19.18-.83l5.69.63a6.11 6.11 0 003.44-.62l1.31-.65c.26-.13.471-.34.6-.6v-.05l-.65-.8z"
				fill="#000"
			/>
			<path
				opacity={0.3}
				d="M298.771 494.898c-38.36 17.51-35.76.38-35.76.38l-1 6.61a11.594 11.594 0 00-.13 2.21c.3 7.77 8 13.12 15.53 11.13l5.94-1.57 14.6-5v.09l4.17-2.18c-3.35-1.66-3.35-11.67-3.35-11.67z"
				fill="#000"
			/>
			<path
				opacity={0.15}
				d="M399.94 463.901l-6.94-3.19M395.11 468.041l-5.44-2.33"
				stroke="#000"
				strokeWidth={0.83}
				strokeMiterlimit={10}
				strokeLinecap="round"
			/>
			<path
				opacity={0.2}
				d="M312.31 507.949H336a2.133 2.133 0 012.13 2.13v24.92a5.92 5.92 0 01-5.92 5.92H316.1a5.916 5.916 0 01-5.92-5.92v-24.92a2.128 2.128 0 012.13-2.13zM394.31 507.949H418a2.133 2.133 0 012.13 2.13v24.92a5.92 5.92 0 01-5.92 5.92H398.1a5.916 5.916 0 01-5.92-5.92v-24.92a2.128 2.128 0 012.13-2.13z"
				fill="#fff"
			/>
			<path
				d="M1114.58 240.949h-57.8c-3.09 0-5.6 2.507-5.6 5.6v26.8c0 3.093 2.51 5.6 5.6 5.6h57.8c3.09 0 5.6-2.507 5.6-5.6v-26.8c0-3.093-2.51-5.6-5.6-5.6z"
				fill="#C62221"
			/>
			<path
				opacity={0.2}
				d="M1114.58 240.949h-57.8c-3.09 0-5.6 2.507-5.6 5.6v26.8c0 3.093 2.51 5.6 5.6 5.6h57.8c3.09 0 5.6-2.507 5.6-5.6v-26.8c0-3.093-2.51-5.6-5.6-5.6z"
				fill="#000"
			/>
			<path
				d="M1108.58 240.949h-57.8c-3.09 0-5.6 2.507-5.6 5.6v26.8c0 3.093 2.51 5.6 5.6 5.6h57.8c3.09 0 5.6-2.507 5.6-5.6v-26.8c0-3.093-2.51-5.6-5.6-5.6z"
				fill="#C62221"
			/>
			<g opacity={0.3} fill="#000">
				<path
					opacity={0.3}
					d="M1059.84 265.531a5.581 5.581 0 000-11.16 5.581 5.581 0 000 11.16zM1079.68 265.531a5.581 5.581 0 000-11.16 5.581 5.581 0 000 11.16zM1099.52 265.531a5.581 5.581 0 000-11.16 5.581 5.581 0 000 11.16z"
				/>
			</g>
			<path d="M1055.06 278.949l.12 12 15-15-15.12 3z" fill="#C62221" />
			<path
				opacity={0.2}
				d="M1047.18 262.002v-14c0-.581.11-1.155.34-1.691a4.374 4.374 0 012.4-2.383c.53-.219 1.11-.33 1.69-.326l45.57.33-43.63 2.78c-.96.069-1.87.452-2.6 1.089a4.42 4.42 0 00-1.4 2.441l-2.37 11.76z"
				fill="#fff"
			/>
			<path
				d="M1071.12 710a6.96 6.96 0 00-2.99.702 6.962 6.962 0 00-2.41 1.918c-8.75 11.26-10.52 35-6.92 41.15 4.26 7.25-.14 9.86.89 16.42.83 5.33 8.32 9.37 11.1 10.7a2.4 2.4 0 002.07 0c2.87-1.39 10.81-5.68 11.49-11.06.83-6.59-3.64-9.06.39-16.44 3.42-6.26.94-29.93-8.15-40.92a6.919 6.919 0 00-2.46-1.851 6.893 6.893 0 00-3.01-.609"
				fill="#C62221"
			/>
			<path
				opacity={0.4}
				d="M1071.12 710a6.96 6.96 0 00-2.99.702 6.962 6.962 0 00-2.41 1.918c-8.75 11.26-10.52 35-6.92 41.15 4.26 7.25-.14 9.86.89 16.42.83 5.33 8.32 9.37 11.1 10.7a2.4 2.4 0 002.07 0c2.87-1.39 10.81-5.68 11.49-11.06.83-6.59-3.64-9.06.39-16.44 3.42-6.26.94-29.93-8.15-40.92a6.919 6.919 0 00-2.46-1.851 6.893 6.893 0 00-3.01-.609"
				fill="#fff"
			/>
			<path
				d="M1133 774.638a7 7 0 00-5.78-1.61c-14 2.63-31.08 19.23-32.46 26.23-1.62 8.25-6.64 7.29-10.21 12.88-2.91 4.54 0 12.53 1.22 15.37a2.367 2.367 0 001.55 1.36c3.07.87 11.86 2.92 15.93-.66 5-4.39 3.28-9.2 11.19-12.05 6.71-2.42 20.55-21.79 21-36a7.09 7.09 0 00-.61-3.012 7.067 7.067 0 00-1.85-2.458M1007.36 789.932a5.676 5.676 0 00-1.56 2.026 5.84 5.84 0 00-.53 2.504c.28 11.85 11.62 28.08 17.19 30.15 6.55 2.43 5.09 6.42 9.2 10.11 3.34 3 10.3 1.66 12.79 1 .29-.073.55-.208.78-.397.23-.188.41-.425.54-.693 1.13-2.41 4-9.35 1.59-13.17-2.93-4.68-7.11-3.92-8.4-10.8-1.09-5.83-15.14-19.78-26.78-22.08a5.783 5.783 0 00-2.56.101c-.83.225-1.6.635-2.26 1.199"
				fill="#C62221"
			/>
			<path
				d="M1112.81 816.419a5.057 5.057 0 00-4.28-.75c-9.91 2.89-21 16.07-21.52 21.21-.58 6.06-4.26 5.73-6.45 10-1.77 3.48.91 9 2 11 .12.227.3.422.51.573.21.151.45.252.7.297 2.28.41 8.76 1.26 11.44-1.61 3.28-3.51 1.71-6.86 7.21-9.48 4.66-2.22 13.25-17.17 12.58-27.47a5.01 5.01 0 00-.65-2.133 5.077 5.077 0 00-1.52-1.637M1038.64 745.42c-.82.257-1.58.694-2.21 1.281a5.736 5.736 0 00-1.45 2.109c-4.26 11.07 0 30.4 4.41 34.43 5.13 4.75 2.26 7.88 4.66 12.85 2 4 8.88 5.46 11.42 5.85a1.999 1.999 0 001.64-.51c2-1.8 7.24-7.12 6.48-11.57-.93-5.45-5.08-6.34-3.65-13.18 1.22-5.81-6.46-24.06-16.35-30.62a5.81 5.81 0 00-4.95-.64zM1107.53 748.001a5.72 5.72 0 00-2.48-.672 5.743 5.743 0 00-2.52.472c-10.83 4.82-21.45 21.53-21.22 27.46.27 7-4 7.18-5.8 12.39-1.48 4.23 2.43 10.14 4 12.2.18.239.4.435.66.575.27.14.55.221.85.235 2.66.11 10.16.08 12.77-3.6 3.19-4.51.89-8.08 6.74-11.9 5-3.25 12.44-21.59 10.09-33.21a5.794 5.794 0 00-1.05-2.34 5.759 5.759 0 00-1.95-1.66"
				fill="#C62221"
			/>
			<path
				opacity={0.2}
				d="M1038.64 745.42c-.82.257-1.58.694-2.21 1.281a5.736 5.736 0 00-1.45 2.109c-4.26 11.07 0 30.4 4.41 34.43 5.13 4.75 2.26 7.88 4.66 12.85 2 4 8.88 5.46 11.42 5.85a1.999 1.999 0 001.64-.51c2-1.8 7.24-7.12 6.48-11.57-.93-5.45-5.08-6.34-3.65-13.18 1.22-5.81-6.46-24.06-16.35-30.62a5.81 5.81 0 00-4.95-.64zM1107.53 748.001a5.72 5.72 0 00-2.48-.672 5.743 5.743 0 00-2.52.472c-10.83 4.82-21.45 21.53-21.22 27.46.27 7-4 7.18-5.8 12.39-1.48 4.23 2.43 10.14 4 12.2.18.239.4.435.66.575.27.14.55.221.85.235 2.66.11 10.16.08 12.77-3.6 3.19-4.51.89-8.08 6.74-11.9 5-3.25 12.44-21.59 10.09-33.21a5.794 5.794 0 00-1.05-2.34 5.759 5.759 0 00-1.95-1.66"
				fill="#fff"
			/>
			<path
				d="M1069.18 750.621a5.758 5.758 0 00-4.57 2c-7.63 9.08-9.85 28.75-7 34 3.31 6.17-.42 8.19.22 13.68.52 4.45 6.62 8.05 8.89 9.24a1.999 1.999 0 001.72.06c2.43-1.06 9.17-4.38 9.9-8.83.89-5.45-2.74-7.65.84-13.65 3-5.1 1.73-24.85-5.48-34.27a5.79 5.79 0 00-1.98-1.618 5.822 5.822 0 00-2.49-.602M1037.42 839.17a4.45 4.45 0 00-1.35 1.487c-.34.59-.53 1.247-.58 1.924-.55 9.29 7.27 22.699 11.49 24.679 5 2.33 3.57 5.35 6.54 8.5 2.42 2.56 7.94 2 9.92 1.65.23-.04.45-.13.64-.26s.35-.308.47-.509c1-1.81 3.72-7 2.09-10.19-2-3.85-5.31-3.53-5.86-9-.48-4.63-10.56-16.44-19.51-19a4.52 4.52 0 00-2-.084c-.67.121-1.3.388-1.85.784"
				fill="#C62221"
			/>
			<path
				opacity={0.2}
				d="M1112.81 816.419a5.057 5.057 0 00-4.28-.75c-9.91 2.89-21 16.07-21.52 21.21-.58 6.06-4.26 5.73-6.45 10-1.77 3.48.91 9 2 11 .12.227.3.422.51.573.21.151.45.252.7.297 2.28.41 8.76 1.26 11.44-1.61 3.28-3.51 1.71-6.86 7.21-9.48 4.66-2.22 13.25-17.17 12.58-27.47a5.01 5.01 0 00-.65-2.133 5.077 5.077 0 00-1.52-1.637M1069.18 750.621a5.758 5.758 0 00-4.57 2c-7.63 9.08-9.85 28.75-7 34 3.31 6.17-.42 8.19.22 13.68.52 4.45 6.62 8.05 8.89 9.24a1.999 1.999 0 001.72.06c2.43-1.06 9.17-4.38 9.9-8.83.89-5.45-2.74-7.65.84-13.65 3-5.1 1.73-24.85-5.48-34.27a5.79 5.79 0 00-1.98-1.618 5.822 5.822 0 00-2.49-.602M1037.42 839.17a4.45 4.45 0 00-1.35 1.487c-.34.59-.53 1.247-.58 1.924-.55 9.29 7.27 22.699 11.49 24.679 5 2.33 3.57 5.35 6.54 8.5 2.42 2.56 7.94 2 9.92 1.65.23-.04.45-.13.64-.26s.35-.308.47-.509c1-1.81 3.72-7 2.09-10.19-2-3.85-5.31-3.53-5.86-9-.48-4.63-10.56-16.44-19.51-19a4.52 4.52 0 00-2-.084c-.67.121-1.3.388-1.85.784"
				fill="#000"
			/>
			<path d="M1070.76 890.3l-.7-.21c.17-.56 17.11-56.48 42.84-72.25l.38.62c-25.49 15.62-42.35 71.28-42.52 71.84z" fill="#C62221" />
			<path d="M1069.67 890.29c-2.33-14.34-31.19-48.57-31.48-48.91l.55-.47c1.2 1.41 29.29 34.73 31.65 49.26l-.72.12z" fill="#C62221" />
			<path
				d="M1070.58 890.22l-.73-.08 3.4-29.42a78.564 78.564 0 0120.3-44.45l37.52-40.52.54.5-37.52 40.51a77.81 77.81 0 00-20.11 44l-3.4 29.46z"
				fill="#C62221"
			/>
			<path d="M1069.56 890.33c-.19-.68-19.43-68.12-60.93-98.83l.43-.59c41.71 30.86 61 98.54 61.2 99.22l-.7.2z" fill="#C62221" />
			<path d="M1068.99 754.077l-.73.009 1.62 136.154.73-.01-1.62-136.153z" fill="#C62221" />
			<path d="M1069.48 846.081c-.72-43.17 36.49-96.16 36.87-96.69l.59.43c-.37.52-37.45 53.32-36.73 96.24l-.73.02z" fill="#C62221" />
			<path
				d="M1069.48 848.101c-.89-16.54-31-100-31.36-100.8l.69-.25c.3.84 30.5 84.36 31.4 101l-.73.05zM1070.92 711.785l-.73.027 1.46 38.863.73-.027-1.46-38.863zM1039.04 850.855l-.28.672 10.94 4.621.29-.672-10.95-4.621zM1010.34 802.941l-.27.676 19.09 7.785.28-.676-19.1-7.785z"
				fill="#C62221"
			/>
			<path
				d="M1035.9 758.675l-.57.45 12.77 16.302.58-.45-12.78-16.302zM1075.7 769.038l-6.58 23.353.71.197 6.57-23.352-.7-.198zM1093.33 756.169l-2.67 19.589.72.098 2.67-19.589-.72-.098zM1109 800.42l-.71-.18c1.82-7.08 4.54-17.79 4.61-18.31h.73c0 .37-3.24 13-4.63 18.46M1106.94 833.636l-16.3 9.614.37.629 16.3-9.614-.37-.629z"
				fill="#C62221"
			/>
			<g opacity={0.3} fill="#000">
				<path opacity={0.3} d="M1070.76 890.3l-.7-.21c.17-.56 17.11-56.48 42.84-72.25l.38.62c-25.49 15.62-42.35 71.28-42.52 71.84z" />
				<path opacity={0.3} d="M1069.67 890.29c-2.33-14.34-31.19-48.57-31.48-48.91l.55-.47c1.2 1.41 29.29 34.73 31.65 49.26l-.72.12z" />
				<path
					opacity={0.3}
					d="M1070.58 890.22l-.73-.08 3.4-29.42a78.564 78.564 0 0120.3-44.45l37.52-40.52.54.5-37.52 40.51a77.81 77.81 0 00-20.11 44l-3.4 29.46z"
				/>
				<path opacity={0.3} d="M1069.56 890.33c-.19-.68-19.43-68.12-60.93-98.83l.43-.59c41.71 30.86 61 98.54 61.2 99.22l-.7.2z" />
				<path opacity={0.3} d="M1068.99 754.077l-.73.009 1.62 136.154.73-.01-1.62-136.153z" />
				<path opacity={0.3} d="M1069.48 846.081c-.72-43.17 36.49-96.16 36.87-96.69l.59.43c-.37.52-37.45 53.32-36.73 96.24l-.73.02z" />
				<path
					opacity={0.3}
					d="M1069.48 848.101c-.89-16.54-31-100-31.36-100.8l.69-.25c.3.84 30.5 84.36 31.4 101l-.73.05zM1070.92 711.785l-.73.027 1.46 38.863.73-.027-1.46-38.863zM1039.04 850.859l-.28.672 10.94 4.621.29-.672-10.95-4.621zM1010.34 802.941l-.27.676 19.09 7.785.28-.676-19.1-7.785z"
				/>
				<path
					opacity={0.3}
					d="M1035.9 758.675l-.57.45 12.77 16.302.58-.45-12.78-16.302zM1075.7 769.038l-6.58 23.353.71.197 6.57-23.352-.7-.198zM1093.33 756.169l-2.67 19.589.72.098 2.67-19.589-.72-.098zM1109 800.42l-.71-.18c1.82-7.08 4.54-17.79 4.61-18.31h.73c0 .37-3.24 13-4.63 18.46M1106.94 833.636l-16.3 9.614.37.629 16.3-9.614-.37-.629z"
				/>
			</g>
			<path
				d="M1083.66 953.12h-26.54c-1.85 0-3.69-.36-5.41-1.07a14.16 14.16 0 01-4.58-3.06 14.16 14.16 0 01-3.06-4.58c-.71-1.72-1.07-3.55-1.07-5.41v-47.93h54.74V939c0 3.74-1.49 7.33-4.13 9.97a14.11 14.11 0 01-9.97 4.13"
				fill="#868A91"
			/>
			<path
				d="M1070.76 953.12h12.9c3.74 0 7.33-1.49 9.97-4.13a14.11 14.11 0 004.13-9.97v-42l-54.59.68-.17 2.3h40.14v40s2.92 13.13-12.4 13.13"
				fill="#606266"
			/>
			<path
				d="M1099.07 897.65H1041c-.21 0-.42-.04-.61-.12-.2-.08-.38-.2-.53-.35-.15-.14-.27-.32-.35-.52-.08-.19-.12-.4-.12-.61v-3.36c0-.21.04-.42.12-.61.08-.2.2-.38.35-.53.15-.14.33-.26.53-.34.19-.08.4-.12.61-.12h58.09c.21 0 .42.04.62.12.19.08.37.2.52.34.15.15.27.33.35.53.08.19.12.4.12.61v3.36c0 .21-.04.42-.12.61-.08.2-.2.38-.35.52-.15.15-.33.27-.52.35-.2.08-.41.12-.62.12"
				fill="#868A91"
			/>
			<path
				d="M1100.68 896.05v-3.36a1.7 1.7 0 00-.53-1.16c-.33-.3-.76-.46-1.2-.44h-11.49v6.56H1099c.22.01.44-.02.64-.1.21-.07.4-.19.56-.34.16-.15.29-.33.38-.53.09-.19.14-.41.15-.63"
				fill="#606266"
			/>
			<path
				d="M1097.3 458c-11.5.1-22.6 4.7-30.7 12.9-8.1 8.2-12.7 19.3-12.6 30.8.1 11.5 4.7 22.6 12.9 30.7 8.2 8.1 19.3 12.7 30.8 12.6 3.6 0 7.1-.5 10.6-1.4.5-.1 1-.3 1.5-.6.4-.3.8-.7 1.1-1.2.3-.4.4-.9.4-1.4 0-.5 0-1-.2-1.5 0-.4-.2-.8-.4-1.2-.2-.4-.5-.7-.8-.9-.5-.3-1-.5-1.6-.6-.6-.1-1.1-.1-1.7.1-2.9.8-5.9 1.1-8.9 1-7.1 0-14-2-19.9-5.9-5.9-3.9-10.5-9.5-13.3-16-2.8-6.5-3.5-13.7-2.2-20.7 1.3-7 4.7-13.3 9.7-18.4 5-5 11.3-8.5 18.3-9.9 6.9-1.4 14.1-.8 20.7 1.9s12.2 7.2 16.1 13.1c3.9 5.9 6.1 12.8 6.2 19.8.1 5.7-1.2 11.3-3.9 16.3-.4 1-3 5-6.3 5.8h-.9c-1.1 0-1.4-.3-1.4-.4-.4-.8-.6-1.6-.8-2.4-.5-4.3-.1-17.6-.1-17.8v-1.6c0-13-10.2-23.5-22.5-23.5s-22.6 10.7-22.5 23.7c.1 13 10.3 23.8 22.7 23.8 5.5 0 10.9-2.2 14.8-6.1v2c.1 2.3.8 4.5 2.2 6.4.9 1.2 2 2.1 3.4 2.7 1.3.6 2.8.9 4.3.9.9 0 1.8-.1 2.7-.3 4.4-1.3 8.1-4.4 11.2-9.2.1-.2.2-.3.2-.3l.2-.2c3.3-6.7 4.8-14.1 4.4-21.5-.4-7.4-2.6-14.6-6.5-20.9-3.9-6.3-9.4-11.5-15.9-15.1-6.6-3.5-13.9-5.4-21.3-5.5zm.2 59.2c-8.2 0-14.6-6.9-14.6-15.7 0-8.8 6.4-15.9 14.5-16 8.1-.1 14.7 7.2 14.8 15.8 0 8.7-6.6 15.9-14.7 15.9z"
				fill="#C62221"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M810 326h89.49v21.291h-5.872v-15.422h-77.749v77.749h77.749v-15.419h5.872v21.291H810V326z"
				fill="#B11916"
			/>
			<path
				d="M821.277 387.569h6.014l3.435-8.067h15.798l3.389 8.067h6.204l-14.701-33.65h-5.442l-14.697 33.65zm11.597-13.268l5.728-13.364 5.774 13.364h-11.502zm27.604 13.268h5.872v-11.647h7.351l8.208 11.647h6.921l-9.021-12.649c4.678-1.335 7.97-4.629 7.97-10.117v-.095c0-2.911-1.002-5.394-2.77-7.207-2.147-2.1-5.441-3.341-9.64-3.341h-14.891v33.409zm5.872-16.848v-11.216h8.544c4.343 0 6.921 1.957 6.921 5.536v.097c0 3.388-2.673 5.583-6.874 5.583h-8.591zm27.269 16.848h23.434v-5.345h-17.565v-28.066h-5.872v33.411h.003z"
				fill="#333"
			/>
		</svg>
	);
};
