import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		buttonIcon: {
			width: 24,
			margin: [[-4, 4, -4, 0]],
			fill: getThemeStyle('paletteCommonWhite'),
		},
		buttonStack: {},
		'@global': {
			'.MuiList-root': {
				'&$buttonStack': {
					marginTop: spacingUnits(1),
				},
			},
		},
	},
	{ name: 'contactButtons' },
);
