import React from 'react';

export const VirtualOffice = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" viewBox="0 0 682 489" {...props}>
			<path
				fill="#F2F2F2"
				d="M635.156 487.946l45.999-251.966c1.323-7.263.125-14.773-3.391-21.213L585.62 46.117c-4.991-9.133-14.067-15.086-24.195-15.87L182.722 1.032c-9.836-.762-19.416 3.455-25.693 11.306L7.793 198.958c-5.816 7.275-8.154 16.862-6.364 26.113l50.92 262.875h582.807z"
			/>
			<path
				fill="#FC9D9D"
				d="M277.681 297.121l4.644 4.519c.607.594.363 1.646-.44 1.887l-1.616.482a2.318 2.318 0 01-1.845-.224l-7.681-4.508 7.649-2.117"
			/>
			<path
				fill="#000"
				d="M277.681 297.121l4.644 4.519c.607.594.363 1.646-.44 1.887l-1.616.482a2.318 2.318 0 01-1.845-.224l-7.681-4.508"
				opacity="0.15"
			/>
			<path
				fill="#FC9D9D"
				d="M266.663 295.99l7.98-1.378a4.344 4.344 0 013.125.661l6.374 4.222a.968.968 0 01.261 1.333.896.896 0 01-1.123.33c-1.725-.772-6.06-2.716-6.06-2.716l-5.74 1.344a.635.635 0 00-.011 1.227l3.266.817c1.064.269 1.747 1.344 1.563 2.459a.53.53 0 01-.516.448l-15.168-.017-4.08-9.962 10.129 1.232z"
			/>
			<path
				fill="#21252B"
				d="M256.03 324.253h45.548c.369 0 .695.336.695.717v1.193c0 .381-.326.717-.695.717H256.03c-.369 0-.694-.336-.694-.717v-1.193c0-.386.276-.717.694-.717z"
			/>
			<path
				fill="#000"
				d="M256.03 324.253h45.548c.369 0 .695.336.695.717v1.193c0 .381-.326.717-.695.717H256.03c-.369 0-.694-.336-.694-.717v-1.193c0-.386.276-.717.694-.717z"
				opacity="0.6"
			/>
			<path
				fill="#21252B"
				d="M287.994 324.253h79.411c.369 0 .695.336.695.717v1.193c0 .381-.326.717-.695.717h-79.411c-.369 0-.694-.336-.694-.717v-1.193c.048-.386.325-.717.694-.717z"
			/>
			<path
				fill="#000"
				d="M367.465 324.729h-80.838c-.141 0-.228-.095-.228-.235 0-.145.092-.235.228-.235h80.838c.141 0 .228.095.228.235 0 .14-.13.235-.228.235z"
				opacity="0.6"
			/>
			<path
				fill="#21252B"
				d="M354.369 324.253h11.962c.928 0 1.709-.667 1.893-1.619l10.026-54.409c.228-1.238-.695-2.335-1.894-2.335h-11.962c-.927 0-1.709.666-1.893 1.618l-10.025 54.41c-.185 1.237.694 2.335 1.893 2.335z"
			/>
			<path
				fill="#000"
				d="M354.369 324.253h11.962c.928 0 1.709-.667 1.893-1.619l10.026-54.409c.228-1.238-.695-2.335-1.894-2.335h-11.962c-.927 0-1.709.666-1.893 1.618l-10.025 54.41c-.185 1.237.694 2.335 1.893 2.335z"
				opacity="0.6"
			/>
			<path
				fill="#21252B"
				d="M288.271 324.253h76.818c.927 0 1.709-.667 1.893-1.619l10.025-54.409c.228-1.238-.694-2.335-1.893-2.335h-76.769c-.928 0-1.709.666-1.893 1.618l-10.026 54.41c-.233 1.237.646 2.335 1.845 2.335z"
			/>
			<path
				fill="#fff"
				d="M290.023 322.87h73.894c.879 0 1.627-.639 1.806-1.546l9.554-51.839c.217-1.182-.662-2.229-1.807-2.229h-73.85c-.879 0-1.628.638-1.807 1.546l-9.553 51.839c-.217 1.187.618 2.229 1.763 2.229z"
			/>
			<path
				fill="#61656D"
				d="M374.989 270.828l.293-1.635c.179-1.008-.569-1.932-1.562-1.932h-74.241c-.836 0-1.557.616-1.704 1.467l-.369 2.1h77.583z"
			/>
			<path
				fill="#000"
				d="M300.699 269.126c-.103.409-.52.745-.933.74-.412 0-.667-.336-.564-.745.103-.409.521-.746.933-.74.413.006.667.337.564.745zM303.493 269.131c-.103.409-.521.745-.933.74-.412 0-.667-.336-.564-.745.103-.409.521-.745.933-.739.412.005.667.336.564.744zM306.303 269.131c-.103.409-.52.745-.933.74-.412 0-.667-.336-.564-.745.103-.409.521-.745.933-.739.413.005.668.336.564.744z"
			/>
			<path fill="#ACB0B5" d="M175.637 334.361h-3.803v153.518h3.803V334.361zM376.66 334.361h-3.803V487.75h3.803V334.361z" />
			<path fill="#61656D" d="M416.887 334.361h-40.232v153.384h40.232V334.361z" />
			<path fill="#80848B" d="M416.887 334.361h-40.232v153.384h40.232V334.361z" />
			<path fill="#61656D" d="M215.874 334.361h-40.232v153.384h40.232V334.361z" />
			<path fill="#80848B" d="M215.874 334.361h-40.232v153.518h40.232V334.361z" />
			<path fill="#ACB0B5" d="M280.849 334.786l92.008-.425v25.721l-92.008.42v-25.716z" />
			<path
				fill="#000"
				d="M331.231 346.159c.456 2.123-1.323 3.976-3.39 3.522-1.047-.229-1.888-1.086-2.132-2.167-.478-2.139 1.34-4.026 3.412-3.544 1.042.246 1.877 1.114 2.11 2.189z"
				opacity="0.5"
			/>
			<path fill="#61656D" d="M338.696 326.716h77.583c2.045 0 3.705 1.714 3.705 3.825s-1.66 3.825-3.705 3.825h-77.583" />
			<path fill="#80848B" d="M338.696 326.716h77.583c2.045 0 3.705 1.714 3.705 3.825s-1.66 3.825-3.705 3.825h-77.583" />
			<path
				fill="#ACB0B5"
				d="M166.946 326.716h214.146c2.046 0 3.706 1.714 3.706 3.825s-1.66 3.825-3.706 3.825H166.946c-2.045 0-3.705-1.714-3.705-3.825.005-2.111 1.66-3.825 3.705-3.825z"
			/>
			<path
				fill="#000"
				d="M416.892 334.361H280.849v1.305h136.043v-1.305zM215.869 334.361h-44.035v1.305h44.035v-1.305zM190.311 388.799h-18.477v3.819h19.02l-.543-3.819z"
				opacity="0.5"
			/>
			<path
				fill="#61656D"
				d="M289.969 322.869l74.062-.005c.846 0 1.573-.627 1.72-1.484l.629-3.629h-77.583l-.553 2.951c-.212 1.125.618 2.167 1.725 2.167z"
			/>
			<path
				fill="#000"
				d="M327.249 320.31c-.19.768-.971 1.389-1.747 1.389-.775 0-1.247-.627-1.057-1.394.189-.767.971-1.389 1.746-1.389.771.006 1.248.627 1.058 1.394zM333.358 320.321c-.19.767-.971 1.389-1.747 1.389-.776 0-1.248-.627-1.058-1.395.19-.767.971-1.388 1.747-1.388.776.005 1.248.627 1.058 1.394zM321.157 320.299c-.19.767-.971 1.389-1.747 1.389-.776 0-1.248-.627-1.058-1.394.19-.768.971-1.389 1.747-1.389.776.005 1.248.627 1.058 1.394z"
			/>
			<path
				fill="#C62221"
				d="M339.336 315.157h13.91c.168 0 .304-.117.342-.291l1.584-8.568a.35.35 0 00-.342-.42h-13.904c-.168 0-.304.118-.342.291L339 314.737c-.033.224.125.42.336.42zM298.86 315.157h13.91c.168 0 .304-.117.342-.291l1.584-8.568a.35.35 0 00-.342-.42H300.45c-.168 0-.304.118-.342.291l-1.584 8.568c-.033.224.125.42.336.42zM319.101 315.157h13.91c.168 0 .304-.117.341-.291l1.585-8.568a.35.35 0 00-.342-.42h-13.904c-.169 0-.304.118-.342.291l-1.584 8.568c-.038.224.119.42.336.42zM307.66 303.566h43.172c.515 0 .949-.37 1.052-.902l4.91-26.589a1.082 1.082 0 00-1.053-1.299h-43.145c-.515 0-.949.37-1.052.902l-4.91 26.589c-.13.688.358 1.299 1.026 1.299z"
			/>
			<path
				fill="#fff"
				d="M307.66 303.566h43.172c.515 0 .949-.37 1.052-.902l4.91-26.589a1.082 1.082 0 00-1.053-1.299h-43.145c-.515 0-.949.37-1.052.902l-4.91 26.589c-.13.688.358 1.299 1.026 1.299z"
				opacity="0.5"
			/>
			<path
				fill="#C62221"
				d="M346.855 301.662l-.971-2.879 4.123-2.06v-5.147c0-1.277-.9-2.357-2.121-2.553l-11.805-1.445c-.965-.146-1.915.325-2.408 1.193l-3.63 6.395 2.913.89.163 7.431h13.742v-1.825h-.006z"
			/>
			<path
				fill="#fff"
				d="M332.712 293.29c.017 0 .038-.006.049-.023l.44-.425a.08.08 0 00.006-.107.075.075 0 00-.104-.005l-.44.425a.08.08 0 00-.005.107.06.06 0 00.054.028z"
			/>
			<path
				fill="#fff"
				d="M333.141 293.307c.017 0 .038-.005.049-.022a.08.08 0 00.006-.107l-.413-.453a.074.074 0 00-.103-.006.08.08 0 00-.006.107l.413.453c.016.017.033.028.054.028zM337.796 290.775c.016 0 .038-.005.048-.022l.44-.426a.078.078 0 00.006-.106.074.074 0 00-.103-.006l-.44.426a.08.08 0 00-.006.106c.017.017.033.028.055.028z"
			/>
			<path
				fill="#fff"
				d="M338.225 290.787c.016 0 .038-.005.049-.022a.08.08 0 00.005-.107l-.413-.453a.074.074 0 00-.103-.006c-.027.028-.033.079-.005.107l.412.453c.017.023.033.028.055.028zM341.772 292.747c0-.303.239-.549.532-.549a.54.54 0 01.531.549.54.54 0 01-.531.548.541.541 0 01-.532-.548zm.147 0c0 .224.173.403.39.403a.396.396 0 00.391-.403.396.396 0 00-.391-.404c-.217 0-.39.18-.39.404zM347.159 294.018a.074.074 0 00.071-.073c0-.224.173-.403.385-.403.211 0 .385.179.385.403 0 .039.033.073.07.073a.074.074 0 00.071-.073.542.542 0 00-.532-.549.541.541 0 00-.531.549c.011.039.043.073.081.073zM347.848 291.577l.982-.342a.07.07 0 00.043-.095.068.068 0 00-.092-.045l-.987.342a.072.072 0 00-.044.095c.017.034.06.056.098.045zM338.381 292.82h1.042a.074.074 0 00.071-.073.074.074 0 00-.071-.073h-1.042c-.038 0-.07.034-.07.073 0 .039.032.073.07.073zM336.115 289.387c.016 0 .038-.006.049-.023a.08.08 0 000-.106l-.734-.756a.074.074 0 00-.103 0 .08.08 0 000 .106l.733.756a.078.078 0 00.055.023z"
			/>
			<path
				fill="#fff"
				d="M341.17 293.144c0-.269.211-.493.477-.493.261 0 .478.219.478.493a.483.483 0 01-.478.493.49.49 0 01-.477-.493zm.032 0c0 .252.201.459.445.459a.454.454 0 00.445-.459.454.454 0 00-.445-.459.454.454 0 00-.445.459zM346.486 294.343h.028c0-.252.2-.459.444-.459s.445.207.445.459h.027a.485.485 0 00-.472-.493.485.485 0 00-.472.493zM348.248 291.737l-.986.345.008.026.987-.344-.009-.027zM338.761 293.127h-1.041v.028h1.041v-.028zM334.717 288.948l-.019.02.738.76.019-.02-.738-.76zM333.792 300.442a.266.266 0 01-.114-.163.25.25 0 01.033-.196.254.254 0 01.157-.117.228.228 0 01.19.033.26.26 0 01.114.163.25.25 0 01-.032.196.26.26 0 01-.158.117c-.016.006-.038.006-.054.006a.228.228 0 01-.136-.039zm.109-.331a.107.107 0 00-.071.051c-.016.028-.022.056-.016.084a.103.103 0 00.049.072.107.107 0 00.152-.033.122.122 0 00.016-.084.11.11 0 00-.109-.09c-.005-.005-.011-.005-.021 0zM345.7 290.837a.26.26 0 01-.114-.162.248.248 0 01.032-.196.258.258 0 01.158-.118.225.225 0 01.19.034.26.26 0 01.114.162.245.245 0 01-.033.196.256.256 0 01-.157.118c-.016.005-.038.005-.054.005a.194.194 0 01-.136-.039zm.109-.33a.102.102 0 00-.071.05c-.016.028-.022.056-.016.084a.107.107 0 00.049.073c.027.017.054.022.081.017a.112.112 0 00.071-.051.114.114 0 00-.033-.157.108.108 0 00-.06-.016h-.021zM334.405 291.431a.26.26 0 01-.081-.358.256.256 0 01.157-.118.228.228 0 01.19.034.26.26 0 01.114.162.245.245 0 01-.032.196.255.255 0 01-.218.123.223.223 0 01-.13-.039zm.109-.33a.105.105 0 00-.071.05c-.016.028-.022.056-.016.084a.107.107 0 00.049.073c.049.034.119.017.152-.034a.122.122 0 00.016-.084.106.106 0 00-.049-.072.1.1 0 00-.06-.017c-.005-.006-.01-.006-.021 0z"
			/>
			<path
				fill="#FA9B9B"
				d="M339.83 283.075s4.009-2.599 4.166.577l.06.823.271 4.116.006.045a.462.462 0 01-.109.313l-2.322 1.635a.189.189 0 01-.228-.011l-1.719-1.512a.466.466 0 01-.158-.414l.065-.605.196-1.859a3.257 3.257 0 01-.082-.728 47.167 47.167 0 00-.146-2.38z"
			/>
			<path
				fill="#3F4249"
				d="M339.472 283.266c.749 0 1.356-.627 1.356-1.4 0-.773-.607-1.4-1.356-1.4-.749 0-1.356.627-1.356 1.4 0 .773.607 1.4 1.356 1.4z"
			/>
			<path fill="#FA9B9B" d="M339.374 280.829s-1.427 6.171 1.644 6.586c2.653.364 2.951-3.909 2.951-3.909l-.13-2.772-4.465.095z" />
			<path
				fill="#3F4249"
				d="M339.553 281.216l2.713.202a.637.637 0 01.569.677.627.627 0 00.57.678c.087.011.157.09.157.185l-.038.621a.133.133 0 00.131.152c.07.011.13-.04.146-.112l.119-.588.12-.191.64.403s1.031-1.646.542-3.147c-.287-.851-1.101-1.159-1.492-1.209a.432.432 0 01-.303-.202c-.158-.274-.543-.75-1.395-.722-.656.022-1.019.397-1.209.688a.41.41 0 01-.521.152c-.277-.135-.678-.202-1.15.089-.895.588-.543 2.173.401 2.324z"
			/>
			<path fill="#000" d="M344.056 284.677s-.255.022-.391-.09l.435.689-.044-.599z" opacity="0.15" />
			<path
				fill="#FA9B9B"
				d="M343.91 283.031s.195-.577.786-.314c.667.302.532 1.865-.602 1.96-.591.05-.472-.302-.374-.784l.19-.862zM339.532 281.351l.314.028c.027 0 .071-.022.071-.061 0-.04-.022-.073-.06-.073l-.315-.028c-.038 0-.076-.012-.119-.023-.076-.028-.228-.084-.363-.185-.028-.022-.071-.011-.087.023-.017.034-.011.072.021.089.147.101.315.174.391.191.049.017.098.028.147.039z"
			/>
			<path
				fill="#000"
				d="M339.873 287.92l.103-.986c.255.275.971.656 1.807.454 0 0 .011-.011 0 0 .51-.134.857-.426 1.074-.689.022-.028.06-.028.087-.011.027.022.027.062.011.09a2.225 2.225 0 01-1.416.784c-.857.588-1.666.358-1.666.358z"
				opacity="0.15"
			/>
			<path
				fill="#21252B"
				d="M338.577 283.428v.285c0 .549.434.997.965.997.532 0 .966-.448.966-.997v-.285a.363.363 0 00-.071-.224h.375a.357.357 0 00-.076.224v.285c0 .549.434.997.966.997.531 0 .965-.448.965-.997v-.285a.357.357 0 00-.352-.364h-3.375a.36.36 0 00-.363.364zm2.501-.213h1.22a.22.22 0 01.217.224v.285c0 .465-.374.84-.824.84-.451 0-.814-.386-.814-.851v-.285a.202.202 0 01.201-.213zm-2.371.224c0-.123.098-.224.217-.224h1.221c.119 0 .217.101.217.224v.285c0 .465-.375.84-.825.84-.45 0-.814-.386-.814-.851l-.016-.274z"
			/>
			<path fill="#FA9B9B" d="M350.007 296.723h-4.486l1.448 6.764h3.027l.011-6.764z" />
			<path
				fill="#FA9B9B"
				d="M347.799 300.194l-.971-1.372-.721-2.996a1.201 1.201 0 00-.174-.398c-.108-.156-.233-.245-.445-.357l-2.398-1.193a.31.31 0 00-.412.14.333.333 0 00.119.436l1.997 1.244-1.801-.594a.31.31 0 00-.402.314.332.332 0 00.179.28l2.105.923-1.606-.7a.284.284 0 00-.396.275c0 .107.06.201.136.252l1.725 1.08-1.139-.705c-.141-.095-.336-.011-.374.157a.267.267 0 00.065.246l.987 1.014 1.665 5.236h1.254c.336-1.411.607-3.282.607-3.282z"
			/>
			<path fill="#FA9B9B" d="M349.611 302.922l-1.579-2.145-2.598 1.148.504 1.562h3.961a1.974 1.974 0 00-.288-.565z" />
			<path
				fill="#000"
				d="M346.839 298.766l-.738-2.985a1.002 1.002 0 00-.515-.655l-.271-.14-.006-.252.629.308c.272.134.472.386.538.689l.656 2.934 2.864 3.758-.005 1.064-3.152-4.721z"
				opacity="0.15"
			/>
			<path fill="#21252B" d="M342.993 293.867h-10.248l1.877 9.62h10.248l-1.877-9.62z" />
			<path fill="#FA9B9B" d="M330.054 295.148l-2.485 4.228 4.091.74 1.833-3.932-3.439-1.036z" />
			<path
				fill="#FA9B9B"
				d="M331.253 297.11l.646.38 1.415-1.142c.321-.118.483-.118.809-.022l2.544.812a.332.332 0 01.212.397.319.319 0 01-.375.235l-2.284-.487 1.709.835c.19.089.239.358.087.515a.305.305 0 01-.315.078l-2.153-.806 1.638.616a.3.3 0 01.109.481.287.287 0 01-.266.09l-2.116-.448 1.432.314c.163.028.255.229.168.38a.246.246 0 01-.211.135l-1.394.05-1.574 1.921c-.743.902-2.023 1.148-2.994.515-.033-.022-.071-.045-.103-.073l-.055-.039c-.602-1.321 3.071-4.737 3.071-4.737z"
			/>
			<path
				fill="#FA9B9B"
				d="M331.253 297.11l-2.316 1.204c-.95.173-1.623 1.052-1.557 2.044.086 1.344 1.443 2.195 2.636 1.652l1.443-1.468-.206-3.432z"
			/>
			<path fill="#000" d="M331.899 297.49l1.388-1.036-1.361.656-3.516.823-.586 1.041 4.075-1.484z" opacity="0.15" />
			<path
				fill="#21252B"
				d="M333.439 296.308l-.206.073-.19-.98 1.026.263-.63.644zM343.378 295.854l.353-.818-.429-.257-.092.196.168.879zM344.029 294.337l.933-2.223a.27.27 0 00-.13-.353.261.261 0 00-.353.146l-.879 2.228.429.202z"
			/>
			<path
				fill="#000"
				d="M344.783 296.684c-.011 0-.022 0-.038-.006l-1.373-.604c-.048-.023-.07-.073-.048-.124.021-.05.076-.072.119-.05l1.372.605c.049.022.071.073.049.123-.011.039-.049.056-.081.056zM344.788 295.709l-1.177-.392-.076.174 1.253.218zM344.626 297.562c-.017 0-.033-.006-.049-.011l-1.026-.639a.1.1 0 01-.032-.128.094.094 0 01.125-.034l1.025.638c.043.028.054.084.033.129a.088.088 0 01-.076.045zM332.843 298.705a.068.068 0 01.086-.056l1.134.235.282.062a.241.241 0 01.19.19l-1.638-.341a.08.08 0 01-.054-.09zM333.504 298.033a.078.078 0 01-.043-.095.074.074 0 01.092-.045l.749.286-.754-.28.873.324.808.308a.308.308 0 01.141.208c-.005 0-.01 0-.016-.006l-1.85-.7zM336.727 291.587l-1.427 2.28h.781l.646-2.28zM345.315 294.987l-.059-2.761-.69 2.391.749.37z"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M339.862 288.043l-.065.604a.467.467 0 00.158.415l1.719 1.512c.06.05.158.061.228.011l2.322-1.635a.466.466 0 00.109-.314l-.006-.045.635.079-.076.28a.393.393 0 01-.146.213l-2.735 1.982a.372.372 0 01-.466-.028l-2.138-1.966a.392.392 0 01-.124-.319l.065-.845.52.056zM350.007 296.723h-3.309l-.125-.56h3.434v.56zM330.054 295.148l.32-.565 2.669.817.136.689-3.125-.941z"
				opacity="0.6"
			/>
			<path fill="#fff" d="M315.526 282.941l.629-3.724 10.693.017-.629 3.701h-10.693v.006z" />
			<path
				fill="#61656D"
				d="M324.092 280.633h-3.027c-.109 0-.195-.073-.195-.162 0-.09.086-.162.195-.162h3.027c.108 0 .195.072.195.162-.005.089-.087.162-.195.162z"
			/>
			<path
				fill="#C62221"
				d="M318.515 282.196c.608 0 1.101-.509 1.101-1.137 0-.627-.493-1.136-1.101-1.136s-1.101.509-1.101 1.136c0 .628.493 1.137 1.101 1.137z"
			/>
			<path
				fill="#fff"
				d="M318.515 282.196c.608 0 1.101-.509 1.101-1.137 0-.627-.493-1.136-1.101-1.136s-1.101.509-1.101 1.136c0 .628.493 1.137 1.101 1.137z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M318.309 281.535l-.369-.38c-.016-.017-.016-.051 0-.073l.098-.101c.016-.017.049-.017.07 0l.239.246.575-.593c.016-.017.049-.017.07 0l.098.101c.016.016.016.05 0 .072l-.711.734c-.016.017-.048.017-.07-.006zM314.734 288.798l.629-3.724 10.693.017-.629 3.718-10.693-.011z"
			/>
			<path
				fill="#61656D"
				d="M323.3 286.497h-3.027c-.109 0-.196-.073-.196-.162 0-.09.087-.163.196-.163h3.027c.108 0 .195.073.195.163-.005.089-.087.162-.195.162z"
			/>
			<path
				fill="#C62221"
				d="M317.723 288.053c.608 0 1.101-.509 1.101-1.137 0-.627-.493-1.136-1.101-1.136s-1.101.509-1.101 1.136c0 .628.493 1.137 1.101 1.137z"
			/>
			<path
				fill="#fff"
				d="M317.723 288.053c.608 0 1.101-.509 1.101-1.137 0-.627-.493-1.136-1.101-1.136s-1.101.509-1.101 1.136c0 .628.493 1.137 1.101 1.137z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M317.517 287.392l-.369-.38c-.016-.017-.016-.051 0-.073l.098-.101c.016-.017.048-.017.07 0l.239.246.575-.593c.016-.017.049-.017.07 0l.098.101c.016.016.016.05 0 .072l-.711.734c-.016.017-.048.017-.07-.006zM313.953 294.662l.629-3.724 10.693.017-.63 3.718-10.692-.011z"
			/>
			<path
				fill="#61656D"
				d="M322.519 292.354h-3.027c-.109 0-.196-.073-.196-.162 0-.09.087-.163.196-.163h3.027c.108 0 .195.073.195.163-.005.089-.087.162-.195.162z"
			/>
			<path
				fill="#C62221"
				d="M316.942 293.916c.608 0 1.101-.508 1.101-1.136 0-.628-.493-1.137-1.101-1.137s-1.101.509-1.101 1.137.493 1.136 1.101 1.136z"
			/>
			<path
				fill="#fff"
				d="M316.942 293.916c.608 0 1.101-.508 1.101-1.136 0-.628-.493-1.137-1.101-1.137s-1.101.509-1.101 1.137.493 1.136 1.101 1.136z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M316.736 293.256l-.369-.381c-.017-.016-.017-.05 0-.072l.097-.101c.017-.017.049-.017.071 0l.239.246.575-.593c.016-.017.049-.017.07 0l.098.1c.016.017.016.051 0 .073l-.711.734c-.016.017-.049.017-.07-.006zM313.161 300.52l.629-3.724 10.693.017-.63 3.718-10.692-.011z"
			/>
			<path
				fill="#61656D"
				d="M321.727 298.219H318.7c-.109 0-.196-.073-.196-.162 0-.09.087-.163.196-.163h3.027c.108 0 .195.073.195.163 0 .089-.087.162-.195.162z"
			/>
			<path
				fill="#C62221"
				d="M316.15 299.775c.608 0 1.101-.509 1.101-1.137s-.493-1.137-1.101-1.137-1.102.509-1.102 1.137.494 1.137 1.102 1.137z"
			/>
			<path
				fill="#fff"
				d="M316.15 299.775c.608 0 1.101-.509 1.101-1.137s-.493-1.137-1.101-1.137-1.102.509-1.102 1.137.494 1.137 1.102 1.137z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M315.944 299.114l-.369-.38c-.017-.017-.017-.051 0-.073l.097-.101c.017-.017.049-.017.071 0l.239.246.575-.593c.016-.017.048-.017.07 0l.098.101c.016.016.016.05 0 .072l-.711.734c-.016.017-.049.017-.07-.006z"
			/>
			<path
				fill="#fff"
				d="M298.86 315.157h13.91c.168 0 .304-.117.342-.291l1.584-8.568a.35.35 0 00-.342-.42H300.45c-.168 0-.304.118-.342.291l-1.584 8.568c-.033.224.125.42.336.42zM319.101 315.157h13.91c.168 0 .304-.117.341-.291l1.585-8.568a.35.35 0 00-.342-.42h-13.904c-.169 0-.304.118-.342.291l-1.584 8.568c-.038.224.119.42.336.42zM339.336 315.157h13.91c.168 0 .304-.117.342-.291l1.584-8.568a.35.35 0 00-.342-.42h-13.904c-.168 0-.304.118-.342.291L339 314.737c-.033.224.125.42.336.42z"
				opacity="0.7"
			/>
			<path fill="#000" d="M215.869 388.799h-9.022l-.51 3.819h9.532v-3.819z" opacity="0.5" />
			<path fill="#000" d="M220.507 353.283l13.383 15.92s.793 2.582.798 5.41l-14.181-21.33z" opacity="0.15" />
			<path
				fill="#C62221"
				d="M207.395 295.037c.076-.638.635-1.092 1.253-1.014.618.079 1.058.656.982 1.294-.076.638-.635 1.092-1.253 1.014-.613-.079-1.053-.661-.982-1.294z"
			/>
			<path
				fill="#000"
				d="M205.176 288.732c.64-.42 1.193-.521 1.72-.403.526.123.965.509 1.323 1.165.353.655.586 1.467.543 2.296l-.016.61c0 .448-.771.577-.76.017l-.027-.672c.027-.219-.065-1.294-.429-1.893-.265-.476-.564-.739-.873-.834-.309-.096-.705.028-1.172.302a.4.4 0 01-.526-.123.428.428 0 01.217-.465zM208.892 296.219c-.7-.028-1.226-.6-1.199-1.322.027-.218.109-.487.239-.644-.293.19-.499.549-.543.935-.027.667.461 1.171 1.107 1.199a.967.967 0 00.673-.196c-.109-.006-.217-.023-.277.028z"
				opacity="0.15"
			/>
			<path
				fill="#F59696"
				d="M199.203 219.404l-2.599 22.025c-.222 1.775-1.546 3.192-3.26 3.517l-2.501.481 5.268 4.055c5.251 4.116 12.049 5.376 18.271 3.572a19.557 19.557 0 003.337-1.299l6.401-3.153-2.658-1.058c-1.508-.582-2.507-2.094-2.545-3.707l-.412-26.516-19.302 2.083z"
			/>
			<path
				fill="#F59696"
				d="M219.731 239.592l-.976.302c-7.069 2.055-14.366-2.161-16.34-9.396l-5.062-18.447c-.683-2.43.705-4.923 3.054-5.628l16.709-4.939c3.049-.879 6.228.935 7.08 4.082l4.698 17.147c1.921 7.246-2.148 14.835-9.163 16.879z"
			/>
			<path fill="#F59696" d="M227.587 218.15s2.121 1.965 3.081 3.404c.955 1.44-1.801 2.173-1.801 2.173l-1.28-5.577z" />
			<path
				fill="#000"
				d="M196.138 229.484h2.029l-2.148 4.435.119-4.435zM214.057 240.37s2.837.246 4.817-.543l.044 3.791-4.861-3.248zM229.285 227.932c-2.903 0-4.471-1.73-4.546-1.809a.314.314 0 01-.006-.431.292.292 0 01.418-.005c.016.016 1.535 1.635 4.188 1.635l-.054.61z"
				opacity="0.15"
			/>
			<path fill="#ACB0B5" d="M175.002 331.247h-3.884V487.75h3.884V331.247z" />
			<path fill="#61656D" d="M216.048 331.247h-41.051V487.75h41.051V331.247z" />
			<path fill="#80848B" d="M216.048 331.247h-41.051V487.75h41.051V331.247z" />
			<path fill="#000" d="M216.048 331.247h-44.93v1.327h44.93v-1.327z" opacity="0.5" />
			<path
				fill="#C62221"
				d="M223.22 283.569l13.139 27.109 19.329-5.437-13.79-42.196c-1.134-3.461-2.941-6.67-5.42-9.279-2.918-3.064-6.293-4.956-11.078-5.029l-31.063-.398h-10.498c-8.924 0-16.161 7.471-16.161 16.683v77.162h62.627l-.093-24.954a3.824 3.824 0 011.004-2.615c2.875-3.097 7.177-6.692 4.562-16.234"
			/>
			<path
				fill="#000"
				d="M210.682 218.514s-4.204 14.156 2.832 22.17c4.047 4.609 8.311 1.45 14.084 8.316 0 0-6.19-.001-9.993 5.275-4.487 6.221-15.076 32.978-33.06 33.667-17.979.689-25.818-3.953-26.138-10.998-.884-19.594 17.127-22.411 18.217-35.51.673-8.075-3.797-41.16 17.659-48.944 20.257-7.352 32.067 4.357 31.861 8.596-.207 4.24.499 10.192-15.462 17.428z"
			/>
			<path
				fill="#F59696"
				d="M310.253 486.726l-7.319-5.208v-31.461l-.027-.062c-.146-47.023-.895-75.919-2.891-93.542-1.888-16.688-18.581-2.895-23.257-5.158-2.805-1.361-4.66-6.77-10.264-7.056-9.868-.498-13.112-7.918-13.112-7.918l-4.639 32.474 25.519 1.171c-.699 27.232 10.091 47.482 15.044 76.098-2.718 1.786-4.731 5.141-4.731 10.175 12.017 4.122 10.709 26.802 10.709 26.802l14.968 3.685z"
			/>
			<g opacity="0.2">
				<path
					fill="#000"
					d="M285.314 351.295c2.062.997 6.467-1.131 10.904-2.385-3.347-1.888-8.24-.174-12.423 1.243.494.487.987.89 1.519 1.142zM274.263 369.966c-.699 27.232 10.085 47.482 15.038 76.098-2.717 1.786-4.73 5.141-4.73 10.175 12.016 4.127 10.709 26.802 10.709 26.802l14.967 3.685-7.318-5.208v-19.589h.005c-2.408-3.427-5.636-12.494-6.222-15.865-4.953-28.616-15.776-48.782-15.082-76.009l-24.765-.789 4.796-26.589c-5.544-3.663-9.005-6.362-9.005-6.362l-3.912 32.475"
					opacity="0.2"
				/>
			</g>
			<path
				fill="#C62221"
				d="M309.797 487.151h-12.434c-1.731 0-3.195-1.316-3.445-3.08-.89-6.328-3.483-20.776-9.347-27.826 0 0-1.78-7.331 4.73-10.181 0 0 6.516 4.446 4.416 17.713l3.619 14.347s8.81 2.862 13.031 7.734c.428.498.081 1.293-.57 1.293z"
			/>
			<path fill="#C62221" d="M284.571 456.245l-1.253 30.906h1.253l3.266-30.906h-3.266z" />
			<path
				fill="#F59696"
				d="M279.124 486.726l-7.318-5.208v-31.461l-.027-.062c-.147-47.024-.895-75.919-2.892-93.542-1.888-16.688-18.58-2.896-23.257-5.158-2.799-1.361-4.66-6.77-10.264-7.056-9.868-.498-25.025-7.448-25.025-7.448l-5.794 35.179 38.593.918c-.7 27.233 10.091 44.571 15.044 73.181-2.718 1.786-4.731 5.141-4.731 10.176 12.016 4.121 10.709 26.801 10.709 26.801l14.962 3.68z"
			/>
			<g opacity="0.2">
				<path
					fill="#000"
					d="M254.18 351.295c2.062.997 6.467-1.131 10.904-2.385-3.347-1.888-8.24-.174-12.423 1.243.494.487.993.89 1.519 1.142zM271.806 461.928c-2.409-3.427-5.637-12.493-6.223-15.864-4.953-28.616-14.598-45.954-13.899-73.181l-38.593-.918 4.823-29.288c-5.544-3.663-7.579-5.886-7.579-5.886l-5.794 35.179 38.594.918c-.7 27.233 10.09 44.565 15.043 73.181-2.718 1.786-4.73 5.141-4.73 10.176 12.016 4.127 10.709 26.801 10.709 26.801l14.967 3.685-7.318-5.208v-19.595z"
					opacity="0.2"
				/>
			</g>
			<path
				fill="#C62221"
				d="M278.668 487.151h-12.434c-1.73 0-3.195-1.316-3.445-3.08-.889-6.328-3.482-20.776-9.347-27.826 0 0-1.779-7.331 4.731-10.181 0 0 6.515 4.446 4.416 17.713l3.618 14.347s8.81 2.862 13.031 7.734c.429.498.076 1.293-.57 1.293z"
			/>
			<path fill="#C62221" d="M253.442 456.245l-1.248 30.906h1.248l3.266-30.906h-3.266z" />
			<path
				fill="#3F4249"
				d="M257.017 440.083c-5.75-25.687-15.26-44.598-14.164-70.974l-52.693-.443c-7.731-.067-13.997-6.501-14.095-14.481l-.271-21.812s44.583-.499 54.451 0c5.604.285 12.586 15.148 15.385 16.503 4.671 2.268 21.364-11.53 23.257 5.158 1.883 16.66 2.653 43.411 2.859 86.044l-14.729.005z"
			/>
			<g opacity="0.2">
				<path
					fill="#000"
					d="M252.27 351.295c2.257 1.098 7.308-1.551 12.142-2.71-3.711-1.507-8.963.677-13.151 2.033.331.28.662.509 1.009.677zM263.652 440.066c-5.192-26.532-14.55-41.104-13.883-67.188l-38.593-.919 5.371-32.603c-3.673-1.411-6.217-2.57-6.217-2.57l-5.794 35.179 38.593.918c-.672 26.085 8.697 40.667 13.883 67.2l6.64-.017z"
					opacity="0.2"
				/>
			</g>
			<path
				fill="#3F4249"
				d="M301.915 381.731l-.559-33.281c-.152-9.05-7.297-16.313-16.069-16.319l-90.983-.089.521 22.82c.179 7.901 6.391 14.235 14.045 14.325l65.388.789c-.564 25.867 7.66 41.177 13.736 69.239h14.881c-.114-23.761-.402-42.588-.96-57.484z"
			/>
			<path fill="#C62221" d="M230.256 332.07s-8.989 1.535-22.308 6.978c-14.875 6.082-30.971.162-30.971.162v-16.195l53.279 9.055z" />
			<path
				fill="#000"
				d="M296.023 439.293c-5.159-26.398-14.951-43.372-14.393-69.238l-82.28-.952c-10.232-.196-18.429-8.809-18.429-19.371v-17.601l-4.861-.005-.629 16.845c0 10.561 4.182 20.137 16.541 20.137l82.28.952c-.564 25.867 8.572 42.756 13.737 69.155l14.88.089h-6.846v-.011z"
				opacity="0.2"
			/>
			<path fill="#000" d="M166.658 265.178s8.702 11.822 32.458 10.467l-6.83-14.801-25.628 4.334z" />
			<path
				fill="#ACB0B5"
				d="M121.984 487.151l18.998-102.592c.369-2.576-.808-4.217 1.714-4.217h41.702c2.523 0 4.97 2.173 5.338 4.749l14.577 102.654h5.36l-13.752-103.208c-.629-4.379-6.19-7.947-10.481-7.947h-43.774c-4.292 0-8.295 3.568-8.93 7.947l-19.535 102.614h8.783z"
			/>
			<path
				fill="#000"
				d="M135.796 378.942h9.64v1.389h-2.745c-2.436 0-1.422 1.534-1.682 3.959h-8.235c.369-2.078 1.481-3.959 3.022-5.348z"
				opacity="0.5"
			/>
			<path
				fill="#ACB0B5"
				d="M121.984 487.151h-3.266l19.438-103.729c.672-3.589 4.106-6.512 7.643-6.512h44.805c3.575 0 6.906 2.968 7.427 6.624l14.87 104.216h-3.239l-14.615-103.768c-.303-2.144-2.338-3.959-4.437-3.959h-44.805c-2.132 0-4.275 1.826-4.682 3.987l-19.139 103.141z"
			/>
			<path
				fill="#000"
				d="M121.984 487.151h-3.266l19.438-103.729c.672-3.589 4.106-6.512 7.643-6.512h44.805c3.575 0 6.906 2.968 7.427 6.624l14.87 104.216h-3.239l-14.615-103.768c-.303-2.144-2.338-3.959-4.437-3.959h-44.805c-2.132 0-4.275 1.826-4.682 3.987l-19.139 103.141z"
				opacity="0.5"
			/>
			<path
				fill="#fff"
				d="M127.533 379.883c-8.246-.565-1.839-19.678-3.076-27.328l-.244-1.54 2.979-.515.244 1.54c1.833 11.357-3.646 24.73.097 24.73 1.753 0 4.297-.084 7.823-.202 9.244-.302 24.722-.818 52.579-.818h1.508v3.114h-1.508c-27.814 0-43.253.51-52.481.818-3.559.117-6.12.201-7.921.201z"
			/>
			<path
				fill="#80848B"
				d="M191.212 378.863H130.43c-4.822 0-4.752-4.177-3.466-11.547 1.383-7.941.7-13.378.39-15.299l2.979-.515c1.79 11.116-3.7 24.242.097 24.242h60.787v3.119h-.005z"
			/>
			<path
				fill="#80848B"
				d="M265.128 382.246s-6.733 4.548-16.064 4.548h-80.301c-17.875 0-37.042-1.339-39.895-8.826-2.854-7.487 122.724-4.777 122.724-4.777s9.901 1.831 10.937 2.638c1.042.801 2.599 6.417 2.599 6.417z"
			/>
			<path
				fill="#ACB0B5"
				d="M128.879 350.153l-10.66-80.343c-.201-1.4-6.901-1.977-6.635-3.22.96-4.458 5.078-11.531 15.868-11.531h54.397c6.271 0 12.152 5.242 13.068 11.637 0 0 11.588 79.044 11.154 80.786-.428 1.736-1.985 5.348-4.834 5.348-2.853 0-71.886.532-71.886.532l-.472-3.209zM196.74 379.564h-64.069c-3.006 0-5.441-2.514-5.441-5.611 0-3.103 2.435-5.617 5.441-5.617h64.069c3.006 0 5.441 2.514 5.441 5.617 0 3.102-2.435 5.611-5.441 5.611z"
			/>
			<path
				fill="#AFB3B8"
				d="M269.278 388.047c-2.268 0-4.167-1.724-4.487-4.07-.092-.443-1.361-5.349-11.832-5.349h-55.497c-2.414 0-4.525-1.881-4.677-4.367-.173-2.722 1.921-4.99 4.525-4.99h55.655c16.714 0 20.756 10.343 20.859 13.961.07 2.531-1.823 4.659-4.27 4.805-.108.011-.195.01-.276.01zm-4.536-4.692c0 .235.017.443.044.611a3.59 3.59 0 01-.027-.252 4.041 4.041 0 01-.017-.359z"
			/>
			<path
				fill="#80848B"
				d="M269.278 388.047c-2.268 0-4.167-1.724-4.487-4.07-.092-.443-1.361-5.349-11.832-5.349H201.65c-2.414 0-4.525-1.881-4.677-4.367-.173-2.722 1.921-4.99 4.525-4.99h51.467c16.714 0 20.756 10.343 20.859 13.961.07 2.531-1.823 4.659-4.27 4.805-.108.011-.195.01-.276.01zm-4.536-4.692c0 .235.017.443.044.611a3.59 3.59 0 01-.027-.252 4.041 4.041 0 01-.017-.359z"
				opacity="0.15"
			/>
			<path
				fill="#ACB0B5"
				d="M260.419 378.864h-60.315c-1.997 0-3.472-1.742-3.445-3.802.081-6.726 2.419-10.248.298-23.386l-.852-.661 3.527-.515.244 1.54c2.11 13.065-3.109 23.71.211 23.71h60.332v3.114z"
			/>
			<path
				fill="#80848B"
				d="M262.193 379.373h-59.936c-7.448 0-.558-13.507-3.146-27.294l3.049-.577c1.833 11.373-2.637 24.752 1.101 24.752h58.932v3.119z"
			/>
			<path
				fill="#ACB0B5"
				d="M275.896 487.151l-14.544-106.742c-.299-2.145-2.317-3.954-4.416-3.954h-44.805c-2.094 0-4.118 1.815-4.416 3.954l-14.512 106.742h-3.239l14.762-107.179c.504-3.657 3.824-6.636 7.405-6.636h44.805c3.575 0 6.9 2.974 7.405 6.636l14.761 107.179h-3.206z"
			/>
			<path
				fill="#ACB0B5"
				d="M193.122 487.745l14.181-106.759c.369-2.576-.809-5.163 1.714-5.163l41.702.946c2.522 0 4.969 2.173 5.338 4.749l14.507 105.628h5.327l-13.66-106.183c-.63-4.379-6.342-7.778-10.639-7.778l-43.611-.162c-4.291 0-8.295 3.567-8.93 7.94l-14.685 106.776 8.756.006z"
			/>
			<path
				fill="#000"
				d="M275.896 487.151l-14.544-106.742c-.299-2.145-2.317-3.954-4.416-3.954h-44.805c-2.094 0-4.118 1.815-4.416 3.954l-14.593 107.336h-3.25l14.848-107.773c.505-3.657 3.825-6.636 7.405-6.636h44.805c3.576 0 6.901 2.974 7.406 6.636l14.761 107.179h-3.201z"
				opacity="0.5"
			/>
			<path
				fill="#80848B"
				d="M125.385 350.152l-10.665-80.343c-.201-1.4-4.829-1.977-4.563-3.22.96-4.458 9.385-8.422 14.284-8.422h54.397c6.271 0 12.152 5.236 13.068 11.642l10.492 82.807-3.108.756h-73.433l-.472-3.22z"
			/>
			<path
				fill="#80848B"
				d="M124.447 258.167h54.396c4.21 0 8.235 2.357 10.709 5.846-.206-.056-.429-.196-.689-.498-1.552-1.781-2.935 1.96-2.935 3.388 0 1.125-48.939 5.93-70.503 7.8l-.7-4.894c-.201-1.4-4.828-1.977-4.562-3.22.954-4.458 9.385-8.422 14.284-8.422z"
			/>
			<path
				fill="#ACB0B5"
				d="M119.51 353.367l-10.666-80.354c-.917-6.401 3.456-11.637 9.727-11.637h56.99c6.271 0 12.152 5.236 13.069 11.637l10.665 80.349s-16.926 4.457-43.866 4.457c-26.935 0-35.919-4.452-35.919-4.452z"
			/>
			<path
				fill="#000"
				d="M199.442 379.373h-69.793l1.069 1.159h68.415l.309-1.159zM189.97 386.787h-18.852v3.898h19.405l-.553-3.898zM216.048 386.787h-9.207l-.52 3.898h9.727v-3.898z"
				opacity="0.5"
			/>
			<path fill="#F59696" d="M211.908 226.494s-5.701.24-6.699-5.578c-.993-5.869 6.016-6.67 6.792-.773l.379 6.407-.472-.056z" />
			<path fill="#FC9D9D" d="M277.974 264.663l.673-8.299a.74.74 0 01.548-.661c.846-.213 1.649.476 1.605 1.367l-.168 3.32-2.658 4.273z" />
			<path
				fill="#000"
				d="M277.974 264.663l.673-8.299a.74.74 0 01.548-.661c.846-.213 1.649.476 1.605 1.367l-.168 3.32-2.658 4.273z"
				opacity="0.15"
			/>
			<path
				fill="#FC9D9D"
				d="M283.556 256.706l3.597-6.967c.266-.515.89-.711 1.389-.437.472.258.673.846.461 1.35l-3.217 7.728 3.673-6.227a.893.893 0 011.21-.336.957.957 0 01.374 1.265l-3.532 6.9 3.19-4.111c.157-.213.521-.381.836-.162.298.196.255.593.13.845l-3.054 6.183-.445 3.069c-.423 2.9-1.894 5.532-4.118 7.358l-.119.101-5.474 8.249-5.132-8.473 2.951-2.845 3.83-13.104 4.047-8.366a.908.908 0 011.188-.448c.44.196.662.7.516 1.17l-2.301 7.258z"
			/>
			<path
				fill="#C62221"
				d="M227.598 288.833l10.605 26.449c1.915 3.371 5.284 5.112 8.664 5.112 2.832 0 5.68-1.226 7.709-3.74l26.848-39.581-6.949-5.516-22.422 23.033-7.958-24.629-16.497 18.872z"
			/>
			<path fill="#000" d="M229.675 294.012l5.865 14.975s-.738 1.719-2.219 3.371l-3.646-18.346z" opacity="0.2" />
			<path
				fill="#000"
				d="M300.32 370.251a.187.187 0 01-.174-.129.194.194 0 01.114-.241c2.197-.767 4.085-2.716 4.101-2.738a.183.183 0 01.261 0c.07.072.07.196 0 .268-.082.084-1.97 2.033-4.248 2.834-.011.006-.033.006-.054.006zM300.938 373.459a.181.181 0 01-.179-.151.195.195 0 01.141-.229c.825-.191 2.024-.891 2.034-.902.087-.056.201-.022.255.073a.19.19 0 01-.07.257c-.049.028-1.259.74-2.138.941-.016.011-.032.011-.043.011z"
				opacity="0.15"
			/>
			<path fill="#fff" d="M211.719 219.342l-.174-.834 15.374-5.079.174.84-15.374 5.073z" />
			<path
				fill="#fff"
				d="M227.918 218.329a.418.418 0 01-.331-.308l-2.078-7.56a.423.423 0 01.287-.527.42.42 0 01.516.297l2.078 7.56a.432.432 0 01-.288.532.522.522 0 01-.184.006z"
			/>
			<path fill="#000" d="M211.404 218.166s.461 1.081.678 3.489a.71.71 0 00.759.66.72.72 0 00.641-.772l-.364-5.152-1.714 1.775z" />
			<path
				fill="#000"
				d="M206.505 217.835c.445-.487.917-.733 1.4-.733.483 0 .944.241 1.383.711.44.476.76 1.109.928 1.826l.184.61a.334.334 0 01-.238.403.323.323 0 01-.391-.246l-.185-.611c-.021-.168-.33-1.064-.764-1.517-.315-.331-.619-.504-.912-.504-.298 0-.613.179-.927.521a.313.313 0 01-.456.011.338.338 0 01-.022-.471z"
				opacity="0.3"
			/>
			<path
				fill="#C62221"
				d="M208.756 224.158c.076-.638.635-1.092 1.254-1.013.618.078 1.058.655.982 1.293-.076.639-.635 1.092-1.254 1.014-.618-.078-1.057-.661-.982-1.294z"
			/>
			<path
				fill="#000"
				d="M210.254 225.34c-.7-.028-1.226-.599-1.199-1.322.027-.218.108-.487.239-.644-.293.191-.5.549-.543.936-.027.666.461 1.17 1.107 1.198a.96.96 0 00.672-.196c-.108-.006-.217-.022-.276.028zM209.489 226.101s1.036.269 1.389.325c.363.056 1.502.123 1.502.123s-1.454 2.027-2.897 2.447c.006 0-.076-2.089.006-2.895z"
				opacity="0.15"
			/>
			<path
				fill="#B0B4B9"
				d="M671.292 488.54H36.642c-.189 0-.32-.134-.32-.33s.131-.331.32-.331h634.715c.19 0 .32.135.32.331s-.19.33-.385.33zM523.368 42.444c0-17.158-12.558-31.304-28.752-33.14v-.22h-3.57c-17.837 0-32.322 14.953-32.322 33.366 0 18.412 14.485 33.364 32.322 33.364h3.57v-.218c16.199-1.848 28.752-15.988 28.752-33.152z"
			/>
			<path
				fill="#84888F"
				d="M517.466 66.04c12.624-13.03 12.624-34.155 0-47.185-12.624-13.03-33.087-13.03-45.711 0-12.621 13.03-12.621 34.155 0 47.185 12.624 13.03 33.087 13.03 45.711 0z"
			/>
			<path
				fill="#fff"
				d="M494.616 72.124c15.879 0 28.752-13.288 28.752-29.68s-12.873-29.68-28.752-29.68-28.752 13.288-28.752 29.68 12.873 29.68 28.752 29.68z"
			/>
			<path
				fill="#84888F"
				d="M494.616 72.124c15.879 0 28.752-13.288 28.752-29.68s-12.873-29.68-28.752-29.68-28.752 13.288-28.752 29.68 12.873 29.68 28.752 29.68z"
			/>
			<path
				fill="#fff"
				d="M494.616 72.124c15.879 0 28.752-13.288 28.752-29.68s-12.873-29.68-28.752-29.68-28.752 13.288-28.752 29.68 12.873 29.68 28.752 29.68z"
				opacity="0.9"
			/>
			<path
				fill="#B0B4B9"
				d="M494.616 12.764c-.499 0-.998 0-1.568.073 15.125.812 27.185 13.77 27.185 29.607s-12.06 28.795-27.185 29.607c.499 0 .998.073 1.568.073 15.912 0 28.752-13.255 28.752-29.68 0-16.425-12.84-29.68-28.752-29.68z"
			/>
			<path
				fill="#C62221"
				d="M494.904 44.73l-9.559-19.59c-.358-.739-.071-1.69.64-2.06a1.488 1.488 0 011.996.66l8.203 16.868 10.915-5.522a1.454 1.454 0 011.996.74c.358.739.071 1.69-.716 2.06l-13.475 6.843z"
			/>
			<path
				fill="#fff"
				d="M519.235 57.693c2.642-4.49 4.139-9.721 4.139-15.321 0-16.425-12.841-29.68-28.753-29.68-.786 0-1.567.073-2.354.073l26.968 44.928zM487.911 13.577c-1.427.37-2.783.812-4.069 1.327l29.751 49.79c1.139-1.03 2.213-2.212 3.211-3.46l-28.893-47.657z"
				opacity="0.5"
			/>
			<path fill="#C2AE88" d="M481.623 420.685l-10.91-.269 2.979 67.463h15.863l2.978-67.463-10.91.269z" />
			<path fill="#83878E" d="M470.713 420.416l2.979 67.463h15.863l2.978-67.463h-21.82z" />
			<path fill="#C62221" d="M486.853 392.461s-.738-8.014 5.143-12.947c5.88-4.934 14.978-3.226 14.978-3.226s-3.027 16.694-20.121 16.173z" />
			<path
				fill="#000"
				d="M486.853 392.461s-.738-8.014 5.143-12.947c5.88-4.934 14.978-3.226 14.978-3.226s-3.027 16.694-20.121 16.173z"
				opacity="0.3"
			/>
			<path
				fill="#C62221"
				d="M486.853 392.461s6.521-12.471 20.121-16.173c0 0-19.79-3.657-20.121 16.173zM487.01 380.062s-3.157-1.031-4.416-6.552c-1.258-5.522 1.449-11.598 1.449-11.598s4.709 3.909 5.088 10.119c.38 6.216-2.121 8.031-2.121 8.031zM475.314 403.442s-3.521.694-7.215-3.696c-3.695-4.391-3.782-11.329-3.782-11.329s6.31 1.131 9.462 6.608c3.151 5.477 1.535 8.417 1.535 8.417z"
			/>
			<path
				fill="#21252B"
				d="M479.54 420.461l-1.036-58.694 1.036-4.082.944 62.776h-.944zM477.131 420.461l-2.224-26.925-.895.627 2.251 26.628.868-.33zM486.05 420.415l.96-45.628.966.649-.852 44.979h-1.074z"
			/>
			<path
				fill="#C62221"
				d="M486.05 405.8s-4.682-3.046-6.787-9.61c-2.099-6.557-1.909-10.634-1.909-10.634s7.616 3.517 9.672 9.313c2.051 5.79-.976 10.931-.976 10.931zM478.813 364.422s-1.671-3.993-.081-8.641c1.589-4.654 9.331-7.325 9.331-7.325s-3.006 10.886-9.25 15.966z"
			/>
			<path
				fill="#C62221"
				d="M478.813 364.422s-.195-5.248 2.056-9.38c2.257-4.133 7.194-6.592 7.194-6.592s-13.764 3.512-9.25 15.972zM477.354 385.556s7.269 6.39 9.363 20.306c0-.006 6.667-11.676-9.363-20.306z"
			/>
			<path
				fill="#000"
				d="M487.01 380.062s-3.157-1.031-4.416-6.552c-1.258-5.522 1.449-11.598 1.449-11.598s4.709 3.909 5.088 10.119c.38 6.216-2.121 8.031-2.121 8.031zM475.314 403.442s-3.521.694-7.215-3.696c-3.695-4.391-3.782-11.329-3.782-11.329s6.31 1.131 9.462 6.608c3.151 5.477 1.535 8.417 1.535 8.417zM486.05 405.8s-4.682-3.046-6.787-9.61c-2.099-6.557-1.909-10.634-1.909-10.634s7.616 3.517 9.672 9.313c2.051 5.79-.976 10.931-.976 10.931zM478.813 364.422s-1.671-3.993-.081-8.641c1.589-4.654 9.331-7.325 9.331-7.325s-3.006 10.886-9.25 15.966z"
				opacity="0.3"
			/>
			<path fill="#C62221" d="M476.204 416.973s-2.029-6.933 2.3-12.36c4.329-5.426 12.651-5.594 12.651-5.594s.206 15.277-14.951 17.954z" />
			<path
				fill="#000"
				d="M476.204 416.973s-2.029-6.933 2.3-12.36c4.329-5.426 12.651-5.594 12.651-5.594s.206 15.277-14.951 17.954z"
				opacity="0.3"
			/>
			<path
				fill="#C62221"
				d="M476.204 416.972s1.198-9.162 14.951-17.948c0 0-18.299.207-14.951 17.948zM487.889 379.895s-2.924-3.13-3.016-8.058c-.093-4.934 6.249-10.243 6.249-10.243s.89 11.279-3.233 18.301z"
			/>
			<path fill="#C62221" d="M487.889 379.895s-4.025-10.718 3.233-18.306c.006 0-12.597 8.534-3.233 18.306z" />
			<path
				fill="#000"
				d="M487.889 379.895s-1.671-3.002-1.763-7.935c-.092-4.934 4.996-10.371 4.996-10.371s.89 11.284-3.233 18.306z"
				opacity="0.3"
			/>
			<path
				fill="#C62221"
				d="M480.12 384.554s-3.298-.286-5.712-5.388c-2.409-5.101-1.063-11.642-1.063-11.642s5.425 2.738 7.123 8.714c1.708 5.98-.348 8.316-.348 8.316z"
			/>
			<path
				fill="#000"
				d="M480.12 384.554s-3.298-.286-5.712-5.388c-2.409-5.101-1.063-11.642-1.063-11.642s5.425 2.738 7.123 8.714c1.708 5.98-.348 8.316-.348 8.316z"
				opacity="0.3"
			/>
			<path fill="#C62221" d="M474.614 399.634s-3.244-2.777-3.863-7.666c-.623-4.889 5.105-10.898 5.105-10.898s2.089 11.116-1.242 18.564z" />
			<path
				fill="#000"
				d="M474.614 399.634s-3.244-2.777-3.863-7.666c-.623-4.889 5.105-10.898 5.105-10.898s2.089 11.116-1.242 18.564z"
				opacity="0.3"
			/>
			<path fill="#C62221" d="M474.614 399.634s-4.107-8.562 1.242-18.564c0 0-10.774 9.19-1.242 18.564z" />
			<path fill="#61656D" d="M492.517 420.416l-6.418.045-2.36 67.418h5.799l2.979-67.463z" />
			<path fill="#AFB3B8" d="M614.102 243.881h-76.395v243.998h76.395V243.881z" />
			<path fill="#E6EDE9" d="M595.874 357.001h-76.395v130.884h76.395V357.001z" />
			<path fill="#D7DADC" d="M595.874 243.881h-76.395v243.998h76.395V243.881z" />
			<path fill="#000" d="M592.098 414.043H523.26v-50.064h68.843v50.064h-.005zm-68.024-.84h67.216v-48.384h-67.216v48.384z" opacity="0.15" />
			<path
				fill="#AFB3B8"
				d="M564.463 374.631H550.9c-.737 0-1.334-.616-1.334-1.378v-2.223c0-.761.597-1.377 1.334-1.377h13.563c.738 0 1.334.616 1.334 1.377v2.223c0 .762-.596 1.378-1.334 1.378z"
			/>
			<path fill="#000" d="M592.098 470.043H523.26v-50.064h68.843v50.064h-.005zm-68.024-.84h67.216v-48.384h-67.216v48.384z" opacity="0.15" />
			<path
				fill="#AFB3B8"
				d="M564.463 430.631H550.9c-.737 0-1.334-.616-1.334-1.378v-2.223c0-.761.597-1.377 1.334-1.377h13.563c.738 0 1.334.616 1.334 1.377v2.223c0 .762-.596 1.378-1.334 1.378z"
			/>
			<path fill="#000" d="M592.098 358.043H523.26v-50.064h68.843v50.064h-.005zm-68.024-.84h67.216v-48.384h-67.216v48.384z" opacity="0.15" />
			<path
				fill="#AFB3B8"
				d="M564.463 318.631H550.9c-.737 0-1.334-.616-1.334-1.378v-2.223c0-.761.597-1.377 1.334-1.377h13.563c.738 0 1.334.616 1.334 1.377v2.223c0 .762-.596 1.378-1.334 1.378z"
			/>
			<path fill="#000" d="M592.098 302.043H523.26v-50.064h68.843v50.064h-.005zm-68.024-.84h67.216v-48.384h-67.216v48.384z" opacity="0.15" />
			<path
				fill="#AFB3B8"
				d="M564.463 262.631H550.9c-.737 0-1.334-.616-1.334-1.378v-2.223c0-.761.597-1.377 1.334-1.377h13.563c.738 0 1.334.616 1.334 1.377v2.223c0 .762-.596 1.378-1.334 1.378z"
			/>
			<path
				fill="#DADDDF"
				fillRule="evenodd"
				d="M226.719 93.807c-5.002-2.077-5.626-9.542-5.626-9.542h-12.619s-.623 7.47-5.625 9.542c-2.436 1.008-7.91 2.218-13.102 4.435-7.02 2.996-11.598 10.064-11.598 17.904v.072h73.27v-.072c0-7.84-4.579-14.908-11.599-17.904-5.192-2.217-10.666-3.421-13.101-4.435z"
				clipRule="evenodd"
			/>
			<path fill="#868A91" d="M219.748 82.49h-9.928v1.775h9.928V82.49zM218.18 78.911h-6.787v3.579h6.787V78.91z" />
			<path fill="#868A91" fillRule="evenodd" d="M215.386 78.91h-1.205V3.457l1.205.095v75.36z" clipRule="evenodd" />
			<path fill="#D7DADC" d="M557.562 232.614h-26.425v11.268h26.425v-11.268z" />
			<path fill="#AFB3B8" d="M531.132 232.614h63.049v11.268h-36.619v-9.408h-26.43v-1.86z" />
			<path fill="#AFB3B8" d="M531.132 232.614h63.049v11.268h-36.619v-9.408h-26.43v-1.86z" />
			<path fill="#AFB3B8" d="M557.562 242.023h-26.425v1.859h26.425v-1.859z" />
			<path fill="#AFB3B8" d="M557.562 242.023h-26.425v1.859h26.425v-1.859z" />
			<path
				fill="#D7DADC"
				d="M579.148 236.529h-15.455v4.032h15.455v-4.032zM583.006 238.247c0-1.836 1.443-3.326 3.222-3.326s3.222 1.495 3.222 3.326c0 1.837-1.448 3.327-3.222 3.327s-3.222-1.495-3.222-3.327zm1.101 0c0 1.204.949 2.19 2.121 2.19 1.166 0 2.121-.986 2.121-2.19s-.949-2.189-2.121-2.189c-1.172 0-2.121.985-2.121 2.189zM562.125 223.179h-21.283v9.436h21.283v-9.436z"
			/>
			<path fill="#AFB3B8" d="M540.842 223.179H591.615v9.436h-29.49v-7.941h-21.283v-1.495z" />
			<path fill="#AFB3B8" d="M562.559 230.755h-21.717v1.859h21.717v-1.859z" />
			<path fill="#AFB3B8" d="M540.842 223.179H591.615v9.436h-29.49v-7.941h-21.283v-1.495z" />
			<path fill="#AFB3B8" d="M562.125 230.755h-21.283v1.859h21.283v-1.859z" />
			<path
				fill="#D7DADC"
				d="M579.512 226.332h-12.445v3.248h12.445v-3.248zM582.62 227.715c0-1.478 1.161-2.677 2.593-2.677 1.433 0 2.594 1.199 2.594 2.677s-1.161 2.677-2.594 2.677c-1.432 0-2.593-1.199-2.593-2.677zm.885 0c0 .974.765 1.764 1.708 1.764.944 0 1.709-.79 1.709-1.764s-.765-1.764-1.709-1.764c-.943 0-1.708.79-1.708 1.764z"
			/>
			<path fill="#000" d="M591.615 232.015h-50.773v.599h50.773v-.599z" opacity="0.15" />
			<path
				fill="#C62221"
				d="M501.869 174.576h-93.603c-1.323 0-2.398-1.109-2.398-2.475v-60.29c0-1.366 1.075-2.475 2.398-2.475h93.603c1.324 0 2.398 1.109 2.398 2.475v60.29c0 1.366-1.074 2.475-2.398 2.475z"
			/>
			<path fill="#000" d="M408.25 109.33h93.63c1.318 0 2.387 1.104 2.387 2.464v3.932h-98.404v-3.932c0-1.36 1.069-2.464 2.387-2.464z" />
			<path
				fill="#fff"
				d="M501.869 174.576h-93.603c-1.323 0-2.398-1.109-2.398-2.475v-60.29c0-1.366 1.075-2.475 2.398-2.475h93.603c1.324 0 2.398 1.109 2.398 2.475v60.29c0 1.366-1.074 2.475-2.398 2.475z"
				opacity="0.2"
			/>
			<path
				fill="#C62221"
				d="M499.808 174.565h-93.603c-1.324 0-2.398-1.109-2.398-2.475v-60.296c0-1.366 1.074-2.475 2.398-2.475h93.603c1.323 0 2.398 1.109 2.398 2.475v60.29c0 1.366-1.075 2.481-2.398 2.481z"
			/>
			<path
				fill="#fff"
				d="M499.808 174.565h-93.603c-1.324 0-2.398-1.109-2.398-2.475v-60.296c0-1.366 1.074-2.475 2.398-2.475h93.603c1.323 0 2.398 1.109 2.398 2.515v60.289c0 1.327-1.075 2.442-2.398 2.442z"
				opacity="0.6"
			/>
			<path fill="#3F4249" d="M406.188 109.319h93.631c1.318 0 2.387 1.103 2.387 2.464v3.931h-98.405v-3.931c0-1.361 1.069-2.464 2.387-2.464z" />
			<path
				fill="#000"
				d="M408.999 112.511c0 .577-.456 1.047-1.015 1.047-.559 0-1.014-.47-1.014-1.047s.455-1.047 1.014-1.047c.559 0 1.015.47 1.015 1.047zM412.422 112.511c0 .577-.456 1.047-1.015 1.047-.558 0-1.014-.47-1.014-1.047s.456-1.047 1.014-1.047c.559 0 1.015.47 1.015 1.047zM415.85 112.511c0 .577-.455 1.047-1.014 1.047-.559 0-1.014-.47-1.014-1.047s.455-1.047 1.014-1.047c.559 0 1.014.47 1.014 1.047z"
			/>
			<path fill="#fff" d="M407.985 112.847a.33.33 0 00.325-.336.331.331 0 00-.325-.336.332.332 0 00-.326.336.33.33 0 00.326.336z" />
			<path
				fill="#C62221"
				fillRule="evenodd"
				d="M413.447 129.676v-3.562h18.038v3.562h-18.038zM413.447 134.901v-3.562h8.219v3.562h-8.219zM413.447 140.125v-3.561h5.789v3.561h-5.789zM413.447 124.45v-3.561h3.852v3.561h-3.852z"
				clipRule="evenodd"
			/>
			<path
				fill="#3F4249"
				d="M435.668 141.777h-23.989v-21.879c0-.146.113-.264.255-.264a.26.26 0 01.255.264v21.352h23.479a.26.26 0 01.255.264.26.26 0 01-.255.263z"
			/>
			<path
				fill="#21252B"
				d="M487.086 174.549l.098-26.304c.011-2.57-1.894-4.709-4.373-4.928l-17.197-1.478c0 .017 0 .034-.005.05l-6.852 2.257c-2.447.784-4.834 3.92-4.682 7.359l1.139 23.044h31.872z"
			/>
			<path
				fill="#F69797"
				d="M467.236 141.412c-.081-2.772 2.002-5.549 4.666-5.628 2.663-.078 4.898 2.565 4.974 5.337.027 1.002-.906.734-1.361 1.842-.798 1.949-1.964 5.119-3.662 5.169-1.677.051-2.187-2.805-3.103-4.67-.543-1.103-1.487-1.025-1.514-2.05z"
			/>
			<path
				fill="#21252B"
				d="M465.354 130.252c-1.346-.056-2.393-1.226-2.344-2.615.054-1.389 1.188-2.47 2.533-2.419.087.005.272-.062.25.022-.553 2.218.087 5.029-.439 5.012z"
			/>
			<path
				fill="#F69797"
				d="M466.921 129.614s8.447-5.836 8.979.968l.179 1.759.846 8.909c.005.264-9.754.644-9.722.314l.397-5.309a6.834 6.834 0 01-.212-1.562c-.168-2.671-.467-5.079-.467-5.079z"
			/>
			<path
				fill="#F69797"
				d="M465.902 123.403s-2.767 14.756 3.835 15.451c5.718.61 6.087-8.574 6.087-8.574l-.445-5.936-4.432-1.669-5.045.728z"
			/>
			<path
				fill="#21252B"
				d="M474.413 122.283a3.583 3.583 0 00-1.568.123c-.217.068-.444-.072-.499-.302-.265-1.092-1.22-1.898-2.359-1.898-.803 0-1.514.403-1.959 1.019-.141.196-.418.241-.591.078a3.583 3.583 0 00-2.924-.952c-1.639.207-2.968 1.557-3.19 3.248-.315 2.464 1.654 4.547 4.031 4.301 1.665-.174 3.027-1.54 3.26-3.248a3.967 3.967 0 00-.152-1.725c.846-.039 2.159-.196 3.396-.033l-.065.044 2.203.773s.689 6.076 1.047 5.953c1.605-.543 2.728-2.195 2.506-4.082-.195-1.697-1.503-3.064-3.136-3.299z"
			/>
			<path
				fill="#21252B"
				d="M467.914 126.387c.657-.963 1.79-1.892 2.968-1.887 1.692.011 2.918 1.333 2.783 2.929l-.028.342a.61.61 0 00.538.66c.298.028.531.28.542.588l.054 1.636a.236.236 0 00.228.229.233.233 0 00.228-.218l.098-1.137-1.075-6.255-5.05-1.378-2.1 1.854.814 2.637z"
			/>
			<path
				fill="#F69797"
				d="M475.308 129.233s.266-1.288 1.595-.891c1.503.465 1.617 3.831-.764 4.335-1.243.274-1.086-.515-.999-1.563l.168-1.881z"
			/>
			<path
				fill="#000"
				d="M477.066 129.205c-.271-.14-.51-.174-.716-.107-.206.073-.374.247-.488.527a1.84 1.84 0 00-.12.935l.011.286c.011.084.082.145.163.134a.15.15 0 00.13-.168l-.011-.286c-.01-.084-.021-.526.098-.778.081-.185.195-.314.315-.37.124-.039.298-.016.477.068.071.033.168 0 .206-.073.038-.028.006-.112-.065-.168z"
				opacity="0.3"
			/>
			<path fill="#000" d="M476.084 132.778s-.548.061-.852-.168l.972 1.45-.12-1.282z" opacity="0.15" />
			<path
				fill="#F69797"
				d="M465.63 128.135l.668-.129c.059-.017.13-.084.108-.168-.022-.084-.081-.135-.163-.112l-.667.129a.978.978 0 01-.255.028c-.179-.017-.515-.028-.857-.152-.071-.022-.147.023-.168.096a.141.141 0 00.092.173c.363.118.749.163.922.157.103 0 .212-.011.32-.022z"
			/>
			<path
				fill="#000"
				d="M467.312 140.002l.163-2.117c.564.571 2.126 1.344 3.906.851 0 0 .021-.022 0 0a4.356 4.356 0 002.257-1.551c.038-.067.124-.067.189-.028.066.039.066.129.028.196-.548.694-1.498 1.529-2.99 1.769-1.801 1.316-3.553.88-3.553.88z"
				opacity="0.15"
			/>
			<path
				fill="#fff"
				d="M468.392 135.051s.851-.022 1.643-.381c.689-.308 1.075-.739 1.075-.739s.363 1.137-.727 1.635c-1.286.588-1.991-.515-1.991-.515z"
			/>
			<path fill="#21252B" d="M470.713 131.411a.534.534 0 00.527-.543c0-.3-.234-.543-.527-.543a.533.533 0 00-.526.543c0 .3.233.543.526.543z" />
			<path fill="#fff" d="M470.475 130.807a.107.107 0 000-.213.107.107 0 000 .213z" />
			<path fill="#21252B" d="M466.536 131.562a.533.533 0 00.526-.543c0-.3-.233-.543-.526-.543a.533.533 0 00-.526.543c0 .3.233.543.526.543z" />
			<path fill="#fff" d="M466.303 130.958a.107.107 0 00.103-.107.106.106 0 00-.103-.106.106.106 0 00-.103.106c0 .059.049.107.103.107z" />
			<path
				fill="#000"
				d="M468.44 134.077l.7.022c.087 0 .147-.067.163-.157 0-.089-.065-.151-.152-.168l-.7-.022c-.168-.017-.32-.162-.304-.342.017-.173.158-.33.331-.313.087 0 .147-.067.163-.157.016-.09-.065-.151-.152-.168-.336-.011-.624.258-.656.61.005.387.271.684.607.695z"
				opacity="0.3"
			/>
			<path
				fill="#21252B"
				d="M464.263 130.453l.016.61c.033 1.176.988 2.106 2.127 2.072 1.139-.033 2.04-1.019 2.007-2.195l-.016-.61a.768.768 0 00-.163-.476l.803-.023a.8.8 0 00-.157.482l.016.61c.033 1.176.987 2.106 2.127 2.072 1.139-.033 2.039-1.019 2.007-2.195l-.016-.61a.767.767 0 00-.782-.762l-2.593.079h-.021l-2.002.061-.315.011-2.3.068c-.423.011-.749.375-.738.806zm5.344-.616h.021l2.594-.078a.47.47 0 01.477.464l.016.611c.027 1.002-.748 1.831-1.719 1.859-.972.028-1.774-.773-1.802-1.775l-.016-.611a.451.451 0 01.429-.47zm-5.067.633a.474.474 0 01.45-.493l2.3-.067.315-.011a.47.47 0 01.477.464l.017.611c.027 1.002-.749 1.831-1.72 1.859-.971.028-1.774-.773-1.801-1.775l-.038-.588z"
			/>
			<path fill="#F69797" d="M424.389 133.03s-1.003.291-.39 2.2c.618 1.91 4.101 5.13 4.101 5.13l1.21-5.785-4.921-1.545z" />
			<path
				fill="#F69797"
				d="M432.63 137.47l-.488-2.705a2.37 2.37 0 00-.185-.577l-1.747-3.729s-.461-.101-.634.509c-.174.611-.12 1.859-.12 1.859l-3.26-6.154a.541.541 0 00-.77-.213.582.582 0 00-.223.717l1.383 3.315s-1.236-.565-1.269 1.025c0 0-1.372.465-.754 2.374.618 1.91 3.537 6.468 3.537 6.468h2.338l2.192-2.889z"
			/>
			<path
				fill="#000"
				d="M426.592 130.559c.027-.016.271.392.645 1.064.196.364.407.762.641 1.188.114.224.26.464.314.772a.985.985 0 01-.032.482.7.7 0 01-.326.392c-.314.157-.662.09-.917-.078-.255-.174-.412-.42-.537-.65-.233-.465-.417-.874-.58-1.226-.32-.7-.499-1.143-.472-1.16.032-.016.266.398.624 1.076.19.364.396.761.618 1.192.12.213.271.42.472.555.195.129.472.168.678.061.206-.1.277-.369.234-.61-.038-.246-.169-.476-.283-.706l-.591-1.215c-.331-.683-.521-1.12-.488-1.137z"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M426.163 133.304c.174.515.375 1.025.57 1.534a.926.926 0 01.065.241c.038.219-.027.459-.212.549-.184.095-.434.056-.607-.056-.179-.118-.315-.308-.423-.498-.201-.387-.385-.745-.559-1.07-.32-.61-.532-.98-.559-.969-.027.017.136.409.423 1.042.147.313.31.683.521 1.103.114.207.255.426.483.582a.827.827 0 00.825.073.601.601 0 00.293-.353.92.92 0 00.027-.431c-.049-.274-.185-.493-.282-.694l-.152-.291-.174-.264c-.076-.134-.244-.492-.244-.492l.005-.006z"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M425.984 135.824a.609.609 0 01.022.101.716.716 0 01-.022.336.488.488 0 01-.227.274.636.636 0 01-.641-.056 1.217 1.217 0 01-.374-.453c-.163-.325-.293-.611-.407-.857-.222-.487-.347-.795-.331-.807.022-.011.185.28.434.751.13.252.277.532.434.834.087.146.19.297.331.387.136.089.331.117.472.044.141-.072.195-.257.163-.425a1.44 1.44 0 00-.092-.286s.065.056.124.095c.038.017.114.062.114.062zM429.266 136.731c-.016.016-.189-.185-.271-.6a2.141 2.141 0 01.315-1.556c.114-.174.255-.325.407-.443l-.038.112c-.201-.913-.309-1.59-.261-1.607.049-.011.244.644.467 1.557l.016.067-.054.045c-.125.106-.25.241-.353.397a2.195 2.195 0 00-.341 1.406c.032.381.151.605.113.622z"
				opacity="0.15"
			/>
			<path
				fill="#21252B"
				d="M438.077 159.091l-10.156-18.888 4.763-2.75 9.564 12.208 14.203-6.854a7.012 7.012 0 013.082-.7l7.508.044-1.297 8.288-20.398 10.142c-2.409 1.68-5.669 1.008-7.269-1.49z"
			/>
			<path
				fill="#fff"
				d="M467.453 138.12l-.119 1.63c0 .296.043.425.266.616l2.213 1.848c.152.123.26.291.315.481l1.47 5.466.255 6.983-4.08-9.778.434-2.027-1.503 3.551-2.197-4.492a1.014 1.014 0 01.12-1.08l2.826-3.198z"
			/>
			<path
				fill="#C62221"
				d="M457.254 168.819l14.734-11.245a.754.754 0 011.091.185l11.403 16.811h-24.076l-3.32-4.631a.824.824 0 01.168-1.12z"
			/>
			<path fill="#fff" d="M458.833 170.427a.557.557 0 01.108-.762l13.346-10.186a.52.52 0 01.743.123l10.161 14.952h-21.396l-2.962-4.127z" />
			<path fill="#fff" d="M458.833 170.427a.557.557 0 01.108-.762l13.346-10.186a.52.52 0 01.743.123l10.161 14.952h-21.396l-2.962-4.127z" />
			<path
				fill="#61656D"
				d="M479.86 174.033l-.206.414-.195-.42-.445-.067.325-.325-.07-.459.396.224.401-.213-.081.454.32.33-.445.062zM475.308 166.451l-10.302 7.588a.34.34 0 01-.482-.084.365.365 0 01.081-.498l10.302-7.588a.34.34 0 01.483.084.37.37 0 01-.082.498zM474.234 164.933l-10.302 7.588a.34.34 0 01-.483-.084.367.367 0 01.082-.499l10.302-7.588a.34.34 0 01.483.084.366.366 0 01-.082.499zM476.361 167.973l-8.827 6.502a.34.34 0 01-.482-.084.365.365 0 01.081-.498l8.826-6.502a.34.34 0 01.483.084.369.369 0 01-.081.498zM477.5 174.565h-.06l-.005-.039.065.039zM477.012 168.92a.34.34 0 01.483.084.365.365 0 01-.082.498l-6.873 5.063h-1.188l7.66-5.645zM478.233 174.536l-.005.028h-.05l.055-.028zM478.547 170.528a.365.365 0 01-.081.498l-4.807 3.539h-1.188l5.593-4.121a.339.339 0 01.483.084z"
				opacity="0.3"
			/>
			<path
				fill="#000"
				d="M462.587 166.541a.985.985 0 01.195-1.344l4.232-3.232a.915.915 0 011.291.185.976.976 0 01-.185 1.355l-4.231 3.232a.91.91 0 01-1.302-.196z"
				opacity="0.4"
			/>
			<path
				fill="#C62221"
				d="M462.587 166.541a.985.985 0 01.195-1.344l4.232-3.232a.915.915 0 011.291.185.976.976 0 01-.185 1.355l-4.231 3.232a.91.91 0 01-1.302-.196z"
			/>
			<path
				fill="#21252B"
				d="M491.312 148.059c-1.123-2.682-3.58-4.508-6.401-4.76l-7.991-.722 4.416 12.482 4.242 10.568-14.702 8.932 15.651-.017 7.525-3.125c2.186-.93 3.32-3.427 2.609-5.751l-5.349-17.607z"
			/>
			<path
				fill="#fff"
				d="M476.605 137.946l.136 1.445c0 .296-.131.582-.358.772l-2.49 2.05a.95.95 0 00-.315.482l-1.497 5.465-.223 6.983 4.08-9.777-.434-2.027 1.503 3.55 2.197-4.491a1.017 1.017 0 00-.12-1.081l-2.479-3.371z"
			/>
			<path fill="#000" d="M485.578 165.628l-3.749-9.324 1.872 8.993-4.042 2.156.852 1.255 5.067-3.08z" opacity="0.2" />
			<path
				fill="#3F4249"
				d="M423.798 148.435c.526 0 1.042.045 1.546.129v3.153a6.696 6.696 0 00-1.546-.185c-3.765 0-6.819 3.153-6.819 7.039 0 .482.049.952.135 1.406h-3.043a10.86 10.86 0 01-.098-1.406c.006-5.6 4.405-10.136 9.825-10.136z"
			/>
			<path
				fill="#C62221"
				d="M430.623 158.571c0-3.337-2.252-6.132-5.273-6.854v-3.153c4.687.767 8.273 4.956 8.273 10.013 0 .935-.125 1.837-.353 2.699h-3.168c.331-.84.521-1.747.521-2.705zM417.114 159.977c.63 3.215 3.386 5.634 6.684 5.634.109 0 .212-.006.32-.006v3.097c-.103.006-.211.006-.32.006-4.958 0-9.06-3.797-9.727-8.731h3.043z"
			/>
			<path
				fill="#fff"
				d="M417.114 159.977c.63 3.215 3.386 5.634 6.684 5.634.109 0 .212-.006.32-.006v3.097c-.103.006-.211.006-.32.006-4.958 0-9.06-3.797-9.727-8.731h3.043z"
				opacity="0.7"
			/>
			<path fill="#21252B" d="M430.102 161.27h3.168c-1.117 4.189-4.768 7.286-9.146 7.432v-3.097c2.696-.129 4.991-1.882 5.978-4.335z" />
			<path
				fill="#C62221"
				d="M347.772 235.325h-93.603c-1.324 0-2.398-1.109-2.398-2.475v-60.296c0-1.366 1.074-2.475 2.398-2.475h93.603c1.324 0 2.398 1.109 2.398 2.475v60.29c0 1.372-1.074 2.481-2.398 2.481z"
			/>
			<path fill="#000" d="M254.153 170.079h93.63c1.318 0 2.387 1.103 2.387 2.464v3.931h-98.404v-3.931c0-1.361 1.069-2.464 2.387-2.464z" />
			<path
				fill="#fff"
				d="M347.772 235.325h-93.603c-1.324 0-2.398-1.109-2.398-2.475v-60.296c0-1.366 1.074-2.475 2.398-2.475h93.603c1.324 0 2.398 1.109 2.398 2.475v60.29c0 1.372-1.074 2.481-2.398 2.481z"
				opacity="0.2"
			/>
			<path
				fill="#C62221"
				d="M345.835 235.325h-93.603c-1.323 0-2.397-1.109-2.397-2.475v-60.296c0-1.366 1.074-2.475 2.397-2.475h93.603c1.324 0 2.398 1.109 2.398 2.475v60.29c.006 1.372-1.068 2.481-2.398 2.481z"
			/>
			<path
				fill="#fff"
				d="M345.835 235.325h-93.603c-1.323 0-2.397-1.109-2.397-2.475v-60.296c0-1.366 1.074-2.475 2.397-2.475h93.603c1.324 0 2.398 1.109 2.398 2.515v60.289c.006 1.333-1.068 2.442-2.398 2.442z"
				opacity="0.6"
			/>
			<path fill="#3F4249" d="M252.222 170.079h93.63c1.318 0 2.387 1.103 2.387 2.464v3.931h-98.404v-3.931c0-1.361 1.068-2.464 2.387-2.464z" />
			<path
				fill="#000"
				d="M255.026 173.277c0 .577-.455 1.047-1.014 1.047-.559 0-1.015-.47-1.015-1.047s.456-1.047 1.015-1.047c.564-.006 1.014.464 1.014 1.047zM258.455 173.277c0 .577-.456 1.047-1.015 1.047-.558 0-1.014-.47-1.014-1.047s.456-1.047 1.014-1.047c.559-.006 1.015.464 1.015 1.047zM261.878 173.277c0 .577-.456 1.047-1.014 1.047-.559 0-1.015-.47-1.015-1.047s.456-1.047 1.015-1.047c.558-.006 1.014.464 1.014 1.047z"
			/>
			<path fill="#fff" d="M254.012 173.613c.18 0 .326-.151.326-.336a.33.33 0 00-.326-.336.33.33 0 00-.326.336c0 .185.146.336.326.336z" />
			<path fill="#906363" d="M274.659 210.477l-3.45-3.545a.396.396 0 00-.532-.033l-.01.005a1.043 1.043 0 00-.245 1.339l.771 1.304 3.466.93z" />
			<path
				fill="#000"
				d="M274.659 210.477l-3.45-3.545a.396.396 0 00-.532-.033l-.01.005a1.043 1.043 0 00-.245 1.339l.771 1.304 3.466.93z"
				opacity="0.15"
			/>
			<path
				fill="#906363"
				d="M265.762 205.824a.44.44 0 01.505.319l.765 2.615c.054.185.211.32.401.336l5.414.471c1.14.101 2.094.929 2.382 2.072l.624 2.43-4.346 1.708-.461-1.562a.687.687 0 00-.515-.487l-3.722-.829a1.818 1.818 0 01-1.085-.728l-3.634-5.057a.444.444 0 01.097-.616.422.422 0 01.564.056l1.606 1.786-.879-2.878a.468.468 0 01.304-.577.44.44 0 01.537.258l1.677 4.021-.586-2.778c-.049-.263.108-.51.352-.56z"
			/>
			<path
				fill="#000"
				d="M264.466 209.307c-.059 0-.108-.039-.13-.101l-.299-1.041a.143.143 0 01.093-.174.129.129 0 01.162.095l.299 1.042a.143.143 0 01-.092.174c-.006 0-.016.005-.033.005z"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M311.902 192.401s3.922 2.1 2.495 7.638c-.537 2.078 1.416 2.554 2.415 3.567 2.593 2.638.705 7.667-.798 7.588l-19.454 8.104s-6.114-10.976-3.184-15.636c1.302-2.072 1.057-2.38.542-4.564-.787-3.348 1.508-4.636 1.508-4.636l1.156-.118 5.099-9.05 7.313 6.401 1.183.179 1.725.527z"
			/>
			<path
				fill="#906363"
				d="M298.399 206.166l-2.973 7.823c1.796 2.778 10.346 1.624 12.63-1.848l2.75-6.596-.635-.18a1.485 1.485 0 01-1.079-1.405l-.602-7.325-7.509-1.042-.249 5.992-.125 2.98a1.49 1.49 0 01-1.063 1.282l-1.145.319z"
			/>
			<path
				fill="#906363"
				d="M302.826 203.405l.396.011c2.837.09 5.213-2.211 5.3-5.14l.223-7.449c.027-.98-.716-1.792-1.66-1.825l-6.733-.213c-1.231-.039-2.257.957-2.295 2.229l-.206 6.916c-.092 2.928 2.138 5.376 4.975 5.471z"
			/>
			<path fill="#21252B" d="M302.707 195.352a.562.562 0 00.52.61.557.557 0 00.592-.537.564.564 0 00-.521-.611.562.562 0 00-.591.538z" />
			<path fill="#fff" d="M302.821 195.492a.123.123 0 00.114.134.122.122 0 00.13-.117.125.125 0 00-.114-.135.127.127 0 00-.13.118z" />
			<path fill="#21252B" d="M298.405 195.285a.562.562 0 00.52.61.557.557 0 00.592-.537.564.564 0 00-.521-.611.564.564 0 00-.591.538z" />
			<path fill="#fff" d="M298.557 195.447a.124.124 0 00.114.135c.065.005.124-.045.13-.118a.124.124 0 00-.114-.134.13.13 0 00-.13.117z" />
			<path
				fill="#000"
				d="M300.998 198.639l.754.05a.164.164 0 00.173-.157.163.163 0 00-.151-.179l-.755-.05a.343.343 0 01-.314-.37.342.342 0 01.358-.33.165.165 0 00.173-.157c.006-.09-.059-.174-.151-.179a.674.674 0 00-.706.638c-.021.386.255.711.619.734z"
				opacity="0.3"
			/>
			<path
				fill="#000"
				d="M295.426 194.462c.814-.594.499-1.72.499-1.72v.028c-.748-6.036 3.283-9.396 6.646-9.71 1.92-.179 6.89.627 7.845 6.681.135.873.114 1.803 1.481 2.665 0 0-1.194 2.996-1.547 4.094-.151.47-1.014-1.462-1.399-1.68-.049-.028-.407.504-.64.851-.141.213-.472.191-.554-.056l-.027-.095a8.098 8.098 0 01-.369-2.671c-2.349-.857-2.083-3.383-2.083-3.383-2.696 4.906-7.318 3.08-7.318 3.08l-.044 2.789s-.482-.476-.954-1.277l-.847.633-.689-.229z"
			/>
			<path fill="#906363" d="M308.142 198.578s2.436.381 3.131-2.083c.694-2.464-2.279-3.147-2.881-.661l-.521 2.722.271.022z" />
			<path
				fill="#000"
				d="M310.611 195.111c-.25-.207-.494-.291-.727-.257-.233.033-.445.184-.624.442a1.983 1.983 0 00-.347.952l-.033.269c-.027.196.304.285.326.05l.038-.291c0-.084.092-.538.271-.79.13-.184.266-.291.412-.308.147-.022.309.045.489.191.07.056.168.045.222-.028a.177.177 0 00-.027-.23z"
				opacity="0.3"
			/>
			<path
				fill="#fff"
				d="M296.392 195.806c-.081 1.271.857 2.369 2.083 2.453 1.232.084 2.295-.885 2.376-2.15a2.343 2.343 0 00-.255-1.216 1.72 1.72 0 011.107.006c-.098.23-.157.482-.174.75-.081 1.272.858 2.369 2.084 2.453 1.231.084 2.294-.885 2.376-2.15.081-1.271-.857-2.369-2.083-2.453-.868-.056-1.655.409-2.073 1.137a1.989 1.989 0 00-1.383.005 2.24 2.24 0 00-1.682-.98c-1.231-.089-2.295.874-2.376 2.145zm5.42-.151c.07-1.109.998-1.949 2.072-1.876 1.074.073 1.888 1.03 1.817 2.139-.07 1.109-.998 1.949-2.072 1.876-1.069-.067-1.888-1.03-1.817-2.139zm-5.133.168c.071-1.109.999-1.949 2.073-1.876 1.074.073 1.888 1.03 1.817 2.139-.07 1.109-.998 1.949-2.072 1.876-1.074-.073-1.888-1.03-1.818-2.139zM308.376 197.699a.501.501 0 00.455.538c.272.016.5-.191.521-.471a.5.5 0 00-.455-.538.497.497 0 00-.521.471z"
			/>
			<path
				fill="#000"
				d="M308.669 198.146a.54.54 0 00.565-.509.558.558 0 00-.066-.297c.12.101.191.252.18.426a.494.494 0 01-.522.47.473.473 0 01-.271-.112c.038.011.076.022.114.022zM308.625 198.594h-.797l.906 1.636-.109-1.636zM306.298 202.71a5.942 5.942 0 01-2.354.812c-.548.431-1.498.985-2.691.985-.174 0-.418 0-.64-.095l.065-1.573.146.061c.651.275 1.335.336 2.1.359a5.743 5.743 0 00.851-.034c.022 0 .033 0 .055-.006a5.172 5.172 0 00.499-.084 5.565 5.565 0 001.812-.7c.065-.044.162-.022.206.056.043.079.022.174-.049.219z"
				opacity="0.15"
			/>
			<path
				fill="#fff"
				d="M300.591 199.553c-.076 0-.119.084-.081.151.287.554.83.924 1.443.918.613-.005 1.144-.392 1.421-.957.032-.068-.011-.146-.087-.146l-2.696.034z"
			/>
			<path
				fill="#000"
				d="M298.432 193.51h.016c4.21.408 6.277-3.316 6.293-3.349a.187.187 0 00-.065-.247c-.081-.05-.19-.016-.239.068-.022.033-1.98 3.556-5.956 3.169-.098-.011-.179.062-.19.163a.175.175 0 00.141.196z"
			/>
			<path
				fill="#21252B"
				d="M295.453 216.151l1.601-5.886 2.029-4.261-3.575.823a5.095 5.095 0 00-3.272 2.442l-11.625 20.132-4.698-15.77-4.709 1.327 3.146 20.367h13.004l2.902-3.64-.26 3.64h8.43l-2.973-19.174z"
			/>
			<path fill="#000" d="M290.251 231.68l.418-4.682c.032-.375-.434-.549-.641-.241l-5.772 8.563h3.087l2.908-3.64z" opacity="0.2" />
			<path
				fill="#fff"
				d="M313.964 206.502l-3.679-1.132c-1.665 6.076-7.182 9.347-10.768 8.468-4.074-1.003 0-7.997 0-7.997l-.684.19-6.374 11.626a9.096 9.096 0 00-.168 7.733l1.128 2.543c.304.683.434 1.433.38 2.178l-.364 5.214h16.905l3.624-28.823z"
			/>
			<path
				fill="#21252B"
				d="M322.947 213.608c-.428-2.699-2.289-4.922-4.812-5.746l-7.047-2.301s-3.683 10.819-4.622 29.764h10.774l.282-4.917 1.026 4.917h7.833l-3.434-21.717z"
			/>
			<path
				fill="#000"
				d="M318.217 220.91c-.011-.145-.217-.145-.223 0l-.477 9.497 1.025 4.918h.57l-.895-14.415zM280.611 229.4l.124 3.097 3.131-8.736-3.255 5.639z"
				opacity="0.2"
			/>
			<path
				fill="#C62221"
				d="M279.032 193.342c5.165 0 9.353-4.322 9.353-9.654 0-5.333-4.188-9.655-9.353-9.655s-9.353 4.322-9.353 9.655c0 5.332 4.188 9.654 9.353 9.654z"
			/>
			<path fill="#C62221" d="M282.949 191.371l5.924 6.222-4.937-7.14-.987.918z" />
			<path
				fill="#fff"
				d="M274.92 184.522a.821.821 0 00.808-.834.822.822 0 00-.808-.835.822.822 0 00-.809.835c0 .461.362.834.809.834zM279.032 184.522a.821.821 0 00.808-.834.822.822 0 00-.808-.835.821.821 0 00-.808.835.82.82 0 00.808.834zM283.144 184.522a.82.82 0 00.808-.834.821.821 0 00-.808-.835.822.822 0 00-.808.835c0 .461.362.834.808.834z"
			/>
			<path
				fill="#C62221"
				d="M384.467 137.302h-93.603c-1.324 0-2.398-1.109-2.398-2.475V74.531c0-1.366 1.074-2.475 2.398-2.475h93.603c1.323 0 2.398 1.109 2.398 2.475v60.29c.005 1.372-1.069 2.481-2.398 2.481z"
			/>
			<path fill="#000" d="M290.853 72.056h93.63c1.318 0 2.387 1.103 2.387 2.464v3.931h-98.404v-3.93c0-1.362 1.069-2.465 2.387-2.465z" />
			<path
				fill="#fff"
				d="M384.467 137.302h-93.603c-1.324 0-2.398-1.109-2.398-2.475V74.531c0-1.366 1.074-2.475 2.398-2.475h93.603c1.323 0 2.398 1.109 2.398 2.475v60.29c.005 1.372-1.069 2.481-2.398 2.481z"
				opacity="0.2"
			/>
			<path
				fill="#C62221"
				d="M382.525 137.302h-93.603c-1.324 0-2.398-1.109-2.398-2.475V74.531c0-1.366 1.074-2.475 2.398-2.475h93.603c1.323 0 2.398 1.109 2.398 2.475v60.29c.005 1.372-1.069 2.481-2.398 2.481z"
			/>
			<path
				fill="#fff"
				d="M382.525 137.302h-93.603c-1.324 0-2.398-1.109-2.398-2.475V74.531c0-1.366 1.074-2.475 2.398-2.475h93.603c1.323 0 2.398 1.109 2.398 2.515v60.289c.005 1.333-1.069 2.442-2.398 2.442z"
				opacity="0.6"
			/>
			<path fill="#3F4249" d="M288.911 72.056h93.63c1.318 0 2.387 1.103 2.387 2.464v3.931h-98.404v-3.93c0-1.362 1.069-2.465 2.387-2.465z" />
			<path
				fill="#000"
				d="M291.716 75.254c0 .577-.456 1.047-1.015 1.047-.559 0-1.014-.47-1.014-1.047s.455-1.047 1.014-1.047c.564-.006 1.015.465 1.015 1.047zM295.144 75.254c0 .577-.456 1.047-1.014 1.047-.559 0-1.015-.47-1.015-1.047s.456-1.047 1.015-1.047c.558-.006 1.014.465 1.014 1.047zM298.567 75.254c0 .577-.455 1.047-1.014 1.047-.559 0-1.015-.47-1.015-1.047s.456-1.047 1.015-1.047c.559-.006 1.014.465 1.014 1.047z"
			/>
			<path fill="#fff" d="M290.701 75.59c.18 0 .326-.15.326-.336a.33.33 0 00-.326-.336.33.33 0 00-.325.336c0 .185.146.336.325.336z" />
			<path
				fill="#C62221"
				d="M367.72 133.398l-2.1-6.21 8.908-4.452v-11.111c0-2.749-1.942-5.09-4.578-5.516l-25.482-3.119c-2.083-.314-4.139.706-5.202 2.576l-7.839 13.81 6.282 1.92.352 16.044h29.664l-.005-3.942z"
			/>
			<path
				fill="#fff"
				d="M337.182 115.322a.17.17 0 00.109-.044l.955-.924a.164.164 0 00.005-.23c-.059-.067-.157-.067-.222-.006l-.955.924a.164.164 0 00-.005.23.15.15 0 00.113.05z"
			/>
			<path
				fill="#fff"
				d="M338.11 115.355a.17.17 0 00.109-.044.164.164 0 00.005-.23l-.895-.986a.152.152 0 00-.222-.005.162.162 0 00-.006.229l.895.986c.027.028.071.05.114.05zM348.163 109.89a.167.167 0 00.108-.045l.955-.924a.164.164 0 00.005-.23c-.059-.067-.157-.067-.222-.005l-.955.924a.163.163 0 00-.005.229c.027.034.07.051.114.051z"
			/>
			<path
				fill="#fff"
				d="M349.085 109.923a.164.164 0 00.114-.275l-.895-.985a.154.154 0 00-.223-.006.164.164 0 00-.005.23l.895.985a.155.155 0 00.114.051zM356.75 114.145c0-.655.516-1.187 1.151-1.187.634 0 1.15.532 1.15 1.187s-.516 1.187-1.15 1.187c-.635 0-1.151-.532-1.151-1.187zm.315 0c0 .476.374.868.841.868a.855.855 0 00.841-.868c0-.476-.374-.868-.841-.868a.852.852 0 00-.841.868zM368.376 116.895a.16.16 0 00.158-.162c0-.476.374-.868.835-.868.461 0 .835.386.835.868 0 .09.071.162.158.162a.159.159 0 00.157-.162c0-.655-.515-1.187-1.15-1.187-.635 0-1.145.532-1.145 1.187a.152.152 0 00.152.162zM369.863 111.614l2.126-.745c.082-.028.125-.117.098-.207a.157.157 0 00-.201-.101l-2.126.745c-.082.028-.125.118-.098.207a.159.159 0 00.201.101zM349.427 114.303h2.246a.16.16 0 00.157-.163.16.16 0 00-.157-.162h-2.246a.16.16 0 00-.158.162c0 .09.071.163.158.163zM344.533 106.894a.16.16 0 00.109-.045.17.17 0 000-.23l-1.584-1.635a.159.159 0 00-.223 0 .172.172 0 000 .23l1.584 1.635a.174.174 0 00.114.045z"
			/>
			<path
				fill="#fff"
				d="M355.448 115.008c0-.583.462-1.059 1.026-1.059.564 0 1.025.476 1.025 1.059 0 .582-.461 1.058-1.025 1.058-.564 0-1.026-.476-1.026-1.058zm.06 0c0 .548.434.996.966.996.531 0 .965-.448.965-.996 0-.549-.434-.997-.965-.997-.532 0-.966.448-.966.997zM366.917 117.595h.065c0-.548.429-.996.96-.996.532 0 .96.448.96.996h.066c0-.582-.462-1.058-1.02-1.058-.559 0-1.031.47-1.031 1.058zM370.801 111.986l-2.127.744.021.063 2.127-.743-.021-.064zM350.246 114.974H348v.067h2.246v-.067zM341.531 105.939l-.046.048 1.588 1.639.046-.047-1.588-1.64zM339.515 130.755a.559.559 0 01-.244-.352.59.59 0 01.071-.426.535.535 0 01.754-.179.562.562 0 01.244.353.587.587 0 01-.071.425.539.539 0 01-.341.252.495.495 0 01-.125.017.588.588 0 01-.288-.09zm.239-.716a.236.236 0 00-.147.106.274.274 0 00-.032.185.248.248 0 00.103.151.248.248 0 00.179.034.241.241 0 00.147-.107.273.273 0 00.032-.185.248.248 0 00-.103-.151.255.255 0 00-.125-.039c-.021 0-.038 0-.054.006zM365.224 110.029a.562.562 0 01-.244-.353.587.587 0 01.071-.425.538.538 0 01.754-.179.559.559 0 01.244.352.584.584 0 01-.071.426.543.543 0 01-.466.269.588.588 0 01-.288-.09zm.239-.717a.236.236 0 00-.146.107.268.268 0 00-.033.185.248.248 0 00.103.151.247.247 0 00.179.033.236.236 0 00.147-.106.274.274 0 00.032-.185.248.248 0 00-.103-.151.255.255 0 00-.125-.039c-.021 0-.038 0-.054.005zM340.834 111.307a.565.565 0 01-.245-.353.59.59 0 01.071-.426.544.544 0 01.342-.252.542.542 0 01.412.073.555.555 0 01.244.353.581.581 0 01-.07.425.536.536 0 01-.754.18zm.244-.717a.24.24 0 00-.147.106.274.274 0 00-.032.185.238.238 0 00.103.151.229.229 0 00.325-.078.269.269 0 00.033-.185.242.242 0 00-.228-.19c-.022.005-.038.005-.054.011z"
			/>
			<path
				fill="#FA9B9B"
				d="M352.546 93.263s8.658-5.61 9 1.25l.125 1.774.586 8.882.005.101c0 .263-.086.504-.233.677l-5.007 3.528a.397.397 0 01-.489-.022l-3.716-3.265a1.04 1.04 0 01-.341-.896l.135-1.305.418-4.015a6.857 6.857 0 01-.168-1.579c-.082-2.694-.315-5.13-.315-5.13z"
			/>
			<path
				fill="#3F4249"
				d="M351.781 93.684c1.618 0 2.93-1.354 2.93-3.024s-1.312-3.024-2.93-3.024-2.929 1.354-2.929 3.024 1.311 3.024 2.929 3.024z"
			/>
			<path fill="#FA9B9B" d="M351.57 88.424s-3.076 13.323 3.542 14.219c5.729.789 6.369-8.434 6.369-8.434l-.277-5.98-9.634.195z" />
			<path
				fill="#3F4249"
				d="M351.955 89.254l5.859.437c.721.067 1.275.722 1.231 1.467-.065.745.488 1.4 1.232 1.467.19.022.341.196.341.392l-.086 1.338c-.022.174.108.33.276.33.147.023.277-.089.32-.24l.255-1.271.255-.415 1.378.874s2.23-3.55 1.167-6.793c-.613-1.842-2.376-2.497-3.228-2.61a.926.926 0 01-.657-.436c-.341-.594-1.166-1.624-3.016-1.557-1.421.045-2.208.857-2.609 1.49a.88.88 0 01-1.123.33c-.597-.286-1.465-.437-2.485.196-1.915 1.254-1.15 4.676.89 5z"
			/>
			<path fill="#000" d="M361.671 96.724s-.553.045-.846-.196l.933 1.49-.087-1.294z" opacity="0.15" />
			<path
				fill="#FA9B9B"
				d="M361.356 93.18s.423-1.25 1.698-.678c1.443.655 1.145 4.032-1.296 4.228-1.275.112-1.02-.655-.809-1.686l.407-1.865z"
			/>
			<path
				fill="#000"
				d="M363.114 93.375c-.255-.173-.488-.24-.7-.196-.211.045-.401.196-.553.46a1.863 1.863 0 00-.233.918l-.022.286c0 .09.065.15.146.15a.148.148 0 00.147-.15l.021-.286c0-.09.044-.526.19-.767.109-.174.234-.286.364-.33.125-.023.298.022.466.128.065.045.169.023.212-.045.049-.016.027-.1-.038-.168z"
				opacity="0.3"
			/>
			<path
				fill="#FA9B9B"
				d="M351.911 89.54l.679.067c.065 0 .146-.045.146-.13 0-.083-.043-.15-.125-.15l-.678-.068c-.087 0-.168-.022-.255-.044-.168-.068-.488-.174-.787-.392-.065-.045-.146-.023-.189.045-.044.067-.022.15.043.195.32.219.678.376.846.415.109.017.212.039.32.061z"
			/>
			<path
				fill="#000"
				d="M352.644 103.724l.222-2.122c.553.593 2.1 1.411 3.906.974 0 0 .022-.022 0 0a4.377 4.377 0 002.311-1.489c.044-.068.125-.068.19-.023.065.045.065.129.022.196-.575.678-1.552 1.49-3.054 1.686-1.85 1.271-3.597.778-3.597.778z"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M354.624 99.927c0 .023.022.023 0 0 .553.045.808.068.808.068.109 0 .168-.068.19-.174 0-.112-.065-.196-.168-.196l-.787-.067c-.108 0-.19.067-.19.151 0 .129.065.218.147.218zM354.331 99.137c1.443.219 2.099-.593 2.333-.985.043-.09.021-.174-.065-.241-.087-.045-.169-.023-.234.067-.211.392-.933 1.249-2.674.655-.087-.022-.168.023-.212.112-.022.09.022.174.109.219.276.084.531.15.743.173z"
				opacity="0.3"
			/>
			<path fill="#21252B" d="M356.322 95.195a.541.541 0 00.532-.548.54.54 0 00-.532-.55.54.54 0 00-.532.55.54.54 0 00.532.548z" />
			<path fill="#fff" d="M356.089 94.585a.11.11 0 00.108-.112.11.11 0 00-.108-.112.11.11 0 00-.109.112.11.11 0 00.109.112z" />
			<path fill="#21252B" d="M352.123 95.218a.541.541 0 00.532-.548.54.54 0 00-.532-.55.54.54 0 00-.532.55.54.54 0 00.532.548z" />
			<path fill="#fff" d="M351.89 94.603a.11.11 0 00.108-.112.11.11 0 00-.108-.112.11.11 0 00-.109.112.11.11 0 00.109.112z" />
			<path
				fill="#000"
				d="M353.946 97.8l.7.044c.086 0 .146-.067.168-.151 0-.09-.065-.151-.147-.174l-.7-.044c-.168-.023-.32-.174-.298-.353.022-.174.168-.33.342-.308.087 0 .146-.067.168-.151.022-.09-.065-.152-.146-.174-.342-.022-.635.24-.679.594 0 .386.255.694.592.716z"
				opacity="0.3"
			/>
			<path
				fill="#21252B"
				d="M349.85 94.03v.617c0 1.181.933 2.144 2.078 2.144 1.144 0 2.077-.963 2.077-2.144v-.616a.777.777 0 00-.146-.482h.808a.765.765 0 00-.168.482v.616c0 1.181.933 2.144 2.078 2.144 1.145 0 2.078-.963 2.078-2.144v-.616a.777.777 0 00-.765-.79H350.609c-.417 0-.759.353-.759.79zm5.392-.459h2.631c.255 0 .467.219.467.482v.616c0 1.008-.808 1.82-1.785 1.82-.976 0-1.763-.834-1.763-1.842v-.616a.462.462 0 01.45-.46zm-5.115.482c0-.263.211-.482.466-.482h2.631c.255 0 .467.219.467.482v.616c0 1.008-.809 1.82-1.785 1.82-.977 0-1.763-.834-1.763-1.842l-.016-.594z"
			/>
			<path fill="#FA9B9B" d="M374.523 122.735h-9.684l3.125 14.599h6.532l.027-14.599z" />
			<path
				fill="#FA9B9B"
				d="M369.76 130.228l-2.094-2.962-1.552-6.462a2.56 2.56 0 00-.374-.857c-.239-.336-.499-.532-.966-.773l-5.175-2.57a.671.671 0 00-.896.308c-.168.324-.07.75.255.94l4.308 2.688-3.89-1.282c-.434-.146-.89.202-.868.678a.702.702 0 00.391.599l4.551 1.999-3.461-1.512a.614.614 0 00-.852.594.641.641 0 00.293.543l3.727 2.335-2.457-1.523c-.304-.202-.727-.023-.803.347a.582.582 0 00.141.532l2.132 2.19 3.591 11.3h2.702c.705-3.074 1.297-7.112 1.297-7.112z"
			/>
			<path fill="#FA9B9B" d="M373.666 136.114l-3.407-4.631-5.615 2.475 1.085 3.377h8.55a3.917 3.917 0 00-.613-1.221z" />
			<path
				fill="#000"
				d="M367.682 127.149l-1.59-6.44a2.173 2.173 0 00-1.106-1.417l-.592-.302-.011-.538 1.362.661c.591.286 1.02.834 1.166 1.49l1.411 6.328 6.033 7.98.146 2.43-6.819-10.192z"
				opacity="0.15"
			/>
			<path fill="#21252B" d="M359.387 116.559l-22.129.005 4.047 20.771h22.129l-4.047-20.776z" />
			<path fill="#FA9B9B" d="M331.448 119.326l-5.36 9.139 8.827 1.59 3.966-8.489-7.433-2.24z" />
			<path
				fill="#FA9B9B"
				d="M334.041 123.565l1.389.818 3.055-2.464c.694-.252 1.047-.258 1.746-.045l5.49 1.753a.711.711 0 01.456.851c-.092.358-.439.599-.808.509l-4.926-1.058 3.694 1.798c.413.196.516.772.19 1.108a.668.668 0 01-.683.168l-4.65-1.736 3.538 1.322a.641.641 0 01.233 1.036.595.595 0 01-.57.19l-4.562-.968 3.097.672c.359.067.548.498.364.817a.547.547 0 01-.45.291l-3.006.107-3.401 4.149c-1.601 1.955-4.367 2.476-6.461 1.109-.076-.05-.147-.101-.223-.151l-.119-.09c-1.329-2.811 6.607-10.186 6.607-10.186z"
			/>
			<path
				fill="#FA9B9B"
				d="M334.041 123.566l-5.001 2.604c-2.051.369-3.5 2.273-3.358 4.412.189 2.907 3.113 4.738 5.696 3.568l3.114-3.164-.451-7.42z"
			/>
			<path fill="#000" d="M335.43 124.388l2.995-2.245-2.941 1.416-7.584 1.781-1.264 2.251 8.794-3.203z" opacity="0.15" />
			<path
				fill="#21252B"
				d="M338.756 121.828l-.44.157-.412-2.111 2.213.571-1.361 1.383zM360.217 120.85l.754-1.759-.922-.56-.195.426.363 1.893zM361.611 117.584l2.013-4.8a.594.594 0 00-.288-.767.558.558 0 00-.759.314l-1.904 4.81.938.443z"
			/>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="1.5"
				d="M363.244 122.451l-2.967-1.305"
				opacity="0.15"
			/>
			<path fill="#000" d="M363.26 120.535l-2.549-.846-.158.381 2.707.465z" opacity="0.15" />
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="1.5"
				d="M362.902 124.343l-2.213-1.372"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M337.47 127.003a.153.153 0 01.179-.123l2.452.515.613.135a.53.53 0 01.412.409l-3.537-.74a.17.17 0 01-.119-.196zM338.897 125.559c-.082-.028-.12-.124-.092-.202a.155.155 0 01.2-.095l1.617.621-1.633-.61 1.888.706 1.741.666a.63.63 0 01.299.448c-.011 0-.022 0-.033-.006l-3.987-1.528zM345.852 111.648l-3.076 4.917h1.681l1.395-4.917zM364.389 118.984l-.13-5.958-1.481 5.157 1.611.801z"
				opacity="0.15"
			/>
			<path
				fill="#000"
				d="M352.617 103.987l-.136 1.305c-.043.331.087.678.342.896l3.716 3.265a.403.403 0 00.488.022l5.007-3.528c.147-.173.234-.414.234-.677l-.006-.101 1.373.162-.168.605a.842.842 0 01-.315.459l-5.897 4.279a.796.796 0 01-1.009-.062l-4.622-4.245a.847.847 0 01-.271-.694l.141-1.826 1.123.14zM374.523 122.736h-7.14l-.271-1.215h7.411v1.215zM331.448 119.326l.689-1.21 5.767 1.759.288 1.484-6.744-2.033z"
				opacity="0.6"
			/>
			<path
				fill="#C62221"
				d="M319.568 93.017h-22.21a.448.448 0 01-.44-.454v-7.128c0-.252.201-.454.44-.454h22.21c.244 0 .439.207.439.454v7.128c-.005.258-.195.454-.439.454z"
			/>
			<path
				fill="#fff"
				d="M319.568 93.017h-22.21a.448.448 0 01-.44-.454v-7.128c0-.252.201-.454.44-.454h22.21c.244 0 .439.207.439.454v7.128c-.005.258-.195.454-.439.454z"
				opacity="0.5"
			/>
			<path
				fill="#fff"
				d="M318.992 93.017h-22.209a.448.448 0 01-.44-.454v-7.128c0-.252.201-.454.44-.454h22.209c.245 0 .44.207.44.454v7.128a.438.438 0 01-.44.454z"
			/>
			<path
				fill="#61656D"
				d="M313.47 88.005h-6.532c-.233 0-.418-.163-.418-.359 0-.201.19-.358.418-.358h6.532c.233 0 .418.162.418.358 0 .202-.185.36-.418.36z"
			/>
			<path
				fill="#AFB3B8"
				d="M316.671 89.393h-9.928c-.12 0-.217-.084-.217-.184 0-.101.097-.185.217-.185h9.928c.119 0 .217.084.217.184.01.101-.093.185-.217.185zM312.661 90.552h-5.913c-.119 0-.217-.084-.217-.184 0-.101.098-.185.217-.185h5.913c.12 0 .218.084.218.185 0 .1-.098.184-.218.184z"
			/>
			<path
				fill="#C62221"
				d="M301.904 86.471h-.467v.05c-1.085.225-1.909 1.221-1.909 2.409 0 1.187.819 2.184 1.909 2.408v.05h.467c1.313 0 2.376-1.098 2.376-2.453 0-1.355-1.063-2.464-2.376-2.464z"
			/>
			<path
				fill="#C62221"
				d="M301.437 91.371c1.312 0 2.376-1.098 2.376-2.453 0-1.354-1.064-2.452-2.376-2.452-1.312 0-2.376 1.098-2.376 2.452 0 1.355 1.064 2.453 2.376 2.453z"
			/>
			<path
				fill="#fff"
				d="M301.437 91.371c1.312 0 2.376-1.098 2.376-2.453 0-1.354-1.064-2.452-2.376-2.452-1.312 0-2.376 1.098-2.376 2.452 0 1.355 1.064 2.453 2.376 2.453z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M300.992 89.943l-.803-.829c-.043-.044-.043-.112 0-.162l.212-.218c.043-.045.109-.045.157 0l.516.532 1.237-1.283c.043-.045.108-.045.157 0l.212.219c.043.044.043.112 0 .162l-1.53 1.58a.101.101 0 01-.158 0z"
			/>
			<path
				fill="#C62221"
				d="M319.568 105.667h-22.21a.448.448 0 01-.44-.453v-7.129c0-.252.201-.454.44-.454h22.21c.244 0 .439.208.439.454v7.129c-.005.257-.195.453-.439.453z"
			/>
			<path
				fill="#fff"
				d="M319.568 105.667h-22.21a.448.448 0 01-.44-.453v-7.129c0-.252.201-.454.44-.454h22.21c.244 0 .439.208.439.454v7.129c-.005.257-.195.453-.439.453z"
				opacity="0.5"
			/>
			<path
				fill="#fff"
				d="M318.992 105.667h-22.209a.448.448 0 01-.44-.453v-7.129c0-.252.201-.454.44-.454h22.209c.245 0 .44.208.44.454v7.129a.438.438 0 01-.44.453z"
			/>
			<path
				fill="#61656D"
				d="M313.47 100.655h-6.532c-.233 0-.418-.163-.418-.359 0-.201.19-.358.418-.358h6.532c.233 0 .418.162.418.358 0 .202-.185.359-.418.359z"
			/>
			<path
				fill="#AFB3B8"
				d="M316.671 102.044h-9.928c-.12 0-.217-.084-.217-.185 0-.101.097-.185.217-.185h9.928c.119 0 .217.084.217.185.01.101-.093.185-.217.185zM312.661 103.203h-5.913c-.119 0-.217-.084-.217-.185 0-.101.098-.185.217-.185h5.913c.12 0 .218.084.218.185 0 .101-.098.185-.218.185z"
			/>
			<path
				fill="#C62221"
				d="M301.904 99.121h-.467v.05c-1.085.224-1.909 1.221-1.909 2.408 0 1.188.819 2.184 1.909 2.408v.051h.467c1.313 0 2.376-1.098 2.376-2.453 0-1.355-1.063-2.464-2.376-2.464z"
			/>
			<path
				fill="#C62221"
				d="M301.437 104.022c1.312 0 2.376-1.099 2.376-2.453 0-1.355-1.064-2.453-2.376-2.453-1.312 0-2.376 1.098-2.376 2.453 0 1.354 1.064 2.453 2.376 2.453z"
			/>
			<path
				fill="#fff"
				d="M301.437 104.022c1.312 0 2.376-1.099 2.376-2.453 0-1.355-1.064-2.453-2.376-2.453-1.312 0-2.376 1.098-2.376 2.453 0 1.354 1.064 2.453 2.376 2.453z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M300.992 102.594l-.803-.829c-.043-.045-.043-.112 0-.163l.212-.218c.043-.045.109-.045.157 0l.516.532 1.237-1.282c.043-.045.108-.045.157 0l.212.218c.043.045.043.112 0 .162l-1.53 1.58a.102.102 0 01-.158 0z"
			/>
			<path
				fill="#C62221"
				d="M319.568 118.318h-22.21a.448.448 0 01-.44-.454v-7.129c0-.252.201-.453.44-.453h22.21c.244 0 .439.207.439.453v7.129c-.005.258-.195.454-.439.454z"
			/>
			<path
				fill="#fff"
				d="M319.568 118.318h-22.21a.448.448 0 01-.44-.454v-7.129c0-.252.201-.453.44-.453h22.21c.244 0 .439.207.439.453v7.129c-.005.258-.195.454-.439.454z"
				opacity="0.5"
			/>
			<path
				fill="#fff"
				d="M318.992 118.318h-22.209a.448.448 0 01-.44-.454v-7.129c0-.252.201-.453.44-.453h22.209c.245 0 .44.207.44.453v7.129a.438.438 0 01-.44.454z"
			/>
			<path
				fill="#61656D"
				d="M313.47 113.305h-6.532c-.233 0-.418-.162-.418-.358 0-.202.19-.358.418-.358h6.532c.233 0 .418.162.418.358 0 .201-.185.358-.418.358z"
			/>
			<path
				fill="#AFB3B8"
				d="M316.671 114.694h-9.928c-.12 0-.217-.084-.217-.185 0-.101.097-.185.217-.185h9.928c.119 0 .217.084.217.185.01.101-.093.185-.217.185zM312.661 115.853h-5.913c-.119 0-.217-.084-.217-.185 0-.1.098-.184.217-.184h5.913c.12 0 .218.084.218.184 0 .101-.098.185-.218.185z"
			/>
			<path
				fill="#C62221"
				d="M301.904 111.771h-.467v.051c-1.085.224-1.909 1.221-1.909 2.408 0 1.187.819 2.184 1.909 2.408v.05h.467c1.313 0 2.376-1.097 2.376-2.453 0-1.355-1.063-2.464-2.376-2.464z"
			/>
			<path
				fill="#C62221"
				d="M301.437 116.671c1.312 0 2.376-1.098 2.376-2.452 0-1.355-1.064-2.453-2.376-2.453-1.312 0-2.376 1.098-2.376 2.453 0 1.354 1.064 2.452 2.376 2.452z"
			/>
			<path
				fill="#fff"
				d="M301.437 116.671c1.312 0 2.376-1.098 2.376-2.452 0-1.355-1.064-2.453-2.376-2.453-1.312 0-2.376 1.098-2.376 2.453 0 1.354 1.064 2.452 2.376 2.452z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M300.992 115.244l-.803-.829c-.043-.045-.043-.112 0-.162l.212-.219c.043-.044.109-.044.157 0l.516.532 1.237-1.282c.043-.045.108-.045.157 0l.212.218c.043.045.043.112 0 .163l-1.53 1.579a.11.11 0 01-.158 0z"
			/>
			<path
				fill="#C62221"
				d="M319.568 130.968h-22.21a.448.448 0 01-.44-.453v-7.129c0-.252.201-.454.44-.454h22.21c.244 0 .439.207.439.454v7.129c-.005.257-.195.453-.439.453z"
			/>
			<path
				fill="#fff"
				d="M319.568 130.968h-22.21a.448.448 0 01-.44-.453v-7.129c0-.252.201-.454.44-.454h22.21c.244 0 .439.207.439.454v7.129c-.005.257-.195.453-.439.453z"
				opacity="0.5"
			/>
			<path
				fill="#fff"
				d="M318.992 130.968h-22.209a.448.448 0 01-.44-.453v-7.129c0-.252.201-.454.44-.454h22.209c.245 0 .44.207.44.454v7.129a.438.438 0 01-.44.453z"
			/>
			<path
				fill="#61656D"
				d="M313.47 125.956h-6.532c-.233 0-.418-.163-.418-.359 0-.201.19-.358.418-.358h6.532c.233 0 .418.162.418.358 0 .202-.185.359-.418.359z"
			/>
			<path
				fill="#AFB3B8"
				d="M316.671 127.344h-9.928c-.12 0-.217-.084-.217-.184 0-.101.097-.185.217-.185h9.928c.119 0 .217.084.217.185.01.1-.093.184-.217.184zM312.661 128.504h-5.913c-.119 0-.217-.084-.217-.185 0-.101.098-.185.217-.185h5.913c.12 0 .218.084.218.185 0 .101-.098.185-.218.185z"
			/>
			<path
				fill="#C62221"
				d="M301.904 124.416h-.467v.051c-1.085.224-1.909 1.22-1.909 2.408 0 1.187.819 2.184 1.909 2.408v.05h.467c1.313 0 2.376-1.098 2.376-2.453 0-1.355-1.063-2.464-2.376-2.464z"
			/>
			<path
				fill="#C62221"
				d="M301.437 129.322c1.312 0 2.376-1.098 2.376-2.453 0-1.355-1.064-2.453-2.376-2.453-1.312 0-2.376 1.098-2.376 2.453 0 1.355 1.064 2.453 2.376 2.453z"
			/>
			<path
				fill="#fff"
				d="M301.437 129.322c1.312 0 2.376-1.098 2.376-2.453 0-1.355-1.064-2.453-2.376-2.453-1.312 0-2.376 1.098-2.376 2.453 0 1.355 1.064 2.453 2.376 2.453z"
				opacity="0.4"
			/>
			<path
				fill="#fff"
				d="M300.992 127.894l-.803-.828c-.043-.045-.043-.112 0-.163l.212-.218c.043-.045.109-.045.157 0l.516.532 1.237-1.283c.043-.044.108-.044.157 0l.212.219c.043.045.043.112 0 .162l-1.53 1.579a.11.11 0 01-.158 0z"
			/>
			<path
				fill="#C62221"
				d="M466.26 263.167h-93.603c-1.324 0-2.398-1.108-2.398-2.475v-60.289c0-1.367 1.074-2.476 2.398-2.476h93.603c1.323 0 2.397 1.109 2.397 2.476v60.289c0 1.367-1.074 2.475-2.397 2.475z"
			/>
			<path fill="#000" d="M372.64 197.922h93.63c1.319 0 2.387 1.103 2.387 2.464v3.931h-98.404v-3.931c0-1.361 1.069-2.464 2.387-2.464z" />
			<path
				fill="#fff"
				d="M466.26 263.167h-93.603c-1.324 0-2.398-1.108-2.398-2.475v-60.289c0-1.367 1.074-2.476 2.398-2.476h93.603c1.323 0 2.397 1.109 2.397 2.476v60.289c0 1.367-1.074 2.475-2.397 2.475z"
				opacity="0.2"
			/>
			<path
				fill="#C62221"
				d="M464.117 263.167h-93.603c-1.324 0-2.398-1.108-2.398-2.475v-60.289c0-1.367 1.074-2.476 2.398-2.476h93.603c1.323 0 2.398 1.109 2.398 2.476v60.289c.005 1.367-1.075 2.475-2.398 2.475z"
			/>
			<path
				fill="#fff"
				d="M464.117 263.167h-93.603c-1.324 0-2.398-1.108-2.398-2.475v-60.289c0-1.367 1.074-2.476 2.398-2.476h93.603c1.323 0 2.398 1.109 2.398 2.515v60.289c.005 1.328-1.075 2.436-2.398 2.436z"
				opacity="0.6"
			/>
			<path fill="#3F4249" d="M370.497 197.922h93.631c1.318 0 2.387 1.103 2.387 2.464v3.931H368.11v-3.931c0-1.361 1.069-2.464 2.387-2.464z" />
			<path
				fill="#000"
				d="M373.308 201.119c0 .577-.456 1.048-1.015 1.048-.559 0-1.014-.471-1.014-1.048 0-.576.455-1.047 1.014-1.047.559 0 1.015.465 1.015 1.047zM376.736 201.119c0 .577-.456 1.048-1.014 1.048-.559 0-1.015-.471-1.015-1.048 0-.576.456-1.047 1.015-1.047.558 0 1.014.465 1.014 1.047zM380.159 201.119c0 .577-.455 1.048-1.014 1.048-.559 0-1.015-.471-1.015-1.048 0-.576.456-1.047 1.015-1.047.559 0 1.014.465 1.014 1.047z"
			/>
			<path fill="#fff" d="M372.293 201.455a.33.33 0 00.326-.336.332.332 0 00-.326-.336.331.331 0 00-.325.336.33.33 0 00.325.336z" />
			<path
				fill="#21252B"
				d="M421.243 220.647c-.244.661-.499 1.339-.743 2.061-.782 2.302 1.383 2.688 1.383 2.688s-.759 1.562.288 2.873c1.047 1.316 2.463.694 2.463.694s.477.711 1.795.851c1.915.202 2.642-.565 2.642-.565l2.018-.291c.255.162.423 1.181 2.534 1.528 1.92.314 2.956-.84 2.956-.84.472 1.053 2.876.969 3.212-.649.315-1.523-.353-2.179-.434-2.251 1.21.963 3.38-1.417 1.269-3.842-.857-.986-1.334-1.45-1.654-1.938.987.286 2.078-.868 1.671-1.937-.521-1.372-1.595-.879-2.55-2.862-1.27-2.632-2.279-3.55-2.338-3.606-1.698-1.798-4.275-2.694-7.129-2.632-2.43.056-3.884 1.019-4.741 2.038-.743.879-1.139 1.999-1.21 3.164-.016.241-.038.482-.065.723-.374.84-1.128 2.318-1.964 2.744-1.139.576-.58 2.256.597 2.049z"
			/>
			<path
				fill="#21252B"
				d="M427.992 227.994c1.195 0 2.164-1 2.164-2.234s-.969-2.234-2.164-2.234c-1.196 0-2.165 1-2.165 2.234s.969 2.234 2.165 2.234z"
			/>
			<path
				fill="#F39494"
				d="M390.358 224.702l8.273 10.791 8.686-3.035 2.62 7.073-8.718 2.565c-2.506.733-5.17-.364-6.504-2.671l-7.189-12.679 2.832-2.044z"
			/>
			<path
				fill="#F39494"
				d="M390.082 223.98s-.223-1.159-.684-3.091c-.152-.644-.976-.404-1.052-.196-.158.436-.103 1.03-.114 1.573-.027 1.305-3.852-1.445-4.948-2.262a.269.269 0 00-.428.235c.037.644.656 2.15 3.775 5.538.353.381.733.784 1.145 1.216 6.206 6.417 2.306-3.013 2.306-3.013z"
			/>
			<path
				fill="#C62221"
				d="M409.937 239.531l-2.62-7.073s5.908-2.066 9.136-3.192c1.35-.47 2.783-.672 4.209-.582l4.058.252 12.868.739c4.129.274 7.102 4.2 6.364 8.394l-1.177 6.821-.847 6.065v12.208H417.51s.467-4.396-.227-12.029c-.407-4.491-2.631-5.04-2.192-9.161.19-1.759.987-2.912 1.687-4.57l-1.947.644-4.894 1.484z"
			/>
			<path
				fill="#F59696"
				d="M432.993 220.098l-.195 7.711a1.438 1.438 0 00.944 1.383l.836.297-2.013 1.114a7.064 7.064 0 01-6.461.264 7.25 7.25 0 01-1.085-.622l-2.046-1.422.966-.224c.548-.118.966-.588 1.058-1.143l1.454-9.094 6.542 1.736z"
			/>
			<path
				fill="#F59696"
				d="M427.384 226.057l.353.045c2.544.252 4.779-1.664 5.023-4.268l.619-6.636a1.572 1.572 0 00-1.384-1.73l-6.011-.616c-1.096-.106-2.072.722-2.175 1.854l-.575 6.165c-.223 2.604 1.627 4.939 4.15 5.186z"
			/>
			<path fill="#21252B" d="M428.263 219.326a.508.508 0 00.499-.516.508.508 0 00-.499-.515.507.507 0 00-.499.515c0 .285.223.516.499.516z" />
			<path fill="#fff" d="M428.062 218.804a.104.104 0 00.103-.106.105.105 0 00-.103-.107.105.105 0 00-.103.107c0 .059.046.106.103.106z" />
			<path fill="#21252B" d="M424.389 219.107a.507.507 0 00.499-.516.507.507 0 00-.499-.515.508.508 0 00-.499.515c0 .285.224.516.499.516z" />
			<path fill="#fff" d="M424.189 218.564a.105.105 0 00.103-.106.105.105 0 00-.103-.107.105.105 0 00-.103.107c0 .058.046.106.103.106z" />
			<path
				fill="#000"
				d="M426.033 221.695l.662.101c.081.005.147-.056.174-.118.005-.084-.055-.151-.114-.179l-.662-.101a.312.312 0 01-.25-.358.342.342 0 01.348-.28c.081.005.146-.056.173-.118.006-.084-.054-.151-.114-.179a.609.609 0 00-.673.521.54.54 0 00.456.711z"
				opacity="0.15"
			/>
			<path fill="#F59696" d="M432.413 222.075s2.154.487 2.913-1.658c.76-2.167-1.861-2.957-2.539-.767l-.624 2.408.25.017z" />
			<path
				fill="#000"
				d="M434.811 219.141c-.217-.207-.423-.28-.63-.275-.206.012-.401.129-.58.353-.179.224-.32.521-.358.84l-.033.235c-.032.168.255.275.288.062l.054-.252c.006-.084.109-.487.282-.694.13-.163.261-.241.385-.258.125-.017.266.056.429.196.06.045.141.05.206-.011a.161.161 0 00-.043-.196z"
				opacity="0.15"
			/>
			<path fill="#C62221" d="M433.113 221.812c.24 0 .434-.201.434-.448a.44.44 0 00-.434-.448.44.44 0 00-.434.448c0 .247.194.448.434.448z" />
			<path
				fill="#000"
				d="M432.907 221.734a.478.478 0 00.543-.42.54.54 0 00-.049-.264c.098.09.152.247.147.392a.43.43 0 01-.5.381.363.363 0 01-.244-.123c.043.011.086.017.103.034zM425.328 225.318c.683.465 1.481.706 2.327.795l.559.034c.06.006.146.011.206-.011h.038c.103-.017.206-.011.336-.023.038 0 .082-.016.125-.016a4.664 4.664 0 001.649-.51c.066-.039.147-.011.185.05a.146.146 0 01-.049.191 5.182 5.182 0 01-2.148.588c-.516.358-1.389.778-2.469.722a4.496 4.496 0 01-1.03-.185l.271-1.635z"
				opacity="0.15"
			/>
			<path
				fill="#fff"
				d="M425.615 222.483c-.059-.005-.108.056-.092.124.223.504.678.873 1.243.907.558.033 1.052-.286 1.329-.762.021-.061.005-.129-.076-.134l-2.404-.135z"
			/>
			<path fill="#F59696" d="M434.578 229.49c-.196 3.662-6.923 6.288-6.923 6.288s-4.871-3.292-4.676-6.955l1.117-.269 10.482.936z" />
			<path
				fill="#fff"
				d="M422.067 218.878c-.141 1.131.635 2.179 1.726 2.324 1.095.146 2.11-.655 2.251-1.781.038-.386 0-.772-.152-1.103.423-.106.808 0 .993.079-.092.207-.168.42-.201.655-.141 1.131.635 2.178 1.725 2.324 1.096.145 2.111-.655 2.252-1.781.141-1.131-.635-2.178-1.725-2.324a1.966 1.966 0 00-1.916.879 1.798 1.798 0 00-1.242-.089 2.061 2.061 0 00-1.443-.98c-1.107-.135-2.126.672-2.268 1.797zm4.85.207c.136-.98 1.004-1.68 1.948-1.54a1.783 1.783 0 011.492 2.011c-.136.98-1.004 1.68-1.948 1.54-.922-.112-1.606-1.031-1.492-2.011zm-4.578-.168c.135-.98 1.003-1.68 1.947-1.54a1.783 1.783 0 011.492 2.011c-.135.98-1.003 1.68-1.947 1.54a1.783 1.783 0 01-1.492-2.011z"
			/>
			<path
				fill="#21252B"
				d="M432.201 233.169l-8.072-.874s.407.711 1.486 1.792c.896.896 2.035 1.697 2.035 1.697s1.188-.465 2.506-1.232c1.286-.745 2.045-1.383 2.045-1.383z"
			/>
			<path
				fill="#F59696"
				d="M419.963 258.061l-1.959.274-4.183-.386c-.439.039-.634.129-.965.431l-2.582 2.38a.429.429 0 00-.038.582c.141.168.385.213.564.073l2.446-1.853-1.589 1.937a.42.42 0 00.163.655.417.417 0 00.417-.083l2.127-2.151-1.617 1.641a.385.385 0 00.125.627.367.367 0 00.358-.039l2.262-1.708-1.535 1.171a.34.34 0 000 .542.312.312 0 00.315.04l2.056-.885a.745.745 0 01.618.011l.787.398a4.55 4.55 0 002.056.498h1.286c1.839-.006-1.112-4.155-1.112-4.155z"
			/>
			<path
				fill="#000"
				d="M413.952 261.673c.011 0 .027 0 .038-.011l1.41-1.07c.027-.028.044-.073.011-.101-.027-.028-.071-.039-.098-.011l-1.41 1.07c-.027.028-.038.072-.011.1.022.006.049.023.06.023zM412.753 261.628c.011 0 .043 0 .043-.011l1.519-1.54a.073.073 0 000-.101.068.068 0 00-.098 0l-1.518 1.54a.071.071 0 000 .101c.01.011.038.011.054.011zM415.872 261.046c-.466.24-1.258.655-1.535.812-.385.229-.499-.073-.499-.073a.352.352 0 00.125.358.324.324 0 00.314.045l2.056-.885a.747.747 0 01.619.011l-.402-.24a.71.71 0 00-.678-.028z"
				opacity="0.15"
			/>
			<path
				fill="#F59696"
				d="M419.963 258.061l13.882-3.276 1.237-10.22 7.579 1.142-1.481 9.576c-.44 2.89-2.528 4.979-5.349 5.292l-14.751 1.635-1.117-4.149z"
			/>
			<path
				fill="#21252B"
				d="M423.369 262.217h-16.557a.267.267 0 00-.255.263v.432c0 .14.12.263.255.263h16.557a.266.266 0 00.255-.263v-.432c-.005-.145-.108-.263-.255-.263z"
			/>
			<path
				fill="#000"
				d="M423.369 262.217h-16.557a.267.267 0 00-.255.263v.432c0 .14.12.263.255.263h16.557a.266.266 0 00.255-.263v-.432c-.005-.145-.108-.263-.255-.263z"
				opacity="0.6"
			/>
			<path
				fill="#21252B"
				d="M412.199 262.217h-28.871a.266.266 0 00-.255.263v.432c0 .14.119.263.255.263h28.871a.266.266 0 00.255-.263v-.432c-.021-.145-.119-.263-.255-.263z"
			/>
			<path
				fill="#000"
				d="M383.702 262.385h29.393c.048 0 .081-.033.081-.084 0-.05-.033-.084-.081-.084h-29.393c-.049 0-.082.034-.082.084 0 .051.044.084.082.084z"
				opacity="0.6"
			/>
			<path
				fill="#21252B"
				d="M412.27 262.217h-4.351c-.336 0-.618-.241-.689-.588l-3.646-19.78a.714.714 0 01.689-.851h4.351c.337 0 .619.241.689.588l3.646 19.779c.065.448-.255.852-.689.852z"
			/>
			<path
				fill="#000"
				d="M412.27 262.217h-4.351c-.336 0-.618-.241-.689-.588l-3.646-19.78a.714.714 0 01.689-.851h4.351c.337 0 .619.241.689.588l3.646 19.779c.065.448-.255.852-.689.852z"
				opacity="0.6"
			/>
			<path
				fill="#21252B"
				d="M411.646 262.217h-27.928c-.336 0-.618-.241-.689-.588l-3.645-19.78a.713.713 0 01.689-.851h27.911c.337 0 .619.241.689.588l3.646 19.779c.081.448-.239.852-.673.852z"
			/>
			<path
				fill="#000"
				d="M395.691 251.05c.168.397-.016.744-.401.744h-.065c.455.521 1.144.885 1.746.868.825-.016 1.21-.711.841-1.562-.352-.851-1.329-1.506-2.148-1.49-.521.017-.873.292-.955.728.374.017.814.308.982.712z"
				opacity="0.6"
			/>
			<path
				fill="#000"
				d="M435.923 237.806l-3.841 15.5-12.119 4.755 13.882-3.271 2.078-16.984zM416.773 237.403c.992-2.643 1.687-8.63 1.687-8.63-.201 0-1.139.23-1.139.23-.245 2.727-2.355 8.966-2.67 9.094l2.122-.694z"
				opacity="0.15"
			/>
			<path
				fill="#21252B"
				d="M430.845 213.418s.233 4.172 2.116 5.857c0 0 .163-1.109 1.627-.941 0 0 .141-.257.353-1.512.217-1.254-.559-6.434-6.125-6.098 0 0-1.768-.37-4.248 1.814-2.479 2.19-1.009 4.581-1.009 4.581s4.134-.599 7.286-3.701z"
			/>
		</svg>
	);
};
