import { Button, Card, Hidden, Snackbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import { format, getDate, getMonth, getYear, parseISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { EventAttributes, createEvents } from 'ics';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AssistantCTAHollow } from '../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { CTAHeadings } from '../../../../../elements/CTAHeadings/cTAHeadings';
import { BpayHorizontal, Calendar, PaperPlane } from '../../../../../elements/PantheraIcon';
import { SpinnerButton } from '../../../../../elements/SpinnerButton/spinnerButton';
import { AvailablePaymentMethod, PaymentType } from '../../../../../enums/paymentForm';
import { AccountRoutes } from '../../../../../enums/routerPath';
import arlApi from '../../../../../interceptor/api';
import { dateFormats } from '../../../../../services/constants/formats';
import { formatMoney } from '../../../../../services/helpers/text.helpers';
import { IGetArrangementSchedule, PaymentScheduleAPI } from '../../../../../store/features/arrangement/paymentSchedule/types';
import { resetArrangementSummary } from '../../../../../store/features/arrangement/summary/summarySlice';
import { resetPaymentFormSuccess } from '../../../../../store/features/paymentForm/paymentFormSlice';
import { RootState } from '../../../../../store/rootReducer';
import { spacingUnits } from '../../../../../theme/theme';
import { DashboardLoader } from '../../../elements/DashboardLoader/dashboardLoader';
import { SummaryLockedModal } from '../SummaryLockedModal/summaryLockedModal';
import useStyles from './arrangementSetup.styles';

export const ArrangementSetup: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	const [showSendSummaryModal, setShowSendSummaryModal] = useState(false);
	const [icsLoading, setIcsLoading] = useState(false);
	const [errorSnackbar, setErrorSnackbar] = useState<string | undefined>();

	const { paymentMethod, paymentAmount, paymentDate, paymentType, nextScheduledDate, billerCode, paymentRef, merchantFeePercent, loading } =
		useSelector(
			(state: RootState) => ({
				paymentMethod: state.paymentForm.paymentSuccess?.paymentMethod,
				paymentAmount: state.paymentForm.paymentSuccess?.paymentAmount,
				paymentDate: state.paymentForm.paymentSuccess?.paymentDate,
				paymentType: state.paymentForm.paymentSuccess?.paymentType,
				nextScheduledDate: state.paymentForm.paymentSuccess?.arrangement?.FirstPaymentDate,
				billerCode: state.customerBpay.bpayDetails?.billerCode,
				paymentRef: state.customerBpay.bpayDetails?.ref,
				merchantFeePercent: state.customer.customerDetails?.merchantFeePercent ?? 0,
				loading: state.customerBpay.loading,
			}),
			shallowEqual,
		);

	useEffect(() => {
		if (paymentMethod?.Name === AvailablePaymentMethod.CARD) {
			document.getElementById('bodyContent')?.classList.add('whiteBackground-sm');
		} else {
			document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm');
		}
		return () => document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm');
	}, [paymentMethod]);

	// Reset payment form
	useEffect(() => {
		dispatch(resetPaymentFormSuccess());
	}, [dispatch]);

	async function getData() {
		return arlApi.get<IGetArrangementSchedule>(PaymentScheduleAPI.SCHEDULES);
	}

	const handleClickDownloadICS = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setIcsLoading(true);
		try {
			const result = await getData();
			if (result.data && result.data.success) {
				const events = result.data.data.schedule.map((schedule) => {
					let desc = `You are scheduled to pay ${formatMoney(schedule.amount)} today.`;
					if (paymentMethod && paymentMethod.Name === AvailablePaymentMethod.CARD) {
						desc = `You are scheduled to pay ${formatMoney(schedule.amount)}${
							merchantFeePercent > 0 ? ` (includes ${merchantFeePercent}% surcharge)` : ''
						} today, this payment will automatically be taken by ${
							AvailablePaymentMethod.CARD
						}. If you cannot make this payment please contact us immediately.`;
					} else if (paymentMethod && paymentMethod.Name === AvailablePaymentMethod.DIRECT) {
						desc = `You are scheduled to pay ${formatMoney(schedule.amount)} today, this payment will automatically be taken by ${
							AvailablePaymentMethod.DIRECT
						}. If you cannot make this payment please contact us immediately.`;
					} else if (paymentMethod && paymentMethod.Name === AvailablePaymentMethod.BPAY) {
						desc = `You are scheduled to pay ${formatMoney(schedule.amount)} today, please ensure you transfer funds via ${
							AvailablePaymentMethod.BPAY
						} using Biller Code: ${billerCode} BPAY Ref: ${paymentRef}. If you cannot make this payment please contact us immediately.`;
					}
					const paDate = parseISO(schedule.date);
					const year = getYear(paDate);
					const month = getMonth(paDate);
					const day = getDate(paDate);
					const evt: EventAttributes = {
						title: `${window.config.REACT_APP_COMPANY} Payment Due`,
						description: desc,
						start: [year, month + 1, day, 9, 0],
						duration: { minutes: 5 },
						url: 'https://www.arlcollect.com.au/',
						organizer: { name: window.config.REACT_APP_COMPANY, email: window.config.REACT_APP_CONTACT_EMAIL },
						alarms: [{ action: 'display', trigger: { hours: 1, before: true } }],
					};
					return evt;
				});
				const { value } = createEvents(events);
				if (value) {
					const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
					saveAs(blob, 'payment-schedule.ics');
				}
			} else {
				setErrorSnackbar(result.data.message);
			}
		} catch (err) {
			setErrorSnackbar('Your ICS file could not be downloaded at this time.');
		} finally {
			setIcsLoading(false);
		}
	};

	const buttons = (
		<>
			{paymentType === PaymentType.ARRANGEMENT && (
				<ButtonStack className={styles.buttonStack} style={{ paddingBottom: spacingUnits(2) }}>
					<Button
						className={styles.button}
						color="secondary"
						endIcon={<PaperPlane />}
						fullWidth
						size="large"
						variant="contained"
						onClick={() => setShowSendSummaryModal(true)}
					>
						Send summary and schedule
					</Button>
					<SpinnerButton
						className={styles.button}
						color="secondary"
						endIcon={<Calendar />}
						fullWidth
						size="large"
						variant="contained"
						onClick={handleClickDownloadICS}
						loading={icsLoading}
					>
						Save to calendar
					</SpinnerButton>
				</ButtonStack>
			)}
			{paymentType === PaymentType.SINGLE && (
				<ButtonStack className={styles.buttonStack} style={{ paddingBottom: spacingUnits(2) }}>
					<Button
						className={styles.button}
						color="secondary"
						endIcon={<PaperPlane />}
						fullWidth
						size="large"
						variant="contained"
						onClick={() => setShowSendSummaryModal(true)}
					>
						Send summary
					</Button>
					<SpinnerButton
						className={styles.button}
						color="secondary"
						endIcon={<Calendar />}
						fullWidth
						size="large"
						variant="contained"
						onClick={handleClickDownloadICS}
						loading={icsLoading}
					>
						Save to calendar
					</SpinnerButton>
				</ButtonStack>
			)}
		</>
	);

	const directDebitCTA = (
		<>
			{paymentType === PaymentType.SINGLE && (
				<AssistantCTAHollow
					subtitle="Your direct debit has been set up, the funds may take up to 5 days to transfer."
					title="Thanks for setting up a direct debit"
				/>
			)}
			{paymentType === PaymentType.ARRANGEMENT && (
				<AssistantCTAHollow
					subtitle={
						nextScheduledDate &&
						`You’ve successfully set up a recurring payment plan. Your first payment will be on the ${format(
							parseISO(nextScheduledDate),
							dateFormats.prettyShort,
						)}. To prevent any issues from occurring please make sure your account has the correct amount of funds on the due date.`
					}
					title="You’re all set!"
				/>
			)}
		</>
	);

	const directDebit = (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					{directDebitCTA}
					{buttons}
					<Typography className={styles.text} variant="body1">
						This transaction will appear on your statement as{' '}
						<Typography className={styles.strong} component="span" variant="body1">
							<strong>{window.config.REACT_APP_COMPANY}</strong>
						</Typography>
					</Typography>
				</Card>
			</Hidden>
			<Hidden mdUp>
				{directDebitCTA}
				{buttons}
				<Typography className={styles.text} variant="body1">
					This transaction will appear on your statement as{' '}
					<Typography className={styles.strong} component="span" variant="body1">
						<strong>{window.config.REACT_APP_COMPANY}</strong>
					</Typography>
				</Typography>
			</Hidden>
		</>
	);

	const creditCardCTA = (
		<>
			{paymentType === PaymentType.SINGLE && (
				<AssistantCTAHollow subtitle="Your credit card payment has been set up." title="Thanks for setting up a credit card payment" />
			)}
			{paymentType === PaymentType.ARRANGEMENT && (
				<AssistantCTAHollow
					subtitle={
						nextScheduledDate &&
						`You’ve successfully set up a recurring payment plan.
				Your first payment will be on the ${format(parseISO(nextScheduledDate), dateFormats.prettyShort)}.
				To prevent any issues from occurring please make sure your account has the correct amount of funds on the due date.`
					}
					title="You’re all set!"
				/>
			)}
		</>
	);

	const creditCard = (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					{creditCardCTA}
					{buttons}
					<Typography className={styles.text} variant="body1">
						This transaction will appear on your statement as{' '}
						<Typography className={styles.strong} component="span" variant="body1">
							<strong>{window.config.REACT_APP_COMPANY}</strong>
						</Typography>
					</Typography>
				</Card>
			</Hidden>
			<Hidden mdUp>
				{creditCardCTA}
				{buttons}
				<Typography className={styles.text} variant="body1">
					This transaction will appear on your statement as{' '}
					<Typography className={styles.strong} component="span" variant="body1">
						<strong>{window.config.REACT_APP_COMPANY}</strong>
					</Typography>
				</Typography>
			</Hidden>
		</>
	);

	const bPay = (
		<>
			<CTAHeadings subtitle="Thank you for setting an agreement" title="Your BPAY payment details" />
			<Card className={styles.card} elevation={8}>
				<BpayHorizontal className={styles.bpay} />
				<Typography className={styles.sectionTitle} variant="subtitle2">
					Biller code
				</Typography>
				<Typography className={styles.sectionBody} variant="subtitle2">
					{billerCode}
				</Typography>
				<Typography className={styles.sectionTitle} variant="subtitle2">
					BPAY reference
				</Typography>
				<Typography className={styles.sectionBody} variant="subtitle2">
					{paymentRef}
				</Typography>
				<Typography className={styles.sectionTitle} variant="subtitle2">
					{paymentType === PaymentType.SINGLE ? 'Due' : 'First payment'}
				</Typography>
				<Typography className={styles.sectionBody} variant="subtitle2">
					{formatMoney(parseFloat(paymentAmount ?? ''))} due {format(parseISO(paymentDate ?? ''), dateFormats.prettyShort)}
				</Typography>
				{buttons}
				<Typography className={styles.body} variant="body1">
					Pay through your internet banking provider using our biller code and your unique BPAY reference number.
				</Typography>
			</Card>
		</>
	);

	return (
		<>
			<Snackbar open={!!errorSnackbar} autoHideDuration={6000}>
				<Alert variant="filled" severity="error" onClose={() => setErrorSnackbar(undefined)}>
					{errorSnackbar}
				</Alert>
			</Snackbar>
			{loading && <DashboardLoader />}
			{!loading && (
				<div className={styles.root}>
					{paymentMethod?.Name === AvailablePaymentMethod.BPAY && bPay}
					{paymentMethod?.Name === AvailablePaymentMethod.CARD && creditCard}
					{paymentMethod?.Name === AvailablePaymentMethod.DIRECT && directDebit}
					<ButtonStack className={screenAtDesktopWidth ? styles.desktopButtons : undefined}>
						<Button color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
							Go to account overview
						</Button>
					</ButtonStack>
					{showSendSummaryModal && (
						<SummaryLockedModal
							buttonText="Send details"
							onClose={() => setShowSendSummaryModal(false)}
							onContinue={() => {
								setShowSendSummaryModal(false);
								window.scrollTo(0, 0);
								dispatch(resetArrangementSummary());
							}}
							subtitle="Pick an existing contact method."
							title="Where would you like to send your summary and schedule?"
						/>
					)}
				</div>
			)}
		</>
	);
};
