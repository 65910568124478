import React from 'react';

export const LegalServices = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 128 128" {...props}>
		<path fill="none" stroke="#c00" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M33.44 5.81v24.84l6.16-4.87 6 4.87V5.81" />
		<path
			d="M30.2 5.81A7.23 7.23 0 0023 13v102.49a7.23 7.23 0 017.23-7.22H105V5.81z"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path
			d="M23 115.49h0a6.7 6.7 0 006.7 6.7h69.43v-13.92M36.98 50.12v21.06h9.42M53.74 71.18l6.42-21.06 6.42 21.06M56.1 63.44h7.98M91.03 50.12l-4.25 21.06-5.04-21.06-5.04 21.06-4.25-21.06"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={3}
		/>
		<path d="M99 45.49V11.81H55l16.63 4.76A36.35 36.35 0 0194.54 36z" fill="#fcc" />
	</svg>
);
