import { makeStyles, Theme } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../theme/theme';

export interface StyleProps {
	color: 'default' | 'primary';
}

export default makeStyles<Theme, StyleProps>(
	{
		avatar: ({ color }) => ({
			color: getThemeStyle('paletteSecondary900'),
			backgroundColor: 'transparent',
			fontWeight: '500',
			fontSize: '3.5rem',

			...(color === 'primary' && {
				backgroundColor: getThemeStyle('palettePrimary900'),
				color: getThemeStyle('paletteCommonWhite'),
			}),
		}),
		logoImage: {
			padding: spacingUnits(1),
		},
	},
	{ name: 'debtBrandLogo' },
);
