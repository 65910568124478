import { CardType, PaymentFrequency, PaymentMode, PaymentType } from '../../../enums/paymentForm';
import { ARLResponse } from '../../../interceptor/base.api.response';
import { IGetPaymentMethods } from '../paymentMethod/types';

export enum PaymentAPI {
	QUICK_PAY = '/api/quickpay',
	CHECK_FIRST = '/api/arrangement/check/first',
	CHECK_SECOND = '/api/arrangement/check/second',
	CHECK_LOW = '/api/arrangement/check/low',
	PAY_NOW = '/api/paynow',
	CREDITCARD = '/api/arrangement/ccpa',
	BPAY = '/api/arrangement/bpay',
	DIRECTDEBIT = '/api/arrangement/ddpa',
}

export type MinAmounts = {
	[key in PaymentFrequency]: number | undefined;
};

export interface IArrangementCheck {
	ResultID: number;
	ResultDesc: string;
}

export interface IArrangementCheck1 extends IArrangementCheck {
	SuggestedWeekly: number;
	SuggestedFortnightly: number;
	SuggestedMonthly: number;
}

export interface IArrangementLow extends IArrangementCheck {
	MinimumWeekly: number;
	MinimumFortnightly: number;
	MinimumMonthly: number;
}

export type IGetArrangementCheck1 = ARLResponse<{ amounts: IArrangementCheck1 }>;

export type IGetArrangementCheck2 = ARLResponse;

export type IGetArrangementCheckLow = ARLResponse<{ amounts: IArrangementLow }>;

export interface ICard {
	cardTypeID?: CardType;
	cardNumber: string;
	encryptedCardNumber: string;
	expiryDate: string;
	cvv: string;
	nameOnCard: string;
}

export interface IDirectDebit {
	bsb: string;
	accountNumber: string;
	suffix: string;
	accountName: string;
}

export interface IQuickPayInput {
	amount: number;
	cardNumber: string;
	expiryDate: string;
	nameOnCard: string;
	cvv: string;
}

export interface IPaymentSuccess {
	paymentMode: PaymentMode;
	paymentMethod: IGetPaymentMethods | undefined;
	paymentDate: string;
	paymentAmount: string;
	paymentType: PaymentType | undefined;
	paymentCard?: ICard;
	arrangement?: ICreateArrangementResponse;
	payNow?: ITransactionInsertOutput;
	inArrangement?: boolean;
}

export interface IPaymentFailure {
	errors: string[];
	paymentAmount: string;
}

export interface BaseArrangement {
	amount: number | undefined;
	startDate: string | undefined;
	frequency: PaymentFrequency | undefined;
}

export interface CreditCardArrangement extends BaseArrangement {
	encryptedCard: string | undefined;
	cardExpiry: string | undefined;
	cardHolderName: string | undefined;
}

export type BPAY = BaseArrangement;

export interface DirectDebit extends BaseArrangement {
	bsb: string;
	accountNumber: string;
	accountName: string;
	suffix: string;
}

export interface ICreateArrangementResponse {
	ResultID: number;
	ResultDesc: string;
	FirstPaymentDate: string;
	LastPaymentDate: string;
	PaymentCount: number;
	paID: number;
}

export type ICreateArrangement = ARLResponse<{ arrangement: ICreateArrangementResponse }>;

export interface PayNow {
	amount: number;
	encryptedCard: string;
	encryptedCvn: string;
	cardExpiry: string;
	cardHolderName: string;
}

export interface ITransactionInsertOutput {
	ResultID: number;
	transactionID: number;
	ResultDesc: string;
}

export interface IPayNowOutput extends ITransactionInsertOutput {
	errors?: string[];
}

export type IPayNow = ARLResponse<{ transaction?: IPayNowOutput; totalAmount: number }>;
