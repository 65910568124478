import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React from 'react';
import { getThemeStyle } from '../../theme/theme';
import useStyles from './spinnerButton.styles';

type SpinnerButtonProps = {
	loading: boolean;
};

export const SpinnerButton: React.FC<SpinnerButtonProps & ButtonProps> = ({ loading, children, ...props }) => {
	const styles = useStyles({ color: props.color, variant: props.variant });
	return (
		<Button {...props} disabled={props.disabled || loading}>
			{children}
			{loading && <CircularProgress className={styles.buttonProgress} size={getThemeStyle('typographyButtonFontSize')} />}
		</Button>
	);
};
