import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
	{
		root: {
			'& $brandmark': {
				width: 360,
				top: -80,
				left: '50%',
				right: 'unset',
				transform: 'translate(-50%, -50%)',
			},
			'& $assistantAvatar': {
				height: 87,
				width: 87,
			},
		},
		brandmark: {},
		assistantAvatar: {},
	},
	{ name: 'otherOptionsLockedModal' },
);
