import { makeStyles, Theme } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../theme/theme';

export interface StyleProps {
	size: string;
	dynamicFontSize: boolean;
	charsLength: number;
}

const baseRoot = 4;
const baseRootSmall = 2.6;
const baseSpan = 2.4;
const baseSub = 2.5;
const baseSmallFont = 1.6;
const resizeCharLength = 5;

export default makeStyles<Theme, StyleProps>(
	{
		root: ({ size, dynamicFontSize, charsLength }) => ({
			paddingLeft: spacingUnits(2),
			position: 'relative',
			width: 'max-content',
			marginBottom: spacingUnits(1),

			...(dynamicFontSize &&
				charsLength > resizeCharLength && {
					fontSize: `${baseRoot - (charsLength - resizeCharLength) * 0.3}rem`,
				}),

			...(size === 'small' && {
				fontSize: `${baseRootSmall}rem`,
				fontWeight: 500,
				lineHeight: 1,
				paddingLeft: spacingUnits(1.5),
				letterSpacing: 0.25,

				...(dynamicFontSize &&
					charsLength > resizeCharLength && {
						fontSize: `${baseRootSmall - (charsLength - resizeCharLength) * 0.1}rem`,
					}),
			}),

			'& > span': {
				position: 'absolute',
				top: 0,
				left: 0,
				fontWeight: 'bold',
				fontSize: `${baseSpan}rem`,
				lineHeight: '3.1rem',
				color: getThemeStyle('palettePrimaryA700'),

				...(dynamicFontSize &&
					charsLength > resizeCharLength && {
						fontSize: `${baseSpan - (charsLength - resizeCharLength) * 0.1}rem`,
					}),

				...(size === 'small' && {
					fontSize: `${baseSmallFont}rem`,
					lineHeight: 1.25,
					...(dynamicFontSize &&
						charsLength > resizeCharLength && {
							fontSize: `${baseSmallFont - (charsLength - resizeCharLength) * 0.1}rem`,
						}),
				}),
			},

			'& > sub': {
				display: 'inline-block',
				fontSize: `${baseSub}rem`,
				lineHeight: 1,
				marginLeft: 1,
				position: 'relative',
				top: -spacingUnits(1),
				marginBottom: -spacingUnits(1),

				...(dynamicFontSize &&
					charsLength > resizeCharLength && {
						top: -5,
						fontSize: `${baseSub - (charsLength - resizeCharLength) * 0.2}rem`,
					}),

				...(size === 'small' && {
					fontSize: `${baseSmallFont}rem`,
					top: -5,
					...(dynamicFontSize &&
						charsLength > resizeCharLength && {
							fontSize: `${baseSmallFont - (charsLength - resizeCharLength) * 0.1}rem`,
						}),
				}),
			},
		}),
	},
	{ name: 'amountPresenter' },
);
