/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailablePaymentMethod } from '../../../enums/paymentForm';
import { IGetPaymentMethods } from './types';

const initialMethods: IGetPaymentMethods[] = [
	{
		Name: AvailablePaymentMethod.BPAY,
		PaymentMethodID: 1,
		AbbreviatedName: 'BPay',
	},
	{
		Name: AvailablePaymentMethod.CARD,
		PaymentMethodID: 2,
		AbbreviatedName: 'CC',
	},
	{
		Name: AvailablePaymentMethod.DIRECT,
		PaymentMethodID: 3,
		AbbreviatedName: 'Direct Debit',
	},
];

export interface PaymentMethodState {
	methods: IGetPaymentMethods[];
	ccPIFOnly: boolean;
	ccPayNowOnly: boolean;
}

const initialState: PaymentMethodState = {
	methods: initialMethods,
	ccPIFOnly: false,
	ccPayNowOnly: false,
};

export const paymentMethodSlice = createSlice({
	name: 'paymentMethod',
	initialState,
	reducers: {
		updateCustomerMethods(state, action: PayloadAction<string>) {
			const customerMethodsArray = action.payload.split(',');
			state.methods = initialMethods.filter((method) => {
				if (method.Name === AvailablePaymentMethod.BPAY && customerMethodsArray.includes('bpay')) {
					return true;
				}
				if (method.Name === AvailablePaymentMethod.CARD && customerMethodsArray.includes('creditcard')) {
					return true;
				}
				if (method.Name === AvailablePaymentMethod.DIRECT && customerMethodsArray.includes('directdebit')) {
					return true;
				}
				return false;
			});
			state.ccPIFOnly = state.methods.length === 0;
			state.ccPayNowOnly = !state.methods.some((method) => method.Name === AvailablePaymentMethod.CARD);
		},
	},
});

export const { updateCustomerMethods } = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
