import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { RootState } from '../../../../../../../store/rootReducer';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { DebtDetailsCard } from '../../../../DebtDetailsPanel/DebtDetailsCard/debtDetailsCard';
import { spacingUnits } from '../../../../../../../theme/theme';
import { AccountRoutes } from '../../../../../../../enums/routerPath';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { payNowWithAmount } from '../../../../../../../store/features/paymentForm/paymentFormSlice';
import { formatMoney } from '../../../../../../../services/helpers/text.helpers';
import { PaymentPlanButton } from '../../../../PaymentPlanButton/paymentPlanButton';

export const MakeQuickPaymentLanding: React.FC = () => {
	const dispatch = useDispatch();
	const { displayNameShort, lastAmount, outstanding } = useSelector(
		(state: RootState) => ({
			displayNameShort: state.auth.user?.displayNameShort || '',
			lastAmount: state.customer.customerDetails?.lastPayAmt || 0,
			outstanding: state.customer.customerDetails?.outstandingAmt || 0,
		}),
		shallowEqual,
	);

	const quickPayAmount = lastAmount > outstanding ? outstanding : lastAmount;

	return (
		<>
			<AssistantCTAHollow
				subtitle={`${displayNameShort} you previously processed a payment of ${formatMoney(
					lastAmount,
				)}. Would you like to repeat this payment? We’ve got it set up for you already.`}
				title="Keep your momentum going"
			/>

			<DebtDetailsCard size="small" />

			<ButtonStack style={{ marginTop: spacingUnits(4) }}>
				<Button
					color="secondary"
					fullWidth
					size="large"
					variant="contained"
					onClick={() => dispatch(payNowWithAmount(quickPayAmount.toString()))}
				>
					Make a quick payment
				</Button>
				<PaymentPlanButton />
				<Button color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
					Show me my account
				</Button>
			</ButtonStack>
		</>
	);
};
