import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			display: 'flex',

			'& $button': {
				flexGrow: 1,
				borderRadius: 0,
				borderLeftWidth: 0,

				'@global .MuiPaper-root': {
					borderRadius: 0,
					borderLeftWidth: 0,
				},
			},
			'&$rootsize-large $button:first-child:not(:last-child)': {
				'@global .MuiPaper-root': {
					borderRadius: `${getThemeStyle('shapeBorderRadius')}px 0 0 ${getThemeStyle('shapeBorderRadius')}px`,
					borderLeftWidth: 1,
				},
			},
			'&$rootsize-large $button:last-child:not(:first-child)': {
				'@global .MuiPaper-root': {
					borderRadius: `0 ${getThemeStyle('shapeBorderRadius')}px ${getThemeStyle('shapeBorderRadius')}px 0`,
				},
			},
			'&$rootsize-large $button:last-child:first-child': {
				'@global .MuiPaper-root': {
					borderRadius: `${getThemeStyle('shapeBorderRadius')}px ${getThemeStyle('shapeBorderRadius')}px ${getThemeStyle(
						'shapeBorderRadius',
					)}px ${getThemeStyle('shapeBorderRadius')}px`,
				},
			},
			'&$rootsize-small $button:first-child:not(:last-child)': {
				borderRadius: [[spacingUnits(1), 0, 0, spacingUnits(1)]],
				borderLeftWidth: 1,
			},
			'&$rootsize-small $button:last-child:not(:first-child)': {
				borderRadius: [[0, spacingUnits(1), spacingUnits(1), 0]],
			},
			'&$rootsize-small $button:last-child:first-child': {
				borderRadius: [[spacingUnits(1), spacingUnits(1), spacingUnits(1), spacingUnits(1)]],
			},
		},
		button: {},
		'rootsize-large': {},
		'rootsize-small': {},
	},
	{ name: 'buttonGroup' },
);
