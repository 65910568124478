import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './industries.styles';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { BusinessHeader } from '../../elements/Header/header';
import { BusinessFooter } from '../../elements/Footer/footer';
import { HeroTitle } from '../../../Customer/elements/HeroTitle/heroTitle';

export const Industries: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<BusinessHeader className={styles.greyHeader} />
			<HeroTitle
				className={styles.greyHero}
				title="Industry sectors we service"
				subtitle="ARL has a number of long-standing relationships with clients across a range of industries, including but not limited to Banking and Finance, Utilities, Government, Telecommunications, Insurance and Commercial sectors. Our services can be tailored entirely to the needs of your business."
				maxWidth={960}
			/>
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<Typography className={styles.underline} variant="h4">
						Banking and Finance
					</Typography>
					<Typography variant="body1">
						ARL has a number of long-standing relationships with the Banking and Finance sector across Australia, having established and
						maintained strong relationships with clients through our performance and service. We have a specialist division focused on the
						Banking and Finance sector, which manages a large volume of related products. This includes cards, personal and home loans,
						and transaction accounts, from both consumer and commercial customers both domestically and worldwide.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Utilities
					</Typography>
					<Typography variant="body1">
						ARL has earned a strong reputation with organisations in the utilities sector for successfully managing their debt recovery
						requirements, with services that add value by improving business processes and results.
					</Typography>
					<Typography variant="body1">
						Our leading technology and analysis techniques determine appropriate collection strategies and maximise results for these
						consumer clients.
					</Typography>
					<Typography variant="h6">Water sector</Typography>
					<Typography variant="body1">
						ARL’s solutions work successfully as an extension of the water organisations' office, evidenced by our current relationships
						with both metropolitan and regional water companies across Australia. ARL has worked closely with these clients to establish
						processes that meet and exceed the minimum requirements set out in state-based legislation and regulation. With a solid
						understanding of the culture and customer service expectations water companies desire, along with strict compliance and
						training to ensure potential hardship cases or disputes are identified early in the collection process, ARL works in an
						efficient manner to protect your brand and enhance your customer interface.
					</Typography>
					<Typography variant="h6">Energy sector</Typography>
					<Typography variant="body1">
						With a strong track record partnering with a number of the largest energy companies in Australia, ARL has a sound
						understanding of the role of industry specific regulators at a state and federal level. Our specialist utilities division has
						regular training sessions on relevant industry topics such as recognising and dealing with hardship, engaging with the
						customer and negotiating successful payment arrangements.
					</Typography>
					<Typography variant="h6">Additional utilities sector services</Typography>
					<Typography variant="body1">
						<strong>Site visits</strong> – ARL has access to a team of field agents and can arrange for site visits to customers’ premises
						to conduct interviews, identify possible hardship cases, engage the customer at an earlier stage in the process and where
						required, fit water restrictors or deliver disconnection notices. This service is provided nationally.
					</Typography>
					<Typography variant="body1">
						<strong>Reminder call/letter campaigns</strong> – ARL conducts first party campaigns to improve cash flow at an earlier stage
						in the collection cycle. Due to employing the latest in predictive dialler technology, ARL can service very high volumes of
						accounts quickly and efficiently with impressive reporting capabilities. All results can be provided to clients in a format
						that ensures easy upload into their system.
					</Typography>
					<Typography variant="body1">
						<strong>Disconnection Campaigns</strong> – ARL conduct these campaigns on ‘active’ debt for energy clients prior to
						disconnection of a customers’ service.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Telecommunications
					</Typography>
					<Typography variant="body1">ARL has a specialist collection team for the telecommunication industry.</Typography>
					<Typography variant="body1">
						Since ARL’s inception, we have been helping communications companies streamline internal processes, reduce customer churn, cut
						costs, focus on service, and collect their unpaid accounts. ARL provides first and third-party collection services to the
						telecommunications industry across Australia.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Insurance
					</Typography>
					<Typography variant="body1">
						Since the company's inception, our depth of experience with various clients in this industry has given us the opportunity to
						develop and refine our service offering to this sector.
					</Typography>
					<Typography variant="body1">
						ARL has considerable exposure and proven management experience in dealing with Third Party at Fault Motor Vehicle and Property
						Claim Recoveries, enabling us to earn a respected reputation with numerous major insurers for successfully managing their debt
						recovery and legal requirements.
					</Typography>
					<Typography variant="body1">
						ARL can provide a total debt recovery solution inclusive of loss recovery, skip tracing, field calls, instalment collection
						and litigation. Our specialist team are equipped to handle claims management, and quantum and liability issues, utilising
						customer searching technology and the allocation of field call and process serving with our accredited field agents if
						required. We also offer tailored reporting to your requirements and online, multi-user access to your accounts. ARL is
						committed to ensuring all activities comply with all applicable legislation and industry guidelines and the General Insurance
						Code of Practice.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Commercial
					</Typography>
					<Typography variant="body1">
						ARL is in a strong position to successfully collect commercial debt, with experienced personnel that has a real understanding
						and working knowledge of dealing with business related debt.
					</Typography>
					<Typography variant="body1">
						Our dedicated commercial team will try all avenues to get the debt paid prior to legal action, but if field and legal services
						are required, ARL is able to provide the full spectrum of services. Working closely with trusted and experienced third
						parties, ARL can provide field services and process serving for large commercial companies to small/medium sized enterprises,
						with a solution to maximise results for all.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Government
					</Typography>
					<Typography variant="body1">
						ARL Collect Pty Ltd (ARL) is an industry leading provider of solutions in the receivables management sector with extensive
						Government relationships spanning Local, State and Federal Government Departments and Agencies.
					</Typography>
					<Typography variant="body1">
						With a strong focus on compliance and adherence to industry related legislation and regulation, ARL understands that
						Government organisations operate within a very sensitive environment and that a customer centric approach is key to being a
						successful outsourcing partner in this sector.
					</Typography>
					<Typography variant="body1">
						Strong and sustainable government finances are essential to the long-term health of the Federal, State and Local economies.
						Designed to enhance traditional government operating principles and practices, an alliance with ARL enables governments to
						reduce their operating costs and ensure that specialist knowledge and expertise is applied directly where it is needed. By
						collecting overdue funds owed for land tax, payroll, council rates, infringements, toll fines and other taxes and charges, ARL
						plays a vital role for government and the broader community. The strong focus on compliance and quality ensures that
						safeguards are in place that provides a debt recovery service aligned to the sensitive nature of Government collections.
					</Typography>
					<Typography variant="body1">
						Our tailored Government service offering is founded on a vast repository of debt management experience, years of government
						interaction, sophisticated technologies, quality and compliance, and operational scalability that meets government needs in an
						ever-changing environment.
					</Typography>
					<Typography variant="body1">
						Long standing relationships are held with Government bodies such as:
						<ul>
							<li>State Revenue Office</li>
							<li>Public Housing Authorities</li>
							<li>Metropolitan Fire Services</li>
							<li>Departments of Education and Training</li>
							<li>Workcover Authorities</li>
							<li>Government Hospitals</li>
							<li>Metropolitan and Regional Water Boards</li>
							<li>State owned Electricity Companies</li>
						</ul>
					</Typography>
				</Spacing>
			</Container>
			<BusinessFooter />
		</Box>
	);
};
