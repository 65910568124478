import { Typography } from '@material-ui/core';
import useStyles from './footer.styles';

export const Footer = () => {
	const styles = useStyles();
	return (
		<footer className={styles.root}>
			<Typography component="span" variant="caption" className={styles.copyright}>
				&copy; {new Date().getFullYear()} {window.config.REACT_APP_COMPANY_FULL}
			</Typography>
		</footer>
	);
};
