import { makeStyles } from '@material-ui/core/styles';
import { fontFamilyText, getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		cardDisplay: {
			padding: [[spacingUnits(2)]],
			marginBottom: spacingUnits(1),
		},
		customerName: {
			fontFamily: getThemeStyle('typographyFontFamily'),
			fontWeight: 500,
			marginBottom: spacingUnits(1),
		},
		currencyDisplay: {
			fontWeight: 500,
			fontFamily: fontFamilyText,
			fontSize: '2.6rem',
			lineHeight: '2.2rem',
			padding: [[spacingUnits(0.5), 0]],
		},
		icon: {
			height: 40,
			width: 40,
		},
	},
	{ name: 'summaryTab' },
);
