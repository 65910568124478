import { makeStyles } from '@material-ui/core/styles';
import MuiTheme from '../../../../../../theme';

import { spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		card: {
			display: 'inline-flex',
			margin: [[0, 'auto']],
			maxWidth: 616,
			minWidth: 'auto',
			padding: [[spacingUnits(6), spacingUnits(8), spacingUnits(7)]],
			width: '100%',
		},

		image: {
			height: '100%',
		},

		content: {
			'& > div:first-of-type': {
				marginBottom: spacingUnits(4),

				'& > p': {
					margin: [[0, 'auto']],
					maxWidth: 488,
				},
			},

			'& > div:last-of-type': {
				margin: [[0, 'auto']],
				width: '100%',
			},
		},
		comparisonCard: {
			marginTop: spacingUnits(2),
		},
		[MuiTheme.breakpoints.up('md')]: {
			comparisonCard: {
				width: '90%',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		offerLength: {
			marginTop: spacingUnits(3),
		},
		[MuiTheme.breakpoints.down('sm')]: {
			offerLength: {
				marginTop: spacingUnits(1),
			},
		},
	},
	{ name: 'lowValueOffer' },
);
