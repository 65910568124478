import { Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './spacing.styles';

type SpacingProps = Omit<BoxProps, 'className'> & {
	className?: string;
	orientation?: 'horizontal' | 'vertical';
	units: number;
};

export const Spacing: React.FC<SpacingProps> = ({ children, orientation = 'vertical', units, ...props }) => {
	const styles = useStyles({ orientation, units });
	return (
		<Box {...props} className={clsx(styles.root, props.className)}>
			{children}
		</Box>
	);
};
