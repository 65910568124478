import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PantheraBreadcrumbs } from '../../../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { SupportRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { FAQ } from './elements/FAQ/faq';
import useStyles from './supportFAQ.styles';

const breadcrumbs = [
	{
		title: 'Help',
		LinkProps: {
			to: WebRoutes.SUPPORT.path,
		},
	},
	{
		title: 'FAQs',
		LinkProps: {
			to: SupportRoutes.FAQ.path,
		},
	},
];

export const SupportFAQ: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<main className={styles.container}>
			<Spacing units={3}>
				<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
				<div>
					<Typography className={styles.title} variant="h4">
						FAQs
					</Typography>
					<FAQ mt={2} />
				</div>
			</Spacing>
		</main>
	);
};
