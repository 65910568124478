import { Button } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ContactPhone } from '../../../../../../../elements/ContactPhone/contactPhoneNumber';
import { logout } from '../../../../../../../store/features/auth/authSlice';
import { RootState } from '../../../../../../../store/rootReducer';
import { ContactButtons } from '../../../../ContactButtons/contactButtons';
import { DebtDetailsCard } from '../../../../DebtDetailsPanel/DebtDetailsCard/debtDetailsCard';

export const PendingAccountClosure: React.FC = () => {
	const dispatch = useDispatch();
	const { name } = useSelector(
		(state: RootState) => ({
			name: state.auth.user?.displayNameShort ?? '',
		}),
		shallowEqual,
	);

	return (
		<>
			<AssistantCTAHollow
				subtitle={
					<>
						{name}, we are currently checking the status of your account before it is closed. Please check back in a few days or give us a
						call on <strong>{ContactPhone()}</strong> for more information.
					</>
				}
				title="Account closure pending"
			/>
			<DebtDetailsCard size="small" />
			<ContactButtons />
			<Button onClick={() => dispatch(logout())} color="primary" fullWidth size="large" variant="outlined">
				Logout
			</Button>
		</>
	);
};
