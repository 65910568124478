import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../../../../../../theme/theme';

export default makeStyles(
	{
		root: {},
		paymentFailedCard: {
			display: 'flex',
			alignItems: 'center',
			padding: [[spacingUnits(2), spacingUnits(1)]],
			margin: [[spacingUnits(2), 0, spacingUnits(4)]],

			'& > div': {
				margin: [[0, spacingUnits(1)]],
			},
		},
		declinedInfo: {
			display: 'flex',
			flexFlow: 'column wrap',
			alignItems: 'flex-start',
		},
		declinedResponse: {
			fontSize: '1.25rem',
			fontWeight: 500,
			color: getThemeStyle('paletteErrorDark'),
		},
		declinedValue: {
			fontWeight: 500,
			fontSize: '2.75rem',
			margin: [[spacingUnits(0.5), 0]],
		},
		declinedCard: {
			'& svg': {
				height: 10,
				width: 31,
			},
		},
		buttonStack: {
			marginTop: '1rem!important',
		},
	},
	{ name: 'paymentFailedCard' },
);
