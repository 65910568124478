import { differenceInDays, parseISO } from 'date-fns';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/rootReducer';
import { DebtStatusLogicProps } from '../../types';
import { PendingAccountClosure } from '../PayingWorkflow/PendingClosure/pendingClosure';
import { ActiveGeneralDashboard } from './General/activeGeneralDashboard';
import { ActiveGeneralLanding } from './General/activeGeneralLanding';
import { MakeQuickPaymentDashboard } from './MakeQuickPayment/makeQuickPaymentDashboard';
import { MakeQuickPaymentLanding } from './MakeQuickPayment/makeQuickPaymentLanding';

export const ActiveWorkflow: React.FC<DebtStatusLogicProps> = ({ isDashboard }) => {
	const { customerDetails, ccPIFOnly, outstandingAmount, ccAllowed } = useSelector(
		(state: RootState) => ({
			customerDetails: state.customer.customerDetails,
			ccPIFOnly: state.paymentMethod.ccPIFOnly,
			outstandingAmount: state.customer.customerDetails?.outstandingAmt ?? 0,
			ccAllowed: state.customer.customerDetails?.IsExtranet_DebtorAllowCreditCardPayment,
		}),
		shallowEqual,
	);

	const paymentOverYear: boolean = customerDetails?.lastPayDate
		? !!(differenceInDays(new Date(), parseISO(customerDetails?.lastPayDate)) > 365)
		: true;

	let content: JSX.Element | null = null;
	if (outstandingAmount <= 0) {
		content = <PendingAccountClosure />;
	} else if (!paymentOverYear && customerDetails?.lastPayAmt && !ccPIFOnly && ccAllowed) {
		content = isDashboard ? <MakeQuickPaymentDashboard /> : <MakeQuickPaymentLanding />;
	} else {
		content = isDashboard ? <ActiveGeneralDashboard /> : <ActiveGeneralLanding />;
	}

	return content;
};
