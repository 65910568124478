import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../theme';
import { getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',

			'& ul': {
				fontSize: getThemeStyle('typographyBody1FontSize'),
				lineHeight: getThemeStyle('typographyBody1LineHeight'),
				[MuiTheme.breakpoints.up('md')]: {
					fontSize: getThemeStyle('typographyBody1DesktopFontSize'),
					lineHeight: getThemeStyle('typographyBody1DesktopLineHeight'),
				},
			},
		},
		greyHeader: {
			backgroundColor: subtleBackgroundColor,
		},
		row: {
			padding: [[spacingUnits(7), spacingUnits(2.75), spacingUnits(12)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},
		},
	},
	{
		name: 'privacy',
	},
);
