import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Lifesaver from '../../../../static/images/marketing/lifesaver.png';
import { getThemeStyle, spacingUnits } from '../../../../theme/theme';

export const covidBannerHeight = 40;

const useStyles = makeStyles(
	(theme) => ({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minHeight: covidBannerHeight,
			backgroundColor: getThemeStyle('paletteGrey100'),
			padding: [[spacingUnits(1), spacingUnits(1.5)]],

			'& > img': {
				height: 20,
				width: 20,
			},

			'& > p': {
				fontSize: '1.6rem',
				lineHeight: '1.6rem',
				fontWeight: 500,
				marginLeft: spacingUnits(1),

				'& > a': {
					color: getThemeStyle('paletteSecondary500'),
					textDecoration: 'none',
				},

				[theme.breakpoints.down('sm')]: {
					fontSize: '1.3rem',
					lineHeight: '1.3rem',
				},

				[theme.breakpoints.down('xs')]: {
					textAlign: 'center',
					fontSize: '1.1rem',
					lineHeight: '1.3rem',
				},
			},
		},
	}),
	{ name: 'covidBanner' },
);

export const FrancomBanner: React.FC = () => {
	const styles = useStyles();
	return (
		<div className={styles.root}>
			{/* <img alt="Lifesaver" src={Lifesaver} />
			<Typography>
				If COVID-19 has affected you please contact our support team on ABC{' '}
				<a href={window.config.REACT_APP_CONTACT_SUPPORT_HREF}>{window.config.REACT_APP_CONTACT_SUPPORT}</a> to discuss.
			</Typography> */}
			{/* <img alt="Lifesaver" src={Lifesaver} /> */}
			<Typography>
				FRANCOM GROUP has acquired the assets of the Panthera Finance Group, including ARL.{' '}
				<a
					href="https://francomgroup.com/site/wp-content/uploads/2024/12/051224_Francom-Acquires-Panthera_FV.pdf"
					target="_blank"
					rel="noreferrer"
				>
					View Details
				</a>
			</Typography>
		</div>
	);
};
