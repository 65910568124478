import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../theme';
import { brandMexicanRed, getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';
import { headerHeight } from '../../elements/HeroTitle/heroTitle';

export default makeStyles(
	(theme) => ({
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',
		},
		greyHeader: {
			backgroundColor: subtleBackgroundColor,
		},
		row: {
			padding: [[spacingUnits(7), spacingUnits(2.75), spacingUnits(12)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},

			[theme.breakpoints.down('sm')]: {
				'& .MuiTypography-h1': {
					fontSize: '4rem',
					lineHeight: '4.2rem',
				},

				'& .MuiTypography-body1': {
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
				},
			},
		},
		heroText: {
			fontSize: '9rem',
			lineHeight: 1.3,
			fontWeight: 900,
			letterSpacing: 'normal',
			marginBottom: '7rem',
			[theme.breakpoints.down('md')]: {
				fontSize: '8rem',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '6rem',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '5rem',
			},
		},
		subText: {
			fontSize: '2.2rem',
			lineHeight: '3rem',
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
				lineHeight: '2.8rem',
			},
		},
		headerContainer: {
			backgroundColor: subtleBackgroundColor,
			paddingTop: spacingUnits(8),
			paddingBottom: spacingUnits(8) + headerHeight,

			[theme.breakpoints.down('md')]: {
				paddingTop: spacingUnits(5),
				paddingBottom: spacingUnits(5),
			},
		},
		underline: {
			'&:not(:first-of-type)': {
				marginTop: `${spacingUnits(8)}px !important`,
			},
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			textAlign: 'left',

			'&:after': {
				content: '""',
				borderBottom: `3px solid ${brandMexicanRed}`,
				paddingTop: 14,
				width: 80,
			},
		},
		img: {
			display: 'block',
			height: 'auto',
			maxWidth: '100%',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: `${spacingUnits(6)}px !important`,
			marginBottom: `${spacingUnits(6)}px !important`,
		},
	}),
	{
		name: 'aboutUs',
	},
);
