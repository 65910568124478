import clsx from 'clsx';
import React from 'react';

import { BoxProps } from '@material-ui/core';
import useStyles from './buttonGroup.styles';
import { ButtonOversizeProps } from '../ButtonOversize/buttonOversize';

type ButtonGroupProps = {
	className?: string;
	size?: 'small' | 'large';
};

export const ButtonGroup: React.FC<ButtonGroupProps & BoxProps> = ({ className, children, size = 'large', ...props }) => {
	const styles = useStyles();
	return (
		<div className={clsx(styles.root, styles[`rootsize-${size}` as keyof typeof styles], className)} {...props}>
			{React.Children.map(children as React.ReactElement<ButtonOversizeProps>, (child) =>
				React.cloneElement(child, {
					className: clsx(child.props.className, styles.button),
					elevation: 0,
				}),
			)}
		</div>
	);
};
