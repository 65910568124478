import { Box, Button, Card, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { AssistantCTAHollow } from '../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../elements/ButtonStack/buttonStack';
import { ComparisonCard } from '../../../../../../elements/ComparisonCard/comparisonCard';
import { PiggyBank, PiggyBankLarge } from '../../../../../../elements/PantheraIcon';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { PeriodicFrequencyID } from '../../../../../../enums/periodicFrequency';
import { calcSavings } from '../../../../../../services/helpers/calculation.helpers';
import { convertPaymentFreqToString, pluralize } from '../../../../../../services/helpers/text.helpers';
import useStyles from './lowValueOffer.styles';

type LowValueOfferProps = {
	outstanding: number;
	paymentFrequencyID: PeriodicFrequencyID;
	minPay: number;
	paymentAmount: string;
	onSetup: (acceptedAmount: number) => void;
	onCantAfford: () => void;
	onBack: () => void;
};

export const LowValueOffer: React.FC<LowValueOfferProps> = ({
	outstanding,
	paymentFrequencyID,
	minPay,
	paymentAmount,
	onSetup,
	onCantAfford,
	onBack,
}) => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));

	const offer = calcSavings(outstanding, parseFloat(paymentAmount), paymentFrequencyID, 0, minPay);

	const hollow = (
		<AssistantCTAHollow
			subtitle={
				<span>
					We understand that making a payment plan might be difficult.
					<br />
					We are willing to offer you a short-term plan to help you get started
				</span>
			}
			title="Your tailored plan"
			subtitleVariant={screenAtDesktopWidth ? 'body1' : 'body2'}
		/>
	);

	const comparisonCard = (
		<ComparisonCard
			className={styles.comparisonCard}
			items={[
				{
					amount: {
						value: offer ? offer.reofferAmt : minPay,
						description: convertPaymentFreqToString(paymentFrequencyID),
					},
					icon: <PiggyBankLarge />,
					identifier: {
						color: 'secondary',
						content: 'Short-term',
					},
					note: offer ? `Over ${pluralize(offer.reofferMonths, 'month', 's')}*` : undefined,
					specifications: [
						{
							content: 'Small payments',
							hasCheckmark: true,
						},
						{
							content: 'Simple set up',
							hasCheckmark: true,
						},
					],
				},
				{
					amount: {
						value: parseFloat(paymentAmount),
						description: convertPaymentFreqToString(paymentFrequencyID),
					},
					icon: <PiggyBank style={{ height: '80%' }} />,
					identifier: {
						color: 'primary',
						content: 'Entered amount',
					},
					note: offer ? `Over ${pluralize(offer.baseMonths, 'month', 's')}*` : undefined,
					specifications: [
						{
							content: 'Below',
						},
						{
							content: 'minimum',
						},
					],
				},
			]}
		/>
	);

	const Buttons = (
		<>
			<Button color="secondary" fullWidth size="large" variant="contained" onClick={() => onSetup(offer ? offer.reofferAmt : minPay)}>
				Set up payment plan
			</Button>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={onCantAfford}>
				I can't afford this amount
			</Button>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={onBack}>
				Back
			</Button>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					<div className={styles.content}>
						{hollow}
						{comparisonCard}
						{offer && (
							<Typography align="center" className={styles.offerLength} variant="body2">
								*Offer lengths may vary over time.
							</Typography>
						)}
						<Box pt={5}>
							<Spacing units={2}>{Buttons}</Spacing>
						</Box>
					</div>
				</Card>
			</Hidden>
			<Hidden mdUp>
				{hollow}
				{comparisonCard}
				{offer && (
					<Typography align="center" className={styles.offerLength} variant="body2">
						*Offer lengths may vary over time.
					</Typography>
				)}
				<ButtonStack>{Buttons}</ButtonStack>
			</Hidden>
		</>
	);
};
