/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../interceptor/api';
import { AppThunk } from '../../store';
import { TransactionAPI, IGetTransaction, ITransactionOutput } from './types';

export interface TransactionState {
	transactions: ITransactionOutput[] | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: TransactionState = {
	transactions: undefined,
	loading: false,
	error: undefined,
};

export const TransactionSlice = createSlice({
	name: 'Transaction',
	initialState,
	reducers: {
		getTransactions(state) {
			state.loading = true;
			state.transactions = undefined;
			state.error = undefined;
		},
		getTransactionsSuccess(state, action: PayloadAction<ITransactionOutput[]>) {
			state.loading = false;
			state.transactions = action.payload;
			state.error = undefined;
		},
		getTransactionsFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		resetTransactions() {
			return initialState;
		},
	},
});

export const { getTransactions, getTransactionsSuccess, getTransactionsFailure, resetTransactions } = TransactionSlice.actions;

export default TransactionSlice.reducer;

export const fetchTransactions = (): AppThunk => async (dispatch) => {
	try {
		dispatch(getTransactions());
		const { data } = await arlApi.get<IGetTransaction>(TransactionAPI.TRANSACTION);
		if (data.success) {
			dispatch(getTransactionsSuccess(data.data.payments));
		} else {
			dispatch(getTransactionsFailure(data.message));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(getTransactionsFailure(e.response?.data.message));
		} else {
			dispatch(getTransactionsFailure('An unknown error occured.'));
		}
	}
};
