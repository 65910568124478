import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

export const ContactPhone = (): string => {
	const loggedInNumber = useSelector((state: RootState) => state.customer.customerDetails?.contactPhone);
	return loggedInNumber || window.config.REACT_APP_CONTACT_NUMBER;
};

export const ContactPhoneHref = (): string => {
	const loggedInNumber = useSelector((state: RootState) => state.customer.customerDetails?.contactPhone);
	return loggedInNumber ? `tel: ${loggedInNumber.replace(/\s/g, '')}` : window.config.REACT_APP_CONTACT_NUMBER_HREF;
};
