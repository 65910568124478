import React from 'react';

export const PiggyBankColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<circle cx="38" cy="19" fill="#ffcccc" r="9" />
			<path
				d="M30.884 34.621h14.604v-3.226H30.884v3.226zM68.94 52.769h-6.123c-5.746 6.865-7.351 7.46-8.414 7.854-.289.107-.507.188-.761.391v6.926h-4.81l-1.536-4.607c-.154.028-.333.063-.535.102-1.378.266-3.684.713-6.678.713-4.916 0-8.446-1.129-10.142-1.672a34.326 34.326 0 00-.524-.165l-1.876 5.629h-4.81v-9.236c-.043-.035-.102-.079-.173-.135-6.127-4.761-7.413-10.062-7.413-13.67 0-7.945 6.472-15.179 16.105-18.001l.137-.04.114.086a10.984 10.984 0 006.686 2.246c2.763 0 5.414-1.026 7.462-2.891l.106-.096.141.028c4.3.852 7.173 2.344 8.399 2.981.153.079.283.146.389.199.07-.067.154-.151.253-.249.886-.883 2.73-2.721 5.955-3.329l.336-.063v9.104c2.68 3.938 2.804 4.537 2.924 5.116.043.211.085.411.297.832h4.491v11.947zM38.187 10.226c4.339 0 7.869 3.53 7.869 7.869 0 4.34-3.53 7.87-7.869 7.87-4.34 0-7.87-3.53-7.87-7.87 0-4.339 3.53-7.869 7.87-7.869zM66.7 37.597l-.076-.166c-.53-1.164-1.222-2.33-2.116-3.565l-.054-.074V22.425h-1.613c-3.135 0-6.101 1.037-8.578 2.999l-.136.108-.158-.072a33.472 33.472 0 00-5.679-1.996l-.342-.087.158-.315a10.963 10.963 0 001.176-4.967C49.282 11.977 44.305 7 38.187 7c-6.119 0-11.096 4.977-11.096 11.095 0 2.071.578 4.094 1.671 5.848l.186.299-.332.118c-6.63 2.349-11.805 6.592-14.572 11.949l-.154.298-.268-.201a6.018 6.018 0 01-2.396-4.782 5.98 5.98 0 015.689-5.967v-3.228C11.974 22.579 8 26.646 8 31.624a9.257 9.257 0 004.504 7.91l.188.112-.06.21a18.29 18.29 0 00-.713 5.043c0 5.685 2.659 11.104 7.488 15.261l.098.085v10.921h10.362l1.624-4.874.255.064a34.585 34.585 0 008.337 1.017 34.51 34.51 0 004.8-.343l.234-.032 1.389 4.168h10.362v-8.224l.137-.083c3.007-1.806 5.592-4.139 7.475-6.747l.085-.118h7.601V37.597H66.7zm-13.058 3.225h3.226v-3.226h-3.226v3.226z"
				fill="#cc0000"
			/>
		</g>
	</svg>
);
