import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../../../theme';
import { bodyContentMarketingMaxWidth, spacingUnits } from '../../../../../../theme/theme';

const container = {
	width: '100%',
	margin: [[0, 'auto']],
	maxWidth: bodyContentMarketingMaxWidth + spacingUnits(5.5),
};

export default makeStyles(
	{
		container: {
			...container,
			padding: [[spacingUnits(7), spacingUnits(2.75), spacingUnits(12)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},
		},
		title: {
			marginBottom: spacingUnits(2),
			[MuiTheme.breakpoints.up('md')]: {
				marginBottom: spacingUnits(4),
			},
		},
	},
	{
		name: 'faq',
	},
);
