import { Box, Button, Card, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { AssistantCTAHollow } from '../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../elements/ButtonStack/buttonStack';
import { ComparisonCard } from '../../../../../../elements/ComparisonCard/comparisonCard';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import useStyles from './almostThereOffer.styles';
import meterCorrect from '../../../../../../static/images/graphics/meter-correct.png';
import meterIncorrect from '../../../../../../static/images/graphics/meter-incorrect.png';
import { calcSavings } from '../../../../../../services/helpers/calculation.helpers';
import { PeriodicFrequencyID } from '../../../../../../enums/periodicFrequency';
import { convertPaymentFreqToString, formatMoney, pluralize } from '../../../../../../services/helpers/text.helpers';

type AlmostTherefferProps = {
	outstanding: number;
	paymentFrequencyID: PeriodicFrequencyID;
	minPay: number;
	paymentAmount: string;
	onSetup: (acceptedAmount: number) => void;
	onCantAfford: () => void;
	onBack: () => void;
};

export const AlmostThereOffer: React.FC<AlmostTherefferProps> = ({
	outstanding,
	paymentFrequencyID,
	minPay,
	paymentAmount,
	onSetup,
	onCantAfford,
	onBack,
}) => {
	const styles = useStyles();

	const offer = calcSavings(outstanding, parseFloat(paymentAmount), paymentFrequencyID, 0, minPay);

	const hollow = (
		<AssistantCTAHollow
			subtitle={`The minimum we suggest for your account is ${formatMoney(offer ? offer.reofferAmt : minPay)} ${convertPaymentFreqToString(
				paymentFrequencyID,
			)}`}
			title="Sorry, we can't accept that amount"
		/>
	);

	const comparisonCard = (
		<ComparisonCard
			className={styles.comparisonCard}
			items={[
				{
					amount: {
						value: offer ? offer.reofferAmt : minPay,
						description: convertPaymentFreqToString(paymentFrequencyID),
					},
					icon: <img alt="" className={styles.image} src={meterCorrect} />,
					identifier: {
						color: 'secondary',
						content: 'Minimum',
					},
					note: offer ? `Over ${pluralize(offer.reofferMonths, 'month', 's')}*` : undefined,
					specifications: [
						{
							content: 'Our offer',
						},
					],
				},
				{
					amount: {
						value: parseFloat(paymentAmount),
						description: convertPaymentFreqToString(paymentFrequencyID),
					},
					icon: <img alt="" className={styles.image} src={meterIncorrect} />,
					identifier: {
						color: 'primary',
						content: 'Below minimum',
					},
					note: offer ? `Over ${pluralize(offer.baseMonths, 'month', 's')}*` : undefined,
					specifications: [
						{
							content: 'Your offer',
						},
					],
				},
			]}
		/>
	);

	const Buttons = (
		<>
			<Button color="secondary" fullWidth size="large" variant="contained" onClick={() => onSetup(offer ? offer.reofferAmt : minPay)}>
				Increase to minimum
			</Button>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={onCantAfford}>
				Try another amount
			</Button>
			<Button color="primary" fullWidth size="large" variant="outlined" onClick={onBack}>
				Back
			</Button>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					<div className={styles.content}>
						{hollow}
						{comparisonCard}
						{offer && (
							<Typography align="center" className={styles.offerLength} variant="body2">
								*Offer lengths may vary over time.
							</Typography>
						)}
						<Box pt={5}>
							<Spacing units={2}>{Buttons}</Spacing>
						</Box>
					</div>
				</Card>
			</Hidden>
			<Hidden mdUp>
				{hollow}
				{comparisonCard}
				{offer && (
					<Typography align="center" className={styles.offerLength} variant="body2">
						*Offer lengths may vary over time.
					</Typography>
				)}
				<ButtonStack>{Buttons}</ButtonStack>
			</Hidden>
		</>
	);
};
