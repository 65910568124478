import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { PaymentRoutes } from '../../../../enums/routerPath';
import secureBadge from '../../../../static/images/branding/secure-badge.png';
import { setPaymentDirectDebit } from '../../../../store/features/paymentForm/paymentFormSlice';
import { IDirectDebit } from '../../../../store/features/paymentForm/types';
import { history } from '../../../../store/history';
import { MaskedPantheraInput } from '../../../PantheraInput/MaskedPantheraInput';
import { PantheraInput } from '../../../PantheraInput/PantheraInput';
import useStyles from './directDebitPaymentForm.styles';

type DirectDebitPaymentFormProps = {
	className?: string;
	autoFocus: boolean;
	paymentDirectDebit?: IDirectDebit;
};

export const DirectDebitPaymentForm: React.FC<DirectDebitPaymentFormProps> = ({ autoFocus, className, paymentDirectDebit }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	// TODO add api call for BSB validation
	const directDebitSchema = Yup.object().shape({
		bsb: Yup.string()
			.required('Enter a valid BSB number')
			.test('validateBSB', 'Invalid BSB number', (bsb) => /^\d{3}-\d{3}$/.test(bsb ?? '')),
		accountNumber: Yup.string()
			.required('Enter a valid account number')
			.matches(/^[0-9]+$/, 'Must be only digits')
			.min(3, 'Invalid account number Length')
			.max(9, 'Invalid account number Length'),
		accountName: Yup.string()
			.required('Enter a valid account name')
			.min(3, 'Invalid account name Length')
			.max(128, 'Invalid account name Length'),
	});

	const handleDirectDebitSubmit = (directDebit: IDirectDebit) => {
		dispatch(setPaymentDirectDebit(directDebit));
		history.push(PaymentRoutes.PAYMENT_AGREEMENT.path);
	};

	return (
		<div className={clsx(styles.root, className)}>
			<div className={styles.secureBadge} style={{ backgroundImage: `url(${secureBadge})` }} />
			<Formik
				initialValues={{
					bsb: paymentDirectDebit?.bsb ?? '',
					accountNumber: paymentDirectDebit?.accountNumber ?? '',
					accountName: paymentDirectDebit?.accountName ?? '',
					suffix: '',
				}}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={handleDirectDebitSubmit}
				validationSchema={directDebitSchema}
			>
				{(props: FormikProps<IDirectDebit>) => {
					const { errors, touched, values, setFieldValue, setFieldTouched } = props;
					return (
						<Form id="directDebitForm">
							<MaskedPantheraInput
								fullWidth
								errorMessage={errors.bsb}
								mask="000-000"
								placeholderChar="x"
								lazy={false}
								overwrite={true}
								value={values.bsb}
								InputProps={{
									name: 'bsb',
									id: 'bsb',
									type: 'tel',
									onBlur: () => setFieldTouched('bsb', true),
								}}
								onAccept={(value: string) => {
									setFieldValue('bsb', value);
								}}
								label="BSB"
								error={!!errors.bsb && !!touched.bsb}
							/>
							<PantheraInput
								fullWidth
								errorMessage={errors.accountNumber}
								InputProps={{
									type: 'number',
									inputMode: 'numeric',
									id: 'accountNumber',
									name: 'accountNumber',
									placeholder: 'Enter account number',
									maxLength: 9,
									value: values.accountNumber,
									onChange: (e) => {
										setFieldValue(e.currentTarget.name, e.currentTarget.value);
									},
									onBlur: (e) => setFieldTouched(e.currentTarget.name, true),
								}}
								label="Account number"
								error={!!errors.accountNumber && !!touched.accountNumber}
							/>
							<PantheraInput
								fullWidth
								errorMessage={errors.accountName}
								InputProps={{
									id: 'accountName',
									name: 'accountName',
									placeholder: 'Enter account name',
									maxLength: 128,
									value: values.accountName,
									onChange: (e) => {
										setFieldValue(e.currentTarget.name, e.currentTarget.value);
									},
									onBlur: (e) => setFieldTouched(e.currentTarget.name, true),
								}}
								label="Account name"
								error={!!errors.accountName && !!touched.accountName}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
