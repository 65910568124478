import { Box, Container, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import useStyles from './compliance.styles';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { BusinessHeader } from '../../elements/Header/header';
import { BusinessFooter } from '../../elements/Footer/footer';
import { HeroTitle } from '../../../Customer/elements/HeroTitle/heroTitle';
import { spacingUnits } from '../../../../theme/theme';
import { WebRoutes } from '../../../../enums/routerPath';

export const Compliance: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<BusinessHeader className={styles.greyHeader} />
			<HeroTitle
				className={styles.greyHero}
				title="Compliance"
				subtitle="When it comes to statutory compliance regimes, including those relating to Privacy and Trade Practices, ARL offers industry leading business practices and procedures."
				maxWidth={960}
			/>
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<Typography variant="body1" style={{ marginBottom: spacingUnits(8) }}>
						Our internal processes are designed to ensure your security and comfort. Detailed training and accreditation processes ensure
						ongoing compliance with all legislative requirements of Privacy, Trade Practices and Credit and Finance, and best practice
						standards. Policies and Procedures are under constant review through internal audits as part of our quality accreditation
						program, which monitors and measures adherence to legislative and regulatory requirements and industry codes of practice.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Code of Conduct
					</Typography>
					<Typography variant="body1">
						ARL's Code of Conduct sets out the minimum standards required of all of our people. The Code adopts the ASIC/ACCC Guidelines
						as standard operating practice, and compliance with the Code is a compulsory element of continued employment with ARL.
						Additionally, ARL is a member of the Australian Collectors and Debt Buyers Association and adheres to their{' '}
						<Link color="secondary" href="https://www.acdba.com/index.php/code-of-practice" target="_blank" rel="noreferrer">
							Code of Practice
						</Link>
						.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Professional Industry Association Memberships
					</Typography>
					<Typography variant="body1">
						ARL is committed to being a responsible corporate citizen of the communities in which it operates and is an active participant
						in business organisations at the local, regional and national level.
					</Typography>
					<Typography variant="body1">
						ARL maintains awareness of developments affecting the industry and makes a positive contribution to raising professional
						industry standards through the following organisations. All memberships listed below are current.
						<ul>
							<li>Australian Collectors & Debt Buyers Association (ACDBA)</li>
							<li>ACA International</li>
							<li>Australian Institute of Credit Management (AICM) - ARL is a Corporate member</li>
							<li>Institute Mercantile Agent (IMA)</li>
							<li>Credit Services Association UK</li>
							<li>Revenue Managers Association (RMA) - Victoria</li>
							<li>NSW Revenue Professionals Inc. (NSWRP) - New South Wales</li>
							<li>Local Government Professionals (LGPro) – Victoria</li>
						</ul>
					</Typography>
					<Typography className={styles.underline} variant="h4">
						ISO and PCI DSS Compliance
					</Typography>
					<Typography variant="body1">
						ARL is certified and operates a Quality Management System (ISO 9001:2015) and an Information Security Management System
						(ISO/IEC 27001:2013) and both systems are externally audited. ARL maintains Payment Card Industry Data Security Standard (PCI
						DSS) certification. Attestation of Compliance (AOC) can be provided upon request.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						IRAP Certified
					</Typography>
					<Typography variant="body1">
						IRAP is a key initiative that provides assurance that ICT systems meet the Government’s cyber security standards such as the
						Australian Government Information Security Manual (ISM) and provides a framework by which individuals are endorsed by
						Australian Signals Directorate (ASD).
					</Typography>
					<Typography className={styles.underline} variant="h4">
						State Licensing
					</Typography>
					<Typography variant="body1">Compliance with the most stringent licensing requirements is mandatory within ARL.</Typography>
					<Typography variant="body1">
						Collection staff must comply with the appropriate Trust Account rules and collection practices and standards. As a collection
						service provider, all requirements are incorporated into ARL’s business management processes and business models. State
						licensing registration details are renewed annually.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Quality Management System
					</Typography>
					<Typography variant="body1">
						ARL is accredited to the International ISO 9001:2015 standard and has a fully functional quality management system that is
						accessible to all ARL staff via the company intranet. The organisation’s ISO 9001 certification is an internationally
						recognised quality management standard based on good management principles. Policy and procedural documentation is added to
						the QMS database via strict document control procedures. The QMS is supported, maintained, audited and subject to continuous
						improvement as a way to ensure that the businesses productivity and performance goals are achieved.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Community Partner
					</Typography>
					<Typography variant="body1">
						ARL has a community partner who specialize in advising our Learning and Development courses, policies, code of conduct,
						values, and general operations.
					</Typography>
					<Typography variant="body1">
						Our Learning and Development team have developed a number of courses with our community partner on topics such as dealing with
						customers in hardship, which form part of our induction program for all new employees, along with optional refresher courses
						for all employees.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Learning and Development
					</Typography>
					<Typography variant="body1">
						All new ARL employees undertake a thorough induction process, with a week of face to face compliance training and six-week
						total induction period
					</Typography>
					<Typography variant="body1">
						Our training and education programs are regularly reviewed and upgraded to benefit from the findings and outcomes achieved in
						the call monitoring and complaints handling process, with an aim to constantly improve communication and service to enhance
						customer experience.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Privacy and Terms
					</Typography>
					<Typography variant="body1">
						You can view our{' '}
						<Link component={RouterLink} color="secondary" to={WebRoutes.PRIVACY.path}>
							Privacy Policy
						</Link>{' '}
						and{' '}
						<Link component={RouterLink} color="secondary" to={WebRoutes.TERMS.path}>
							Terms of Use
						</Link>
						.
					</Typography>
				</Spacing>
			</Container>
			<BusinessFooter />
		</Box>
	);
};
