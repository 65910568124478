import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AssistantCTAHollow } from '../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { LockedModal } from '../../../../../../elements/LockedModal/lockedModal';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { AccountRoutes } from '../../../../../../enums/routerPath';
import { resetPaymentForm, setNegotiationModalOpen } from '../../../../../../store/features/paymentForm/paymentFormSlice';

type CantAffordProps = {
	open: boolean;
};

export const CantAffordModal: React.FC<CantAffordProps> = ({ open }) => {
	const dispatch = useDispatch();

	const handleTryAnother = () => {
		dispatch(setNegotiationModalOpen(undefined));
	};

	const handleNotify = () => {
		dispatch(resetPaymentForm());
	};

	return (
		<LockedModal open={open} onClose={handleTryAnother}>
			<AssistantCTAHollow title="Don't worry, we're here to support you." />
			<Spacing units={1.5}>
				<Button
					color="primary"
					fullWidth
					size="large"
					variant="outlined"
					onClick={handleNotify}
					component={Link}
					to={AccountRoutes.HARDSHIP.path}
				>
					Apply for hardship
				</Button>
				<Button color="secondary" fullWidth size="large" variant="contained" onClick={handleTryAnother}>
					Try another amount
				</Button>
			</Spacing>
		</LockedModal>
	);
};
