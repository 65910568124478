import { ARLResponse } from '../../../../interceptor/base.api.response';

export enum PaymentScheduleAPI {
	SCHEDULES = '/api/arrangement/schedule',
}

export interface IArrangementSchedule {
	rank: number;
	paID: number;
	date: string;
	amount: number;
	method: string;
}

export type IGetArrangementSchedule = ARLResponse<{ schedule: IArrangementSchedule[] }>;
