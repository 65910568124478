import { makeStyles } from '@material-ui/core/styles';
import { fontFamilyText, getThemeStyle, spacingUnits } from '../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $card': {
				padding: [[spacingUnits(3), spacingUnits(2)]],
			},
			'& $review': {
				marginBottom: spacingUnits(2),
			},
			'& $checkbox': {
				marginTop: spacingUnits(2),
				marginBottom: spacingUnits(2),
			},
			'& $viewTerms': {
				color: getThemeStyle('paletteSecondary500'),
				fontWeight: 500,
				marginLeft: 4,
				cursor: 'pointer',
				textDecoration: 'underline',
			},
			color: getThemeStyle('paletteCommonBlack'),
			'& $frequency': {
				fontWeight: 500,
				marginBottom: spacingUnits(1),
				color: 'inherit',
				letterSpacing: 'unset',
			},
			'& $amount': {
				color: 'inherit',
				fontWeight: 500,
				fontFamily: fontFamilyText,
				fontSize: '2.6rem',
				lineHeight: '2.2rem',
				marginBottom: spacingUnits(1.5),
				letterSpacing: 'unset',
			},
			'& $startDate': {
				fontWeight: 400,
				fontSize: '1.4rem',
				letterSpacing: 'unset',
				marginBottom: spacingUnits(0.5),
			},
			'& $methodCard': {
				fontWeight: 400,
				fontSize: '1.4rem',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
				letterSpacing: 'unset',

				'& $cardIcon': {
					height: 10,
					marginRight: spacingUnits(0.5),
				},
			},
			'& $methodBpay': {
				fontWeight: 400,
				fontSize: '1.4rem',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
				letterSpacing: 'unset',

				'& $bpayIcon': {
					height: 18,
					margin: [[0, spacingUnits(0.5)]],
				},
			},
			'& $methodDirect': {
				fontWeight: 400,
				fontSize: '1.4rem',
				letterSpacing: 'unset',

				'& > span': {
					fontWeight: 500,
				},
			},
		},
		card: {},
		review: {},
		checkbox: {},
		viewTerms: {},
		frequency: {},
		amount: {},
		startDate: {},
		methodCard: {},
		methodBpay: {},
		methodDirect: {},
		cardIcon: {},
		bpayIcon: {},
		desktopButtons: {
			justifyContent: 'flex-start',
		},
	},
	{ name: 'paymentAgreement' },
);
