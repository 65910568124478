import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LandingLoader } from '../../../../elements/LandingLoader/landingLoader';
import { WebsiteWorkflow } from '../../../../enums/websiteWorkflow';
import { setShowDebtPanel } from '../../../../store/features/accountLayout/accountLayoutSlice';
import { RootState } from '../../../../store/rootReducer';
import { DashboardLoader } from '../DashboardLoader/dashboardLoader';
import { ActiveWorkflow } from './elements/ActiveWorkflow/activeWorkflow';
import { BlockedWorkflow } from './elements/BlockedWorkflow/blockedWorkflow';
import { PayingWorkflow } from './elements/PayingWorkflow/payingWorkflow';
import { DebtStatusLogicProps } from './types';

export const DebtStatusLogic: React.FC<DebtStatusLogicProps> = ({ ...props }) => {
	const dispatch = useDispatch();
	const { websiteWorkflow, customerDetailsLoaded } = useSelector(
		(state: RootState) => ({
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			customerDetailsLoaded: state.customer.isLoaded,
		}),
		shallowEqual,
	);

	// Show side panel if dashboard view
	useEffect(() => {
		dispatch(setShowDebtPanel(props.isDashboard));
		return () => {
			dispatch(setShowDebtPanel(false));
		};
	}, [props.isDashboard, dispatch]);

	return (
		<>
			{!customerDetailsLoaded && (props.isDashboard ? <DashboardLoader /> : <LandingLoader />)}
			{customerDetailsLoaded &&
				websiteWorkflow &&
				(() => {
					switch (websiteWorkflow) {
						case WebsiteWorkflow.ACTIVE:
							return <ActiveWorkflow {...props} />;
						case WebsiteWorkflow.PAYING:
							return <PayingWorkflow {...props} />;
						case WebsiteWorkflow.BLOCKED:
							return <BlockedWorkflow {...props} />;
						default:
							return null;
					}
				})()}
		</>
	);
};
