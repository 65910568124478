import { Card } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Severity } from '../../enums/severity';
import { CircleTimesSolid } from '../PantheraIcon/CircleTimesSolid';
import useStyles from './dismissableCard.styles';
import { DismissableCardEvents } from './types';

type DismissableCardProps = {
	className?: string;
	severity?: Severity;
};

export const DismissableCard: React.FC<DismissableCardProps & DismissableCardEvents> = ({
	children,
	severity = Severity.SUCCESS,
	className,
	onDismiss,
	showCard = true,
	...props
}) => {
	const styles = useStyles();
	return (
		<Card className={clsx(className, styles.root, severity && styles.rootWithSeverityPadding, !showCard && styles.hidden)} raised {...props}>
			{severity && <div className={clsx(styles.severityStripe, styles[`severity-${severity}`])} />}
			{children}
			{onDismiss && <CircleTimesSolid className={styles.closeIcon} onClick={onDismiss} />}
		</Card>
	);
};
