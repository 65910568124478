import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../theme';
import { bodyContentMarketingMaxWidth, getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';

const container = {
	width: '100%',
	margin: [[0, 'auto']],
	maxWidth: bodyContentMarketingMaxWidth + spacingUnits(5.5),
};

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',
		},
		greyHeader: {
			backgroundColor: subtleBackgroundColor,
		},
		debtHelplineBanner: {
			backgroundColor: getThemeStyle('paletteCommonBlack'),

			'& > div': {
				...container,

				color: getThemeStyle('paletteCommonWhite'),
				padding: spacingUnits(4),
				[MuiTheme.breakpoints.up('md')]: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},

				'& > img': {
					marginBottom: spacingUnits(2),
					maxWidth: 188,
					[MuiTheme.breakpoints.up('md')]: {
						maxWidth: 246,
						marginBottom: 0,
						marginRight: spacingUnits(7.5),
					},
				},
				'& > p': {
					fontSize: '1.8rem',
				},
			},
		},
	},
	{
		name: 'supportLayout',
	},
);
