import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssistantCTAHollow } from '../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { CurrencyFancyDisplay } from '../../../../../elements/CurrencyFancyDisplay/currencyFancyDisplay';
import { PantheraCheckbox } from '../../../../../elements/PantheraCheckbox/pantheraCheckbox';
import { MasterCard, Visa } from '../../../../../elements/PantheraIcon';
import { BpayHorizontal } from '../../../../../elements/PantheraIcon/BpayHorizontal';
import { SpinnerButton } from '../../../../../elements/SpinnerButton/spinnerButton';
import { AvailablePaymentMethod, CardType, PaymentMode, PaymentType } from '../../../../../enums/paymentForm';
import { dateFormats } from '../../../../../services/constants/formats';
import { scheduleBPayPayment, scheduleCardPayment, scheduleDirectDebitPayment } from '../../../../../store/features/paymentForm/paymentFormSlice';
import { BaseArrangement } from '../../../../../store/features/paymentForm/types';
import { history } from '../../../../../store/history';
import { RootState } from '../../../../../store/rootReducer';
import { AcceptTermsModal } from '../../PaymentArrangement/elements/AcceptTermsModal/acceptTermsModal';
import useStyles from './paymentAgreement.styles';

export const PaymentAgreement: React.FC = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const [isTermsModalOpen, setTermsModalOpen] = useState(false);
	const [hasAccepted, setHasAccepted] = useState(false);
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));

	const {
		merchantFeePercent,
		paymentFrequency,
		paymentAmount,
		paymentMethod,
		paymentMode,
		paymentDate,
		paymentType,
		cardLast4,
		paymentCard,
		paymentDirectDebit,
		loading,
	} = useSelector((state: RootState) => ({
		merchantFeePercent: state.customer.customerDetails?.merchantFeePercent,
		paymentFrequency: state.paymentForm.paymentFrequency,
		paymentAmount: state.paymentForm.paymentAmount,
		paymentMethod: state.paymentForm.paymentMethod,
		paymentMode: state.paymentForm.paymentMode,
		paymentDate: state.paymentForm.paymentDate,
		paymentType: state.paymentForm.paymentType,
		cardLast4: state.paymentForm.paymentCard?.cardNumber.substring(12),
		paymentCard: state.paymentForm.paymentCard,
		paymentDirectDebit: state.paymentForm.paymentDirectDebit,
		loading: state.paymentForm.loading,
	}));

	const handleBack = () => {
		history.goBack();
	};

	const handleSubmit = () => {
		if (paymentType === PaymentType.SINGLE && paymentMode === PaymentMode.NOW) return;
		const baseArrangement: BaseArrangement = {
			amount: parseFloat(paymentAmount),
			startDate: format(parseISO(paymentDate), dateFormats.dbDate),
			frequency: paymentFrequency?.Name,
		};
		if (paymentMethod?.Name === AvailablePaymentMethod.CARD) {
			dispatch(
				scheduleCardPayment({
					...baseArrangement,
					encryptedCard: paymentCard?.encryptedCardNumber,
					cardExpiry: paymentCard?.expiryDate,
					cardHolderName: paymentCard?.nameOnCard,
				}),
			);
		}
		if (paymentMethod?.Name === AvailablePaymentMethod.BPAY) {
			dispatch(scheduleBPayPayment(baseArrangement));
		}
		if (paymentMethod?.Name === AvailablePaymentMethod.DIRECT) {
			if (!paymentDirectDebit) return;
			dispatch(
				scheduleDirectDebitPayment({
					...baseArrangement,
					bsb: paymentDirectDebit?.bsb.replace(/-/g, ''),
					accountNumber: paymentDirectDebit.accountNumber,
					accountName: paymentDirectDebit.accountName,
					suffix: paymentDirectDebit.suffix,
				}),
			);
		}
	};

	return (
		<>
			<AssistantCTAHollow title="Please review your plan" />
			<Box className={styles.root}>
				<Card className={styles.card} elevation={8}>
					<Typography className={styles.frequency} variant="subtitle2">
						{paymentFrequency?.Name} payment of
					</Typography>
					<CurrencyFancyDisplay className={styles.amount} value={Number(paymentAmount)} />
					<Typography className={styles.startDate} variant="subtitle2">
						{paymentType === PaymentType.SINGLE ? 'Due' : 'First payment'} {format(parseISO(paymentDate), dateFormats.prettyShort)}
					</Typography>
					{paymentMethod?.Name === AvailablePaymentMethod.CARD && (
						<>
							<Typography className={styles.methodCard} variant="subtitle2">
								{paymentCard?.cardTypeID === CardType.MASTER ? (
									<MasterCard className={styles.cardIcon} />
								) : (
									<Visa className={styles.cardIcon} />
								)}{' '}
								{cardLast4}
							</Typography>
							{!!merchantFeePercent && (
								<Alert className={styles.checkbox} severity="info" style={{ display: 'inline-flex' }}>
									A surcharge of {merchantFeePercent}% will be added to the payment amount for all Visa or MasterCard.
								</Alert>
							)}
						</>
					)}
					{paymentMethod?.Name === AvailablePaymentMethod.BPAY && (
						<Typography className={styles.methodBpay} variant="subtitle2">
							Via <BpayHorizontal className={styles.bpayIcon} /> (BPAY details will be supplied)
						</Typography>
					)}
					{paymentMethod?.Name === AvailablePaymentMethod.DIRECT && (
						<Typography className={styles.methodDirect} variant="subtitle2">
							Via <span>{paymentMethod.Name}</span>
						</Typography>
					)}
					<PantheraCheckbox
						checked={hasAccepted}
						className={styles.checkbox}
						label={hasAccepted ? 'I have read and agreed to the terms of this plan' : 'View and agree to the terms of this plan'}
						onChange={hasAccepted ? () => setHasAccepted(false) : () => setTermsModalOpen(true)}
						value="accept"
					/>
				</Card>
			</Box>
			<ButtonStack className={screenAtDesktopWidth ? styles.desktopButtons : undefined}>
				<SpinnerButton
					color="secondary"
					data-cy="btnCompleteSetup"
					disabled={!hasAccepted}
					fullWidth
					size="large"
					variant="contained"
					onClick={handleSubmit}
					loading={loading}
				>
					Complete set up
				</SpinnerButton>
				<Button color="primary" fullWidth size="large" variant="outlined" onClick={handleBack}>
					Back
				</Button>
			</ButtonStack>
			{isTermsModalOpen && paymentMethod && (
				<AcceptTermsModal
					onAccept={() => {
						setHasAccepted(true);
						setTermsModalOpen(false);
					}}
					onClose={() => setTermsModalOpen(false)}
					paymentType={paymentType}
					paymentMethod={paymentMethod.Name}
				/>
			)}
		</>
	);
};
