import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, inputMaxWidth, spacingUnits, transition } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			position: 'relative',
			maxWidth: inputMaxWidth,

			'& $label': {
				width: '100%',

				'& ~ $button': {
					top: spacingUnits(4.4),
				},
			},

			'& $indicator': {
				width: 13,
				display: 'inline-block',
				opacity: 0,
				position: 'absolute',
				right: '100%',
				top: spacingUnits(3.5),
				transform: 'translate(-3px, -50%)',
				transition: transition('opacity'),

				'& > *': {
					width: '100%',
					fill: getThemeStyle('palettePrimaryA400'),
				},
				'&$indicatorWithLabel': {
					top: `calc(${spacingUnits(4.5)}px + ${getThemeStyle('typographySubtitle2LineHeight')} + 2px)`,
				},
			},

			'& $errorText': {
				position: 'absolute',
				bottom: 0,
				left: 0,
				fontSize: '1.3rem',
				lineHeight: '1.6rem',
				fontWeight: 500,
				letterSpacing: '-0.019rem',
				padding: [[0, 0, spacingUnits(1), spacingUnits(1.5)]],
			},

			'& $helperText': {
				fontSize: '1.4rem',
				lineHeight: '1.8rem',
				padding: [[spacingUnits(1), 0, 0]],
			},

			'& $errorMessage': {
				color: getThemeStyle('paletteErrorMain'),
			},

			'&.MuiFormControl-root': {
				marginBottom: spacingUnits(2),

				'& .MuiInputBase-root.Mui-error .MuiInputBase-input': {
					paddingBottom: spacingUnits(1),
				},
			},
		},
		tooltip: {
			position: 'absolute',
			right: 0,

			display: 'flex',
			alignItems: 'center',

			backgroundColor: 'transparent',
			border: 'none',
			color: getThemeStyle('palettePrimary500'),
			cursor: 'pointer',
			fill: getThemeStyle('palettePrimary500'),
			lineHeight: '2.4rem',
			padding: 0,
			zIndex: 1,

			'& > p': {
				fontWeight: 500,
				marginRight: spacingUnits(0.5),
			},

			'& > svg': {
				width: spacingUnits(2),
			},
		},

		button: {
			position: 'absolute',
			height: spacingUnits(5),
			top: spacingUnits(1),
			right: '10px',
		},

		label: {},
		indicator: {},
		indicatorWithLabel: {},
		helperText: {},
		input: {},
		errorMessage: {},
		errorText: {},
	},
	{ name: 'pantheraInput' },
);
