import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ContactType } from '../../../../enums/contactTypes';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { CustomerContactsAPI, IContactOutput, IEmailContacts } from '../types';

export interface CustomerEmailState {
	emailContacts: IContactOutput[] | undefined;
	loading: boolean;
	error: string | undefined;
}

const initialState: CustomerEmailState = {
	emailContacts: undefined,
	loading: false,
	error: undefined,
};

export const customerEmailSlice = createSlice({
	name: 'customerEmailSlice',
	initialState,
	reducers: {
		getEmailContacts(state: CustomerEmailState) {
			state.loading = true;
			state.emailContacts = undefined;
			state.error = undefined;
		},
		getEmailContactsSuccess(state: CustomerEmailState, action: PayloadAction<IContactOutput[]>) {
			state.loading = false;
			state.emailContacts = action.payload;
			state.error = undefined;
		},
		getEmailContactsFailure(state: CustomerEmailState, action: PayloadAction<string>) {
			state.loading = false;
			state.emailContacts = undefined;
			state.error = action.payload;
		},
	},
});

export const { getEmailContacts, getEmailContactsSuccess, getEmailContactsFailure } = customerEmailSlice.actions;

export default customerEmailSlice.reducer;

type FetchEmailContactsInput = ContactType.Email_Personal | ContactType.Email_Work;

export const fetchEmailContacts =
	(contactTypes?: FetchEmailContactsInput[]): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getEmailContacts());
			const { data } = await arlApi.get<IEmailContacts>(CustomerContactsAPI.EMAIL_CONTACTS, {
				params: {
					contactTypeID: JSON.stringify(contactTypes ?? [ContactType.Email_Personal, ContactType.Email_Work]),
				},
			});
			if (data.success) {
				dispatch(getEmailContactsSuccess(data.data.contacts));
			} else {
				dispatch(getEmailContactsFailure(data.message));
			}
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getEmailContactsFailure(e.response?.data.message));
			} else {
				dispatch(getEmailContactsFailure('An unknown error occured.'));
			}
		}
	};
