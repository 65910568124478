import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import { BusinessHeader } from '../../elements/Header/header';
import useStyles from './contact.styles';
import { BusinessFooter } from '../../elements/Footer/footer';
import { ContactDetails } from '../../../Customer/scenes/Contact/elements/contactDetails';
import { HeroTitle } from '../../../Customer/elements/HeroTitle/heroTitle';
import { BusinessContactForm } from './elements/contactForm';

export const BusinessContact: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<BusinessHeader className={styles.greyHeader} />
			<HeroTitle
				className={styles.greyHero}
				title="Contact sales"
				subtitle="Find out how we can help reach your business goals. Let’s get started."
			/>
			<Container className={styles.row} maxWidth="lg">
				<Grid container spacing={10}>
					<BusinessContactForm />
					<Grid item xs={12} md={4}>
						<div>
							<ContactDetails isBusiness={true} />
						</div>
					</Grid>
				</Grid>
			</Container>
			<BusinessFooter />
		</Box>
	);
};
