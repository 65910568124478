/* eslint-disable no-nested-ternary */
import {
	Box,
	Button,
	CircularProgress,
	LabelDisplayedRowsArgs,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowsCreditDebit } from '../../../../../../elements/PantheraIcon/ArrowsCreditDebit';
import { WarningRejected } from '../../../../../../elements/PantheraIcon/WarningRejected';
import { dateFormats } from '../../../../../../services/constants/formats';
import { currency } from '../../../../../../services/helpers/text.helpers';
import { fetchTransactions, resetTransactions } from '../../../../../../store/features/transaction/transactionSlice';
import { ITransactionOutput } from '../../../../../../store/features/transaction/types';
import { RootState } from '../../../../../../store/rootReducer';
import useStyles from './paymentsTab.styles';

const TRANSACTION_STATUS_PAYMENT = 'Paid';
const TRANSACTION_STATUS_DISHONOUR = 'Dishonoured';
const TRANSACTION_STATUS_REVERSED = 'Reversed';

export const PaymentsTab: React.FC = () => {
	const styles = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const [rowCount, setRowCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const { loading, transactions } = useSelector(
		(state: RootState) => ({
			loading: state.transaction.loading,
			transactions: state.transaction.transactions,
		}),
		shallowEqual,
	);

	useEffect(() => {
		dispatch(fetchTransactions());
		return () => {
			dispatch(resetTransactions());
		};
	}, [dispatch]);

	useEffect(() => {
		if (transactions) {
			setRowCount(transactions.length);
		}
	}, [dispatch, transactions]);

	const getTransactionTypeImage = (transaction: ITransactionOutput | undefined) => {
		if (transaction === undefined) return <></>;

		switch (transaction.status) {
			case TRANSACTION_STATUS_DISHONOUR:
			case TRANSACTION_STATUS_REVERSED:
				return <WarningRejected />;
			default:
				return <ArrowsCreditDebit />;
		}
	};

	const getTransactionLabel = (transaction: ITransactionOutput | undefined) => {
		if (transaction === undefined) return '';
		const formattedMethod = transaction.method
			.split(' ')
			.filter((x) => x.length > 0)
			.map((x) => x.charAt(0).toUpperCase() + x.slice(1))
			.join(' ');

		switch (transaction.status) {
			case TRANSACTION_STATUS_PAYMENT:
				return `${formattedMethod} payment`;
			case TRANSACTION_STATUS_DISHONOUR:
			case TRANSACTION_STATUS_REVERSED:
				return `${formattedMethod} rejected`;
			default:
				return formattedMethod;
		}
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const pageSize = parseInt(event.target.value, 10);
		setRowsPerPage(pageSize);
		setPage(0);
	};

	const handleLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
		return `${from}-${to > 0 ? to : count} of ${count}`;
	};

	return (
		<>
			<div className={styles.section}>
				{transactions ? (
					<>
						<Typography variant="subtitle1">Payments made</Typography>
						<TableContainer>
							<Table aria-label="Schedules table">
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
										<TableCell>Amount</TableCell>
										<TableCell>Date</TableCell>
										<TableCell>Payment Method</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(rowsPerPage > 0 ? transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : transactions).map(
										(tran) => (
											<TableRow key={`${tran.date}_${tran.amount}`} className={tran.amount < 0 ? styles.failedPaymentRow : ''}>
												<TableCell>
													<div className={styles.svgIcon}>{getTransactionTypeImage(tran)}</div>
												</TableCell>
												<TableCell>{currency(tran.amount)}</TableCell>
												<TableCell component="th" scope="row">
													{format(parseISO(tran.date), dateFormats.prettyShort)}
												</TableCell>
												<TableCell>{getTransactionLabel(tran)}</TableCell>
											</TableRow>
										),
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component="div"
							rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
							count={rowCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelDisplayedRows={handleLabelDisplayedRows}
						/>
					</>
				) : loading ? (
					<Box display="flex" alignItems="center" justifyContent="center" padding={4}>
						<CircularProgress color="secondary" size={80} />
					</Box>
				) : (
					<Typography variant="subtitle1">No payment history</Typography>
				)}
			</div>

			<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => history.goBack()}>
				Back
			</Button>
		</>
	);
};
