import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle } from '../../../../theme/theme';

export default makeStyles(
	{
		liveChatIcon: {
			width: 24,
			marginTop: -4,
			marginRight: 4,
			marginBottom: -4,
			marginLeft: 0,
			fill: getThemeStyle('paletteCommonWhite'),
		},
		bold: {
			fontWeight: 500,
			whiteSpace: 'noWrap',
		},
	},
	{ name: 'default' },
);
