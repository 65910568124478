import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

import useStyles from './landingLoader.styles';

export const LandingLoader: React.FC = () => {
	const styles = useStyles();
	return (
		<>
			<div className={styles.root}>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start">
					<Skeleton variant="circle" width={104} height={104} className={styles.avatar} />
				</Grid>
				<Grid container direction="row" justifyContent="center">
					<Typography variant="h5" className={styles.title}>
						<Skeleton variant="text" />
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body1">
						<Skeleton variant="text" />
					</Typography>
				</Grid>
			</div>
		</>
	);
};
