import redHatDisplayBlackWoff from '../static/fonts/RedHatDisplay-Black.woff';
import redHatDisplayBlackWoff2 from '../static/fonts/RedHatDisplay-Black.woff2';
import redHatDisplayBoldWoff from '../static/fonts/RedHatDisplay-Bold.woff';
import redHatDisplayBoldWoff2 from '../static/fonts/RedHatDisplay-Bold.woff2';
import redHatDisplayMediumWoff from '../static/fonts/RedHatDisplay-Medium.woff';
import redHatDisplayMediumWoff2 from '../static/fonts/RedHatDisplay-Medium.woff2';
import redHatDisplayRegularWoff from '../static/fonts/RedHatDisplay-Regular.woff';
import redHatDisplayRegularWoff2 from '../static/fonts/RedHatDisplay-Regular.woff2';
import redHatTextBoldWoff from '../static/fonts/RedHatText-Bold.woff';
import redHatTextBoldWoff2 from '../static/fonts/RedHatText-Bold.woff2';
import redHatTextMediumWoff from '../static/fonts/RedHatText-Medium.woff';
import redHatTextMediumWoff2 from '../static/fonts/RedHatText-Medium.woff2';
import redHatTextRegularWoff from '../static/fonts/RedHatText-Regular.woff';
import redHatTextRegularWoff2 from '../static/fonts/RedHatText-Regular.woff2';

export const redHatTextBold = {
	fontFamily: 'Red Hat Text',
	src: `url(${redHatTextBoldWoff2}) format('woff2'),
		url(${redHatTextBoldWoff}) format('woff')`,
	fontWeight: 'bold',
	fontStyle: 'normal',
};

export const redHatTextMedium = {
	fontFamily: 'Red Hat Text',
	src: `url(${redHatTextMediumWoff2}) format('woff2'),
		url(${redHatTextMediumWoff}) format('woff')`,
	fontWeight: 500,
	fontStyle: 'normal',
};

export const redHatTextRegular = {
	fontFamily: 'Red Hat Text',
	src: `url(${redHatTextRegularWoff2}) format('woff2'),
		url(${redHatTextRegularWoff}) format('woff')`,
	fontWeight: 'normal',
	fontStyle: 'normal',
};

export const redHatDisplayBlack = {
	fontFamily: 'Red Hat Display',
	src: `url(${redHatDisplayBlackWoff2}) format('woff2'),
		url(${redHatDisplayBlackWoff}) format('woff')`,
	fontWeight: 900,
	fontStyle: 'normal',
};

export const redHatDisplayBold = {
	fontFamily: 'Red Hat Display',
	src: `url(${redHatDisplayBoldWoff2}) format('woff2'),
		url(${redHatDisplayBoldWoff}) format('woff')`,
	fontWeight: 'bold',
	fontStyle: 'normal',
};

export const redHatDisplayMedium = {
	fontFamily: 'Red Hat Display',
	src: `url(${redHatDisplayMediumWoff2}) format('woff2'),
		url(${redHatDisplayMediumWoff}) format('woff')`,
	fontWeight: 500,
	fontStyle: 'normal',
};

export const redHatDisplayRegular = {
	fontFamily: 'Red Hat Display',
	src: `url(${redHatDisplayRegularWoff2}) format('woff2'),
		url(${redHatDisplayRegularWoff}) format('woff')`,
	fontWeight: 'normal',
	fontStyle: 'normal',
};
