/* eslint-disable @typescript-eslint/no-shadow */
import { Dialog } from '@material-ui/core';
import { DatePicker, BaseDatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';

import useStyles from './datePicker.styles';
import { CustomDatePickerDay } from './elements/CustomDatePickerDay/customDatePickerDay';
import { ArrowLeft } from '../../../PantheraIcon/ArrowLeft';
import { ArrowRight } from '../../../PantheraIcon/ArrowRight';

type DatePickerModalProps = {
	onChange: (date: MaterialUiPickersDate) => void;
	DatePickerProps?: BaseDatePickerProps;
	today?: Date;
	value?: Date;
};

export const DatePickerModal: React.FC<DatePickerModalProps> = ({ onChange, today = new Date(), value = null, DatePickerProps = {} }) => {
	const styles = useStyles();
	const handleChange = (date: MaterialUiPickersDate) => {
		onChange(date);
	};
	return (
		<Dialog onClose={() => handleChange(value)} open PaperProps={{ elevation: 8 }}>
			<div className={styles.root}>
				<DatePicker
					autoOk
					disableToolbar
					leftArrowIcon={<ArrowLeft className={styles.arrows} />}
					onChange={handleChange}
					openTo="date"
					orientation="landscape"
					renderDay={(day, selectedDate, isDayInCurrentMonth, dayComponent) => (
						<CustomDatePickerDay
							day={day}
							isDayInCurrentMonth={isDayInCurrentMonth}
							onSelect={handleChange}
							today={today}
							{...dayComponent.props}
						/>
					)}
					rightArrowIcon={<ArrowRight className={styles.arrows} />}
					value={value}
					variant="static"
					{...DatePickerProps}
				/>
			</div>
		</Dialog>
	);
};
