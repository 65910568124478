import { Button, Card, Hidden } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AccountRoutes, PaymentRoutes } from '../../../../../enums/routerPath';
import { payNowWithAmount, resetPaymentForm } from '../../../../../store/features/paymentForm/paymentFormSlice';
import { RootState } from '../../../../../store/rootReducer';
import { PaymentFailedCard } from './elements/PaymentFailedCard/paymentFailedCard';
import useStyles from './paymentFailed.styles';

export const PaymentFailed: React.FC = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const { paymentAmount, last4Digits, cardTypeID, hasFixedAmount, paymentFailureMsgs } = useSelector((state: RootState) => ({
		paymentAmount: state.paymentForm.paymentFailure?.paymentAmount ?? '',
		last4Digits: state.paymentForm.paymentCard?.cardNumber.substring(12) || '',
		cardTypeID: state.paymentForm.paymentCard?.cardTypeID,
		hasFixedAmount: state.paymentForm.hasFixedAmount,
		paymentFailureMsgs: state.paymentForm.paymentFailure?.errors,
	}));

	const title = 'Payment declined';

	const actions = (
		<>
			{hasFixedAmount ? (
				<Button
					id="btnTryAgain"
					color="secondary"
					fullWidth
					size="large"
					variant="contained"
					onClick={() => dispatch(payNowWithAmount(paymentAmount))}
				>
					Try again
				</Button>
			) : (
				<Button
					id="btnTryAnotherMethod"
					color="secondary"
					fullWidth
					size="large"
					variant="contained"
					component={Link}
					to={PaymentRoutes.SINGLE_PAYMENT.path}
					onClick={() => dispatch(resetPaymentForm())}
				>
					Try another method
				</Button>
			)}
			<Button id="btnOverview" color="primary" fullWidth size="large" variant="outlined" component={Link} to={AccountRoutes.OVERVIEW.path}>
				Go to account overview
			</Button>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card className={styles.card} elevation={8}>
					<PaymentFailedCard
						title={title}
						subTitle={paymentFailureMsgs?.join('. ') || ''}
						paymentAmount={paymentAmount}
						last4Digits={last4Digits}
						cardTypeID={cardTypeID}
						actions={actions}
					/>
				</Card>
			</Hidden>
			<Hidden mdUp>
				<PaymentFailedCard
					title={title}
					subTitle={paymentFailureMsgs?.join('. ') || ''}
					paymentAmount={paymentAmount}
					last4Digits={last4Digits}
					cardTypeID={cardTypeID}
					actions={actions}
				/>
			</Hidden>
		</>
	);
};
