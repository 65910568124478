import teal from '@material-ui/core/colors/teal';
import { makeStyles } from '@material-ui/core/styles';

import { fontFamilyText, getThemeStyle, spacingUnits } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			padding: [[spacingUnits(1.5), spacingUnits(2)]],
			marginBottom: spacingUnits(3),
			textAlign: 'left',
			alignItems: 'center',
			wordBreak: 'break-all',

			'& $checkIcon': {
				height: spacingUnits(6.5),
				width: spacingUnits(6.5),
				borderRadius: '100%',
				backgroundColor: teal[900],
				marginRight: spacingUnits(1.5),
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				backgroundSize: [[spacingUnits(5), spacingUnits(5)]],
			},
			'& $currencyDisplay': {
				fontWeight: 500,
				fontFamily: fontFamilyText,
				fontSize: '2.6rem',
				lineHeight: '2.2rem',
				margin: [[spacingUnits(0.5), 0]],
			},
			'& $sectionSubtitle': {
				color: getThemeStyle('paletteTertiary900'),
				fontSize: '1.4rem',
				fontWeight: 'normal',
				fontFamily: fontFamilyText,
			},
			'& $sectionBody': {
				color: getThemeStyle('paletteBlack'),
				fontSize: '1.6rem',
				fontWeight: 500,
				fontFamily: fontFamilyText,
			},
		},
		checkIcon: {},
		currencyDisplay: {},
		sectionSubtitle: {},
		sectionBody: {},
	},
	{ name: 'paymentSuccessCard' },
);
