import { Button } from '@material-ui/core';
import React from 'react';
import { startChat } from '../../../../actions/startChat';
import { ButtonStack } from '../../../../elements/ButtonStack/buttonStack';
import { ContactPhone, ContactPhoneHref } from '../../../../elements/ContactPhone/contactPhoneNumber';
import { BubbleEllipsis, Phone } from '../../../../elements/PantheraIcon';
import { BubbleSmsFilled } from '../../../../elements/PantheraIcon/BubbleSmsFilled';
import { EmailSolid } from '../../../../elements/PantheraIcon/EmailSolid';
import useStyles from './contactButtons.styles';

export type ContactButtonProps = {
	email?: string;
	phone?: {
		href: string;
		number: string;
	};
};

export const ContactButtons: React.FC<ContactButtonProps> = ({ email, phone, children }) => {
	const styles = useStyles();
	return (
		<ButtonStack className={styles.buttonStack}>
			<a href={phone ? phone.href : ContactPhoneHref()} style={{ textDecoration: 'none' }}>
				<Button color="secondary" fullWidth size="large" variant="contained">
					<Phone className={styles.buttonIcon} /> Call {phone ? phone.number : ContactPhone()}
				</Button>
			</a>
			<a href={window.config.REACT_APP_CONTACT_MOBILE_HREF} style={{ textDecoration: 'none' }}>
				<Button color="secondary" fullWidth size="large" variant="contained">
					<BubbleSmsFilled className={styles.buttonIcon} /> Start an SMS conversation
				</Button>
			</a>
			<Button color="secondary" fullWidth size="large" variant="contained" onClick={startChat}>
				<BubbleEllipsis className={styles.buttonIcon} /> Live chat
			</Button>
			{email && (
				<a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
					<Button color="secondary" fullWidth size="large" variant="contained">
						<EmailSolid className={styles.buttonIcon} /> Email
					</Button>
				</a>
			)}
			{children}
		</ButtonStack>
	);
};
