/* eslint-disable react/no-array-index-key */
import { Card, List, ListItem, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { LinkProps } from 'react-router-dom';
import React from 'react';
import useStyles from './cTANavigationCard.styles';
import { CTAHeadings } from '../CTAHeadings/cTAHeadings';
import { ChevronRight } from '../PantheraIcon/ChevronRight';
import { WrapWithLink } from '../WrapWithLink/wrapWithLink';

interface CTAItem {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	icon?: React.ReactNode;
	LinkProps?: LinkProps;
	handleClick?: () => void;
}
type CTANavigationCardProps = {
	title?: string;
	subtitle?: string;
	items: CTAItem[];
};
export const CTANavigationCard: React.FC<CTANavigationCardProps> = ({ title, subtitle, items, ...props }) => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<div className={styles.root}>
			<CTAHeadings subtitle={subtitle} title={title} />
			<Card raised {...props}>
				<List className={styles.list}>
					{items.map((item, index) => (
						<ListItem key={index} disableGutters divider={index !== items.length - 1} onClick={item.handleClick}>
							<WrapWithLink className={styles.listItemWrapper} LinkProps={item.LinkProps}>
								{item.icon && <div className={styles.listItemIcon}>{item.icon}</div>}
								<Typography className={styles.listItemTitle} variant={screenAtDesktopWidth ? 'subtitle1' : 'subtitle2'}>
									{item.title}
								</Typography>
								{item.subtitle && <Typography variant="body2">{item.subtitle}</Typography>}
								{(item.LinkProps || item.handleClick) && screenAtDesktopWidth && <ChevronRight className={styles.listItemLinkItem} />}
							</WrapWithLink>
						</ListItem>
					))}
				</List>
			</Card>
		</div>
	);
};
