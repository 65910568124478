import { Box, Button, Card, Hidden } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { ContactPhone, ContactPhoneHref } from '../../../../../../../elements/ContactPhone/contactPhoneNumber';
import { Phone } from '../../../../../../../elements/PantheraIcon/Phone';
import { logout } from '../../../../../../../store/features/auth/authSlice';
import { RootState } from '../../../../../../../store/rootReducer';

export const Blocked: React.FC = () => {
	const dispatch = useDispatch();

	const displayNameShort = useSelector((state: RootState) => state.auth.user?.displayNameShort ?? '');

	const content = {
		title: 'Account under review',
		subtitle: `Hi ${displayNameShort}, sorry we cannot help you through the portal. Please contact us for further details.`,
	};

	const CardContent: React.FC = () => (
		<>
			<AssistantCTAHollow subtitle={content.subtitle} title={content.title} />
			<ButtonStack style={{ marginTop: 0 }}>
				<a href={ContactPhoneHref()} style={{ textDecoration: 'none' }}>
					<Button color="secondary" fullWidth size="large" startIcon={<Phone />} variant="contained">
						Call {ContactPhone()}
					</Button>
				</a>
				<Button onClick={() => dispatch(logout())} color="primary" fullWidth size="large" variant="outlined">
					Logout
				</Button>
			</ButtonStack>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card elevation={8}>
					<Box padding={6}>
						<CardContent />
					</Box>
				</Card>
			</Hidden>
			<Hidden mdUp>
				<CardContent />
			</Hidden>
		</>
	);
};
