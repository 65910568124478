import { makeStyles } from '@material-ui/core';
import { getThemeStyle, spacingUnits } from '../../theme/theme';
import MuiTheme from '../../theme';

const padding = spacingUnits(2);

export default makeStyles(
	{
		root: {
			'& > div': {
				padding,

				'&:first-of-type': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',

					color: getThemeStyle('paletteTertiary900'),

					'& h2': {
						marginBottom: -2,

						'&, & > span': {
							color: getThemeStyle('paletteCommonBlack'),
						},
					},
				},
			},
			marginBottom: spacingUnits(2),
		},
		details: {
			display: 'flex',
			alignItems: 'center',
			marginTop: spacingUnits(0.5),
		},
		noWrap: {
			whiteSpace: 'nowrap',
		},
		cardIcon: {
			width: 34,
			marginRight: spacingUnits(2),

			[MuiTheme.breakpoints.down('sm')]: {
				marginRight: spacingUnits(1),
			},
		},
		bpayIcon: {
			height: 21,
		},
		actionButton: {
			fontSize: '1.3rem',
			fontWeight: 500,
			padding: [[spacingUnits(1), spacingUnits(1.5)]],
			marginBottom: spacingUnits(1),

			'&:last-child': {
				marginBottom: 0,
			},
		},
		paymentIcon: {
			marginRight: spacingUnits(0.5),
		},
		paymentType: {
			fontWeight: 500,
		},
		info: {
			fontWeight: 500,
			color: getThemeStyle('paletteCommonBlack'),
			marginRight: spacingUnits(0.5),
			lineHeight: 1.2,
		},
	},
	{ name: 'NextPaymentCard' },
);
