import React from 'react';

export const ACDBA = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 128 128" {...props}>
		<path
			d="M56.45 87.79a7.55 7.55 0 0015.1 0c0-4.17-3.1-6.1-7.55-7.55-4-1.29-7.55-3.38-7.55-7.55a7.55 7.55 0 0115.1 0M64 60.93v4.26M64 95.34v4.26M24.77 70.94v37.68M32.85 108.62V85.48M40.92 85.48v23.14M40.92 70.94v14.54"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2.5}
		/>
		<circle cx={32.85} cy={55.9} r={6.11} fill="none" stroke="#c00" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} />
		<path
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2.5}
			d="M24.77 85.48h16.15M32.85 66.06v7.08M32.85 66.06h7.16l8.84-8.84v-7.67M32.85 66.06h-7.17l-8.84-8.84v-7.67M87.08 70.94v37.68M95.15 108.62V85.48M103.23 85.48v23.14M103.23 70.94v14.54"
		/>
		<circle cx={95.15} cy={55.9} r={6.11} fill="none" stroke="#c00" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} />
		<path
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2.5}
			d="M87.08 85.48h16.15M95.15 66.06v7.08M95.15 66.06h7.17l8.84-8.84v-7.67M95.15 66.06h-7.16l-8.84-8.84v-7.67M64 1.64L3.84 34.99h120.32L64 1.64zM115.5 34.99v7.52h-103v-7.52M115.5 118.18v-9.56h-103v9.56"
		/>
		<path
			d="M11.85 118.18h104.3a8 8 0 018 8v.17h0H3.84h0v-.17a8 8 0 018.01-8z"
			fill="none"
			stroke="#c00"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2.5}
		/>
		<path d="M24.73 29.2L64 7.43l39.27 21.77-19.88-5.73a70 70 0 00-38.78 0z" fill="#fcc" />
	</svg>
);
