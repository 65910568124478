import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, inputMaxWidth, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			'& $numberDisplay': {
				display: 'block',
				textAlign: 'center',
				margin: [[0, 'auto']],
				minHeight: 36,

				'& > h2': {
					position: 'relative',
					display: 'inline-block',
					paddingLeft: spacingUnits(2),

					'& > span': {
						position: 'absolute',
						top: 0,
						left: 0,
						fontWeight: 'bold',
						fontSize: '2.4rem',
						lineHeight: '3.1rem',
						color: getThemeStyle('palettePrimaryA700'),
					},
				},
			},
			'& $frequencyDisplay': {
				textAlign: 'center',
				fontWeight: 'normal',
				marginBottom: spacingUnits(3),
			},
			'& $frequencyDisplayDesktop': {
				fontWeight: 'normal',
				marginBottom: 0,
			},
			'& $frequencyButtonsWrapper': {
				marginBottom: spacingUnits(3),
				maxWidth: inputMaxWidth,

				'& $frequencyButton': {
					fontSize: '1.6rem',
					fontWeight: 'normal',
					backgroundColor: getThemeStyle('paletteGrey50'),
					height: spacingUnits(5),
					boxShadow: getThemeStyle('shadows0'),
					border: '1px solid #D8E2E9',

					'&:not(:first-of-type)': {
						borderLeftWidth: 0,
					},
					'&:hover': {
						boxShadow: getThemeStyle('shadows0'),
					},
					'&$frequencyButtonSelected': {
						fontWeight: 500,
						backgroundColor: getThemeStyle('paletteTertiary400'),
						color: getThemeStyle('paletteCommonWhite'),
					},
					'& span': {
						justifyContent: 'center',
					},
				},
			},
			'& $numberButton': {
				boxShadow: getThemeStyle('shadows0'),
				fontSize: '1.8rem',
				fontWeight: 500,
			},
			'& $paymentInput': {
				marginBottom: 0,
			},
			'& .MuiInputBase-adornedStart': {
				paddingLeft: spacingUnits(2),

				'& .MuiInputAdornment-positionStart .MuiTypography-colorTextSecondary': {
					color: getThemeStyle('palettePrimaryA700'),
					fontWeight: 'bold',
					fontSize: '2rem',
				},
			},
			'& $totalInput': {
				'& .MuiInputBase-root': {
					backgroundColor: getThemeStyle('paletteCommonLightWhite'),
				},
				'& input': {
					fontWeight: 500,
				},
				marginBottom: 0,
			},
		},
		numberDisplay: {},
		frequencyDisplay: {},
		frequencyDisplayDesktop: {},
		frequencyButtonsWrapper: {},
		frequencyButton: {},
		frequencyButtonSelected: {},
		numberButton: {},
		paymentInput: {},
		totalInput: {},
	},
	{ name: 'paymentAmountForm' },
);
