import React from 'react';

export const PhoneTowerColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<defs>
			<path d="M0.7856 0.05L49.2956 0.05 49.2956 63.9997 0.7856 63.9997z" id="path-1" />
		</defs>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<ellipse cx="40.143" cy="22" fill="#ffcccc" rx="3.143" ry="3" />
			<path d="M30 21L17 10 14 18 14 27 16 31 18 33 30 23z" fill="#ffcccc" />
			<path d="M66 21L53 10 50 18 50 27 52 31 54 33 66 23z" fill="#ffcccc" transform="matrix(-1 0 0 1 116 0)" />
			<g transform="translate(12 8)">
				<path d="M39.955 16.869c1.102-1.598 1.407-4.017.002-6.011l-2.287 1.889c.453.8.298 1.653-.007 2.229l2.292 1.893z" fill="#cc0000" />
				<path d="M51.682 26.54c5.864-7.412 5.864-17.94 0-25.352l-2.274 1.876c4.97 6.325 4.97 15.274 0 21.6l2.274 1.877z" fill="#cc0000" />
				<path d="M18.32 14.784c-.254-.586-.317-1.33.087-2.037l-2.286-1.888c-1.479 2.078-.956 4.316-.268 5.576l2.467-1.65z" fill="#cc0000" />
				<path d="M45.822 21.711a12.87 12.87 0 000-15.695l-2.275 1.878a9.915 9.915 0 010 11.94l2.275 1.877z" fill="#cc0000" />
				<g transform="translate(3 .95)">
					<mask fill="#fff" id="mask-2">
						<use xlinkHref="#path-1" />
					</mask>
					<path
						d="M9.74 61.054l15.362-14.631 15.258 14.63H9.74zm7.023-22.628l6.209 5.955L11.08 55.706l-.691-.46 6.373-16.82zm7.595-20.304l.318.023c.063.005.126.013.187.022.059.008.117.017.178.017.06 0 .119-.01.178-.018.062-.008.124-.016.186-.021l.318-.023 6.095 16.353H18.263l6.095-16.353zm.683-7.866a2.442 2.442 0 11-.002 4.884 2.442 2.442 0 01.002-4.884zm.068 32.09l-5.135-4.924H30.28l-5.171 4.924zm14.558 12.84l-.692.458-11.736-11.256 6.127-5.835 6.3 16.633zM28.427 16.94l.207-.194a5.209 5.209 0 001.677-3.832c0-2.208-1.412-4.195-3.515-4.945l-.282-.101V.05h-2.946v7.818l-.283.1c-2.102.75-3.514 2.738-3.514 4.946 0 1.457.595 2.818 1.676 3.832l.207.194L5.039 61.054H.786V64h48.51v-2.946h-4.253L28.427 16.94z"
						fill="#cc0000"
						mask="url(#mask-2)"
					/>
				</g>
				<path
					d="M12.002 19.04c-.788-1.371-3.156-6.332.529-11.146l-2.272-1.878c-4.311 5.539-2.587 11.639-.706 14.68l2.449-1.657z"
					fill="#cc0000"
				/>
				<path d="M6.672 24.663c-4.97-6.325-4.97-15.274 0-21.6L4.398 1.188c-5.864 7.413-5.864 17.94 0 25.352l2.274-1.876z" fill="#cc0000" />
			</g>
		</g>
	</svg>
);
