import React from 'react';

export const DirectDebitPayment = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 122 80" {...props}>
		<defs>
			<path
				d="M4.622.721A3.716 3.716 0 0 1 .906 4.436v12.383a3.716 3.716 0 0 1 3.716 3.715H34.34a3.715 3.715 0 0 1 3.715-3.715V4.436A3.715 3.715 0 0 1 34.34.721H4.622z"
				id="a"
			/>
			<linearGradient x1="50%" y1="99.999%" x2="50%" y2=".002%" id="b">
				<stop stopColor="#D3E6F4" offset="0%" />
				<stop stopColor="#EFF6FB" offset="100%" />
			</linearGradient>
		</defs>
		<g fill="none" fillRule="evenodd">
			<g transform="translate(12 29)">
				<mask id="c" fill="#fff">
					<use xlinkHref="#a" />
				</mask>
				<path
					d="M4.622.721A3.716 3.716 0 0 1 .906 4.436v12.383a3.716 3.716 0 0 1 3.716 3.715H34.34a3.715 3.715 0 0 1 3.715-3.715V4.436A3.715 3.715 0 0 1 34.34.721H4.622z"
					fill="url(#b)"
					mask="url(#c)"
				/>
			</g>
			<path
				d="M50.054 48.819a3.715 3.715 0 0 0-3.715 3.715H16.621a3.715 3.715 0 0 0-3.715-3.715V36.436a3.715 3.715 0 0 0 3.715-3.715H46.34a3.715 3.715 0 0 0 3.715 3.715v12.383zm0-21.051H12.906a4.953 4.953 0 0 0-4.953 4.953v19.813a4.953 4.953 0 0 0 4.953 4.953h37.148a4.953 4.953 0 0 0 4.953-4.953V32.721a4.953 4.953 0 0 0-4.953-4.953z"
				fill="#54A5FF"
			/>
			<path
				d="M50.054 27.768H12.906a4.953 4.953 0 0 0-4.953 4.953v19.813A4.953 4.953 0 0 1 3 47.58V27.768a4.953 4.953 0 0 1 4.953-4.953h37.148a4.953 4.953 0 0 1 4.953 4.953"
				fill="#4990DE"
			/>
			<path d="M31.48 37.675a4.954 4.954 0 1 1 0 9.907 4.954 4.954 0 0 1 0-9.908" fill="#7D9BB3" />
			<path
				d="M20.336 45.104a2.476 2.476 0 1 1-.001-4.953 2.476 2.476 0 0 1 .001 4.953M42.625 45.104a2.476 2.476 0 1 1-.002-4.953 2.476 2.476 0 0 1 .002 4.953"
				fill="#97BCD9"
			/>
			<path
				d="M61.912 45.654a1.704 1.704 0 0 0 2.368 2.452l.042-.042 5.114-5.114a1.705 1.705 0 0 0 0-2.41l-5.114-5.114a1.705 1.705 0 0 0-2.41 2.411l2.204 2.204H46.07v3.409h18.045l-2.204 2.204z"
				fill="#289372"
			/>
			<g>
				<path
					d="M95.4 24.25c6.461 0 12.008 3.917 14.395 9.506h7.143C114.267 24.374 105.638 17.5 95.4 17.5 83.03 17.5 73 27.53 73 39.9c0 6.278 2.587 11.95 6.75 16.018V39.9c0-8.643 7.006-15.65 15.65-15.65m0 9.507h11.046c-2.16-3.875-6.296-6.497-11.047-6.497-6.98 0-12.64 5.66-12.64 12.64v3.134h7.357a6.143 6.143 0 0 1 5.284-9.278"
					fill="#05476C"
				/>
				<path
					d="M108.04 39.9a12.64 12.64 0 0 0-.393-3.135h-6.964a6.144 6.144 0 0 1-5.284 9.278H82.76V58.39a22.275 22.275 0 0 0 7.05 3.205v-9.056h5.59c6.98 0 12.64-5.659 12.64-12.64"
					fill="#53575A"
				/>
				<path
					d="M117.576 36.765h-6.842c.206 1.013.315 2.06.315 3.134 0 8.644-7.006 15.65-15.65 15.65-.88 0-1.741-.076-2.58-.216v6.813c.847.097 1.707.152 2.58.152 12.37 0 22.4-10.028 22.4-22.399 0-1.064-.08-2.11-.223-3.134"
					fill="#53575A"
				/>
			</g>
		</g>
	</svg>
);
