/* eslint-disable @typescript-eslint/no-shadow */
import clsx from 'clsx';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import useStyles from './wrapWithLink.styles';

const externalPattern = /^((http|https|mailto|tel|sms):)/;

type WrapWithLinkProps = {
	className?: string;
	disabled?: boolean;
	LinkProps?: LinkProps;
};

export const WrapWithLink: React.FC<WrapWithLinkProps> = ({ children, className, disabled, LinkProps, ...props }) => {
	const styles = useStyles();
	if (!LinkProps || disabled) {
		return <div className={clsx(styles.root, className)}>{children}</div>;
	}
	if (LinkProps && LinkProps.to && externalPattern.test(LinkProps.to.toString())) {
		return (
			<a className={clsx(styles.root, className)} href={LinkProps.to.toString()} target={LinkProps.target}>
				{children}
			</a>
		);
	}
	return (
		<Link className={clsx(styles.root, className)} {...props} {...LinkProps}>
			{children}
		</Link>
	);
};
