import { Box, Button, Grid, Portal, Snackbar, Typography } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AssistantCTAHollow } from '../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { LockedModal } from '../../../../../elements/LockedModal/lockedModal';
import { BpayVertical } from '../../../../../elements/PantheraIcon/BpayVertical';
import { fetchBpayDetails } from '../../../../../store/features/customer/bpay/customerBpaySlice';
import { RootState } from '../../../../../store/rootReducer';
import useStyles from './bpayDetailsLockedModal.styles';

type Props = {
	onClose: () => void;
};

export const BPAYDetailsLockedModal: React.FC<Props> = ({ onClose }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const { bpayDetails, loading, error } = useSelector(
		(state: RootState) => ({
			bpayDetails: state.customerBpay.bpayDetails,
			loading: state.customerBpay.loading,
			error: state.customerBpay.error,
		}),
		shallowEqual,
	);

	const [errorSnackbarOpen, setErrorSnackbar] = useState(false);

	useEffect(() => {
		if (!bpayDetails) {
			dispatch(fetchBpayDetails());
		}
	}, [bpayDetails, dispatch]);

	useEffect(() => {
		setErrorSnackbar(!!error);
	}, [error]);

	return (
		<>
			<Portal>
				<Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbar(false)}>
					<Alert variant="filled" severity="error">
						We were unable to retrieve BPAY details
					</Alert>
				</Snackbar>
			</Portal>
			<LockedModal className={styles.root} onClose={onClose} showCloseButton open={true}>
				<AssistantCTAHollow
					avatarClassName={styles.assistantAvatar}
					title="Additional Payment"
					subtitle="Additional payments can be made at any time using the below BPAY details."
				/>
				<Grid container spacing={1}>
					<Grid item xs={3}>
						<BpayVertical height={70} />
					</Grid>
					<Grid item xs={9}>
						<Box style={{ padding: 10 }}>
							<Typography variant="h6" gutterBottom>
								Biller Code:
							</Typography>
							<Typography variant="body1" gutterBottom>
								{loading ? <Skeleton variant="text" width={150} /> : bpayDetails?.billerCode}
							</Typography>

							<Typography variant="h6" gutterBottom>
								Ref:
							</Typography>
							<Typography variant="body1">{loading ? <Skeleton variant="text" width={150} /> : bpayDetails?.ref}</Typography>
						</Box>
					</Grid>
				</Grid>
				<ButtonStack>
					<Button color="primary" fullWidth onClick={onClose} size="large" variant="outlined">
						Close
					</Button>
				</ButtonStack>
			</LockedModal>
		</>
	);
};
