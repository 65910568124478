import {
	BottomNavigation,
	BottomNavigationAction,
	Box,
	Divider,
	Drawer,
	Grid,
	Hidden,
	LinearProgress,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Contacts, AccountBalance, Timer, Assignment } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CurrencyFancyDisplay } from '../../../../elements/CurrencyFancyDisplay/currencyFancyDisplay';
import { DebtBrandLogo } from '../../../../elements/DebtBrandLogo/debtBrandLogo';
import { Times } from '../../../../elements/PantheraIcon/Times';
import { SettingsRoutes } from '../../../../enums/routerPath';
import { setDebtPanelOpen } from '../../../../store/features/accountLayout/accountLayoutSlice';
import { RootState } from '../../../../store/rootReducer';
import { spacingUnits } from '../../../../theme/theme';
import useStyles from './debtDetailsPanel.styles';

type DebtDetailsPanelProps = {
	openForMobile: boolean;
};

export const DebtDetailsPanelComponent: React.FC<DebtDetailsPanelProps> = ({ openForMobile }) => {
	const dispatch = useDispatch();
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));

	const scrollRef = React.useRef<PerfectScrollbar | null>();

	const { customerDetails, loading } = useSelector(
		(state: RootState) => ({
			customerDetails: state.customer.customerDetails,
			loading: state.customer.loading,
		}),
		shallowEqual,
	);

	const [progress, setProgress] = useState<number>(0);
	useEffect(() => {
		if (customerDetails) {
			const total = customerDetails.paidAmt + customerDetails.outstandingAmt;
			setProgress(Math.floor((customerDetails.paidAmt / total) * 100 + 0.5));
		}
	}, [customerDetails]);

	useEffect(() => {
		const container = document.querySelector('#scrollbar-container');
		if (container) {
			scrollRef.current = new PerfectScrollbar(container);
		}
		return () => {
			scrollRef.current?.destroy();
			scrollRef.current = null;
		};
	});

	return (
		<>
			<Drawer
				classes={{ paper: styles.drawerPaper }}
				className={clsx(styles.root, screenAtDesktopWidth && styles.rootWithWidth)}
				elevation={0}
				open={screenAtDesktopWidth || (!screenAtDesktopWidth && openForMobile)}
				variant={screenAtDesktopWidth ? 'permanent' : 'temporary'}
			>
				<div id="scrollbar-container" className={styles.scrollBarContainer}>
					<Hidden mdUp>
						<div
							className={styles.closeWrapper}
							onClick={() => dispatch(setDebtPanelOpen(false))}
							onKeyDown={() => dispatch(setDebtPanelOpen(false))}
							role="button"
							tabIndex={0}
							style={{ zIndex: 2000 }}
						>
							<Times className={styles.closeIcon} />
							Close
						</div>
					</Hidden>
					<div className={styles.drawerContent}>
						<Grid alignItems="center" container direction="row" justifyContent="flex-start">
							<Grid item xs={2}>
								<Paper className={styles.debtBranding} elevation={0}>
									<div className={styles.squareSpacing}>
										{loading ? (
											<Skeleton variant="rect" />
										) : (
											<DebtBrandLogo color="default" clientDisplayName={customerDetails?.clientLetterName ?? ''} />
										)}
									</div>
								</Paper>
							</Grid>
							<Grid className={styles.debtTitleWrapper} item xs={10}>
								<Typography variant="h6">{loading ? <Skeleton /> : customerDetails?.clientLetterName}</Typography>
								<Typography component="p" variant="subtitle2">
									{loading ? <Skeleton /> : customerDetails?.clientReference}
								</Typography>
							</Grid>
						</Grid>
						<Divider />
						<Grid alignItems="flex-end" className={styles.detailRow} container direction="row" justifyContent="center">
							<Grid item sm={6} xs={6}>
								{loading ? (
									<Skeleton />
								) : (
									<CurrencyFancyDisplay className={styles.currencyDisplay} value={customerDetails?.outstandingAmt ?? 0} />
								)}
								<Typography className={styles.sectionBody} component="p" variant="subtitle2">
									Outstanding balance
								</Typography>
							</Grid>
							<Grid item sm={6} xs={6}>
								<Typography className={styles.sectionTitle} component="p" variant="subtitle2">
									{loading ? <Skeleton /> : customerDetails?.accountName}
								</Typography>
								<Typography className={styles.sectionBody} component="p" variant="subtitle2">
									Account name
								</Typography>
							</Grid>
						</Grid>
						<Grid alignItems="flex-end" className={styles.detailRow} container direction="row" justifyContent="center">
							<Grid item sm={6} xs={6}>
								<Typography className={styles.sectionTitle} component="p" variant="subtitle2">
									{loading ? <Skeleton /> : customerDetails?.Reference}
								</Typography>
								<Typography className={styles.sectionBody} component="p" variant="subtitle2">
									ARL Reference
								</Typography>
							</Grid>
							<Grid item sm={6} xs={6}>
								<Typography className={styles.sectionTitle} component="p" variant="subtitle2">
									{loading ? <Skeleton /> : customerDetails?.clientReference}
								</Typography>
								<Typography className={styles.sectionBody} component="p" variant="subtitle2">
									Original client reference
								</Typography>
							</Grid>
						</Grid>

						{(customerDetails?.outstandingAmt ?? 0) > 0 && (
							<Hidden smDown>
								<Box alignItems="center" display="flex" justifyContent="space-between" className={styles.progress}>
									<LinearProgress style={{ marginRight: spacingUnits(1) }} value={progress} variant="determinate" />

									<Box component="span" fontSize="1.2rem" fontWeight="bold" lineHeight={1} mb="1px">
										{progress}%
									</Box>
								</Box>
							</Hidden>
						)}

						<BottomNavigation showLabels>
							<BottomNavigationAction label="Summary" icon={<Assignment />} component={Link} to={SettingsRoutes.SUMMARY.path} />
							<BottomNavigationAction label="Contacts" icon={<Contacts />} component={Link} to={SettingsRoutes.CONTACTS.path} />
							<BottomNavigationAction label="Payments" icon={<AccountBalance />} component={Link} to={SettingsRoutes.PAYMENTS.path} />
							<BottomNavigationAction label="Schedules" icon={<Timer />} component={Link} to={SettingsRoutes.SCHEDULES.path} />
						</BottomNavigation>
					</div>
				</div>
			</Drawer>
		</>
	);
};
