import React from 'react';
import useStyles from './default.styles';
import { AssistantCTACard } from '../../../../elements/AssistantCTACard/assistantCTACard';
import { DismissableCardEvents } from '../../../../elements/DismissableCard/types';
import { ContactPhone } from '../../../../elements/ContactPhone/contactPhoneNumber';

export const DefaultCard: React.FC<DismissableCardEvents> = ({ onDismiss, showCard = true }) => {
	const styles = useStyles();
	return (
		<AssistantCTACard
			subtitle={
				<>
					From here you can manage your account as well as set up payment plans and make one off payments on your account. If you have any
					questions please call us on <span className={styles.bold}>{ContactPhone()}</span>.
				</>
			}
			title="Welcome to your account dashboard"
			onDismiss={onDismiss}
			showCard={showCard}
		/>
	);
};
