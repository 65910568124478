import { Typography } from '@material-ui/core';

const CardTerms = () => {
	return (
		<>
			<Typography variant="subtitle1">Initial Terms of Agreement</Typography>
			<Typography variant="body1">
				In the terms of the arrangements made between us and you, we undertake to periodically debit your debit/credit card for the agreed
				amount for payment of your outstanding debt.
			</Typography>
			<Typography variant="body1">
				You will be required to give at least 3 days notice in writing when you require any changes to the initial terms of the arrangement.
			</Typography>
			<Typography variant="body1">
				In the event the payment dishonours, the payment will be retried up to 7 days after the scheduled payment.
			</Typography>
			<Typography variant="body1">This arrangement may increase your monthly commitment to your credit provider.</Typography>
			<Typography variant="body1">
				The terms of your arrangement may vary according to the applicable interest or fees incurred on your account.
			</Typography>
			<Typography variant="body1">
				If the debit is returned unpaid your payment arrangement may be cancelled and we will contact you seeking the full balance.
			</Typography>

			<Typography variant="subtitle1">Drawing Arrangements</Typography>
			<Typography variant="body1">
				The first drawing under this agreement will be drawn on the nominated day. If any drawing falls due on a non-business day, it will be
				debited from your debit/credit card the previous business day before the scheduled drawing date.
			</Typography>
			<Typography variant="subtitle1">Changes to the Arrangement</Typography>
			<Typography variant="body1">
				If you want to make changes to the drawing arrangements, contact us directly by phone on {window.config.REACT_APP_CONTACT_NUMBER}.
			</Typography>
			<Typography variant="subtitle1">Your Details</Typography>
			<Typography variant="body1">
				Any information regarding the storage of your information is covered under ARL Collect's privacy policy.
			</Typography>
		</>
	);
};

export default CardTerms;
