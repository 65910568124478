import { makeStyles } from '@material-ui/core/styles';
import { getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			border: `1px solid ${getThemeStyle('paletteGrey50')}`,
			borderRadius: 8,
			display: 'flex',
			alignItems: 'center',
			paddingLeft: spacingUnits(1),
			paddingTop: spacingUnits(0.75),
			paddingRight: 0,
			paddingBottom: spacingUnits(0.75),

			'&$rootIsOpen': {
				backgroundColor: getThemeStyle('paletteSecondary900'),

				'& .MuiTypography-subtitle2': {
					color: getThemeStyle('paletteCommonWhite'),
				},

				'& $caret': {
					fill: getThemeStyle('paletteCommonWhite'),
				},

				'& $avatar': {
					backgroundColor: getThemeStyle('paletteSecondary400'),
					border: `1px solid ${getThemeStyle('paletteSecondary500')}`,
				},
			},

			'& $avatar': {
				backgroundColor: getThemeStyle('paletteSecondary600'),
				height: 28,
				width: 28,
				marginRight: spacingUnits(0.5),
				border: `1px solid ${getThemeStyle('paletteSecondary700')}`,
				'& svg': {
					height: 20,
					width: 20,
					fill: getThemeStyle('paletteSecondary50'),
					transform: 'translateY(3px)',
				},
			},
			'& $userName': {
				fontWeight: 500,
			},
			'& $caret': {
				fill: getThemeStyle('paletteGrey500'),
			},
		},
		rootIsOpen: {},
		avatar: {},
		userName: {},
		caret: {},
		dropDown: {
			'& svg': {
				height: 20,
			},
			'& .MuiListItemIcon-root': {
				'min-width': 28,
				fill: getThemeStyle('paletteSecondary500'),
			},
		},
	},
	{ name: 'userMenu' },
);
