import { lighten, makeStyles } from '@material-ui/core/styles';
import MuiTheme from '../../../../../../theme';
import { getStrictDimensionsForDiameter, getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		section: {
			marginBottom: spacingUnits(4),
			[MuiTheme.breakpoints.down('sm')]: {
				margin: [[0, -spacingUnits(2), spacingUnits(4), -spacingUnits(2)]],
			},

			'& .MuiTablePagination-selectRoot': {
				border: 0,
				fontSize: '1.4rem',
				backgroundColor: 'inherit',
			},

			'& .Mui-selected': {
				backgroundColor: lighten(getThemeStyle('paletteErrorLight'), 0.85),

				'&:hover': {
					backgroundColor: lighten(getThemeStyle('paletteErrorLight'), 0.85),
				},
			},
		},

		failedPaymentRow: {
			'& .MuiTableCell-body': {
				color: getThemeStyle('paletteErrorDark'),
			},
		},

		svgIcon: {
			'& svg': {
				...getStrictDimensionsForDiameter(30),
			},
		},

		paymentResponse: {
			flexBasis: 100,
			backgroundColor: getThemeStyle('paletteGrey100'),
		},

		buttons: {},
	},
	{ name: 'paymentsTab' },
);
