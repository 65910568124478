import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';
import { WebRoutes } from '../../enums/routerPath';
import { RootState } from '../../store/rootReducer';
import { IPageProps, Page } from '../page';

export interface IArrangementSuccessRouteProps extends RouteProps {
	redirectPath?: string;
}

export const ArrangementSuccessRoute: React.FC<IArrangementSuccessRouteProps & IPageProps> = ({ title, redirectPath, component, ...props }) => {
	const arrangementSuccess = useSelector((state: RootState) => state.paymentForm.paymentSuccess?.arrangement);
	return arrangementSuccess ? <Page title={title} {...props} component={component} /> : <Redirect to={redirectPath ?? WebRoutes.HOME.path} />;
};
