import { Card, Grid, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { CurrencyFancyDisplay } from '../../../../../../../elements/CurrencyFancyDisplay/currencyFancyDisplay';
import { MasterCard, Visa } from '../../../../../../../elements/PantheraIcon';
import { CardType } from '../../../../../../../enums/paymentForm';
import useStyles from './paymentFailedCard.styles';

export type PaymentFailedCardProps = {
	title: string;
	subTitle: string;
	paymentAmount: string;
	last4Digits: string;
	cardTypeID: CardType | undefined;
	actions: ReactNode;
};

export const PaymentFailedCard: React.FC<PaymentFailedCardProps> = ({ title, subTitle, paymentAmount, last4Digits, cardTypeID, actions }) => {
	const styles = useStyles();

	return (
		<>
			<AssistantCTAHollow subtitle={subTitle} title={title}>
				<Grid container justifyContent="center">
					<Card className={styles.paymentFailedCard} elevation={8}>
						<div className={styles.declinedInfo}>
							<Typography className={styles.declinedResponse}>Payment unsuccessful</Typography>
							<CurrencyFancyDisplay className={styles.declinedValue} value={Number(paymentAmount)} />
							<Typography className={styles.declinedCard} variant="body2">
								{cardTypeID && cardTypeID === CardType.MASTER ? <MasterCard /> : <Visa />} {last4Digits}
							</Typography>
						</div>
					</Card>
				</Grid>
			</AssistantCTAHollow>

			<ButtonStack className={styles.buttonStack}>{actions}</ButtonStack>
		</>
	);
};
