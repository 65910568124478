import { makeStyles } from '@material-ui/core/styles';
import { spacingUnits } from '../../../../../theme/theme';

export default makeStyles({
	root: {},
	card: {
		padding: [[spacingUnits(3), spacingUnits(2)]],
	},
	buttonStack: {
		marginTop: '1rem!important',
	},
});
