import React from 'react';

export const PCI = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 251.1 251.1" {...props}>
		<path
			d="M94.34 58.67h8.89a5.66 5.66 0 015.66 5.66v168.11a5.66 5.66 0 01-5.66 5.66H18.36a5.66 5.66 0 01-5.66-5.66V64.33a5.66 5.66 0 015.66-5.66h8.89"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<path fill="none" stroke="#c00000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={5} d="M27.25 107.97h67.09v31.52H27.25z" />
		<path fill="#fccccc" d="M82.94 119.38v8.72H38.66v-8.72z" />
		<path
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
			d="M27.25 82.71h67.09M27.25 36.24v-9.7A13.54 13.54 0 0140.79 13h40a13.54 13.54 0 0113.55 13.54v9.7M94.34 36.24V71.7M27.25 71.7V36.24M39.73 39.88h42.13M48.06 53.97h25.46M108.89 94.43h20.72a6.35 6.35 0 016.35 6.36v104.89a6.35 6.35 0 01-6.35 6.36h-20.72M38.12 172.9h7.2M57.19 172.9h7.21M76.27 172.9h7.2M38.12 189.78h7.2M57.19 189.78h7.21M76.27 189.78h7.2M38.12 206.66h7.2M57.19 206.66h7.21M76.27 206.66h7.2"
		/>
		<circle
			cx={177.81}
			cy={188.27}
			r={7.98}
			transform="rotate(-76.76 177.82 188.278)"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<rect
			x={135.86}
			y={108.71}
			width={126.27}
			height={78.8}
			rx={10.95}
			transform="rotate(-90 198.995 148.115)"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<rect x={167.05} y={102.44} width={21.5} height={15.95} rx={4.07} transform="rotate(-90 177.805 110.415)" fill="#fccccc" />
		<path
			d="M185.78 171.76a8 8 0 10-15.95 0M221.21 193.17v-13.9M221.21 167.77v-13.91M221.21 142.37v-13.91M221.21 116.97v-13.91"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
	</svg>
);
