import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../../../theme';
import { bodyContentMarketingMaxWidth, getStrictDimensionsForDiameter, getThemeStyle, spacingUnits } from '../../../../../../theme/theme';

const container = {
	width: '100%',
	margin: [[0, 'auto']],
	maxWidth: bodyContentMarketingMaxWidth + spacingUnits(5.5),
};

export default makeStyles(
	{
		root: {
			'& $responsibility': {
				marginTop: spacingUnits(7),
				[MuiTheme.breakpoints.up('md')]: {
					marginTop: spacingUnits(5),
				},
			},
			'& > ul': {
				fontSize: getThemeStyle('typographyBody1FontSize'),
				lineHeight: getThemeStyle('typographyBody1LineHeight'),
				[MuiTheme.breakpoints.up('md')]: {
					fontSize: getThemeStyle('typographyBody1DesktopFontSize'),
					lineHeight: getThemeStyle('typographyBody1DesktopLineHeight'),
				},
			},
		},
		container: {
			...container,
			padding: [[spacingUnits(7), spacingUnits(2.75), spacingUnits(12)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},
		},
		externalLink: {
			display: 'inline-flex',
			alignItems: 'center',

			color: getThemeStyle('palettePrimary500'),
			fill: getThemeStyle('palettePrimary500'),
			fontWeight: 500,

			'& > svg': getStrictDimensionsForDiameter(18),
		},
		responsibility: {},
	},
	{
		name: 'ourCommitment',
	},
);
