import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import store from '../store/store';
// eslint-disable-next-line import/no-cycle
import { logout } from '../store/features/auth/authSlice';
import { setErrorModalInfo } from '../store/features/errorModal/errorModalSlice';

const arlApi = axios.create({
	baseURL: window.config.REACT_APP_API_URL + (process.env.NODE_ENV === 'development' ? '' : '/fn'),
});

arlApi.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('token');
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		config.headers['Content-Type'] = 'application/json';
		return config;
	},
	(error) => {
		Promise.reject(error);
	},
);

arlApi.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// if there is no response, then most likely a CORS error
		if (!error.response) {
			error.message = 'An unknown error occurred.';
			if (error.response?.data) {
				error.response.data.message = 'An unknown error occurred.';
			}
		}
		// Brute force protection limit reached
		if ((error.response.status === 429 || error.response.status === 403) && error.response.headers['retry-after']) {
			const t = new Date();
			t.setSeconds(t.getSeconds() + Number(error.response.headers['retry-after']));
			const msg = `Sorry, this request has been blocked for security reasons. Please try again ${formatDistanceToNow(t, {
				includeSeconds: true,
				addSuffix: true,
			})}.`;
			error.message = msg;
			if (error.response?.data) {
				error.response.data.message = msg;
			}
		}
		// DB Connection issue - Display as modal instead of snackbar
		if (error.response?.status === 503) {
			if (error.response?.data) {
				// Prevent snackbar from also appearing
				error.response.data.message = undefined;
			}
			store.dispatch(
				setErrorModalInfo({
					title: 'An error has occurred',
					alert: "Sorry we're currently experiencing a technical issue, please try again in 5 minutes. If your issue is urgent you can contact us via phone.",
				}),
			);
		}
		if (error.response?.status === 401) {
			store.dispatch(logout());
		}
		return Promise.reject(error);
	},
);

export default arlApi;
