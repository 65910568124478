import { getISODay, add, getHours, getMinutes, isSaturday, isFriday } from 'date-fns';

export const nextBusinessDate = (selectedDate: Date) => {
	let dayIncrement = 1;

	if (getISODay(selectedDate) === 5) {
		// set to monday
		dayIncrement = 3;
	} else if (getISODay(selectedDate) === 6) {
		// set to monday
		dayIncrement = 2;
	} else if (getISODay(selectedDate) === 7) {
		// set to monday
		dayIncrement = 1;
	}

	return add(selectedDate, { days: dayIncrement });
};

export const isBusinessHours = () => {
	let isDuringBusiness = false;
	const currentDate = new Date();
	const currentTimeMins = getHours(currentDate) * 60 + getMinutes(currentDate);
	// Saturday 9am 3pm
	if (isSaturday(currentDate) && currentTimeMins >= 9 * 60 && currentTimeMins <= 15 * 60) {
		isDuringBusiness = true;
	}
	// Friday 7:30am 6:30pm
	if (isFriday(currentDate) && currentTimeMins >= 7 * 60 + 30 && currentTimeMins <= 18 * 60 + 30) {
		isDuringBusiness = true;
	}
	// Mon - Thurs 7:30am 8pm
	if (getISODay(currentDate) >= 1 && getISODay(currentDate) <= 4 && currentTimeMins >= 7 * 60 + 30 && currentTimeMins <= 20 * 60) {
		isDuringBusiness = true;
	}
	return isDuringBusiness;
};
