import { darken, makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../../../theme';
import {
	bodyContentMarketingMaxWidth,
	getStrictDimensionsForDiameter,
	getThemeStyle,
	spacingUnits,
	subtleBackgroundColor,
} from '../../../../../../theme/theme';
import family from '../../../../../../static/images/help-centre/family.jpg';
import surfers from '../../../../../../static/images/help-centre/surfers.jpg';
import customerSupport from '../../../../../../static/images/help-centre/customer-support.jpg';

const container = {
	width: '100%',
	margin: [[0, 'auto']],
	maxWidth: bodyContentMarketingMaxWidth + spacingUnits(5.5),
};

export default makeStyles(
	{
		container: {
			...container,
			padding: [[spacingUnits(7), spacingUnits(2.75), spacingUnits(12)]],
			[MuiTheme.breakpoints.up('md')]: {
				padding: [[spacingUnits(9), spacingUnits(2.75), spacingUnits(14)]],
			},
		},
		covidCard: {
			backgroundColor: darken(subtleBackgroundColor, 0.025),
			padding: [[spacingUnits(3), spacingUnits(2)]],
			[MuiTheme.breakpoints.up('md')]: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: [[spacingUnits(4)]],
			},
			'& $image': {
				...getStrictDimensionsForDiameter(151),
				flexGrow: 1,
				flexShrink: 0,
				borderRadius: '100%',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundImage: `url('${family}')`,
			},
			'& $content': {
				marginBottom: spacingUnits(2),
				[MuiTheme.breakpoints.up('md')]: {
					margin: [[0, spacingUnits(4)]],
					'& > p': {
						fontSize: '1.8rem',
						lineHeight: '2.6rem',
					},
				},
				'& > h6, & > h4': {
					marginBottom: spacingUnits(1),
				},
				'& > p': {
					fontSize: '1.6rem',
				},
			},
			'& $button': {
				[MuiTheme.breakpoints.up('md')]: {
					flexShrink: 0,
					maxWidth: 135,
					marginRight: spacingUnits(1),
				},
			},
		},

		imageCard: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),

			[MuiTheme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row-reverse',
			},
			'& > figure': {
				backgroundImage: `url('${surfers}')`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: '100% 75%',
				backgroundSize: '140%',
				height: 198,
				margin: 0,
				width: '100%',
				[MuiTheme.breakpoints.up('md')]: {
					backgroundImage: `url('${customerSupport}')`,
					backgroundPosition: 'top right',
					backgroundSize: 'cover',
					width: 550,
					height: 530,
					flexShrink: 0,
				},
			},

			'& > div': {
				padding: [[spacingUnits(3), spacingUnits(2)]],
				[MuiTheme.breakpoints.up('md')]: {
					display: 'flex',
					flexDirection: 'column',
					padding: [[spacingUnits(9), spacingUnits(6), spacingUnits(8)]],
					'& $button': {
						marginTop: 'auto',
						maxWidth: 200,
					},
				},
			},
		},
		image: {},
		content: {},
		button: {},
	},
	{
		name: 'support',
	},
);
