import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';
import { WebRoutes } from '../../enums/routerPath';
import { RootState } from '../../store/rootReducer';
import { IPageProps, Page } from '../page';

export interface IPaymentAmountRouteProps extends RouteProps {
	redirectPath?: string;
}

export const PaymentAmountRoute: React.FC<IPaymentAmountRouteProps & IPageProps> = ({ title, component, redirectPath, ...rest }) => {
	const paymentAmount = useSelector((state: RootState) => state.paymentForm.paymentAmount);

	return Number(paymentAmount) > 0 ? (
		<Page title={title} {...rest} component={component} />
	) : (
		<Redirect to={redirectPath ?? WebRoutes.ACCOUNT.path} />
	);
};
