import { makeStyles } from '@material-ui/core/styles';
import { getThemeStyle, spacingUnits } from '../../../../theme/theme';
import MuiTheme from '../../../../theme';

export default makeStyles(
	{
		root: {
			zIndex: getThemeStyle('zIndexDrawer') + 1,
			'& $backArrow': {
				borderRadius: 6,
				backgroundColor: getThemeStyle('palettePrimary50'),
				marginRight: spacingUnits(1),
				padding: spacingUnits(1),
				display: 'flex',
				'& > *': {
					width: 24,
					fill: getThemeStyle('paletteTertiary500'),
				},
			},
			'& $logo': {
				display: 'flex',
				alignItems: 'center',
				flexGrow: 1,
				justifyContent: 'flex-start',
				marginRight: spacingUnits(1),

				'& > *': {
					height: 32,
				},
			},
		},
		backArrow: {},
		logo: {},
		[MuiTheme.breakpoints.up('sm')]: {
			root: {
				'& $backArrow': {
					marginRight: spacingUnits(3),
				},
				'& $logo': {
					'& > *': {
						height: 38,
					},
				},
				'@global .MuiToolbar-root > :first-child': {
					marginLeft: spacingUnits(-1.5),
				},
			},
		},
		[MuiTheme.breakpoints.up('md')]: {
			root: {
				'& $logo': {
					flexGrow: 'unset',
				},
				'@global .MuiToolbar-root > :first-child': {
					marginLeft: spacingUnits(-0.5),
				},
			},
		},
	},
	{ name: 'headerLoggedIn' },
);
