import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import arlApi from '../../../../interceptor/api';
import { AppThunk } from '../../../store';
import { updateCustomerMethods } from '../../paymentMethod/paymentMethodSlice';
import { CustomerAPI, ICustomerDetailsOutput, ICustomerGet } from '../types';

export interface CustomerState {
	customerDetails: ICustomerDetailsOutput | undefined;
	loading: boolean;
	error: string | undefined;
	isLoaded: boolean;
}

const initialState: CustomerState = {
	customerDetails: undefined,
	loading: false,
	error: undefined,
	isLoaded: false,
};

export const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		getCustomerDetails(state: CustomerState) {
			state.loading = true;
			state.customerDetails = undefined;
			state.error = undefined;
			state.isLoaded = false;
		},
		getCustomerDetailsSuccess(state: CustomerState, action: PayloadAction<ICustomerDetailsOutput>) {
			state.loading = false;
			state.customerDetails = action.payload;
			state.error = undefined;
			state.isLoaded = true;
		},
		getCustomerDetailsFailure(state: CustomerState, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
			state.isLoaded = true;
		},
	},
});

export const { getCustomerDetails, getCustomerDetailsSuccess, getCustomerDetailsFailure } = customerSlice.actions;

export default customerSlice.reducer;

export const fetchCustomerDetails = (): AppThunk => async (dispatch) => {
	try {
		dispatch(getCustomerDetails());
		const { data } = await arlApi.get<ICustomerGet>(CustomerAPI.CUSTOMER_DETAILS);
		if (data.success) {
			dispatch(getCustomerDetailsSuccess(data.data.customer));
			// Remove unavailable payment types
			dispatch(updateCustomerMethods(data.data.customer.clientPaymentMethods));
		} else {
			dispatch(getCustomerDetailsFailure(data.message));
		}
	} catch (err) {
		if (err.isAxiosError) {
			const e: AxiosError = err;
			dispatch(getCustomerDetailsFailure(e.response?.data.message));
		} else {
			dispatch(getCustomerDetailsFailure('An unknown error occured.'));
		}
	}
};
