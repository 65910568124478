import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearTimedOut } from '../../store/features/auth/authSlice';
import { RootState } from '../../store/rootReducer';

export const SessionTimeoutSnackbar = () => {
	const dispatch = useDispatch();

	const timedOut = useSelector((state: RootState) => state.auth.timedOut ?? false);

	const handleClearTimedOut = useCallback(() => {
		dispatch(clearTimedOut());
		document.body.removeEventListener('click', handleClearTimedOut);
	}, [dispatch]);

	useEffect(() => {
		if (timedOut) {
			document.body.addEventListener('click', handleClearTimedOut);
		}
		return () => {
			document.body.removeEventListener('click', handleClearTimedOut);
		};
	}, [handleClearTimedOut, timedOut]);

	return (
		<>
			<Snackbar open={timedOut} autoHideDuration={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert variant="filled" severity="warning">
					You have been automatically logged out after inactivity. Please login again.
				</Alert>
			</Snackbar>
		</>
	);
};
