import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle } from '../../theme/theme';

export default makeStyles(
	{
		root: {
			'@global .MuiTabs-root': {
				backgroundColor: getThemeStyle('palettePrimary500'),
			},
		},
	},
	{ name: 'tabbedCard' },
);
