import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Hidden } from '@material-ui/core';
import { RootState } from '../../../../../../../store/rootReducer';
import { DefaultCard } from '../../../../Default/default';
import { DashboardPayoffOptions } from '../../../../DashboardPayoffOptions/dashboardPayOffOptions';
import { DebtDetailsCard } from '../../../../DebtDetailsPanel/DebtDetailsCard/debtDetailsCard';
import { setActionCardOpen } from '../../../../../../../store/features/accountLayout/accountLayoutSlice';

export const MakeQuickPaymentDashboard: React.FC = () => {
	const actionCardOpen = useSelector((state: RootState) => state.accountLayout.actionCardOpen);
	const dispatch = useDispatch();
	return (
		<>
			<Hidden smDown>
				<DefaultCard onDismiss={() => dispatch(setActionCardOpen(false))} showCard={actionCardOpen} />
				<DashboardPayoffOptions />
			</Hidden>
			<Hidden mdUp>
				<DebtDetailsCard color="primary" />
				<DefaultCard onDismiss={() => dispatch(setActionCardOpen(false))} showCard={actionCardOpen} />
				<DashboardPayoffOptions />
			</Hidden>
		</>
	);
};
