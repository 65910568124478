import { makeStyles, Theme } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../theme/theme';

export interface StyleProps {
	variant: string;
	dividerTop?: string | number;
	center: boolean;
	isSingle: boolean;
}

export default makeStyles<Theme, StyleProps>(
	{
		root: ({ variant, isSingle }) => ({
			border: `1px solid ${getThemeStyle('palettePrimary50')}`,
			overflow: 'visible',
			position: 'relative',
			width: isSingle ? '60%' : '100%',

			...(isSingle && {
				minWidth: 'auto',
				marginLeft: 'auto',
				marginRight: 'auto',
			}),

			...(variant === 'transparent' && {
				border: 'none',
				boxShadow: 'none',
			}),
		}),

		itemWrapper: {
			display: 'flex',
			overflow: 'visible',
		},

		itemOuter: ({ variant, center }) => ({
			display: 'flex',
			justifyContent: 'center',

			width: '100%',

			...(variant === 'transparent' &&
				!center && {
					'&:first-of-type': {
						justifyContent: 'flex-start',
					},

					'&:last-of-type': {
						justifyContent: 'flex-end',
					},
				}),
		}),

		item: ({ variant }) => ({
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-start',

			marginTop: 0,
			padding: [[spacingUnits(3), spacingUnits(1.75), spacingUnits(3), spacingUnits(1.75)]],
			position: 'relative',

			...(variant === 'transparent' && {
				backgroundColor: 'transparent',
				marginTop: 0,
				padding: 0,

				'& h2': {
					marginTop: spacingUnits(1.5),
				},
			}),

			'& button': {
				cursor: 'default',
				marginBottom: '0.75rem',

				'&.MuiButton-outlinedPrimary': {
					backgroundColor: getThemeStyle('paletteCommonWhite'),
				},
			},

			'& svg': {
				fill: getThemeStyle('paletteSecondary500'),
			},
		}),

		itemHeader: {
			fontSize: '1.6rem',
			fontWeight: 600,
			letterSpacing: '-0.5px',
			lineHeight: 1,
			marginTop: '-2px',
			marginBottom: '10px',
		},

		iconContainer: {
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'center',

			height: '4.8rem',
			marginBottom: '1rem',
			width: '100%',

			'& svg': {
				fill: getThemeStyle('paletteTertiary600'),
				height: '100%',
			},
		},

		specification: {
			fontWeight: 500,
			lineHeight: 1.125,
			textAlign: 'center',

			'&:not(:first-of-type)': {
				marginTop: '0.5rem',
			},

			'& svg': {
				fill: getThemeStyle('paletteSecondary500'),
				marginRight: '4px',
				marginBottom: '-2px',
				width: '14px',
			},
		},

		amountDescription: {
			fontSize: '1.6rem',
			fontWeight: 500,
			letterSpacing: '-0.5px',
			lineHeight: 1,
			marginTop: '-2px',
		},

		divider: ({ dividerTop }) => ({
			position: 'relative',

			'& span': {
				borderLeft: `1px solid ${getThemeStyle('palettePrimary50')}`,
				display: 'block',
				height: '100%',
			},

			'& div': {
				position: 'absolute',
				top: dividerTop || '0',
				bottom: '0',
				left: '0',

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				backgroundColor: getThemeStyle('paletteSecondary800'),
				border: `1px solid ${getThemeStyle('paletteSecondary900')}`,
				borderRadius: '50%',
				color: getThemeStyle('paletteCommonWhite'),
				fontWeight: 'bold',
				height: '3.6rem',
				marginBottom: 'auto',
				marginTop: 'auto',
				transform: 'translateX(-50%)',
				width: '3.6rem',
			},
		}),

		footer: ({ variant }) => ({
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			backgroundColor: getThemeStyle('paletteCommonLightWhite'),
			borderBottomLeftRadius: 'inherit',
			borderBottomRightRadius: 'inherit',
			borderTop: `1px solid ${getThemeStyle('palettePrimary50')}`,
			padding: [[spacingUnits(2), spacingUnits(1.75), spacingUnits(3)]],

			...(variant === 'transparent' && {
				backgroundColor: 'transparent',
				borderTop: 'none',
				paddingLeft: 0,
				paddingRight: 0,
			}),
		}),

		hasIdentifier: {
			paddingTop: '0 !important',
			marginTop: '-1.4rem !important',
		},
	},
	{ name: 'comparisonCard' },
);
