import { Box, Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { FourOhFourIcon } from '../../../../elements/PantheraIcon/FourOhFourIcon';
import { WebRoutes } from '../../../../enums/routerPath';
import { CustomerFooter } from '../../elements/Footer/footer';
import { CustomerHeader } from '../../elements/Header/header';
import useStyles from './fourOhFour.styles';

export const FourOhFour: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root} width="100%">
			<CustomerHeader />
			<Container fixed className={styles.container}>
				<Typography variant="h1">Page Not Found</Typography>
				<Typography variant="body1">Sorry, but the page you were trying to view does not exist.</Typography>
				<FourOhFourIcon className={styles.icon} />
				<Button fullWidth color="primary" variant="contained" className={styles.button} component={Link} to={WebRoutes.HOME.path}>
					Back to home
				</Button>
			</Container>
			<CustomerFooter />
		</Box>
	);
};
