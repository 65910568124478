import { Button, Card } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { CTAHeadings } from '../../../../../elements/CTAHeadings/cTAHeadings';
import { CardPaymentForm } from '../../../../../elements/Forms/elements/CardPaymentForm/cardPaymentForm';
import { SpinnerButton } from '../../../../../elements/SpinnerButton/spinnerButton';
import { PaymentMode } from '../../../../../enums/paymentForm';
import { calculateMerchantFeeStrings } from '../../../../../services/helpers/paymentForm.helpers';
import { formatMoney } from '../../../../../services/helpers/text.helpers';
import { history } from '../../../../../store/history';
import { RootState } from '../../../../../store/rootReducer';
import useStyles from './creditCard.styles';

export const CreditCard: React.FC = () => {
	const styles = useStyles();
	const { loading, paymentAmount, merchantFeePercent, paymentMode, ccPIFOnly } = useSelector((state: RootState) => ({
		loading: state.paymentForm.loading,
		paymentAmount: state.paymentForm.paymentAmount,
		merchantFeePercent: state.customer.customerDetails?.merchantFeePercent,
		paymentMode: state.paymentForm.paymentMode,
		ccPIFOnly: state.paymentMethod.ccPIFOnly,
	}));

	const [total, SetTotal] = React.useState('0');

	useEffect(() => {
		SetTotal(calculateMerchantFeeStrings(paymentAmount, merchantFeePercent).total);
	}, [paymentAmount, merchantFeePercent]);

	return (
		<>
			<Card className={styles.card} elevation={8}>
				<CTAHeadings title="Enter your card details" subtitle="Let’s finalise your payment" />
				{ccPIFOnly && !!merchantFeePercent && (
					<Alert className={styles.merchantFee} severity="info">
						A Visa or MasterCard surcharge of {merchantFeePercent}% has been added to the payment amount.
					</Alert>
				)}
				<CardPaymentForm autoFocus={true} className={styles.form} />
			</Card>
			<ButtonStack>
				<SpinnerButton form="cardPaymentForm" color="secondary" fullWidth size="large" variant="contained" type="submit" loading={loading}>
					{paymentMode === PaymentMode.NOW ? `Pay ${formatMoney(parseFloat(total))}` : 'Continue'}
				</SpinnerButton>
				<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => history.goBack()}>
					Back
				</Button>
			</ButtonStack>
		</>
	);
};
