import { makeStyles, Link, Typography } from '@material-ui/core';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { BubbleSms, PhoneCall } from '../../../../../elements/PantheraIcon';
import { Mailbox } from '../../../../../elements/PantheraIcon/Mailbox';
import { Spacing } from '../../../../../elements/Spacing/spacing';
import { WebRoutes } from '../../../../../enums/routerPath';
import { brandMexicanRed, spacingUnits, textGrey } from '../../../../../theme/theme';

const useStyles = makeStyles(
	(theme) => ({
		contactDetailsSection: {
			display: 'flex',
			alignItems: 'flex-start',

			'& > svg': {
				fill: brandMexicanRed,
				width: 40,
			},

			'& > div': {
				marginLeft: spacingUnits(1.5),

				'& .MuiTypography-root': {
					lineHeight: 1.25,

					'& b': {
						fontWeight: 500,
					},
				},
			},

			'& small': {
				marginLeft: spacingUnits(0.75),
				fontSize: '1.2rem',
			},

			'& ul': {
				listStyle: 'none',
				margin: '0 !important',
				padding: 0,

				'& > li': {
					color: textGrey,
					fontSize: '1.6rem',
					fontWeight: 500,

					'& > span': {
						display: 'inline-block',
						width: 45,
					},
				},
			},

			'& a': {
				color: textGrey,
				textDecoration: 'underline',
			},
		},
	}),
	{ name: 'contactDetails' },
);

type ContactDetailsProps = {
	isBusiness?: boolean;
};

export const ContactDetails: React.FC<ContactDetailsProps> = ({ isBusiness }) => {
	const styles = useStyles();
	return (
		<Spacing units={3}>
			{!isBusiness && (
				<div className={styles.contactDetailsSection}>
					<BubbleSms />
					<Spacing units={1}>
						<Typography variant="h4">SMS chat</Typography>
						<Typography variant="body1">
							To start a text message
							<br />
							<a href={window.config.REACT_APP_CONTACT_MOBILE_HREF}>
								<strong>{window.config.REACT_APP_CONTACT_MOBILE}</strong>
							</a>
						</Typography>
						<Typography variant="body1">
							<Link component={ReactLink} to={WebRoutes.COMPLAINTS.path}>
								View our Complaints Policy and Procedure
							</Link>
						</Typography>
					</Spacing>
				</div>
			)}
			<div className={styles.contactDetailsSection}>
				<Mailbox />
				<Spacing units={1}>
					<Typography variant="h4">Mailing address</Typography>
					<Typography variant="body1">
						<b>
							PO BOX 14390,
							<br />
							City Mail Centre, Melbourne, VIC, 8001
						</b>
					</Typography>
				</Spacing>
			</div>
			<div className={styles.contactDetailsSection}>
				<PhoneCall />
				<Spacing units={1}>
					<Typography variant="h4">Call</Typography>
					<Typography variant="body1">
						Within Australia
						<br />
						<b>{window.config.REACT_APP_CONTACT_NUMBER}</b>
					</Typography>
					<Typography variant="body1">
						Overseas
						<br />
						<b>{window.config.REACT_APP_CONTACT_OVERSEAS}</b>
					</Typography>
					<Typography variant="body1">
						Open hours <small>VIC (AEST)</small>
					</Typography>
					<ul>
						<li>
							<span>Mon:</span> 8:00am - 6:00pm
						</li>
						<li>
							<span>Tue:</span> 8:00am - 6:00pm
						</li>
						<li>
							<span>Wed:</span> 8:00am - 6:00pm
						</li>
						<li>
							<span>Thu:</span> 8:00am - 6:00pm
						</li>
						<li>
							<span>Fri:</span> 8:00am - 6:00pm
						</li>
						<li>
							<span>Sat:</span> 9:00am - 2:00pm
						</li>
						<li>
							<span>Sun:</span> Closed
						</li>
					</ul>
				</Spacing>
			</div>
		</Spacing>
	);
};
