/* eslint-disable max-len */
import { Box, Button, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Fintech } from '../../../../elements/Illustrations/Fintech';
import { Lock } from '../../../../elements/Illustrations/Lock';
import { LinkArrow } from '../../../../elements/LinkArrow/linkArrow';
import { ACDBA } from '../../../../elements/PantheraIcon/ACDBA';
import { BankColored } from '../../../../elements/PantheraIcon/BankColored';
import { BillsColored } from '../../../../elements/PantheraIcon/BillsColored';
import { CarColored } from '../../../../elements/PantheraIcon/CarColored';
import { CartColored } from '../../../../elements/PantheraIcon/CartColored';
import { CreditCardsColored } from '../../../../elements/PantheraIcon/CreditCardsColored';
import { FieldServing } from '../../../../elements/PantheraIcon/FieldServing';
import { HandshakeColored } from '../../../../elements/PantheraIcon/HandshakeColored';
import { IRAP } from '../../../../elements/PantheraIcon/IRAP';
import { ISOCompliance } from '../../../../elements/PantheraIcon/ISOCompliance';
import { LegalServices } from '../../../../elements/PantheraIcon/LegalServices';
import { LightBulbColored } from '../../../../elements/PantheraIcon/LightBulbColored';
import { PCI } from '../../../../elements/PantheraIcon/PCI';
import { PhoneTowerColored } from '../../../../elements/PantheraIcon/PhoneTowerColored';
import { PiggyBankColored } from '../../../../elements/PantheraIcon/PiggyBankColored';
import { ProcessServing } from '../../../../elements/PantheraIcon/ProcessServing';
import { ThirdPartyCollections } from '../../../../elements/PantheraIcon/ThirdPartyCollections';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { BusinessRoutes } from '../../../../enums/routerPath';
import { BusinessFooter } from '../../elements/Footer/footer';
import { BusinessHeader } from '../../elements/Header/header';
import useStyles from './home.styles';

export const institutions = [
	{
		icon: <BankColored />,
		text: 'Banking',
	},
	{
		icon: <PhoneTowerColored />,
		text: 'Phone and Internet',
	},
	{
		icon: <CarColored />,
		text: 'Automotive finance',
	},
	{
		icon: <LightBulbColored />,
		text: 'Gas and Electricity',
	},
	{
		icon: <BillsColored />,
		text: 'Personal loans',
	},
	{
		icon: <HandshakeColored />,
		text: 'Business finance',
	},
	{
		icon: <CreditCardsColored />,
		text: 'Credit cards',
	},
	{
		icon: <PiggyBankColored />,
		text: 'Overdraft',
	},
	{
		icon: <CartColored />,
		text: 'Buy now pay later',
	},
];

export const BusinessHome: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
	return (
		<Box className={styles.root}>
			<BusinessHeader />
			<Container className={styles.hero} maxWidth={false}>
				<Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
					<Spacing className={styles.heroWrapper} units={isSmall ? 3 : 10}>
						<Typography className={styles.heroText} color="secondary" variant="h1">
							ARL combines <span>years of Collections</span> experience with the latest in{' '}
							<span>Data Science modelling and Machine Learning</span> to give a cutting edge Digital advantage to our Clients and
							Customers.
						</Typography>
						<Box display="flex" flexDirection="row" justifyContent="flex-start" alignContent="center" flexWrap="wrap">
							<Box className={styles.buttonWrapper} display="flex">
								<Button
									className={styles.button}
									size={isSmall ? 'small' : 'medium'}
									color="primary"
									variant="outlined"
									fullWidth
									component={RouterLink}
									to={BusinessRoutes.CONTACT.path}
								>
									Request a demo
								</Button>
							</Box>
							<Box className={styles.buttonWrapper} display="flex">
								<Button
									className={styles.button}
									size={isSmall ? 'small' : 'medium'}
									color="secondary"
									variant="contained"
									href={window.config.REACT_APP_CLIENT_URL}
									fullWidth
								>
									Existing Client Login
								</Button>
							</Box>
						</Box>
					</Spacing>
				</Box>
				<Box className={styles.heroImage}>
					<Fintech />
				</Box>
			</Container>
			<Container className={styles.row} maxWidth={false}>
				<Container maxWidth="md">
					<Spacing units={isSmall ? 3 : 5} display="flex" alignItems="center" flexDirection="column">
						<Typography className={styles.underline} variant="h1">
							Industries
						</Typography>
						<Typography align="center" variant="body1">
							Our extensive experience allows us to serve clients across a wide range of industries with custom tailored solutions and
							pricing to meet your needs.{'  '}
							<LinkArrow component={RouterLink} to={BusinessRoutes.OUR_SERVICES.path}>
								Find out more
							</LinkArrow>
						</Typography>
						<Paper className={styles.industriesGrid} elevation={3}>
							<Grid container>
								{institutions.map(({ icon, text }, i) => (
									<Grid key={text} item md={4} xs={12}>
										{icon}
										<Typography variant="body1">{text}</Typography>
									</Grid>
								))}
							</Grid>
						</Paper>
					</Spacing>
				</Container>
			</Container>
			<Container className={styles.row} maxWidth={false}>
				<Container maxWidth="md">
					<Spacing units={isSmall ? 3 : 5} display="flex" alignItems="center" flexDirection="column">
						<Typography className={styles.underline} variant="h1">
							Services
						</Typography>
						<Typography align="center" variant="body1">
							We can provide services to meet your needs at any time in the collections life cycle as well as customised workflows to
							meet your specific needs.{'  '}
							<LinkArrow component={RouterLink} to={BusinessRoutes.OUR_SERVICES.path}>
								Find out more
							</LinkArrow>
						</Typography>
						<Grid className={styles.servicesGrid} container spacing={2}>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<ThirdPartyCollections />
								<Typography className={styles.caption} align="center" variant="body1">
									Third Party Collections
								</Typography>
							</Grid>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<ProcessServing />
								<Typography className={styles.caption} align="center" variant="body1">
									Process Serving
								</Typography>
							</Grid>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<FieldServing />
								<Typography className={styles.caption} align="center" variant="body1">
									Field Serving
								</Typography>
							</Grid>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<LegalServices />
								<Typography className={styles.caption} align="center" variant="body1">
									Legal Services
								</Typography>
							</Grid>
						</Grid>
					</Spacing>
				</Container>
			</Container>
			<Container className={styles.row} maxWidth={false} disableGutters>
				<Container maxWidth="md">
					<Spacing units={isSmall ? 3 : 6} display="flex" alignItems="center" flexDirection="column">
						<Grid container spacing={8} alignItems="center">
							<Box clone order={{ xs: 2, md: 1 }}>
								<Grid item xs={12} md={6}>
									<Spacing units={isSmall ? 3 : 5}>
										<Typography className={clsx(styles.underline, styles.underLineLeft)} variant="h1">
											Security & Compliance
										</Typography>
										<Typography variant="body1">
											Security is in our DNA and as well as maintaining the highest information security certification we also
											offer industry leading business practices and procedures to ensure statutory compliance.{'  '}
											<LinkArrow component={RouterLink} to={BusinessRoutes.COMPLIANCE.path}>
												Find out more
											</LinkArrow>
										</Typography>
									</Spacing>
								</Grid>
							</Box>
							<Box clone order={{ xs: 1, md: 2 }}>
								<Grid className={styles.imgWrapper} container item xs={12} md={6} justifyContent="flex-end">
									<Lock className={styles.svg} />
								</Grid>
							</Box>
						</Grid>
						<Grid className={styles.securityGrid} container spacing={2}>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<ACDBA />
								<Typography className={styles.caption} align="center" variant="body1">
									ACDBA Member
								</Typography>
							</Grid>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<ISOCompliance />
								<Typography className={styles.caption} align="center" variant="body1">
									ISO27001 Compliant
								</Typography>
							</Grid>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<PCI />
								<Typography className={styles.caption} align="center" variant="body1">
									PCI DSS Compliant
								</Typography>
							</Grid>
							<Grid container item xs={12} sm={6} md={3} direction="column" justifyContent="center" alignItems="center">
								<IRAP />
								<Typography className={styles.caption} align="center" variant="body1">
									IRAP Assessed
								</Typography>
							</Grid>
						</Grid>
					</Spacing>
				</Container>
			</Container>
			<BusinessFooter />
		</Box>
	);
};
