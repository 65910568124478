import { ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import { isSameDay } from 'date-fns';
import React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import useStyles from './customDatePickerDay.styles';

type CustomDatePickerDayProps = {
	day: Date | null;
	isDayInCurrentMonth?: boolean;
	onSelect: (date: MaterialUiPickersDate | Date) => void;
	selected?: boolean;
	disabled?: boolean;
	today: Date;
};

export const CustomDatePickerDay: React.FC<CustomDatePickerDayProps> = ({
	day = null,
	isDayInCurrentMonth,
	onSelect,
	today,
	children,
	selected,
	disabled,
}) => {
	const styles = useStyles();
	const isToday = isDayInCurrentMonth && isSameDay(day ?? 0, today);
	return (
		<div className={styles.root}>
			<ButtonBase
				className={clsx(
					styles.dateButton,
					isDayInCurrentMonth && styles.dateButtonInMonth,
					isToday && !selected && styles.dateButtonToday,
					selected && styles.dateButtonSelected,
					disabled && styles.dateButtonDisabled,
				)}
				disabled={disabled}
				onClick={() => {
					onSelect(day);
				}}
			>
				{children}
			</ButtonBase>
		</div>
	);
};
