import { CircularProgress, FormControlProps, MenuItem } from '@material-ui/core';
import React from 'react';
import { getThemeStyle } from '../../theme/theme';
import { CirclePlusSolid } from '../PantheraIcon/CirclePlusSolid';
import { PantheraSelect, PantheraSelectProps } from '../PantheraSelect/PantheraSelect';
import useStyles from './contactSelect.styles';

type ContactSelectItem = {
	display: string;
	value: string | number;
};

type ContactSelectProps = {
	addNewText?: React.ReactNode;
	items: ContactSelectItem[];
	loading: boolean;
	placeHolderText?: React.ReactNode;
	showPlaceholder?: boolean;
	onAdd?: () => void;
};

export const ContactSelect: React.FC<ContactSelectProps & PantheraSelectProps & React.PropsWithChildren<FormControlProps>> = ({
	addNewText,
	items,
	loading,
	placeHolderText = 'Please select one',
	showPlaceholder = false,
	onAdd,
	...props
}) => {
	const styles = useStyles();
	return (
		<PantheraSelect fullWidth {...props}>
			<MenuItem style={{ display: showPlaceholder ? 'inital' : 'none' }} value="">
				{loading && (
					<>
						Loading <CircularProgress className={styles.spinner} size={getThemeStyle('typographyButtonFontSize')} />
					</>
				)}
				{!loading && placeHolderText}
			</MenuItem>
			{items.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					{item.display}
				</MenuItem>
			))}
			{onAdd && addNewText && (
				<MenuItem value="-1" className={styles.addWrapper} onClick={onAdd}>
					{addNewText}
					<span className={styles.icon}>
						<CirclePlusSolid />
					</span>
				</MenuItem>
			)}
		</PantheraSelect>
	);
};
