import { ARLResponse } from '../../../interceptor/base.api.response';

export enum CustomerAPI {
	CUSTOMER_DETAILS = '/api/customer',
	BPAY_DETAILS = '/api/customer/bpay',
}

export enum CustomerContactsAPI {
	PHONE_CONTACTS = '/api/customer/phone',
	EMAIL_CONTACTS = '/api/customer/email',
	ADDRESS_CONTACTS = '/api/customer/address',
}

export interface ICustomerDetailsOutput {
	Reference: number;
	fullName: string;
	clientName: string;
	clientLetterName: string;
	dateOfDebt?: string;
	paidAmt: number;
	outstandingAmt: number;
	dob: string;
	accountName: string;
	clientReference: string;
	contractStartDate?: string;
	contractEndDate?: string;
	supplyAddress?: string;
	accountItems: string;
	gender?: string;
	licence?: string;
	assignedDate: string;
	websiteWorkflow: string;
	lastPayDate: string;
	lastPayAmt: number;
	lastPayStatus: string;
	paymentMethod: string;
	nextPayDate?: string;
	nextPayAmt?: number;
	nextPayType?: string;
	isDebtPurchase: string;
	items: string;
	webMessage: string;
	contactPhone: string;
	IsExtranet_DebtorAllowCreditCardPayment: boolean;
	merchantFeePercent: number;
	outstandingIncludingMerchantFee: number;
	clientPaymentMethods: string;
}

export type ICustomerGet = ARLResponse<{ customer: ICustomerDetailsOutput }>;

export interface IBpayDetails {
	billerCode: string;
	ref: string;
}

export type ICustomerBpayGet = ARLResponse<{ bpay: IBpayDetails }>;

export interface IContactOutput {
	ContactDetailID: number;
	ContactDetail: string;
	ContactDetailNormalized: string;
	CreateTS: string;
	ContactDetailTypeID: number;
}

export interface IContactAddressOutput {
	AddressID: number;
	ApartmentNo: string | null;
	StreetNo: string | null;
	Street: string | null;
	StreetType: string | null;
	Address: string | null;
	Suburb: string | null;
	State: string | null;
	Postcode: string | null;
	Country: string;
	CreateTS: string;
	AddressTypeID: number;
	AddressType: string;
}

export type IPhoneContacts = ARLResponse<{ contacts: IContactOutput[] }>;
export type IEmailContacts = ARLResponse<{ contacts: IContactOutput[] }>;
export type IAddressContacts = ARLResponse<{ contacts: IContactAddressOutput[] }>;
