import { Button, Card } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonStack } from '../../../../../elements/ButtonStack/buttonStack';
import { CTAHeadings } from '../../../../../elements/CTAHeadings/cTAHeadings';
import { SpinnerButton } from '../../../../../elements/SpinnerButton/spinnerButton';
import useStyles from './directDebit.styles';
import { history } from '../../../../../store/history';
import { RootState } from '../../../../../store/rootReducer';
import { PaymentType } from '../../../../../enums/paymentForm';
import { formatMoney } from '../../../../../services/helpers/text.helpers';
import { DirectDebitPaymentForm } from '../../../../../elements/Forms/elements/DirectDebitPaymentForm/directDebitPaymentForm';

export const DirectDebit: React.FC = () => {
	const styles = useStyles();
	const { loading, paymentAmount, paymentType, paymentDirectDebit } = useSelector((state: RootState) => ({
		loading: state.paymentForm.loading,
		paymentAmount: state.paymentForm.paymentAmount,
		paymentType: state.paymentForm.paymentType,
		paymentDirectDebit: state.paymentForm.paymentDirectDebit,
	}));
	return (
		<>
			<div className={styles.root}>
				<CTAHeadings
					subtitle={`Let's set up your ${
						paymentType === PaymentType.ARRANGEMENT ? 'recurring payment plan' : `payment of ${formatMoney(parseFloat(paymentAmount))}`
					}`}
					title="Enter your bank details"
				/>
				<Card className={styles.card} elevation={8}>
					<DirectDebitPaymentForm autoFocus={true} paymentDirectDebit={paymentDirectDebit} />
				</Card>
				<ButtonStack>
					<SpinnerButton
						form="directDebitForm"
						color="secondary"
						fullWidth
						size="large"
						variant="contained"
						type="submit"
						loading={loading}
					>
						Continue
					</SpinnerButton>
					<Button color="primary" fullWidth size="large" variant="outlined" onClick={() => history.goBack()}>
						Back
					</Button>
				</ButtonStack>
			</div>
		</>
	);
};
