/* eslint-disable import/no-named-as-default-member */
// https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import themeScss, { getThemeStyle, spacingUnits } from './theme';

import {
	redHatDisplayBlack,
	redHatDisplayBold,
	redHatDisplayMedium,
	redHatDisplayRegular,
	redHatTextBold,
	redHatTextMedium,
	redHatTextRegular,
} from './fonts';

const themeBreakpoints = createMuiTheme({
	breakpoints: {
		values: {
			xs: themeScss.breakpointsValuesXS * 1,
			sm: themeScss.breakpointsValuesSM * 1,
			md: themeScss.breakpointsValuesMD * 1,
			lg: themeScss.breakpointsValuesLG * 1,
			xl: themeScss.breakpointsValuesXL * 1,
		},
	},
});

const theme = createMuiTheme({
	...themeBreakpoints,
	direction: 'ltr',
	palette: {
		type: 'light',
		background: {
			default: themeScss.backgroundColor,
		},
		text: {
			primary: themeScss.paletteTextPrimary,
			secondary: themeScss.paletteTextSecondary,
			hint: themeScss.paletteTextHint,
		},
		error: {
			light: themeScss.paletteErrorLight,
			main: themeScss.paletteErrorMain,
			dark: themeScss.paletteErrorDark,
		},
		primary: {
			main: themeScss.palettePrimary500,
			contrastText: themeScss.paletteCommonWhite,
		},
		secondary: {
			main: themeScss.paletteSecondary500,
			contrastText: themeScss.paletteCommonWhite,
		},
		tertiary: {
			main: themeScss.paletteTertiary500,
			contrastText: themeScss.paletteCommonWhite,
		},
		success: {
			main: themeScss.paletteSuccessMain,
			contrastText: themeScss.paletteCommonWhite,
		},
	},
	typography: {
		fontFamily: themeScss.typographyFontFamily,
		fontSize: themeScss.typographyFontSize * 1,
		body1: {
			fontSize: themeScss.typographyBody1FontSize,
			fontWeight: themeScss.typographyBody1FontWeight,
			fontFamily: themeScss.typographyBody1FontFamily,
			letterSpacing: 0,
			lineHeight: themeScss.typographyBody1LineHeight,
			[themeBreakpoints.breakpoints.up('md')]: {
				fontSize: themeScss.typographyBody1DesktopFontSize,
				lineHeight: themeScss.typographyBody1DesktopLineHeight,
			},
		},
		body2: {
			fontSize: themeScss.typographyBody2FontSize,
			fontWeight: themeScss.typographyBody2FontWeight,
			fontFamily: themeScss.typographyBody2FontFamily,
			letterSpacing: 0,
			lineHeight: themeScss.typographyBody2LineHeight,
		},
		caption: {
			fontSize: themeScss.typographyCaptionFontSize,
			fontWeight: themeScss.typographyCaptionFontWeight,
			fontFamily: themeScss.typographyCaptionFontFamily,
			letterSpacing: 0,
			lineHeight: themeScss.typographyCaptionLineHeight,
		},
		h1: {
			fontSize: themeScss.typographyH1FontSize,
			fontWeight: themeScss.typographyH1FontWeight,
			fontFamily: themeScss.typographyH1FontFamily,
			letterSpacing: themeScss.typographyH1LetterSpacing,
			lineHeight: themeScss.typographyH1LineHeight,
			color: themeScss.typographyH1Color,
		},
		h2: {
			fontSize: themeScss.typographyH2FontSize,
			fontWeight: themeScss.typographyH2FontWeight,
			fontFamily: themeScss.typographyH2FontFamily,
			letterSpacing: themeScss.typographyH2LetterSpacing,
			lineHeight: themeScss.typographyH2LineHeight,
			color: themeScss.typographyH2Color,
		},
		h3: {
			fontSize: themeScss.typographyH3FontSize,
			fontWeight: themeScss.typographyH3FontWeight,
			fontFamily: themeScss.typographyH3FontFamily,
			letterSpacing: themeScss.typographyH3LetterSpacing,
			lineHeight: themeScss.typographyH3LineHeight,
			color: themeScss.typographyH3Color,
		},
		h4: {
			fontSize: themeScss.typographyH4FontSize,
			fontWeight: themeScss.typographyH4FontWeight,
			fontFamily: themeScss.typographyH4FontFamily,
			letterSpacing: themeScss.typographyH4LetterSpacing,
			lineHeight: themeScss.typographyH4LineHeight,
			color: themeScss.typographyH4Color,
		},
		h5: {
			fontSize: themeScss.typographyH5FontSize,
			fontWeight: themeScss.typographyH5FontWeight,
			fontFamily: themeScss.typographyH5FontFamily,
			letterSpacing: themeScss.typographyH5LetterSpacing,
			lineHeight: themeScss.typographyH5LineHeight,
			color: themeScss.typographyH5Color,
		},
		h6: {
			fontSize: themeScss.typographyH6FontSize,
			fontWeight: themeScss.typographyH6FontWeight,
			fontFamily: themeScss.typographyH6FontFamily,
			letterSpacing: themeScss.typographyH6LetterSpacing,
			lineHeight: themeScss.typographyH6LineHeight,
			color: themeScss.typographyH6Color,
		},
		subtitle1: {
			fontSize: themeScss.typographySubtitle1FontSize,
			fontWeight: themeScss.typographySubtitle1FontWeight,
			fontFamily: themeScss.typographySubtitle1FontFamily,
			letterSpacing: themeScss.typographySubtitle1LetterSpacing,
			lineHeight: themeScss.typographySubtitle1LineHeight,
			color: themeScss.typographySubtitle1Color,
		},
		subtitle2: {
			fontSize: themeScss.typographySubtitle2FontSize,
			fontWeight: themeScss.typographySubtitle2FontWeight,
			fontFamily: themeScss.typographySubtitle2FontFamily,
			letterSpacing: themeScss.typographySubtitle2LetterSpacing,
			lineHeight: themeScss.typographySubtitle2LineHeight,
			color: themeScss.typographySubtitle2Color,
		},
		button: {
			fontSize: themeScss.typographyButtonFontSize,
			fontWeight: themeScss.typographyButtonFontWeight,
			fontFamily: themeScss.typographyButtonFontFamily,
			letterSpacing: themeScss.typographyButtonLetterSpacing,
			lineHeight: themeScss.typographyButtonLineHeight,
		},
		overline: {
			fontSize: themeScss.typographyOverlineFontSize,
			fontWeight: themeScss.typographyOverlineFontWeight,
			fontFamily: themeScss.typographyOverlineFontFamily,
			letterSpacing: themeScss.typographyOverlineLetterSpacing,
			lineHeight: themeScss.typographyOverlineLineHeight,
			color: themeScss.typographyOverlineColor,
		},
	},
	shape: {
		borderRadius: themeScss.shapeBorderRadius * 1,
	},
	spacing: themeScss.spacingUnit * 1,
	shadows: [
		themeScss.shadows0,
		themeScss.shadows1,
		themeScss.shadows2,
		themeScss.shadows3,
		themeScss.shadows4,
		themeScss.shadows5,
		themeScss.shadows6,
		themeScss.shadows7,
		themeScss.shadows8,
		themeScss.shadows9,
		themeScss.shadows10,
		themeScss.shadows11,
		themeScss.shadows12,
		themeScss.shadows13,
		themeScss.shadows14,
		themeScss.shadows15,
		themeScss.shadows16,
		themeScss.shadows17,
		themeScss.shadows18,
		themeScss.shadows19,
		themeScss.shadows20,
		themeScss.shadows21,
		themeScss.shadows22,
		themeScss.shadows23,
		themeScss.shadows24,
	],
	zIndex: {
		appBar: themeScss.zIndexAppBar,
		drawer: themeScss.zIndexDrawer,
		mobileStepper: themeScss.zIndexMobileStepper,
		modal: themeScss.zIndexModal,
		snackbar: themeScss.zIndexSnackbar,
		speedDial: themeScss.zIndexSpeedDial,
		tooltip: themeScss.zIndexTooltip,
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [
					redHatTextBold,
					redHatTextMedium,
					redHatTextRegular,
					redHatDisplayBlack,
					redHatDisplayBold,
					redHatDisplayMedium,
					redHatDisplayRegular,
				],
				html: {
					WebkitFontSmoothing: 'auto',
					fontSize: '62.5%',
					minHeight: '100%',
					height: '100%',
				},
				body: {
					height: '100%',
					overflowX: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					// Google maps autocomplete container
					'& .pac-container': {
						zIndex: '10000 !important',
					},
					'& .grecaptcha-badge': {
						visibility: 'hidden',
					},
					'& .yabbr-frame-account': {
						[themeBreakpoints.breakpoints.down('md')]: {
							bottom: '20px !important',
						},
					},
					'& .yabbr-frame': {
						zIndex: '1299 !important', // Snackbar is set to 1400, Modal is 1300
					},
				},
				'#root': {
					display: 'flex',
					flexGrow: 1,
					'& > .telemetry': {
						width: '100%',
						display: 'flex',
						flexGrow: 1,
					},
				},
				'*, *::before, *::after': {
					boxSizing: 'border-box',
				},
				svg: {
					height: '100%',
				},
			},
		},
		MuiAppBar: {
			root: {
				flexDirection: 'row',
			},
			colorDefault: {
				backgroundColor: themeScss.paletteCommonWhite,
			},
		},
		MuiBadge: {
			anchorOriginBottomLeftCircle: {
				left: '12%',
				bottom: '12%',
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: themeScss.paletteBackdropBackground,
			},
		},
		MuiButton: {
			root: {
				textTransform: 'none',

				'&.MuiButton-setWidth': {
					width: 200,
				},
				'&:hover.Mui-disabled': {
					backgroundColor: 'transparent',
				},
			},
			contained: {
				backgroundColor: themeScss.paletteCommonLightWhite,
				borderRadius: `${themeScss.spacingUnit}px`,
				padding: `${themeScss.spacingUnit * 2 - 1}px ${themeScss.spacingUnit * 2}px`,
				boxShadow: themeScss.shadows2,
				'&.Mui-disabled': {
					opacity: 0.5,
					boxShadow: themeScss.shadows2,
				},
				'&:hover.Mui-disabled': {
					backgroundColor: themeScss.paletteCommonLightWhite,
				},
			},
			containedPrimary: {
				border: `1px solid ${themeScss.palettePrimary700}`,
				'& .MuiButton-startIcon, & .MuiButton-endIcon': {
					fill: themeScss.paletteCommonWhite,
				},
				'&.Mui-disabled': {
					backgroundColor: themeScss.palettePrimary500,
					color: themeScss.paletteCommonWhite,
				},
				'&:hover.Mui-disabled': {
					backgroundColor: themeScss.palettePrimary500,
				},
			},
			containedSecondary: {
				border: `1px solid ${themeScss.paletteSecondary700}`,
				'& .MuiButton-startIcon, & .MuiButton-endIcon': {
					fill: themeScss.paletteCommonWhite,
				},
				'&.Mui-disabled': {
					backgroundColor: themeScss.paletteSecondary500,
					color: themeScss.paletteCommonWhite,
				},
				'&:hover.Mui-disabled': {
					backgroundColor: themeScss.paletteSecondary500,
				},
			},
			endIcon: {
				marginLeft: 'auto',
				marginRight: -6,
			},
			iconSizeLarge: {
				height: 24,
				width: 24,
				marginTop: -4,
				marginBottom: -4,
				'& > *': {
					width: '100%',
				},
			},
			outlined: {
				borderRadius: `${themeScss.spacingUnit}px`,
				padding: `${themeScss.spacingUnit * 1.5}px ${themeScss.spacingUnit * 2}px`,
				'&.Mui-disabled': {
					opacity: 0.5,
				},
			},
			sizeSmall: {
				padding: '4px 8px',
				fontSize: '1.3rem',
				lineHeight: '1.6rem',
				borderRadius: '6px',
			},
			sizeLarge: {
				borderRadius: `${themeScss.shapeBorderRadius}px`,
				padding: `${themeScss.spacingUnit * 2 - 1}px ${themeScss.spacingUnit * 2}px`,
				fontSize: '1.6rem',
				lineHeight: '1.8rem',
			},
			startIcon: {
				display: 'inline-block',
				lineHeight: 0,
			},
			text: {
				color: themeScss.paletteTertiary900,
			},
		},
		MuiButtonBase: {
			root: {
				border: 'inherit',
			},
		},
		MuiCardContent: {
			root: {
				padding: 0,
				'&:last-child': {
					paddingBottom: 0,
				},
			},
		},
		MuiCardActions: {
			root: {
				padding: `${themeScss.spacingUnit * 2}px 0 0`,
				flexDirection: 'column',
				[themeBreakpoints.breakpoints.up('lg')]: {
					flexDirection: 'row',
				},
			},
			spacing: {
				'& > *': {
					[themeBreakpoints.breakpoints.down('lg')]: {
						width: '100%',
					},
				},
				'& > :not(:first-child)': {
					marginLeft: 0,
					marginTop: `${themeScss.spacingUnit * 2}px`,
					[themeBreakpoints.breakpoints.up('lg')]: {
						marginLeft: `${themeScss.spacingUnit * 2}px`,
						marginTop: 'inherit',
					},
				},
			},
		},
		MuiChip: {
			root: {
				height: 28,
				backgroundColor: themeScss.palettePrimaryA100,
				color: themeScss.paletteTertiary700,
			},
			label: {
				fontSize: '1.4rem',
				lineHeight: '1.6rem',
				fontWeight: 500,
			},
			icon: {
				height: 16,
				width: 16,
				marginLeft: `${themeScss.spacingUnit}px`,
				marginRight: -8,
				color: 'inherit',
				fill: 'currentColor',
			},
			sizeSmall: {
				height: 22,
			},
		},
		MuiDialog: {
			paper: {
				margin: `${themeScss.spacingUnit * 2.75}px`,
				border: `1px solid ${themeScss.palettePrimaryA200}`,
			},
			paperScrollPaper: {
				maxHeight: `calc(100% - ${themeScss.spacingUnit * 2}px)`,
			},
		},
		MuiDivider: {
			root: {
				margin: `${themeScss.spacingUnit * 2}px 0`,
			},
		},
		MuiDrawer: {
			paper: {
				backgroundColor: themeScss.paletteTertiary500,
				color: themeScss.paletteCommonWhite,
				'& .MuiTypography-root': {
					color: themeScss.paletteCommonWhite,
				},
				'& .MuiDivider-root': {
					opacity: 0.2,
					backgroundColor: themeScss.paletteTertiary100,
				},
			},
		},
		MuiFab: {
			extended: {
				borderRadius: `${themeScss.shapeBorderRadius}px`,
				height: 'auto',
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: themeScss.typographySubtitle2FontSize,
				fontWeight: themeScss.typographySubtitle2FontWeight,
				fontFamily: themeScss.typographySubtitle2FontFamily,
				letterSpacing: themeScss.typographySubtitle2LetterSpacing,
				lineHeight: themeScss.typographySubtitle2LineHeight,
				color: themeScss.typographySubtitle2Color,
				'&.Mui-error,&.Mui-focused': {
					color: themeScss.typographySubtitle2Color,
				},
			},
			filled: {
				'& + .MuiInputBase-root': {
					backgroundColor: themeScss.paletteCommonWhite,
				},
			},
		},
		MuiInputAdornment: {
			root: {
				pointerEvents: 'none',
			},
		},
		MuiInputBase: {
			root: {
				border: `1px solid ${themeScss.palettePrimary100}`,
				borderRadius: `${themeScss.spacingUnit}px`,
				backgroundColor: themeScss.paletteInputUnfocusedBackground,
				transition: themeBreakpoints.transitions.create(['background-color', 'border-color']),
				caretColor: themeScss.palettePrimaryA400,
				'&.Mui-focused': {
					backgroundColor: themeScss.paletteCommonWhite,
				},
				'&.Mui-error': {
					borderColor: themeScss.paletteErrorLight,
				},
				'&.Mui-focused:not(.Mui-error) + .input-indicator': {
					opacity: 1,
				},
				'&.Mui-error + .input-indicator': {
					opacity: 1,
					'& > *': {
						fill: themeScss.paletteErrorLight,
					},
				},
				'&.MuiInputBase-multiline': {
					padding: `${themeScss.spacingUnit * 2}px ${themeScss.spacingUnit * 1.5}px`,
				},
			},
			input: {
				height: `${themeScss.spacingUnit * 7}px`,
				borderRadius: `${themeScss.spacingUnit}px`,
				padding: `${themeScss.spacingUnit}px ${themeScss.spacingUnit * 1.5}px`,
				boxSizing: 'border-box',
				transition: themeBreakpoints.transitions.create(['padding']),
			},
			inputMultiline: {
				borderRadius: 0,
			},
		},
		MuiInputLabel: {
			root: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
				marginBottom: 2,
				'& + .MuiInput-formControl': {
					marginTop: `calc(${themeScss.spacingUnit}px + ${themeScss.typographySubtitle2LineHeight})`,
				},
			},
			shrink: {
				transform: 'none',
			},
		},
		MuiList: {
			padding: {
				paddingTop: 0,
				paddingBottom: 0,
			},
		},
		MuiListItem: {
			divider: {
				borderBottom: `1px solid ${themeScss.palettePrimary100}`,
			},
		},
		MuiPaper: {
			rounded: {
				borderRadius: themeScss.spacingUnit,
			},
		},
		MuiPickersBasePicker: {
			pickerView: {
				width: `${themeScss.datePickerWidth}px`,
				maxWidth: 'none',
				minWidth: 0,
			},
			pickerViewLandscape: {
				padding: `${themeScss.spacingUnit}px`,
			},
		},
		MuiPickersCalendar: {
			transitionContainer: {
				marginTop: `${themeScss.spacingUnit * 2}px`,
				minHeight: '270px',
			},
			week: {
				'& > div': {
					width: '100%',
					padding: '2px',
				},
			},
		},
		MuiPickersCalendarHeader: {
			iconButton: {
				'&.Mui-disabled svg': {
					fill: themeScss.paletteGrey50,
				},
			},
			switchHeader: {
				height: `${themeScss.spacingUnit * 10}px`,
			},
			dayLabel: {
				width: '100%',
				fontFamily: themeScss.typographyDisplayFontFamily,
				fontWeight: 'bold',
				fontSize: '1.8rem',
				color: themeScss.paletteTertiary900,
			},
		},
		MuiPickersStaticWrapper: {
			staticWrapperRoot: {
				width: `${themeScss.datePickerWidth}px`,
			},
		},
		MuiTableCell: {
			root: {
				padding: `${themeScss.spacingUnit * 1.75}px`,
				borderBottom: `1px solid ${themeScss.paletteTertiary100}`,

				'&.MuiTableCell-head': {
					fontWeight: 600,
				},

				'&:first-child, &:last-child': {
					padding: `${themeScss.spacingUnit * 1.75}px 0`,
				},
			},
		},
		MuiTab: {
			root: {
				opacity: 0.6,
				textTransform: 'none',
				fontSize: '1.6rem',
				lineHeight: '1.8rem',
				minHeight: `${themeScss.spacingUnit * 6.75}px`,
				padding: `${themeScss.spacingUnit * 3}px 0 ${themeScss.spacingUnit * 1.5}px`,
				margin: `0 ${themeScss.spacingUnit * 1.5}px`,
				transition: `opacity ${themeScss.transitionsDurationStandard}ms ${themeScss.transitionsEasingEaseInOut} 0s`,
				'&.Mui-selected': {
					opacity: 1,
				},
			},
		},
		MuiTabs: {
			root: {
				height: `${themeScss.spacingUnit * 6.75}px`,
				color: themeScss.paletteCommonWhite,
				padding: `0 ${themeScss.spacingUnit * 1.5}px`,
			},
			flexContainer: {
				'&::after': {
					content: '""',
					position: 'absolute',
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: themeScss.paletteTertiary900,
					height: 1,
				},
			},
			indicator: {
				borderRadius: 1,
				backgroundColor: themeScss.paletteSecondary500,
				height: `${themeScss.spacingUnit * 0.5}px`,
			},
		},
		MuiToolbar: {
			root: {
				flexGrow: 1,
			},
			regular: {
				minHeight: `${themeScss.toolbarMarketingHeight}px`,
				[themeBreakpoints.breakpoints.up('sm')]: {
					minHeight: `${themeScss.toolbarMarketingHeight}px`,
				},
			},
			dense: {
				minHeight: `${themeScss.toolbarLoggedInHeight}px`,
				[themeBreakpoints.breakpoints.up('sm')]: {
					minHeight: `${themeScss.toolbarLoggedInHeight}px`,
				},
			},
		},

		MuiLinearProgress: {
			root: {
				borderRadius: spacingUnits(1.75),
				height: spacingUnits(1),
				width: '100%',
			},

			barColorPrimary: {
				backgroundColor: getThemeStyle('paletteSecondary300'),
				border: `1px solid ${getThemeStyle('paletteSecondary400')}`,
			},

			colorPrimary: {
				backgroundColor: getThemeStyle('paletteCommonLightWhite'),
			},
		},
	},
} as ThemeOptions);

export default theme;
