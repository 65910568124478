import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './services.styles';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { BusinessHeader } from '../../elements/Header/header';
import { BusinessFooter } from '../../elements/Footer/footer';
import { HeroTitle } from '../../../Customer/elements/HeroTitle/heroTitle';

export const Services: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<BusinessHeader className={styles.greyHeader} />
			<HeroTitle
				className={styles.greyHero}
				title="Services"
				subtitle="Our services can be tailored entirely to the needs of your business. From first party collections and early intervention to meeting the needs of clients with both commercial and consumer receivables requirements, we endeavour to provide the best financial solutions for both our clients, and our customers."
				maxWidth={960}
			/>
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<Typography className={styles.underline} variant="h4">
						First Party Collections
					</Typography>
					<Typography variant="body1">
						We offer our clients a true Account Receivables function with services tailored to first party collections and early
						intervention.
					</Typography>
					<Typography variant="body1">
						Outsourcing accounts earlier in the collection process or at internal peak workload times, ARL can act on behalf of your
						organisation initiating courtesy reminders, requesting payment, and conducting skip tracing, whilst advising of the likely
						outcomes should payment not be received; freeing up internal resources.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Third Party Collections
					</Typography>
					<Typography variant="body1">
						ARL’s knowledge, expertise and systems can be introduced to the receivables process at any stage of the credit to cash cycle
						of an account.
					</Typography>
					<Typography variant="body1">
						ARL can become an extension of our clients’ own resources, allowing the client to focus on core business functions and
						opportunities. Our focus on results, quality performance, and service delivery will provide efficient strategic and
						cost-effective solutions to some of a client’s toughest problems.
					</Typography>
					<Typography variant="body1">
						ARL conducts customised collection activity depending on industry sector and/or client requirements. Collection activity
						includes demand letters, telephone demands, Solicitor demand letters, SMS and email campaigns, field calls, skip tracing and
						undertaking the full legal process.
					</Typography>
					<Typography variant="body1">
						The ARL collection model has been developed around many years of experience and the founding principles of this company, and
						is supported by industry leading systems and technology.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Process Serving
					</Typography>
					<Typography variant="body1">
						Our licensed commercial agents have the ability to serve any legal document on behalf of any client.
					</Typography>
					<Typography variant="body1">
						ARL monitor this process and ensure documents are served within a timely manner. Updates of service and timeframes are
						provided to clients, with agents advising details such as: Confirmation of service addresses; Name, Age and Sex of person
						document was served upon and description of property, assets sighted including vehicle make/model/registration/value. All
						calls are conducted by agents trained in the art of enquiry, designed to elicit factual information, and prompt reporting is
						not only provided in writing, but also through our Client Portal.
					</Typography>
					<Typography variant="body1">
						The need to draw on licensed, experienced and proven agents is paramount for repossessions. Through our extensive network,
						repossession services are also available.
					</Typography>
					<Typography className={styles.underline} variant="h4">
						Field Services
					</Typography>
					<Typography variant="body1">
						Upon client authorisation, our licensed commercial agents will attend the customers address with every endeavour to interview
						your client.
					</Typography>
					<Typography variant="body1">All agents submit a written report, should the information be able to be obtained.</Typography>
					<Typography className={styles.underline} variant="h4">
						Legal Services
					</Typography>
					<Typography variant="body1">
						ARL works closely with trusted and experienced providers as servicing partners to deliver legal advice specifically on
						receivables management and on comprehensive commercial matters.
					</Typography>
					<Typography variant="body1">
						These specialist legal resources are linked with ARL’s Account Managers and ensure that client legal accounts are effectively
						and efficiently actioned. Our solicitors have developed a reputation for providing accurate, expert advice and a fast,
						efficient legal service.
					</Typography>
				</Spacing>
			</Container>
			<BusinessFooter />
		</Box>
	);
};
