import { makeStyles } from '@material-ui/core';

import { getThemeStyle } from '../../theme/theme';

export default makeStyles(
	{
		breadcrumbLink: {
			color: getThemeStyle('paletteSecondary500'),
			'&:hover': {
				textDecoration: 'underline',
			},
			'& > span': {
				fontSize: '1.6rem',
			},
		},
	},
	{
		name: 'pantheraBreadcrumbs',
	},
);
