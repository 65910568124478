import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ContactPhone, ContactPhoneHref } from '../../../../../../elements/ContactPhone/contactPhoneNumber';
import { Home } from '../../../../../../elements/PantheraIcon/Home';
import { InformationSolid } from '../../../../../../elements/PantheraIcon/InformationSolid';
import { PhoneCall } from '../../../../../../elements/PantheraIcon/PhoneCall';
import { AccountRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { WebsiteWorkflow } from '../../../../../../enums/websiteWorkflow';
import { RootState } from '../../../../../../store/rootReducer';
import useStyles from './headerLinks.styles';

export const HeaderLinks: React.FC = () => {
	const styles = useStyles();

	const { websiteWorkflow, customerLoaded, outstanding } = useSelector(
		(state: RootState) => ({
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			customerLoaded: state.customer.isLoaded,
			outstanding: state.customer.customerDetails?.outstandingAmt ?? 0,
		}),
		shallowEqual,
	);

	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		if (customerLoaded && (websiteWorkflow === WebsiteWorkflow.BLOCKED || outstanding <= 0)) {
			setShow(false);
		}
	}, [customerLoaded, websiteWorkflow, outstanding]);

	return (
		<div className={styles.root}>
			{customerLoaded && show && (
				<Button startIcon={<Home className={styles.homeIcon} />} component={Link} to={AccountRoutes.OVERVIEW.path}>
					Account overview
				</Button>
			)}
			<a href={ContactPhoneHref()}>
				<Button startIcon={<PhoneCall className={styles.phoneIcon} />}>{ContactPhone()}</Button>
			</a>
			<Button startIcon={<InformationSolid className={styles.infoIcon} />} component={Link} to={WebRoutes.SUPPORT.path}>
				Support centre
			</Button>
		</div>
	);
};
