import { makeStyles } from '@material-ui/core/styles';
import { spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		section: {
			marginBottom: spacingUnits(4),
			'& $buttons': {
				padding: `${spacingUnits(2)}px 0`,

				'& button:not(:last-child)': {
					marginRight: spacingUnits(1),
				},
			},

			'& .MuiTablePagination-selectRoot': {
				border: 0,
				fontSize: '1.4rem',
				backgroundColor: 'inherit',
			},
		},
		buttons: {},
	},
	{ name: 'schedulesTab' },
);
