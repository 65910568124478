import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../../../theme';
import { spacingUnits } from '../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			top: 0,
			zIndex: 9,
			lineHeight: 0,
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			position: 'absolute',
			padding: spacingUnits(3),
			justifyContent: 'space-between',
		},
		logo: {
			'& svg': {
				height: 60,

				[MuiTheme.breakpoints.down('sm')]: {
					height: 40,
				},
			},
		},
		headerLinks: {
			fontSize: '1.5rem',
			fontWeight: 500,
			'& a': {
				marginRight: '2em',
				'&:last-child': {
					marginRight: 0,
				},
				[MuiTheme.breakpoints.down('sm')]: {
					marginRight: '1em',
				},
			},
		},
	},
	{ name: 'loginHeader' },
);
