import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import useStyles from './loginHeader.styles';
import { WrapWithLink } from '../../../../../../elements/WrapWithLink/wrapWithLink';
import { WebRoutes } from '../../../../../../enums/routerPath';
import { Logo } from '../../../../../../elements/Branding/Logo';

export const LoginHeader: React.FC = () => {
	const styles = useStyles();
	return (
		<header className={styles.root}>
			<WrapWithLink
				className={styles.logo}
				LinkProps={{
					to: WebRoutes.HOME.path,
				}}
			>
				<Logo />
			</WrapWithLink>
			<div className={styles.headerLinks}>
				<Link component={RouterLink} to={WebRoutes.ABOUT.path}>
					About Us
				</Link>
				<Link component={RouterLink} to={WebRoutes.SUPPORT.path}>
					Need Help?
				</Link>
				<Link component={RouterLink} to={WebRoutes.CONTACT.path}>
					Contact
				</Link>
			</div>
		</header>
	);
};
