import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, RouteProps } from 'react-router-dom';
import { WebRoutes, accountRoutePrefix } from '../enums/routerPath';

export interface IPageProps extends RouteProps {
	title?: string;
	description?: string;
}

// TODO get previous url and store in redux for back buttons

export const Page: FunctionComponent<IPageProps> = ({ title, description, ...props }) => {
	return (
		<>
			<Helmet defaultTitle={window.config.REACT_APP_COMPANY}>
				<title>{title}</title>
				<meta name="description" content={description} />
				{props.path && (props.path.includes(accountRoutePrefix) || props.path === WebRoutes.FOUR_OH_FOUR.path) && (
					<meta name="robots" content="none" />
				)}
			</Helmet>
			<Route {...props} />
		</>
	);
};
