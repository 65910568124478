import React from 'react';

export const FourOhFourIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 1754.44 567.06" {...props}>
		<circle cx="877.22" cy="283.53" r="283.45" fill="#B2B6BB" />
		<circle cx="877.22" cy="283.53" r="237.86" transform="translate(56.44 703.33) rotate(-45)" fill="#61656d" />
		<path
			d="M860.25,452.23l0-39.71a192.45,192.45,0,0,1-50.05-8.86,24.16,24.16,0,0,1-15.94-29l.38-1.48a24.11,24.11,0,0,1,31.07-17A140.87,140.87,0,0,0,871,364c23.83,0,40.12-9.24,40.1-26,0-15.88-13.41-25.9-44.36-36.31-44.74-15-75.28-35.85-75.33-76.4,0-36.78,25.84-65.65,70.55-74.49l-.05-39.7,41,0,0,36.78a178.63,178.63,0,0,1,38.15,5.45,24.13,24.13,0,0,1,17.1,29.32h0A24.14,24.14,0,0,1,928,199.88a128.17,128.17,0,0,0-36.73-5.16c-27.17,0-35.93,11.74-35.91,23.45,0,13.79,14.65,22.55,50.2,35.88,49.76,17.5,69.85,40.46,69.9,78.08,0,37.2-26.25,69-74.31,77.42l.06,42.63Z"
			fill="#ffffff"
		/>
		<path d="M450,.25V332.09h70.42V441.36H450V566.82H318.9V441.36H0v-106L297,.25ZM149.74,332.09H318.9V141.89Z" fill="#C62221" />
		<path d="M1684,.25V332.09h70.42V441.36H1684V566.82H1552.9V441.36H1234v-106L1531,.25ZM1383.74,332.09H1552.9V141.89Z" fill="#C62221" />
	</svg>
);
