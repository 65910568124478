import { makeStyles } from '@material-ui/core/styles';

import { getThemeStyle, spacingUnits } from '../../theme/theme';

export default makeStyles(
	({ breakpoints }) => ({
		root: {
			'& $dialog': {
				borderRadius: [[spacingUnits(3), spacingUnits(3), 0, 0]],
				overflowX: 'hidden',
				padding: spacingUnits(3),
				paddingTop: spacingUnits(4),

				[breakpoints.up('sm')]: {
					borderRadius: 8,
					maxWidth: 550,
					padding: [[spacingUnits(7), spacingUnits(6)]],
				},
			},

			'@global .MuiDialog-scrollPaper': {
				[breakpoints.down('xs')]: {
					alignItems: 'flex-end',

					'@global .MuiDialog-paper': {
						marginLeft: 0,
						marginRight: 0,
						marginBottom: 0,
						width: '100%',
					},
				},
			},

			'& $brandmark': {
				position: 'absolute',
				fill: getThemeStyle('palettePrimary500'),
				opacity: 0.05,
				mixBlendMode: 'multiply',
				width: 350,
				maxWidth: '80%',
				top: -30,
				left: '50%',
				transform: 'translate(-50%, -50%)',
			},
		},

		closeButton: {
			position: 'absolute',
			top: spacingUnits(2),
			right: spacingUnits(2),

			backgroundColor: 'transparent',
			border: 'none',
			cursor: 'pointer',
			height: spacingUnits(3.5),
			padding: 0,
			width: spacingUnits(3.5),

			[breakpoints.up('sm')]: {
				height: spacingUnits(3),
				width: spacingUnits(3),
			},

			'& > svg': {
				fill: getThemeStyle('paletteTertiary500'),
				position: 'relative',
				width: '100%',
				zIndex: 1,
			},
		},

		container: {
			width: '100%',
			margin: '0 auto',
			position: 'relative',
			minHeight: '100%',
		},
		dialog: {},
		brandmark: {},
	}),
	{ name: 'lockedModal' },
);
