import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { timedOut } from '../../../../store/features/auth/authSlice';
import { RootState } from '../../../../store/rootReducer';

export const SessionTimeout = () => {
	const timeout = (process.env.NODE_ENV === 'development' ? 1440 : 15) * 1000 * 60;
	const dispatch = useDispatch();

	const authenticated = useSelector((state: RootState) => state.auth.user?.authenticated ?? false);

	const handleOnIdle = () => {
		if (authenticated) {
			dispatch(timedOut());
		}
	};

	return (
		<>
			<IdleTimer onIdle={handleOnIdle} timeout={timeout} eventsThrottle={1000} />
		</>
	);
};
