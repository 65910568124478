import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Color } from '@material-ui/lab/Alert';

type SnackbarDetails = {
	severity: Color;
	message: string;
};

export interface AccountLayoutState {
	showDebtPanel: boolean;
	actionCardOpen: boolean;
	debtPanelOpen: boolean;
	snackbarInfo: SnackbarDetails | undefined;
}

const initialState: AccountLayoutState = {
	showDebtPanel: false,
	actionCardOpen: true,
	debtPanelOpen: false,
	snackbarInfo: undefined,
};

export const accountLayoutSlice = createSlice({
	name: 'accountLayout',
	initialState,
	reducers: {
		setShowDebtPanel(state: AccountLayoutState, action: PayloadAction<boolean>) {
			state.showDebtPanel = action.payload;
		},
		setActionCardOpen(state: AccountLayoutState, action: PayloadAction<boolean>) {
			state.actionCardOpen = action.payload;
		},
		setDebtPanelOpen(state: AccountLayoutState, action: PayloadAction<boolean>) {
			state.debtPanelOpen = action.payload;
		},
		setSnackbarOpen(state, action: PayloadAction<SnackbarDetails | undefined>) {
			state.snackbarInfo = action.payload;
		},
	},
});

export const { setShowDebtPanel, setActionCardOpen, setDebtPanelOpen, setSnackbarOpen } = accountLayoutSlice.actions;

export default accountLayoutSlice.reducer;
