import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CTANavigationCard } from '../../../../elements/CTANavigationCard/cTANavigationCard';
import { HdMedicalFolder } from '../../../../elements/PantheraIcon';
import { HdCalendar } from '../../../../elements/PantheraIcon/HdCalendar';
import { HdPaymentTerminal } from '../../../../elements/PantheraIcon/HdPaymentTerminal';
import { AvailablePaymentMethod } from '../../../../enums/paymentForm';
import { PaymentRoutes } from '../../../../enums/routerPath';
import { payNowWithAmount } from '../../../../store/features/paymentForm/paymentFormSlice';
import { RootState } from '../../../../store/rootReducer';
import { BPAYDetailsLockedModal } from '../../scenes/PaymentModules/BpayDetailsLockedModal/bpayDetailsLockedModal';
import { OtherOptionsLockedModal } from '../OtherOptionsLockedModal/otherOptionsLockedModal';

type PayOffOptionsProps = {
	variant?: string;
};

export const DashboardPayoffOptions: React.FC<PayOffOptionsProps> = ({ variant }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [otherOptionsModalIsOpen, setOtherOptionsModalIsOpen] = useState(false);
	const [bpayModalOpen, setBpayModalOpen] = useState(false);

	const { ccPIFOnly, outstandingAmount, ccAllowed, bpayAllowed } = useSelector(
		(state: RootState) => ({
			ccPIFOnly: state.paymentMethod.ccPIFOnly,
			outstandingAmount: state.customer.customerDetails?.outstandingAmt,
			ccAllowed: state.customer.customerDetails?.IsExtranet_DebtorAllowCreditCardPayment,
			bpayAllowed: state.paymentMethod.methods.some((method) => method.Name === AvailablePaymentMethod.BPAY),
		}),
		shallowEqual,
	);

	const handleSinglePaymentClick = () =>
		ccPIFOnly ? dispatch(payNowWithAmount(outstandingAmount?.toString() ?? '0')) : history.push(PaymentRoutes.SINGLE_PAYMENT.path);

	const handleInArragementSinglePaymentClick = () => {
		if (ccPIFOnly) {
			dispatch(payNowWithAmount(outstandingAmount?.toString() ?? '0'));
		} else if (ccAllowed) {
			history.push(PaymentRoutes.SINGLE_PAYMENT.path);
		} else {
			setBpayModalOpen(true);
		}
	};

	const cTAActions = [
		...(!ccPIFOnly
			? [
					{
						title: 'I would like to pay in installments',
						subtitle: 'Choose an amount that suits you at a frequency that meets your needs.',
						icon: <HdCalendar />,
						LinkProps: {
							to: PaymentRoutes.PAYMENT_PLAN.path,
						},
					},
			  ]
			: []),
		{
			title: 'I would like to pay in full',
			subtitle: 'Pay a small amount or the full account, now or later.',
			icon: <HdPaymentTerminal />,
			handleClick: () => handleSinglePaymentClick(),
		},
		{
			title: 'Other options',
			subtitle: 'If you would like to request more infromation about your account.',
			icon: <HdMedicalFolder />,
			handleClick: () => {
				setOtherOptionsModalIsOpen(true);
			},
		},
	];

	const cTAActionsArrangement = [
		...(ccAllowed || bpayAllowed
			? [
					{
						title: 'Make an additional payment',
						subtitle: 'Pay off your account faster by making extra payments.',
						icon: <HdPaymentTerminal />,
						handleClick: () => handleInArragementSinglePaymentClick(),
					},
			  ]
			: []),
		{
			title: 'Other options',
			subtitle: 'If you would like to request more infromation about your account.',
			icon: <HdMedicalFolder />,
			handleClick: () => {
				setOtherOptionsModalIsOpen(true);
			},
		},
	];

	return (
		<>
			<CTANavigationCard
				items={variant === 'arrangement' ? cTAActionsArrangement : cTAActions}
				subtitle={variant === 'arrangement' ? undefined : 'Let’s pay this off'}
				title={variant === 'arrangement' ? 'More ways to manage your account' : 'What are your intentions for this account?'}
			/>
			{otherOptionsModalIsOpen && (
				<OtherOptionsLockedModal
					onClose={() => {
						setOtherOptionsModalIsOpen(false);
					}}
					onContinue={() => {
						setOtherOptionsModalIsOpen(false);
					}}
				/>
			)}
			{bpayModalOpen && (
				<BPAYDetailsLockedModal
					onClose={() => {
						setBpayModalOpen(false);
					}}
				/>
			)}
		</>
	);
};
