import React from 'react';

export const BankColored = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" {...props}>
		<defs>
			<path d="M0 0.0005L69.7537878 0.0005 69.7537878 65.1025 0 65.1025z" id="path-1" />
		</defs>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<path d="M24.286 27l-3.572 6.476L24.286 61h10l3.143-27.524L35.286 27h-11z" fill="#ffcccc" />
			<path d="M44.143 27l-2.572 6.476L44 61h12l2-28-2-6H44.143z" fill="#ffcccc" />
			<ellipse cx="40" cy="19" fill="#ffcccc" rx="3.143" ry="3" />
			<g transform="translate(5 7)">
				<g>
					<mask fill="#fff" id="mask-2">
						<use xlinkHref="#path-1" />
					</mask>
					<path
						d="M34.877 13.95a2.326 2.326 0 11.001-4.65 2.326 2.326 0 01-.001 4.65zm0-6.975a4.65 4.65 0 100 9.3 4.65 4.65 0 000-9.3zM2.326 62.778h65.102v-2.325H2.326v2.325zm3.488-4.651H63.94v-2.325H5.814v2.325zm6.893-32.982l-1.688-4.22h5.866l-1.688 4.22a1.161 1.161 0 00-.083.524l2.228 27.808H10.56l2.229-27.808a1.174 1.174 0 00-.082-.524zm18.665.61l-2.218 27.722h-9.48l-2.216-27.721 1.93-4.83H29.44l1.932 4.83zm2.26-.61l-1.688-4.22h5.867l-1.688 4.22a1.161 1.161 0 00-.083.524l2.228 27.808h-6.782l2.229-27.808a1.161 1.161 0 00-.083-.524zm18.665.61L50.08 53.478H40.6l-2.217-27.721 1.932-4.83h10.05l1.932 4.83zm2.264-.61l-1.691-4.22h5.867l-1.69 4.22a1.19 1.19 0 00-.082.524l2.229 27.808h-6.782l2.228-27.808a1.159 1.159 0 00-.08-.524zM5.678 18.6L34.877 2.49l29.2 16.11h-58.4zM68.59 58.127h-2.325V54.64c0-.643-.52-1.163-1.163-1.163h-3.576l-2.218-27.721 1.932-4.83h7.35a1.161 1.161 0 00.562-2.181L35.439.144a1.167 1.167 0 00-1.123 0L.602 18.744a1.163 1.163 0 00.562 2.182h7.35l1.932 4.83-2.217 27.72H4.65c-.642 0-1.163.52-1.163 1.164v3.487H1.164c-.642 0-1.163.52-1.163 1.163v4.65c0 .642.52 1.163 1.163 1.163H68.59c.642 0 1.162-.521 1.162-1.163v-4.65c0-.642-.52-1.163-1.162-1.163z"
						fill="#cc0000"
						mask="url(#mask-2)"
					/>
				</g>
			</g>
		</g>
	</svg>
);
