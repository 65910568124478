import { makeStyles } from '@material-ui/core/styles';

import { fontFamilyDisplay, getThemeStyle, transition } from '../../../../../../theme/theme';

export default makeStyles(
	{
		root: {
			height: 0,
			paddingBottom: '100%',
			position: 'relative',

			'& $dateButton': {
				position: 'absolute',
				top: 0,
				left: 0,
				height: '100%',
				width: '100%',
				borderRadius: 5,
				fontFamily: fontFamilyDisplay,
				fontSize: '1.8rem',
				color: getThemeStyle('paletteTertiary200'),
				transition: transition(['color', 'background-color']),

				'&:hover': {
					backgroundColor: getThemeStyle('paletteGrey50'),
				},
				'&$dateButtonInMonth': {
					color: getThemeStyle('paletteTertiary900'),
				},
				'&$dateButtonToday': {
					color: getThemeStyle('palettePrimary500'),
				},
				'&$dateButtonSelected': {
					backgroundColor: getThemeStyle('palettePrimary500'),
					color: getThemeStyle('paletteCommonWhite'),
					fontWeight: 'bold',
				},
				'&$dateButtonDisabled': {
					color: getThemeStyle('paletteGrey50'),
				},
			},
		},
		dateButton: {},
		dateButtonInMonth: {},
		dateButtonToday: {},
		dateButtonSelected: {},
		dateButtonDisabled: {},
	},
	{ name: 'customDatePickerDay' },
);
