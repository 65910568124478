import React from 'react';

export const ISOCompliance = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 251.1 251.1" {...props}>
		<path fill="none" stroke="#c00000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={5} d="M197.06 197.64l13.88 12.37 20.01-24.47" />
		<path
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
			d="M175.56 172.37h73.04v51.62h-73.04zM240.5 164.7a28.42 28.42 0 00-28.42-28.42h0a28.43 28.43 0 00-28.42 28.42"
		/>
		<path fill="#fccccc" d="M240.6 189.25l-22.04 26.73h22.04v-26.73z" />
		<path
			d="M136.9 102.36a41.11 41.11 0 0112.45 29.52M74.77 107.68A41.25 41.25 0 01126.46 95M68.51 150a41.37 41.37 0 011.9-32.87M86.14 114.58l.22-.27a28.1 28.1 0 0121.78-10.17c11.49 0 19.92 6.12 25 15.89a39.29 39.29 0 014.18 16.58 140.64 140.64 0 01-1.79 28.59M78.47 160.9c4.39-8.94 3.44-20.63 2.58-26.43a27.53 27.53 0 01-.12-7.25M123.17 150a87.72 87.72 0 01-4 22.29"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<path
			d="M89.47 169.37a36.21 36.21 0 005.31-28.19s-3.69-17.23 8-21.93a13.88 13.88 0 016.16-.91c3.94.29 10.44 2.08 13 10.41a20.27 20.27 0 01.65 3.19c.19 1.41.45 3.73.61 6.72M108.24 159.13a50.92 50.92 0 01-5.45 15.3M107.93 130.56a75.41 75.41 0 011.5 16.21"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<path
			d="M165.4 198.46H16.63A14.13 14.13 0 012.5 184.33v-146a11.26 11.26 0 0111.26-11.22h187.06a11.26 11.26 0 0111.26 11.26v88.85"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<path
			d="M30.67 49.75a2 2 0 10-2 2 2 2 0 002-2zM53.15 49.75a2 2 0 10-2 2 2 2 0 002-2z"
			fill="none"
			stroke="#c00000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={5}
		/>
		<circle cx={73.66} cy={49.75} r={1.97} fill="none" stroke="#c00000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={5} />
		<path fill="none" stroke="#c00000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={5} d="M2.5 71.97h209.58" />
	</svg>
);
