/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormHelperText, InputLabel, Select, SelectProps, FormControlProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { TriangleRight } from '../PantheraIcon';
import useStyles from './pantheraSelect.styles';

export type PantheraSelectProps = {
	className?: string;
	label: React.ReactNode;
	helperText?: string;
	SelectComponent?: typeof Select;
	SelectProps?: SelectProps;
	shouldDisplayIndicator?: boolean;
	errorMessage?: string;
};

export const PantheraSelect = ({
	children,
	className,
	label,
	helperText,
	errorMessage,
	SelectComponent = Select,
	SelectProps,
	shouldDisplayIndicator = false,
	...props
}: PantheraSelectProps & React.PropsWithChildren<FormControlProps>) => {
	const styles = useStyles();
	return (
		<FormControl className={clsx(styles.root, className)} {...props}>
			{label && (
				<InputLabel className={styles.label} disableAnimation shrink>
					{label}
				</InputLabel>
			)}
			<SelectComponent
				className={styles.input}
				disableUnderline
				fullWidth
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					className: styles.menu,
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
				}}
				{...SelectProps}
			>
				{children}
			</SelectComponent>
			{shouldDisplayIndicator && (
				<span className={clsx('input-indicator', styles.indicator, label && styles.indicatorWithLabel)}>
					<TriangleRight />
				</span>
			)}
			{helperText && <FormHelperText className={styles.helperText}>{helperText}</FormHelperText>}
			{props.error && <span className={styles.errorMessage}>{errorMessage}</span>}
		</FormControl>
	);
};
