import React from 'react';

export const MasterCard = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg
		id="Layer_1"
		x="0px"
		y="0px"
		viewBox="0 0 116.5 72"
		style={{
			enableBackground: 'new 0 0 116.5 72',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<style type="text/css">{'\n\t.st0{fill:#FF5F00;}\n\t.st1{fill:#EB001B;}\n\t.st2{fill:#F79E1B;}\n'}</style>
		<rect x={40.9} y={7.7} className="st0" width={34.7} height={56.6} />
		<path
			className="st1"
			d="M44.5,36c0-11,5.1-21.5,13.8-28.3C42.6-4.6,20-1.9,7.7,13.8S-1.9,52,13.8,64.3c13.1,10.3,31.4,10.3,44.5,0 C49.6,57.5,44.5,47.1,44.5,36z"
		/>
		<path
			className="st2"
			d="M113.1,58.3v-1.2h0.5v-0.2h-1.2v0.2h0.5v1.2H113.1z M115.4,58.3v-1.4H115l-0.4,1l-0.4-1h-0.4v1.4h0.3v-1.1 l0.4,0.9h0.3l0.4-0.9v1.1L115.4,58.3z"
		/>
		<path
			className="st2"
			d="M116.5,36c0,19.9-16.1,36-36,36c-8.1,0-15.9-2.7-22.2-7.7C73.9,52,76.6,29.4,64.3,13.8 c-1.8-2.3-3.8-4.3-6.1-6.1c15.6-12.3,38.3-9.6,50.6,6.1C113.8,20.1,116.5,27.9,116.5,36L116.5,36z"
		/>
	</svg>
);
