import React from 'react';

export const FileTextFeather = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" {...props}>
		<path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
		<path d="M14 2L14 8 20 8" />
		<path d="M16 13L8 13" />
		<path d="M16 17L8 17" />
		<path d="M10 9L9 9 8 9" />
	</svg>
);
