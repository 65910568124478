import React from 'react';

export const TimerFast = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M59.7,22.66l-.6-.58,1.95-2.34,3.21,2.67,3-3.63-10-8.37-3,3.63,3.21,2.67-2,2.43a27.62,27.62,0,0,0-13-4.54V9.78H37.64V14.6A27.86,27.86,0,1,0,59.7,22.66ZM40,65.5A23.14,23.14,0,1,1,63.14,42.36,23.17,23.17,0,0,1,40,65.5ZM58.41,42.36a18.27,18.27,0,0,1-5.37,13L51.38,57,41.23,46.92a4.93,4.93,0,0,1-1.23.16,4.72,4.72,0,1,1,4.72-4.72,4.37,4.37,0,0,1-.16,1.21l6.66,6.64a13.55,13.55,0,0,0,2.47-7.85A13.7,13.7,0,0,0,40,28.67H37.64V24H40A18.43,18.43,0,0,1,58.41,42.36Z" />
		<path d="M20.06,19.23H1.14V24H15.63A30,30,0,0,1,20.06,19.23Z" />
		<path d="M10.1,36.07h-9v4.72H9.49A30.46,30.46,0,0,1,10.1,36.07Z" />
		<path d="M13.22,27.65H1.14v4.72h10A31.19,31.19,0,0,1,13.22,27.65Z" />
	</svg>
);
