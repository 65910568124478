export enum PeriodicFrequencyID {
	ONE_OFF = 0,
	WEEKLY = 2,
	FORTNIGHTLY = 3,
	MONTHLY = 4,
}

export enum PeriodicFrequencyString {
	ONE_OFF = 'one off',
	WEEKLY = 'per week',
	FORTNIGHTLY = 'per fortnight',
	MONTHLY = 'per month',
}
