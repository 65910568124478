import { makeStyles, alpha } from '@material-ui/core/styles';

import MuiTheme from '../../../../theme';
import {
	debtDetailsPanelWidth,
	fontFamilyDisplay,
	fontFamilyText,
	getThemeStyle,
	spacingUnits,
	toolbarLoggedInHeight,
} from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			flexShrink: 0,
			'&$hidden': {
				display: 'none',
			},
			'&$rootWithWidth': {
				maxWidth: debtDetailsPanelWidth,
				width: '100%',
			},
			'& $drawerPaper': {
				maxWidth: debtDetailsPanelWidth,
				width: '100%',
			},
			'& $closeWrapper': {
				position: 'absolute',
				right: spacingUnits(2),
				top: spacingUnits(1.5),
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				fontSize: '1.4rem',
				fontWeight: 'bold',
				fontFamily: fontFamilyDisplay,

				'& $closeIcon': {
					fill: getThemeStyle('paletteCommonWhite'),
					width: 24,
				},
			},
			'& $drawerContent': {
				overflow: 'auto',
				padding: spacingUnits(4),
			},
			'& $debtBranding': {
				height: 0,
				position: 'relative',
				paddingBottom: '100%',

				'& $squareSpacing': {
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,

					'& > *': {
						width: '100%',
						height: '100%',
					},
				},
			},
			'& $debtTitleWrapper': {
				paddingLeft: spacingUnits(1),

				'& h6': {
					marginBottom: 2,
				},
				'& p': {
					fontFamily: fontFamilyText,
					fontWeight: 'normal',
				},
			},
			'& $detailRow': {
				marginBottom: spacingUnits(3),
			},
			'& $sectionTitle': {
				color: 'inherit',
				fontWeight: 500,
				fontFamily: fontFamilyText,
				marginBottom: 2,
			},
			'& $sectionBody': {
				color: 'inherit',
				fontSize: '1.4rem',
				fontWeight: 'normal',
				fontFamily: fontFamilyText,
			},
			'& $currencyDisplay': {
				fontWeight: 500,
				fontFamily: fontFamilyText,
				fontSize: '2.6rem',
				lineHeight: '2.2rem',
				marginBottom: 2,
			},
			'& $downloadButton': {
				marginTop: spacingUnits(1.5),
				fontWeight: 500,
				fontSize: '1.4rem',
				justifyContent: 'flex-start',
				alignItems: 'center',
				lineHeight: '2.4rem',
				boxShadow: getThemeStyle('shadows0'),

				'& > span': {
					wordBreak: 'break-all',
				},
			},
			'& $noDocuments': {
				marginTop: spacingUnits(1.5),
				fontWeight: 500,
				fontSize: '1.4rem',
				justifyContent: 'flex-start',
				alignItems: 'center',
				lineHeight: '2.4rem',
				boxShadow: getThemeStyle('shadows0'),
				cursor: 'default',
				'&:hover': {
					backgroundColor: getThemeStyle('palettePrimary500'),
				},
				'& > span': {
					wordBreak: 'break-all',
				},
			},
			'& $attachmentSpinner': {
				color: getThemeStyle('paletteCommonWhite'),
			},
			'& .MuiSkeleton-root': {
				backgroundColor: alpha(getThemeStyle('paletteCommonWhite'), 0.13),
			},
			'& .MuiBottomNavigation-root': {
				marginTop: spacingUnits(1.5),
				backgroundColor: 'inherit',

				'& .MuiBottomNavigationAction-root:first-child': {
					paddingLeft: 0,
				},
				'& .MuiBottomNavigationAction-root:last-child': {
					borderRight: 'none !important',
					paddingRight: 0,
					minWidth: 90,
				},
				'& .MuiBottomNavigationAction-root': {
					paddingLeft: spacingUnits(1),
					paddingRight: spacingUnits(1),
					minWidth: 'auto',
					borderRight: '1px solid white',
				},
				'& .MuiBottomNavigationAction-label': {
					lineHeight: '1.8rem',
					textAlign: 'center',
					color: getThemeStyle('paletteCommonWhite'),
					fontSize: '1.2rem',
				},
				'& .MuiSvgIcon-root': {
					color: getThemeStyle('paletteCommonWhite'),
				},
			},
		},
		rootWithWidth: {},
		drawerPaper: {},
		closeWrapper: {},
		closeIcon: {},
		drawerContent: {},
		debtBranding: {},
		squareSpacing: {},
		debtTitleWrapper: {},
		detailRow: {},
		sectionTitle: {},
		sectionBody: {},
		currencyDisplay: {},
		downloadButton: {},
		noDocuments: {},
		attachmentSpinner: {},
		hidden: {},
		[MuiTheme.breakpoints.up('md')]: {
			root: {
				'& $drawerContent': {
					marginTop: toolbarLoggedInHeight,
				},
			},
		},
		[MuiTheme.breakpoints.down('sm')]: {
			root: {
				'& $drawerContent': {
					paddingTop: spacingUnits(5),
				},
			},
		},
		progress: {
			'& .MuiLinearProgress-colorPrimary': {
				backgroundColor: getThemeStyle('paletteTertiary800'),
			},
			'& .MuiLinearProgress-barColorPrimary': {
				backgroundColor: getThemeStyle('paletteSecondary400'),
				borderColor: getThemeStyle('paletteSecondary500'),
			},
		},
		scrollBarContainer: {
			position: 'relative',
			height: '100%',
		},
	},
	{ name: 'debtDetailsPanel' },
);
