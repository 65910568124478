/* eslint-disable react/no-array-index-key */
import { ButtonProps, CardActions, CardContent, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { BrandAvatar } from '../Branding/BrandAvatar';
import { LogoBrandmark } from '../Branding/LogoBrandmark';
import { PersistentCard } from '../PersistentCard/persistentCard';
import useStyles from './assistantCTACardPersistent.styles';

type AssistantCTACardProps = {
	assistant?: string;
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	actions?: React.ReactNode;
};

export const AssistantCTACardPersistent: React.FC<AssistantCTACardProps> = ({ assistant, title, subtitle, children, actions }) => {
	const styles = useStyles();
	return (
		<PersistentCard className={styles.root} severity="success">
			<LogoBrandmark className={styles.brandmark} />
			<BrandAvatar assistant={assistant} className={styles.avatar} />
			<div>
				<CardContent>
					<Typography className={styles.cardTitle} variant="h6">
						{title}
					</Typography>
					{subtitle && <Typography variant="body1">{subtitle}</Typography>}
				</CardContent>
				{children}
				{React.Children.count(actions) > 0 && (
					<CardActions>
						{(actions as React.ReactElement)?.type !== React.Fragment &&
							React.Children.map(actions as React.ReactElement<ButtonProps>, (child, index) =>
								React.isValidElement(child) ? (
									<React.Fragment key={index}>
										{React.cloneElement(child, {
											className: clsx(child?.props.className),
										})}
									</React.Fragment>
								) : null,
							)}
						{(actions as React.ReactElement)?.type === React.Fragment &&
							React.Children.map(
								(actions as React.ReactElement)?.props.children as React.ReactElement<ButtonProps>,
								(fragChild, fragIndex) =>
									React.isValidElement(fragChild) ? (
										<React.Fragment key={fragIndex}>
											{React.cloneElement(fragChild, {
												className: clsx(fragChild?.props.className, styles.buttonWrapper),
											})}
										</React.Fragment>
									) : null,
							)}
					</CardActions>
				)}
			</div>
		</PersistentCard>
	);
};
