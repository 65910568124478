import { makeStyles } from '@material-ui/core';
import MuiTheme from '../../../../theme';
import { getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';

export default makeStyles(
	{
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',
		},
		greyHeader: {
			backgroundColor: subtleBackgroundColor,
		},
		row: {
			paddingTop: spacingUnits(7),
			paddingBottom: spacingUnits(12),
			[MuiTheme.breakpoints.up('md')]: {
				paddingTop: spacingUnits(9),
				paddingBottom: spacingUnits(25),
			},
		},
	},
	{
		name: 'contact',
	},
);
