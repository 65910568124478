import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

// Fixes same route being added to stack https://github.com/ReactTraining/history/issues/470
const prevHistoryPush = history.push;
let lastLocation = history.location;

history.listen((location) => {
	lastLocation = location;
});
history.push = (pathname: string, state = {}) => {
	if (
		lastLocation === null ||
		pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash ||
		JSON.stringify(state) !== JSON.stringify(lastLocation.state)
	) {
		prevHistoryPush(pathname, state);
	}
};
