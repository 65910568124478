import React from 'react';
import { useSelector } from 'react-redux';
import { Toolbar, AppBar, Hidden } from '@material-ui/core';
import { LinkProps } from 'react-router-dom';
import useStyles from './headerLoggedIn.styles';
import { RootState } from '../../../../store/rootReducer';
import { ArrowLeft } from '../../../../elements/PantheraIcon/ArrowLeft';
import { WrapWithLink } from '../../../../elements/WrapWithLink/wrapWithLink';
import { WebRoutes } from '../../../../enums/routerPath';
import { UserMenu } from './elements/UserMenu/userMenu';
import { HeaderLinks } from './elements/HeaderLinks/headerLinks';
import { Logo } from '../../../../elements/Branding/Logo';

type HeaderLoggedInProps = {
	BackArrowProps?: LinkProps;
};

export const HeaderLoggedIn: React.FC<HeaderLoggedInProps> = ({ BackArrowProps }) => {
	const styles = useStyles();
	const displayName = useSelector((state: RootState) => state.auth.user?.displayName ?? '');
	return (
		<AppBar className={styles.root} color="default" elevation={1} position="fixed">
			<Toolbar variant="dense">
				{BackArrowProps && (
					<Hidden mdUp>
						<WrapWithLink className={styles.backArrow} LinkProps={BackArrowProps}>
							<ArrowLeft />
						</WrapWithLink>
					</Hidden>
				)}
				<WrapWithLink
					className={styles.logo}
					LinkProps={{
						to: WebRoutes.HOME.path,
					}}
				>
					<Logo />
				</WrapWithLink>
				<Hidden smDown>
					<HeaderLinks />
				</Hidden>
				<UserMenu fullName={displayName} />
			</Toolbar>
		</AppBar>
	);
};
