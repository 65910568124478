import { makeStyles } from '@material-ui/core';
import { getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';

export default makeStyles(
	(theme) => ({
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',
		},
		row: {
			paddingTop: spacingUnits(8),
			paddingBottom: spacingUnits(8),

			'&:nth-child(even)': {
				backgroundColor: subtleBackgroundColor,
			},

			'& p': {
				fontSize: '2rem',
				lineHeight: 1.8,
			},

			'& h1': {
				lineHeight: 1.1,
				[theme.breakpoints.down('xs')]: {
					fontSize: '4rem',
				},
			},
		},
		imgWrapper: {
			[theme.breakpoints.down('md')]: {
				justifyContent: 'center',
			},
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		svgWrapper: {
			display: 'flex',
			width: '100%',
			height: 'auto',
			maxHeight: 470,
			[theme.breakpoints.down('sm')]: {
				maxHeight: 300,
			},

			'& svg': {
				height: 'auto',
				width: '100%',
			},
		},
		heroTitle: {
			fontSize: '6rem',
		},
		button: {
			paddingLeft: spacingUnits(8),
			paddingRight: spacingUnits(8),
		},
	}),
	{ name: 'home' },
);
