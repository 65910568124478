import { darken, makeStyles } from '@material-ui/core';
import { brandMexicanRed, getThemeStyle, spacingUnits, subtleBackgroundColor } from '../../../../theme/theme';
import { headerHeight, mobileHeaderHeight } from '../../../Customer/elements/HeroTitle/heroTitle';

export default makeStyles(
	(theme) => ({
		root: {
			backgroundColor: getThemeStyle('palettePrimary500'),
			width: '100%',
			overflowX: 'hidden',
		},
		hero: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: `calc(100vh - ${headerHeight}px)`,
			overflow: 'hidden',
			position: 'relative',

			'& > div:first-of-type': {
				paddingTop: spacingUnits(5),
				maxWidth: 1280,
				[theme.breakpoints.down('lg')]: {
					paddingBottom: spacingUnits(40),
				},

				[theme.breakpoints.down('xs')]: {
					paddingBottom: spacingUnits(30),
				},
			},

			'& .MuiButton-outlinedPrimary': {
				color: getThemeStyle('paletteCommonWhite'),
				borderColor: 'rgba(255, 255, 255, 0.5)',
				'&:hover': {
					backgroundColor: 'rgba(255, 255, 255, 0.2)',
				},
			},

			[theme.breakpoints.down('sm')]: {
				minHeight: `calc(100vh - ${mobileHeaderHeight}px)`,
			},
		},
		heroWrapper: {
			width: '62%',
			[theme.breakpoints.down('sm')]: {
				width: '85%',
			},
		},
		heroText: {
			position: 'relative',
			zIndex: 2,
			fontWeight: 900,
			textAlign: 'left',
			fontSize: '6rem',
			lineHeight: 1.2,
			color: getThemeStyle('paletteCommonWhite'),
			'& span': {
				backgroundImage: `linear-gradient(90deg, ${getThemeStyle('paletteSecondary500')}, ${getThemeStyle('paletteSecondary500')})`,
				backgroundPosition: '0 88%',
				backgroundSize: '100% 20%',
				backgroundRepeat: 'no-repeat',
			},

			[theme.breakpoints.down('md')]: {
				fontSize: '5rem',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '4rem',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '3rem',
			},
		},
		heroImage: {
			position: 'absolute',
			bottom: 0,
			right: 0,
			maxWidth: 940,
			width: '80%',
			'& svg': {
				zIndex: 1,
				opacity: 0.4,
				height: 'auto',
				width: '100%',
			},
		},
		buttonWrapper: {
			maxWidth: 300,
			width: '100%',
			padding: spacingUnits(1),
			position: 'relative',
			zIndex: 2,
		},
		row: {
			paddingTop: spacingUnits(12),
			paddingBottom: spacingUnits(12),

			[theme.breakpoints.down('sm')]: {
				paddingTop: spacingUnits(6),
				paddingBottom: spacingUnits(6),
			},

			backgroundColor: getThemeStyle('paletteCommonWhite'),

			'&:nth-child(odd)': {
				backgroundColor: subtleBackgroundColor,
			},

			'& p': {
				fontSize: '2rem',
				lineHeight: 2,
				[theme.breakpoints.down('sm')]: {
					textAlign: 'left',
				},
			},

			'& h1': {
				lineHeight: 1.1,
				[theme.breakpoints.down('sm')]: {
					textAlign: 'left',
				},
				[theme.breakpoints.down('xs')]: {
					fontSize: '4rem',
				},
			},
		},
		underline: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',

			[theme.breakpoints.down('sm')]: {
				alignSelf: 'flex-start',
				alignItems: 'flex-start',
				textAlign: 'left',
			},

			'&:after': {
				content: '""',
				borderBottom: `3px solid ${brandMexicanRed}`,
				paddingTop: 14,
				width: 80,
			},
		},
		underLineLeft: {
			alignSelf: 'flex-start',
			alignItems: 'flex-start',
			textAlign: 'left',
		},
		imgWrapper: {
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
			},
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		svg: {
			height: 'auto',
			width: 400,

			[theme.breakpoints.down('sm')]: {
				width: 250,
			},
		},
		button: {
			paddingTop: spacingUnits(2),
			paddingBottom: spacingUnits(2),
			paddingLeft: spacingUnits(8),
			paddingRight: spacingUnits(8),

			[theme.breakpoints.down('xs')]: {
				paddingTop: spacingUnits(1.5),
				paddingBottom: spacingUnits(1.5),
				paddingLeft: spacingUnits(4),
				paddingRight: spacingUnits(4),
			},
		},
		industriesGrid: {
			borderRadius: getThemeStyle('shapeBorderRadius') / 1.5,
			marginTop: spacingUnits(6),
			maxWidth: 550,
			overflow: 'hidden',

			[theme.breakpoints.up('md')]: {
				maxWidth: 1066,
			},

			'& > div > div': {
				display: 'flex',
				alignItems: 'center',

				border: `0.5px solid ${darken(subtleBackgroundColor, 0.1)}`,
				padding: [[spacingUnits(1.5), spacingUnits(2)]],

				'& > svg': {
					width: 48,
				},

				'& > p': {
					fontWeight: 500,
					marginLeft: spacingUnits(1),
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
				},
			},
		},
		servicesGrid: {
			'& svg': {
				height: 'auto',
				width: 180,

				[theme.breakpoints.down('sm')]: {
					width: 150,
				},
			},
		},
		securityGrid: {
			'& svg': {
				height: 'auto',
				width: 80,
			},
		},
		caption: {
			marginTop: spacingUnits(1.5),
			fontSize: '1.8rem !important',
			fontWeight: 500,
		},
	}),
	{ name: 'businessHome' },
);
