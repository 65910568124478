import { Box, Button, Card, Hidden } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AssistantCTAHollow } from '../../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../../../../elements/ButtonStack/buttonStack';
import { logout } from '../../../../../../../store/features/auth/authSlice';
import { RootState } from '../../../../../../../store/rootReducer';

export const PIF: React.FC = () => {
	const dispatch = useDispatch();

	const { displayNameShort, clientName } = useSelector(
		(state: RootState) => ({
			displayNameShort: state.auth.user?.displayNameShort ?? '',
			clientName: state.customer.customerDetails?.clientName ?? '',
		}),
		shallowEqual,
	);

	const content = {
		title: 'Your account has been settled',
		subtitle: `Hi ${displayNameShort}, congratulations your ${clientName} account has been fully settled!`,
	};

	const CardContent: React.FC = () => (
		<>
			<AssistantCTAHollow subtitle={content.subtitle} title={content.title} />
			<ButtonStack style={{ marginTop: 0 }}>
				<Button onClick={() => dispatch(logout())} color="primary" fullWidth size="large" variant="outlined">
					Logout
				</Button>
			</ButtonStack>
		</>
	);

	return (
		<>
			<Hidden smDown>
				<Card elevation={8}>
					<Box padding={6}>
						<CardContent />
					</Box>
				</Card>
			</Hidden>
			<Hidden mdUp>
				<CardContent />
			</Hidden>
		</>
	);
};
