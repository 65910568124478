export enum ContactType {
	Phone_Home = 1,
	Phone_Work = 2,
	Phone_Mobile = 3,
	Fax = 4,
	Email_Work = 5,
	Email_Personal = 6,
}

export enum ContactTypeString {
	Phone_Home = 'Home',
	Phone_Work = 'Work',
	Phone_Mobile = 'Mobile',
	Email_Work = 'Work',
	Email_Personal = 'Personal',
}

export enum AddressType {
	PRIMARY = 1,
	MAILING = 2,
	REGISTERED = 3,
	PROPERTY = 4,
	DELVIERY = 5,
	PLACE_OF_BUSINESS = 6,
	ACCOUNTANT = 7,
	DIRECTORS = 8,
	OTHER = 9,
	PREVIOUS = 10,
	EMPLOYER = 13,
	LANDLORD = 15,
	BILLING = 101,
	RESIDENTIAL = 102,
	TERM = 103,
	TEMPORARY = 104,
}

export enum AddressTypeString {
	PRIMARY = 'Primary',
	MAILING = 'Mailing',
	REGISTERED = 'Registered',
	PROPERTY = 'Property',
	DELVIERY = 'Delivery',
	PLACE_OF_BUSINESS = 'Place of business',
	ACCOUNTANT = 'Accountant',
	DIRECTORS = 'Directors',
	OTHER = 'Other',
	PREVIOUS = 'Previous',
	EMPLOYER = 'Employer',
	LANDLORD = 'Landlord',
	BILLING = 'Billing',
	RESIDENTIAL = 'Residential',
	TERM = 'Term',
	TEMPORARY = 'Temporary',
}
